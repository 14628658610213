import Utils from '../../utils';
import moment from 'moment';

export const updatePaginationDashboard = (currentPage: number) => {
    return (dispatch: Function, getState: Function) => {
        const { moduleType, graphType, filterType } = getState().dashboardReducer;
        dispatch({
            type: Utils.ActionName.UPDATE_PAGINATION_DASHBOARD,
            payload: currentPage,
        });
        dispatch(getChartDashboard(moduleType, filterType, graphType));
    }
}

export const Customers = () => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(1, 'Please check your internet connection!');

            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = '';
        Utils.api.getApiCall(
            Utils.endPoints.customers,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getChartDashboard = (moduleType: any, filterType: any, graphType: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.GET_DASHBOARD,
            payload: {
                chartData: [],
            },
        });
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const {
            buildingFilterData,
            floorFilterData,
            areaFilterData,
            lineFilterData,
            isLoaded,
            sortAvgcycle,
            sortEffoperator,
            sortEff,
            sortLbr,
            lineId,
        } = getState().dashboardFilterReducer;
        const { shiftId, pageSize, currentPage } = getState().dashboardReducer;
        let parameters = '';

        if (moduleType === 2) {
            parameters = `?moduleType=${moduleType}&sortAvgcycle=${sortAvgcycle ? -1 : 1}&sortEffoperator=${
                sortEffoperator ? -1 : 1
            }&shiftId=${shiftId}`;
            if (lineId !== 0) {
                parameters = parameters + `&id=${lineId}`;
            }
        } else {
            parameters = `?moduleType=${moduleType}&filterType=${filterType}&graphType=${graphType}&sortLbr=${
                sortLbr ? -1 : 1
            }&sortEff=${sortEff ? -1 : 1}&shiftId=${shiftId}`;
        }
        parameters += `&pageSize=${pageSize}&currentPage=${currentPage}`
        if (isLoaded) {
            let countBuilding = 0;
            let countFloor = 0;
            let countArea = 0;
            let countLines = 0;

            let addParameter = '';
            let addFloor = '';
            let addArea = '';
            let addLines = '';

            for (let i = 0; i < buildingFilterData.length; i++) {
                if (buildingFilterData[i].checked) {
                    if (countBuilding === 0) {
                        addParameter = addParameter + buildingFilterData[i].id;
                    } else {
                        addParameter = addParameter + ',' + buildingFilterData[i].id;
                    }
                    countBuilding = countBuilding + 1;
                }
            }
            for (let i = 0; i < floorFilterData.length; i++) {
                if (floorFilterData[i].checked) {
                    if (countFloor === 0) {
                        addFloor = addFloor + floorFilterData[i].id;
                    } else {
                        addFloor = addFloor + ',' + floorFilterData[i].id;
                    }
                    countFloor = countFloor + 1;
                }
            }
            for (let i = 0; i < areaFilterData.length; i++) {
                if (areaFilterData[i].checked) {
                    if (countArea === 0) {
                        addArea = addArea + areaFilterData[i].id;
                    } else {
                        addArea = addArea + ',' + areaFilterData[i].id;
                    }
                    countArea = countArea + 1;
                }
            }
            for (let i = 0; i < lineFilterData.length; i++) {
                if (lineFilterData[i].checked) {
                    if (countLines === 0) {
                        addLines = addLines + lineFilterData[i].id;
                    } else {
                        addLines = addLines + ',' + lineFilterData[i].id;
                    }
                    countLines = countLines + 1;
                }
            }
            if (countBuilding > 0) {
                parameters = parameters + `&buildingIds=${addParameter}`;
            }
            if (countFloor > 0) {
                parameters = parameters + `&floorIds=${addFloor}`;
            }
            if (countArea > 0) {
                parameters = parameters + `&areaIds=${addArea}`;
            }
            if (countLines > 0) {
                parameters = parameters + `&lineIds=${addLines}`;
            }
        }
        Utils.api.getApiCall(
            Utils.endPoints.dashboardChart,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data.numericalStats;
                    dispatch({
                        type: Utils.ActionName.GET_DASHBOARD,
                        payload: {
                            dashboardData: innerData,
                            totalCount: innerData.activeLinesEff,
                            moduleType,
                            filterType,
                            graphType,
                        },
                    });
                    if (moduleType === 2) {
                        dispatch({
                            type: Utils.ActionName.GET_DASHBOARD,
                            payload: {
                                lineName: data.data.lineName,
                            },
                        });
                    }

                    if (graphType === 1 && moduleType === 1) {
                        dispatch(chartDataCalculation(innerData, filterType));
                    } else if (moduleType === 2) {
                        dispatch(lineChartCalculation(innerData, filterType));
                    } else {
                        dispatch(lineDataCalculation(innerData, filterType));
                    }
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const chartDataCalculation = (dataArray: any, filterType: any) => {
    return (dispatch: Function, getState: Function) => {
        const chartArray: any = [];
        const effArray: any = [];
        const nptArray: any = [];
        const effTgtArray: any = [];

        var todayDate = new Date();
        var endLimit = moment(todayDate, 'MMDDYYYY').isoWeek();

        var startLimit = 0;
        if (filterType === 3 || filterType === 2) {
            if (filterType === 3) {
                startLimit = 30;
                endLimit = 0;
            } else {
                startLimit = 6;
                endLimit = 0;
            }
            let countNpt = 0;
            let count = 0;

            let indexPosition = 1;
            let indexPositionNpt = 1;

            for (let i = startLimit; i >= endLimit; i--) {
                var date = new Date();

                date.setDate(date.getDate() - i);
                var dateCompare = moment(date).format('YYYY-MM-DD');
                let effVal: any = {};
                let nptVal: any = {};
                if (count >= dataArray.effGraph.length) {
                    count = 0;
                }
                const dateIsSame =
                    dataArray.effGraph.length > 0
                        ? moment(dataArray.effGraph[count].filterDate).isSame(moment(dateCompare))
                        : false;
                if (dateIsSame) {
                    effVal = {
                        x: moment(date).date().toString(),
                        y: Math.round((dataArray.effGraph[count].averageValue + Number.EPSILON) * 100) / 100,
                        label: `ACT EFF\n${
                            Math.round((dataArray.effGraph[count].averageValue + Number.EPSILON) * 100) / 100
                        }%`,
                    };
                    count = count + 1;
                    indexPosition = indexPosition + 1;
                } else {
                    effVal = {
                        x: moment(date).date().toString(),
                        y: 0,
                        label: 0,
                    };
                    indexPosition = indexPosition + 1;
                }
                effArray.push(effVal);
                if (countNpt >= dataArray.nptGraph.length) {
                    countNpt = 0;
                }
                const dateIsSameNpt =
                    dataArray.nptGraph.length > 0
                        ? moment(dataArray.nptGraph[countNpt].filterDate).isSame(moment(dateCompare))
                        : false;
                if (dateIsSameNpt) {
                    let labelString = '';
                    for (let j = 0; j < dataArray.nptGraph[countNpt].nptTypes.length; j++) {
                        labelString =
                            j === dataArray.nptGraph[countNpt].nptTypes.length - 1
                                ? labelString +
                                  `${dataArray.nptGraph[countNpt].nptTypes[j].nptValue}\tmin\t${dataArray.nptGraph[countNpt].nptTypes[j].nptType} `
                                : labelString +
                                  `${dataArray.nptGraph[countNpt].nptTypes[j].nptValue}\tmin\t${dataArray.nptGraph[countNpt].nptTypes[j].nptType}\n `;
                    }
                    nptVal = {
                        x: moment(date).date().toString(),
                        y: Math.round((dataArray.nptGraph[countNpt].duration + Number.EPSILON) * 100) / 100,

                        label: labelString,
                    };
                    countNpt = countNpt + 1;
                    indexPositionNpt = indexPositionNpt + 1;
                } else {
                    nptVal = {
                        x: moment(date).date().toString(),
                        y: 0,
                        label: 0,
                    };
                    indexPositionNpt = indexPositionNpt + 1;
                }
                nptArray.push(nptVal);
            }
        } else if (filterType === 4) {
            let date = new Date();
            var date2 = new Date();
            endLimit = moment(date2, 'MMDDYYYY').isoWeek();

            date.setDate(date.getDate() - 90);
            startLimit = moment(date, 'MMDDYYYY').isoWeek();
            let countNpt = 0;
            let count = 0;

            let indexPosition = 1;
            let indexPositionNpt = 1;

            for (let i = startLimit; i <= endLimit; i++) {
                let effVal: any = {};
                let nptVal: any = {};
                if (dataArray.effGraph.length > 0 ? i === dataArray.effGraph[count].filterDate : false) {
                    effVal = {
                        x: i.toString(),
                        y: Math.round((dataArray.effGraph[count].averageValue + Number.EPSILON) * 100) / 100,

                        label: `ACT EFF\n${
                            Math.round((dataArray.effGraph[count].averageValue + Number.EPSILON) * 100) / 100
                        }%`,
                    };
                    count = count + 1;
                    indexPosition = indexPosition + 1;
                } else {
                    effVal = {
                        x: i.toString(),
                        y: 0,
                        label: 0,
                    };
                    indexPosition = indexPosition + 1;
                }
                effArray.push(effVal);

                if (dataArray.nptGraph.length > 0 ? i === dataArray.nptGraph[countNpt].filterDate : false) {
                    let labelString = '';
                    for (let j = 0; j < dataArray.nptGraph[countNpt].nptTypes.length; j++) {
                        labelString =
                            j === dataArray.nptGraph[countNpt].nptTypes.length - 1
                                ? labelString +
                                  `${dataArray.nptGraph[countNpt].nptTypes[j].nptValue}\tmin\t${dataArray.nptGraph[countNpt].nptTypes[j].nptType} `
                                : labelString +
                                  `${dataArray.nptGraph[countNpt].nptTypes[j].nptValue}\tmin\t${dataArray.nptGraph[countNpt].nptTypes[j].nptType}\n `;
                    }
                    nptVal = {
                        x: i.toString(),
                        y: Math.round((dataArray.nptGraph[countNpt].duration + Number.EPSILON) * 100) / 100,

                        label: labelString,
                    };
                    countNpt = countNpt + 1;
                    indexPositionNpt = indexPositionNpt + 1;
                } else {
                    nptVal = {
                        x: i.toString(),
                        y: 0,
                        label: 0,
                    };
                    indexPositionNpt = indexPositionNpt + 1;
                }
                nptArray.push(nptVal);
            }
        } else {
            startLimit = 1;
            endLimit = 23;
            let countNpt = 0;
            let countEff = 0;
            let hourNpt = '';
            let hourEFF = '';
            for (let i = 23; i >= 0; i--) {
                let hourLoop = moment().subtract(i, 'hours').format('HH');

                if (dataArray.effGraph.length === countEff) {
                    countEff = 0;
                }
                if (dataArray.nptGraph.length === countNpt) {
                    countNpt = 0;
                }
                if (dataArray.nptGraph.length > 0) {
                    hourNpt = moment(dataArray.nptGraph[countNpt].datetime).format('HH');
                } else {
                    hourNpt = '25';
                }
                if (dataArray.effGraph.length > 0) {
                    hourEFF = moment(dataArray.effGraph[countEff].datetime).format('HH');
                } else {
                    hourEFF = '25';
                }

                let effVal: any = {};
                if (hourLoop === hourEFF) {
                    effVal = {
                        x: hourLoop,
                        y: Math.round((dataArray.effGraph[countEff].averageValue + Number.EPSILON) * 100) / 100,
                        label: `ACT EFF\n${
                            Math.round((dataArray.effGraph[countEff].averageValue + Number.EPSILON) * 100) / 100
                        }%`,
                    };
                    countEff = countEff + 1;
                } else {
                    effVal = {
                        x: hourLoop,
                        y: 0,
                        label: 0,
                    };
                }

                effArray.push(effVal);
                let effTgtVal = {
                    x: hourLoop,
                    y: dataArray.targetGraphTime || 80,
                    label: `TGT EFF\n${dataArray.targetGraphTime || 80}%`,
                };
                effTgtArray.push(effTgtVal);
                let nptVal: any = {};
                if (hourLoop === hourNpt) {
                    let labelString = '';
                    for (let j = 0; j < dataArray.nptGraph[countNpt].nptTypes.length; j++) {
                        labelString =
                            j === dataArray.nptGraph[countNpt].nptTypes.length - 1
                                ? labelString +
                                  `${dataArray.nptGraph[countNpt].nptTypes[j].nptValue}\tmin\t${dataArray.nptGraph[countNpt].nptTypes[j].nptType} `
                                : labelString +
                                  `${dataArray.nptGraph[countNpt].nptTypes[j].nptValue}\tmin\t${dataArray.nptGraph[countNpt].nptTypes[j].nptType}\n `;
                    }
                    nptVal = {
                        x: hourLoop,
                        y: Math.round((dataArray.nptGraph[countNpt].duration + Number.EPSILON) * 100) / 100,

                        label: labelString,
                    };
                    countNpt = countNpt + 1;
                } else {
                    nptVal = {
                        x: hourLoop,
                        y: 0,

                        label: 0,
                    };
                }
                nptArray.push(nptVal);
            }
        }

        chartArray.push(nptArray);
        chartArray.push(effArray);

        dispatch({
            type: Utils.ActionName.GET_DASHBOARD,
            payload: {
                chartData: chartArray,
                targetEffData: effTgtArray,
            },
        });
    };
};

export const lineDataCalculation = (dataArray: any, filterType: any) => {
    return (dispatch: Function, getState: Function) => {
        const chartArray: any = [];
        const effArray: any = [];
        const nptArray: any = [];
        const effTgtArray: any = [];

        for (let i = 0; i < dataArray.effGraph.length; i++) {
            let effVal: any = {};
            let effTgtVal: any = {};

            effVal = {
                x: dataArray.effGraph[i].lineName,
                y: Math.round((dataArray.effGraph[i].averageValue + Number.EPSILON) * 100) / 100,
                label: `ACT EFF\n${Math.round((dataArray.effGraph[i].averageValue + Number.EPSILON) * 100) / 100}%`,
            };

            effTgtVal = {
                x: dataArray.effGraph[i].lineName,
                y: dataArray.effGraph[i].targetEff,
                label: `TGT EFF\n${dataArray.effGraph[i].targetEff}%`,
            };

            effArray.push(effVal);
            effTgtArray.push(effTgtVal);
        }
        for (let i = 0; i < dataArray.nptGraph.length; i++) {
            let nptVal: any = {};
            let labelString = '';

            if (dataArray.nptGraph[i].nptTypes != null){
                for (let j = 0; j < dataArray.nptGraph[i].nptTypes.length; j++) {
                    labelString =
                        j === dataArray.nptGraph[i].nptTypes.length - 1
                            ? labelString +
                              `${dataArray.nptGraph[i].nptTypes[j].nptValue}\tmin\t${dataArray.nptGraph[i].nptTypes[j].nptType} `
                            : labelString +
                              `${dataArray.nptGraph[i].nptTypes[j].nptValue}\tmin\t${dataArray.nptGraph[i].nptTypes[j].nptType}\n `;
                }
                nptVal = {
                    x: dataArray.nptGraph[i].lineName,
                    y: Math.round((dataArray.nptGraph[i].duration + Number.EPSILON) * 100) / 100,
    
                    label: labelString,
                };
            } else {
                nptVal = {
                    x: dataArray.nptGraph[i].lineName,
                    y: 0,
    
                    label: labelString,
                };
            }

            let isLineName = false;
            dataArray.effGraph.forEach((eff: any) => {
                if(eff.lineName === dataArray.nptGraph[i].lineName){
                    isLineName = true;
                }
            });

            if(isLineName){
                nptArray.push(nptVal);
            }
        }

        chartArray.push(nptArray);
        chartArray.push(effArray);

        dispatch({
            type: Utils.ActionName.GET_DASHBOARD,
            payload: {
                chartData: chartArray,
                targetEffData: effTgtArray,
            },
        });
    };
};

export const lineChartCalculation = (dataArray: any, filterType: any) => {
    return (dispatch: Function, getState: Function) => {
        const chartArray: any = [];
        const effArray: any = [];
        const nptArray: any = [];
        const effTgtArray: any = [];

        let countNpt = 0;
        let countEff = 0;
        let currentShiftDate = new Date(dataArray.shiftDetail.startTime);
        let date = currentShiftDate.getUTCHours();
        for (let i = 0; i < 12; i++) {
            if (date === 24) {
                date = 0;
            }

            if (dataArray.effGraph.length === countEff) {
                countEff = 0;
            }
            if (dataArray.nptGraph.length === countNpt) {
                countNpt = 0;
            }
            let hourNpt =
                dataArray.nptGraph.length > 0
                    ? parseInt(moment(dataArray.nptGraph[countNpt].datetime).format('HH'))
                    : 25;
            let hourEFF =
                dataArray.effGraph.length > 0
                    ? parseInt(moment(dataArray.effGraph[countEff].datetime).format('HH'))
                    : 25;

            let effVal: any = {};
            if (date === hourEFF) {
                effVal = {
                    x: date.toString(),
                    y: Math.round((dataArray.effGraph[countEff].averageValue + Number.EPSILON) * 100) / 100,
                    label: `ACT EFF\n${
                        Math.round((dataArray.effGraph[countEff].averageValue + Number.EPSILON) * 100) / 100
                    }%`,
                };
                countEff = countEff + 1;
            } else {
                effVal = {
                    x: date.toString(),
                    y: 0,

                    label: 0,
                };
            }
            effArray.push(effVal);

            let nptVal: any = {};
            if (date === hourNpt) {
                let labelString = '';
                if(dataArray?.nptGraph[countNpt]?.nptTypes){
                    for (let j = 0; j < dataArray.nptGraph[countNpt].nptTypes.length; j++) {
                        labelString =
                            j === dataArray.nptGraph[countNpt].nptTypes.length - 1
                                ? labelString +
                                  `${dataArray.nptGraph[countNpt].nptTypes[j].nptValue}\tmin\t${dataArray.nptGraph[countNpt].nptTypes[j].nptType} `
                                : labelString +
                                  `${dataArray.nptGraph[countNpt].nptTypes[j].nptValue}\tmin\t${dataArray.nptGraph[countNpt].nptTypes[j].nptType}\n `;
                    }
                }
                nptVal = {
                    x: date.toString(),
                    y: Math.round((dataArray.nptGraph[countNpt].duration + Number.EPSILON) * 100) / 100,

                    label: labelString,
                };
                countNpt = countNpt + 1;
            } else {
                nptVal = {
                    x: date.toString(),
                    y: 0,

                    label: 0,
                };
            }

            let effTgtVal = {
                x: date.toString(),
                y: dataArray.targetGraphTime,
                label: `TGT EFF\n${dataArray.targetGraphTime}%`,
            };
            effTgtArray.push(effTgtVal);

            nptArray.push(nptVal);
            date = date + 1;
        }
        chartArray.push(nptArray);
        chartArray.push(effArray);

        dispatch({
            type: Utils.ActionName.GET_DASHBOARD,
            payload: {
                chartData: chartArray,
                targetEffData: effTgtArray,
            },
        });
    };
};

export const getallLine = (search: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = `?search=${search}`;
        Utils.api.getApiCall(
            Utils.endPoints.allActiveLines,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.GET_DASHBOARD,
                        payload: {
                            searchData: innerData,
                        },
                    });

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getFilterData = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = '';
        Utils.api.getApiCall(
            Utils.endPoints.analyticsBuildings,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    let finalData: any = [];
                    for (let i = 0; i < innerData.length; i++) {
                        let tempData = {
                            building: innerData[i].building,
                            id: innerData[i].id,
                            checked: false,
                        };
                        finalData.push(tempData);
                    }
                    dispatch({
                        type: Utils.ActionName.GET_DASHBOARD_FILTER,
                        payload: { buildingFilterData: finalData },
                    });
                    dispatch(floorFilter());
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const floorFilter = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }

        let parameters = '';
        Utils.api.getApiCall(
            Utils.endPoints.analyticsFloors,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    let finalData: any = [];
                    for (let i = 0; i < innerData.length; i++) {
                        let tempData = {
                            buildingId: innerData[i].buildingId,
                            floor: innerData[i].floor,
                            id: innerData[i].id,
                            checked: false,
                        };
                        finalData.push(tempData);
                    }
                    dispatch({
                        type: Utils.ActionName.GET_DASHBOARD_FILTER,
                        payload: { floorFilterData: finalData },
                    });
                    dispatch(areaFilter());
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};
export const areaFilter = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }

        let parameters = '';
        Utils.api.getApiCall(
            Utils.endPoints.analyticsAreas,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    let finalData: any = [];
                    for (let i = 0; i < innerData.length; i++) {
                        let tempData = {
                            area: innerData[i].area,
                            id: innerData[i].id,
                            floorId: innerData[i].floorId,
                            isActive: innerData[i].isActive,
                            checked: false,
                        };
                        finalData.push(tempData);
                    }
                    dispatch({
                        type: Utils.ActionName.GET_DASHBOARD_FILTER,
                        payload: { areaFilterData: finalData },
                    });
                    dispatch(lineFilter());
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const lineFilter = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }

        let parameters = '';
        Utils.api.getApiCall(
            Utils.endPoints.analyticsLines,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    let finalData: any = [];
                    for (let i = 0; i < innerData.length; i++) {
                        let tempData = {
                            areaId: innerData[i].areaId,
                            id: innerData[i].id,
                            mfgLine: innerData[i].mfgLine,
                            isActive: innerData[i].isActive,

                            checked: false,
                        };
                        finalData.push(tempData);
                    }
                    dispatch({
                        type: Utils.ActionName.GET_DASHBOARD_FILTER,
                        payload: { lineFilterData: finalData },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};
