import * as React from 'react';
import { LBRTableContainer } from '../../styles';
import Header from './Components/Header';
import { Table, TableContainer } from '@material-ui/core';
import TableHeader from './Components/TableHeader';
import TableBody from './Components/TableBody';
import TableFooter from './Components/TableFooter';
import { DataTableProp } from '../../Interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { callUpdateReducer, getLbrRows } from '../../Redux/actions';
import { UPDATE_QUERY_ROWS } from '../../Redux/constant';

interface LBRHistoryTableProp {
    selectedLabels: string[],
    onDeselectedLabels: (index: any) => void
}

const LBRHistoryTable:React.FC<LBRHistoryTableProp> = ({ selectedLabels, onDeselectedLabels }) => {
    
    const [selected, setSelected] = React.useState<number[]>([]);
    const dispatch = useDispatch();
    const {
        isLoadingRows,
        queryLbrRows: {
            orderBy,
            orderDirection,
            limit,
            count,
            page,
        },
    } = useSelector((state: any) => state.lbrGlobal);
    const { data: rows } = useSelector((state: any) => state.lbrRows);

    const handleChangeOrdering = (orderBy: keyof DataTableProp) => {
        dispatch(callUpdateReducer(UPDATE_QUERY_ROWS, {
            orderBy,
            orderDirection: orderDirection === 'asc' 
                ? 'desc' 
                : 'asc',
        }));
        reFetchingApi();
    };

    const handleChangePage = (page: number) => {
        dispatch(
            callUpdateReducer(
                UPDATE_QUERY_ROWS,
                { page: page + 1 },
            ),
        );
        reFetchingApi();
    }

    const handleChangeLimit = ({ target: { value: limit }}: any) => {
        dispatch(
            callUpdateReducer(
                UPDATE_QUERY_ROWS,
                { limit }
            )
        );
        reFetchingApi();
    }

    const handleOnselected = (id: number) => {
        if(selected.includes(id)){
            setSelected(selected.filter((selectId: number) => selectId != id));
            return false;
        }
        setSelected([...selected, id]);
    };

    const handleOnSelectAllClick = () => {
        if(rows.length === selected.length){
            setSelected([]);
            return false;
        };
        setSelected(rows.map(({ id }: any) => id));
    };
    
    const reFetchingApi = React.useCallback(() => {
        dispatch(getLbrRows());
    }, [limit, page, orderBy, orderDirection]);

    return (
        <LBRTableContainer boxShadow={3}>
            <Header
                onDeselectedLabels={onDeselectedLabels}
                selectedLabels={selectedLabels} 
                selected={selected} />
            <TableContainer>
            <Table size='small' style={{ position: 'relative', }}>
                <TableHeader
                    rowCount={rows?.length || 0}
                    numSelected={selected.length}
                    onChangeOrder={handleChangeOrdering}
                    onSelectAllClick={handleOnSelectAllClick}
                    orderBy={orderBy} 
                    orderDirection={orderDirection} />
                <TableBody
                    isLoadingRows={isLoadingRows}
                    onSelected={handleOnselected} 
                    selected={selected} 
                    rows={rows || []} 
                />
                <TableFooter
                    rowsPerPage={limit}
                    count={count}
                    page={(page - 1)} 
                    onChangePage={handleChangePage} 
                    onChangeRowsPerPage={handleChangeLimit} 
                />
            </Table>
            </TableContainer>
        </LBRTableContainer>
    )
}

export default LBRHistoryTable;