import React from 'react';
import { makeStyles, createStyles, Theme, Button } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        cancelButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#515151',
            opacity: 1,
            height: '36px',
        },
    }),
);
interface Props {
    handleClose?: any;
    disable?: boolean;
    text?: string;
}

const Index = ({ handleClose, disabled = false, text = '' }: any) => {
    const classes = styles();
    const { t } = useTranslation();
    return (
        <Button
            variant="outlined"
            size="large"
            className={classes.cancelButton}
            onClick={handleClose}
            disabled={disabled}
        >
            {text ? text : t('CANCEL')}
        </Button>
    );
};
export default Index;