import React, { useEffect, useRef, useState } from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    TextField,
    InputAdornment,
    Button,
    Avatar,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    TablePagination,
    Menu,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import LocalImages from '../../utils/images';
import DeleteIcon from '@material-ui/icons/Delete';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { deleteNPT, getAllNptTypes, getNpt } from './action';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModel } from '../../model';
import moment from 'moment';
import Utils from '../../utils';
import SimpleModal from '../../components/modal';
import AddNPT from './addNpt';
import { getMfgLinesList, getShiftsList } from '../admin/action';
import { getNptCategories } from '../admin/manageNpt/action';
import EditNPT from './editNpt';
import DeleteModal from '../../components/modal/deleteModal';
import NPTFilter from '../../components/filters/npt';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            width: '95.5%',
            margin: '16px auto',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '18px 1.2%',
        },
        textStyl: {
            fontSize: '18px',
            textAlign: 'center',
        },
        heading: {
            font: 'normal normal normal 20px/24px Roboto',
            letterSpacing: '0.07px',
            color: '#515151',
            opacity: 1,
            marginBottom: '18px',
        },
        searchInput: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            width: '300px',
            '& .MuiInputBase-root': {
                height: '36px',
                font: 'normal normal normal 14px/20px Roboto',
                letterSpacing: '0.05px',
                color: '#515151',
                opacity: 1,
            },
        },
        styleOperations: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '18px',
        },
        leftStyleOperations: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
        },
        addButton: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            opacity: 1,
            margin: '0 15px',
            border: '1px solid #1E88E5',
            font: 'normal normal normal 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#1E88E5',
            textTransform: 'uppercase',
            height: '36px',
        },
        filterButton: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            opacity: 1,
            border: '1px solid #1E88E5',
            font: 'normal normal normal 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#1E88E5',
            textTransform: 'uppercase',
            height: '36px',
        },
        categories: {
            padding: '11px',
            textTransform: 'uppercase',
            backgroundColor: '#F7F8FA',
            '& th': {
                font: 'normal normal bold 14px/21px Roboto',
                letterSpacing: '0.08px',
                color: '#515151',
                textTransform: 'uppercase',
                opacity: 1,
                marginLeft: '0.5%',
                verticalAlign: 'top',
                '& > span': {
                    cursor: 'pointer',
                },
                [theme.breakpoints.up(1150)]: {
                    whiteSpace: 'nowrap',
                },
            },
            '&>th:nth-child(2)': {
                whiteSpace: 'nowrap',
            },
            '&>th:nth-child(12)': {
                whiteSpace: 'nowrap',
            },
        },
        searchIcon: {
            cursor: 'pointer',
        },
        filterCount: {
            minWidth: '24px',
            minHeight: '24px',
            width: '1em',
            height: '1em',
            textAlign: 'center',
            font: 'normal normal normal 14px/20px Roboto!important',
            letterSpacing: '0.05px',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            opacity: 1,
            backgroundColor: '#1E88E5',
        },
        categoriesItems: {
            backgroundColor: 'var(--white)',
            alignItems: 'center',
            minHeight: '32px',
            padding: '6px 11px',
            borderBottom: '1px solid #E0E0E0',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#f2f2f2',
            },
            '& td': {
                font: 'normal normal normal 14px/14px Roboto',
                letterSpacing: '0px',
                color: '#515151',
                textTransform: 'uppercase',
                opacity: 1,
                marginLeft: '0.5%',
                verticalAlign: 'top',
            },
            '&>td:nth-child(12)': {
                whiteSpace: 'nowrap',
            },
        },
        dwnIcnStyl: {
            cursor: 'pointer',
            width: '13px',
            verticalAlign: 'middle',
            transition: '1s all',
        },
        headStyl: {
            marginTop: '25px',
            marginBottom: '14px',
            textAlign: 'center',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#707070',
            opacity: 1,
        },
        paginationContainer: {
            marginTop: '40px',
            backgroundColor: '#F7F8FA',
            '& .MuiTypography-root': {
                textAlign: 'left',
                font: 'normal normal normal 14px/14px Roboto',
                letterSpacing: '0px',
                color: '#515151',
                opacity: 1,
            },
        },
        filterMenu: {
            '& .MuiList-root': {
                width: '194px',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: '0px 0px 6px #00000029',
                opacity: 1,
            },
        },
        rotateIcon: {
            transform: 'rotate(180deg)',
            transition: '1s all',
        },
        clickable: {
            cursor: 'pointer',
        },
    }),
);

export default function NPT() {
    const classes = styles();
    const { t } = useTranslation();
    const { nptCategoriesData } = useSelector((state: ReducersModel) => state.nptCategoryListReducer);
    const { nptData, count, page, limit, filterLineIds, filterNptTypeIds } = useSelector(
        (state: ReducersModel) => state.nptListReducer,
    );
    const { mfgLines } = useSelector((state: ReducersModel) => state.mfgLinesReducer);
    const { shifts } = useSelector((state: ReducersModel) => state.shiftsReducer);
    const { allNptTypeData } = useSelector((state: ReducersModel) => state.allNptTypeListReducer);

    const [nptIdToDelete, setNptIdToDelete] = useState(null);
    const dispatch = useDispatch();

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        dispatch({
            type: Utils.ActionName.GET_NPT,
            payload: {
                page: newPage + 1,
            },
        });
        dispatch(getNpt());
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({
            type: Utils.ActionName.GET_NPT,
            payload: {
                page: 1,
                limit: event.target.value,
            },
        });

        dispatch(getNpt());
    };
    //add npt
    const [openAddNpt, setOpenAddNpt] = React.useState(false);

    const handleOpenAddNpt = () => {
        setOpenAddNpt(true);
    };

    const handleCloseAddNpt = () => {
        setOpenAddNpt(false);
    };

    // edit npt
    const [openEditNpt, setOpenEditNpt] = React.useState(false);

    const handleOpenEditNpt = () => {
        setOpenEditNpt(true);
    };

    const handleCloseEditNpt = () => {
        setOpenEditNpt(false);
    };

    useEffect(() => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        dispatch({
            type: Utils.ActionName.GET_NPT,
            payload: {
                searchNpt: '',
                page: 1,
            },
        });
        dispatch(getNpt());
        dispatch(getShiftsList());
        dispatch(getMfgLinesList());
        dispatch(getNptCategories(1000));
        dispatch(getAllNptTypes());
    }, [dispatch]);

    const [isDateSort, setIsDateSort] = React.useState(false);

    const handleDateSort = (key: any) => {
        setIsDateSort(!isDateSort);
        dispatch({
            type: Utils.ActionName.GET_NPT,
            payload: {
                page: 1,
            },
        });
        if (isDateSort) {
            dispatch({
                type: Utils.ActionName.GET_NPT,
                payload: {
                    order: 'ASC',
                    sortBy: key,
                },
            });
        } else {
            dispatch({
                type: Utils.ActionName.GET_NPT,
                payload: {
                    order: 'DESC',
                    sortBy: key,
                },
            });
        }
        dispatch(getNpt());
    };

    const nptTable = () => {
        return (
            <>
                <Table stickyHeader={true} aria-label="sticky table">
                    <TableHead style={{ borderTop: '1px solid #e2dcdc' }}>
                        <TableRow className={classes.categories}>
                            <TableCell>#</TableCell>
                            <TableCell>
                                <span onClick={() => handleDateSort('tranDt')}>
                                    {t('Date')}
                                    <ArrowDownwardIcon
                                        className={
                                            isDateSort
                                                ? classes.dwnIcnStyl
                                                : `${classes.rotateIcon} ${classes.dwnIcnStyl}`
                                        }
                                    />
                                </span>
                            </TableCell>
                            <TableCell>{t('START')}</TableCell>
                            <TableCell>{t('END')}</TableCell>
                            <TableCell>{t('NPTMINS')}</TableCell>
                            <TableCell>{t('MPWR')}</TableCell>
                            <TableCell>{t('TTLNPTMINS')}</TableCell>
                            <TableCell>{t('Line')}</TableCell>
                            <TableCell>{t('NPTType')}</TableCell>
                            <TableCell>{t('NPTCAT')}</TableCell>
                            <TableCell>{t('DATEMODIFIED')}</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {nptData && nptData.length > 0 ? (
                            nptData.map((row: any, index: any) => (
                                <>
                                    <TableRow className={classes.categoriesItems} key={row.id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                            {moment(`${row.tranDt}T${row.tranTime}Z`).format('YYYY-MM-DD')}
                                        </TableCell>
                                        <TableCell>
                                            {row.tranTime
                                                ? moment(`${moment().format('YYYY-MM-DD')}T${row.tranTime}Z`).format(
                                                    'HH:mm',
                                                )
                                                : '-'}
                                        </TableCell>
                                        <TableCell>
                                            {row.tranTime
                                                ? moment(`${moment().format('YYYY-MM-DD')}T${row.endTime}Z`).format(
                                                    'HH:mm',
                                                )
                                                : '-'}
                                        </TableCell>
                                        <TableCell>{row.duration}</TableCell>
                                        <TableCell>{row.manpower}</TableCell>
                                        <TableCell>{row.ttl_min}</TableCell>
                                        <TableCell>{row.mfgLineData ? row.mfgLineData.mfgLine : '-'}</TableCell>
                                        <TableCell>{row.nptTypeData ? row.nptTypeData.nptType : '-'}</TableCell>
                                        <TableCell>
                                            {row.nptTypeData.categoryData ? row.nptTypeData.categoryData.nptCat : '-'}
                                        </TableCell>
                                        <TableCell>
                                            {row.updatedDt !== null ? moment(row.updatedDt).format('YYYY-MM-DD') : '-'}
                                        </TableCell>
                                        <TableCell align="right">
                                            <EditIcon
                                                color={'primary'}
                                                onClick={() => {
                                                    const index = nptCategoriesData.findIndex(
                                                        (x: any) => x.id === row.nptTypeData.nptCatId,
                                                    );
                                                    dispatch({
                                                        type: Utils.ActionName.GET_NPT,
                                                        payload: {
                                                            selectedEditNptId: row.id,
                                                            selectedEditDate: row.tranDt,
                                                            selectedEditStartTime: row.tranTime,
                                                            selectedEditEndTime: row.endTime,
                                                            selectedEditManpower: row.manpower,
                                                            selectedEditLineId: row.mfgLineId,
                                                            selectedEditNptCategoryId: row.nptTypeData.nptCatId,
                                                            selectedEditNptTypeId: row.nptTypeId,
                                                            selectedEditNptTypeData:
                                                                index >= 0 ? nptCategoriesData[index].npttypes : [],
                                                        },
                                                    });
                                                    handleOpenEditNpt();
                                                }}
                                            />
                                            <DeleteIcon
                                                color={'primary'}
                                                onClick={() => {
                                                    handleOpenNptDelete();
                                                    setNptIdToDelete(row.id);
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell className={classes.headStyl} colSpan={13}>
                                    {t('NoRecords')}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <div className={classes.paginationContainer}>
                    <TablePagination
                        component="div"
                        count={count}
                        page={page - 1}
                        onPageChange={handleChangePage}
                        rowsPerPage={limit}
                        labelDisplayedRows={({ from, to, count, page }) => `  ${from}-${to} of  ${count} `}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </>
        );
    };
    const debounceCount = useRef(0);
    const api = () =>
        new Promise((res) => {
            setTimeout(() => {
                res(1);
            }, 500);
        });

    const handleSearch = (event: any) => {
        dispatch({
            type: Utils.ActionName.GET_NPT,
            payload: {
                searchNpt: event.target.value,
                page: 1,
            },
        });
        debounceCount.current += 1;
        const localDebounceCount = debounceCount.current;
        api().then((d) => {
            if (localDebounceCount !== debounceCount.current) {
                return;
            }
            dispatch(getNpt());
        });
    };

    // for filter

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    // for delete npt category
    const [openNptDelete, setOpenNptDelete] = React.useState(false);
    const handleOpenNptDelete = () => {
        setOpenNptDelete(true);
    };
    const handleCloseNptDelete = () => {
        setOpenNptDelete(false);
    };

    const getFilterCount = () => {
        return +filterLineIds.length + filterNptTypeIds.length;
    };

    return (
        <>
            <Helmet>
                <title>{t('NPTList')}</title>
            </Helmet>
            <div className={classes.mainContainer}>
                <div className={classes.heading}>{t('NPTList')}</div>
                <div className={classes.styleOperations}>
                    <div className={classes.leftStyleOperations}>
                        <TextField
                            className={classes.searchInput}
                            variant="outlined"
                            placeholder={t('Search')}
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img
                                            alt={t('Search')}
                                            src={LocalImages.SEARCH_ICON}
                                            className={classes.searchIcon}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<AddIcon />}
                            className={classes.addButton}
                            onClick={() => {
                                handleOpenAddNpt();
                            }}
                        >
                            {t('Add')}
                        </Button>
                    </div>
                    <div>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<FormatListBulletedIcon />}
                            className={classes.filterButton}
                            endIcon={<Avatar className={classes.filterCount}>{getFilterCount()}</Avatar>}
                            onClick={handleOpenFilter}
                        >
                            {t('Filter')}
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseFilter}
                            className={classes.filterMenu}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            getContentAnchorEl={null}
                        >
                            <NPTFilter mfgLines={mfgLines} shifts={shifts} allNptTypes={allNptTypeData} />
                        </Menu>
                    </div>
                </div>
                {nptTable()}
                <SimpleModal
                    handleOpen={handleOpenAddNpt}
                    isOpen={openAddNpt}
                    body={<AddNPT handleClose={handleCloseAddNpt} />}
                />
                <SimpleModal
                    handleOpen={handleOpenEditNpt}
                    isOpen={openEditNpt}
                    body={<EditNPT handleClose={handleCloseEditNpt} />}
                />
                <SimpleModal
                    handleOpen={handleOpenNptDelete}
                    isOpen={openNptDelete}
                    body={
                        <DeleteModal
                            title={`${t('DeleteNPT')}?`}
                            onDelete={() => {
                                dispatch(deleteNPT(nptIdToDelete, handleCloseNptDelete));
                            }}
                            handleClose={handleCloseNptDelete}
                        />
                    }
                />
            </div>
        </>
    );
}
