import axios, { AxiosRequestConfig } from 'axios';

interface AjaxProps extends AxiosRequestConfig {
  headers?: object;
}

/**
 * Gets the header properties to be added into axios
 * @param {AjaxProps} props Header information object
 * @return object
 */
export function getHeaders(props: AjaxProps): object {
  const userToken = localStorage.getItem('accessToken');
  return {
    ...props.headers,
    Authorization: `Bearer ${userToken}`,
    'X-Requested-With': 'XMLHttpRequest',
  };
}

/**
 * Gets the default properties to be passed
 * to the axios properties
 * @param {AjaxProps} props Properties passed to ajax
 * @return object
 */
export function getProps(props: AjaxProps = {}): AxiosRequestConfig {
  return {
    method: 'GET',
    responseType: 'json',
    ...props,
  };
}

/**
 * Sends an AJAX request using axios
 * @param {string} url URL to be requested to
 * @param {AjaxProps} props Properties of Axios
 * @return promise
 */
const ajax = async (url: string, pProps: AjaxProps = {}): Promise<any> => {
  const props: AxiosRequestConfig = {
    ...getProps(pProps),
    url,
    headers: getHeaders(pProps),
  };

  return axios(props).then((data) => data);
}

export default ajax;


