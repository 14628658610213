import ajax from "../../../../../utils/ajax";
import { Dispatch } from "redux";
import constant from "../../../../../utils/constants";
import endPoints from "../../../../../utils/endPoints";
import {
    UPDATE_DATA_GRAPH,
    UPDATE_SHIFTS,
    UPDATE_SHIFT_ID,
    REQUEST_API_ROWS,
    REQUEST_API_SUCCESS_ROWS,
    REQUEST_API_GRAPH,
    REQUEST_API_SUCCESS_GRAPH,
    REQUEST_API_SHIFTS,
    REQUEST_API_SUCCESS_SHIFTS,
    REQUEST_API_ERROR_SHIFTS,
    UPDATE_MULTIPLE_VALUES,
    GET_ROWS,
    UPDATE_QUERY_ROWS,
} from "./constant";
import { 
    distinceByCreatedDt,
    formatDate, 
    handleSortBy, 
    normalizeDataChart,
 } from "../helpers";

const baseUrl = constant.apiUrl;

export const getFactoryShifts = (
    currentShiftId: number,
    lineSetupId: number,
    uid: string,
) => async (dispatch: Function, getState: Function) => {
    const { shifts: existingShifts } = getState().lbrGlobal;
    dispatch(callReducer(REQUEST_API_SHIFTS));
    dispatch(callUpdateReducer(UPDATE_MULTIPLE_VALUES, {
        lineSetupId,
        uid,
    }));
    try {
        if(existingShifts.length === 0){
            const { data: response } = await ajax(`${baseUrl}${endPoints.shifts}`);
            const { data: { data: shifts } } = response;
            dispatch(callUpdateReducer(
                UPDATE_SHIFTS, 
                shifts.map(({ id, shift }: any) => ({ id, name: shift })),
            ));
            dispatch(callUpdateReducer(UPDATE_SHIFT_ID, currentShiftId));
        }
        dispatch(callReducer(REQUEST_API_SUCCESS_SHIFTS));
    } catch (error) {
        dispatch(callReducer(REQUEST_API_SUCCESS_SHIFTS));
        dispatch(callReducer(REQUEST_API_ERROR_SHIFTS));
    }
}

export const getLbrGraph = () => async (dispatch: Function, getState: Function) => {
    dispatch(callReducer(REQUEST_API_GRAPH));
    try {
        const { dateRange, shiftId, uid } = getState().lbrGlobal;
        const { query:{ orderBy, viewBy }} = getState().lbrGraph;
        const { ticks } = dateRange;
        const query = {
            shiftId,
            uid,
            dateFrom: formatDate(dateRange.startDate, 'Y-MM-DD'),
            dateTo: formatDate(dateRange.endDate, 'Y-MM-DD'),
        }
        const pathUrl = `${endPoints.lbrHistoryGraph}?${new URLSearchParams(query)}`;
        const { data: response } = await ajax(baseUrl + pathUrl);
        const { data } = response;
        const dataSortBy = handleSortBy(
            data, 
            orderBy === 1
                ? 'desc'
                : 'asc'
        );
        const dataDistintByCreatedDt = distinceByCreatedDt(dataSortBy);
        const finalResult = normalizeDataChart(ticks, viewBy['key'], dataDistintByCreatedDt);
        dispatch(callUpdateReducer(UPDATE_DATA_GRAPH, finalResult));
        dispatch(callReducer(REQUEST_API_SUCCESS_GRAPH));
    } catch (error) {
        dispatch(callReducer(REQUEST_API_SUCCESS_GRAPH));
    }
}

export const getLbrRows = () => {
    return async (dispatch: Dispatch, getState: Function) => {
        dispatch(callReducer(REQUEST_API_ROWS));
        try {
            const { 
                dateRange, 
                shiftId,
                uid,
                lineSetupId,
                queryLbrRows,
            } = getState().lbrGlobal;
            const query = {
                ...queryLbrRows,
                shiftId,
                uid,
                lineSetupId,
                dateFrom: formatDate(dateRange.startDate, 'Y-MM-DD'),
                dateTo: formatDate(dateRange.endDate, 'Y-MM-DD'),
            }
            const pathUrl = `${endPoints.lbrHistory}?${new URLSearchParams(query)}`;
            const { data: response } = await ajax(baseUrl + pathUrl);
            const { data:{ data, count } } = response;
            dispatch(callUpdateReducer(GET_ROWS, data));
            dispatch(callUpdateReducer(UPDATE_QUERY_ROWS, { count }));
            dispatch(callReducer(REQUEST_API_SUCCESS_ROWS));
        } catch (error) {
            dispatch(callReducer(REQUEST_API_SUCCESS_ROWS));
            console.error(error);
        }
    }
}

export const callReducer = (type: any) => ({ type });
export const callUpdateReducer = (type: any, payload: any) => ({ type, payload });
