import React, { useEffect } from 'react';
import { makeStyles, createStyles, Theme, Tabs, Tab } from '@material-ui/core';
import OperationCode from './operationCode';
import { useDispatch } from 'react-redux';
import Utils from '../../../utils';
import { getFactoriesList } from '../../manageStyle/action';
import Settings from './settings';
import Translation from './translation';
import { useTranslation } from 'react-i18next';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            width: '95.5%',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '6px',
            backgroundColor: 'var(--white)',
            padding: '18px 25px',
        },
        textStyl: {
            fontSize: '18px',
            textAlign: 'center',
        },
        heading: {
            font: 'normal normal normal 20px/24px Roboto',
            letterSpacing: '0.07px',
            color: '#515151',
            opacity: 1,
            marginBottom: '18px',
        },
        tabDesign: {
            margin: '0 8px',
        },
        tabsContainer: {
            display: 'flex',
            borderBottom: '3px solid #f0f0f0',
            '& .MuiButtonBase-root': {
                borderBottom: '4px solid rgb(22, 137, 250, 0.2)',
            },
            '& .MuiTabs-indicator': {
                height: '3px',
            },
            '& .MuiTab-wrapper': {
                font: 'normal normal normal 16px/19px Roboto',
                letterSpacing: '0.06px',
                opacity: 1,
            },
            '& .MuiTab-root': { textTransform: 'unset' },
        },
        BgContainer: {
            width: '99%',
            margin: '0 auto',
        },
    }),
);

export default function ProductSetting() {
    const classes = styles();
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getFactoriesList());
        dispatch({
            type: Utils.ActionName.UPDATE_OPERRATION_CODE,
            payload: { filterFactoryIds: [] },
        });
    }, [dispatch]);

    return (
        <>
            <div className={classes.mainContainer}>             
                <div className={classes.heading}>{t('ProductSettings')}</div>
                <div className={classes.tabsContainer}>
                    <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
                        <Tab label={t('Settings')} />
                        <Tab className={classes.tabDesign} label={t('MasterDataManagement')} />
                        <Tab className={classes.tabDesign} label={t('MasterDataTranslation')} />
                    </Tabs>
                </div>
            </div>
            <div className={classes.BgContainer}>
                {value === 0 ? <Settings /> : value === 1 ? <OperationCode isProductSetting={true} /> : <Translation />}
            </div>
        </>
    );
}
