import React, { useEffect } from 'react';
import { makeStyles, createStyles, Theme, TextField, Button, Typography, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AddRoundIcon from '@material-ui/icons/AddRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { getProductSettings, updateProductSettings } from './action';
import { ReducersModel } from '../../../model';
import Utils from '../../../utils';
import { Helmet } from 'react-helmet';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        settings: {
            minWidth: '360px',
            maxWidth: '400px',
            margin: '24px auto 62px',
            '& > div': {
                padding: '45px 30px',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: '0px 3px 6px #00000029',
                margin: '8px',
                font: 'normal normal normal 20px/20px Roboto',
                letterSpacing: '0.07px',
                color: '#515151',
            },
        },
        controls: {
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
        },
        center: {
            alignItems: 'center',
        },
        perControl: {
            position: 'relative',
            '& > span': {
                position: 'absolute',
                bottom: '5px',
            },
        },
        controlContainer: {
            marginTop: '36px',
        },
        skillDesc: {
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
        },
        skillMatrixInner: {
            display: 'flex',
            alignItems: 'flex-end',
            '& > p': {
                font: 'normal normal normal 14px/21px Roboto',
                letterSpacing: '0.08px',
                margin: '0 10px',
            },
        },
        notifications: {
            paddingLeft: '24px',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '12px',
            marginRight: '-12px',
        },
        addColor: {
            color: '#4087de',
            fontSize: '30px',
            cursor: 'pointer',
        },
        deleteColor: {
            color: '#c33e38',
            fontSize: '30px',
            cursor: 'pointer',
        },
        heading: {
            marginBottom: '-25px',
        },
        saveContainer: {
            backgroundColor: 'white',
            padding: '10px 0',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '22px',
            position: 'fixed',
            bottom: '0',
            width: '100%',
        },
        cancelButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#515151',
            opacity: 1,
            height: '36px',
        },
        saveButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#FFFFFF',
            opacity: 1,
            height: '36px',
        },
    }),
);

export default function Settings() {
    const classes = styles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {
        editEffEmails,
        editEffValue,
        editLbrEmails,
        editLbrValue,
        editNptEmails,
        editNptValue,
        editTimeStudy,
        editSkillMatrix,
        cancelActive,
        skillmatrixmode,
    } = useSelector((state: ReducersModel) => state.productSettingReducer);

    useEffect(() => {
        dispatch(getProductSettings(t));
    }, [dispatch, t]);

    const validateEmail = (email: string) => {
        //eslint-disable-next-line
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return re.test(email);
    };

    const addEmailAddress = (type: string) => {
        switch (type) {
            case 'eff':
                const tempEffArr = [...editEffEmails];
                const tempEffArrLen = tempEffArr.length;
                if (tempEffArrLen === 0) {
                    tempEffArr.push('');
                    dispatch({
                        type: Utils.ActionName.GET_PRODUCT_SETTINGS,
                        payload: {
                            editEffEmails: tempEffArr,
                            cancelActive: true,
                        },
                    });
                    break;
                }
                if (validateEmail(tempEffArr[tempEffArrLen - 1])) {
                    tempEffArr.push('');
                    dispatch({
                        type: Utils.ActionName.GET_PRODUCT_SETTINGS,
                        payload: {
                            editEffEmails: tempEffArr,
                            cancelActive: true,
                        },
                    });
                }
                break;
            case 'lbr':
                const tempLbrArr = [...editLbrEmails];
                const tempLbrArrLen = tempLbrArr.length;
                if (tempLbrArrLen === 0) {
                    tempLbrArr.push('');
                    dispatch({
                        type: Utils.ActionName.GET_PRODUCT_SETTINGS,
                        payload: {
                            editLbrEmails: tempLbrArr,
                            cancelActive: true,
                        },
                    });
                }
                if (validateEmail(tempLbrArr[tempLbrArrLen - 1])) {
                    tempLbrArr.push('');
                    dispatch({
                        type: Utils.ActionName.GET_PRODUCT_SETTINGS,
                        payload: {
                            editLbrEmails: tempLbrArr,
                            cancelActive: true,
                        },
                    });
                }
                break;
            case 'npt':
                const tempNptArr = [...editNptEmails];
                const tempNptArrLen = tempNptArr.length;
                if (tempNptArrLen === 0) {
                    tempNptArr.push('');
                    dispatch({
                        type: Utils.ActionName.GET_PRODUCT_SETTINGS,
                        payload: {
                            editNptEmails: tempNptArr,
                            cancelActive: true,
                        },
                    });
                }
                if (validateEmail(tempNptArr[tempNptArrLen - 1])) {
                    tempNptArr.push('');
                    dispatch({
                        type: Utils.ActionName.GET_PRODUCT_SETTINGS,
                        payload: {
                            editNptEmails: tempNptArr,
                            cancelActive: true,
                        },
                    });
                }
                break;
            default:
                break;
        }
    };

    const deleteEmailAddress = (type: string, location: any) => {
        switch (type) {
            case 'eff':
                const tempEffArr = [...editEffEmails];
                tempEffArr.splice(location, 1);
                dispatch({
                    type: Utils.ActionName.GET_PRODUCT_SETTINGS,
                    payload: {
                        editEffEmails: tempEffArr,
                        cancelActive: true,
                    },
                });
                break;
            case 'lbr':
                const tempLbrArr = [...editLbrEmails];
                tempLbrArr.splice(location, 1);
                dispatch({
                    type: Utils.ActionName.GET_PRODUCT_SETTINGS,
                    payload: {
                        editLbrEmails: tempLbrArr,
                        cancelActive: true,
                    },
                });
                break;
            case 'npt':
                const tempNptArr = [...editNptEmails];
                tempNptArr.splice(location, 1);
                dispatch({
                    type: Utils.ActionName.GET_PRODUCT_SETTINGS,
                    payload: {
                        editNptEmails: tempNptArr,
                        cancelActive: true,
                    },
                });
                break;
            default:
                break;
        }
    };

    const updateEmails = (type: string, event: any, index: any) => {
        switch (type) {
            case 'eff':
                const tempEffArr = [...editEffEmails];
                tempEffArr[index] = event.target.value;
                dispatch({
                    type: Utils.ActionName.GET_PRODUCT_SETTINGS,
                    payload: {
                        editEffEmails: tempEffArr,
                        cancelActive: true,
                    },
                });
                break;
            case 'lbr':
                const tempLbrArr = [...editLbrEmails];
                tempLbrArr[index] = event.target.value;
                dispatch({
                    type: Utils.ActionName.GET_PRODUCT_SETTINGS,
                    payload: {
                        editLbrEmails: tempLbrArr,
                        cancelActive: true,
                    },
                });
                break;
            case 'npt':
                const tempNptArr = [...editNptEmails];
                tempNptArr[index] = event.target.value;
                dispatch({
                    type: Utils.ActionName.GET_PRODUCT_SETTINGS,
                    payload: {
                        editNptEmails: tempNptArr,
                        cancelActive: true,
                    },
                });
                break;
            default:
                break;
        }
    };

    const handleClose = () => {
        dispatch(getProductSettings(t));
        dispatch({
            type: Utils.ActionName.GET_PRODUCT_SETTINGS,
            payload: {
                cancelActive: false,
            },
        });
    };

    const handleSave = () => {
        const tempEditEffEmails = [...editEffEmails];
        const tempEditLbrEmails = [...editLbrEmails];
        const tempEditNptEmails = [...editNptEmails];
        if (
            tempEditEffEmails[tempEditEffEmails.length - 1] === '' ||
            tempEditLbrEmails[tempEditLbrEmails.length - 1] === '' ||
            tempEditNptEmails[tempEditNptEmails.length - 1] === ''
        ) {
            if (tempEditEffEmails[tempEditEffEmails.length - 1] === '') {
                const tempEffEmails = tempEditEffEmails.pop();
                dispatch({
                    type: Utils.ActionName.GET_PRODUCT_SETTINGS,
                    payload: {
                        editEffEmails: tempEffEmails.length > 0 ? tempEffEmails : [],
                    },
                });
            }
            if (tempEditLbrEmails[tempEditLbrEmails.length - 1] === '') {
                const tempLbrEmails = tempEditLbrEmails.pop();
                dispatch({
                    type: Utils.ActionName.GET_PRODUCT_SETTINGS,
                    payload: {
                        editLbrEmails: tempLbrEmails.length > 0 ? tempLbrEmails : [],
                    },
                });
            }
            if (tempEditNptEmails[tempEditNptEmails.length - 1] === '') {
                const tempNptEmails = tempEditNptEmails.pop();
                dispatch({
                    type: Utils.ActionName.GET_PRODUCT_SETTINGS,
                    payload: {
                        editNptEmails: tempNptEmails.length > 0 ? tempNptEmails : [],
                    },
                });
            }
            dispatch(updateProductSettings(t));
        } else {
            dispatch(updateProductSettings(t));
        }
        dispatch({
            type: Utils.ActionName.GET_PRODUCT_SETTINGS,
            payload: {
                cancelActive: false,
            },
        });
    };

    const handleRadio = (event: any) => {
        dispatch({
            type: Utils.ActionName.GET_PRODUCT_SETTINGS,
            payload: {
                skillmatrixmode: parseInt(event.target.value),
                cancelActive: true,
            },
        });
    };

    return (
        <React.Fragment>
            <div className={classes.settings}>
                <Helmet>
                    <title>{t('Settings')}</title>
                </Helmet>
                <div>
                    <div className={classes.heading}>{t('Targets')}</div>
                    <div className={classes.controlContainer}>
                        <div className={classes.controls}>
                            <span>{t('EFFGreaterThan')}</span>
                            <div className={classes.perControl} style={{ width: '45px' }}>
                                <TextField
                                    label=""
                                    value={editEffValue}
                                    onChange={(event: any) => {
                                        dispatch({
                                            type: Utils.ActionName.GET_PRODUCT_SETTINGS,
                                            payload: {
                                                editEffValue: event.target.value,
                                                cancelActive: true,
                                            },
                                        });
                                    }}
                                />
                                <span>%</span>
                            </div>
                        </div>
                        <div className={classes.notifications}>
                            <span>{t('Notification')}</span>
                            <AddRoundIcon className={classes.addColor} onClick={() => addEmailAddress('eff')} />
                        </div>

                        {editEffEmails &&
                            editEffEmails.length > 0 &&
                            editEffEmails.map((item: string, index: any) => {
                                return (
                                    <div className={classes.notifications} key={index}>
                                        <TextField
                                            label=""
                                            placeholder={t('EmailAddress')}
                                            value={item}
                                            onChange={(event) => updateEmails('eff', event, index)}
                                            fullWidth
                                        />
                                        <CloseRoundedIcon
                                            className={classes.deleteColor}
                                            onClick={() => deleteEmailAddress('eff', index)}
                                        />
                                    </div>
                                );
                            })}
                    </div>

                    <div className={classes.controlContainer}>
                        <div className={classes.controls}>
                            <span>{t('LBRGreaterThan')}</span>
                            <div className={classes.perControl} style={{ width: '45px' }}>
                                <TextField
                                    label=""
                                    value={editLbrValue}
                                    onChange={(event: any) => {
                                        dispatch({
                                            type: Utils.ActionName.GET_PRODUCT_SETTINGS,
                                            payload: {
                                                editLbrValue: event.target.value,
                                                cancelActive: true,
                                            },
                                        });
                                    }}
                                />
                                <span>%</span>
                            </div>
                        </div>
                        <div className={classes.notifications}>
                            <span>{t('Notification')}</span>
                            <AddRoundIcon className={classes.addColor} onClick={() => addEmailAddress('lbr')} />
                        </div>

                        {editLbrEmails &&
                            editLbrEmails.length > 0 &&
                            editLbrEmails.map((item: string, index: any) => {
                                return (
                                    <div className={classes.notifications} key={index}>
                                        <TextField
                                            label=""
                                            type="email"
                                            required
                                            placeholder={t('EmailAddress')}
                                            value={item}
                                            onChange={(event) => updateEmails('lbr', event, index)}
                                            fullWidth
                                        />
                                        <CloseRoundedIcon
                                            className={classes.deleteColor}
                                            onClick={() => deleteEmailAddress('lbr', index)}
                                        />
                                    </div>
                                );
                            })}
                    </div>

                    <div className={classes.controlContainer}>
                        <div className={classes.controls}>
                            <span>{t('NPTLessThan')}</span>
                            <div className={classes.perControl} style={{ width: '45px' }}>
                                <TextField
                                    label=""
                                    value={editNptValue}
                                    onChange={(event: any) => {
                                        dispatch({
                                            type: Utils.ActionName.GET_PRODUCT_SETTINGS,
                                            payload: {
                                                editNptValue: event.target.value,
                                                cancelActive: true,
                                            },
                                        });
                                    }}
                                />
                                <span>%</span>
                            </div>
                        </div>
                        <div className={classes.notifications}>
                            <span>{t('Notification')}</span>
                            <AddRoundIcon className={classes.addColor} onClick={() => addEmailAddress('npt')} />
                        </div>

                        {editNptEmails &&
                            editNptEmails.length > 0 &&
                            editNptEmails.map((item: string, index: any) => {
                                return (
                                    <div className={classes.notifications} key={index}>
                                        <TextField
                                            label=""
                                            placeholder={t('EmailAddress')}
                                            value={item}
                                            onChange={(event) => updateEmails('npt', event, index)}
                                            fullWidth
                                        />
                                        <CloseRoundedIcon
                                            className={classes.deleteColor}
                                            onClick={() => deleteEmailAddress('npt', index)}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div>
                    <div className={classes.heading}>{t('TimeStudy')}</div>
                    <div className={classes.controlContainer}>
                        <div className={classes.controls}>
                            <span>{t('DefaultCycleQuantity')}</span>
                            <TextField
                                label=""
                                value={editTimeStudy}
                                style={{ width: '45px' }}
                                onChange={(event: any) => {
                                    dispatch({
                                        type: Utils.ActionName.GET_PRODUCT_SETTINGS,
                                        payload: {
                                            editTimeStudy: event.target.value,
                                            cancelActive: true,
                                        },
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className={classes.heading}>{t('SkillMatrix')}</div>
                    <div className={classes.controlContainer}>
                        <Typography className={classes.skillDesc}>{t('OperatorSkillCalculator')}</Typography>
                        <div className={classes.controls}>
                            <span>{t('AverageEff')}</span>
                            <div className={classes.skillMatrixInner}>
                                <Typography>{t('Last')}</Typography>
                                <TextField
                                    label=""
                                    value={editSkillMatrix}
                                    style={{ width: '45px' }}
                                    onChange={(event: any) => {
                                        dispatch({
                                            type: Utils.ActionName.GET_PRODUCT_SETTINGS,
                                            payload: {
                                                editSkillMatrix: event.target.value,
                                                cancelActive: true,
                                            },
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <RadioGroup
                                aria-label="skillMatrixMode"
                                name="skillMatrixMode"
                                value={skillmatrixmode.toString()}
                                onChange={handleRadio}
                            >
                                <FormControlLabel value="1" control={<Radio color="primary" />} label={t('SMV')} />
                                <FormControlLabel value="2" control={<Radio color="primary" />} label={t('Target Cycle Time')} />
                            </RadioGroup>
                        </div>
                    </div>
                </div>
            </div>
            {cancelActive && (
                <div className={classes.saveContainer}>
                    <Button variant="outlined" size="large" className={classes.cancelButton} onClick={handleClose}>
                        {t('CANCEL')}
                    </Button>
                    <Button
                        color="primary"
                        size="large"
                        type="submit"
                        variant="contained"
                        className={classes.saveButton}
                        onClick={handleSave}
                    >
                        {t('Save')}
                    </Button>
                </div>
            )}
        </React.Fragment>
    );
}
