import Utils from '../utils';
import { logout } from '../components/header/action';
import ajax from './ajax';
import constant from './constants';

let isRefreshingToken: boolean = false;

/**
 * function to check if code matches to user invalid.
 * @param data
 */

// const checkUserValidation = (data: any) => {
//     if (data) {
//         const { statusCode } = data,
//             { sessionExpired, unauthorized, accessDenied } = Utils.constants.api_error_code;
//         if (statusCode) {
//             return statusCode === sessionExpired || statusCode === unauthorized || statusCode === accessDenied;
//         }
//         return false;
//     }
//     return false;
// };

//new
const checkUserValidation = (data: any) => {
    if (data) {
        const { statusCode } = data,
            { sessionExpired, unauthorized, accessDenied } = Utils.constants.api_error_code;
        if (statusCode) {
            return statusCode === sessionExpired || statusCode === unauthorized || statusCode === accessDenied;
        }
        let status = data.status;
        if (status) {
            return status === sessionExpired || status === unauthorized || status === accessDenied;
        }

        return false;
    }
    return false;
};

//end

export const isTimeOutCall = () => {
    return (dispatch: Function) => {
        dispatch({
            type: Utils.ActionName.SIGN_IN,
            payload: { isTimeOut: true },
        });
    };
};
export const isTimeOutInitial = () => {
    return (dispatch: Function) => {
        dispatch({
            type: Utils.ActionName.SIGN_IN,
            payload: { isTimeOut: false },
        });
    };
};

// when user session expires and need to get a new accessToken using refreshToken
// export const getAccessTokenUsingRefreshToken = () => {
//     if (!navigator.onLine) {
//         //check if user is online or not
//         Utils.showAlert(1, 'Please check your internet connection!');
//         // setSubmitting(false);
//         return;
//     }

//     if (localStorage.getItem('refreshToken') !== null) {
//         const dataToSend = `?refreshToken=${localStorage.getItem('refreshToken')}`;
//         Utils.api.getApiCall(
//             Utils.endPoints.refreshToken,
//             dataToSend,
//             (respData: any) => {
//                 let { data } = respData;
//                 if (
//                     data.statusCode ===
//                     (Utils.constants.api_success_code.success || Utils.constants.api_success_code.postSuccess)
//                 ) {
//                     localStorage.setItem('accessToken', data.data.accessToken);
//                     localStorage.setItem('refreshToken', data.data.refreshToken);
//                     Utils.constants.setAuthorizationToken(data.data.accessToken);
//                     window.location.reload();
//                 } else {
//                     logout();
//                 }
//             },
//             (error: any) => {
//                 logout();
//             },
//         );
//         // };
//     } else {
//         logout();
//     }
//     isTimeOutInitial();
// };
//new

export const getAccessTokenUsingRefreshToken = (callback: any) => {
    console.log('getting accessToken by refreshToken');

    if (!navigator.onLine) {
        //check if user is online or not
        Utils.showAlert(1, 'Please check your internet connection!');
        // setSubmitting(false);
        return;
    }

    if (!isRefreshingToken) {
        if (localStorage.getItem('refreshToken') !== null) {
            const dataToSend = `?refreshToken=${localStorage.getItem(
                'refreshToken',
            )}&accessToken=${localStorage.getItem('accessToken')}`;
            isRefreshingToken = true;
            Utils.api.getApiCall(
                Utils.endPoints.refreshToken,
                dataToSend,
                (respData: any) => {
                    const { data } = respData;
                    if (
                        data.statusCode ===
                        (Utils.constants.api_success_code.success || Utils.constants.api_success_code.postSuccess)
                    ) {
                        localStorage.setItem('accessToken', data.data.accessToken);
                        localStorage.setItem('refreshToken', data.data.refreshToken);
                        Utils.constants.setAuthorizationToken(data.data.accessToken);
                        isRefreshingToken = false;
                        // window.location.reload();
                        callback();
                    } else {
                        isRefreshingToken = false;
                        logout();
                    }
                },
                (error: any) => {
                    isRefreshingToken = false;
                    logout();
                },
            );
        } else {
            isRefreshingToken = false;
            logout();
        }
    } else {
        let checkRefreshCall = new Promise((resolve: any, reject: any) => {
            setTimeout(() => {
                if (!isRefreshingToken) {
                    resolve();
                }
            }, 2000);
        });
        checkRefreshCall
            .then((success: any) => {
                callback();
            })
            .catch((error: any) => {
                logout();
            });
    }
};

//end
// };
/**
 *
 * @param endPoint api end point
 * @param params request data
 * @param successCallback function for handle success response
 * @param errorCallback  function for handle error response
 */
const postApiCall = (endPoint: string, params: object, successCallback: Function, errorCallback: Function) => {
    // @ts-ignore
    Utils.constants.setAuthorizationToken(localStorage.getItem('accessToken'));
    Utils.constants.axios
        .post(endPoint, params)
        .then((response: any) => {
            successCallback(response);
        })
        .catch((error: any) => {
            if (error.code === 'ECONNABORTED') {
                let payload = {
                    data: {
                        statusCode: 408,
                    },
                };
                errorCallback(payload);
            } else if (error.response) {
                let data = error.response.data;
                if (checkUserValidation(data)) {
                    //if user session expired
                    setTimeout(() => {
                        // logOutApiCall();
                        if (localStorage.getItem('accessToken') !== null) {
                            getAccessTokenUsingRefreshToken(() =>
                                postApiCall(endPoint, params, successCallback, errorCallback),
                            );
                        }
                    }, 1000);
                } else {
                    errorCallback(error.response);
                }
            } else if (!error.response) {
                let payload = {
                    data: {
                        statusCode: '',
                        message: 'Please try again later',
                    },
                };
                errorCallback(payload);
            }
        });
};
const postApiCallWithHeader = (endPoint: string, params: object, headers: any, successCallback: Function, errorCallback: Function) => {
    // @ts-ignore
    Utils.constants.setAuthorizationToken(localStorage.getItem('accessToken'));
    Utils.constants.axios
        .post(endPoint, params, {
            headers,
        })
        .then((response: any) => {
            successCallback(response);
        })
        .catch((error: any) => {
            if (error.code === 'ECONNABORTED') {
                let payload = {
                    data: {
                        statusCode: 408,
                    },
                };
                errorCallback(payload);
            } else if (error.response) {
                let data = error.response.data;
                if (checkUserValidation(data)) {
                    //if user session expired
                    setTimeout(() => {
                        // logOutApiCall();
                        if (localStorage.getItem('accessToken') !== null) {
                            getAccessTokenUsingRefreshToken(() =>
                                postApiCall(endPoint, params, successCallback, errorCallback),
                            );
                        }
                    }, 1000);
                } else {
                    errorCallback(error.response);
                }
            } else if (!error.response) {
                let payload = {
                    data: {
                        statusCode: '',
                        message: 'Please try again later',
                    },
                };
                errorCallback(payload);
            }
        });
};
const postApiGlobalCall = (endPoint: string, params: object, successCallback: Function, errorCallback: Function) => {
    // @ts-ignore
    Utils.constants.setAuthorizationToken(localStorage.getItem('accessToken'));
    Utils.constants.axios
        .post(Utils.constants.apiUrlGlobal + endPoint, params)
        .then((response: any) => {
            successCallback(response);
        })
        .catch((error: any) => {
            if (error.code === 'ECONNABORTED') {
                let payload = {
                    data: {
                        statusCode: 408,
                    },
                };
                errorCallback(payload);
            } else if (error.response) {
                let data = error.response.data;
                if (checkUserValidation(data)) {
                    //if user session expired
                    setTimeout(() => {
                        if (localStorage.getItem('accessToken') !== null) {
                            logOutApiCall();
                        }
                    }, 1000);
                } else {
                    errorCallback(error.response);
                }
            } else if (!error.response) {
                let payload = {
                    data: {
                        statusCode: '',
                        message: 'Please try again later',
                    },
                };
                errorCallback(payload);
            }
        });
};

/**
 *
 * @param endPoint api end point
 * @param params api url parameter
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */

/**
 *
 * @param endPoint api end point
 * @param params api url parameter
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const getApiCall = (endPoint: string, params: string = '', successCallback: Function, errorCallback: Function) => {
    // @ts-ignore
    Utils.constants.setAuthorizationToken(localStorage.getItem('accessToken'));
    Utils.constants.axios
        .get(Utils.constants.apiUrl + endPoint + params, {})
        .then((response: any) => {
            successCallback(response);
        })
        .catch((error: any) => {
            if (error.code === 'ECONNABORTED') {
                let payload = {
                    data: {
                        statusCode: 408,
                    },
                };
                errorCallback(payload);
            } else if (error.response) {
                let data = error.response.data;

                if (checkUserValidation(data)) {
                    setTimeout(() => {
                        // logOutApiCall();
                        if (localStorage.getItem('accessToken') !== null) {
                            getAccessTokenUsingRefreshToken(() =>
                                getApiCall(endPoint, params, successCallback, errorCallback),
                            );
                        }
                    }, 1000);
                } else {
                    errorCallback(error.response);
                }
            } else if (!error.response) {
                let payload = {
                    data: {
                        statusCode: '',
                        message: 'Please try again later',
                    },
                };
                errorCallback(payload);
            }
        });
};

const getApiGlobalCall = (
    endPoint: string,
    params: string = '',
    successCallback: Function,
    errorCallback: Function,
) => {
    // @ts-ignore
    Utils.constants.setAuthorizationToken(localStorage.getItem('accessToken'));
    Utils.constants.axios
        .get(Utils.constants.apiUrlGlobal + endPoint + params, {})
        .then((response: any) => {
            successCallback(response);
        })
        .catch((error: any) => {
            if (error.code === 'ECONNABORTED') {
                let payload = {
                    data: {
                        statusCode: 408,
                    },
                };
                errorCallback(payload);
            } else if (error.response) {
                let data = error.response.data;

                if (checkUserValidation(data)) {
                    //if user session expired
                    console.log('session expired for user');
                    //   Utils.showAlert(2, data.message);
                    setTimeout(() => {
                        // logOutApiCall();
                        if (localStorage.getItem('accessToken') !== null) {
                            // getAccessTokenUsingRefreshToken();
                            getAccessTokenUsingRefreshToken(() =>
                                getApiCall(endPoint, params, successCallback, errorCallback),
                            );
                        }
                    }, 1000);
                    // errorCallback(error.response);
                } else {
                    errorCallback(error.response);
                }
            } else if (!error.response) {
                let payload = {
                    data: {
                        statusCode: '',
                        message: 'Please try again later',
                    },
                };
                errorCallback(payload);
            }
        });
};

/**
 *
 * @param endPoint api end point
 * @param params api request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const patchApiCall = (endPoint: string, params: object, successCallback: Function, errorCallback: Function) => {
    // @ts-ignore
    Utils.constants.setAuthorizationToken(localStorage.getItem('accessToken'));
    Utils.constants.axios
        .patch(endPoint, params)
        .then((response: any) => {
            successCallback(response);
        })
        .catch((error: any) => {
            if (error.code === 'ECONNABORTED') {
                let payload = {
                    data: {
                        statusCode: 408,
                    },
                };
                errorCallback(payload);
            } else if (error.response) {
                let data = error.response.data;
                if (checkUserValidation(data)) {
                    setTimeout(() => {
                        if (localStorage.getItem('accessToken') !== null) {
                            getAccessTokenUsingRefreshToken(() =>
                                patchApiCall(endPoint, params, successCallback, errorCallback),
                            );
                        }
                    }, 1000);
                } else {
                    errorCallback(error.response);
                }
            } else if (!error.response) {
                let payload = {
                    data: {
                        statusCode: '',
                        message: 'Please try again later',
                    },
                };
                errorCallback(payload);
            }
        });
};
/**
 *
 * @param endPoint api end point
 * @param params request data
 * @param successCallback function for handle success response
 * @param errorCallback  function for handle error response
 */
const putApiCall = (endPoint: string, params: object, successCallback: Function, errorCallback: Function) => {
    // @ts-ignore
    Utils.constants.setAuthorizationToken(localStorage.getItem('accessToken'));
    Utils.constants.axios
        .put(endPoint, params)
        .then((response: any) => {
            successCallback(response);
        })
        .catch((error: any) => {
            if (error.code === 'ECONNABORTED') {
                let payload = {
                    data: {
                        statusCode: 408,
                    },
                };
                errorCallback(payload);
            } else if (error.response) {
                let data = error.response.data;
                if (checkUserValidation(data)) {
                    setTimeout(() => {
                        if (localStorage.getItem('accessToken') !== null) {
                            getAccessTokenUsingRefreshToken(() =>
                                putApiCall(endPoint, params, successCallback, errorCallback),
                            );
                        }
                    }, 1000);
                } else {
                    errorCallback(error.response);
                }
            } else if (!error.response) {
                let payload = {
                    data: {
                        statusCode: '',
                        message: 'Please try again later',
                    },
                };
                errorCallback(payload);
            }
        });
};

/**
 *
 * @param endPoint api end point
 * @param params api request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */

const deleteApiCall = (endPoint: string, params: string = '', successCallback: Function, errorCallback: Function) => {
    // @ts-ignore
    Utils.constants.setAuthorizationToken(localStorage.getItem('accessToken'));
    Utils.constants.axios
        .delete(Utils.constants.apiUrl + endPoint + params, {})
        .then((response: any) => {
            successCallback(response);
        })
        .catch((error: any) => {
            if (error.code === 'ECONNABORTED') {
                let payload = {
                    data: {
                        statusCode: 408,
                    },
                };
                errorCallback(payload);
            } else if (error.response) {
                let data = error.response.data;
                if (checkUserValidation(data)) {
                    setTimeout(() => {
                        if (localStorage.getItem('accessToken') !== null) {
                            getAccessTokenUsingRefreshToken(() =>
                                deleteApiCall(endPoint, params, successCallback, errorCallback),
                            );
                        }
                    }, 1000);
                } else {
                    errorCallback(error.response);
                }
            } else if (!error.response) {
                let payload = {
                    data: {
                        statusCode: '',
                        message: 'Please try again later',
                    },
                };
                errorCallback(payload);
            }
        });
};

/**
 * Logout API
 */
const logOutApiCall = () => {
    if (!navigator.onLine) {
        //check if user is online or not
        return;
    }
    Utils.constants.getAccessToken();
    Utils.constants.removeSession();
    window.location.replace(window.location.origin);
    Utils.constants.removeSession();
};

const getApiCallFactory = (
    endPoint: string,
    params: string = '',
    successCallback: Function,
    errorCallback: Function,
) => {
    // @ts-ignore
    Utils.constants.setAuthorizationToken(localStorage.getItem('accessToken'));
    Utils.constants.axios
        .get(endPoint + params, {})
        .then((response: any) => {
            successCallback(response);
        })
        .catch((error: any) => {
            if (error.code === 'ECONNABORTED') {
                let payload = {
                    data: {
                        statusCode: 408,
                    },
                };
                errorCallback(payload);
            } else if (error.response) {
                let data = error.response.data;

                if (checkUserValidation(data)) {
                    //if user session expired
                    setTimeout(() => {
                        if (localStorage.getItem('accessToken') !== null) {
                            // logOutApiCall();
                        }
                    }, 1000);
                } else {
                    errorCallback(error.response);
                }
            } else if (!error.response) {
                let payload = {
                    data: {
                        statusCode: '',
                        message: 'Please try again later',
                    },
                };
                errorCallback(payload);
            }
        });
};

const getApiCallLanguage = (
    endPoint: string,
    params: string = '',
    successCallback: Function,
    errorCallback: Function,
) => {
    // @ts-ignore
    Utils.constants.setAuthorizationToken(localStorage.getItem('accessToken'));
    Utils.constants.axios
        .get(endPoint + params, {})
        .then((response: any) => {
            successCallback(response);
        })
        .catch((error: any) => {
            if (error.code === 'ECONNABORTED') {
                let payload = {
                    data: {
                        statusCode: 408,
                    },
                };
                errorCallback(payload);
            } else if (error.response) {
                let data = error.response.data;
                if (!checkUserValidation(data)) {
                    errorCallback(error.response);
                }
            } else if (!error.response) {
                let payload = {
                    data: {
                        statusCode: '',
                        message: 'Please try again later',
                    },
                };
                errorCallback(payload);
            }
        });
};

// /**
//  *
//  * @param endPoint api end point
//  * @param params request data
//  * @param successCallback function for handle success response
//  * @param errorCallback  function for handle error response
//  */
// const postApiCallFactory = (endPoint: string, params: object, successCallback: Function, errorCallback: Function) => {
//     // @ts-ignore
//     Utils.constants.axios
//         .post(endPoint, params)
//         .then((response: any) => {
//             successCallback(response);
//         })
//         .catch((error: any) => {
//             if (error.code === 'ECONNABORTED') {
//                 let payload = {
//                     data: {
//                         statusCode: 408,
//                     },
//                 };
//                 errorCallback(payload);
//             } else if (error.response) {
//                 let data = error.response.data;
//                 if (checkUserValidation(data)) {
//                     //if user session expired
//                   setTimeout(() => {
//                       // logOutApiCall();
//                       let myToken = Utils.constants.axios.defaults.headers.common['Authorization'];
//                       // console.log(myToken.substring(7));
//                       // debugger;
//                       if (localStorage.getItem('accessToken') !== null) {
//                           getAccessTokenUsingRefreshToken(() =>
//                               postApiCallFactory(
//                                   endPoint,
//                                   {
//                                       factoryId: params.factoryId,
//                                       accessToken: myToken.substring(7),
//                                   },
//                                   successCallback,
//                                   errorCallback,
//                               ),
//                           );
//                       }
//                   }, 1000);
//                 } else {
//                     errorCallback(error.response);
//                 }
//             } else if (!error.response) {
//                 let payload = {
//                     data: {
//                         statusCode: '',
//                         message: 'Please try again later',
//                     },
//                 };
//                 errorCallback(payload);
//             }
//         });
// };

//new

/**
 *
 * @param endPoint api end point
 * @param params request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const postApiCallFactory = (endPoint: string, params: any, successCallback: Function, errorCallback: Function) => {
    // @ts-ignore
    Utils.constants.axiosFactory
        .post(endPoint, params)
        .then((response: any) => {
            successCallback(response);
        })
        .catch((error: any) => {
            if (error.code === 'ECONNABORTED') {
                let payload = {
                    data: {
                        statusCode: 408,
                    },
                };
                errorCallback(payload);
            } else if (error.response) {
                let data = error.response.data;
                // NOTE: The status code at the global api end is 401 for both
                // invalid token and no access to the user
                // Hence as of now we are comparing the message provided by api.
                const noFactoryAccess = "This user don't have access to this factory";
                if (data.message === noFactoryAccess) {
                    errorCallback(error.response);
                } else {
                    if (checkUserValidation(data)) {
                        //if user session expired
                        console.log('session expired for user');
                        setTimeout(() => {
                            let myToken = Utils.constants.axios.defaults.headers.common['Authorization'];
                            if (localStorage.getItem('accessToken') !== null) {
                                getAccessTokenUsingRefreshToken(() =>
                                    postApiCallFactory(
                                        endPoint,
                                        {
                                            factoryId: params.factoryId,
                                            accessToken: myToken.substring(7),
                                        },
                                        successCallback,
                                        errorCallback,
                                    ),
                                );
                            }
                        }, 1000);

                        // errorCallback(error.response);
                    } else {
                        errorCallback(error.response);
                    }
                }
            } else if (!error.response) {
                let payload = {
                    data: {
                        statusCode: '',
                        message: 'Please try again later',
                    },
                };
                errorCallback(payload);
            }
        });
};
//end

export const removeOperatorFromPoolApi = async (operatorId: any) => {
    try {
        const urlPath = constant.apiUrl + Utils.endPoints.operatorInPool;
        const response = await ajax(`${urlPath}/${operatorId}`, { method: 'DELETE' });

        return response;
    } catch (error) {
        console.error(error);
    }
}

/**
 * export all function
 */
export default {
    putApiCall,
    getApiCall,
    getApiGlobalCall,
    postApiGlobalCall,
    postApiCall,
    postApiCallWithHeader,
    patchApiCall,
    deleteApiCall,
    logOutApiCall,
    checkUserValidation,
    getApiCallFactory,
    postApiCallFactory,
    getApiCallLanguage,
};
