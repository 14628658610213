import React, { Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Container from '../components/container';

//custom imports below
import Utils from '../utils';

interface Props {
    path: string;
    component: any;
}

const PrivateRoute = ({ component: Component, ...rest }: Props) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <Suspense fallback={''}>
                    {Utils.constants.getUserIdToken() ? (
                        <Container {...props}>
                            <Component {...props} />
                        </Container>
                    ) : (
                        // <Redirect to="/" />
                        
                        (localStorage.getItem('companyCodeAdmin') || JSON.stringify(localStorage.getItem('companyCodeAdmin'))) ? 
                           
                            <Route component={() => { 
                                    window.location.href = `${localStorage.getItem('companyCodeURLAdmins')}`; 
                                    return null;
                                }}/>
                            :
                            <Redirect to="/" /> 
                    )}
                </Suspense>
            )}
        />
    );
};
export default PrivateRoute;
