import React, { useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import OperatorListTable from './operatorListTable';
import Skills from './skills';
import { getMfgLinesList, getOperationCode, getShiftsList } from '../action';
import { useDispatch } from 'react-redux';
import { getMachineSubtypeList } from '../../manageStyle/action';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-around',
            marginTop: '5px',
            // [theme.breakpoints.between(1024, 1054)]: {
            //     overflow: 'scroll',
            // },
        },
        innerContainerLeft: {
            flexBasis: '48.2%',
            height: '90vh',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                display: 'none !important',
            },
        },
        innerContainerRight: {
            flexBasis: '48.2%',
            backgroundColor: 'var(--white)',
            padding: '18px 16px',
            height: '90vh',
            overflow: 'auto',
            paddingBottom: 0,
            '&::-webkit-scrollbar': {
                display: 'none !important',
            },
        },
    }),
);

export default function ManageOperator() {
    const classes = styles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getShiftsList());
        dispatch(getMachineSubtypeList());
        dispatch(getMfgLinesList());
        // custom limit for all operation codes
        dispatch(getOperationCode(1000));
    }, [dispatch]);

    return (
        <div className={classes.mainContainer}>
            <Helmet>
                <title>{t('ManageOperator')}</title>
            </Helmet>
            <div className={classes.innerContainerLeft}>
                <OperatorListTable />
            </div>
            <div className={classes.innerContainerRight}>
                <Skills />
            </div>
        </div>
    );
}
