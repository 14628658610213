import Utils from '../../utils';
import {
    operatorModel,
    SkillModel,
    linesSetupModel,
    shiftsModel,
    operationCodeModel,
    ppaCodeModel,
    mfgLinesModel,
    timeStudyOperatorModel,
} from '../../model';

export const operatorReducer = (state: operatorModel = new operatorModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.UPDATE_OPERATOR_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const skillReducer = (state: SkillModel = new SkillModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.UPDATE_SKILL_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const linesSetupReducer = (state: linesSetupModel = new linesSetupModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_LINES_SETUP:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const shiftsReducer = (state: shiftsModel = new shiftsModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_SHIFTS_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const operationCodeReducer = (state: operationCodeModel = new operationCodeModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.UPDATE_OPERRATION_CODE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const ppaCodeReducer = (state: ppaCodeModel = new ppaCodeModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.UPDATE_PPA_CODE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const mfgLinesReducer = (state: mfgLinesModel = new mfgLinesModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_MFG_LINES:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const timeStudyOperatorReducer = (state: timeStudyOperatorModel = new timeStudyOperatorModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_TIMESTUDY_OPERATOR_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
