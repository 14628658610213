import Utils from '../../utils';
import { DashboardModel, DashboardFilterModel } from '../../model';

export const dashboardReducer = (state: DashboardModel = new DashboardModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_DASHBOARD:
            return { ...state, ...action.payload };
        case Utils.ActionName.UPDATE_PAGINATION_DASHBOARD:
            return { ...state, currentPage: action.payload };
        default:
            return state;
    }
};

export const dashboardFilterReducer = (state: DashboardFilterModel = new DashboardFilterModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_DASHBOARD_FILTER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
