import React from 'react';
import { createStyles, makeStyles, TextField, Theme, InputAdornment } from '@material-ui/core';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import Tooltip from '@material-ui/core/Tooltip';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        inputField: {
            '& .MuiInputBase-input': {
                font: 'normal normal normal 14px/14px Roboto',
                letterSpacing: '0.07px',
                opacity: 1,
            },
            '& .MuiFormLabel-root': {
                font: 'normal normal normal 12px/21px Roboto',
                letterSpacing: '0.07px',
                opacity: 1,
            },
            '& .MuiInputAdornment-marginDense': {
                '& svg': {
                    cursor: 'pointer',
                    // , color: '#1565c0'
                },
            },
        },
        required: {
            color: '#D32F2F',
        },
    }),
);
interface Props {
    label?: string;
    placeholder?: string;
    id?: string;
    name?: string;
    type?: string;
    onBlur?: any;
    values?: any;
    onChange?: any;
    style?: any;
    helperText?: any;
    error?: any;
    isRequired?: boolean;
    isDisabled?: boolean;
    UOM?: string;
    translation?: boolean;
    translationHandle?: any;
    select?: any;
    variantStyle?: any;
}

export default function NormalInput({
    label,
    placeholder,
    id,
    name,
    type,
    onBlur,
    values,
    onChange,
    style,
    helperText,
    isRequired,
    error,
    isDisabled,
    UOM = '',
    translation,
    translationHandle,
    select,
    variantStyle,
}: Props) {
    const classes = styles();

    return (
        <TextField
            variant={variantStyle}
            onBlur={onBlur}
            value={values ? values : ''}
            onChange={onChange}
            placeholder={placeholder}
            helperText={helperText}
            error={error}
            name={name}
            label={
                <span>
                    {label}
                    {isRequired ? <span className={classes.required}> *</span> : <span></span>}
                </span>
            }
            type={type}
            fullWidth
            size={'small'}
            className={[classes.inputField, style].join(' ')}
            // required={isRequired}
            disabled={isDisabled ? isDisabled : false}
            defaultValue={'-'}
            InputProps={
                UOM || translation || select
                    ? {
                          endAdornment: (
                              <InputAdornment position="end">
                                  {UOM ? (
                                      UOM
                                  ) : select ? (
                                      select()
                                  ) : (
                                      <Tooltip title={'Translation'} placement="top-start" arrow>
                                          <GTranslateIcon color={'primary'} onClick={translationHandle} />
                                      </Tooltip>
                                  )}
                              </InputAdornment>
                          ),
                      }
                    : { autoComplete: 'off' }
            }
        />
    );
}
