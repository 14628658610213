import Utils from '../../utils';
import { notificationModel, AppDrawerModel, ProfileAdminModel } from '../../model';

export const notificationReducer = (state: notificationModel = new notificationModel(), action: any) => {
	switch (action.type) {
		case Utils.ActionName.GET_NOTIFICATION:
			return { ...state, ...action.payload };
		default:
			return state;
	}
};

export const appDrawerReducer = (state: AppDrawerModel = new AppDrawerModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_PRODUCT_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const userNameReducer = (state: ProfileAdminModel = new ProfileAdminModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_PROFILE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
