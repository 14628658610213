import React from 'react';
import {
    createStyles,
    makeStyles,
    Theme,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    TableContainer,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import SimpleModal from '../../../components/modal';
import DeleteModal from '../../../components/modal/deleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModel } from '../../../model';
import moment from 'moment';
import { deleteTimeStudy, getTimestudyOperator } from '../action';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        tableContainer: {
            maxHeight: '65vh',
            overflow: 'auto',
            '& .MuiPaper-root': {
                boxShadow: 'none',
            },
            '& td': {
                padding: '10px 7px',
            },
        },
        categories: {
            padding: '7px',
            letterSpacing: '0.08px',
            textTransform: 'uppercase',
            opacity: 1,
            background: '#D2D2D2 0% 0% no-repeat ',
            wordBreak: 'keep-all',
            '& th': {
                font: 'normal normal normal 13px/21px Roboto',
                padding: '5px 5px',
                color: 'var(--input-fill)',
                verticalAlign: 'top',
                backgroundColor: '#D2D2D2',
                '& > span': {
                    cursor: 'pointer',
                },
            },
        },
        noData: {
            display: 'flex',
            justifyContent: 'center',
            font: 'normal normal normal 16px/16px Roboto',
            color: '#515151',
            marginTop: '10px',
            marginBottom: '100px',
        },
        row: {
            '& td': {
                font: 'normal normal normal 14px/14px Roboto',
                letterSpacing: '0px',
                color: '#515151',
                verticalAlign: 'top',
            },
            '&:hover': {
                backgroundColor: 'var(--background-grey)',
            },
            '&>td:nth-child(10)': {
                padding: '5px 7px',
            },
        },
        dwnIcnStyl: {
            cursor: 'pointer',
            width: '13px',
            verticalAlign: 'middle',
            transition: '1s all',
        },
        rotateIcon: {
            transform: 'rotate(180deg)',
            transition: '1s all',
        },
    }),
);

export default function TimeStudyTableList() {
    const classes = styles();
    const tableEl: any = React.useRef();
    const { t } = useTranslation();
    const [openSkillDelete, setOpenSkillDelete] = React.useState(false);
    const [timeStudyIdToDelete, setTimeStudyIdToDelete] = React.useState<null | number>(null);
    const [distanceBottom, setDistanceBottom] = React.useState(0);
    const { 
        timeStudyOperatorData, 
        count, 
        page, 
        limit,
    } = useSelector((state: ReducersModel) => state.timeStudyOperatorReducer);
  
    const scrollListener = React.useCallback(() => {
        let bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;
        if (!distanceBottom) 
            setDistanceBottom(Math.round(bottom * 0.2));
        if (tableEl.current.scrollTop > bottom - distanceBottom && count > (page * limit)) 
            dispatch(getTimestudyOperator(operatorId, page + 1));
    }, [distanceBottom, page]);

    React.useLayoutEffect(() => {
        const tableRef = tableEl.current;
        tableRef.addEventListener('scroll', scrollListener);
        return () => tableRef.removeEventListener('scroll', scrollListener);
    }, [scrollListener]);

    const handleOpenSkillDelete = () => {
        setOpenSkillDelete(true);
    };
    const handleCloseSkillDelete = () => {
        setOpenSkillDelete(false);
    };

    const { operatorId } = useSelector((state: ReducersModel) => state.skillReducer);
    const dispatch = useDispatch();

    return (
        <>
            <TableContainer className={classes.tableContainer} ref={tableEl}>
                <Table stickyHeader className={classes.tableContainer}>
                    <TableHead className={classes.categories}>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>{t('OperationCode')}</TableCell>
                            <TableCell>{t('SkillType')}</TableCell>
                            <TableCell>{t('MCCode')}</TableCell>
                            <TableCell>{t('TimeStudy')}</TableCell>
                            <TableCell>{t('EFF')}</TableCell>
                            <TableCell>{t('Createdby')}</TableCell>
                            <TableCell>{t('DateCreated')}</TableCell>
                            <TableCell>{t('Channel')}</TableCell>
                            <TableCell>{''}</TableCell>
                        </TableRow>
                    </TableHead>
                    {timeStudyOperatorData && timeStudyOperatorData.length > 0 ? (
                        <TableBody>
                            {timeStudyOperatorData.map((row: any, index: any) => {
                                const {
                                    createdBy,
                                    createdDt,
                                    eff,
                                    channel,
                                    time,
                                    operationData,
                                    macsubtypesData,
                                    skillTypeData,
                                    id,
                                } = row;
                                const { code } = operationData;
                                return (
                                    <TableRow key={index} hover={true} className={classes.row}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{code}</TableCell>
                                        <TableCell>
                                            {skillTypeData !== null ? skillTypeData.skillTypeCode : '-'}
                                        </TableCell>
                                        <TableCell>
                                            {macsubtypesData !== null ? macsubtypesData.subType : '-'}
                                        </TableCell>
                                        <TableCell>{time !== null ? time : '-'}</TableCell>
                                        <TableCell>{`${eff}%`}</TableCell>
                                        <TableCell>{createdBy}</TableCell>
                                        <TableCell>{moment(createdDt).format('YYYY-MM-DD')}</TableCell>
                                        <TableCell>{channel !== null ? channel : '-'}</TableCell>
                                        <TableCell>
                                            <DeleteIcon
                                                color={'primary'}
                                                onClick={() => {
                                                    handleOpenSkillDelete();
                                                    setTimeStudyIdToDelete(id);
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    ) : (
                        <TableRow>
                            <TableCell colSpan={9}>
                                <div className={classes.noData}>{t('NoRecords')}</div>
                            </TableCell>
                        </TableRow>
                    )}
                </Table>
            </TableContainer>
            <SimpleModal
                handleOpen={handleOpenSkillDelete}
                isOpen={openSkillDelete}
                body={
                    <DeleteModal
                        title={`${t('DeleteSkill')}?`}
                        onDelete={() => {
                            dispatch(deleteTimeStudy(timeStudyIdToDelete, operatorId));
                        }}
                        handleClose={handleCloseSkillDelete}
                    />
                }
            />
        </>
    );
}
