import { getlbrViewBy, viewByOptions } from "../../helpers";
import { 
    UPDATE_DATA_GRAPH, 
    UPDATE_GRAPH_ORDER_BY,
    UPDATE_GRAPH_SELECTED_LABELS,
    UPDATE_GRAPH_VIEW_BY,
} from "../constant";

const reducers = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_DATA_GRAPH: 
            return {...state, data: payload};
        case UPDATE_GRAPH_SELECTED_LABELS: 
            return {...state, selectedLabels: payload};
        case UPDATE_GRAPH_ORDER_BY: 
            return { ...state, query: { ...state.query, orderBy: payload }}
        case UPDATE_GRAPH_VIEW_BY: 
            localStorage.setItem('viewByDefaultId', JSON.stringify(payload))
            return { ...state, query: { ...state.query, viewBy: payload }}
        default: return state;
        
    }
}

const initialState = {
    data: null,
    query: {
        orderBy: 1,
        viewBy: getlbrViewBy(),
    },
    selectedLabels: [],
};

export default reducers;