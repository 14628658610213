import React, { useState } from 'react';
import LocalImages from '../../utils/images';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import SimpleModal from './index';
import { useTranslation } from 'react-i18next';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        styleImageBG: {
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            cursor: 'pointer',
        },
        deleteStyle: {
            position: 'absolute',
            top: '5px',
            right: '5px',
            cursor: 'pointer',
            height: '24px',
            width: '24px',
        },
        thumbnailPopup: {
            position: 'absolute',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: '30px',
            width: 'auto',
            '& img': {
                maxHeight: '70vh',
                width: 'auto',
            },
        },
    }),
);

interface Props {
    alt: string;
    src: string;
    size: string;
    noModal?: boolean;
}

export default function ResponsiveImage({ alt, src, size, noModal }: Props) {
    const classes = styles();
    const { t } = useTranslation();
    const [openModalOne, setOpenModalOne] = useState(false);
    const [imageModal, setModalImage] = useState('');

    function getModalStyle() {
        const top = 50;
        const left = 50;
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const [modalStyle] = React.useState(getModalStyle);

    return (
        <>
            <div
                onClick={() => {
                    setOpenModalOne(true);
                    setModalImage(src);
                }}
            >
                <img src={src} alt={''} className={classes.styleImageBG} style={{ width: size, height: size }} />
            </div>
            {!noModal && (
                <SimpleModal
                    handleOpen={() => {
                        setOpenModalOne(true);
                    }}
                    isOpen={openModalOne}
                    body={
                        <div className={classes.thumbnailPopup} style={modalStyle}>
                            <img src={imageModal} alt={alt} />
                            <img
                                src={LocalImages.CANCEL}
                                alt={t('Close')}
                                className={classes.deleteStyle}
                                onClick={() => setOpenModalOne(false)}
                            />
                        </div>
                    }
                />
            )}
        </>
    );
}
