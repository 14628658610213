import { nptCategoryListModel, nptTypeListModel } from '../../../model';
import Utils from '../../../utils';

export const nptCategoryListReducer = (state: nptCategoryListModel = new nptCategoryListModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_NPT_CATEGORIES_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const nptTypeListReducer = (state: nptTypeListModel = new nptTypeListModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_NPT_TYPES_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
