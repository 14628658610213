import React from 'react';
import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        inputField: {
            '& .MuiInputBase-input': {
                font: 'normal normal normal 14px/14px Roboto',
                letterSpacing: '0.07px',
                opacity: 1,
            },
            '& .MuiFormLabel-root': {
                font: 'normal normal normal 12px/21px Roboto',
                letterSpacing: '0.07px',
                opacity: 1,
            },
        },
        required: {
            color: '#D32F2F',
        },
    }),
);

interface Props {
    label?: string;
    placeholder?: string;
    id?: string;
    name?: string;
    type?: string;
    onBlur?: any;
    values?: any;
    onChange?: any;
    style?: any;
    helperText?: any;
    error?: any;
    isRequired?: boolean;
    isDisabled?: boolean;
    maxLength?: undefined | number;
}

export default function TimeInput({
    label,
    placeholder,
    id,
    name,
    type,
    onBlur,
    values,
    onChange,
    style,
    helperText,
    isRequired,
    error,
    isDisabled,
    maxLength = undefined,
}: Props) {
    const classes = styles();

    return (
        <TextField
            id={id || 'txtInput'}
            ref={() => {
                const text: any = document.getElementById(id || 'txtInput');
                text.addEventListener('keypress', (evt: any) => {
                    const reg = /^[0-9]{1,2}(?:\:(?:[0-9]{1,2}(?:\.(?:[0-9]{1,2})?)?)?)?$/;
                    const value = evt.target.value;
                    const char = String.fromCharCode(evt.keyCode);
                    const str = `${value}${char}`
                    if(Number(str.split(':')[1]) >= 60){
                        evt.preventDefault();
                    }
                    if (!reg.test(str)) {
                        evt.preventDefault();
                    }
                });
            }}
            onBlur={onBlur}
            value={values ? values : ''}
            onChange={onChange}
            placeholder={placeholder}
            helperText={helperText}
            error={error}
            name={name}
            label={
                <span>
                    {label}
                    {isRequired ? <span className={classes.required}> *</span> : <span></span>}
                </span>
            }
            type={type}
            fullWidth
            size={'small'}
            className={classes.inputField}
            disabled={isDisabled ? isDisabled : false}
            defaultValue={'-'}
            inputProps={{
                autoComplete: 'off',
                maxLength: maxLength,
                pattern: '[0-9]{1,2}:[0-9]{1,2}\.[0-9]{1,2}'
            }}
        />
    );
}
