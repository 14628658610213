import React, { useEffect } from 'react';
import { getLineSetupHistory } from '../../screens/manageStyle/action';
import {
    createStyles,
    makeStyles,
    Theme,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    TablePagination,
    Chip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModel } from '../../model';
import moment from 'moment';
import Utils from '../../utils';
import SimpleModal from '../modal';
import { NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LocalImages from '../../utils/images';
import LineSetupForm from '../../screens/lineSetup/lineSetupForm';
import { minuteToTime } from '../../utils/convertTime';
import { getLBAInfo } from '../../screens/lineBalancingAssist/action';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        lineSetUpHistoryContainer: {
            overflow: 'auto',
            maxHeight: '57vh',
            position: 'relative',
        },
        mainContainer: {
            '& td': { padding: '7px', verticalAlign: 'top', whiteSpace: 'nowrap' },
        },
        tableHeader: {
            backgroundColor: 'var(--background-grey)',
            '& th': {
                font: 'normal normal bold 13px/23px Roboto',
                letterSpacing: '0.08px',
                color: 'var(--dark-grey)',
                textTransform: 'uppercase',
                opacity: 1,
                padding: '16px 7px',
                verticalAlign: 'top',
                whiteSpace: 'nowrap',
            },
        },
        row: {
            '& td:nth-child(4)': {
                textTransform: 'uppercase',
            },
        },
        paginationContainer: {
            marginTop: '40px',
            backgroundColor: '#F7F8FA',
            '& .MuiTypography-root': {
                textAlign: 'left',
                font: 'normal normal normal 14px/14px Roboto',
                letterSpacing: '0px',
                color: '#515151',
                opacity: 1,
            },
        },
        report: {
            color: '#1689FA',
            cursor: 'pointer',
        },
        noData: {
            display: 'flex',
            justifyContent: 'center',
            font: 'normal normal normal 16px/16px Roboto',
            color: '#515151',
            marginTop: '10px',
            marginBottom: '100px',
        },
        delete: {
            cursor: 'pointer',
        },
        lineName: {
            color: '#1689FA',
            cursor: 'pointer',
            '& a': {
                color: '#1689FA',
                textDecoration: 'none',
            },
        },
        statusChip: {
            minWidth: '80px',
            height: '26px',
            font: 'normal normal normal 12px/14px Roboto',
            letterSpacing: 0,
            color: '#FFFFFF',
            textTransform: 'uppercase',
            opacity: 1,
        },
        dwnIcnStyl: {
            cursor: 'pointer',
            width: '13px',
            verticalAlign: 'middle',
            transition: '1s all',
        },
        rotateIcon: {
            transform: 'rotate(180deg)',
            transition: '1s all',
        },
    }),
);

export default function LineSetupHistory() {
    const classes = styles();
    const dispatch = useDispatch();
    const history = useHistory();
    const styleId = new URL(window.location.href).searchParams.get('id');
    const { t } = useTranslation();

    useEffect(() => {
        dispatch({
            type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
            payload: {
                historyData: [],
                page: 1,
            },
        });
        dispatch(getLineSetupHistory(styleId));
    }, [dispatch, styleId]);
    const { historyData, count, page, limit } = useSelector(
        (state: ReducersModel) => state.lineSetupHistoryTableReducer,
    );

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        dispatch({
            type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
            payload: {
                page: newPage + 1,
                data: [],
            },
        });
        dispatch(getLineSetupHistory(styleId));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({
            type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
            payload: {
                limit: event.target.value,
                page: 1,
                data: [],
            },
        });
        dispatch(getLineSetupHistory(styleId));
    };

    // for delete line setup
    const [openEdit, setOpenEdit] = React.useState(false);
    const handleOpenEdit = () => {
        setOpenEdit(true);
    };
    const handleCloseEdit = () => {
        setOpenEdit(false);
    };


    const [isSort, setIsSort] = React.useState<any>({
        lineName: false,
        shift: false,
        targetEff: false,
        eff: false,
        highestEff: false,
        totalOutput: false,
        allQtyAchieved: false,
        completionDate: false,
        createdBy: false,
        createdDt: false,
        ttlCycleTime: false,
    });

    const handleSort = (keyToSort: string) => {
        let newObject: any = {};
        Object.keys(isSort).forEach(function (key) {
            if (key === keyToSort) {
                newObject[key] = !isSort[key];
            } else {
                newObject[key] = false;
            }
        });
        setIsSort(newObject);

        dispatch({
            type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
            payload: {
                page: 1,
                order: newObject[keyToSort] ? 'ASC' : 'DESC',
                sortBy: keyToSort,
            },
        });
        dispatch(getLineSetupHistory(styleId));
    };

    const handleCopy = (row: any) => {
        dispatch({
            type: Utils.ActionName.GET_LINE_SHIFTS,
            payload: {
                isSaveAs: true,
                uid: row.uid,
                currentShiftActive: row.id,
                fromHistory: true,
            },
        });
        dispatch({
            type: Utils.ActionName.GET_LINE_SETUP_INFO,
            payload: {
                infoData: row,
            },
        });
        handleOpenEdit();
    };

    const handleLineBalancingInfo = (row: any) => {
        if (row.lineName !== null && row.lineName !== '') {
            history.push(
                `/line-balancing-assist/line-balancing-info?id=${row.uid}`,
            );
        };
        window.location.reload();
    }

    return (
        <div className={classes.mainContainer}>
            <div className={classes.lineSetUpHistoryContainer}>
                <Table stickyHeader={true} aria-label="sticky table">
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center">#</TableCell>
                            <TableCell>
                                <span onClick={() => handleSort('lineName')}>
                                    {t('LineSetupname')}
                                    <ArrowDownwardIcon
                                        className={
                                            isSort['lineName']
                                                ? classes.dwnIcnStyl
                                                : `${classes.rotateIcon} ${classes.dwnIcnStyl}`
                                        }
                                    />
                                </span>
                            </TableCell>
                            <TableCell>{t('Line')}</TableCell>
                            <TableCell>
                                <span onClick={() => handleSort('shift')}>
                                    {t('Shift')}
                                    <ArrowDownwardIcon
                                        className={
                                            isSort['shift']
                                                ? classes.dwnIcnStyl
                                                : `${classes.rotateIcon} ${classes.dwnIcnStyl}`
                                        }
                                    />
                                </span>
                            </TableCell>
                            <TableCell align="center">{t('Status')}</TableCell>
                            <TableCell>{t('TTLM/C')}</TableCell>
                            <TableCell>{t('MPWR')}</TableCell>
                            <TableCell>{t('TTLSMV')}</TableCell>
                            <TableCell>{t('TTL TCT')}</TableCell>
                            <TableCell align="right">
                                <span onClick={() => handleSort('targetEff')}>
                                    {t('TGTEFF')}
                                    <ArrowDownwardIcon
                                        className={
                                            isSort['targetEff']
                                                ? classes.dwnIcnStyl
                                                : `${classes.rotateIcon} ${classes.dwnIcnStyl}`
                                        }
                                    />
                                </span>
                            </TableCell>
                            <TableCell align="right">
                                <span onClick={() => handleSort('eff')}>
                                    {t('AVGEFF')}
                                    <ArrowDownwardIcon
                                        className={
                                            isSort['eff']
                                                ? classes.dwnIcnStyl
                                                : `${classes.rotateIcon} ${classes.dwnIcnStyl}`
                                        }
                                    />
                                </span>
                            </TableCell>
                            <TableCell align="right">
                                <span onClick={() => handleSort('highestEff')}>
                                    {t('HighestEFF')}
                                    <ArrowDownwardIcon
                                        className={
                                            isSort['highestEff']
                                                ? classes.dwnIcnStyl
                                                : `${classes.rotateIcon} ${classes.dwnIcnStyl}`
                                        }
                                    />
                                </span>
                            </TableCell>
                            <TableCell align="right">{t('PERF')}</TableCell>
                            <TableCell align="right">{t('LBR')}</TableCell>
                            <TableCell align="right">
                                <span onClick={() => handleSort('ttlCycleTime')}>
                                    {t('TTLCycleTime')}
                                    <ArrowDownwardIcon
                                        className={
                                            isSort['ttlCycleTime']
                                                ? classes.dwnIcnStyl
                                                : `${classes.rotateIcon} ${classes.dwnIcnStyl}`
                                        }
                                    />
                                </span>
                            </TableCell>
                            <TableCell align="right">
                                <span onClick={() => handleSort('totalOutput')}>
                                    {t('Output')}
                                    <ArrowDownwardIcon
                                        className={
                                            isSort['totalOutput']
                                                ? classes.dwnIcnStyl
                                                : `${classes.rotateIcon} ${classes.dwnIcnStyl}`
                                        }
                                    />
                                </span>
                            </TableCell>
                            <TableCell align="right">
                                <span onClick={() => handleSort('allQtyAchieved')}>
                                    {t('LineOutput')}
                                    <ArrowDownwardIcon
                                        className={
                                            isSort['allQtyAchieved']
                                                ? classes.dwnIcnStyl
                                                : `${classes.rotateIcon} ${classes.dwnIcnStyl}`
                                        }
                                    />
                                </span>
                            </TableCell>
                            <TableCell>{t('StartDate')}</TableCell>
                            <TableCell>{t('EndDate')}</TableCell>
                            <TableCell>
                                <span onClick={() => handleSort('completionDate')}>
                                    {t('ActEndDate')}
                                    <ArrowDownwardIcon
                                        className={
                                            isSort['completionDate']
                                                ? classes.dwnIcnStyl
                                                : `${classes.rotateIcon} ${classes.dwnIcnStyl}`
                                        }
                                    />
                                </span>
                            </TableCell>
                            <TableCell>{t('ClosedBy')}</TableCell>
                            <TableCell>{t('OrderNo')}</TableCell>
                            <TableCell>{t('Customer')}</TableCell>
                            <TableCell>{t('MODIFIEDBY')}</TableCell>
                            <TableCell>{t('DATEMODIFIED')}</TableCell>
                            <TableCell>
                                <span onClick={() => handleSort('createdBy')}>
                                    {t('Createdby')}
                                    <ArrowDownwardIcon
                                        className={
                                            isSort['createdBy']
                                                ? classes.dwnIcnStyl
                                                : `${classes.rotateIcon} ${classes.dwnIcnStyl}`
                                        }
                                    />
                                </span>
                            </TableCell>
                            <TableCell>
                                <span onClick={() => handleSort('createdDt')}>
                                    {t('DateCreated')}
                                    <ArrowDownwardIcon
                                        className={
                                            isSort['createdDt']
                                                ? classes.dwnIcnStyl
                                                : `${classes.rotateIcon} ${classes.dwnIcnStyl}`
                                        }
                                    />
                                </span>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    {historyData.length > 0 ? (
                        <>
                            <TableBody>
                                {historyData.map((row: any, index: any) => (
                                    <TableRow key={row.id} className={classes.row}>
                                        <TableCell>
                                            <img
                                                src={LocalImages.COPY1}
                                                alt={t('Copy')}
                                                className={classes.delete}
                                                onClick={() => handleCopy(row)}
                                            />
                                        </TableCell>
                                        <TableCell align="center">{index + 1}</TableCell>
                                        <TableCell
                                            className={classes.lineName}
                                            onClick={() => {
                                                handleLineBalancingInfo(row);
                                            }}
                                            style={
                                                row.lineName === null || row.lineName === ''
                                                    ? { cursor: 'default' }
                                                    : {}
                                            }
                                        >
                                            <NavLink to={`/line-balancing-assist/line-balancing-info?id=${row.uid}`}>
                                                {row.lineName}
                                            </NavLink>
                                        </TableCell>
                                        <TableCell>Line {row.mfgLineData.mfgLine}</TableCell>
                                        <TableCell>{row.shift}</TableCell>
                                        <TableCell align="center">
                                            <Chip
                                                label={row.status === 3 ? 'COMPLETED' : 'CLOSED'}
                                                className={classes.statusChip}
                                                style={
                                                    row.status === 3
                                                        ? { backgroundColor: '#1E88E5' }
                                                        : { backgroundColor: '#ACACAC' }
                                                }
                                            />
                                        </TableCell>
                                        <TableCell align="right">{row.currttlMcn}</TableCell>
                                        <TableCell align="right">
                                            {row.currManpower === null ? 0 : row.currManpower}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.totalWebSmv && row.totalWebSmv !== null
                                                ? Utils.CommonFunctions.maxDecimalValue(row.totalWebSmv, 4)
                                                : '-'}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.totalTct
                                                ? minuteToTime(row.totalTct)
                                                : 'N/A'}
                                        </TableCell>
                                        <TableCell align="right">{`${row.targetEff}%`}</TableCell>
                                        <TableCell align="right">
                                            {row.eff && row.eff !== null
                                                ? `${Utils.CommonFunctions.maxDecimalValue(row.eff, 2)}%`
                                                : ''}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.highestEff && row.highestEff !== null
                                                ? Utils.CommonFunctions.maxDecimalValue(row.highestEff, 2)
                                                : ''}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.perf !== '' && row.perf !== null
                                                ? `${Utils.CommonFunctions.maxDecimalValue(row.perf, 2)}%`
                                                : '-'}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.lbr !== '' && row.lbr !== null
                                                ? `${Utils.CommonFunctions.maxDecimalValue(row.lbr, 2)}%`
                                                : '-'}
                                        </TableCell>
                                        <TableCell>{`${row.ttlCycleTime}s`}</TableCell>
                                        <TableCell align="right">{row.totalOutput}</TableCell>
                                        <TableCell align="right">{`${row.allQtyAchieved}/${row.quantity}`}</TableCell>
                                        <TableCell>{moment(row.startDate).format('YYYY-MM-DD')}</TableCell>
                                        <TableCell>
                                            {row.endDate !== null ? moment(row.endDate).format('YYYY-MM-DD') : '-'}
                                        </TableCell>
                                        <TableCell>
                                            {row.completionDate !== null
                                                ? moment(row.completionDate).format('YYYY-MM-DD')
                                                : '-'}
                                        </TableCell>
                                        <TableCell>{row.closedBy}</TableCell>
                                        <TableCell>{row.orderNo}</TableCell>
                                        <TableCell>{row.customer}</TableCell>
                                        <TableCell>{row.updatedBy}</TableCell>
                                        <TableCell>
                                            {row.updatedDt !== null ? moment(row.updatedDt).format('YYYY-MM-DD') : '-'}
                                        </TableCell>
                                        <TableCell>{row.createdBy}</TableCell>
                                        <TableCell>{moment(row.createdDt).format('YYYY-MM-DD')}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </>
                    ) : (
                        <TableRow>
                            <TableCell colSpan={16}>
                                <div className={classes.noData}>{t('NoRecords')}</div>
                            </TableCell>
                        </TableRow>
                    )}
                </Table>
            </div>
            <div className={classes.paginationContainer}>
                <TablePagination
                    component="div"
                    count={count}
                    page={page - 1}
                    onPageChange={handleChangePage}
                    rowsPerPage={limit}
                    labelDisplayedRows={({ from, to, count, page }) => `  ${from}-${to} of  ${count} `}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
            <SimpleModal
                handleOpen={handleOpenEdit}
                isOpen={openEdit}
                body={<LineSetupForm handleClose={handleCloseEdit} saveAs />}
            />
        </div>
    );
}
