import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LocalImages from '../../utils/images';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: '16px',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        minHeight: 200,
        maxheight: 400,
        display: 'block',
        overflow: 'hidden',
        maxWidth: '100%',
        marginTop: '10px',
    },
    closeIcon: {
        position: 'absolute',
        right: '8px',
    },
    deleteStyle: {
        position: 'absolute',
        top: '1.7%',
        right: '1.7%',
        cursor: 'pointer',
        height: '24px',
        width: '24px',
    },
}));

interface Props {
    handleClose: any;
    items: any;
    imgToOpen: number;
    alt: string;
}

export const ImageCaurosel = ({ handleClose, items, imgToOpen, alt }: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(imgToOpen);
    const maxSteps = items.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
    };

    function getModalStyle() {
        const top = 50;
        const left = 50;
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const [modalStyle] = React.useState(getModalStyle);
    const { t } = useTranslation();

    return (
        <div style={modalStyle} className={classes.paper}>
            <div className={classes.root}>
                <img
                    src={LocalImages.CANCEL}
                    alt={t('Close')}
                    className={classes.deleteStyle}
                    onClick={() => handleClose()}
                />
                <img className={classes.img} src={items[activeStep]} alt={alt} />
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                            {t('Next')}
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            {t('Back')}
                        </Button>
                    }
                />
            </div>
        </div>
    );
};
