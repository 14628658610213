export const normalizeRows = (stations: any, flagValue: number, retrun: Function) => {
  const defaultHeight = 50;
  const marginBottom = 10;
  const result = stations.reduce((acc: any, station: any, key: any) => {

      const height = station.operations.length * defaultHeight;
      const heightMargin = station.operations.length * marginBottom;
      const operatorId = station.operator?.id || null;
      const allSkillsData = station.operator?.operatorskills || [];
      const steps = station.operations.reduce((acc: any, obj: any) => acc.concat(obj.step), []);
      const removeDuplicatedOperations: any = [];
      const seenOperations: any = {};

      const filteredSkills = station.operations?.reduce((acc: any, row: any) => {
          const skill = allSkillsData?.filter(
              (item: any) =>
                  item.operatorId === operatorId &&
                  item?.skillTypeCode?.trim() === row?.skillType?.trim() &&
                  item.macSubTypeId === row.macSubTypeId
          );
          return skill ? acc.concat(skill) : acc;
      }, []);
     
      const operations = station.operations.reduce((acc: any, operation: any, key: number) => {
          const countDuplicated = steps.filter((step: number) => step === operation.step);
          const height = countDuplicated.length > 1
              ? defaultHeight * countDuplicated.length + marginBottom * (countDuplicated.length - 1)
              : defaultHeight * countDuplicated.length;
          return acc.concat({
              ...operation,
              height: height,
          })
      }, []);

      for (const obj of operations) {
          const step = obj.step;
          if (!seenOperations[step]) {
              seenOperations[step] = true;
              removeDuplicatedOperations.push(obj);
          }
      };

      const totalActMpwr = station.operations.reduce((acc: any, { thManpower }: any) => (acc + thManpower), 0);
      const operatorInlines = station.operations.reduce((acc: any, operator: any, key: number) => {
          const operatorSkill = filteredSkills.find((skill: any) =>
              skill?.skillTypeCode?.trim() === operator?.skillType?.trim() &&
              skill?.macSubTypeId === operator.macSubTypeId &&
              skill?.operation === operator.operationId
          );
          const subTypes = filteredSkills?.map((skill: any) => skill?.macSubTypeData.subType);
          return acc.concat({
              code: station.operator?.operatorId,
              mpwr: (operator?.thManpower / totalActMpwr).toFixed(1),
              eff: operatorSkill?.eff,
              height: defaultHeight,
              skills: subTypes.filter((sub: any, index: number) => subTypes.indexOf(sub) === index),
              step: operator.step,
              cycleTime: (operatorSkill?.timestudyAvgTime * 100) / (flagValue * 7) || 0,
              tct: (operator.tct * 60 * 100) / (flagValue * 7) || 0,
          });
      }, []);

      return acc.concat({
          station: key + 1,
          height: height + heightMargin - marginBottom,
          operations: removeDuplicatedOperations,
          operatorInlines: operatorInlines,
      });
  }, []);
  retrun(result);
}