import { combineReducers } from 'redux';

import { signInReducer, themeReducer, factoryReducer, globalLangDataReducer } from '../screens/login/reducer';
import { operatorReducer, skillReducer } from '../screens/admin/reducer';

import {
    lineSetupReducer,
    lineBalanceReducer,
    lineSetupInfoReducer,
    operatorInPoolReducer,
    stationReducer,
    lineSetupReplicaReducer,
    searchOperatorInPoolReducer,
    infoLineSetupReducer,
    linesSetupModelStationWiseReplicaReducer,
    lineSetupListReducer,
    lineShiftReducer,
} from '../screens/lineSetup/reducer';

import {
    lineAssistInfoReducer,
    assistTableLineSetup,
    assistReportReducer,
} from '../screens/lineBalancingAssist/reducer';

import {
    styleListReducer,
    seasonListReducer,
    customersListReducer,
    styleDetailReducer,
    lineSetupHistoryTableReducer,
    newStyleReducer,
    needleListReducer,
    machineListReducer,
    operationCodeListReducer,
    NewOperationReducer,
    sewingOperationReducer,
    sewingOperationForAddStyleReducer,
    ppaOperationReducer,
    factoriesListReducer,
    productTypeListReducer,
} from '../screens/manageStyle/reducer';

import {
    linesSetupReducer,
    shiftsReducer,
    operationCodeReducer,
    ppaCodeReducer,
    mfgLinesReducer,
    timeStudyOperatorReducer,
} from '../screens/admin/reducer';

import { nptListReducer, allNptTypeListReducer } from '../screens/npt/reducer';

import { nptCategoryListReducer, nptTypeListReducer } from '../screens/admin/manageNpt/reducer';

import { globalLoaderReducer } from './rootReducer';
import { dashboardReducer, dashboardFilterReducer } from '../screens/dashboard/reducer';
import { notificationReducer, userNameReducer, appDrawerReducer } from '../components/header/reducer';

import {
    AnalyticsFiltersReducer,
    AnalyticsEfficiencyReducer,
    AnalyticsOperatorNPTReducer,
    AnalyticsStatsReducer,
    AnalyticsSaveReducer,
    AnalyticsTemplateListReducer,
    analyticsChartReducer,
    filterLoaderReducer,
} from '../screens/anayticAndReport/reducer';

import { productSettingReducer, masterDataTranslationReducer } from '../screens/admin/productSettings/reducer';
import lbrReducer from './../screens/lineBalancingAssist/LBR/LBRHistory/Redux/reducers';

/**
 * combine all reducer into single root reducer
 */
const rootReducer = combineReducers({
    ...lbrReducer,
    signInReducer,
    infoLineSetupReducer,
    globalLoaderReducer,
    filterLoaderReducer,
    themeReducer,
    lineSetupReducer,
    styleListReducer,
    seasonListReducer,
    customersListReducer,
    productTypeListReducer,
    styleDetailReducer,
    lineBalanceReducer,
    lineSetupHistoryTableReducer,
    newStyleReducer,
    needleListReducer,
    machineListReducer,
    operationCodeListReducer,
    operatorReducer,
    skillReducer,
    NewOperationReducer,
    sewingOperationReducer,
    sewingOperationForAddStyleReducer,
    linesSetupReducer,
    shiftsReducer,
    operationCodeReducer,
    ppaCodeReducer,
    ppaOperationReducer,
    mfgLinesReducer,
    factoriesListReducer,
    lineSetupInfoReducer,
    operatorInPoolReducer,
    stationReducer,
    lineSetupReplicaReducer,
    searchOperatorInPoolReducer,
    linesSetupModelStationWiseReplicaReducer,
    nptCategoryListReducer,
    nptTypeListReducer,
    lineAssistInfoReducer,
    assistTableLineSetup,
    assistReportReducer,
    nptListReducer,
    allNptTypeListReducer,
    dashboardReducer,
    AnalyticsFiltersReducer,
    AnalyticsEfficiencyReducer,
    AnalyticsOperatorNPTReducer,
    AnalyticsStatsReducer,
    AnalyticsSaveReducer,
    AnalyticsTemplateListReducer,
    dashboardFilterReducer,
    analyticsChartReducer,
    notificationReducer,
    productSettingReducer,
    factoryReducer,
    masterDataTranslationReducer,
    timeStudyOperatorReducer,
    lineSetupListReducer,
    lineShiftReducer,
    globalLangDataReducer,
    appDrawerReducer,
});

export default rootReducer;
