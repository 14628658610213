import moment from 'moment';

/**
 * Convert time string to minute number
 * 
 * @param timeStr ie: 08:40.404 mm:ss.ms
 */
export const timeToMinute = (timeStr: any): number => {
  try {
    const arr1 = timeStr.split(":");
    const mn1 = Number(arr1[0]);
    const arr2 = arr1[1].split(".");
    const msToSec = arr2[1] / 1000;
    const mn2 = (msToSec + Number(arr2[0])) / 60;
    return mn1 + mn2;
  } catch {
    return 0;
  }
}

/**
 * Convert from minute to time
 * 
 * @param mn minute number
 * @returns 
 */
export const minuteToTime = (mn: number): string => {
  const secs = mn * 60;
  const formatted = moment.utc(secs*1000).format('mm:ss.SS');
  return formatted;
}
