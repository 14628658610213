import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { lightTheme, darkTheme } from '../components/themeSettings/theme';
import AuthContainer from '../components/authContainer';
import { ReducersModel } from '../model';
import { useSelector } from 'react-redux';
import StyleInformation from '../screens/manageStyle/styleInformation';
import EditStyle from '../screens/manageStyle/editStyle';
import ManageOperator from '../screens/admin/manageOperator';
import ProductSetting from '../screens/admin/productSettings';
import NPT from '../screens/npt';
import PrivateRoute from './private';
import ManageNPT from '../screens/admin/manageNpt/index';
import EditManageNPT from '../screens/admin/manageNpt/editNpt';
import Utils from '../utils';
import Notifications from '../screens/notification';
import AddUser from '../screens/userManagement';
import { useTranslation } from 'react-i18next';
import LineSetupForm from '../screens/lineSetup/lineSetupForm';
import { 
    LBRRestore as LBRRestoreScreen,
} from '../screens/lineBalancingAssist/LBR';

const Login = lazy(() => import('../screens/login'));
const Dashboard = lazy(() => import('../screens/dashboard'));
const LineOutput = lazy(() => import('../screens/dashboard/lineOutput'));
const LineBalancingAssist = lazy(() => import('../screens/lineBalancingAssist/index'));
const LineBalancingInfo = lazy(() => import('../screens/lineBalancingAssist/lineBalancingInfo'));
const AnalyticAndReport = lazy(() => import('../screens/anayticAndReport/index'));
const LineSetup = lazy(() => import('../screens/lineSetup/index'));
const LineSetupView = lazy(() => import('../screens/lineSetup/lineSetupView'));
const ManageStyleListing = lazy(() => import('../screens/manageStyle/index'));
const ManageStyleAdd = lazy(() => import('../screens/manageStyle/addStyle'));
const OperationCodeScreen = lazy(() => import('../screens/admin/manageOperation'));

/* InventoryAuthorize from LTlabs */
const InventoryAuthorize = lazy(() => import('../screens/authentication/inventoryAuthorize'));
/* End InventoryAuthorize from LTlabs */

/**
 * Root router component
 * @param props parameter for Router function
 */
const Router: React.FC = () => {
    const { t } = useTranslation();
    const { selectTheme } = useSelector((state: ReducersModel) => state.themeReducer);

    return (
        <React.Fragment>
            <ThemeProvider theme={selectTheme === 'light' ? lightTheme : darkTheme}>
                {' '}
                <BrowserRouter>
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={(props: any) => (
                                <Suspense
                                    fallback={
                                        <div>
                                            <span>{t('Loading')}....</span>
                                        </div>
                                    }
                                >
                                    {' '}
                                    <React.Fragment>
                                        {Utils.constants.getUserIdToken() ? (
                                            <Redirect to="/dashboard" />
                                        ) : (
                                            <AuthContainer>
                                                <Login {...props} />
                                            </AuthContainer>
                                        )}
                                    </React.Fragment>
                                </Suspense>
                            )}
                        />

                        {/* InventoryAuthorize from LTlabs */}
                        {/* <Route path={'/authorized'} component={InventoryAuthorize} exact /> */}
                        <Route
                            exact
                            path={'/authorized'}
                            render={(props: any) => (
                                <Suspense
                                    fallback={
                                        <div>
                                            <span>{t('Loading')}....</span>
                                        </div>
                                    }
                                >
                                    {' '}
                                    <React.Fragment>
                                        {/* {Utils.constants.getUserIdToken() ? (
                                            <Redirect to="/dashboard" />
                                        ) : (
                                            <AuthContainer>
                                                <InventoryAuthorize {...props} />
                                            </AuthContainer>
                                        )} */}
                                        <AuthContainer>
                                            <InventoryAuthorize {...props} />
                                        </AuthContainer>
                                    </React.Fragment>
                                </Suspense>
                            )}
                        />
                        {/* End InventoryAuthorize from LTlabs */}
                        <PrivateRoute path={'/dashboard/line-output'} component={LineOutput} />
                        <PrivateRoute path={'/dashboard'} component={Dashboard} />
                        <PrivateRoute path={'/admin/manage-operator'} component={ManageOperator} />
                        <PrivateRoute path={'/admin/product-setting'} component={ProductSetting} />
                        <PrivateRoute path={'/admin/operationCode'} component={OperationCodeScreen} />
                        <PrivateRoute path={'/admin/manage-npt-edit'} component={EditManageNPT} />
                        <PrivateRoute path={'/admin/manage-npt'} component={ManageNPT} />
                        <PrivateRoute path={'/npt'} component={NPT} />
                        <PrivateRoute path={'/line-setup-view'} component={LineSetupView} />
                        <PrivateRoute path={'/lineSetup'} component={LineSetup} />
                        <PrivateRoute path={'/lineSetup-add'} component={LineSetupForm} />

                        <PrivateRoute
                            path={'/line-balancing-assist/line-balancing-info'}
                            component={LineBalancingInfo}
                        />
                        <PrivateRoute path={'/line-balancing-assist'} component={LineBalancingAssist} />
                        <PrivateRoute path={'/lbrhistory-restore'} component={LBRRestoreScreen} />
                        <PrivateRoute path={'/analytics'} component={AnalyticAndReport} />
                        <PrivateRoute path={'/manage-style/style-list'} component={ManageStyleListing} />
                        <PrivateRoute path={'/manage-style/add-style'} component={ManageStyleAdd} />
                        <PrivateRoute path={'/manage-style/style-information'} component={StyleInformation} />
                        <PrivateRoute path={'/manage-style/edit-style'} component={EditStyle} />
                        <PrivateRoute path={'/inbox'} component={Notifications} />
                        <PrivateRoute path={'/user-management/add-user'} component={AddUser} />
                    </Switch>
                </BrowserRouter>
            </ThemeProvider>
        </React.Fragment>
    );
};

export default Router;
