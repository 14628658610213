import * as React from 'react';
import { 
    Grid,
    Typography,
    Avatar,
} from '@material-ui/core';
import { formatDate } from '../../LBRHistory/helpers';
import { minuteToTime } from '../../../../../utils/convertTime';

const format = 'yyyy-MM-DD';

export default ({ infoData, runningDays }: any) => (
    <Grid item xs={8}>
        <Grid container>
            <Grid item xs={12}>
            <Grid container style={{ marginBottom: 20, alignItems: 'center' }}>
                    <Grid item xs={6}><Typography variant='h6'>{infoData.lineName}</Typography></Grid>
                    <Grid item xs={6} style={{ textAlign: 'end' }}>
                        <Typography variant='subtitle2' style={{ color: '#777', marginRight: 10 }}>
                            <strong>Updated at {formatDate(infoData.updatedDt, 'HH:ss')} </strong> 
                            {infoData.shift}, {formatDate(infoData.updatedDt, 'dddd Do MMMM YYYY')}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={10}>
                
                <Grid container>
                    <Grid item xs={4} style={{ marginBottom: 10 }}>
                        <Typography style={{ color: '#888' }} variant='caption'>{'Style name'}</Typography>
                        <Typography variant='body1'>{infoData.styleData.title}</Typography>
                    </Grid>
                    <Grid item xs={4} style={{ marginBottom: 10 }}>
                        <Typography style={{ color: '#888' }} variant='caption'>{'Total Target Cycle Time'}</Typography>
                        <Typography variant='body1'>{minuteToTime(infoData.totalTct)}</Typography>
                    </Grid>
                    <Grid item xs={2} style={{ marginBottom: 10 }}>
                        <Typography style={{ color: '#888' }} variant='caption'>{'Line'}</Typography>
                        <Typography variant='body1'>{infoData.line}</Typography>
                    </Grid>
                    <Grid item xs={2} style={{ marginBottom: 10 }}>
                        <Typography style={{ color: '#888' }} variant='caption'>{'Order No.'}</Typography>
                        <Typography variant='body1'>{infoData.orderNo}</Typography>
                    </Grid>
                    
                    <Grid item xs={4} style={{ marginBottom: 10 }}>
                        <Typography style={{ color: '#888' }} variant='caption'>{'Style Code'}</Typography>
                        <Typography variant='body1'>{infoData.styleData.code}</Typography>
                    </Grid>
                    <Grid item xs={4} style={{ marginBottom: 10 }}>
                        <Typography style={{ color: '#888' }} variant='caption'>{'Start Date & Shift'}</Typography>
                        <Typography variant='body1'>{formatDate(infoData.startDate, format)} {infoData.startShift}</Typography>
                    </Grid>
                    <Grid item xs={2} style={{ marginBottom: 10 }}>
                        <Typography style={{ color: '#888' }} variant='caption'>{'Quantity (pcs)'}</Typography>
                        <Typography variant='body1'>{infoData.quantity}</Typography>
                    </Grid>
                    <Grid item xs={2} style={{ marginBottom: 10 }}>
                        <Typography style={{ color: '#888' }} variant='caption'>{'Customer'}</Typography>
                        <Typography variant='body1'>{infoData.customer}</Typography>
                    </Grid>
                    <Grid item xs={4} style={{ marginBottom: 10 }}>
                        <Typography style={{ color: '#888' }} variant='caption'>{'Total SMV'}</Typography>
                        <Typography variant='body1'>{infoData.totalWebSmv}</Typography>
                    </Grid>
                    <Grid item xs={4} style={{ marginBottom: 10 }}>
                        <Typography style={{ color: '#888' }} variant='caption'>{'End Date & Shift'}</Typography>
                        <Typography variant='body1'>{formatDate(infoData.endDate, format)} {infoData.endShift}</Typography>
                    </Grid>
                    <Grid item xs={2} style={{ marginBottom: 10 }}>
                        <Typography style={{ color: '#888' }} variant='caption'>{'Target EFF'}</Typography>
                        <Typography variant='body1'>{infoData.targetEff}%</Typography>
                    </Grid>
                    <Grid item xs={2} style={{ marginBottom: 10 }}>
                        <Typography style={{ color: '#888' }} variant='caption'>{'Running Days'}</Typography>
                        <Typography variant='body1'>
                            {runningDays || '-'}
                        </Typography>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={2} style={{ height: '100%' }}>
                <Typography style={{ color: '#888' }} variant='caption'>{'Style Image'}</Typography>
                <Avatar style={{ width: '100%', height: '100%'}} variant='rounded' src={infoData.styleData.image1}/>
            </Grid>
        </Grid>
    </Grid>
);