import { styled } from '@material-ui/core';
import React from 'react';

const LBRLegenLine = ({ color = '#000', size = 26 }: any) => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={size}
        height={size}
        data-name='Component 73 – 2'
        viewBox='0 0 20 20'
      >
        <defs>
          <clipPath id='clip-path'>
            <path
              fill='none'
              stroke='#707070'
              strokeWidth='1'
              d='M0 0H20V20H0z'
              data-name='Rectangle 9926'
              transform='translate(983 692)'
            ></path>
          </clipPath>
        </defs>
        <g
          clipPath='url(#clip-path)'
          data-name='Mask Group 327'
          transform='translate(-983 -692)'
        >
          <g data-name='Group 13019' transform='translate(154 351)'>
            <g
              stroke={color}
              data-name='Group 12887'
              transform='translate(0 1)'
            >
              <path
                fill='rgba(0,0,0,0)'
                strokeWidth='4'
                d='M849 350h-20'
                data-name='Path 1513'
              ></path>
              <g
                fill='#fff'
                strokeWidth='1'
                data-name='Ellipse 623'
                transform='translate(835 346)'
              >
                <circle cx='4' cy='4' r='4' stroke='none'></circle>
                <circle cx='4' cy='4' r='3.5' fill='none'></circle>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  };

  const LBRLegenBar = styled('div')(({ color = '#000', size = 15 }: any) => ({
    display: 'block',
    width: size,
    height: size,
    background: color,
    borderRadius: 3,
  }));

  export const LBRLegen = ({ type = 'line', color = '#000', size = 26 }: any) => {
    if(type === 'bar'){
      return (
        <LBRLegenBar color={color} />
      )
    }
    return (<LBRLegenLine color={color} size={size} />);
  }