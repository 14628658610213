import React, { useEffect } from 'react';
import {
    Typography,
    makeStyles,
    createStyles,
    Theme,
    TextField,
    InputAdornment,
    Button,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    TablePagination,
    Avatar,
    Menu,
    MenuItem,
    Divider,
    FormControlLabel,
    Checkbox,
    FormControl,
    Select,
} from '@material-ui/core';
import Utils from '../../../utils';
//@ts-ignore
import Highlighter from 'react-highlight-words';
import AddIcon from '@material-ui/icons/Add';
import MultimediaButton from '../../../components/buttons/multimediaButton';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { useDispatch, useSelector } from 'react-redux';
import LocalImages from '../../../utils/images';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { ReducersModel } from '../../../model';
import SimpleModal from '../../../components/modal';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { getOperationCode, getPpaCode, getFactoriesList, deleteOperationCode } from '../action';
import AddOperationCodeForm from './addOperationCodeForm';
import moment from 'moment';
import AddppaForm from './addPpaForm';
import BulkOperationUpload from './bulkOperationUpload';
import DeleteModal from '../../../components/modal/deleteModal';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { getMDMApi, deleteMDM, getSkillMDMApi, getSkillAll } from './action';
import AddMDMForm from './addMDMFrom';
// import FeaturesFilter from '../../../components/filters/features';
import OperationCodesFilter from '../../../components/filters/features';
import { ImageCaurosel } from '../../../components/caurosel/imagecaurosel';
import { minuteToTime } from '../../../utils/convertTime';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            padding: '0 16px 16px 16px',
            backgroundColor: 'var(--white)',
            marginTop: '10px',
        },
        textStyl: {
            fontSize: '18px',
            textAlign: 'center',
        },
        styleOperations: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '18px',
        },
        leftStyleOperations: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
        },
        searchInput: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            width: '300px',
            '& .MuiInputBase-root': {
                height: '36px',
                font: 'normal normal normal 14px/20px Roboto',
                letterSpacing: '0.05px',
                color: '#515151',
                opacity: 1,
            },
        },
        searchIcon: {
            cursor: 'pointer',
        },
        addButton: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            opacity: 1,
            margin: '0 15px',
            border: '1px solid #1E88E5',
            font: 'normal normal normal 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#1E88E5',
            textTransform: 'uppercase',
            height: '36px',
            width: '72px',
        },
        filterButton: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            opacity: 1,
            border: '1px solid #DFDFDF',
            font: 'normal normal normal 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: 'var(--title-light)',
            textTransform: 'uppercase',
            height: '36px',
        },
        operationCodeContainer: {
            overflow: 'auto',
            maxHeight: '57vh',
            position: 'relative',
            paddingBottom: '10px',
            bottom: '0px',
        },
        categories: {
            padding: '9px 7px 7px 7px',
            letterSpacing: '0.08px',
            textTransform: 'uppercase',
            opacity: 1,
            wordBreak: 'keep-all',
            background: 'var(--background-grey) 0% 0% no-repeat padding-box',
            '& th': {
                font: 'normal normal normal 14px/21px Roboto',
                fontWeight: 'bold',
                verticalAlign: 'top',
                padding: '7px 10px',
                color: 'var(--input-fill)',
                whiteSpace: 'nowrap',
                '& > span': {
                    cursor: 'pointer',
                },
                [theme.breakpoints.up(1050)]: {
                    whiteSpace: 'nowrap',
                },
            },
        },
        categoriesItems: {
            borderLeft: 'solid 5px rgb(0,0,0,0)',
            '& td': {
                font: 'normal normal normal 14px/21px Roboto',
                padding: '7px 10px',
                color: 'var(--input-fill)',
                letterSpacing: '0.08px',
                opacity: 1,
                verticalAlign: 'top',
            },
            '&>td:nth-child(4)': {
                wordBreak: 'break-word',
                minWidth: '150px',
            },
            '&>td:nth-child(5)': {
                minWidth: '103px',
            },
            '&>td:nth-child(14)': {
                whiteSpace: 'nowrap',
                '& svg': {
                    cursor: 'pointer',
                    color: 'transparent',
                    width: '30px',
                },
            },
            '&:hover': {
                borderLeft: 'solid 5px var(--blue)',
                '&>td:nth-child(14)': {
                    '& svg': {
                        color: 'var(--blue)',
                        transition: '1s all',
                    },
                },
            },
        },
        masterCategoriesItems: {
            borderLeft: 'solid 5px rgb(0,0,0,0)',
            '& td': {
                font: 'normal normal normal 14px/21px Roboto',
                padding: '7px 10px',
                color: 'var(--input-fill)',
                letterSpacing: '0.08px',
                opacity: 1,
                verticalAlign: 'top',
            },
        },
        inActive: {
            font: 'normal normal normal 14px/21px Roboto',
            padding: '5px',
            color: 'var(--dark-grey)',
            letterSpacing: '0.08px',
            opacity: 0.6,
            wordBreak: 'break-word',
            '&>td': {
                fontStyle: 'italic',
            },
        },
        editIcon: {
            color: 'var(--blue)',
            cursor: 'pointer',
            width: '30px',
        },
        selectStyl: {
            width: '225px',
            marginBottom: '10px',
            textAlign: 'left',
            display: 'flex',
            '& .MuiSelect-selectMenu': {
                padding: '10px !important',
                font: 'normal normal medium 16px/20px Roboto',
                letterSpacing: '0.06px',
                color: 'var(--dark-grey)',
                opacity: '1',
            },
        },
        dwnIcnStyl: {
            cursor: 'pointer',
            width: '13px',
            verticalAlign: 'middle',
            transition: '1s all',
        },
        rotateIcon: {
            transform: 'rotate(180deg)',
            transition: '1s all',
        },
        paginationContainer: {
            marginTop: '10px',
            backgroundColor: '#F7F8FA',
            '& .MuiTypography-root': {
                textAlign: 'left',
                font: 'normal normal normal 14px/14px Roboto',
                letterSpacing: '0px',
                color: '#515151',
                opacity: 1,
            },
        },
        hidePagination: {
            display: 'none',
        },
        filterCount: {
            minWidth: '24px',
            minHeight: '24px',
            width: '1em',
            height: '1em',
            textAlign: 'center',
            font: 'normal normal normal 14px/20px Roboto!important',
            letterSpacing: '0.05px',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            opacity: 1,
            backgroundColor: '#1E88E5',
        },
        filterMenu: {
            '& .MuiList-root': {
                width: '194px',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: '0px 0px 6px #00000029',
                opacity: 1,
            },
        },
        noData: {
            display: 'flex',
            justifyContent: 'center',
            font: 'normal normal normal 16px/16px Roboto',
            color: '#515151',
            marginTop: '10px',
            marginBottom: '100px',
        },
        tileHeading: {
            font: 'normal normal normal 20px/24px Roboto',
            letterSpacing: '0.07px',
            color: '#515151',
            marginBottom: '16px',
            paddingTop: '10px',
        },
        alignActionIcons: {
            '& svg': {
                color: '#1E88E5 !important',
                cursor: 'pointer',
            },
        },
        mainContainerFilter: {
            minWidth: '210px',
        },
        title: {
            padding: '0 16px 8px 16px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
        subTitle: {
            padding: '8px 16px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
        filtersBox: {
            padding: '8px 16px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
            display: 'flex',
            flexDirection: 'column',
            '& .MuiTypography-root': {
                textAlign: 'left',
                font: 'normal normal normal 12px/21px Roboto',
                letterSpacing: '0.07px',
                color: '#868686',
                opacity: 1,
            },
            '& .MuiCheckbox-root': {
                padding: '2px 7px',
            },
            '& .MuiFormGroup-root': { flexDirection: 'row!important' },
        },
        naturalCheck: {
            '& .MuiTypography-root': {
                color: '#515151',
            },
        },
        imgContainer: {
            display: 'flex',
        },
        styleImageContainer: {
            display: 'flex',
            '& > div': {
                marginRight: '4px',
                marginBottom: '4px',
                '&:last-child': {
                    marginRight: '4px',
                    marginBottom: '0px',
                },
            },
        },
        styleImageBG: {
            width: '30px',
            height: '30px',
            cursor: 'pointer',
            border: '0.75px solid',
        },
    }),
);

export default function OperationCode(props: any) {
    const classes = styles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {
        operationCodeData,
        page,
        limit,
        count,
        filterFactoryIds,
        filterStatus,
        filterSkillIds,
        searchOperation,
    } = useSelector((state: ReducersModel) => state.operationCodeReducer);
    const { factoriesData } = useSelector((state: ReducersModel) => state.factoriesListReducer);
    const { skillTypeData } = useSelector((state: ReducersModel) => state.ppaCodeReducer);
    const { firstLang, secondLang } = useSelector((state: ReducersModel) => state.globalLangDataReducer);

    const {
        ppaCodeData,
        pagePpa,
        limitPpa,
        countPpa,
        filterStatus: ppaFilterStatus,
        selectedType,
        filterCount,
        isInActiveMDM,
        isActiveMDM,
        searchMasterData,
    } = useSelector((state: ReducersModel) => state.ppaCodeReducer);
    const [isBulk, setIsBulk] = React.useState(false);
    const [isSort, setIsSort] = React.useState(false);
    const [isUpdatedDateSort, setIsUpdatedDateSort] = React.useState(false);
    const [isAddOperation, setIsAddOperation] = React.useState(false);
    const [openCodeDelete, setOpenCodeDelete] = React.useState(false);
    const [openPPADelete, setOpenPPADelete] = React.useState(false);
    const [operationCodeIdToDelete, setOperationCodeIdToDelete] = React.useState(null);
    const [idToDelete, setIdToDelete] = React.useState(null);
    const [isAddPpa, setIsAddPPA] = React.useState(false);
    const [isOpenAddForm, setIsOpenAddForm] = React.useState(false);
    const [openImage, setOpenImage] = React.useState(false);
    const [imgToOpen, setImgToOpen] = React.useState(0);
    const [images, setImages] = React.useState<string[]>([]);

    //for handle Caurosel open
    const handleImageOpen = (idx: number) => {
        setImgToOpen(idx);
        setOpenImage(true);
    };
    const handleImageClose = () => {
        setOpenImage(false);
    };

    // for Deleting Operation Code
    const handleOpenCodeDelete = () => {
        setOpenCodeDelete(true);
    };
    const handleCloseCodeDelete = () => {
        setOpenCodeDelete(false);
    };

    // for Deleting PPA
    const handleOpenPPADelete = () => {
        setOpenPPADelete(true);
    };
    const handleClosePPADelete = () => {
        setOpenPPADelete(false);
    };

    const handleImageArray = (row: any) => {
        const tempArray = [];

        for (var i = 1; i <= 3; i++) {
            if (row[`machineAttachment${i}Data`] !== null) {
                const { image1, attachmentCode } = row[`machineAttachment${i}Data`];
                tempArray.push(Utils.CommonFunctions.getImageWithOperationCode(attachmentCode, image1));
            }
        }
        setImages(tempArray);
    };

    const callApis = () => {
        dispatch(getMDMApi(selectedType));
    };
    const handleSearch = (event: any) => {
        if (props.isProductSetting) {
            dispatch({
                type: Utils.ActionName.UPDATE_PPA_CODE,
                payload: {
                    searchMasterData: event.target.value,
                },
            });
            callApis();
        } else {
            dispatch({
                type: Utils.ActionName.UPDATE_OPERRATION_CODE,
                payload: {
                    searchOperation: event.target.value,
                },
            });
            dispatch(getOperationCode());
        }
    };
    useEffect(() => {
        dispatch({
            type: Utils.ActionName.UPDATE_OPERRATION_CODE,
            payload: {
                operationCodeData: [],
                page: 1,
                count: 0,
                filterStatus: ['true'],
                searchOperation: '',
            },
        });
        dispatch({
            type: Utils.ActionName.UPDATE_PPA_CODE,
            payload: {
                ppaCodeData: [],
                pagePpa: 1,
                countPpa: 0,
                filterStatus: ['true'],
                searchMasterData: '',
                limitPpa: 10,
            },
        });

        if (props.isProductSetting) {
            dispatch(getMDMApi(selectedType));
        } else {
            dispatch({
                type: Utils.ActionName.UPDATE_PPA_CODE,
                payload: {
                    limitPpa: 10000,
                },
            });
            dispatch(getOperationCode());
            dispatch(getSkillMDMApi());
            dispatch(getMDMApi(3));
        }
        dispatch(getFactoriesList());

        return () => {
            dispatch({
                type: Utils.ActionName.UPDATE_PPA_CODE,
                payload: {
                    ppaCodeData: [],
                    isActiveMDM: true,
                    isInActiveMDM: false,
                },
            });
        };
    }, [dispatch, props.isProductSetting]);

    const handleSort = (key: any) => {
        setIsSort(!isSort);
        setIsUpdatedDateSort(false);

        if (isSort) {
            dispatch({
                type: props.isProductSetting
                    ? Utils.ActionName.UPDATE_PPA_CODE
                    : Utils.ActionName.UPDATE_OPERRATION_CODE,
                payload: {
                    order: 'ASC',
                    sortBy: key,
                },
            });
        } else {
            dispatch({
                type: props.isProductSetting
                    ? Utils.ActionName.UPDATE_PPA_CODE
                    : Utils.ActionName.UPDATE_OPERRATION_CODE,
                payload: {
                    order: 'DESC',
                    sortBy: key,
                },
            });
        }
        if (props.isProductSetting) {
            callApis();
        } else {
            dispatch(getOperationCode());
        }
    };

    const handleUpdatedDateSort = (key: any) => {
        setIsUpdatedDateSort(!isUpdatedDateSort);
        setIsSort(false);
        if (isUpdatedDateSort) {
            dispatch({
                type: props.isProductSetting
                    ? Utils.ActionName.UPDATE_PPA_CODE
                    : Utils.ActionName.UPDATE_OPERRATION_CODE,
                payload: {
                    order: 'ASC',
                    sortBy: key,
                },
            });
        } else {
            dispatch({
                type: props.isProductSetting
                    ? Utils.ActionName.UPDATE_PPA_CODE
                    : Utils.ActionName.UPDATE_OPERRATION_CODE,
                payload: {
                    order: 'DESC',
                    sortBy: key,
                },
            });
        }
        if (props.isProductSetting) {
            dispatch(getPpaCode());
        } else {
            dispatch(getOperationCode());
        }
    };

    // for PPA filter

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorEl(null);
    };
    const hndleChngActive = (event: any) => {
        const target = event.target.checked;
        dispatch({
            type: Utils.ActionName.UPDATE_PPA_CODE,
            payload: {
                isActiveMDM: target,
                filterCount: target ? filterCount + 1 : filterCount - 1,
            },
        });

        callApis();
    };

    const hndleChngInActive = (event: any) => {
        const target = event.target.checked;
        dispatch({
            type: Utils.ActionName.UPDATE_PPA_CODE,
            payload: {
                isInActiveMDM: target,
                filterCount: target ? filterCount + 1 : filterCount - 1,
            },
        });

        callApis();
    };
    const insideMenuFilter = () => {
        return (
            <div className={classes.mainContainerFilter}>
                <div className={classes.title}>{t('Filterby')}</div>
                <Divider />
                <div>
                    <div className={classes.subTitle}>{t('Status')}</div>
                    <div className={classes.filtersBox}>
                        <FormControlLabel
                            className={classes.naturalCheck}
                            control={<Checkbox color="primary" checked={isActiveMDM} onChange={hndleChngActive} />}
                            label={t('Active')}
                        />
                        <FormControlLabel
                            className={classes.naturalCheck}
                            control={<Checkbox color="primary" checked={isInActiveMDM} onChange={hndleChngInActive} />}
                            label={t('Inactive')}
                        />
                    </div>
                </div>
            </div>
        );
    };
    const tableOpertaion = () => {
        return (
            <>
                <div className={classes.operationCodeContainer}>
                    <Table stickyHeader={true} aria-label="sticky table">
                        <TableHead>
                            <TableRow className={classes.categories}>
                                <TableCell align="center">#</TableCell>
                                <TableCell>
                                    <span onClick={() => handleSort('code')}>
                                        {t('OperationCode')}
                                        <ArrowDownwardIcon
                                            className={
                                                isSort
                                                    ? classes.dwnIcnStyl
                                                    : `${classes.rotateIcon} ${classes.dwnIcnStyl}`
                                            }
                                        />
                                    </span>
                                </TableCell>
                                <TableCell>{t('DisplayName')}</TableCell>
                                <TableCell>{t('DESCRIPTION')}</TableCell>
                                <TableCell>{t('Attachement')}</TableCell>
                                <TableCell>{t('SMV')}</TableCell>
                                <TableCell title='Target Cycle Time'>{t('TCT')}</TableCell>
                                <TableCell>{t('SkillType')}</TableCell>
                                <TableCell>{t('Status')}</TableCell>
                                <TableCell>{t('MODIFIEDBY')}</TableCell>
                                <TableCell>
                                    <span onClick={() => handleUpdatedDateSort('updatedDt')}>
                                        {t('DATEMODIFIED')}
                                        <ArrowDownwardIcon
                                            className={
                                                isUpdatedDateSort
                                                    ? classes.dwnIcnStyl
                                                    : `${classes.rotateIcon} ${classes.dwnIcnStyl}`
                                            }
                                        />
                                    </span>
                                </TableCell>
                                <TableCell>{t('Createdby')}</TableCell>
                                <TableCell>{t('CREATEDBYFACTORY')}</TableCell>
                                <TableCell>{t('DateCreated')}</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {operationCodeData && operationCodeData.length > 0 ? (
                                operationCodeData.map((row: any, index: any) => (
                                    <TableRow
                                        className={`${classes.categoriesItems} ${!row.isActive ? classes.inActive : ''
                                            }`}
                                        key={row.id}
                                    >
                                        <TableCell align="center">{index + 1}</TableCell>
                                        <TableCell>
                                            <Highlighter
                                                highlightClassName="YourHighlightClass"
                                                searchWords={[searchOperation]}
                                                autoEscape={true}
                                                textToHighlight={row.code}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Highlighter
                                                highlightClassName="YourHighlightClass"
                                                searchWords={[searchOperation]}
                                                autoEscape={true}
                                                textToHighlight={row.displayName}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Highlighter
                                                highlightClassName="YourHighlightClass"
                                                searchWords={[searchOperation]}
                                                autoEscape={true}
                                                textToHighlight={row.desc1}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <div className={classes.styleImageContainer}>
                                                {row.machineAttachment1Data !== null && (
                                                    <div
                                                        onClick={() => {
                                                            handleImageArray(row);
                                                            handleImageOpen(0);
                                                        }}
                                                    >
                                                        <img
                                                            className={classes.styleImageBG}
                                                            src={Utils.CommonFunctions.getImageWithOperationCode(
                                                                row.machineAttachment1Data.attachmentCode,
                                                                row.machineAttachment1Data.image1,
                                                            )}
                                                            alt={''}
                                                        />
                                                    </div>
                                                )}
                                                {row.machineAttachment2Data !== null && (
                                                    <div
                                                        onClick={() => {
                                                            handleImageArray(row);
                                                            handleImageOpen(1);
                                                        }}
                                                    >
                                                        <img
                                                            className={classes.styleImageBG}
                                                            src={Utils.CommonFunctions.getImageWithOperationCode(
                                                                row.machineAttachment2Data.attachmentCode,
                                                                row.machineAttachment2Data.image1,
                                                            )}
                                                            alt={''}
                                                        />
                                                    </div>
                                                )}
                                                {row.machineAttachment3Data !== null && (
                                                    <div
                                                        onClick={() => {
                                                            handleImageArray(row);
                                                            handleImageOpen(2);
                                                        }}
                                                    >
                                                        <img
                                                            className={classes.styleImageBG}
                                                            src={Utils.CommonFunctions.getImageWithOperationCode(
                                                                row.machineAttachment3Data.attachmentCode,
                                                                row.machineAttachment3Data.image1,
                                                            )}
                                                            alt={''}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            {row.smv && row.smv !== null
                                                ? Utils.CommonFunctions.maxDecimalValue(row.smv, 4)
                                                : '-'}
                                        </TableCell>
                                        <TableCell>
                                            {row.tct
                                                ? minuteToTime(row.tct)
                                                : 'N/A'}
                                        </TableCell>
                                        <TableCell>{row.skillTypeCode}</TableCell>
                                        <TableCell>{row.isActive ? 'Active' : 'Inactive'}</TableCell>
                                        <TableCell>{row.updatedBy ? row.updatedBy : '-'}</TableCell>
                                        <TableCell>
                                            {row.updatedDt !== null ? moment(row.updatedDt).format('YYYY-MM-DD') : '-'}
                                        </TableCell>
                                        <TableCell>{row.createdBy}</TableCell>
                                        <TableCell>{row.factoryData ? row.factoryData.factory : '-'}</TableCell>
                                        <TableCell>{moment(row.createdDt).format('YYYY-MM-DD')}</TableCell>
                                        <TableCell align="right" className={classes.alignActionIcons}>
                                            <EditIcon
                                                className={classes.editIcon}
                                                onClick={() =>
                                                    handleOpenEdit(
                                                        row.code,
                                                        row.displayName,
                                                        row.smv,
                                                        row.tct,
                                                        row.desc1,
                                                        row.id,
                                                        row.skillTypeId,
                                                        row.skillTypeName,
                                                        row.skillTypeCode,
                                                        row.isActive,
                                                        row.createdBy,
                                                        row.createdDt,
                                                        row.updatedBy,
                                                        row.updatedDt,
                                                        row.factoryData?.factory,
                                                        row[`displayName_${firstLang}`],
                                                        row[`displayName_${secondLang}`],
                                                        row.machineAttachmentId1,
                                                        row.machineAttachmentId2,
                                                        row.machineAttachmentId3,
                                                    )
                                                }
                                            />
                                            <DeleteIcon
                                                color={'primary'}
                                                onClick={() => {
                                                    handleOpenCodeDelete();
                                                    setOperationCodeIdToDelete(row.id);
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={14} align="center">
                                        <div className={classes.noData}>{t('NoRecords')}</div>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <div className={classes.paginationContainer}>
                    <TablePagination
                        component="div"
                        count={count}
                        page={page - 1}
                        onPageChange={handleChangePage}
                        rowsPerPage={limit}
                        labelDisplayedRows={({ from, to, count, page }) => `  ${from}- ${to} of  ${count} `}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </>
        );
    };

    const getFilterCount = () => {
        return +filterFactoryIds.length + filterStatus.length + filterSkillIds.length;
    };

    const filter = () => {
        return (
            <>
                <div className={classes.styleOperations}>
                    <div className={classes.leftStyleOperations}>
                        <TextField
                            className={classes.searchInput}
                            variant="outlined"
                            placeholder={t('Search')}
                            onChange={handleSearch}
                            inputProps={{
                                autoComplete: 'off',
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img
                                            alt={t('Search')}
                                            src={LocalImages.SEARCH_ICON}
                                            className={classes.searchIcon}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<AddIcon />}
                            className={classes.addButton}
                            onClick={() => {
                                dispatch({
                                    type: Utils.ActionName.UPDATE_OPERRATION_CODE,
                                    payload: {
                                        isEditOperation: false,
                                    },
                                });
                                dispatch({
                                    type: Utils.ActionName.UPDATE_PPA_CODE,
                                    payload: {
                                        isEditPpA: false,
                                    },
                                });
                                props.isProductSetting === false
                                    ? setIsAddOperation(true)
                                    : selectedType === 1
                                        ? setIsAddPPA(true)
                                        : setIsOpenAddForm(true);
                            }}
                        >
                            {t('Add')}
                        </Button>
                        {props.isProductSetting === false ? (
                            <MultimediaButton
                                icon={LocalImages.CLOUD_UPLOAD}
                                text={t('BulkUpload')}
                                onClick={() => {
                                    setIsBulk(true);
                                }}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                    <div>
                        {props.isProductSetting === false ? (
                            <React.Fragment>
                                <Button
                                    // filter for operation
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<FormatListBulletedIcon />}
                                    className={classes.filterButton}
                                    endIcon={<Avatar className={classes.filterCount}>{getFilterCount()}</Avatar>}
                                    onClick={handleOpenFilter}
                                >
                                    {t('Filter')}
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleCloseFilter}
                                    className={classes.filterMenu}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    getContentAnchorEl={null}
                                >
                                    <OperationCodesFilter factories={factoriesData} skills={skillTypeData} />
                                </Menu>
                            </React.Fragment>
                        ) : (
                            <>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<FormatListBulletedIcon />}
                                    className={classes.filterButton}
                                    endIcon={<Avatar className={classes.filterCount}>{filterCount}</Avatar>}
                                    onClick={handleOpenFilter}
                                >
                                    {t('Filter')}
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleCloseFilter}
                                    className={classes.filterMenu}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    getContentAnchorEl={null}
                                >
                                    {insideMenuFilter()}
                                </Menu>
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const handleOpenEdit = (
        operatorCode: any,
        displayName: any,
        smv: any,
        tct: number,
        desc: any,
        id: any,
        skillTypeId: any,
        skillTypeName: string,
        skillTypeCode: string,
        isActive: any,
        createdBy: any,
        createdDt: any,
        updatedBy: any,
        updatedDt: any,
        factoryName: any,
        firstLangData: string,
        secongLangData: string,
        machineAttachmentId1: number,
        machineAttachmentId2: number,
        machineAttachmentId3: number,
    ) => {
        dispatch({
            type: Utils.ActionName.UPDATE_OPERRATION_CODE,
            payload: {
                isEditOperation: true,
                operationCodeEdit: operatorCode,
                displayNameEdit: displayName,
                smvEdit: smv,
                tctEdit: tct,
                descEdit: desc,
                selectedSkillType: skillTypeId,
                selectedSkillTypeName: skillTypeName,
                selectedSkillTypeCode: skillTypeCode,
                idEdit: id,
                selectedisActive: isActive,
                createdBy,
                createdDt,
                updatedBy,
                updatedDt,
                factoryName,
                firstLangDataEdit: firstLangData,
                secondLangDataEdit: secongLangData,
                selectedMachineId1: machineAttachmentId1,
                selectedMachineId2: machineAttachmentId2,
                selectedMachineId3: machineAttachmentId3,
            },
        });
        setIsAddOperation(true);
    };
    const handleOpenMDM = (rowData: any) => {
        setIsOpenAddForm(true);

        let typeData = '';
        switch (selectedType) {
            case 2:
                typeData = rowData.productType;
                break;
            case 3:
                typeData = rowData.attachmentCode;

                break;
            case 4:
                typeData = rowData.needleType;
                break;
            case 5:
                typeData = rowData.skillType;

                break;
            case 6:
                typeData = rowData.subType;

                break;
        }
        dispatch({
            type: Utils.ActionName.UPDATE_PPA_CODE,
            payload: {
                isEditPpA: true,
                ppaName: typeData,
                descEdit: rowData.desc1,
                idEdit: rowData.id,
                selectedisActive: rowData.isActive,
                createdBy: rowData.createdBy,
                createdDt: rowData.createdDt,
                updatedBy: rowData.updatedBy,
                updatedDt: rowData.updatedDt,
                displayName: rowData.displayName,
                hostedImages: selectedType === 3 ? [rowData.image1] : [],
                firstLangDataEdit: rowData[`displayName_${firstLang}`],
                secondLangDataEdit: rowData[`displayName_${secondLang}`],
            },
        });
    };
    const handleOpenPpaEdit = (row: any) => {
        const { name, desc1, id, isActive, createdBy, createdDt, updatedBy, updatedDt } = row;
        dispatch({
            type: Utils.ActionName.UPDATE_PPA_CODE,
            payload: {
                isEditPpA: true,
                ppaName: name,
                descEdit: desc1,
                idEdit: id,
                selectedisActive: isActive,
                createdBy,
                createdDt,
                updatedBy,
                updatedDt,
                firstLangDataEdit: row[`name_${firstLang}`],
                secondLangDataEdit: row[`name_${secondLang}`],
            },
        });
        setIsAddPPA(true);
    };
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        dispatch({
            type: Utils.ActionName.UPDATE_OPERRATION_CODE,
            payload: {
                page: newPage + 1,
                operationCodeData: [],
            },
        });
        dispatch(getOperationCode());
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({
            type: Utils.ActionName.UPDATE_OPERRATION_CODE,
            payload: {
                limit: event.target.value,
                page: 1,
                operationCodeData: [],
            },
        });
        dispatch(getOperationCode());
    };

    const handleChangePagePpa = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        dispatch({
            type: Utils.ActionName.UPDATE_PPA_CODE,
            payload: {
                pagePpa: newPage + 1,
                ppaCodeData: [],
            },
        });
        callApis();
    };

    const handleChangeRowsPerPagePpa = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({
            type: Utils.ActionName.UPDATE_PPA_CODE,
            payload: {
                limitPpa: event.target.value,
                operationCodeData: [],
            },
        });
        callApis();
    };

    const masterDataManagementTable = () => {
        return (
            <>
                <Table stickyHeader={true} aria-label="sticky table">
                    <TableHead>
                        <TableRow className={classes.categories}>
                            <TableCell align="center">#</TableCell>
                            <TableCell>
                                <span onClick={() => handleSort('name')}>
                                    {selectedType === 1
                                        ? 'FEATURE NAME'
                                        : selectedType === 3
                                            ? 'ATTACHMENT CODE'
                                            : selectedType === 4
                                                ? 'NEEDLE CODE'
                                                : selectedType === 5
                                                    ? ' SKILL TYPE CODE'
                                                    : selectedType === 2
                                                        ? ' PRODUCT TYPE CODE'
                                                        : selectedType === 6
                                                            ? ' MACHINE CODE'
                                                            : 'NAME'}
                                    <ArrowDownwardIcon
                                        className={
                                            isSort ? classes.dwnIcnStyl : `${classes.rotateIcon} ${classes.dwnIcnStyl}`
                                        }
                                    />
                                </span>
                            </TableCell>
                            {selectedType === 3 ||
                                selectedType === 4 ||
                                selectedType === 5 ||
                                selectedType === 2 ||
                                selectedType === 6 ? (
                                <TableCell>DISPLAY NAME</TableCell>
                            ) : (
                                ''
                            )}
                            <TableCell>{t('DESCRIPTION')}</TableCell>
                            {selectedType === 3 ? <TableCell>{'ATTACHMENT'}</TableCell> : ''}
                            <TableCell>{t('Status')}</TableCell>
                            <TableCell>{t('MODIFIEDBY')}</TableCell>
                            <TableCell>{t('DATEMODIFIED')}</TableCell>
                            <TableCell>{t('Createdby')}</TableCell>
                            <TableCell>{t('DateCreated')}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ppaCodeData && ppaCodeData.length > 0 ? (
                            ppaCodeData.map((row: any, index: any) => (
                                <TableRow
                                    className={`${classes.masterCategoriesItems} ${!row.isActive ? classes.inActive : ''
                                        }`}
                                    key={row.id}
                                >
                                    <TableCell align="center">{index + 1}</TableCell>
                                    <TableCell>
                                        <Highlighter
                                            highlightClassName="YourHighlightClass"
                                            searchWords={[searchMasterData]}
                                            autoEscape={true}
                                            textToHighlight={
                                                selectedType === 1
                                                    ? row.name
                                                    : selectedType === 2
                                                        ? row.productType
                                                        : selectedType === 3
                                                            ? row.attachmentCode
                                                            : selectedType === 4
                                                                ? row.needleType
                                                                : selectedType === 5
                                                                    ? row.skillType
                                                                    : selectedType === 6
                                                                        ? row.subType
                                                                        : row.name
                                            }
                                        />
                                    </TableCell>
                                    {selectedType === 3 ||
                                        selectedType === 4 ||
                                        selectedType === 5 ||
                                        selectedType === 2 ||
                                        selectedType === 6 ? (
                                        <TableCell>{row.displayName}</TableCell>
                                    ) : (
                                        ''
                                    )}
                                    <TableCell>{row.desc1}</TableCell>
                                    {selectedType === 3 ? (
                                        <TableCell>
                                            <div
                                                onClick={() => {
                                                    setImages([row.image1 != '' ? row.image1 : LocalImages.NOIMAGE]);
                                                    handleImageOpen(0);
                                                }}
                                            >
                                                <img
                                                    src={Utils.CommonFunctions.getImage(row.image1)}
                                                    className={classes.styleImageBG}
                                                    alt={''}
                                                />
                                            </div>
                                        </TableCell>
                                    ) : (
                                        ''
                                    )}
                                    <TableCell>{row.isActive ? t('Active') : t('Inactive')}</TableCell>
                                    <TableCell>{row.updatedBy ? row.updatedBy : '-'}</TableCell>
                                    <TableCell>
                                        {row.updatedDt !== null ? moment(row.updatedDt).format('YYYY-MM-DD') : '-'}
                                    </TableCell>
                                    <TableCell>{row.createdBy}</TableCell>
                                    <TableCell>{moment(row.createdDt).format('YYYY-MM-DD')}</TableCell>
                                    <TableCell align={'right'}>
                                        <EditIcon
                                            onClick={() => {
                                                if (selectedType === 1) {
                                                    handleOpenPpaEdit(row);
                                                } else {
                                                    handleOpenMDM(row);
                                                }
                                            }}
                                            className={classes.editIcon}
                                        />
                                        <DeleteIcon
                                            color={'primary'}
                                            onClick={() => {
                                                handleOpenPPADelete();
                                                setIdToDelete(row.id);
                                            }}
                                            className={classes.editIcon}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={14} align="center">
                                    <div className={classes.noData}>{t('NoRecords')}</div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <div className={classes.paginationContainer}>
                    <TablePagination
                        component="div"
                        count={countPpa}
                        page={pagePpa - 1}
                        onPageChange={handleChangePagePpa}
                        rowsPerPage={limitPpa}
                        labelDisplayedRows={({ from, to, count, page }) => `  ${from} - ${to} of  ${count} `}
                        onRowsPerPageChange={handleChangeRowsPerPagePpa}
                    />
                </div>
            </>
        );
    };
    const handleChangemasterDataSelected = (event: any) => {
        const number = event.target.value;
        dispatch({
            type: Utils.ActionName.UPDATE_PPA_CODE,
            payload: {
                selectedType: number,
                pagePpa: 1,
                limitPpa: 10,
            },
        });
        dispatch(getMDMApi(number));
    };

    return (
        <>
            {props.isProductSetting ? (
                <Helmet>
                    <title>{t('MasterDataManagement')}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{t('ManageOperation')}</title>
                </Helmet>
            )}
            <div className={classes.mainContainer}>
                <Typography className={classes.tileHeading}>
                    {props.isProductSetting ? (
                        <FormControl>
                            <Select
                                className={classes.selectStyl}
                                value={selectedType}
                                onChange={handleChangemasterDataSelected}
                                variant="outlined"
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                            >
                                <MenuItem value={1}>{t('Feature')} </MenuItem>
                                <MenuItem value={2}>{t('ProductType')} </MenuItem>
                                <MenuItem value={6}>{t('Machines')} </MenuItem>
                                <MenuItem value={3}>{t('MachinesType')} </MenuItem>
                                <MenuItem value={4}>{t('NeedlesType')} </MenuItem>
                                <MenuItem value={5}>{t('SkillType')} </MenuItem>
                            </Select>
                        </FormControl>
                    ) : (
                        'Operation Code'
                    )}
                </Typography>

                <div>{filter()}</div>
                <div>{props.isProductSetting ? masterDataManagementTable() : tableOpertaion()}</div>

                {/* <div>{masterDataManagementTable()}</div> */}
                <SimpleModal
                    handleOpen={() => {
                        setIsAddOperation(true);
                    }}
                    isOpen={isAddOperation}
                    body={
                        <AddOperationCodeForm
                            handleClose={() => {
                                setIsAddOperation(false);
                            }}
                        />
                    }
                />
                <SimpleModal
                    isOpen={isBulk}
                    body={
                        <BulkOperationUpload
                            handleClose={() => {
                                setIsBulk(false);
                            }}
                        />
                    }
                />
                <SimpleModal
                    handleOpen={() => {
                        setIsAddPPA(true);
                    }}
                    isOpen={isAddPpa}
                    body={
                        <AddppaForm
                            handleClose={() => {
                                setIsAddPPA(false);
                            }}
                        />
                    }
                />
                <SimpleModal
                    handleOpen={() => {
                        setIsOpenAddForm(true);
                    }}
                    isOpen={isOpenAddForm}
                    body={
                        <AddMDMForm
                            handleClose={() => {
                                setIsOpenAddForm(false);
                                if (selectedType === 3) {
                                    dispatch({
                                        type: Utils.ActionName.UPDATE_PPA_CODE,
                                        payload: {
                                            hostedImages: [],
                                        },
                                    });
                                }
                            }}
                        />
                    }
                />
                <SimpleModal
                    handleOpen={handleOpenCodeDelete}
                    isOpen={openCodeDelete}
                    body={
                        <DeleteModal
                            title={`${t('Delete')} ${t('OperationCode')} ? `}
                            onDelete={() => {
                                dispatch(deleteOperationCode(operationCodeIdToDelete, handleCloseCodeDelete));
                            }}
                            handleClose={handleCloseCodeDelete}
                        />
                    }
                />
                <SimpleModal
                    handleOpen={handleOpenPPADelete}
                    isOpen={openPPADelete}
                    body={
                        <DeleteModal
                            title={
                                selectedType === 1
                                    ? ' Delete Feature'
                                    : selectedType === 2
                                        ? 'Delete Produt Type'
                                        : selectedType === 3
                                            ? 'Delete Machine Attachment'
                                            : selectedType === 4
                                                ? 'Delete Needle Type'
                                                : selectedType === 6
                                                    ? 'Delete Machines'
                                                    : 'Delete Skill Type'
                            }
                            onDelete={() => {
                                dispatch(deleteMDM(idToDelete, handleClosePPADelete));
                            }}
                            handleClose={handleClosePPADelete}
                        />
                    }
                />
                <SimpleModal
                    isOpen={openImage}
                    body={
                        <ImageCaurosel
                            handleClose={handleImageClose}
                            items={images}
                            imgToOpen={imgToOpen}
                            alt={t('OperationCode')}
                        />
                    }
                />
            </div>
        </>
    );
}
