import React, { useState, useEffect, useRef } from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    TextField,
    InputAdornment,
    Button,
    Avatar,
    MenuItem,
    Menu,
    Select,
    FormControl,
} from '@material-ui/core';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LocalImages from '../../../utils/images';
import { ReducersModel } from '../../../model';
import TranslationFilter from '../../../components/filters/masterDataTranslationFilter';
import BButton from '../../../components/buttons/index';
import TButton from '../../../components/buttons/transparentButton';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import {
    getNeedleTypesAll,
    getFeaturesAll,
    getMachinesAll,
    getStylesAll,
    getNPTTypesAll,
    getNPTCategoriesAll,
    needleTypetranslation,
    ppaTranslationUpdate,
    nptCategoriesTranslationUpdate,
    nptTypesTranslationUpdate,
    machinesTranslationUpdate,
    stylesTranslationUpdate,
    getProductAll,
    getSkillAll,
    getmachineAttachmentAll,
    productTypesTranslationUpdate,
    skillTypesTranslationUpdate,
    machineAttachmentTranslationUpdate,
    getoperationCodeTranslationAll,
    operationCodeTranslationUpdate,
} from './action';
import Utils from '../../../utils';
import MasterDataTransation from './translationTable';
import SimpleModal from '../../../components/modal';
import TranslationModal from '../../../components/modal/translationModal';
import { Helmet } from 'react-helmet';

var googleTranslate = require('google-translate')('AIzaSyBZ4GpFQP8mZ0swkJyM-dbr0FWRGBjGGbU');

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            padding: '0 16px',
            backgroundColor: '#FFFFFF',
        },
        styleOperations: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '18px',
        },
        leftStyleOperations: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
        },
        searchInput: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            width: '300px',
            '& .MuiInputBase-root': {
                height: '36px',
                font: 'normal normal normal 14px/20px Roboto',
                letterSpacing: '0.05px',
                color: '#515151',
                opacity: 1,
            },
            '& svg': {
                cursor: 'pointer',
            },
        },

        filterButton: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            opacity: 1,
            border: '1px solid #DFDFDF',
            font: 'normal normal normal 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: 'var(--title-light)',
            textTransform: 'uppercase',
            height: '36px',
        },
        selectStyl: {
            width: '202px',
            marginBottom: '10px',
            textAlign: 'left',
            display: 'flex',
            '& .MuiSelect-selectMenu': {
                padding: '10px !important',
                font: 'normal normal medium 16px/20px Roboto',
                letterSpacing: '0.06px',
                color: 'var(--dark-grey)',
                opacity: '1',
            },
        },
        menuPaper: {
            maxHeight: '360px',
        },
        filterCount: {
            minWidth: '24px',
            minHeight: '24px',
            width: '1em',
            height: '1em',
            textAlign: 'center',
            font: 'normal normal normal 14px/20px Roboto!important',
            letterSpacing: '0.05px',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            opacity: 1,
            backgroundColor: '#1E88E5',
        },
        filterMenu: {
            '& .MuiList-root': {
                width: '194px',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: '0px 0px 6px #00000029',
                opacity: 1,
            },
        },
        inputSearch: {
            width: '300px',
        },
        googleTranslate: {
            color: '#1E88E5',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #1E88E5',
            borderRadius: '4px',
            padding: '4px 10px',
            marginLeft: 16,
            '&>span': {
                font: 'normal normal normal 16px/20px Roboto',
                marginLeft: 7,
            },
        },
        settingsButtonContainer: {
            width: '100%',
            left: '0px',
            backgroundColor: 'var(--white)',
            padding: '8px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            bottom: 0,
        },
        searchIcon: {
            cursor: 'pointer',
        },
    }),
);

export default function Translation() {
    const classes = styles();
    const [transatePopup, setTranslatePopup] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        needleTypeData,
        ppaData,
        nptCategoryData,
        nptTypeData,
        machineData,
        styleData,
        productTypeData,
        skillTypeData,
        machineAttachmentData,
        operationCodeData,
        selectedType,
        selectedTypeName,
    } = useSelector((state: ReducersModel) => state.masterDataTranslationReducer);
    const { search, language1Filter, language2Filter } = useSelector(
        (state: ReducersModel) => state.masterDataTranslationReducer,
    );
    const getFilterCount = () => {
        return +language1Filter + +language2Filter + 1;
    };
    /********************************************** */
    const debounceCount = useRef(0);
    const api = () =>
        new Promise((res) => {
            setTimeout(() => {
                res(1);
            }, 500);
        });
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        switch (selectedType) {
            case 1:
                dispatch(getNeedleTypesAll());
                break;
            case 2:
                dispatch(getFeaturesAll());
                break;
            case 3:
                dispatch(getNPTCategoriesAll());
                break;
            case 4:
                dispatch(getNPTTypesAll());
                break;
            case 5:
                dispatch(getMachinesAll());
                break;
            case 6:
                dispatch(getStylesAll());
                break;
            case 7:
                dispatch(getProductAll());
                break;
            case 8:
                dispatch(getSkillAll());
                break;
            case 9:
                dispatch(getmachineAttachmentAll());
                break;
            case 10:
                dispatch(getoperationCodeTranslationAll());
                break;
        }
    }, [dispatch]);

    const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    /**Handle Change */
    const handleChangemasterDataSelected = (event: any) => {
        dispatch({
            type: Utils.ActionName.MASTER_DATA_TRANSLATION,
            payload: {
                search: '',
                needleTypeData: [],
                styleData: [],
                ppaData: [],
                nptCategoryData: [],
                nptTypeData: [],
                machineData: [],
                productTypeData: [],
                skillTypeData: [],
                machineAttachmentData: [],
                operationCodeData: [],
                count: 0,
                page: 1,
                isTranslated: false,
            },
        });
        const number = event.target.value;
        var type;
        switch (number) {
            case 1:
                type = 'Needle Type';
                break;
            case 2:
                type = 'Features';
                break;
            case 3:
                type = 'NPT Category';
                break;
            case 4:
                type = 'NPT Type';
                break;
            case 5:
                type = 'Machines';
                break;
            case 6:
                type = 'Styles';
                break;
            case 7:
                type = 'Product Type';
                break;
            case 8:
                type = 'Skill Type';
                break;
            case 9:
                type = 'Machine Attachment';
                break;
            case 10:
                type = 'Operation Code';
                break;
        }
        dispatch({
            type: Utils.ActionName.MASTER_DATA_TRANSLATION,
            payload: {
                selectedType: number,
                selectedTypeName: type,
            },
        });
        if (number === Utils.constants.masterDataSelection.needleType.index) {
            dispatch(getNeedleTypesAll());
            return;
        }
        if (number === Utils.constants.masterDataSelection.ppa.index) {
            dispatch(getFeaturesAll());
            return;
        }
        if (number === Utils.constants.masterDataSelection.nptCategory.index) {
            dispatch(getNPTCategoriesAll());
            return;
        }

        if (number === Utils.constants.masterDataSelection.nptType.index) {
            dispatch(getNPTTypesAll());
            return;
        }

        if (number === Utils.constants.masterDataSelection.machines.index) {
            dispatch(getMachinesAll());
            return;
        }

        if (number === Utils.constants.masterDataSelection.styles.index) {
            dispatch(getStylesAll());
            return;
        }

        if (number === Utils.constants.masterDataSelection.productType.index) {
            dispatch(getProductAll());
            return;
        }

        if (number === Utils.constants.masterDataSelection.skillType.index) {
            dispatch(getSkillAll());
            return;
        }
        if (number === Utils.constants.masterDataSelection.machineAttachment.index) {
            dispatch(getmachineAttachmentAll());
            return;
        }

        if (number === Utils.constants.masterDataSelection.operationCode.index) {
            dispatch(getoperationCodeTranslationAll());
            return;
        }
    };

    /********Handle search*********/
    const handleSearch = (event: any) => {
        dispatch({
            type: Utils.ActionName.MASTER_DATA_TRANSLATION,
            payload: {
                search: event.target.value,
                page: 1,
            },
        });
        debounceCount.current += 1;
        const localDebounceCount = debounceCount.current;
        api().then((d) => {
            if (localDebounceCount !== debounceCount.current) {
                return;
            }
            if (selectedType === Utils.constants.masterDataSelection.needleType.index) {
                dispatch(getNeedleTypesAll());
                return;
            }

            if (selectedType === Utils.constants.masterDataSelection.ppa.index) {
                dispatch(getFeaturesAll());
                return;
            }

            if (selectedType === Utils.constants.masterDataSelection.nptCategory.index) {
                dispatch(getNPTCategoriesAll());
                return;
            }

            if (selectedType === Utils.constants.masterDataSelection.nptType.index) {
                dispatch(getNPTTypesAll());
                return;
            }

            if (selectedType === Utils.constants.masterDataSelection.machines.index) {
                dispatch(getMachinesAll());
                return;
            }

            if (selectedType === Utils.constants.masterDataSelection.styles.index) {
                dispatch(getStylesAll());
                return;
            }

            if (selectedType === Utils.constants.masterDataSelection.productType.index) {
                dispatch(getProductAll());
                return;
            }

            if (selectedType === Utils.constants.masterDataSelection.skillType.index) {
                dispatch(getSkillAll());
                return;
            }
            if (selectedType === Utils.constants.masterDataSelection.machineAttachment.index) {
                dispatch(getmachineAttachmentAll());
                return;
            }

            if (selectedType === Utils.constants.masterDataSelection.operationCode.index) {
                dispatch(getoperationCodeTranslationAll());
                return;
            }
        });
    };

    /*******
     * check in list data or not
     */

    const CheckDataInList = () => {
        let list = [];
        switch (selectedType) {
            case 1:
                list = needleTypeData;
                return list;
            // break;
            case 2:
                list = ppaData;
                return list;
            // break;
            case 3:
                list = nptCategoryData;
                return list;
            // break;
            case 4:
                list = nptTypeData;
                return list;
            // break;
            case 5:
                list = machineData;
                return list;
            // break;
            case 6:
                list = styleData;
                return list;
            // break;
            case 7:
                list = productTypeData;
                return list;
            // break;
            case 8:
                list = skillTypeData;
                return list;
            // break;
            case 9:
                list = machineAttachmentData;
                return list;
            // break;
            case 10:
                list = operationCodeData;
                return list;
            // break;
        }
    };

    /*******
     * Translation
     * ********/
    const translate = async () => {
        dispatch({
            type: Utils.ActionName.MASTER_DATA_TRANSLATION,
            payload: {
                isTranslated: true,
            },
        });
        let list: any = [];
        switch (selectedType) {
            case 1:
                list = needleTypeData;
                break;
            case 2:
                list = ppaData;
                break;
            case 3:
                list = nptCategoryData;
                break;
            case 4:
                list = nptTypeData;
                break;
            case 5:
                list = machineData;
                break;
            case 6:
                list = styleData;
                break;
            case 7:
                list = productTypeData;
                break;
            case 8:
                list = skillTypeData;
                break;
            case 9:
                list = machineAttachmentData;
                break;
            case 10:
                list = operationCodeData;
                return list;
        }

        let count = 0;
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: list.length ? true : false,
        });
        list?.map((data: any, index: number) => {
            googleTranslate.translate(data.name, Utils.constants.firstLanguage, function (err: any, translation: any) {
                list[index]['firstLang'] = translation.translatedText;
                list[index]['firstLangIsGoogle'] = true;
                list[index]['firstLangUpdated'] = true;
                list[index]['isFirstManualUpdate'] = false;
                ++count;
                if (count === list.length * 2) {
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            });
            googleTranslate.translate(data.name, Utils.constants.secondLanguage, function (err: any, translation: any) {
                list[index]['secondLang'] = translation.translatedText;
                list[index]['secondLangIsGoogle'] = true;
                list[index]['secondLangUpdated'] = true;
                list[index]['isSecondManualUpdate'] = false;
                ++count;
                if (count === list.length * 2) {
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            });
        });
    };

    const translateOnlyUnTranslate = () => {
        dispatch({
            type: Utils.ActionName.MASTER_DATA_TRANSLATION,
            payload: {
                isTranslated: true,
            },
        });
        let list: any = [];
        switch (selectedType) {
            case 1:
                list = needleTypeData;
                break;
            case 2:
                list = ppaData;
                break;
            case 3:
                list = nptCategoryData;
                break;
            case 4:
                list = nptTypeData;
                break;
            case 5:
                list = machineData;
                break;
            case 6:
                list = styleData;
                break;
            case 7:
                list = productTypeData;
                break;
            case 8:
                list = skillTypeData;
                break;
            case 9:
                list = machineAttachmentData;
                break;
            case 10:
                list = operationCodeData;
                break;
        }

        let count = 0;
        let count1 = 0;

        list?.forEach((data: any, index: number) => {
            if (!data.firstLang) {
                ++count1;
            }
            if (!data.secondLang) {
                ++count1;
            }
        });
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: count1 ? true : false,
        });
        setTranslatePopup(false);
        list?.forEach((data: any, index: number) => {
            if (!data.firstLang) {
                googleTranslate.translate(
                    data.name,
                    Utils.constants.firstLanguage,
                    function (err: any, translation: any) {
                        list[index]['firstLang'] = translation.translatedText;
                        list[index]['firstLangIsGoogle'] = true;
                        list[index]['firstLangUpdated'] = true;
                        list[index]['isFirstManualUpdate'] = false;
                        ++count;
                        if (count === count1) {
                            dispatch({
                                type: Utils.ActionName.IS_LOADING,
                                payload: false,
                            });
                        }
                    },
                );
            }
            if (!data.secondLang) {
                googleTranslate.translate(
                    data.name,
                    Utils.constants.secondLanguage,
                    function (err: any, translation: any) {
                        list[index]['secondLang'] = translation.translatedText;
                        list[index]['secondLangIsGoogle'] = true;
                        list[index]['secondLangUpdated'] = true;
                        list[index]['isSecondManualUpdate'] = false;
                        ++count;
                        if (count === count1) {
                            dispatch({
                                type: Utils.ActionName.IS_LOADING,
                                payload: false,
                            });
                        }
                    },
                );
            }
        });
    };

    const filter = () => {
        return (
            <div>
                <FormControl>
                    <Select
                        className={classes.selectStyl}
                        value={selectedType}
                        onChange={handleChangemasterDataSelected}
                        variant="outlined"
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                            classes: { paper: classes.menuPaper },
                        }}
                    >
                        <MenuItem value={2}>{t('Feature')} </MenuItem>
                        <MenuItem value={5}>{t('Machines')} </MenuItem>
                        <MenuItem value={9}>{t('MachinesType')} </MenuItem>
                        <MenuItem value={1}>{t('NeedlesType')} </MenuItem>
                        <MenuItem value={3}>{t('NPTCategory')} </MenuItem>
                        <MenuItem value={4}>{t('NPTType')} </MenuItem>
                        <MenuItem value={10}>{t('OperationCode')} </MenuItem>
                        <MenuItem value={7}>{t('ProductType')} </MenuItem>
                        <MenuItem value={8}>{t('SkillType')} </MenuItem>
                        <MenuItem value={6}>{t('Style')} </MenuItem>
                    </Select>
                </FormControl>
                <div className={classes.styleOperations}>
                    <div className={classes.leftStyleOperations}>
                        <div className={classes.inputSearch}>
                            <TextField
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                className={classes.searchInput}
                                variant="outlined"
                                placeholder={t('Search')}
                                onChange={handleSearch}
                                value={search}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img
                                                alt={t('Search')}
                                                src={LocalImages.SEARCH_ICON}
                                                className={classes.searchIcon}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div
                            className={classes.googleTranslate}
                            onClick={() => (CheckDataInList().length ? setTranslatePopup(true) : () => {})}
                        >
                            <GTranslateIcon /> <span>{t('GOOGLETRANSLATE')}</span>
                        </div>
                    </div>
                    <div>
                        <React.Fragment>
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<FormatListBulletedIcon />}
                                className={classes.filterButton}
                                endIcon={<Avatar className={classes.filterCount}>{getFilterCount()}</Avatar>}
                                onClick={handleOpenFilter}
                            >
                                {t('Filter')}
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleCloseFilter}
                                className={classes.filterMenu}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                getContentAnchorEl={null}
                            >
                                <TranslationFilter masterDataSelected={selectedType} />
                            </Menu>
                        </React.Fragment>
                    </div>
                </div>
            </div>
        );
    };

    function Card({ children, outerStyle }: any) {
        return <div className={outerStyle}>{children}</div>;
    }

    const tableOfTranslations = () => {
        switch (selectedType) {
            case 1:
                return (
                    <MasterDataTransation
                        listData={needleTypeData}
                        listName={selectedTypeName}
                        resetTranslation={resetTranslation}
                        tableName={t('NeedlesType')}
                    />
                );
            case 2:
                return (
                    <MasterDataTransation
                        listData={ppaData}
                        listName={selectedTypeName}
                        resetTranslation={resetTranslation}
                        tableName={t('Features')}
                    />
                );
            case 3:
                return (
                    <MasterDataTransation
                        listData={nptCategoryData}
                        listName={selectedTypeName}
                        resetTranslation={resetTranslation}
                        tableName={t('NPTCategory')}
                    />
                );
            case 4:
                return (
                    <MasterDataTransation
                        listData={nptTypeData}
                        listName={selectedTypeName}
                        resetTranslation={resetTranslation}
                        tableName={t('NPTType')}
                    />
                );
            case 5:
                return (
                    <MasterDataTransation
                        listData={machineData}
                        listName={selectedTypeName}
                        resetTranslation={resetTranslation}
                        tableName={t('Machines')}
                    />
                );
            case 6:
                return (
                    <MasterDataTransation
                        listData={styleData}
                        listName={selectedTypeName}
                        resetTranslation={resetTranslation}
                        tableName={t('Styles')}
                    />
                );
            case 7:
                return (
                    <MasterDataTransation
                        listData={productTypeData}
                        listName={selectedTypeName}
                        resetTranslation={resetTranslation}
                        tableName={t('ProductType')}
                    />
                );
            case 8:
                return (
                    <MasterDataTransation
                        listData={skillTypeData}
                        listName={selectedTypeName}
                        resetTranslation={resetTranslation}
                        tableName={t('SkillType')}
                    />
                );
            case 9:
                return (
                    <MasterDataTransation
                        listData={machineAttachmentData}
                        listName={selectedTypeName}
                        resetTranslation={resetTranslation}
                        tableName={t('MachinesType')}
                    />
                );
            case 10:
                return (
                    <MasterDataTransation
                        listData={operationCodeData}
                        listName={selectedTypeName}
                        resetTranslation={resetTranslation}
                        tableName={t('OperationCode')}
                    />
                );
        }
    };

    const resetTranslation = () => {
        dispatch({
            type: Utils.ActionName.MASTER_DATA_TRANSLATION,
            payload: {
                isTranslated: false,
            },
        });
        if (selectedType === Utils.constants.masterDataSelection.needleType.index) {
            dispatch(getNeedleTypesAll());
            return;
        }

        if (selectedType === Utils.constants.masterDataSelection.ppa.index) {
            dispatch(getFeaturesAll());
            return;
        }

        if (selectedType === Utils.constants.masterDataSelection.machines.index) {
            dispatch(getMachinesAll());
            return;
        }

        if (selectedType === Utils.constants.masterDataSelection.styles.index) {
            dispatch(getStylesAll());
            return;
        }

        if (selectedType === Utils.constants.masterDataSelection.nptCategory.index) {
            dispatch(getNPTCategoriesAll());
            return;
        }

        if (selectedType === Utils.constants.masterDataSelection.nptType.index) {
            dispatch(getNPTTypesAll());
            return;
        }
        if (selectedType === Utils.constants.masterDataSelection.productType.index) {
            dispatch(getProductAll());
            return;
        }

        if (selectedType === Utils.constants.masterDataSelection.skillType.index) {
            dispatch(getSkillAll());
            return;
        }
        if (selectedType === Utils.constants.masterDataSelection.skillType.index) {
            dispatch(getmachineAttachmentAll());
            return;
        }
        if (selectedType === Utils.constants.masterDataSelection.operationCode.index) {
            dispatch(getoperationCodeTranslationAll());
            return;
        }
    };

    const savetranslation = () => {
        dispatch({
            type: Utils.ActionName.MASTER_DATA_TRANSLATION,
            payload: {
                isTranslated: false,
            },
        });
        switch (selectedType) {
            case 1:
                dispatch(needleTypetranslation());
                break;
            case 2:
                dispatch(ppaTranslationUpdate());
                break;
            case 3:
                dispatch(nptCategoriesTranslationUpdate());
                break;
            case 4:
                dispatch(nptTypesTranslationUpdate());
                break;
            case 5:
                dispatch(machinesTranslationUpdate());
                break;
            case 6:
                dispatch(stylesTranslationUpdate());
                break;
            case 7:
                dispatch(productTypesTranslationUpdate());
                break;
            case 8:
                dispatch(skillTypesTranslationUpdate());
                break;
            case 9:
                dispatch(machineAttachmentTranslationUpdate());
                break;
            case 10:
                dispatch(operationCodeTranslationUpdate());
                break;
        }
    };

    return (
        <>
            <div className={classes.mainContainer}>
                <Helmet>
                    <title>{t('MasterDataTranslation')}</title>
                </Helmet>
                <div>{filter()}</div>
                {tableOfTranslations()}
                <Card outerStyle={classes.settingsButtonContainer}>
                    <TButton handleClose={() => resetTranslation()} />
                    <BButton
                        type="button"
                        text={t('Save')}
                        onPress={() => {
                            savetranslation();
                        }}
                    />
                </Card>
            </div>
            <SimpleModal
                // handleClose={() => setTranslatePopup(false)}
                isOpen={transatePopup}
                body={
                    <TranslationModal
                        handleClose={translateOnlyUnTranslate}
                        onDelete={translate}
                        title={t('GOOGLETRANSLATE')}
                        desc={t('DoYouWantToTranslateAll')}
                        secondPara={t('IfNoOnlyUntranslatedWillTranslated')}
                        deleteBtn={t('ok')}
                        canceBtn={t('No')}
                        isDelete={false}
                    />
                }
            />
        </>
    );
}
