import * as React from 'react';
import {
    Grid,
    Typography,
    Box,
} from '@material-ui/core';
import { BoxPercentage } from '../../styles';
 
export default ({ shiftInfoData, infoData, targetOutput }: any) => (
    
    <Grid item xs={4}>
        <Box style={{ background: '#fff', borderBottom: '3px solid #888', padding: 5, width: '100%' }}>
            <Typography variant='h6' style={{ textAlign: 'center' }}>
                {shiftInfoData.shift}
                {`(${(shiftInfoData.actualStartTime).substr(0, 5) || 0})`} -
                {`(${(shiftInfoData.actualEndTime).substr(0, 5) || 0})`}
            </Typography>
            <Typography variant='subtitle1' style={{ color: '#777', fontSize: 12, width: '100%', textAlign: 'center' }}>
                <strong>Break Time</strong>{`(${shiftInfoData.totalBreakTime} min)`} |
                <strong> Overtime</strong>{`(${shiftInfoData.totalOverTime} min)`}
            </Typography>
        </Box>
        <Box style={{ display: 'flex', gap: 5, }}>
            <Box style={{ flex: 1 }}>
                <Box style={{ background: '#fff', padding: 15, margin: '10px 0' }}>
                    <Grid container alignItems='flex-end'>
                        <Grid item xs={6}>
                            <Typography style={{ color: '#000', fontSize: 11, fontWeight: 'bold' }} variant='caption'>{'Day Output'}</Typography>
                            <Typography variant='h6' style={{ fontWeight: 'bold', lineHeight: 1 }}>
                                {infoData.totalOutput}/{infoData.quantity}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'end' }}>
                            <Typography style={{ color: '#000', fontSize: 11, marginBottom: 0 }} variant='caption'>
                                {parseInt(infoData.workingMinutes)} min
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box style={{ display: 'flex', gap: 5, alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography style={{ color: '#000', fontSize: 11, fontWeight: 'bold' }} variant='caption'>{'Total Output'}</Typography>
                        <Typography style={{ color: '#000', fontSize: 11, fontWeight: 'bold' }} variant='caption'>{'Total Output'}</Typography>
                    </Box>
                    <Box style={{ display: 'flex', gap: 5, alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box style={{ background: '#666', color: '#fff', padding: 5, borderRadius: 5, textAlign: 'center', flex: 1 }}>
                            <Typography variant='h6' style={{ fontWeight: 'bold', lineHeight: 1 }}>
                            {infoData.dayQtyAchieved}
                            </Typography>
                        </Box>
                        <Typography variant='h6'>/</Typography>
                        <Box style={{ background: '#666', color: '#fff', padding: 5, borderRadius: 5, textAlign: 'center', flex: 1 }}>
                            <Typography variant='h6' style={{ fontWeight: 'bold', lineHeight: 1 }}>
                                {targetOutput}
                            </Typography>
                        </Box>
                    </Box>

                    <Grid container alignItems='flex-end'>
                        <Grid item xs={6}>
                            <Typography style={{ color: '#000', fontSize: 11, fontWeight: 'bold' }} variant='caption'>{'Total Shift Output'}</Typography>
                            <Typography variant='h6' style={{ fontWeight: 'bold', lineHeight: 1 }}>
                                -/-
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'end' }}>
                            <Typography style={{ color: '#000', fontSize: 11, marginBottom: 0 }} variant='caption'>
                                {'-'}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box style={{ flex: 1, overflow: 'hidden', width: '100%' }}>
                <Box style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
                    <Box style={{ margin: '10px 0', display: 'flex', width: '100%', overflow: 'hidden', height: '100%', alignItems: 'center', }}>
                        <BoxPercentage title={`${(infoData.eff).toFixed(2)}%`} subTitle={'Today\'s Line Eff'} />
                        <BoxPercentage title={`-`} subTitle={'Current Shift EFF'} />
                        <BoxPercentage title={`-`} subTitle={'Overall Shift EFF'} />
                    </Box>
                    <Box style={{ margin: '10px 0' }}>
                        <Grid container>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={12}>
                                <Box style={{ padding: 15, background: '#fff' }}>
                                    <Typography style={{ color: '#000', fontSize: 12 }} variant='caption'>{'LBR'}</Typography>
                                    <Typography variant='h6' style={{ fontWeight: 'bold', lineHeight: 1 }}>
                                        {infoData.actualLbr.toFixed(2)}%
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Box>
    </Grid>
);