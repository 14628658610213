import Utils from '../../utils';
import { lineChartCalculation } from '../dashboard/action';
import { getOperatorInPool, getShiftFomData } from '../lineSetup/action';

export const getStyleList = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const { page, limit, search, sortBy, order, filterCustomerIds, filterFactoryIds } = getState().styleListReducer;

        let parameters = `?page=${page}&limit=${limit}${
            order.length > 0 ? `&order=${order}&sortBy=${sortBy}` : '&sortBy=id'
        }${search.length > 0 ? `&search=${search}` : ''}${
            filterCustomerIds.length > 0 ? `&customerId=${filterCustomerIds.toString()}` : ''
        }${filterFactoryIds.length > 0 ? `&factoryId=${filterFactoryIds.toString()}` : ''} `;

        Utils.api.getApiCall(
            Utils.endPoints.styleList,
            parameters,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;

                    dispatch({
                        type: Utils.ActionName.GET_STYLE_LIST,
                        payload: { data: innerData.data, page: innerData.page, count: innerData.count },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getShiftsLineAssist = (id: any) => {
    return (dispatch: Function, getState: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        let parameters = `?uid=${id}`;
        let { currentShiftActive } = getState().lineShiftReducer;

        Utils.api.getApiCall(
            Utils.endPoints.fetchShift,
            parameters,
            (respData: any) => {
                let shiftTempArray: any = [];
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    for (let i = 0; i < innerData.length; i++) {
                        let shiftIdDataTemp = {
                            lineID: innerData[i].id,
                            shiftId: innerData[i].shiftData.id,
                            fromTime: innerData[i].shiftData.fromTime,
                            toTime: innerData[i].shiftData.toTime,
                            lineBreaktime: innerData[i].lineBreaktime,
                            lineOvertime: innerData[i].lineOvertime,
                            isEdited: false,
                        };
                        shiftTempArray.push(shiftIdDataTemp);
                    }
                    currentShiftActive = currentShiftActive === 0 ? innerData[0].id : currentShiftActive;
                    dispatch({
                        type: Utils.ActionName.GET_LINE_SHIFTS,
                        payload: {
                            lineShiftData: innerData.shiftData,
                            wholeShiftData: innerData,
                            uid: innerData[0].uid,
                            shiftData: shiftTempArray,
                            currentShiftActive,
                        },
                    });
                    dispatch(getLBAInfo(currentShiftActive));
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getLBAInfo = (id: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = `?id=${id}`;
        Utils.api.getApiCall(
            Utils.endPoints.lbaInfo,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    
                    dispatch({
                        type: Utils.ActionName.GET_LINE_ASSIST_INFO,
                        payload: {
                            infoData: innerData,
                            totalSmv: innerData.totalWebSmv,
                            lbr: innerData.actualLbr,
                            updatedAt: new Date().toISOString(),
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.GET_LINE_SHIFTS,
                        payload: {
                            uid: innerData.uid,
                            currentShiftActive: innerData.id,
                            currentShiftToBeActive: innerData.shiftId,
                            lineStatus: innerData.status,
                        },
                    });
                    dispatch(getOperatorInPool(innerData.lineId, innerData.id, innerData.shiftId));
                    dispatch(getLBATable(id));
                    dispatch(getShiftFomData(id));
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getDashboardLineOutput = (id: any, shiftId: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = `?id=${id}&shiftId=${shiftId}`;
        Utils.api.getApiCall(
            Utils.endPoints.dashboardLineOutput,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;

                    dispatch(lineChartCalculation(innerData.numericalStats, 1));
                    dispatch({
                        type: Utils.ActionName.GET_DASHBOARD,
                        payload: {
                            dashboardData: innerData.numericalStats,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.GET_LINE_ASSIST_INFO,
                        payload: {
                            infoData: innerData,
                            totalSmv: innerData.totalWebSmv,
                            lbr: innerData.actualLbr,
                            updatedAt: new Date().toISOString(),
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.GET_LINE_SHIFTS,
                        payload: {
                            uid: innerData.uid,
                            currentShiftActive: innerData.id,
                            currentShiftToBeActive: innerData.shiftId,
                            lineStatus: innerData.status,
                        },
                    });
                    dispatch(getLBATable(id));
                    dispatch(getShiftFomData(id));
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getLBADetails = (id: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = `?id=${id}`;
        Utils.api.getApiCall(
            Utils.endPoints.lbaInfo,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;

                    dispatch({
                        type: Utils.ActionName.GET_LINE_ASSIST_INFO,
                        payload: {
                            infoData: innerData,
                            totalSmv: innerData.totalWebSmv,
                            lbr: innerData.actualLbr,
                            updatedAt: new Date().toISOString(),
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.GET_LINE_SHIFTS,
                        payload: {
                            lineStatus: innerData.status,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getLBATable = (id: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const { isSaved, isLBALine } = getState().lineShiftReducer;

        let parameters = `?id=${id}`;
        Utils.api.getApiCall(
            Utils.endPoints.labTable,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    if (isSaved && isLBALine) {
                        dispatch({
                            type: Utils.ActionName.GET_LINE_SHIFTS,
                            payload: {
                                lineSetupAfterSave: innerData,
                            },
                        });
                        dispatch({
                            type: Utils.ActionName.LINE_SETUP_DATA,
                            payload: {
                                lineSetup: innerData,
                                tempArray: [],
                            },
                        });
                    } else {
                        dispatch({
                            type: Utils.ActionName.ASSIST_LINE_TABLE,
                            payload: {
                                lineSetup: innerData,
                            },
                        });
                    }
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const updateStyleInfo = (handleClose: any, history: any, isLSI?: any, workingTime?: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        let dataToSend: any = {};
        if (isLSI) {
            const { lineSetupId, totalSmv, styleId } = getState().linesSetupReducer;
            const { infoData } = getState().lineSetupInfoReducer;
            const lineName = infoData.lineName;
            const { lineSetup, finalLbr, targetEFF, lbrOperation, grapghValSelect, workingMinutes } = getState().infoLineSetupReducer;
            const { lineStatus } = getState().lineShiftReducer;
            let graphData = { lbrOperation, grapghValSelect };
            var graphDataString = JSON.stringify(graphData);
            dataToSend = {
                data: lineSetup,
                lineSetupId: lineSetupId,
                lineSetupmanpower: lineSetup.length,
                styleId,
                lineName,
                lbr: finalLbr,
                actualLbr: infoData.actualLbr,
                actualLbrTct: infoData.actualLbrTct,
                totalWebSmv: totalSmv,
                totalWebTct: infoData.totalTct,
                eff: targetEFF,
                effTgt: 0,
                currentShiftEff: infoData.currentShiftEff,
                currentShiftEffTct: infoData.currentShiftEffTct,
                totalOutputTgt: 0,
                status: lineStatus,
                lbrGraph: graphDataString,
                targetEff: targetEFF,
                workingMinutes: workingMinutes,
            };
        } else {
            const { infoData, lbr } = getState().lineAssistInfoReducer;
            const { lineSetup, totalSmv, LBRTgt } = getState().assistTableLineSetup;
            const { lineStatus } = getState().lineShiftReducer;
            dataToSend = {
                data: lineSetup,
                lineSetupId: infoData.id,
                lineSetupmanpower: lineSetup.length,
                styleId: infoData.styleId,
                lineName: infoData.lineName,
                lbr: lbr,
                actualLbr: infoData.actualLbr,
                actualLbrTct: infoData.actualLbrTct,
                totalWebSmv: infoData.totalWebSmv,
                totalWebTct: infoData.totalTct,
                eff: infoData.eff,
                effTgt: infoData.effTgt,
                currentShiftEff: infoData.currentShiftEff,
                currentShiftEffTct: infoData.currentShiftEffTct,
                totalOutputTgt: infoData.totalOutputTgt,
                lbrTgt: LBRTgt || 0,
                status: lineStatus,
                targetEff: infoData.targetEff,
                workingMinutes: infoData.workingMinutes,
            };
        }

        // In case in line setup user change shift working time
        // Like add breaktime, and overtime
        if(workingTime) dataToSend.workingMinutes = workingTime;

        Utils.api.putApiCall(
            Utils.endPoints.stationSave,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch({
                        type: Utils.ActionName.GET_LINE_ASSIST_INFO,
                        payload: {
                            isUpdated: false,
                        },
                    });
                    if (!isLSI) {
                        handleClose();
                    }
                    let { currentShiftActive } = getState().lineShiftReducer;
                    dispatch(getLBAInfo(currentShiftActive));
                } else {
                    dispatch({
                        type: Utils.ActionName.GET_LINE_ASSIST_INFO,
                        payload: {
                            isUpdated: false,
                        },
                    });
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }

                // If working time update we need to reload page to get new updated data
                if(workingTime) {
                    window.location.reload();
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.GET_LINE_ASSIST_INFO,
                    payload: {
                        isUpdated: false,
                    },
                });

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const editStatusApi = (statusId: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { uid } = getState().lineShiftReducer;
        let { currentShiftActive } = getState().lineShiftReducer;

        const dataToSend = { status: statusId };
        Utils.api.putApiCall(
            `${Utils.endPoints.lineStatus}?uid=${uid}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.GET_LINE_SHIFTS,
                        payload: {
                            lineStatus: statusId,
                        },
                    });
                    dispatch(getLBADetails(currentShiftActive));
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getLBAReport = (id: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = `?id=${id}`;
        Utils.api.getApiCall(
            Utils.endPoints.reportLba,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData: any = data.data;
                    const innerTable: any = data.data.details;
                    let check = true;
                    for (let i = 0; i < innerTable.length; i++) {
                        if (i === 26) {
                        }
                        if (i !== innerTable.length - 1) {
                            let flag = 1;
                            let lastFlag = true;

                            do {
                                if (i + flag >= innerTable.length) {
                                    lastFlag = false;
                                } else {
                                    if (innerTable[i].operationData?.step === innerTable[i + flag].operationData?.step) {
                                        flag = flag + 1;
                                    } else {
                                        lastFlag = false;
                                    }
                                    if (flag === innerTable.length) {
                                        lastFlag = false;
                                    }
                                }
                            } while (lastFlag);
                            for (let j = 0; j < flag; j++) {
                                if (innerTable[j + i].operationData === null) {
                                    innerTable[j + i].operationData = { change: check };
                                } else {
                                    innerTable[j + i].operationData.change = check;
                                }
                                innerTable[j + i].operationData.change = check;
                            }
                            check = !check;
                            i = i + flag - 1;
                        } else {
                            innerTable[i].operationData.change = check;
                        }
                    }
                    dispatch({
                        type: Utils.ActionName.ASSIST_LINE_RREPORT,
                        payload: {
                            reportData: innerData,
                            stepsData: innerData.details,
                            tableData: innerTable,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const saveLBRHistory = (lineSetupId: any, uid: any, shiftId: any, remark: any, lineSetupData: any, setSubmitting: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            setSubmitting(false);
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }

        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const dataToSend = {
            lineSetupId,
            uid,
            shiftId,
            remark,
            lineSetupData,
        }

        Utils.api.postApiCall(
            Utils.endPoints.lbrHistory,
            dataToSend,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                setSubmitting(false);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        )
    }
}
