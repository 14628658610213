import axios from 'axios';
import LocalImages from './images';
/**
 * function to remove all spaces from a given string.
 * @param value
 */
const removeSpaces = (value: string) => {
    return value.replace(/\s/g, '');
};

const generateRandomNumber = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

function setAuthorizationToken() {
    // @ts-ignore
    // constants.setAuthorizationToken(localStorage.getItem('accessToken'));
    // @ts-ignore
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
}

// for 2 decimal places at max
function maxDecimalValue(value: any, digitsAfterDecimal: any) {
    return +parseFloat(value).toFixed(digitsAfterDecimal);
}

// always 2 decimal values
function alwaysDecimalValue(value: any, digitsAfterDecimal: any) {
    return (Math.round(value * 100) / 100).toFixed(digitsAfterDecimal);
}

function percentage(valueOne: number, valueTwo: number) {
    return (valueOne / valueTwo) * 100;
}

function dailyTargetPer(valueOne: number, valueTwo: number) {
    return (valueOne * valueTwo) / 100;
}

const showCustomToast = (message: string = '', type: number = 2) => {
    // Custom Toast
    const toast = document.createElement('div');
    toast.id = 'custom-toast';
    toast.style.background = type === 1 ? '#43a047' : '#d32f2f';
    toast.onclick = function () {
        //@ts-ignore
        this.parentElement.removeChild(this);
        clearInterval(myinterval);
    };
    toast.innerHTML = `<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path></svg> <span id="toast-message">${message}</span> <span id="toast-timer">5s</span><svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>`;
    document.body.appendChild(toast);

    // Custom Timer
    let timer = 4;
    let myinterval = setInterval(function () {
        if (document.getElementById('toast-timer')) {
            //@ts-ignore
            document.getElementById('toast-timer').innerHTML = timer + 's';
            if (timer === 0) {
                clearInterval(myinterval);
            } else {
                timer--;
            }
        }
    }, 1000);

    // Timeout to remove toast
    setTimeout(() => {
        if (document.getElementById('custom-toast')) {
            //@ts-ignore
            document.getElementById('custom-toast').remove();
            clearInterval(myinterval);
        }
    }, 5000);
};

const toTimestamp = (strDate: any) => {
    const dt = Date.parse(strDate);
    return dt / 1000;
};

const isValidTime = (selTime: any, startTime: any, endTime: any) => {
    const selTimeTS = toTimestamp(selTime);
    const startTimeTS = toTimestamp(startTime);
    const endTimeTS = toTimestamp(endTime);

    if (startTimeTS <= selTimeTS && selTimeTS <= endTimeTS) {
        return true;
    }

    return false;
};

const isValidOverTime = (selTime: any, values: any) => {
    const { nextShiftStartTime, startTime, endTime, factoryStartTime } = values;
    let overTimeTS = toTimestamp(selTime);
    let startTimeTS = toTimestamp(startTime);
    let endTimeTS = toTimestamp(endTime);

    if (overTimeTS >= toTimestamp(factoryStartTime) && overTimeTS < startTimeTS) {
        return true;
    } else if (overTimeTS >= endTimeTS && overTimeTS < toTimestamp(nextShiftStartTime)) {
        return true;
    }
    return false;
};

const isValidBreakDuration = (duration: any, endTime: any, breakStartTime: any) => {
    let endTimeTS = toTimestamp(endTime);
    let breakStartTimeTS = toTimestamp(breakStartTime);
    let tempDuration = Number(duration);

    if (breakStartTimeTS + tempDuration * 60 <= endTimeTS) {
        return true;
    }

    return false;
};

const isValidOverTimeDuration = (duration: any, values: any, overTimeStart: any) => {
    const { nextShiftStartTime, startTime, endTime, previousShiftEndTime } = values;

    let endTimeTS = toTimestamp(endTime);
    let overStartTimeTS = toTimestamp(overTimeStart);
    let tempDuration = Number(duration);
    let startTimeTS = toTimestamp(startTime);

    if (overStartTimeTS >= toTimestamp(previousShiftEndTime) && overStartTimeTS < startTimeTS) {
        if (overStartTimeTS + tempDuration * 60 <= startTimeTS) {
            return true;
        }
    } else if (overStartTimeTS >= endTimeTS && overStartTimeTS < toTimestamp(nextShiftStartTime)) {
        if (overStartTimeTS + tempDuration * 60 <= toTimestamp(nextShiftStartTime)) {
            return true;
        }
    }
    return false;
};

const getImage = (image: string) => {
    return image !== '' ? image : LocalImages.NOIMAGE;
};

const getImageWithOperationCode = (attachmentCode: string, image: string) => {
    return attachmentCode !== '' && image !== '' ? image : LocalImages.NOIMAGE;
};

const CommonFunctions = {
    removeSpaces,
    generateRandomNumber,
    setAuthorizationToken,
    maxDecimalValue,
    alwaysDecimalValue,
    percentage,
    showCustomToast,
    dailyTargetPer,
    isValidTime,
    isValidOverTime,
    isValidOverTimeDuration,
    isValidBreakDuration,
    toTimestamp,
    getImage,
    getImageWithOperationCode
};

export default CommonFunctions;
