import Utils from '../../utils';
import {
    styleListModel,
    LineSetupHistoryTable,
    NewStyleModel,
    NewOperationModel,
    operationCodeListModel,
    machineListModel,
    needleListModel,
    sewingOperationModel,
    sewingOperationForAddStyleModel,
    ppaOperationModel,
    factoriesListModel,
    productTypeListModel,
    styleDetailsModel,
    customersListModel,
    seasonListModel
} from '../../model';

export const styleListReducer = (state: styleListModel = new styleListModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_STYLE_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const seasonListReducer = (state: seasonListModel = new seasonListModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_SEASON_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const customersListReducer = (state: customersListModel = new customersListModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_CUSTOMER_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const productTypeListReducer = (state: productTypeListModel = new productTypeListModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_PRODUCT_TYPE_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const styleDetailReducer = (state: styleDetailsModel = new styleDetailsModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_STYLE_DETAIL:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const lineSetupHistoryTableReducer = (
    state: LineSetupHistoryTable = new LineSetupHistoryTable(),
    action: any,
) => {
    switch (action.type) {
        case Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const newStyleReducer = (state: NewStyleModel = new NewStyleModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.NEW_STYLE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const NewOperationReducer = (state: NewOperationModel = new NewOperationModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.NEW_OPERATION:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const operationCodeListReducer = (state: operationCodeListModel = new operationCodeListModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.OPERATION_CODE_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const machineListReducer = (state: machineListModel = new machineListModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.MACHINE_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const needleListReducer = (state: needleListModel = new needleListModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.NEEDLE_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const sewingOperationReducer = (state: sewingOperationModel = new sewingOperationModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.UPDATE_SEWING_OPERATION:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const sewingOperationForAddStyleReducer = (
    state: sewingOperationForAddStyleModel = new sewingOperationForAddStyleModel(),
    action: any,
) => {
    switch (action.type) {
        case Utils.ActionName.UPDATE_SEWING_OPERATION_FOR_ADD_STYLE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const ppaOperationReducer = (state: ppaOperationModel = new ppaOperationModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_MASTER_PPA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const factoriesListReducer = (state: factoriesListModel = new factoriesListModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_FACTORIES_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
