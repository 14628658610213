import axios from 'axios';

import {
    setSession,
    removeSession,
    getAccessToken,
    getUserIdToken,
    setAccessToken,
    manageUserSession,
    // getCurrentLanguage,
    setAuthorizationToken,
} from './session';

const s3config = {
    bucketName: 'app-development',
    region: 'us-east-1',
    // dirName: "users" / optional /,
    accessKeyId: 'AKIAQUD4MO3QVM23AIF6',
    secretAccessKey: '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',
};

// QA
// const apiURLBase = 'http://ltlabsqaapi.appskeeper.in/api/v1/';
// const factoryURLBase = 'http://ltwarehouseqapi.appskeeper.in/api/v1/user/factories';
// const companyCodeBase = 'AI-LTIE-QA';
//

// // DEV;
// const apiURLBase = 'http://ltlabsdevapi.appskeeper.in/api/v1/';
// const factoryURLBase = 'http://ltwarehousedevapi.appskeeper.in/api/v1/user/factories';
// const companyCodeBase = 'AI';

// // STG
// const apiURLBase = 'http://ltlabsstgapi.appskeeper.in/api/v1/';
// const factoryURLBase = 'http://ltwarehousestgapi.appskeeper.in/api/v1/user/factories';
// const companyCodeBase = 'AI-LTIE-STG';

// const globalURLBase = 'https://dev.ltlabs.co/msv/global-admin/api/v1/auth/exchange-factory';

const apiURLBase = `${process.env.REACT_APP_DOMAIN}`;
const apiURLBaseGlobal = `${process.env.REACT_APP_DOMAIN_Global}`;
const factoryURLBase = `${apiURLBaseGlobal}user/factories`;
const companyCodeBase = 'STX';
const globalURLBase = `${process.env.REACT_APP_DOMAIN_Global}`;
const languageURLBase = `${process.env.REACT_APP_LANG_GLOBAL}/masterdata/factory`;
// const ltmURLBase = `${process.env.REACT_APP_LTM_URL}` || 'https://dhg-staging-ltm.ltlabs.co';
const ltmURLBase = `${process.env.REACT_APP_LTM_URL}`;

const d = new Date();
const offsetValue = -1 * d.getTimezoneOffset();

const $axios = axios.create({
    // baseURL: process.env.REACT_APP_DOMAIN || apiURLBase,
    baseURL: apiURLBase,
    // baseURL: 'http://ltlabsqaapi.appskeeper.in/api/v1/',
    // timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        offsetValue: offsetValue,
    },
});
const $axiosFactory = axios.create({
    baseURL: globalURLBase,

    headers: {
        'Content-Type': 'application/json',
        offsetValue: offsetValue,
    },
});

// const CancelToken = axios.CancelToken;

const api_error_code = {
    unauthorized: 401,
    accessDenied: 430,
    // sessionExpired: 440,
    sessionExpired: 419,
    validationError: 400,
    emailNotVerified: 403,
};

const api_success_code = {
    postSuccess: 201,
    success: 200,
};

const user_type = {
    SUB_ADMIN: 2,
    ADMIN: 1,
};

const table_actions = {
    SEARCH: 'search',
    CHANGE_PAGE: 'changePage',
    ON_SEARCH_CLOSE: 'onSearchClose',
    CHANGE_ROWS_PER_PAGE: 'changeRowsPerPage',
};

const paths = {
    LOGIN: '/',
    DASHBOARD: '/dashboard',
};

const expiryDaysForShare = 45;

const alert_messages = {
    INVITATION_SENT: '',
};

const page_limit = {
    limit: 6,
};
const masterDataSelection = {
    needleType: {
        name: 'Needle Type',
        index: 1,
    },
    ppa: {
        name: 'Features',
        index: 2,
    },
    nptCategory: {
        name: 'NPT Category',
        index: 3,
    },
    nptType: {
        name: 'NPT Type',
        index: 4,
    },
    machines: {
        name: 'Machines',
        index: 5,
    },
    styles: {
        name: 'Styles',
        index: 6,
    },
    productType: {
        name: 'Product Type',
        index: 7,
    },
    skillType: {
        name: 'Skill Type',
        index: 8,
    },
    machineAttachment: {
        name: 'Machine Attachment',
        index: 9,
    },
    operationCode: {
        name: 'Operation Code',
        index: 10,
    },
};

const firstLanguage = 'khm';
const secondLanguage = 'chi';

// in seconds
const showAlertSeconds = 4;
/**
 * constant variable for the website
 */
const constant = {
    paths,
    apiUrl: apiURLBase,
    apiUrlGlobal: apiURLBaseGlobal,
    languageURLBase,
    setSession,
    axios: $axios,
    table_actions,
    removeSession,
    getUserIdToken,
    getAccessToken,
    api_error_code,
    setAccessToken,
    api_success_code,
    manageUserSession,
    // getCurrentLanguage,
    userType: user_type,
    spaceRegex: /^\S*$/,
    alert_messages,
    numberRegex: /^\d+$/,
    setAuthorizationToken,
    ALGORITHM: 'aes-256-gcm',
    hasConnection: navigator.onLine,
    mainMenuLinksArray: ['/dashboard'],
    // axiosIP: $axiosIP,
    // eslint-disable-next-line
    passwordRegex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z0-9!_@./#&+-\d]{8,}$/,
    onlyNumberRegex: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*._\b)$/,
    // eslint-disable-next-line
    emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    page_limit,
    expiryDaysForShare,
    showAlertSeconds,
    s3config,
    factoryURLBase,
    globalURLBase,
    masterDataSelection,
    firstLanguage,
    secondLanguage,
    companyCodeBase,
    axiosFactory: $axiosFactory,
    ltmURLBase,
};
export default constant;
