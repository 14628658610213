import React from 'react';
import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import moment from 'moment';
const styles = makeStyles((theme: Theme) =>
    createStyles({
        inputField: {
            '& .MuiFormHelperText-root': {
                color: '#f44336',
            },
            '& .MuiInputBase-input': {
                font: 'normal normal normal 14px/14px Roboto',
                letterSpacing: '0.07px',
                opacity: 1,
            },
            '& .MuiFormLabel-root': {
                font: 'normal normal normal 12px/21px Roboto',
                letterSpacing: '0.07px',
                opacity: 1,
            },
        },
        required: {
            color: '#D32F2F',
        },
    }),
);
interface Props {
    label?: string;
    placeholder?: string;
    id?: string;
    name?: string;
    type?: string;
    onBlur?: any;
    values?: any;
    onChange?: any;
    style?: any;
    helperText?: any;
    error?: any;
    touched?: any;
    errors?: any;
    data?: any;
    isRequired?: boolean;
    stopPastDate?: boolean;
}

export default function DatePicker({
    label,
    id,
    values,
    onChange,
    touched,
    helperText,
    error,
    data,
    isRequired,
    name,
    onBlur,
    stopPastDate,
}: Props) {
    const classes = styles();
    const today = moment().format('YYYY-MM-DD');

    return (
        <TextField
            className={classes.inputField}
            style={touched ? { color: '#757575' } : {}}
            type="date"
            id={id}
            inputProps={stopPastDate ? { disablePast: stopPastDate, min: today } : { disablePast: stopPastDate }}
            label={
                <span>
                    {label}
                    {isRequired ? <span className={classes.required}> *</span> : <span></span>}
                </span>
            }
            value={values}
            onChange={onChange}
            helperText={helperText}
            error={error}
            InputLabelProps={{
                shrink: true,
            }}
            fullWidth
            variant="standard"
            name={name}
            onBlur={onBlur}
        >
            {data}
        </TextField>
    );
}
