import React, { useRef, useState } from 'react';
import { makeStyles, createStyles, Theme, Typography, Stepper, Step, StepLabel, Button } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import PublishIcon from '@material-ui/icons/Publish';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { validateNptBulkUpload, nptBulkUpload, getNptCategories, handleDownloadNpt, handleDownloadNptTemplate } from './action';
import { ReducersModel } from '../../../model';
import Utils from '../../../utils';
import { useTranslation } from 'react-i18next';
const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            '& .MuiTextField-root': {
                marginBottom: '10px!important',
            },
            '& .MuiStepLabel-root': {
                display: 'flex',
                flexDirection: 'column',
            },
            '& .MuiStepLabel-labelContainer': {
                marginTop: '10px',
            },
            '& .MuiStepper-horizontal': {
                width: '77%',
                margin: 'auto',
            },
            '& .MuiStepConnector-root': {
                marginTop: '-6%',
            },
        },
        textStyl: {
            fontSize: '20px',
            color: 'var(--dark-grey)',
            marginTop: '44px',
        },
        paper: {
            position: 'absolute',
            width: '44.44%',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        saveContainer: {
            backgroundColor: 'white',
            padding: '10px 0',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '41px',
        },
        cancelButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#515151',
            opacity: 1,
        },
        saveButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#FFFFFF',
            opacity: 1,
            '& .MuiCircularProgress-root': {
                height: 'unset',
                width: 'unset',
            },
        },
        root: {
            width: '100%',
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        downloadText: {
            font: 'normal normal bold 16px/14px Roboto',
            letterSpacing: '0px',
            cursor: 'pointer',
            width: 'fit-content',
            color: '#2D96EF',
            textTransform: 'uppercase',
            opacity: 1,
            marginBottom: '14px',
            '&>span': {
                '& .MuiSvgIcon-root': {
                    width: '11px',
                    height: '11px',
                },
            },
            '& a': {
                textDecoration: 'none',
                color: '#2D96EF',
            },
        },
        styleImageTitle: {
            marginBottom: '10px',
            marginTop: '25px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#000000',
            opacity: 1,
        },
        dropDescription: {
            textAlign: 'center',
            font: 'normal normal bold 16px/28px Roboto',
            letterSpacing: '0px',
            color: '#515151',
            opacity: 1,
        },
        orBox: {
            textAlign: 'center',
            font: 'normal normal normal 14px/28px Roboto',
            letterSpacing: '0px',
            color: '#515151',
            opacity: 1,
            marginBottom: '6px',
        },
        imageNote: {
            marginTop: '10px',
            textAlign: 'center',
            font: 'normal normal normal 14px/28px Roboto',
            letterSpacing: '0px',
            color: '#515151',
            opacity: 1,
        },
        dropIcon: {},
        browseButton: {
            margin: '0 15px',
            minWidth: '44%',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#FFFFFF',
            opacity: 1,
            height: '36px',
        },
        dropImagesContainer: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            border: '2px dashed rgb(112, 112, 112, 0.6)',
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '4.6% 8%',
        },
        listUpdated: {
            margin: '60px 0',
            textAlign: 'center',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#000000',
            opacity: 1,
        },
        fileName: {
            textAlign: 'center',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#000000',
            maxWidth: '55%',
            margin: 'auto',
            marginTop: '10px',
        },
        validateMessage: {
            textAlign: 'center',
            margin: 'auto',
            marginBottom: '34px',
            maxWidth: '55%',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            '&>span': {
                color: '#AA1919',
            },
        },
        pleaseConfirmText: {
            textAlign: 'center',
            margin: 'auto',
            maxWidth: '55%',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
        },
        reselectFile: {
            font: "normal normal bold 16px/14px Roboto",
            letterSpacing: "0px",
            color: "#2D96EF",
            textTransform: "uppercase",
            marginTop: "24px"
        },
        fileStyle: {
            font: "normal normal normal 14px/21px Roboto",
            letterSpacing: "0.08px",
            color: "#000000",
        },
        apiValidateMessage: {
            textAlign: 'center',
            margin: 'auto',
            marginTop: '24px',
            maxWidth: '55%',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#AA1919',
        },
    }),
);

export default function BulkNptUploading(props: any) {
    const classes = styles();
    const [fileName, setFileName] = useState('');
    const [fileData, setFileData] = useState();
    const [apiError, setAPIError] = useState("");
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { duplicateRecord, totalRecord, nameOfFile } = useSelector((state: ReducersModel) => state.skillReducer);
    const [firstStepLoader, setFirstStepLoader] = useState(false);
    const [secondStepLoader, setSecondStepLoader] = useState(false);
    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    function getSteps() {
        return [t("UploadFile"), t("ValidateFile"), t("UploadSuccess")];
    }
    const uploadButtonRef = useRef<any>(null);

    const droppedFiles = (acceptedFiles: any, rejectedFiles: any) => {
        const file = acceptedFiles[0];
        if (
            // acceptedFiles[0] &&
            // (acceptedFiles[0].type === 'application/excel' ||
            //     acceptedFiles[0].type === 'application/csv' ||
            //     acceptedFiles[0].type === 'text/csv')
            true
        ) {
            setFileName(file.name);
            setFileData(file);
            dispatch({
                type: Utils.ActionName.UPDATE_SKILL_LIST,
                payload: {
                    nameOfFile: file.name,
                    wholeFile: file,
                },
            });
        }
    };

    const downlodLatestNpts = () => {
        handleDownloadNpt();
    };

    const downloadTemplate = () => {
        handleDownloadNptTemplate();
    }

    const UploadFileContentStep = () => {
        return (
            <div>
                <div className={classes.downloadText}>
                    <span onClick={downloadTemplate}>{t("DownloadTEMPLATEFILE")}</span>
                    <span>
                        <HelpIcon />
                    </span>
                </div>
                <div className={classes.downloadText}>
                    <span onClick={downlodLatestNpts}>{t("DownloadlatestNPTlist")}</span>
                    <span>
                        <HelpIcon />
                    </span>
                </div>

                <div>
                    <Dropzone
                        onDrop={(acceptedFiles, rejectedFiles) => {
                            droppedFiles(acceptedFiles, rejectedFiles);
                        }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div className={classes.dropImagesContainer} {...getRootProps()}>
                                {fileName ? <span className={classes.fileStyle}>{fileName}</span> :
                                <>
                                    <div className={classes.dropIcon}>
                                        <PublishIcon />
                                    </div>
                                    <div className={classes.dropDescription}>{t("DragDropFiles")}</div>
                                    <div className={classes.orBox}>{t("or")}</div>
                                </>
                                }
                                <input
                                    {...getInputProps()}
                                    type="file"
                                    id="profil"
                                    style={{ display: 'none' }}
                                    ref={uploadButtonRef}
                                    accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    multiple
                                />
                                {fileName ?
                                    <Button
                                        color="primary"
                                        size="large"
                                        variant="text"
                                        className={classes.reselectFile}
                                        onClick={() => {
                                            uploadButtonRef.current.click();
                                        }}
                                    >
                                        {t("Reselectfile")}
                                    </Button>
                                :
                                    <Button
                                        color="primary"
                                        size="large"
                                        variant="contained"
                                        className={classes.browseButton}
                                        onClick={() => {
                                            uploadButtonRef.current.click();
                                        }}
                                    >
                                        {t("Browse")}
                                    </Button>
                                }
                                {apiError !=="" && <div className={classes.apiValidateMessage}>{apiError}</div>}
                            </div>
                        )}
                    </Dropzone>
                    <div className={classes.imageNote}>{t("FormatFiles")}</div>
                    <div className={classes.saveContainer}>
                        <Button
                            variant="outlined"
                            size="large"
                            className={classes.cancelButton}
                            onClick={() => {
                                props.handleClose();
                                dispatch({
                                    type: Utils.ActionName.IS_LOADING,
                                    payload: false,
                                });
                            }}
                        >
                            {t("CANCEL")}
                        </Button>
                        <Button
                            color="primary"
                            disabled={fileName.length > 0 && !firstStepLoader ? false : true}
                            size="large"
                            type="submit"
                            variant="contained"
                            className={classes.saveButton}
                            onClick={() => {
                                if (props.isOperation) {
                                    handleNext();
                                } else {
                                    setFirstStepLoader(true);
                                    dispatch(validateNptBulkUpload(fileData, setFirstStepLoader, handleNext, (errorCallBack: string) => {setAPIError(errorCallBack)}));
                                }
                            }}
                        >
                            {firstStepLoader
                                ? t("Validating")
                                : t("Validate")}
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const ValidateFileContentStep = () => {
        return (
            <div>
                <div className={classes.fileName}>“{nameOfFile}”</div>
                <div className={classes.validateMessage}>
                    {t("Willimport")} {totalRecord} {t("Npts")}{', '}
                    <span>{t("Including")} {duplicateRecord} {t("ExistingNPTsUpdated")}</span>
                </div>

                <div className={classes.pleaseConfirmText}>{t("PleaseConfirm")}</div>
                <div className={classes.saveContainer}>
                    <Button
                        variant="outlined"
                        size="large"
                        className={classes.cancelButton}
                        disabled={!secondStepLoader ? false : true}
                        onClick={() => {
                            props.handleClose();
                            dispatch({
                                type: Utils.ActionName.IS_LOADING,
                                payload: false,
                            });
                        }}
                    >
                        {t("CANCEL")}
                    </Button>
                    <Button
                        color="primary"
                        size="large"
                        type="submit"
                        variant="contained"
                        className={classes.saveButton}
                        disabled={secondStepLoader}
                        onClick={() => {
                            setFirstStepLoader(true);
                            dispatch(nptBulkUpload(setSecondStepLoader, handleNext));
                        }}
                    >
                        {secondStepLoader ? t("Uploading") : t("ok")}
                    </Button>
                </div>
            </div>
        );
    };

    const UploadSuccessFileContentStep = () => {
        return (
            <div>
                <div className={classes.listUpdated}>{t("NPTlistisupdated")}</div>
                <div className={classes.saveContainer}>
                    <Button
                        color="primary"
                        size="large"
                        type="submit"
                        variant="contained"
                        className={classes.saveButton}
                        onClick={() => {
                            dispatch({
                                type: Utils.ActionName.GET_NPT_CATEGORIES_LIST,
                                payload: {
                                    page: 1,
                                },
                            });
                            dispatch(getNptCategories());
                            props.handleClose();
                        }}
                    >
                        {t("ok")}
                    </Button>
                </div>
            </div>
        );
    };

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return <UploadFileContentStep />;
            case 1:
                return <ValidateFileContentStep />;
            case 2:
                return <UploadSuccessFileContentStep />;
            default:
                return <UploadSuccessFileContentStep />;
        }
    }

    const [modalStyle] = React.useState(getModalStyle);

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const steps = getSteps();

    const isStepOptional = (step: number) => {
        return step === 1;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    return (
        <div style={modalStyle} className={classes.paper}>
            <div className={classes.mainContainer}>
                <Typography className={classes.textStyl}>{t("BulkUpload")}</Typography>
                <div className={classes.root}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps: { completed?: boolean } = {};
                            const labelProps: { optional?: React.ReactNode } = {};
                            if (isStepOptional(index)) {
                                labelProps.optional = <Typography variant="caption">{/* Optional */}</Typography>;
                            }
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {getStepContent(activeStep)}
                </div>
            </div>
        </div>
    );
}
