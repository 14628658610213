import * as React from 'react';
import { TableRow, Checkbox } from '@material-ui/core';
import MuiTableBody from '@material-ui/core/TableBody';
import { formatDate, formatNumber, formatPercent } from '../../../helpers';
import { TableBodyProp } from '../../../Interfaces';
import { LBRTableCell, LBRTableCellLink, LBRTableCellPercentage } from '../../../styles';
import RowStatus from './RowStatus';
import { tableHeaderCell } from './../../LBRHistoryTable/constant';
import { useHistory } from 'react-router-dom';

const TableBody:React.FC<TableBodyProp> = ({
    rows,
    selected,
    onSelected,
    isLoadingRows,
}) => {
    const history = useHistory();
    const { min: minValue, max: maxValue }: any = rows.reduce((result: any, current: any) => {
        const lbr = current.actualLbr;
        return {
            min: lbr < result.min ? lbr : result.min,
            max: lbr > result.max ? lbr : result.max,
        };
    }, { min: Infinity, max: -Infinity });

    const navigation = (item: any) => {
        const { id, versionNo, lineSetupId } = item;
        history.push({
            pathname: '/lbrhistory-restore',
            search: `?id=${id}&lineSetupId=${lineSetupId}&versionNo=${versionNo}`,
        })
    }

    return (
        <MuiTableBody style={{ minHeight: 500 }}>
            <RowStatus 
                rowLength={rows.length} 
                columnLength={tableHeaderCell.length} 
                isLoading={isLoadingRows} 
            />
            {rows?.map((item: any, key: any) => (
                <TableRow>
                    <LBRTableCell padding='checkbox'>
                        <Checkbox
                            checked={selected.includes(item.id)}
                            onChange={()=> onSelected(item.id)}
                            color='primary' 
                        />
                    </LBRTableCell>
                    <LBRTableCell padding='none'>{key + 1}</LBRTableCell>
                    <LBRTableCellLink onClick={() => navigation(item)} padding='none' width='8%'>{item.versionNo}</LBRTableCellLink>
                    <LBRTableCell padding='none' width='10%'>{formatDate(new Date(item.createdDt))}</LBRTableCell>
                    <LBRTableCell padding='none' width='8%'>{item.createdBy}</LBRTableCell>
                    <LBRTableCell padding='none' width='12%'>{ item.lastTimeStudyDt != null? (formatDate(new Date(item.lastTimeStudyDt))) : ""}</LBRTableCell>
                    <LBRTableCellPercentage 
                        isHightest={(item.actualLbr === maxValue)} 
                        isLowest={(item.actualLbr === minValue)} 
                        sameMinMaxValue={(item.actualLbr === maxValue) === (item.actualLbr === minValue)}
                        align='center' >
                            {formatPercent(item.actualLbr)}
                    </LBRTableCellPercentage>
                    <LBRTableCell align='center' >{formatPercent(item.currentShiftEff || item.outputEff)}</LBRTableCell>
                    <LBRTableCell align='center' padding='none'>{formatNumber(item.totalManpower)}</LBRTableCell>
                    <LBRTableCell padding='none' width='10%'>{formatNumber(item.totalOutput)}</LBRTableCell>
                    <LBRTableCell padding='none' width='10%'>{formatNumber(item.totalOutput * item.totalWebSmv)}</LBRTableCell>
                    <LBRTableCell padding='none' width='20%' style={{ wordBreak: 'break-all' }}>{item.remark}</LBRTableCell>
                </TableRow>
            ))}
        </MuiTableBody>
    )
}

export default TableBody;