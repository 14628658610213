import Utils from '../../../utils';

export const getProductSettings = (t: any) => {
    return (dispatch: Function, getState: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, t('PleaseCheckInternet'));
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }

        let parameters = ``;

        Utils.api.getApiCall(
            Utils.endPoints.productSetting,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    localStorage.setItem('productSettings', JSON.stringify(innerData));
                    dispatch({
                        type: Utils.ActionName.GET_PRODUCT_SETTINGS,
                        payload: {
                            editEffEmails: innerData.eff?.emails,
                            editEffValue: innerData.eff?.value,
                            editLbrEmails: innerData.lbr?.emails,
                            editLbrValue: innerData.lbr?.value,
                            editNptEmails: innerData.npt?.emails,
                            editNptValue: innerData.npt?.value,
                            editTimeStudy: innerData.timestudy?.value,
                            editSkillMatrix: innerData.skillmatrix?.value,
                            skillmatrixmode: innerData.skillmatrixmode?.value,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const updateProductSettings = (t: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, t('PleaseCheckInternet'));
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const {
            editEffEmails,
            editEffValue,
            editLbrEmails,
            editLbrValue,
            editNptEmails,
            editNptValue,
            editTimeStudy,
            editSkillMatrix,
            skillmatrixmode,
        } = getState().productSettingReducer;

        const dataToSend = {
            eff: {
                value: editEffValue,
                emails: editEffEmails,
            },
            lbr: {
                value: editLbrValue,
                emails: editLbrEmails,
            },
            npt: {
                value: editNptValue,
                emails: editNptEmails,
            },
            timestudy: {
                value: editTimeStudy,
            },
            skillmatrix: {
                value: editSkillMatrix,
            },
            skillmatrixmode: {
                value: skillmatrixmode,
            },
        };
        Utils.api.putApiCall(
            Utils.endPoints.productSetting,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    Utils.showAlert(1, data.message);
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getNeedleTypesAll = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const {
            statusAll,
            language1Filter,
            language2Filter,
            page,
            limit,
            search,
        } = getState().masterDataTranslationReducer;
        let parameters = `?page=${page}&limit=${limit}${search.length > 0 ? `&search=${search}` : ''}${
            statusAll === 2
                ? `&translatedFilter=true`
                : statusAll === 3
                ? `&untranslatedFilter=true`
                : statusAll === 1
                ? '&translatedFilter=true&untranslatedFilter=true'
                : ''
        }${language1Filter ? `&language2Filter=true` : ''}${
            language2Filter ? `&language1Filter=true` : ''
        }&firstLang=khm&secondLang=chi`;

        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        Utils.api.getApiCall(
            Utils.endPoints.needleTranslation,
            parameters,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                        payload: {
                            needleTypeData: innerData.data,
                            firstLangCount: innerData.firstLangCount,
                            secondLangCount: innerData.secondLangCount,
                            totalLangCount: innerData.totalLangCount,
                            count: innerData.count,
                            page: innerData.page,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getFeaturesAll = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const {
            statusAll,
            language1Filter,
            language2Filter,
            page,
            limit,
            search,
        } = getState().masterDataTranslationReducer;
        let parameters = `?page=${page}&limit=${limit}${search.length > 0 ? `&search=${search}` : ''}${
            statusAll === 2
                ? `&translatedFilter=true`
                : statusAll === 3
                ? `&untranslatedFilter=true`
                : statusAll === 1
                ? '&translatedFilter=true&untranslatedFilter=true'
                : ''
        }${language1Filter ? `&language2Filter=true` : ''}${
            language2Filter ? `&language1Filter=true` : ''
        }&firstLang=khm&secondLang=chi`;

        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        Utils.api.getApiCall(
            Utils.endPoints.ppaTranslation,
            parameters,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                        payload: {
                            ppaData: innerData.data,
                            firstLangCount: innerData.firstLangCount,
                            secondLangCount: innerData.secondLangCount,
                            totalLangCount: innerData.totalLangCount,
                            count: innerData.count,
                            page: innerData.page,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getMachinesAll = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const {
            statusAll,
            language1Filter,
            language2Filter,
            page,
            limit,
            search,
        } = getState().masterDataTranslationReducer;
        let parameters = `?page=${page}&limit=${limit}${search.length > 0 ? `&search=${search}` : ''}${
            statusAll === 2
                ? `&translatedFilter=true`
                : statusAll === 3
                ? `&untranslatedFilter=true`
                : statusAll === 1
                ? '&translatedFilter=true&untranslatedFilter=true'
                : ''
        }${language1Filter ? `&language2Filter=true` : ''}${
            language2Filter ? `&language1Filter=true` : ''
        }&firstLang=khm&secondLang=chi`;

        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        Utils.api.getApiCall(
            Utils.endPoints.machinesTranslation,
            parameters,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                        payload: {
                            machineData: innerData.data,
                            firstLangCount: innerData.firstLangCount,
                            secondLangCount: innerData.secondLangCount,
                            totalLangCount: innerData.totalLangCount,
                            count: innerData.count,
                            page: innerData.page,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getStylesAll = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const {
            statusAll,
            language1Filter,
            language2Filter,
            page,
            limit,
            search,
        } = getState().masterDataTranslationReducer;
        let parameters = `?page=${page}&limit=${limit}${search.length > 0 ? `&search=${search}` : ''}${
            statusAll === 2
                ? `&translatedFilter=true`
                : statusAll === 3
                ? `&untranslatedFilter=true`
                : statusAll === 1
                ? '&translatedFilter=true&untranslatedFilter=true'
                : ''
        }${language1Filter ? `&language2Filter=true` : ''}${
            language2Filter ? `&language1Filter=true` : ''
        }&firstLang=khm&secondLang=chi`;
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        Utils.api.getApiCall(
            Utils.endPoints.stylesTranslation,
            parameters,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                        payload: {
                            styleData: innerData.data,
                            firstLangCount: innerData.firstLangCount,
                            secondLangCount: innerData.secondLangCount,
                            totalLangCount: innerData.totalLangCount,
                            count: innerData.count,
                            page: innerData.page,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getNPTCategoriesAll = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const {
            statusAll,
            language1Filter,
            language2Filter,
            page,
            limit,
            search,
        } = getState().masterDataTranslationReducer;
        let parameters = `?page=${page}&limit=${limit}${search.length > 0 ? `&search=${search}` : ''}${
            statusAll === 2
                ? `&translatedFilter=true`
                : statusAll === 3
                ? `&untranslatedFilter=true`
                : statusAll === 1
                ? '&translatedFilter=true&untranslatedFilter=true'
                : ''
        }${language1Filter ? `&language2Filter=true` : ''}${
            language2Filter ? `&language1Filter=true` : ''
        }&firstLang=khm&secondLang=chi`;

        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        Utils.api.getApiCall(
            Utils.endPoints.nptCategoriesTranslation,
            parameters,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                        payload: {
                            nptCategoryData: innerData.data,
                            firstLangCount: innerData.firstLangCount,
                            secondLangCount: innerData.secondLangCount,
                            totalLangCount: innerData.totalLangCount,
                            count: innerData.count,
                            page: innerData.page,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getNPTTypesAll = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const {
            statusAll,
            language1Filter,
            language2Filter,
            page,
            limit,
            search,
        } = getState().masterDataTranslationReducer;
        let parameters = `?page=${page}&limit=${limit}${search.length > 0 ? `&search=${search}` : ''}${
            statusAll === 2
                ? `&translatedFilter=true`
                : statusAll === 3
                ? `&untranslatedFilter=true`
                : statusAll === 1
                ? '&translatedFilter=true&untranslatedFilter=true'
                : ''
        }${language1Filter ? `&language2Filter=true` : ''}${
            language2Filter ? `&language1Filter=true` : ''
        }&firstLang=khm&secondLang=chi`;

        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        Utils.api.getApiCall(
            Utils.endPoints.nptTypesTranslation,
            parameters,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                        payload: {
                            nptTypeData: innerData.data,
                            firstLangCount: innerData.firstLangCount,
                            secondLangCount: innerData.secondLangCount,
                            totalLangCount: innerData.totalLangCount,
                            count: innerData.count,
                            page: innerData.page,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getSkillAll = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const {
            statusAll,
            language1Filter,
            language2Filter,
            page,
            limit,
            search,
        } = getState().masterDataTranslationReducer;
        let parameters = `?page=${page}&limit=${limit}${search.length > 0 ? `&search=${search}` : ''}${
            statusAll === 2
                ? `&translatedFilter=true`
                : statusAll === 3
                ? `&untranslatedFilter=true`
                : statusAll === 1
                ? '&translatedFilter=true&untranslatedFilter=true'
                : ''
        }${language1Filter ? `&language2Filter=true` : ''}${
            language2Filter ? `&language1Filter=true` : ''
        }&firstLang=khm&secondLang=chi`;

        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        Utils.api.getApiCall(
            Utils.endPoints.skillTypesTranslation,
            parameters,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                        payload: {
                            skillTypeData: innerData.data,
                            firstLangCount: innerData.firstLangCount,
                            secondLangCount: innerData.secondLangCount,
                            totalLangCount: innerData.totalLangCount,
                            count: innerData.count,
                            page: innerData.page,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getProductAll = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const {
            statusAll,
            language1Filter,
            language2Filter,
            page,
            limit,
            search,
        } = getState().masterDataTranslationReducer;
        let parameters = `?page=${page}&limit=${limit}${search.length > 0 ? `&search=${search}` : ''}${
            statusAll === 2
                ? `&translatedFilter=true`
                : statusAll === 3
                ? `&untranslatedFilter=true`
                : statusAll === 1
                ? '&translatedFilter=true&untranslatedFilter=true'
                : ''
        }${language1Filter ? `&language2Filter=true` : ''}${
            language2Filter ? `&language1Filter=true` : ''
        }&firstLang=khm&secondLang=chi`;
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        Utils.api.getApiCall(
            Utils.endPoints.productTypesTranslation,
            parameters,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                        payload: {
                            productTypeData: innerData.data,
                            firstLangCount: innerData.firstLangCount,
                            secondLangCount: innerData.secondLangCount,
                            totalLangCount: innerData.totalLangCount,
                            count: innerData.count,
                            page: innerData.page,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getmachineAttachmentAll = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const {
            statusAll,
            language1Filter,
            language2Filter,
            page,
            limit,
            search,
        } = getState().masterDataTranslationReducer;
        let parameters = `?page=${page}&limit=${limit}${search.length > 0 ? `&search=${search}` : ''}${
            statusAll === 2
                ? `&translatedFilter=true`
                : statusAll === 3
                ? `&untranslatedFilter=true`
                : statusAll === 1
                ? '&translatedFilter=true&untranslatedFilter=true'
                : ''
        }${language1Filter ? `&language2Filter=true` : ''}${
            language2Filter ? `&language1Filter=true` : ''
        }&firstLang=khm&secondLang=chi`;
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        Utils.api.getApiCall(
            Utils.endPoints.machineAttachmentTranslation,
            parameters,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                        payload: {
                            machineAttachmentData: innerData.data,
                            firstLangCount: innerData.firstLangCount,
                            secondLangCount: innerData.secondLangCount,
                            totalLangCount: innerData.totalLangCount,
                            count: innerData.count,
                            page: innerData.page,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getoperationCodeTranslationAll = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const {
            statusAll,
            language1Filter,
            language2Filter,
            page,
            limit,
            search,
        } = getState().masterDataTranslationReducer;
        let parameters = `?page=${page}&limit=${limit}${search.length > 0 ? `&search=${search}` : ''}${
            statusAll === 2
                ? `&translatedFilter=true`
                : statusAll === 3
                ? `&untranslatedFilter=true`
                : statusAll === 1
                ? '&translatedFilter=true&untranslatedFilter=true'
                : ''
        }${language1Filter ? `&language2Filter=true` : ''}${
            language2Filter ? `&language1Filter=true` : ''
        }&firstLang=khm&secondLang=chi`;
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        Utils.api.getApiCall(
            Utils.endPoints.operationCodeTranslation,
            parameters,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                        payload: {
                            operationCodeData: innerData.data,
                            firstLangCount: innerData.firstLangCount,
                            secondLangCount: innerData.secondLangCount,
                            totalLangCount: innerData.totalLangCount,
                            count: innerData.count,
                            page: innerData.page,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

/**
 * translation of needle type
 */
export const needleTypetranslation = () => {
    return (dispatch: Function, getState: any) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { needleTypeData } = getState().masterDataTranslationReducer;

        const dataToSend = { data: needleTypeData };
        Utils.api.putApiCall(
            `${Utils.endPoints.needleTranslationUpdate}?firstLang=khm&secondLang=chi`,
            dataToSend,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch(getNeedleTypesAll());
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

/**
 * translation of PPA/Features
 */
export const ppaTranslationUpdate = () => {
    return (dispatch: Function, getState: any) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { ppaData } = getState().masterDataTranslationReducer;

        const dataToSend = { data: ppaData };
        Utils.api.putApiCall(
            `${Utils.endPoints.ppaTranslationUpdate}?firstLang=khm&secondLang=chi`,
            dataToSend,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch(getFeaturesAll());
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

/**
 * translation of Machines
 */
export const machinesTranslationUpdate = () => {
    return (dispatch: Function, getState: any) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { machineData } = getState().masterDataTranslationReducer;

        const dataToSend = { data: machineData };
        Utils.api.putApiCall(
            `${Utils.endPoints.machinesTranslationUpdate}?firstLang=khm&secondLang=chi`,
            dataToSend,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch(getMachinesAll());
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

/**
 * translation of Styles
 */
export const stylesTranslationUpdate = () => {
    return (dispatch: Function, getState: any) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { styleData } = getState().masterDataTranslationReducer;

        const dataToSend = { data: styleData };
        Utils.api.putApiCall(
            `${Utils.endPoints.stylesTranslationUpdate}?firstLang=khm&secondLang=chi`,
            dataToSend,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch(getStylesAll());
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

/**
 * translation of NPT Categories
 */
export const nptCategoriesTranslationUpdate = () => {
    return (dispatch: Function, getState: any) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { nptCategoryData } = getState().masterDataTranslationReducer;

        const dataToSend = { data: nptCategoryData };
        Utils.api.putApiCall(
            `${Utils.endPoints.nptCategoriesTranslationUpdate}?firstLang=khm&secondLang=chi`,
            dataToSend,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch(getNPTCategoriesAll());
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

/**
 * translation of NPT Types
 */
export const nptTypesTranslationUpdate = () => {
    return (dispatch: Function, getState: any) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { nptTypeData } = getState().masterDataTranslationReducer;

        const dataToSend = { data: nptTypeData };
        Utils.api.putApiCall(
            `${Utils.endPoints.nptTypesTranslationUpdate}?firstLang=khm&secondLang=chi`,
            dataToSend,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch(getNPTTypesAll());
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

//  traslation of skill type

export const skillTypesTranslationUpdate = () => {
    return (dispatch: Function, getState: any) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { skillTypeData } = getState().masterDataTranslationReducer;

        const dataToSend = { data: skillTypeData };
        Utils.api.putApiCall(
            `${Utils.endPoints.skillTypesTranslationUpdate}?firstLang=khm&secondLang=chi`,
            dataToSend,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch(getSkillAll());
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const productTypesTranslationUpdate = () => {
    return (dispatch: Function, getState: any) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { productTypeData } = getState().masterDataTranslationReducer;

        const dataToSend = { data: productTypeData };
        Utils.api.putApiCall(
            `${Utils.endPoints.productTypesTranslationUpdate}?firstLang=khm&secondLang=chi`,
            dataToSend,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch(getProductAll());
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

//   traslation of Machine Attachment type

export const machineAttachmentTranslationUpdate = () => {
    return (dispatch: Function, getState: any) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { machineAttachmentData } = getState().masterDataTranslationReducer;

        const dataToSend = { data: machineAttachmentData };
        Utils.api.putApiCall(
            `${Utils.endPoints.machineAttachmentTranslationUpdate}?firstLang=khm&secondLang=chi`,
            dataToSend,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch(getmachineAttachmentAll());
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

//   traslation of Operation Code

export const operationCodeTranslationUpdate = () => {
    return (dispatch: Function, getState: any) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { operationCodeData } = getState().masterDataTranslationReducer;

        const dataToSend = { data: operationCodeData };
        Utils.api.putApiCall(
            `${Utils.endPoints.operationCodeTranslationUpdate}?firstLang=khm&secondLang=chi`,
            dataToSend,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch(getoperationCodeTranslationAll());
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

/**
 *  master  data management
 */

export const getMDMApi = (number: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const { pagePpa, limitPpa, searchMasterData, order, isInActiveMDM, isActiveMDM } = getState().ppaCodeReducer;
        let sortBy = '';
        switch (number) {
            case 6:
                sortBy = 'subType';
                break;
            case 1:
                sortBy = 'name';
                break;
            case 2:
                sortBy = 'productType';
                break;
            case 3:
                sortBy = 'attachmentCode';
                break;
            case 4:
                sortBy = 'needleType';
                break;
            case 5:
                sortBy = 'skillType';
                break;
        }
        let parameters = `?page=${pagePpa}&limit=${limitPpa}&order=${order}&sortBy=${sortBy}${
            searchMasterData.length > 0 ? `&search=${searchMasterData}` : ``
        }${
            isInActiveMDM && isActiveMDM ? `` : isActiveMDM ? `&isActive=true` : isInActiveMDM ? `&isActive=false` : ``
        }`;
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        Utils.api.getApiCall(
            number === 1
                ? Utils.endPoints.ppaCode
                : number === 4
                ? Utils.endPoints.needleMasterData
                : number === 5
                ? Utils.endPoints.skillTypeMasterData
                : number === 2
                ? Utils.endPoints.productMasterData
                : number === 3
                ? Utils.endPoints.machineMasterData
                : number === 6
                ? Utils.endPoints.machineData
                : Utils.endPoints.ppaCode,
            parameters,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data.data;
                    dispatch({
                        type: Utils.ActionName.UPDATE_PPA_CODE,
                        payload: {
                            ppaCodeData: innerData,
                            countPpa: data.data.count,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

/**
 *  master  data add api
 */

export const addMGM = (values: any, setSubmitting: any, handleClose: any) => {
    return (dispatch: Function, getState: Function) => {
        const { selectedType, hostedImages } = getState().ppaCodeReducer;
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            setSubmitting(false);
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { nameType, displayName, description, isActive, firstLang, secondLang } = values;
        const { firstLang: firstLanguage, secondLang: secondLanguage } = getState().globalLangDataReducer;

        let dataToSend = {
            displayName,
            desc1: description,
            firstLang: firstLanguage,
            secondLang: secondLanguage,
            firstLangData: firstLang,
            secondLangData: secondLang,
            firstLangIsGoogle: false,
            secondLangIsGoogle: false,
            isActive,
        };

        switch (selectedType) {
            case 2:
                var addType = { productType: nameType };
                dataToSend = { ...dataToSend, ...addType };
                break;
            case 3:
                var addAttachmentCode = {
                    attachmentCode: nameType,
                    image1: hostedImages.length > 0 ? hostedImages[0] : '',
                };
                dataToSend = { ...dataToSend, ...addAttachmentCode };
                break;
            case 4:
                var addNeedleType = { needleType: nameType };
                dataToSend = { ...dataToSend, ...addNeedleType };
                break;
            case 5:
                var addSkillType = { skillType: nameType };
                dataToSend = { ...dataToSend, ...addSkillType };
                break;
            case 6:
                var addSubType = { subType: nameType };
                dataToSend = { ...dataToSend, ...addSubType };
                break;
        }
        Utils.api.postApiCall(
            selectedType === 4
                ? Utils.endPoints.addNeedleMasterData
                : selectedType === 5
                ? Utils.endPoints.addSkillTypeMasterData
                : selectedType === 2
                ? Utils.endPoints.addProductMasterData
                : selectedType === 6
                ? Utils.endPoints.addMachineData
                : Utils.endPoints.addMachineMasterData,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    setSubmitting(false);
                    handleClose();
                    dispatch(getMDMApi(selectedType));
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                setSubmitting(false);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

/**
 * update master data translation
 */
export const updateMGM = (values: any, setSubmitting: any, handleClose: any) => {
    return (dispatch: Function, getState: any) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { selectedType, idEdit, hostedImages } = getState().ppaCodeReducer;
        const { firstLang: firstLanguage, secondLang: secondLanguage } = getState().globalLangDataReducer;

        const { nameType, displayName, description, isActive, firstLang, secondLang } = values;

        let dataToSend = {
            displayName,
            desc1: description,
            firstLang: firstLanguage,
            secondLang: secondLanguage,
            firstLangData: firstLang,
            secondLangData: secondLang,
            firstLangIsGoogle: false,
            secondLangIsGoogle: false,
            isActive,
        };
        switch (selectedType) {
            case 2:
                var addType = { productType: nameType };
                dataToSend = { ...dataToSend, ...addType };
                break;
            case 3:
                var addAttachmentCode = {
                    attachmentCode: nameType,
                    image1: hostedImages.length > 0 ? hostedImages[0] : '',
                };
                dataToSend = { ...dataToSend, ...addAttachmentCode };
                break;

            case 4:
                var addNeedleType = { needleType: nameType };
                dataToSend = { ...dataToSend, ...addNeedleType };
                break;
            case 5:
                var addSkillType = { skillType: nameType };
                dataToSend = { ...dataToSend, ...addSkillType };
                break;
            case 6:
                var addSubType = { subType: nameType };
                dataToSend = { ...dataToSend, ...addSubType };
                break;
        }
        Utils.api.putApiCall(
            selectedType === 4
                ? `${Utils.endPoints.addNeedleMasterData}?id=${idEdit}`
                : selectedType === 5
                ? `${Utils.endPoints.addSkillTypeMasterData}?id=${idEdit}`
                : selectedType === 2
                ? `${Utils.endPoints.addProductMasterData}?id=${idEdit}`
                : selectedType === 6
                ? `${Utils.endPoints.addMachineData}?id=${idEdit}`
                : `${Utils.endPoints.addMachineMasterData}?id=${idEdit}`,
            dataToSend,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    handleClose();
                    dispatch(getMDMApi(selectedType));
                    setSubmitting(false);
                } else {
                    setSubmitting(false);

                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                setSubmitting(false);

                const { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

/**
 * update master data translation
 */

export const deleteMDM = (id: any, handleCloseEdit: any) => {
    return (dispatch: Function, getState: Function) => {
        const { selectedType } = getState().ppaCodeReducer;

        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const dataToSend = '';
        Utils.api.deleteApiCall(
            selectedType === 1
                ? `${Utils.endPoints.ppaCode}/${id}`
                : selectedType === 4
                ? `${Utils.endPoints.addNeedleMasterData}/${id}`
                : selectedType === 5
                ? `${Utils.endPoints.addSkillTypeMasterData}/${id}`
                : selectedType === 2
                ? `${Utils.endPoints.addProductMasterData}/${id}`
                : selectedType === 6
                ? `${Utils.endPoints.addMachineData}/${id}`
                : `${Utils.endPoints.addMachineMasterData}/${id}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch(getMDMApi(selectedType));
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    handleCloseEdit();
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

/** Get Skill Master Data Management */

export const getSkillMDMApi = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const {
            pagePpa,
            limitPpa,
            searchMasterData,
            order,
            sortBy,
            isInActiveMDM,
            isActiveMDM,
        } = getState().ppaCodeReducer;

        let parameters = `?page=${pagePpa}&limit=${limitPpa}&order=${order}&sortBy=${sortBy}${
            searchMasterData.length > 0 ? `&search=${searchMasterData}` : ``
        }${
            isInActiveMDM && isActiveMDM ? `` : isActiveMDM ? `&isActive=true` : isInActiveMDM ? `&isActive=false` : ``
        }`;

        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        Utils.api.getApiCall(
            Utils.endPoints.skillTypeMasterData,
            parameters,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data.data;
                    dispatch({
                        type: Utils.ActionName.UPDATE_PPA_CODE,
                        payload: {
                            skillTypeData: innerData,
                            countPpa: data.data.count,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};
