import Utils from '../../utils';
import {
    AnalyticsFiltersModel,
    AnalyticsEfficiencyModel,
    AnalyticsOperatorNPT,
    AnalyticsStatsModel,
    AnalyticsSaveTemplateModel,
    AnalyticsTemplateListModel,
    AnalyticsChart,
    LoadingFilterModel,
} from '../../model';

export const AnalyticsFiltersReducer = (state: AnalyticsFiltersModel = new AnalyticsFiltersModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_ANALYTICS_FILTERS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const AnalyticsEfficiencyReducer = (
    state: AnalyticsEfficiencyModel = new AnalyticsEfficiencyModel(),
    action: any,
) => {
    switch (action.type) {
        case Utils.ActionName.GET_ANALYTICS_EFFICIENCY:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const AnalyticsOperatorNPTReducer = (state: AnalyticsOperatorNPT = new AnalyticsOperatorNPT(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_ANALYTICS_OP_NPT:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const AnalyticsStatsReducer = (state: AnalyticsStatsModel = new AnalyticsStatsModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_ANALYTICS_STATS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const AnalyticsSaveReducer = (
    state: AnalyticsSaveTemplateModel = new AnalyticsSaveTemplateModel(),
    action: any,
) => {
    switch (action.type) {
        case Utils.ActionName.UPDATE_ANALYTICS_TEMPLATE_NAME:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const AnalyticsTemplateListReducer = (
    state: AnalyticsTemplateListModel = new AnalyticsTemplateListModel(),
    action: any,
) => {
    switch (action.type) {
        case Utils.ActionName.GET_ANALYTICS_TEMPLATE_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const analyticsChartReducer = (state: AnalyticsChart = new AnalyticsChart(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_ANALYTICS_CHART:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const filterLoaderReducer = (state: LoadingFilterModel = new LoadingFilterModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.IS_LOADING_FILTER:
            return { ...state, isFilterLoading: action.payload };
        default:
            return state;
    }
};
