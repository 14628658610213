import React from 'react';
import { makeStyles, createStyles, Theme, CircularProgress } from '@material-ui/core';
const styles = makeStyles((theme: Theme) =>
    createStyles({
        spinner: {
            width: '100%',
            height: '350px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }),
);

export default function Spinner() {
    const classes = styles();

    return (
        <div className={classes.spinner}>
            <CircularProgress />
        </div>
    );
}
