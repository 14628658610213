import Utils from '../../utils';
import {
    lineSetuoModel,
    lineSetuoReplicaModel,
    LineSetupInfo,
    OperatorInPool,
    searchOperatorInPoolModel,
    StationModel,
    linesSetupDataModel,
    linesSetupModelStationWiseReplicaModel,
    lineSetupList,
    LineShiftModel,
} from '../../model';

export const lineSetupReducer = (state: lineSetuoModel = new lineSetuoModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.LINE_SETUP:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const lineBalanceReducer = (state: lineSetuoModel = new lineSetuoModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.UPDATE_LINE_BALANCE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const lineSetupInfoReducer = (state: LineSetupInfo = new LineSetupInfo(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_LINE_SETUP_INFO:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const operatorInPoolReducer = (state: OperatorInPool = new OperatorInPool(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_OPERATOR_POOL:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const stationReducer = (state: StationModel = new StationModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_STATION:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const lineSetupReplicaReducer = (state: lineSetuoReplicaModel = new lineSetuoReplicaModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_LINES_SETUP_REPLICA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const searchOperatorInPoolReducer = (
    state: searchOperatorInPoolModel = new searchOperatorInPoolModel(),
    action: any,
) => {
    switch (action.type) {
        case Utils.ActionName.GET_OPERATOR_IN_POOL_SEARCH:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const infoLineSetupReducer = (state: linesSetupDataModel = new linesSetupDataModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.LINE_SETUP_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
export const linesSetupModelStationWiseReplicaReducer = (
    state: linesSetupModelStationWiseReplicaModel = new linesSetupModelStationWiseReplicaModel(),
    action: any,
) => {
    switch (action.type) {
        case Utils.ActionName.LINE_SETUP_DATA_STATION_REPLICA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const lineSetupListReducer = (state: lineSetupList = new lineSetupList(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.LINE_SETUP_LIST_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const lineShiftReducer = (state: LineShiftModel = new LineShiftModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_LINE_SHIFTS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
