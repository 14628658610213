import { GET_ROWS } from "../constant";

const reducers = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ROWS:
            return {...state, data: payload};
        default: return state;
    }
}

const initialState = {
    data: null,
};

export default reducers;