import React from 'react';
// @ts-ignore
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Badge, createStyles, makeStyles, Theme, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { DragIndicator } from '@material-ui/icons';
import SimpleModal from '../../components/modal';
import AddOperation from './addOperation';
import EditOperation from './editOperation';
import Utils from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModel } from '../../model';
import { deleteOperation } from './action';
import DeleteModal from '../../components/modal/deleteModal';
import { useTranslation } from 'react-i18next';
import { ImageCaurosel } from '../../components/caurosel/imagecaurosel';
import { minuteToTime } from '../../utils/convertTime';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        dragBox: {
            marginBottom: '10px',
        },
        categories: {
            display: 'flex',
            marginBottom: '10px',
            backgroundColor: 'white',
            height: '41.5px',
            padding: '7px',
            '&:hover': {
                '&>div:nth-child(1)': {
                    opacity: 1,
                },
                '&>div:nth-child(11)': {
                    '& svg': {
                        color: 'var(--blue)',
                        transition: '1s all',
                    },
                },
            },
            '&:focus': {
                '&>div:nth-child(1)': {
                    opacity: 1,
                },
            },
            alignItems: 'center',
            '&>div': {
                font: 'normal normal normal 14px/21px Roboto',
                letterSpacing: '0.08px',
                color: '#000000DE',
                textTransform: 'uppercase',
                opacity: 1,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                padding: '9px',
                whiteSpace: 'nowrap',
                display: 'inline-block',
            },
            '&>div:nth-child(1)': {
                width: '3%',
                display: 'flex',
                margin: '-14px 0',
                marginLeft: '-7px',
                alignItems: 'center',
                opacity: 0,
                paddingLeft: '0px',
            },
            '&>div:nth-child(2)': { width: '5%' },
            '&>div:nth-child(3)': { width: '13%' },
            '&>div:nth-child(4)': { width: '25%' },
            '&>div:nth-child(5)': { width: '8%' },
            '&>div:nth-child(6)': { width: '9%' },
            '&>div:nth-child(7)': { width: '9%' },
            '&>div:nth-child(8)': { width: '9%' },
            '&>div:nth-child(9)': { width: '10%' },
            '&>div:nth-child(10)': { width: '7%', textAlign: 'center' },
            '&>div:nth-child(11)': { width: '7%', display: 'flex'},
            '&>div:nth-child(12)': { cursor: 'pointer'},
        },
        roundStep: {
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '20px',
            height: '20px',
            border: '1px solid #707070',
        },
        standingRectangle: {
            padding: '0 2px',
            height: '56px',
            backgroundColor: '#1E88E5',
        },
        styleImageBG: {
            width: '25px',
            height: '25px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            cursor: 'pointer',
            border: '1px solid',
        },
        badge: {
            '& .MuiBadge-anchorOriginTopRightRectangle': {
                transform: 'scale(0.8) translate(50%, -50%)',
            },
        },
        overflowVisible: {
            overflow: 'visible !important'
        }
    }),
);

export default function OperationList({ sewingData, isSameUser }: any) {
    const classes = styles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [images, setImages] = React.useState<string[]>([]);
    const [openImage, setOpenImage] = React.useState(false);

    const { operationIdToDelete, styleIdOfOperationToDelete } = useSelector(
        (state: ReducersModel) => state.sewingOperationForAddStyleReducer,
    );

    function onDragEnd(result: any) {
        if (!result.destination) return;

        const items = Array.from(sewingData);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        dispatch({
            type: Utils.ActionName.UPDATE_SEWING_OPERATION_FOR_ADD_STYLE,
            payload: {
                sewingData: items,
                listIsReordered: true,
            },
        });
    }

    // add operation
    const [openAddOperation, setOpenAddOperation] = React.useState(false);
    const handleOpenAddOperation = () => {
        setOpenAddOperation(true);
    };
    const handleCloseAddOperation = () => {
        setOpenAddOperation(false);
    };

    // edit operation
    const [openEditOperation, setOpenEditOperation] = React.useState(false);
    const handleOpenEditOperation = () => {
        setOpenEditOperation(true);
    };
    const handleCloseEditOperation = () => {
        setOpenEditOperation(false);
    };

    // delete operation
    const [openDeleteOperation, setOpenDeleteOperation] = React.useState(false);
    const handleOpenDeleteOperation = () => {
        setOpenDeleteOperation(true);
    };
    const handleCloseDeleteOperation = () => {
        setOpenDeleteOperation(false);
    };
    const getImageArray = (row: any) => {
        const tempArray = [];
        for (var i = 1; i <= 3; i++) {
            if (row[`machineAttachment${i}Data`] !== null) {
                const { image1, attachmentCode } = row[`machineAttachment${i}Data`];
                tempArray.push(Utils.CommonFunctions.getImageWithOperationCode(attachmentCode, image1));
            }
        }
        setImages(tempArray);
    };

    const getLength = (row: any) => {
        const tempArray = [];
        for (var i = 1; i <= 3; i++) {
            if (row[`machineAttachment${i}Data`] !== null) {
                const { image1, attachmentCode } = row[`machineAttachment${i}Data`];
                tempArray.push(Utils.CommonFunctions.getImageWithOperationCode(attachmentCode, image1));
            }
        }
        return tempArray.length;
    };
    const handleImageOpen = () => {
        setOpenImage(true);
    };
    const handleImageClose = () => {
        setOpenImage(false);
    };

    const deleteOperationState = (operationId: any, styleId: any) => {
        dispatch({
            type: Utils.ActionName.UPDATE_SEWING_OPERATION_FOR_ADD_STYLE,
            payload: {
                operationIdToDelete: operationId,
                styleIdOfOperationToDelete: styleId,
            },
        });
    };

    return (
        <React.Fragment>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                    {(provided: any) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} {...provided.dragHandleProps}>
                            {sewingData.map((row: any, index: any) => {
                                const imageLength = getLength(row);
                                const {
                                    step,
                                    id,
                                    operation,
                                    operationData,
                                    desc1,
                                    frequency,
                                    macSubTypeId,
                                    needleTypeId,
                                    smv,
                                    tct,
                                    styleId,
                                    macSubTypeData,
                                    needleTypeData,
                                    operationId,
                                    skillTypeData,
                                    machineAttachment1Data,
                                } = row;
                                return (
                                    <Draggable key={id} draggableId={`${step}`} index={index}>
                                        {(provided: any) => (
                                            <div
                                                className={classes.categories}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <div>
                                                    <span className={classes.standingRectangle} />
                                                    <DragIndicator
                                                        style={{ color: '#D2D2D2', width: '18px', height: '18px' }}
                                                    />
                                                </div>
                                                <div>
                                                    <div className={classes.roundStep}>{index + 1}</div>
                                                </div>

                                                <div>
                                                    <Tooltip title={operation} placement="top-start">
                                                        <span>{operation}</span>
                                                    </Tooltip>
                                                </div>

                                                <div>
                                                    <Tooltip title={desc1} placement="top-start">
                                                        <span>{desc1}</span>
                                                    </Tooltip>
                                                </div>
                                                <div>
                                                    <Tooltip title={skillTypeData?.skillTypeCode} placement="top-start">
                                                        <span>{skillTypeData?.skillTypeCode}</span>
                                                    </Tooltip>
                                                </div>
                                                <div>
                                                    {machineAttachment1Data !== null ? (
                                                        <Badge
                                                            className={imageLength > 1 ? `${classes.badge}` : ''}
                                                            invisible={imageLength < 2}
                                                            badgeContent={imageLength}
                                                            color="primary"
                                                        >
                                                            <div
                                                                onClick={() => {
                                                                    getImageArray(row);
                                                                    handleImageOpen();
                                                                }}
                                                            >
                                                                <img
                                                                    className={classes.styleImageBG}
                                                                    src={Utils.CommonFunctions.getImageWithOperationCode(
                                                                        row.machineAttachment1Data.attachmentCode,
                                                                        row.machineAttachment1Data.image1,
                                                                    )}
                                                                    alt={''}
                                                                />
                                                            </div>
                                                        </Badge>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                                <div>{Utils.CommonFunctions.maxDecimalValue(frequency, 4)}</div>
                                                <div>
                                                    <Tooltip title={macSubTypeData?.subType} placement="top-start">
                                                        <span>{macSubTypeData?.subType}</span>
                                                    </Tooltip>
                                                </div>
                                                <div>
                                                    <Tooltip title={needleTypeData?.needleType} placement="top-start">
                                                        <span>{needleTypeData?.needleType}</span>
                                                    </Tooltip>
                                                </div>
                                                <div>{smv}</div>
                                                <div>
                                                    {tct
                                                        ? minuteToTime(tct)
                                                        : '-'}
                                                </div>
                                                <div className={classes.overflowVisible}>
                                                    <span
                                                        onClick={() => {
                                                            handleOpenAddOperation();
                                                            dispatch({
                                                                type:
                                                                    Utils.ActionName
                                                                        .UPDATE_SEWING_OPERATION_FOR_ADD_STYLE,
                                                                payload: {
                                                                    selectedOperationStep: index + 2,
                                                                },
                                                            });
                                                        }}
                                                        style={isSameUser ? {} : { display: 'none' }}
                                                    >
                                                        <AddIcon />
                                                    </span>
                                                    <span
                                                        style={isSameUser ? {} : { display: 'none' }}
                                                        onClick={() => {
                                                            handleOpenEditOperation();
                                                            dispatch({
                                                                type:
                                                                    Utils.ActionName
                                                                        .UPDATE_SEWING_OPERATION_FOR_ADD_STYLE,
                                                                payload: {
                                                                    selectedOperationcode: operation,
                                                                    selectedDescription: desc1,
                                                                    selectedFrequency: frequency,
                                                                    selectedMachine: macSubTypeId,
                                                                    selectedNeedleType: needleTypeId,
                                                                    selectedSmv: smv,
                                                                    selectedTct: tct,
                                                                    selectedMachineName: macSubTypeData.subType,
                                                                    selectedNeedleTypeName: needleTypeData.needleType,
                                                                    selectedOperatorId: id,
                                                                    selectedOperationCodeId: operationId,
                                                                    selectedOperation: sewingData[index],
                                                                },
                                                            });
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </span>
                                                    <span
                                                        style={
                                                            sewingData.length > 1 && isSameUser
                                                                ? {}
                                                                : { display: 'none' }
                                                        }
                                                        onClick={() => {
                                                            handleOpenDeleteOperation();
                                                            deleteOperationState(id, styleId);
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <SimpleModal
                handleOpen={handleOpenAddOperation}
                isOpen={openAddOperation}
                body={<AddOperation handleClose={handleCloseAddOperation} />}
            />
            <SimpleModal
                handleOpen={handleOpenEditOperation}
                isOpen={openEditOperation}
                body={<EditOperation handleClose={handleCloseEditOperation} />}
            />

            <SimpleModal
                handleOpen={handleCloseDeleteOperation}
                isOpen={openDeleteOperation}
                body={
                    <DeleteModal
                        title={t('DeleteOperation')}
                        handleClose={handleCloseDeleteOperation}
                        onDelete={() => {
                            dispatch(deleteOperation(operationIdToDelete, styleIdOfOperationToDelete, sewingData));
                        }}
                    />
                }
            />
            <SimpleModal
                isOpen={openImage}
                body={
                    <ImageCaurosel handleClose={handleImageClose} items={images} imgToOpen={0} alt={t('Attachment')} />
                }
            />
        </React.Fragment>
    );
}
