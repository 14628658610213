import axios from 'axios';
import moment from 'moment';
import Utils from '../../utils';
import { timeToMinute } from '../../utils/convertTime';
import { getUserIdToken } from '../../utils/session';

export const getOperator = () => {
    return (dispatch: Function, getState: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }

        dispatch({
            type: Utils.ActionName.UPDATE_SKILL_LIST,
            payload: {
                skillData: [],
                page: 1,
            },
        });
        const {
            page,
            limit,
            searchOperator,
            sortBy,
            order,
            filterLineIds,
            filterShiftIds,
            filterStatus,
        } = getState().operatorReducer;

        let parameters = `?page=${page}&limit=${limit}${order.length > 0 ? `&order=${order}&sortBy=${sortBy}` : ''}${
            searchOperator.length > 0 ? `&search=${searchOperator}` : ''
        }${filterLineIds.length > 0 ? `&lineId=${filterLineIds.toString()}` : ''}${
            filterShiftIds.length > 0 ? `&shiftId=${filterShiftIds.toString()}` : ''
        }${filterStatus.length === 1 ? `&status=${filterStatus[0]}` : ''} `;

        Utils.api.getApiCall(
            Utils.endPoints.operatorList,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.UPDATE_OPERATOR_LIST,
                        payload: {
                            operatorData: innerData.data,
                            page: innerData.page,
                            count: innerData.count,
                            limit: innerData.limit,
                        },
                    });

                    if (innerData.data[0]) {
                        dispatch({
                            type: Utils.ActionName.UPDATE_SKILL_LIST,
                            payload: {
                                operatorId: innerData.data[0].id,
                            },
                        });
                        dispatch({
                            type: Utils.ActionName.UPDATE_OPERATOR_LIST,
                            payload: {
                                selectedLine: innerData.data[0].mfgLineData.mfgLine,
                                selectedOperatorName: innerData.data[0].operatorName,
                                selectedCardId: innerData.data[0].cardId,
                                selectedEmployeeId: innerData.data[0].operatorId,
                                selectedisActive: innerData.data[0].isActive,
                                operatorImage: innerData.data[0].operatorImage,
                            },
                        });
                        dispatch(getSkill(innerData.data[0].id));
                    } else {
                        dispatch({
                            type: Utils.ActionName.UPDATE_SKILL_LIST,
                            payload: {
                                skillData: [],
                                operatorId: null,
                            },
                        });
                    }

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getSkill = (operatorId: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');

            return;
        }

        let parameters = `?operatorId=${operatorId}`;
        Utils.api.getApiCall(
            Utils.endPoints.skillList,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;

                    dispatch({
                        type: Utils.ActionName.UPDATE_SKILL_LIST,
                        payload: {
                            skillData: innerData.data,
                            page: innerData.page,
                            isOperatorChange: false,
                        },
                    });
                } else {
                    Utils.showAlert(2, data.message);
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
            },
        );
    };
};

export const addOperator = (values: any, setSubmitting: any, history: any, handleClose: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            setSubmitting(false);
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { lineId, line, shiftId, shift, operatorName, cardId, isActive, employeeId, description } = values;

        const dataToSend = {
            lineId,
            line,
            shiftId,
            shift,
            operatorName,
            cardId,
            isActive,
            operatorId: employeeId,
            description,
        };
        Utils.api.postApiCall(
            Utils.endPoints.operatorList,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    setSubmitting(false);
                    handleClose();
                    dispatch(getOperator());
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                setSubmitting(false);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getLinesSetup = () => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = '';

        Utils.api.getApiCall(
            Utils.endPoints.lineSetup,
            parameters,
            (respData: any) => {
                let { data } = respData;

                // For users data
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.GET_LINES_SETUP,
                        payload: { lines: data.data.data },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getShiftsList = () => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = '';

        Utils.api.getApiCall(
            Utils.endPoints.shifts,
            parameters,
            (respData: any) => {
                let { data } = respData;

                // For users data
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    let shifts = data.data.data;

                    let curTime = moment().format('HH:mm:ss');
                    let currentShift = {};
                    shifts.map((shift: any) => {
                        if (shift.startTime > shift.endTime) {
                            if (
                                (curTime >= shift.startTime && curTime <= '23:59:59') ||
                                (curTime >= '00:00:00' && curTime < shift.endTime)
                            ) {
                                currentShift = shift;
                            }
                        } else {
                            if (curTime >= shift.startTime && curTime < shift.endTime) {
                                currentShift = shift;
                            }
                        }
                    });
                    dispatch({
                        type: Utils.ActionName.GET_SHIFTS_LIST,
                        payload: { shifts: data.data.data, currentActiveShift: currentShift },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const validateOperatorBulkUpload = (
    operators: any,
    setFirstStepLoader: any,
    handleNext: any,
    errorCallBack: Function,
) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        var dataUpload = new FormData();
        dataUpload.append('operators', operators);

        const dataToSend = dataUpload;
        Utils.api.postApiCall(
            Utils.endPoints.validateSkills,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.UPDATE_SKILL_LIST,
                        payload: {
                            totalRecord: data.data.totalRecords,
                            duplicateRecord: data.data.duplicateRecords,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    Utils.showAlert(1, data.message);
                    errorCallBack('');
                    setFirstStepLoader(false);
                    handleNext();
                } else {
                    errorCallBack(data.message);
                    setFirstStepLoader(false);
                }
            },
            (error: any) => {
                let { data } = error;
                errorCallBack(data.message);
                setFirstStepLoader(false);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const operatorBulkUpload = (setSecondStepLoader: any, handleNext: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const { wholeFile } = getState().skillReducer;

        var dataUpload = new FormData();
        dataUpload.append('operators', wholeFile);
        setSecondStepLoader(true);
        const dataToSend = dataUpload;
        Utils.api.postApiCall(
            Utils.endPoints.bulkUpload,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.UPDATE_SKILL_LIST,
                        payload: {
                            totalRecord: data.data.totalRecords,
                            duplicateRecord: data.data.duplicateRecords,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    Utils.showAlert(1, data.message);
                    setSecondStepLoader(false);
                    handleNext();
                } else {
                    Utils.showAlert(2, data.message);
                    setSecondStepLoader(false);
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                setSecondStepLoader(false);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const validateOperationBulkUpload = (
    operations: any,
    setFirstStepLoader: any,
    handleNext: any,
    firstLang: any,
    secondLang: any,
    errorCallBack: Function,
) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        var dataUpload = new FormData();
        dataUpload.append('operations', operations);

        const dataToSend = dataUpload;
        const d = new Date();
        const offsetValue = -1 * d.getTimezoneOffset();
        
        Utils.api.postApiCallWithHeader(
            Utils.endPoints.validateBulkUploadOperation,
            dataToSend,
            {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getUserIdToken()}`,
                offsetValue: offsetValue,
                lang1: firstLang,
                lang2: secondLang,
            },
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.UPDATE_SKILL_LIST,
                        payload: {
                            totalRecord: data.data.totalRecords,
                            duplicateRecord: data.data.duplicateRecords,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    Utils.showAlert(1, data.message);
                    errorCallBack('');
                    setFirstStepLoader(false);
                    handleNext();
                } else {
                    errorCallBack(data.message);
                    setFirstStepLoader(false);
                }
            },
            (error: any) => {
                let { data } = error;
                errorCallBack(data.message);
                setFirstStepLoader(false);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const bulkUploadOperation = (setSecondStepLoader: any, handleNext: any, firstLang: any, secondLang: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const { wholeFile } = getState().skillReducer;

        var dataUpload = new FormData();
        dataUpload.append('operations', wholeFile);
        setSecondStepLoader(true);
        const dataToSend = dataUpload;
        const d = new Date();
        const offsetValue = -1 * d.getTimezoneOffset();
        
        Utils.api.postApiCallWithHeader(
            Utils.endPoints.bulkUploadOperation,
            dataToSend,
            {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getUserIdToken()}`,
                offsetValue: offsetValue,
                lang1: firstLang,
                lang2: secondLang,
            },
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.UPDATE_SKILL_LIST,
                        payload: {
                            totalRecord: data.data.totalRecords,
                            duplicateRecord: data.data.duplicateRecords,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch(getOperationCode());
                    Utils.showAlert(1, data.message);
                    setSecondStepLoader(false);
                    handleNext();
                } else {
                    Utils.showAlert(2, data.message);
                    setSecondStepLoader(false);
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                setSecondStepLoader(false);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const deleteOperator = (id: any, handleCloseEdit: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const dataToSend = '';
        Utils.api.deleteApiCall(
            `${Utils.endPoints.operatorList}?id=${id}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch(getOperator());
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    handleCloseEdit();
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const editOperator = (values: any, setSubmitting: any, history: any, handleClose: any, selectedLine: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const {
            lineId,
            line,
            shiftId,
            shift,
            operatorName,
            cardId,
            isActive,
            employeeId,
            description,
            operatorId,
        } = values;

        const isActiveBoolean = isActive === 'true' ? true : false;

        const dataToSend = {
            lineId: Number(lineId),
            line,
            shiftId,
            shift,
            operatorName,
            cardId,
            operatorId: `${employeeId}`,
            isActive: isActiveBoolean,
            description,
        };

        Utils.api.putApiCall(
            `${Utils.endPoints.operatorList}?id=${operatorId}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    setSubmitting(false);
                    handleClose();
                    dispatch(getOperator());
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                setSubmitting(false);
                let { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const editSkill = (values: any, setSubmitting: any, history: any, handleClose: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { operatorId, operation, macSubTypeId, eff } = values;
        const dataToSend = {
            operatorId,
            operation,
            macSubTypeId,
            eff,
        };
        Utils.api.putApiCall(
            `${Utils.endPoints.editSkill}?id=${values.id}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    setSubmitting(false);
                    handleClose();
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch(getSkill(operatorId));
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                setSubmitting(false);
                let { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const deleteSkill = (id: any, skillData: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const dataToSend = `?id=${id}`;
        Utils.api.deleteApiCall(
            Utils.endPoints.skillRemove,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);

                    const skillDataDeepCopy = JSON.parse(JSON.stringify(skillData));
                    const index = skillDataDeepCopy.findIndex((data: any) => data.id === id);
                    if (index > -1) {
                        skillDataDeepCopy.splice(index, 1);
                    }
                    dispatch({
                        type: Utils.ActionName.UPDATE_SKILL_LIST,
                        payload: {
                            skillData: skillDataDeepCopy,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getOperationCode = (limitManual?: any, searchString = null) => {
    return (dispatch: Function, getState: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const {
            page,
            limit,
            sortBy,
            order,
            filterFactoryIds,
            filterStatus,
            filterSkillIds,
            searchOperation,
        } = getState().operationCodeReducer;

        let parameters = '';
        parameters = `?page=${page}&limit=${limit}${order.length > 0 ? `&order=${order}&sortBy=${sortBy}` : ''}${
            searchOperation.length > 0 ? `&search=${searchOperation}` : ''
        }${filterFactoryIds.length > 0 ? `&factoryId=${filterFactoryIds.toString()}` : ''}${
            filterStatus.length === 1 ? `&isActive=${filterStatus[0]}` : ''
        }${filterSkillIds.length > 0 ? `&skillTypeId=${filterSkillIds.toString()}` : ''}`;

        if (limitManual) {
            parameters = `?limit=${limitManual}&page=1`;
        }

        if(searchString) {
            parameters = `${parameters}&search=${searchString}`;
        }
        
        Utils.api.getApiCall(
            Utils.endPoints.operationCode,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.UPDATE_OPERRATION_CODE,
                        payload: {
                            operationCodeData: innerData.data,
                            page: innerData.page,
                            count: innerData.count,
                        },
                    });

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getPpaCode = () => {
    return (dispatch: Function, getState: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const { pagePpa, limitPpa, sortBy, order, isInActiveMDM, isActiveMDM } = getState().ppaCodeReducer;
        const { searchOperation } = getState().operationCodeReducer;

        let parameters = `?page=${pagePpa}&limit=${limitPpa}${
            order.length > 0 ? `&order=${order}&sortBy=${sortBy}` : ''
        }${searchOperation.length > 0 ? `&search=${searchOperation}` : ''}${
            isInActiveMDM && isActiveMDM ? `` : isActiveMDM ? `&isActive=true` : isInActiveMDM ? `&isActive=false` : ``
        }`;

        Utils.api.getApiCall(
            Utils.endPoints.ppaCode,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.UPDATE_PPA_CODE,
                        payload: {
                            ppaCodeData: innerData.data,
                            pagePpa: innerData.page,
                            countPpa: innerData.count,
                        },
                    });

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const addOperationCode = (values: any, setSubmitting: any, handleClose: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            setSubmitting(false);
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { firstLang, secondLang } = getState().globalLangDataReducer;

        const {
            operationCode,
            smv,
            tct,
            description,
            displayName,
            skillTypeId,
            skillTypeName,
            firstLangData,
            secondLangData,
            skillTypeCode,
            firstLangIsGoogle,
            secondLangIsGoogle,
            machineCodes,
        } = values;

        const dataToSend: any = {
            code: operationCode,
            smv,
            tct: timeToMinute(tct),
            desc1: description,
            displayName,
            skillTypeId,
            skillTypeName,
            skillTypeCode,
            firstLang,
            secondLang,
            firstLangData,
            secondLangData,
            firstLangIsGoogle,
            secondLangIsGoogle,
        };

        for (let i = 0; i < machineCodes.length && i < 3; i++) {
            dataToSend[`machineAttachmentId${i + 1}`] = machineCodes[i].id;
            dataToSend[`machineAttachmentCode${i + 1}`] = machineCodes[i].attachmentCode;
            dataToSend[`machineAttachmentImage${i + 1}`] = machineCodes[i].image1;
        }

        for (let i = machineCodes.length; i < 3; i++) {
            dataToSend[`machineAttachmentId${i + 1}`] = null;
            dataToSend[`machineAttachmentCode${i + 1}`] = null;
            dataToSend[`machineAttachmentImage${i + 1}`] = null;
        }

        Utils.api.postApiCall(
            Utils.endPoints.operationCode,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    setSubmitting(false);

                    dispatch(getOperationCode());
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    handleClose();
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                setSubmitting(false);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const editOperatorCode = (values: any, setSubmitting: any, handleClose: any, id: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(1, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const { firstLang, secondLang } = getState().globalLangDataReducer;
        const {
            operationCode,
            smv,
            tct,
            description,
            isActive,
            displayName,
            skillTypeId,
            skillTypeName,
            firstLangData,
            secondLangData,
            skillTypeCode,
            firstLangIsGoogle,
            secondLangIsGoogle,
            machineCodes,
        } = values;

        const dataToSend: any = {
            code: operationCode,
            desc1: description,
            smv,
            tct: timeToMinute(tct),
            displayName,
            isActive: isActive === 'true' ? true : false,
            skillTypeId,
            skillTypeCode,
            skillTypeName,
            firstLang,
            secondLang,
            firstLangData,
            secondLangData,
            firstLangIsGoogle,
            secondLangIsGoogle,
        };
        for (let i = 0; i < machineCodes.length && i < 3; i++) {
            dataToSend[`machineAttachmentId${i + 1}`] = machineCodes[i].id;
            dataToSend[`machineAttachmentCode${i + 1}`] = machineCodes[i].attachmentCode;
            dataToSend[`machineAttachmentImage${i + 1}`] = machineCodes[i].image1;
        }

        for (let i = machineCodes.length; i < 3; i++) {
            dataToSend[`machineAttachmentId${i + 1}`] = null;
            dataToSend[`machineAttachmentCode${i + 1}`] = null;
            dataToSend[`machineAttachmentImage${i + 1}`] = null;
        }

        Utils.api.putApiCall(
            `${Utils.endPoints.operationCode}?id=${id}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    setSubmitting(false);
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch(getOperationCode());
                    handleClose();
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                setSubmitting(false);
                let { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getMfgLinesList = () => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = '';

        Utils.api.getApiCall(
            Utils.endPoints.mfgLines,
            parameters,
            (respData: any) => {
                let { data } = respData;

                // For users data
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.GET_MFG_LINES,
                        payload: { mfgLines: data.data.data },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getOperationCodeAll = () => {
    return (dispatch: Function, getState: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const { page, searchOperation, sortBy, order } = getState().operationCodeReducer;

        let parameters = `?page=${page}&limit=${10000}${order.length > 0 ? `&order=${order}&sortBy=${sortBy}` : ''}${
            searchOperation.length > 0 ? `&search=${searchOperation}` : ''
        } `;

        Utils.api.getApiCall(
            Utils.endPoints.operationCode,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.UPDATE_OPERRATION_CODE,
                        payload: {
                            operationCodeData: innerData.data,
                            page: innerData.page,
                            count: innerData.count,
                        },
                    });

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const downlodLatestOperatorListFile = () => {
    return (dispatch: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }

        let parameters = '';

        Utils.api.getApiCall(
            Utils.endPoints.downloadLatestOperators,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const blob = new Blob([data.data], { type: 'application/octet-stream' });
                    const url = window.URL.createObjectURL(blob);
                    window.open(url);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const handleDownloadOperators = async () => {
    let date = moment().format('YYYY-MM-DD');

    const d = new Date();
    const offsetValue = -1 * d.getTimezoneOffset();

    const req = await axios({
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getUserIdToken()}`,
            offsetValue: offsetValue,
        },
        method: 'get',
        url: Utils.constants.apiUrl + `user/operator/download-operators`,
        responseType: 'blob',
    });
    var blob = new Blob([req.data], {
        type: req.headers['content-type'],
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `latestOperators_${date}.xlsx`;
    link.click();
};

export const handleDownloadOperatorsTemplate = async () => {
    const d = new Date();
    const offsetValue = -1 * d.getTimezoneOffset();

    const req = await axios({
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getUserIdToken()}`,
            offsetValue: offsetValue,
        },
        method: 'get',
        url: Utils.constants.apiUrl + `user/operator/download-operators-template-file`,
        responseType: 'blob',
    });
    var blob = new Blob([req.data], {
        type: req.headers['content-type'],
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `Operators_Template.xlsx`;
    link.click();
};

export const handleDownloadMasterOperationsCode = async (firstLang: any, secondLang: any) => {
    let date = moment().format('YYYY-MM-DD');

    const d = new Date();
    const offsetValue = -1 * d.getTimezoneOffset();

    const req = await axios({
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getUserIdToken()}`,
            offsetValue: offsetValue,
            lang1: firstLang,
            lang2: secondLang,
        },
        method: 'get',
        url: Utils.constants.apiUrl + 'user/operation-master/download-operations',
        responseType: 'blob',
    });
    var blob = new Blob([req.data], {
        type: req.headers['content-type'],
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `latestOperationsCode_${date}.xlsx`;
    link.click();
};

export const handleDownloadMasterOperationsCodeTemplate = async (firstLang: any, secondLang: any) => {
    const d = new Date();
    const offsetValue = -1 * d.getTimezoneOffset();

    const req = await axios({
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getUserIdToken()}`,
            offsetValue: offsetValue,
            lang1: firstLang,
            lang2: secondLang,
        },
        method: 'get',
        url: Utils.constants.apiUrl + 'user/operation-master/download-operations-template-file',
        responseType: 'blob',
    });
    var blob = new Blob([req.data], {
        type: req.headers['content-type'],
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `Operations_Code_Template.xlsx`;
    link.click();
};

export const editOperatorImage = (image: any, operatorId: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const dataToSend = {
            operatorImage: image,
        };

        Utils.api.putApiCall(
            `${Utils.endPoints.changeOperatorImage}?id=${operatorId}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.UPDATE_SKILL_LIST,
                        payload: {
                            page: 1,
                        },
                    });
                    dispatch(getOperator());
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getFactoriesList = () => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = '';

        Utils.api.getApiCall(
            Utils.endPoints.factoriesList,
            parameters,
            (respData: any) => {
                let { data } = respData;
                // For users data
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.GET_FACTORIES_LIST,
                        payload: {
                            factoriesData: data.data.data,
                            limit: data.data.limit,
                            count: data.data.count,
                            page: data.data.page,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const deleteOperationCode = (id: any, handleCloseEdit: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const dataToSend = '';
        Utils.api.deleteApiCall(
            `${Utils.endPoints.operationCode}/${id}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch(getOperationCode());
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    handleCloseEdit();
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getTimestudyOperator = (id: number, page: number = 1) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { 
            timeStudyOperatorData: currentData,
            filterSkillIds, 
            filterMachineIds, 
            search,
        } = getState().timeStudyOperatorReducer;
        let parameters = `?page=${page}&limit=1000&skillTypeId=${filterSkillIds.toString()}&macId=${filterMachineIds.toString()}${
            search.length > 0 ? `&search=${search}` : ''
        }`;

        Utils.api.getApiCall(
            `${Utils.endPoints.timeStudyOperatorData}/${id}`,
            parameters,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    const timeStudyOperatorData = page > 1
                        ? [...currentData, ...innerData.data]
                        : innerData.data;

                    const payload = {
                        count: innerData.count,
                        page: innerData.page,
                        limit: innerData.limit,
                        timeStudyOperatorData,
                    };

                    dispatch({
                        type: Utils.ActionName.GET_TIMESTUDY_OPERATOR_LIST,
                        payload,
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const addSkill = (values: any, setSubmitting: any, history: any, handleClose: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            setSubmitting(false);
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { operatorId, operation, macSubTypeId, eff, time } = values;

        const dataToSend = {
            operatorId,
            operationId: operation,
            macId: macSubTypeId,
            eff,
            time: time === '' ? ['0'] : [time],
        };
        Utils.api.postApiCall(
            Utils.endPoints.timeStudyAdd,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    setSubmitting(false);
                    handleClose();
                    dispatch(getTimestudyOperator(operatorId));
                    dispatch(getSkill(operatorId));
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                setSubmitting(false);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const deleteTimeStudy = (id: any, operatorId: number) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const dataToSend = '';
        Utils.api.deleteApiCall(
            `${Utils.endPoints.timeStudyDelete}/${id}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch(getTimestudyOperator(operatorId));
                    dispatch(getSkill(operatorId));
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const addPpa = (values: any, setSubmitting: any, handleClose: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            setSubmitting(false);
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { ppaName, description, firstLang, secondLang, isActive } = values;
        const { firstLang: firstLanguage, secondLang: secondLanguage } = getState().globalLangDataReducer;

        const dataToSend = {
            name: ppaName,
            desc1: description,
            firstLang: firstLanguage,
            secondLang: secondLanguage,
            firstLangData: firstLang,
            secondLangData: secondLang,
            firstLangIsGoogle: false,
            secondLangIsGoogle: false,
            isActive,
        };
        Utils.api.postApiCall(
            Utils.endPoints.ppaCode,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    setSubmitting(false);

                    dispatch(getPpaCode());
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    handleClose();
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                setSubmitting(false);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const editPpa = (values: any, setSubmitting: any, handleClose: any, id: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const { ppaName, description, firstLang, secondLang, isActive } = values;
        const { firstLang: firstLanguage, secondLang: secondLanguage } = getState().globalLangDataReducer;

        const isActiveBoolean = isActive === 'true' ? true : false;

        const dataToSend = {
            name: ppaName,
            desc1: description,
            firstLang: firstLanguage,
            secondLang: secondLanguage,
            firstLangData: firstLang,
            secondLangData: secondLang,
            firstLangIsGoogle: false,
            secondLangIsGoogle: false,
            isActive: isActiveBoolean,
        };

        Utils.api.putApiCall(
            `${Utils.endPoints.ppaCode}?id=${id}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    setSubmitting(false);
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch(getPpaCode());
                    handleClose();
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                setSubmitting(false);
                let { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};
