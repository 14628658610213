import ajax from "./ajax";
import constant from "./constants";
import endPoints from "./endPoints";

/**
 * Function to order an array of data objects by an alphabetic field
 * 
 * @param {any[]} data - Array of data objects to be sorted
 * @param {string} key - The key representing the field to be used for sorting
 * @returns {any[]} - The sorted array of data objects
*/
export const orderingByAlphabetic = (data: any[], key: string): any[] => {
    const hasKey = data.every(obj => obj.hasOwnProperty(key));

    if(data.length > 0 && hasKey) {
        return data.sort((a: any, b:any) => (
            a[key].toLowerCase() < b[key].toLowerCase() 
                ? -1 
                : a[key].toLowerCase() > b[key].toLowerCase() 
                    ? 1 
                    : 0
        ));
    };
    return data;
};

export const getStationEff = async (
  operator: any,
  operation: any,
  targetEff: any,
) => {
  try {
    const { operationId, macSubTypeId, skillType } = operation;
    const { operatorskills: skills } = operator;
    const { eff, effTct } = skills.find((skill: any) =>
        skill?.skillTypeCode?.trim() === skillType?.trim() &&
        skill?.macSubTypeId === macSubTypeId &&
        skill?.operation === operationId
    );

    // If there is 0 tct value effTct also 0, so we will use eff. 
    return { 
      eff, 
      effTct: effTct || eff 
    };
  } catch (error) {
    return { eff: targetEff, effTct: targetEff };
  }
}

export const lbrCalculation = async (stations: any) => {
    try {
      const stationLength = stations.reduce((sum: any, { stationDivided }: any) => sum + stationDivided, 0);
      
      const totalSum = stations.reduce((sum: any, { operationCycleOperatorEff }: any) => sum + operationCycleOperatorEff, 0);
      const maxValue = Math.max(...stations.map(({ operationCycleOperatorEff }: any) => operationCycleOperatorEff));
      const lbrValue = totalSum / (stationLength * maxValue) * 100;

      const totalSumTct = stations.reduce((sum: any, { operationCycleOperatorEffTct }: any) => sum + operationCycleOperatorEffTct, 0);
      const maxValueTct = Math.max(...stations.map(({ operationCycleOperatorEffTct }: any) => operationCycleOperatorEffTct));
      const lbrValueTct = totalSumTct / (stationLength * maxValueTct) * 100;

      return {
        lbrValueTct,
        lbrValue,
      }
    } catch (error) {
      return {
        lbrValueTct: 0,
        lbrValue: 0,
      };
    }
}

export const normalizeStationForCalculationLbr = async (stations: any, infoData: any) => {
  const { targetEff } = infoData;
  return await Promise.all(stations.reduce((acc: any, cur: any, stationNumber: any) => {
    return acc.concat(
      cur.operations.map(async (operation: any) => {
        const { eff, effTct } = await getStationEff(cur.operator, operation, targetEff);
        const smv = operation.smv;
        const tct = operation.tct || operation.smv; // If there is 0 tct, we will use smv. 
        const smvOpCycle: any = (smv * 60);
        const smvOpCycleTct: any = (tct * 60);
        return {
          smv,
          smvOpCycle,
          tct,
          smvOpCycleTct,
          eff,
          effTct,
          operationCycleOperatorEff: ((smvOpCycle) / (eff / 100)),
          operationCycleOperatorEffTct: ((smvOpCycleTct) / (effTct / 100)),
          station: (stationNumber+1),
          stationDivided: cur.operations.length > 1 
            ? 1 / cur.operations.length 
            : 1,
          operatorId: cur.operator.id,
          operationId: operation.id,
        };
      })
    );
  }, []));
};

export const targetOutputCalculation = (
  workingMinutes: any, 
  manPower: any,
  totalSmv: any,
  targetEff: any,
) => {
  const result = ((workingMinutes * manPower / totalSmv) * (targetEff / 100)).toFixed(0);
  return result || 0;
}

export const runningDayCalculation = (
  shiftQuantity: any,
  targetOutput: any,
) => {
  const result = Math.round(shiftQuantity / targetOutput);
  return result || 0;
}

export const getProductSettings = async () => {
  try {
    const baseUrl = constant.apiUrl;
    const pathUrl = endPoints.productSetting;
    const { data: response } = await ajax(`${baseUrl}${pathUrl}`);
    const { data: productSettings } = response;
    localStorage.setItem('productSettings', JSON.stringify(productSettings));
    return productSettings;
  } catch (error) {
    return false;
  }
}

export const getValueTctOrSmv = async (data: any, fieldA: any, fieldB: any) => {
  try {
    let productSettings: any = localStorage.getItem('productSettings');
    productSettings = JSON.parse(productSettings) || await getProductSettings();
    const { skillmatrixmode } = productSettings;
    const result = skillmatrixmode.value === 1
      ? data[fieldA]
      : data[fieldB];

      return result || 0;
  } catch (error) {
    return data[fieldA] || 0;
  }
}

export const getOperatorEffInPool = async (
  operationId: any,
  operatorSkill: any,
  operatorMacSubType: any,
  skills: any,
) => {
  const matchedSOperatorSkill = skills.find(({ operationData, macSubTypeData }: any) => (
    operationData.id === operationId &&
    operationData.skillTypeCode === operatorSkill &&
    macSubTypeData.subType === operatorMacSubType
  ));
  return matchedSOperatorSkill
    ? await getValueTctOrSmv(matchedSOperatorSkill, 'eff', 'effTct')
    : 0
}

export const updateOperationCycleOperatorEffMax = (stations: any) => {
  return stations.reduce((acc: any, cur: any) => {
    //Condition, Filtering stations with same, stationNo, operator, operation.
    const filteredStations = stations.filter(
      (station: any) =>
        station.operatorId === cur.operatorId ||
        station.station === cur.station ||
        station.operationId === cur.operationId
    );
    //Sub Condition, Filtering stations with same operation.
    const operationsId = filteredStations.map(({ operationId }: any) => operationId);
    const sameOparations = filteredStations.filter(({ operationId }: any) => !operationsId.indexOf(operationId));
    //Then, Get the maximum of operationCycleOperatorEff
    const max = filteredStations.reduce((sum: any, cur: any) => sum + cur.operationCycleOperatorEff, 0);
    const maxTct = filteredStations.reduce((sum: any, cur: any) => sum + cur.operationCycleOperatorEffTct, 0);
    //Modify Operation Cycle Operator Eff
    return acc.concat({
      ...cur,
      operationCycleOperatorEff: max / sameOparations.length,
      operationCycleOperatorEffTct: maxTct / sameOparations.length
    });
  }, []);
};