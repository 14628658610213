export default {
    login: '/login',
    tokenDetails: 'user/tokenDetails',
    refreshToken: 'user/accessToken',
    season: 'user/season',
    customers: 'user/customers',
    styleList: 'user/style/',
    operationList: 'user/operation/',
    styleDetail: 'user/style/details',
    lineSetup: 'user/lineSetup',
    needleTypeList: 'user/needleType/list',
    machineSubtypeList: 'user/machines/macsubtypes',
    operatorList: 'user/operator/',
    shifts: 'user/shifts/list',
    skill: 'user/operator/add-skill',
    skillList: 'user/operator/operator-skill/',
    validateSkills: 'user/operator/validate-bulk-upload',
    bulkUpload: 'user/operator/bulk-upload',
    skillRemove: 'user/operator/remove-skill',
    operationCode: 'user/operation-master',
    reorderOperations: 'user/operation/operation-order',
    ppaCode: 'user/ppa-master/',
    editSkill: 'user/operator/edit-skill',
    ppaMaster: 'user/ppa-master/?page=1&limit=100&isActive=true',
    mfgLines: 'user/mfgLines/list',
    bulkUploadOperation: 'user/operation-master/bulk-upload',
    validateBulkUploadOperation: 'user/operation-master/validate-bulk-upload',
    editPPA: 'user/operation/ppa',
    factoriesList: 'user/factory/list',
    notFindValidation: 'user/lineSetup/checkValidity',
    lineSeupInfo: 'user/lineSetup/detail',
    operatorInPool: 'user/operator/inPool',
    stations: 'user/stations',
    saveAsStyle: 'user/style/save-as',
    deleteLineSetup: 'user/lineSetup/',
    downloadLatestOperators: 'user/operator/download-operators',
    downloadLatestOperations: 'user/operation-master/download-operations',
    searchForOperatorInPoolInOtherLine: 'user/operator/searchForOperatorInPoolInOtherLine',
    addOperatorInPool: 'user/operator/inPool',
    validateStyleBulkUpload: 'user/style/validate-bulk-upload',
    bulkUploadStyle: 'user/style/bulk-upload',
    operationListForLine: 'user/operation/stations',
    stationSave: 'user/stations/new',
    nptCategory: 'user/npt/category',
    validateBulkUploadNpt: 'user/npt/validate-bulk-upload',
    bulkUploadNpt: 'user/npt/bulk-upload',
    nptType: 'user/npt/type',
    getNptTypesByCategory: 'user/npt/categorybyId',
    lbaInfo: 'user/line-balance-assist/detail',
    dashboardLineOutput: 'user/web-dashboard/dashfeb/lineDetail',
    labTable: 'user/line-balance-assist',
    npt: 'user/npt/allNpts',
    addNpt: 'user/line-npt/web',
    editNpt: 'user/line-npt/edit/web',
    lineNpt: 'user/line-npt/',
    allNptTypes: 'user/npt/allTypes',
    changeOperatorImage: 'user/operator/operator-image',
    dashboardChart: 'user/web-dashboard/dashfeb',
    analyticsBuildings: 'user/webAnalytics/buildings',
    analyticsAreas: 'user/webAnalytics/areas',
    analyticsShifts: 'user/webAnalytics/shifts',
    analyticsFloors: 'user/webAnalytics/floors',
    analyticsLines: 'user/webAnalytics/lines',
    analyticsNPTs: 'user/webAnalytics/npts',
    analyticsStyles: 'user/webAnalytics/styles',
    analyticsOperators: 'user/webAnalytics/operators',
    analyticsTopOperatorNPT: 'user/webAnalytics/top-Operator-Npt-List',
    analyticsEfficiency: 'user/webAnalytics/efficiency-style-line',
    analyticsTemplate: 'user/webAnalytics/template',
    allActiveLines: 'user/lineSetup/allActiveLines',
    analyticsStats: 'user/webAnalytics/numericalStats',
    effOverTime: 'user/webAnalytics/efficiency-over-time',
    effOverLine: 'user/webAnalytics/efficiency-over-line',
    notification: 'user/notifications/list',
    userDetails: 'user/userDetails',
    nptgraphOverTime: 'user/webAnalytics/nptgraph-over-time',
    nptgraphOverPer: 'user/webAnalytics/nptgraph-over-percentage',
    exportTopNPT: 'user/webAnalytics/export-top-Npt-List',
    exportEffOverTime: 'user/webAnalytics/export-efficiency-over-time',
    exportEffOverLine: 'user/webAnalytics/export-efficiency-over-line',
    exportNPTOverTime: 'user/webAnalytics/export-nptgraph-over-time',
    exportNPTOverPercentage: 'user/webAnalytics/export-nptgraph-over-percentage',
    productSetting: 'user/target',
    needleTranslation: 'user/masterDataTranslations/needle/list',
    ppaTranslation: 'user/masterDataTranslations/ppa/list',
    machinesTranslation: 'user/masterDataTranslations/macsubtypes/list',
    stylesTranslation: 'user/masterDataTranslations/styles/list',
    nptCategoriesTranslation: 'user/masterDataTranslations/nptcategories/list',
    nptTypesTranslation: 'user/masterDataTranslations/npttypes/list',
    needleTranslationUpdate: 'user/masterDataTranslations/needle/list/Update',
    ppaTranslationUpdate: 'user/masterDataTranslations/ppa/list/Update',
    machinesTranslationUpdate: 'user/masterDataTranslations/macsubtypes/list/Update',
    stylesTranslationUpdate: 'user/masterDataTranslations/styles/list/Update',
    nptCategoriesTranslationUpdate: 'user/masterDataTranslations/nptcategories/list/Update',
    nptTypesTranslationUpdate: 'user/masterDataTranslations/npttypes/list/Update',
    productModule: 'productModule',
    appDrawer: 'app-drawer?app=ltie',
    userNameAdmin: 'me',
    userFactoryAdmin: 'user/factories',
    companyCodeLoginAdmin: 'companylogin',
    reportLba: 'user/line-balance-assist/data/pdf',
    notificationMarkRead: 'user/notifications/markRead',
    deleteNotification: 'user/notifications',
    hideNotification: 'user/notifications/markAsHide',
    factoryChange: '',
    productTypesTranslation: 'user/masterDataTranslations/productType/list',
    skillTypesTranslation: 'user/masterDataTranslations/skillType/list',
    operationCodeTranslation: 'user/masterDataTranslations/operationCode/list',
    machineAttachmentTranslation: 'user/masterDataTranslations/machineAttachment/list',
    needleMasterData: 'user/masterDataManagement/needle/list',
    skillTypeMasterData: 'user/masterDataManagement/skillType/list',
    productMasterData: 'user/masterDataManagement/product/list',
    machineMasterData: 'user/masterDataManagement/machineAttachment/list',
    addNeedleMasterData: 'user/masterDataManagement/needle',
    addSkillTypeMasterData: 'user/masterDataManagement/skillType',
    addProductMasterData: 'user/masterDataManagement/product',
    addMachineMasterData: 'user/masterDataManagement/machineAttachment',
    machineData: 'user/masterDataManagement/macsubtypes/list',
    addMachineData: 'user/masterDataManagement/macsubtypes',
    timeStudyOperatorData: 'user/timestudy/operator',
    timeStudyAdd: 'user/timestudy/web',
    timeStudyDelete: 'user/timestudy',
    productTypesTranslationUpdate: 'user/masterDataTranslations/productType/list/Update',
    skillTypesTranslationUpdate: 'user/masterDataTranslations/skillType/list/Update',
    machineAttachmentTranslationUpdate: 'user/masterDataTranslations/machineAttachment/list/Update',
    operationCodeTranslationUpdate: 'user/masterDataTranslations/operationCode/list/Update',
    lineSetupListGet: 'user/lineSetup/lsi/list',
    lineSetupListDelete: 'user/lineSetup/lsiById',
    lineSetupListStatus: 'user/lineSetup/lsi/status',
    fetchShift: 'user/lineSetup/uid/detail',
    saveAs: 'user/lineSetup/save-as',
    getEditShiftForm: 'user/lineSetup/detailshift',
    editShiftDataForm: 'user/lineSetup/editshift',
    lineStatus: 'user/lineSetup/status',
    toCorequest: 'user/lineSetup/to-corequest',
    cancelStyle: 'user/operation/cancel',
    imageUpload: 'user/s3/upload-file',
    imageDownload: 'user/s3/download-file',
    getUserName: 'user/tokenDetails',
    globalAdminImageUpload: 'ref/upload/image',
    lbrHistory: 'user/lbr/history',
    lbrHistoryRange: 'user/lbr/history/range',
    lbrHistoryGraph: 'user/lbr/history/web_graph',
    lbrHistoryRestoreDetail: 'user/lbr/history/detail',
    lbrHistoryRestore: 'user/lbr/history/restore',
};
