import * as React from 'react';
import { Grid, Box } from '@material-ui/core';
import {
    BoxRowHeader,
    LineBalance,
    OperatorInLine,
    Operations,
    LineBalanceState,
    InfoGrid,
    ShiftInfo,
    Header,
} from './components';
import {
    CustomBox,
    LBRRestoreContainer,
    LBRRestoreContainerOverlay,
    NumberStation,
} from './styles';
import ajax from '../../../../utils/ajax';
import constant from '../../../../utils/constants';
import endPoints from '../../../../utils/endPoints';
import { useHistory } from 'react-router-dom';
import { normalizeRows } from './helpers';
import { runningDayCalculation, targetOutputCalculation } from '../../../../utils/helpers';

const LBRRestore = () => {

    const [data, setData] = React.useState<any>(null);
    const [rows, setRows] = React.useState<any>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const searchParams = new URLSearchParams(window.location.search);
    const history = useHistory();
    const baseUrl = constant.apiUrl;
    const linesetupLbrId = searchParams.get("id");
    const versionNo = searchParams.get("versionNo");
    const lineSetupId = searchParams.get("lineSetupId");

    const fetchApi = async () => {
        try {
            const pathUrl = `${baseUrl}${endPoints.lbrHistoryRestoreDetail}`;
            const { data: response } = await ajax(`${pathUrl}?id=${linesetupLbrId}`);
            const { data } = response;
            const [{ details }] = data;
            const parsedDetail = JSON.parse(details);
            if (
                parsedDetail.avgTakTime &&
                parsedDetail.infoData &&
                parsedDetail.lineSetup &&
                parsedDetail.flagValue &&
                parsedDetail.infoData &&
                parsedDetail.shiftInfoData && 
                parsedDetail.totalSmv
            ) {
                setData(parsedDetail);
                normalizeRows(
                    parsedDetail.lineSetup, 
                    parsedDetail.flagValue, 
                    setRows,
                );
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleOnRestore = async () => {
        setIsLoading(true);
        try {
            const pathUrl = `${baseUrl}${endPoints.lbrHistoryRestore}`;
            const { status } = await ajax(pathUrl, {
                method: 'PUT',
                data: { lineSetupId, versionNo }
            });
            if (status === 200) {
                const { id, uid, currentShift } = data.infoData;
                history.push({
                    pathname: '/line-balancing-assist/line-balancing-info',
                    search: `?id=${uid}&currentShiftActive=${id}&currentShiftToBeActive=${currentShift.id}`,
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    React.useEffect(() => {
        fetchApi();
    }, []);

    if(!data) return null;

    const {
        flagValue,
        infoData,
        shiftInfoData,
        avgCycleTime,
        avgTakTime,
        lineSetup,
        totalSmv,
    } = data;
    
    const targetOutput = targetOutputCalculation(
        infoData.workingMinutes, 
        lineSetup.length,
        totalSmv,
        infoData.targetEff,
    );
    const runningDays = runningDayCalculation(infoData.shiftQuantity, targetOutput);

    return (
        <LBRRestoreContainer>
            <LBRRestoreContainerOverlay />
            <Header
                onRestore={handleOnRestore}
                isLoading={isLoading}
                versionNo={versionNo}
                infoData={infoData} />
            <Grid container style={{ gap: 5, flexWrap: 'nowrap' }}>
                <InfoGrid
                    manPower={lineSetup.length}
                    runningDays={runningDays}
                    infoData={infoData} />
                <ShiftInfo
                    targetOutput={targetOutput}
                    infoData={infoData}
                    shiftInfoData={shiftInfoData} />
            </Grid>
            <Box style={{ position: 'relative' }}>
                <BoxRowHeader />
                <Grid container style={{ gap: 10, flexWrap: 'nowrap' }}>
                    <Grid item xs={2}>
                        {rows?.map((item: any) => (
                            <CustomBox
                                background={'#fff'}
                                height={item.height}>
                                <NumberStation>{item.station}</NumberStation>
                            </CustomBox>
                        ))}
                    </Grid>
                    <Operations rows={rows} />
                    <OperatorInLine rows={rows} />
                    <LineBalanceState rows={rows} avgCycleTime={avgCycleTime} />
                </Grid>
                <LineBalance
                    avgTakTime={avgTakTime}
                    avgCycleTime={avgCycleTime}
                    flag={(flagValue)} />
            </Box>
        </LBRRestoreContainer>
    )
}

export default LBRRestore;