import * as React from 'react';
import {
    TableRow,
    TableCell,
    Typography,
    CircularProgress,
} from '@material-ui/core';

interface RowStatusProps {
    rowLength: number,
    columnLength: number,
    isLoading: boolean,
}
 
const RowStatus: React.FC<RowStatusProps> = ({ 
    rowLength = 10, 
    columnLength, 
    isLoading,
}) => {

    if(rowLength > 0 && !isLoading) return null;
    return ( 
        <TableRow>
            <TableCell 
                align='center' 
                colSpan={columnLength + 1} 
                style={{ 
                    position: 'absolute', 
                    inset: 0, 
                    background: '#f2f2f2e3', 
                    zIndex: 99,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                }}>
                { isLoading 
                        ? <CircularProgress size={32} style={{ padding: 20 }} />
                        : rowLength === 0
                            ? <Typography style={{ padding: 20 }} variant='h6'>
                                {'No record found'}  
                              </Typography>
                            : ''
                }
            </TableCell>
        </TableRow>
    );
}
 
export default RowStatus;