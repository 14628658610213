import React from 'react';
import { Box } from '@material-ui/core';
import {
  VictoryChart,
  VictoryAxis,
  VictoryBar,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import { 
  LBHistoryLables, 
  LBRVictoryCustomTooltip,
} from './index';
import { 
  LBRCustomNextButton, 
  LBRCustomPreviousButton, 
  styles,
} from './../styles';
import { LBRVictoryChartProp } from './../Interfaces';
import { 
  handleAllBarPointMutation, 
  handleAxisLabelsStyle, 
  handleDisplayTick, 
  handleSingleBarPointMutation,
} from '../helpers';

const LBRVictoryChart: React.FC<LBRVictoryChartProp> = React.forwardRef(({ 
  data, 
  isLoading, 
  viewByBarColor, 
  viewByLabel,
  viewByKey,
  onSelectedBar,
  selectedLabels,
}, ref) => {
  const [pointXOptions, setPointXOptions] = React.useState({
    startPoint: 1,
    endPoint: 1,
    sizePoint: 31,
    dataCount: 0,
    showPrevNav: false,
    showNextNav: false,
  });
  const [barColor, setBarColor] = React.useState<any>('');
  const [externalEventMutations, setExternalEventMutations] = React.useState<any>(undefined);
  const chartWidth = 1400;
  const chartHeight = 275;
  const disabledColor = '#C4C4C4';

  const handleZoomInOut = (type = 'zoomIn') => {
    const pointZooming = 5;
    const { startPoint, endPoint, sizePoint, dataCount } = pointXOptions;
    const newStartPoint = type === 'zoomIn'
      ? Math.min(startPoint + pointZooming, startPoint)
      : Math.max(startPoint - pointZooming, startPoint);
    const newEndPoint = type === 'zoomIn'
      ? Math.min(endPoint + pointZooming, dataCount)
      : Math.max(endPoint - pointZooming, 31);
    const newSidePoint = type === 'zoomIn'
      ? Math.min(sizePoint + pointZooming, dataCount)
      : Math.max(sizePoint - pointZooming, 31)
    if(type === 'zoomOut' && newEndPoint - newStartPoint < 30) 
      return false;
    if(type === 'zoomIn' && endPoint === dataCount) 
      return false;
    setPointXOptions({
      ...pointXOptions,
      startPoint: newStartPoint,
      endPoint: newEndPoint,
      sizePoint: newSidePoint,
      showPrevNav: newStartPoint > 1,
      showNextNav: newEndPoint < dataCount,
    });
  };

  const handleNavigationZoomDomainChange = (direction: string) => {
    const pointMoving = 5;
    const { startPoint, endPoint, sizePoint, dataCount } = pointXOptions;
    const newStartPoint = direction === 'next' 
      ? Math.min(startPoint + pointMoving, dataCount)
      : Math.max(startPoint - pointMoving, 1);

    const newEndPoint = direction === 'next' 
      ? Math.min(endPoint + pointMoving, dataCount + sizePoint)
      : Math.max(endPoint - pointMoving, sizePoint);

    setPointXOptions({
      ...pointXOptions,
      startPoint: newStartPoint,
      endPoint: newEndPoint,
      showPrevNav: newStartPoint > 1,
      showNextNav: newEndPoint < dataCount,
    });
  }

  const tooltipComponent = () => {
    if(data ===  null) return;
    return (
      <VictoryTooltip
        flyoutComponent={
          <LBRVictoryCustomTooltip  
            endPoint={pointXOptions.endPoint} 
            data={data} 
          />
        }
        style={{ fill: "transparent" }}
      />
    )
  };

  const handleExternalEventMutations = React.useCallback(() => {
    setExternalEventMutations([
        {
          childName: "bar-viewby",
          eventKey: 'all',
          target: ["data"],
          mutation: (props: any) => 
          handleAllBarPointMutation(
              props, 
              selectedLabels,
              data?.axisBottom, 
              viewByBarColor, 
              disabledColor,
          ),
          callback: () => setExternalEventMutations(undefined),
        },
     ]);
  }, [selectedLabels]);

  React.useEffect(() => handleExternalEventMutations(), [handleExternalEventMutations])
  
  React.useEffect(() => {
    if (data && data.axisBottom) {
      const dataCount = data.axisBottom.length;
      const conditionNavigation = (dataCount > pointXOptions.sizePoint);
      setPointXOptions(conditionNavigation
        ? { ...pointXOptions, startPoint: 1, endPoint: pointXOptions.sizePoint, dataCount, showNextNav: true, showPrevNav: false }
        : { ...pointXOptions, startPoint: 1, endPoint: dataCount, dataCount, showNextNav: false, showPrevNav: false }
      );
      setBarColor(viewByBarColor);
      onSelectedBar([]);
      handleExternalEventMutations();
    }
  }, [data]);

  React.useImperativeHandle(ref, () => ({
    handleZoomInOut,
  }));

  let maxViewBy = data ? Math.max(...data?.[viewByKey || 'mpwr'].map((d: any) => d.y)) : 0;
  let maxLBREff = data ? Math.max(...[...data?.lbr, ...data?.eff].map((d: any) => d.y)) : 0;
  maxViewBy = maxViewBy + (maxViewBy * 10 / 100);
  maxLBREff = maxLBREff + (maxLBREff * 10 / 100);

  return (
    <Box position='relative'>
      <LBRCustomPreviousButton disabled={!pointXOptions.showPrevNav || isLoading} onClick={() => handleNavigationZoomDomainChange('previous')} />
      <LBRCustomNextButton disabled={!pointXOptions.showNextNav || isLoading} onClick={() => handleNavigationZoomDomainChange('next')} />
      <Box position='relative'>
        <Box>
          <VictoryChart
            externalEventMutations={externalEventMutations}
            events={[{
              childName: "bar-viewby",
              target: "data",
              eventHandlers: {
                onClick: () => {
                  return [
                    {
                      childName: "bar-viewby",
                      mutation: (props: any) => handleSingleBarPointMutation(
                        props,
                        data?.axisBottom,
                        selectedLabels,
                        onSelectedBar,
                      ),
                    },
                  ];
                },
              },
            }]}
            domainPadding={{ x: [30, 30], y: 20 }}
            height={chartHeight}
            width={chartWidth}
            padding={{ top: 40, bottom: 40, left: 100, right: 90 }}
            domain={{ x: [pointXOptions.startPoint, pointXOptions.endPoint] }}
            containerComponent={
              <VictoryVoronoiContainer voronoiDimension="x"
                labels={({ datum, index }) => '.'}
                labelComponent={tooltipComponent()}
              />
            }
          >
            <LBHistoryLables LeftLabel='LBR / EFFS' RightLabel={viewByLabel} />
            <VictoryAxis
              dependentAxis
              style={styles.AxisVertical}
              orientation='left'
              tickValues={[0.25, 0.5, 0.75, 1]}
              tickFormat={(t) => t.toString().includes('e') ? '0%' : `${Math.round(t * maxLBREff)}%`}
            />
            <VictoryAxis
              dependentAxis
              style={styles.AxisViewBy}
              orientation='right'
              tickValues={[0.25, 0.5, 0.75, 1]}
              tickFormat={(tick: any) => handleDisplayTick(Math.round(tick * maxViewBy))}
            />
            <VictoryBar
              name="bar-viewby"
              standalone={true}
              style={{ data: { fill: barColor, width: 30 } }}
              data={data?.[viewByKey || 'mpwr']}
              y={(datum) => datum.y / maxViewBy}
            />
            <VictoryLine
              interpolation='natural'
              style={styles.VictoryLineLBR}
              standalone={false}
              data={data?.lbr}
              y={(datum) => datum.y / maxLBREff}
              labels={({ datum, index }) => ''}
            />
            <VictoryScatter
              style={styles.VictoryScatterLBR}
              size={4}
              data={data?.lbr}
              y={(datum) => datum.y / maxLBREff}
              labels={({ datum, index }) => ''}
            />
            <VictoryLine
              interpolation='natural'
              style={styles.VictoryLineEFF}
              standalone={false}
              data={data?.eff}
              y={(datum) => datum.y / maxLBREff}
              labels={({ datum, index }) => ''}
            />
            <VictoryScatter
              style={styles.VictoryScatterEFF}
              size={4}
              data={data?.eff}
              y={(datum) => datum.y / maxLBREff}
              labels={({ datum, index }) => ''}
            />
            <VictoryAxis
              name='line-axisBottom'
              style={{
                ...styles.AxisHorizontal,
                ...({tickLabels: handleAxisLabelsStyle(pointXOptions.sizePoint, selectedLabels)}),
              }}
              orientation='bottom'
              standalone={true}
              tickValues={data?.axisBottom}
            />
          </VictoryChart>
        </Box>
      </Box>
    </Box>
  );  
});

export default LBRVictoryChart;