import * as React from 'react';
import {
    Box,
    Divider,
    FormControl,
    Grid,
    InputAdornment,
    OutlinedInput,
    Typography,
} from "@material-ui/core";
import { formatDate } from '../../LBRHistory/helpers';
import {
    FileCopyRounded,
    Autorenew,
} from '@material-ui/icons';
import { ButtonRestore } from '../styles';
  
export default ({ 
    infoData, 
    versionNo, 
    onRestore, 
    isLoading,
}: any) => {

    const currentURL = window.location.href;
    const format = 'yyyy-MM-DD';

    return (
        <Box style={{ background: '#fff', padding: 10, marginBottom: 10, }}>
            <Grid container>
                <Grid item xs={5} style={{ zIndex: 3 }}>
                    <Box style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                        <Typography variant='h5'>Version No: {versionNo}</Typography>
                        <Divider variant="fullWidth" flexItem orientation="vertical" />
                        <ButtonRestore
                            onClick={onRestore}
                            color="primary"
                            variant="contained"
                            startIcon={
                                <Autorenew 
                                    className={isLoading && 'loading-sync'} 
                                    style={{ fontSize: 24 }} 
                                />}
                        >
                            RESTORE
                        </ButtonRestore>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                <Box display={'flex'} 
                        style={{ gap: 10, alignItems: 'center', justifyContent: 'flex-end', marginRight: 10 }}>
                        <Typography variant='subtitle1'>REPORT</Typography>
                        <FormControl fullWidth size="small" variant="outlined">
                            <OutlinedInput
                                value={`${currentURL}`}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <FileCopyRounded />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="subtitle2">Modified by:-{infoData.updatedBy}</Typography>
                    <Typography variant="subtitle2">Date Modified:-{formatDate(infoData.updatedDt, format)}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="subtitle2">Created by:-{infoData.createdBy}</Typography>
                    <Typography variant="subtitle2">Date Created:-{formatDate(infoData.createdDt, format)}</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}