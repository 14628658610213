import * as React from 'react';
import { Grid } from '@material-ui/core';
import { CustomBox, CustomerRow, CycleTimeLine, NumberStep, OperationContainer, OperationDesc, TctLine } from '../../styles';
import { minuteToTime } from '../../../../../../utils/convertTime';

export default ({ rows, avgCycleTime }: any) => {

    return (
        <Grid item xs={2}>
            {rows?.map((operators: any, key: number) => {
                const getBackgroundCycleTimeLine = (operators: any, key: number) => {
                    const currentValue = key > 0
                        ? (operators[key - 1]?.cycleTime + operators[key]?.cycleTime)
                        : operators[key].cycleTime;

                    return currentValue <= avgCycleTime
                        ? '#4caf50'
                        : 'red'
                }
                const prevCycleTimeLineWidth = (operators: any, key: number) => {
                    const result = operators[key - 1]?.cycleTime;
                    return result || 0;
                };
                const prevTctLineWidth = (operators: any, key: number) => {
                    const result = operators[key - 1]?.tct;
                    return result || 0;
                };
                return (
                    operators.operatorInlines?.map((operator: any, index: number) => operator.code && (
                        <CustomBox
                            style={{ position: 'relative', outline: '0px' }}
                            background={'#fff'}
                            height={operator.height}>
                            <TctLine
                                prevWith={index > 0 && prevCycleTimeLineWidth(operators.operatorInlines, index) ? prevTctLineWidth(operators, key) : false}
                                width={parseInt(operator?.tct)} />
                            <CycleTimeLine
                                width={parseInt(operator?.cycleTime)}
                                background={getBackgroundCycleTimeLine(operators.operatorInlines, index)}
                                prevWith={index > 0 ? prevCycleTimeLineWidth(operators.operatorInlines, index) : false}
                            />
                        </CustomBox>
                    ))
                )
            })}
        </Grid>
    )
}