import { TableHeaderCellProp, dataProp } from "../../Interfaces";

const tableHeaderCell: TableHeaderCellProp[] = [
    { id: '#', numeric: false, disablePadding: 'none', label: '#' , disableOrder: true, align: 'left'},
    { id: 'versionNo', numeric: false, disablePadding: 'none', label: 'VERSION NO' , disableOrder: false, width: '7%', align: 'left'},
    { id: 'updatedDt', numeric: false, disablePadding: 'none', label: 'DATE MODIFIED' , disableOrder: false, width: '15%', align: 'left'},
    { id: 'updatedBy', numeric: false, disablePadding: 'none', label: 'MODIFIED BY' , disableOrder: false, width: '15%', align: 'left'},
    { id: 'lastTimeStudyDt', numeric: false, disablePadding: 'none', label: 'LAST TIME STUDY' , disableOrder: false, width: '20%', align: 'left'},
    { id: 'lbr', numeric: false, disablePadding: 'none', label: 'LBR' , disableOrder: false, align: 'center'},
    { id: 'currentShiftEff', numeric: false, disablePadding: 'none', label: 'EFF' , disableOrder: false, align: 'center'},
    { id: 'totalManpower', numeric: false, disablePadding: 'none', label: 'MANPOWER' , disableOrder: false, align: 'center'},
    { id: 'outputByPcs', numeric: false, disablePadding: 'none', label: 'OUTPUT BY PCS' , disableOrder: true, align: 'left'},
    { id: 'outputBymin', numeric: false, disablePadding: 'none', label: 'OUTPUT BY MIN' , disableOrder: true, align: 'left'},
    { id: 'remark', numeric: false, disablePadding: 'none', label: 'REMARK' , disableOrder: true, width: '10%', align: 'left'},
];

export {
    tableHeaderCell,
}