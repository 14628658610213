import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import React from 'react';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        inputField: {
            '& .MuiIconButton-root': {
                padding: 'unset!important',
            },
            width: '100%',
            marginTop: 0,
            marginBottom: 0,
            '& .MuiInputBase-input': {
                font: 'normal normal normal 14px/14px Roboto',
                letterSpacing: '0.07px',
                opacity: 1,
            },
            '& .MuiFormLabel-root': {
                font: 'normal normal normal 12px/21px Roboto',
                letterSpacing: '0.07px',
                opacity: 1,
            },
        },
        required: {
            color: '#D32F2F',
        },
        clock: {
            '& .MuiSvgIcon-root': {
                width: '18px',
                height: '18px',
                marginRight: '4px',
                color: '#444444',
            },
        },
    }),
);

interface Props {
    label?: string;
    placeholder?: string;
    id?: string;
    name?: string;
    type?: string;
    onBlur?: any;
    values?: any;
    onChange?: any;
    style?: any;
    helperText?: any;
    error?: any;
    isRequired?: boolean;
    isDisabled?: boolean;
}

export default function TimePicker({
    label,
    placeholder,
    id,
    name,
    type,
    onBlur,
    values,
    onChange,
    style,
    helperText,
    isRequired,
    error,
    isDisabled,
}: Props) {
    // const [selectedDate, setSelectedDate] = React.useState<any>(null);
    const classes = styles();

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
                className={classes.inputField}
                onBlur={onBlur}
                placeholder={placeholder}
                helperText={helperText}
                error={error}
                name={name}
                margin="normal"
                autoOk={true}
                ampm={false}
                label={
                    <span>
                        {label}
                        {isRequired ? <span className={classes.required}> *</span> : <span></span>}
                    </span>
                }
                value={values}
                defaultValue={values}
                onChange={onChange}
                KeyboardButtonProps={{
                    'aria-label': 'change time',
                }}
                keyboardIcon={
                    <div className={classes.clock}>
                        <AccessTimeIcon />
                    </div>
                }
                // inputProps={{
                //     autoComplete: 'off',
                // }}
                views={['hours', 'minutes']}
                variant="dialog"
                clearable={false}
            />
        </MuiPickersUtilsProvider>
    );
}
