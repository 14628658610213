import React from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    Table,
    TableRow,
    withStyles,
    TableHead,
    TableBody,
    Typography,
    Divider,
    Badge,
} from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import { Helmet } from 'react-helmet';
import MultimediaButton from '../../components/buttons/multimediaButton';
import LocalImages from '../../utils/images';
import { ReducersModel } from '../../model';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Utils from '../../utils';
import { useTranslation } from 'react-i18next';
import ResponsiveImage from '../../components/modal/responsiveImageModal';
import SimpleModal from '../../components/modal';
import { ImageCaurosel } from '../../components/caurosel/imagecaurosel';
import { saveAsStyle } from './action';
import { minuteToTime } from '../../utils/convertTime';

const TableCell = withStyles({
    root: {
        borderBottom: 'none',
    },
})(MuiTableCell);

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            width: '99%',
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '20px',
        },
        heading: {
            font: 'normal normal normal 20px/24px Roboto',
            letterSpacing: '0.07px',
            color: '#515151',
            opacity: 1,
            marginBottom: '18px',
        },
        leftContainer: {
            width: 'calc(29.06% - 32px)',
            backgroundColor: 'white',
            padding: '18px 16px',
            maxHeight: '62vh',
            overflow: 'auto',
        },
        rightContainer: {
            width: 'calc(70.34% - 32px)',
            backgroundColor: 'white',
            padding: '0 16px 35px 16px',
            maxHeight: '62vh',
            overflow: 'auto',
        },
        subHeading: {
            textAlign: 'left',
            font: 'normal normal normal 12px/21px Roboto',
            letterSpacing: '0.07px',
            color: '#8C8C8C',
            opacity: 1,
        },
        description: {
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#000000',
            marginBottom: '16px',
            opacity: 1,
        },
        styleImage: {
            marginRight: '16px',
            height: '64px',
            width: '64px',
        },
        divider: {
            margin: '10.5px 0',
        },
        categories: {
            '& th': {
                font: 'normal normal normal 14px/21px Roboto',
                letterSpacing: '0.08px',
                color: '#000000DE',
                textTransform: 'uppercase',
                opacity: 1,
                padding: '16px 3px',
                verticalAlign: 'top',
            },
        },
        styleInfoHeader: {
            '&>th:nth-child(1)': {
                width: '11%',
                maxWidth: '11%',
            },
            '&>th:nth-child(2)': {
                width: '8%',
                minWidth: '8%',
            },
            '&>th:nth-child(3)': {
                width: '15%',
                minWidth: '15%',
            },
            '&>th:nth-child(4)': {
                width: '24%',
                minWidth: '24%',
            },
            '&>th:nth-child(5)': {
                width: '11%',
                maxWidth: '11%',
            },
            '&>th:nth-child(6)': {
                width: '10%',
                maxWidth: '10%',
                textAlign: 'center',
            },
            '&>th:nth-child(7)': {
                width: '7%',
                maxWidth: '7%',
            },
            '&>th:nth-child(8)': {
                width: '13%',
                maxWidth: '13%',
            },
            '&>th:nth-child(9)': {
                width: '7%',
                maxWidth: '7%',
            },
        },
        categoriesItems: {
            padding: '7px',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#000000DE',
            textTransform: 'uppercase',
            opacity: 1,
            '& td': {
                padding: '16px 3px',
                // wordBreak: 'break-word',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                '-webkit-line-clamp': '1',
                '-webkit-box-orient': 'vertical',
                verticalAlign: 'top',
            },
            '&>td:nth-child(6)': {
                textAlign: 'center',
            },
        },
        styleInfoTable: {
            '&>td:nth-child(1)': {
                width: '11%',
                maxWidth: '11%',
            },
            '&>td:nth-child(2)': {
                width: '8%',
                minWidth: '8%',
            },
            '&>td:nth-child(3)': {
                width: '15%',
                minWidth: '15%',
            },
            '&>td:nth-child(4)': {
                width: '24%',
                minWidth: '24%',
            },
            '&>td:nth-child(5)': {
                width: '11%',
                maxWidth: '11%',
            },
            '&>td:nth-child(6)': {
                width: '10%',
                maxWidth: '10%',
                textAlign: 'center',
            },
            '&>td:nth-child(7)': {
                width: '7%',
                maxWidth: '7%',
            },
            '&>td:nth-child(8)': {
                width: '13%',
                maxWidth: '13%',
            },
            '&>td:nth-child(9)': {
                width: '7%',
                maxWidth: '7%',
            },
        },
        buttonContainer: {
            margin: '18px 0 24px 0',
            display: 'flex',
            '&>div': {
                marginRight: '20px',
            },
        },
        imagesContainer: {
            marginBottom: '48px',
        },
        headStyl: {
            font: 'normal normal normal 20px/24px Roboto',
            letterSpacing: '0.07px',
            color: '#515151',
            textTransform: 'uppercase',
            paddingBottom: '15px',
            opacity: '1',
            paddingTop: '18px',
        },
        ifPpaStyle: {
            fontWeight: 'bold',
        },
        headBorder: {
            borderTop: '2px solid #cecece',
            borderBottom: '2px solid #cecece',
        },
        indexStyle: {
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '20px',
            height: '20px',
            border: '1px solid #707070',
        },
        noRecords: {
            marginTop: '25px',
            marginBottom: '14px',
            textAlign: 'center',
            font: 'normal normal normal 16px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#707070',
            opacity: 1,
        },
        dividerLine: {
            backgroundColor: '#bfbfbf',
            height: '1.5px',
            marginTop: '3px',
        },
        noneDisplay: {
            fontWeight: 'bold',
            position: 'relative',
            opacity: 0,
        },
        responsiveImages: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > div': {
                marginRight: '16px',
                marginBottom: '16px',
                '&:last-child': {
                    marginRight: '0px',
                    marginBottom: '0px',
                },
            },
        },
        styleImageBG: {
            width: '25px',
            height: '25px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            cursor: 'pointer',
            border: '1px solid',
        },
        badge: {
            '& .MuiBadge-anchorOriginTopRightRectangle': {
                transform: 'scale(0.8) translate(50%, -50%)',
            },
        },
    }),
);

export default function StyleDetails({ history }: any) {
    const classes = styles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [images, setImages] = React.useState<string[]>([]);
    const [openImage, setOpenImage] = React.useState(false);
    const {
        season,
        brand,
        desc1,
        image1,
        image2,
        image3,
        createdBy,
        createdDt,
        updatedBy,
        updatedDt,
        productType,
        title,
        code
    } = useSelector((state: ReducersModel) => state.styleDetailReducer);

    const { sewingData, featureWiseOperations, totalSmv, totalTct } = useSelector(
        (state: ReducersModel) => state.sewingOperationReducer,
    );
    const styleId = new URL(window.location.href).searchParams.get('id');
    const getTotalSMV = () => {
        let totalSMV = 0;
        if (sewingData && sewingData.length > 0) {
            sewingData.forEach((data: any) => {
                totalSMV += data.smv;
            });
        }
        return Utils.CommonFunctions.maxDecimalValue(totalSMV, 4);
    };
    const getTotalTct = () => {
        let totalTct = 0;
        if (sewingData && sewingData.length > 0) {
            sewingData.forEach((data: any) => {
                totalTct += data.tct;
            });
        }
        return minuteToTime(totalTct);
    };

    const getImageArray = (row: any) => {
        const tempArray = [];
        for (var i = 1; i <= 3; i++) {
            if (row[`machineAttachment${i}Data`] !== null) {
                const { image1, attachmentCode } = row[`machineAttachment${i}Data`];
                tempArray.push(Utils.CommonFunctions.getImageWithOperationCode(attachmentCode, image1));
            }
        }
        setImages(tempArray);
    };

    const getLength = (row: any) => {
        const tempArray = [];
        for (var i = 1; i <= 3; i++) {
            if (row[`machineAttachment${i}Data`] !== null) {
                const { image1, attachmentCode } = row[`machineAttachment${i}Data`];
                tempArray.push(Utils.CommonFunctions.getImageWithOperationCode(attachmentCode, image1));
            }
        }
        return tempArray.length;
    };

    const handleImageOpen = () => {
        setOpenImage(true);
    };

    const handleImageClose = () => {
        setOpenImage(false);
    };

    const getOperationsByGroups = () => {
        return (
            <>
                {featureWiseOperations &&
                    Object.keys(featureWiseOperations).map((particularFeature: any) => (
                        <>
                            {particularFeature.toString().toUpperCase() !== 'NULL' && (
                                <TableRow className={classes.categoriesItems}>
                                    <TableCell className={classes.ifPpaStyle} colSpan={8}>
                                        {particularFeature}
                                    </TableCell>
                                </TableRow>
                            )}
                            {featureWiseOperations[particularFeature].map((row: any) => {
                                if (particularFeature.toString().toUpperCase() !== 'NULL') {
                                    const imageLength = getLength(row);
                                    return (
                                        <TableRow className={classes.categoriesItems} key={row.id}>
                                            <TableCell></TableCell>
                                            <TableCell>
                                                <span className={classes.indexStyle}>{row.step}</span>
                                            </TableCell>
                                            <TableCell>{row.operation}</TableCell>
                                            <TableCell>{row.desc1}</TableCell>
                                            <TableCell>{row.skillTypeName}</TableCell>
                                            <TableCell>
                                                {row.machineAttachment1Data !== null ? (
                                                    <Badge
                                                        className={imageLength > 1 ? `${classes.badge}` : ''}
                                                        invisible={imageLength < 2}
                                                        badgeContent={imageLength}
                                                        color="primary"
                                                    >
                                                        <div
                                                            onClick={() => {
                                                                getImageArray(row);
                                                                handleImageOpen();
                                                            }}
                                                        >
                                                            <img
                                                                className={classes.styleImageBG}
                                                                src={Utils.CommonFunctions.getImageWithOperationCode(
                                                                    row.machineAttachment1Data.attachmentCode,
                                                                    row.machineAttachment1Data.image1,
                                                                )}
                                                                alt={''}
                                                            />
                                                        </div>
                                                    </Badge>
                                                ) : (
                                                    ''
                                                )}
                                            </TableCell>
                                            <TableCell>{row.macSubTypeData.subType}</TableCell>
                                            <TableCell>{row.needleTypeData.needleType}</TableCell>
                                            <TableCell align="right">
                                                {Utils.CommonFunctions.maxDecimalValue(row.smv, 4)}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.tct
                                                    ? minuteToTime(row.tct)
                                                    : 'N/A'}
                                            </TableCell>
                                        </TableRow>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </>
                    ))}
            </>
        );
    };

    return (
        <>
            <Helmet>
                <title>{t('StyleDetails')}</title>
            </Helmet>

            <div className={classes.mainContainer}>
                <div className={classes.leftContainer}>
                    <div>{title && title !== null ? title : '-'}</div>
                    <div className={classes.buttonContainer}>
                        <div>
                            <MultimediaButton
                                icon={LocalImages.EDIT_PENCIL}
                                text={t('Edit')}
                                onClick={() => {
                                    history.push(`/manage-style/edit-style?id=${styleId}`);
                                }}
                            />
                        </div>
                        <div>
                            <MultimediaButton
                                icon={LocalImages.COPY}
                                text={t('Copy')}
                                onClick={() => {
                                    dispatch(saveAsStyle(history, styleId));
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <div className={classes.subHeading}>{t('StyleCode')}</div>
                        <div className={classes.description}>{code && code !== null ? code : '-'}</div>
                    </div>
                    <div>
                        <div className={classes.subHeading}>{t('StyleSMV')}</div>
                        <div className={classes.description}>
                            {totalSmv && totalSmv !== null ? Utils.CommonFunctions.maxDecimalValue(totalSmv, 4) : '-'}
                        </div>
                    </div>
                    <div>
                        <div className={classes.subHeading}>{t('Customer')}</div>
                        <div className={classes.description}>{brand && brand !== null ? brand : '-'}</div>
                    </div>
                    <div>
                        <div className={classes.subHeading}>{t('ProductType')}</div>
                        <div className={classes.description}>
                            {productType && productType !== null ? productType : '-'}
                        </div>
                    </div>
                    <div>
                        <div className={classes.subHeading}>{t('SEASON')}</div>
                        <div className={classes.description}>{season && season !== null ? season : '-'}</div>
                    </div>
                    <div>
                        <div className={classes.subHeading}>{t('DESCRIPTION')}</div>
                        <div className={classes.description}>{desc1 && desc1 !== null ? desc1 : desc1}</div>
                    </div>
                    <div className={classes.imagesContainer}>
                        <div className={classes.subHeading}>{t('StyleImage')}</div>
                        <div className={classes.responsiveImages}>
                            {image1 !== null && <ResponsiveImage src={image1} alt={'style'} size="64px" />}
                            {image2 !== null && <ResponsiveImage src={image2} alt={'style'} size="64px" />}
                            {image3 !== null && <ResponsiveImage src={image3} alt={'style'} size="64px" />}
                        </div>
                        {image1 === null && image2 === null && image3 === null && (
                            <div className={classes.description}>{t('NoImageFound')}</div>
                        )}
                    </div>
                    <div className={classes.subHeading}>
                        {t('MODIFIEDBY')}: {updatedBy === null ? 'N/A' : updatedBy}
                    </div>
                    <div className={classes.subHeading}>
                        {t('DATEMODIFIED')}: {updatedDt !== null ? moment(updatedDt).format('YYYY-MM-DD') : '-'}
                    </div>
                    <div className={classes.subHeading}>
                        {t('Createdby')}: {createdBy === null ? 'N/A' : createdBy}
                    </div>
                    <div className={classes.subHeading}>{t('CREATEDBYFACTORY')}: Factory 01</div>
                    <div className={classes.subHeading}>
                        {t('DateCreated')}: {moment(createdDt).format('YYYY-MM-DD')}
                    </div>
                </div>
                <div className={classes.rightContainer}>
                    <Typography className={classes.headStyl}>{t('SewingOperations')}</Typography>
                    <Table stickyHeader={true} aria-label="sticky table">
                        <TableHead className={classes.headBorder}>
                            <TableRow className={`${classes.categories} ${classes.styleInfoHeader}`}>
                                <TableCell>{t('Features')}</TableCell>
                                <TableCell>{t('STEP')}</TableCell>
                                <TableCell>{t('OperationCode')}</TableCell>
                                <TableCell>{t('DESCRIPTION')}</TableCell>
                                <TableCell>{t('SkillType')}</TableCell>
                                <TableCell>{t('Img')}</TableCell>
                                <TableCell>{t('MC')}</TableCell>
                                <TableCell>{t('NEEDLETYPE')}</TableCell>
                                <TableCell align="right">{t('SMV')}</TableCell>
                                <TableCell align="right">{t('TCT')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sewingData && sewingData.length > 0 ? <>{getOperationsByGroups()}</> : ''}

                            {featureWiseOperations &&
                                featureWiseOperations['null'] &&
                                featureWiseOperations['null'].length > 0 &&
                                sewingData &&
                                sewingData.length > 0 && (
                                    <>
                                        <TableRow className={classes.categoriesItems}>
                                            <TableCell className={classes.ifPpaStyle} colSpan={8}>
                                                {t('ASSEMBLY')}
                                            </TableCell>
                                        </TableRow>
                                        {featureWiseOperations &&
                                            featureWiseOperations['null'] &&
                                            featureWiseOperations['null'].map((row: any) => {
                                                const imageLength = getLength(row);
                                                return (
                                                    <TableRow
                                                        className={`${classes.categoriesItems} ${classes.styleInfoTable}`}
                                                        key={row.id}
                                                    >
                                                        <TableCell></TableCell>
                                                        <TableCell>
                                                            <span className={classes.indexStyle}>{row.step}</span>
                                                        </TableCell>
                                                        <TableCell>{row?.operation}</TableCell>
                                                        <TableCell>{row.desc1}</TableCell>
                                                        <TableCell>{row?.skillTypeData?.skillTypeCode}</TableCell>
                                                        <TableCell>
                                                            {row.machineAttachment1Data !== null ? (
                                                                <Badge
                                                                    invisible={imageLength < 2}
                                                                    badgeContent={imageLength}
                                                                    color="primary"
                                                                    className={
                                                                        imageLength > 1 ? `${classes.badge}` : ''
                                                                    }
                                                                >
                                                                    <div
                                                                        onClick={() => {
                                                                            getImageArray(row);
                                                                            handleImageOpen();
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className={classes.styleImageBG}
                                                                            src={Utils.CommonFunctions.getImageWithOperationCode(
                                                                                row.machineAttachment1Data
                                                                                    .attachmentCode,
                                                                                row.machineAttachment1Data.image1,
                                                                            )}
                                                                            alt={''}
                                                                        />
                                                                    </div>
                                                                </Badge>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </TableCell>
                                                        <TableCell>{row.macSubTypeData.subType}</TableCell>
                                                        <TableCell>{row.needleTypeData.needleType}</TableCell>
                                                        <TableCell align="right">
                                                            {Utils.CommonFunctions.maxDecimalValue(row.smv, 4)}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {row.tct
                                                                ? minuteToTime(row.tct)
                                                                : 'N/A'}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </>
                                )}

                            {sewingData.length > 0 ? (
                                <>
                                    <TableRow className={classes.categoriesItems}>
                                        <TableCell align="center" colSpan={10}>
                                            <div>
                                                <Divider className={classes.dividerLine} />
                                                <Divider className={classes.dividerLine} />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.categoriesItems}>
                                        <TableCell className={classes.ifPpaStyle} colSpan={4}>
                                            {t('THEEND')}
                                        </TableCell>
                                        <TableCell align="right" className={classes.ifPpaStyle} colSpan={2}>
                                            {t('TotalSMV')}:
                                        </TableCell>
                                        <TableCell align="right" className={classes.ifPpaStyle} colSpan={2}>
                                            {getTotalSMV()}
                                        </TableCell>
                                        <TableCell align="right" className={classes.ifPpaStyle} colSpan={2}>
                                            {getTotalTct()}
                                        </TableCell>
                                    </TableRow>
                                </>
                            ) : (
                                <TableRow className={classes.categoriesItems}>
                                    <TableCell align="center" colSpan={9}>
                                        <Typography align={'center'} className={classes.noRecords}>
                                            {t('NoRecords')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
            <SimpleModal
                isOpen={openImage}
                body={
                    <ImageCaurosel handleClose={handleImageClose} items={images} imgToOpen={0} alt={t('Attachment')} />
                }
            />
        </>
    );
}
