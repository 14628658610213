import Utils from '../../utils';
import { LineSetupAssistInfo, linesAssistTable, linesAssistReport } from '../../model';

export const lineAssistInfoReducer = (state: LineSetupAssistInfo = new LineSetupAssistInfo(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_LINE_ASSIST_INFO:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const assistTableLineSetup = (state: linesAssistTable = new linesAssistTable(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.ASSIST_LINE_TABLE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
export const assistReportReducer = (state: linesAssistReport = new linesAssistReport(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.ASSIST_LINE_RREPORT:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
