import React, { useEffect, useRef, useState } from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    FormControl,
    Button,
    MenuItem,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { Helmet } from 'react-helmet';
import { Formik } from 'formik';
// @ts-ignore
import Schema from '../../schema';
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';
import { ReducersModel } from '../../model';
import NormalInput from '../../components/inputs/normalInput';
import SelectPicker from '../../components/selectPicker';
import SelectPickerDefault from '../../components/selectPicker/defaultIndex';
import SimpleModal from '../../components/modal';
import OperationList from './operationsList';

import {
    editCurrentStyle,
    getCustomers,
    getMachineSubtypeList,
    getNeedleTypesList,
    getPPA,
    getProductType,
    getSeasons,
    getStyleDetail,
    getSweingOperationForAddStyle,
    reorderOperations,
    updatePPA,
    saveAsStyle,
    cancelUpdatedStyle,
    uploadImage,
    uploadImageToGlobalAdmin,
    modifyStyleImage,
} from './action';
import Utils from '../../utils';
import FirstAddOperation from './firstAddOperation';
import WithRemoveImage from '../../components/image/withRemoveImage';
import { getOperationCodeAll } from '../admin/action';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { minuteToTime } from '../../utils/convertTime';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        leftContainer: {
            width: '27%',
            margin: '16px auto',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '18px 1.2%',
            maxHeight: '74vh',
            overflow: 'auto',
            '& .MuiTextField-root': {
                marginBottom: '10px',
            },
        },
        rightContainer: {
            width: '68.2%',
            margin: '16px auto',
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '50px',
            maxHeight: '77vh',
            overflow: 'auto',
        },
        rightInnerContainer: {
            padding: '18px 1.2%',
            height: 'fit-content',
            marginBottom: '12px',
            backgroundColor: 'white',
            color: '#515151',
            textAlign: 'left',
            font: 'normal normal normal 20px/24px Roboto',
            letterSpacing: '0.07px',
            opacity: 1,
            '&>span': {
                color: '#DD0D0D',
            },
        },
        rightInnerMainContainer: {
            height: 'fit-content',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'sticky',
            top: '0',
            '&>div': {
                textAlign: 'left',
                font: 'normal normal normal 16px/19px Roboto',
                letterSpacing: '0.06px',
                color: '#515151',
                textTransform: 'uppercase',
                opacity: 1,
                marginBottom: '23px',
            },
        },
        rightStickyContainer: {
            zIndex: 2,
        },
        leftTableDescription: {
            width: 'calc(13.74% - 25px)',
            [theme.breakpoints.down(1140)]: {
                width: 'calc(14.74% - 25px)',
            },
        },
        rightTableDescription: {
            width: 'calc(91.5% - 32px)',
        },
        leftTableDescriptionDrag: {
            width: 'calc(10.74%)',
            '&>div': {
                '& .MuiFormControl-root': {
                    width: '90%!important',
                    display: 'flex!important',
                    margin: 'auto',
                },
                backgroundColor: 'white',
                paddingBottom: '13px',
                marginBottom: '10px',
            },
        },
        rightTableDescriptionDrag: {
            width: 'calc(87.32%)',
        },
        columns: {
            backgroundColor: '#D2D2D2',
            minHeight: '35px',
            [theme.breakpoints.down(1324)]: {
                minHeight: '55px',
            },
        },
        heading: {
            backgroundColor: 'white',
            padding: '18px 6px',
        },
        saveContainer: {
            backgroundColor: 'white',
            padding: '10px 0',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            position: 'fixed',
            bottom: '0px',
        },
        cancelButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#515151',
            opacity: 1,
            height: '36px',
        },
        saveButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#FFFFFF',
            opacity: 1,
            height: '36px',
        },
        browseButton: {
            margin: '0 15px',
            minWidth: '77.3%',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#FFFFFF',
            opacity: 1,
            height: '36px',
        },
        dropImagesContainer: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            border: '2px dashed rgb(112, 112, 112, 0.6)',
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '8%',
        },
        styleImageTitle: {
            marginBottom: '10px',
            marginTop: '25px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#000000',
            opacity: 1,
        },
        dropDescription: {
            textAlign: 'center',
            font: 'normal normal bold 16px/28px Roboto',
            letterSpacing: '0px',
            color: '#515151',
            opacity: 1,
        },
        orBox: {
            textAlign: 'center',
            font: 'normal normal normal 14px/28px Roboto',
            letterSpacing: '0px',
            color: '#515151',
            opacity: 1,
            marginBottom: '6px',
        },
        imageNote: {
            marginTop: '10px',
            textAlign: 'center',
            font: 'normal normal normal 14px/28px Roboto',
            letterSpacing: '0px',
            color: '#515151',
            opacity: 1,
        },
        dropIcon: {},
        noRecords: {
            marginTop: '25px',
            marginBottom: '14px',
            textAlign: 'center',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#707070',
            opacity: 1,
        },
        addOperation: {
            textAlign: 'center',
            font: 'normal normal medium 16px/21px Roboto',
            letterSpacing: '0.09px',
            color: '#1689FA',
            opacity: 1,
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            width: 'fit-content',
            margin: 'auto',
        },
        modalBodyContainer: {
            height: '50vh',
        },
        paper: {
            position: 'absolute',
            width: 400,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        categories: {
            display: 'flex',
            backgroundColor: '#D2D2D2',
            '&>div': {
                font: 'normal normal normal 14px/21px Roboto',
                letterSpacing: '0.08px',
                color: '#000000DE',
                textTransform: 'uppercase',
                opacity: 1,
                padding: '7px 7px 7px 0px',
            },
            '& div:nth-child(1)': { width: '7%', marginLeft: '1%' },
            '& div:nth-child(2)': { width: '13%' },
            '& div:nth-child(3)': { width: '18%' },
            '& div:nth-child(4)': { width: '8%' },
            '& div:nth-child(5)': { width: '7%' },
            '& div:nth-child(6)': { width: '7%' },
            '& div:nth-child(7)': { width: '9%' },
            '& div:nth-child(8)': { width: '10%' },
            '& div:nth-child(9)': { width: '7%' },
            '& div:nth-child(10)': { width: '13%' },
        },
        totalCategories: {
            display: 'flex',
            width: 'calc(90.5% - 32px)',
            '&>div': {
                font: 'normal normal bold 14px/21px Roboto',
                letterSpacing: '0.08px',
                color: '#000000DE',
                textTransform: 'uppercase',
                opacity: 1,
                padding: '7px',
            },
            '& div:nth-child(1)': { width: '37%', paddingLeft: '2%' },
            '& div:nth-child(2)': { width: '36.5%', textAlign: 'right' },
            '& div:nth-child(3)': { width: '13.5%' },
        },
        totalCategoriesContainer: {
            width: '100%',
            justifyContent: 'flex-end',
            display: 'flex',
        },
        stylesContainer: {
            display: 'flex',
            marginBottom: '25px',
            flexWrap: 'wrap',
        },
        styleImageContainer: {
            position: 'relative',
            marginRight: '20px',
            marginBottom: '16px',
        },
        styleImage: {
            width: '96px',
            height: '96px',
        },
        deleteStyle: {
            position: 'absolute',
            top: '2%',
            right: '2%',
            cursor: 'pointer',
            height: '24px',
            width: '24px',
        },
        ppaSelectContainer: {
            '& .MuiInputBase-input': {
                textAlign: 'center',
            },
        },
        headingContainer: {
            padding: '20px 0',
            '&>div': {
                textAlign: 'left',
                font: 'normal normal normal 12px/21px Roboto',
                letterSpacing: '0.07px',
                color: '#8C8C8C',
                opacity: 1,
                paddingTop: '1px',
            },
        },
        statusContainer: {
            display: 'flex',
            alignItems: 'center',
            '& .MuiFormGroup-root': { flexDirection: 'row' },
            '& .MuiTypography-root': {
                textAlign: 'left',
                font: 'normal normal normal 14px/21px Roboto',
                letterSpacing: '0.08px',
                color: '#515151',
                opacity: 1,
            },
        },
        status: {
            marginRight: '40px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
        styleImageBG: {
            width: '96px',
            height: '96px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
    }),
);

export default function EditStyle({ history }: any) {
    const classes = styles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { seasons } = useSelector((state: ReducersModel) => state.seasonListReducer);
    const { customers } = useSelector((state: ReducersModel) => state.customersListReducer);
    const { sewingData } = useSelector((state: ReducersModel) => state.sewingOperationForAddStyleReducer);
    const { productTypes } = useSelector((state: ReducersModel) => state.productTypeListReducer);
    const { isLoading } = useSelector((state: ReducersModel) => state.globalLoaderReducer);
    const {
        season,
        desc1,
        image1,
        image2,
        image3,
        customerId,
        seasonId,
        customer,
        createdById,
        title,
        editHostedImages,
        createdBy,
        createdDt,
        updatedBy,
        updatedDt,
        isActive,
        code,
    } = useSelector((state: ReducersModel) => state.styleDetailReducer);
    const { selectedProduct, selectedProductId } = useSelector((state: ReducersModel) => state.productTypeListReducer);
    const { PPAData } = useSelector((state: ReducersModel) => state.ppaOperationReducer);
    const styleId = new URL(window.location.href).searchParams.get('id');
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [localImage, setLocalImage] = useState<Array<any>>([...editHostedImages]);

    const handleFile = (e: any) => {
        const { files } = e.target;
        let tempHostedimages = [...editHostedImages];
        if (tempHostedimages.length + e.target.files.length > 3) {
            alert(t('PleaseSelectMax'));
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const hostedImage: any = [];

        for (let i = 0; i < files.length; i += 1) {
            if (
                e.target.files[i] &&
                (e.target.files[i].type === 'image/png' ||
                    e.target.files[i].type === 'image/jpg' ||
                    e.target.files[i].type === 'image/jpeg')
            ) {
                hostedImage.push(files[i]);
            }
        }
        if (hostedImage.length > 0){
            setLocalImage([...localImage, ...hostedImage]);
            uploadImageToGlobalAdmin(files)
                .then((fileURL: any) => {
                    dispatch(modifyStyleImage(fileURL, true));
                })
                .catch((error: any) => {
                    console.error(error);
                });
        }else{
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
        }
    };

    const removeImage = (index: number, setFieldValue: any) => {
        const tempHostedimages = [...editHostedImages];

        if (index > -1) {
            tempHostedimages.splice(index, 1);
            const newArray = [...localImage];
            newArray.splice(index, 1);
            setLocalImage(newArray);
        }

        dispatch({
            type: Utils.ActionName.GET_STYLE_DETAIL,
            payload: {
                editHostedImages: tempHostedimages,
            },
        });
    };

    const uploadButtonRef = useRef<any>(null);
    const ppaSelect = (ppa: any, operationId: any) => {
        return (
            <div className={classes.ppaSelectContainer}>
                <SelectPickerDefault
                    isDisabled={false}
                    values={ppa}
                    onChange={(event: any) => {
                        if (event.target.value !== 'null') {
                            const value = event.target.value;
                            const index = PPAData.findIndex((x: any) => x.id === value);
                            const ppaName = PPAData[index].name;
                            dispatch(updatePPA(operationId, value, ppaName, styleId, sewingData));
                        }
                    }}
                    onClear={() => {
                        dispatch(updatePPA(operationId, null, null, styleId, sewingData));
                    }}
                    data={PPAListingNew()}
                />
            </div>
        );
    };

    useEffect(() => {
        dispatch({
            type: Utils.ActionName.GET_STYLE_DETAIL,
            payload: {
                editHostedImages: [],
            },
        });
        // customer list
        dispatch(getCustomers());
        // seasons list
        dispatch(getSeasons());
        // needle types list
        dispatch(getNeedleTypesList());
        // machine sub types list
        dispatch(getMachineSubtypeList());
        // for getting the data to edit
        dispatch(getStyleDetail(styleId));
        dispatch(getSweingOperationForAddStyle(styleId));
        dispatch(getPPA());
        dispatch(getOperationCodeAll());
        dispatch(getProductType());

        return () => {
            dispatch({
                type: Utils.ActionName.GET_STYLE_DETAIL,
                payload: {
                    editHostedImages: [],
                },
            });
            dispatch({
                type: Utils.ActionName.NEW_STYLE,
                payload: {
                    image1: null,
                    image2: null,
                    image3: null,
                },
            });
            dispatch({
                type: Utils.ActionName.UPDATE_SEWING_OPERATION,
                payload: {
                    sewingData: [],
                },
            });
            dispatch({
                type: Utils.ActionName.UPDATE_SEWING_OPERATION_FOR_ADD_STYLE,
                payload: {
                    sewingData: [],
                },
            });
        };
    }, [dispatch, styleId]);

    const seasonsListing = () => {
        return seasons && seasons.length > 0
            ? seasons.map((data: any, index: any) => {
                  return (
                      <MenuItem id={data.season} key={data.season} value={data.id}>
                          {data.season}
                      </MenuItem>
                  );
              })
            : null;
    };

    const customersListing = () => {
        return customers && customers.length > 0
            ? customers.map((data: any, index: any) => {
                  return (
                      <MenuItem key={data.customer} value={data.id}>
                          {data.customer}
                      </MenuItem>
                  );
              })
            : null;
    };

    const productTypeListing = () => {
        return productTypes && productTypes.length > 0
            ? productTypes.map((data: any, index: any) => {
                  return (
                      <MenuItem key={data.index} value={data.id}>
                          {data.displayName}
                      </MenuItem>
                  );
              })
            : null;
    };

    const PPAListingNew = () => {
        return PPAData && PPAData.length > 0
            ? PPAData.map((data: any, index: any) => {
                  return (
                      <MenuItem key={index} value={data.id}>
                          {data.name}
                      </MenuItem>
                  );
              })
            : null;
    };

    const droppedFiles = (acceptedFiles: any, rejectedFiles: any, setFieldValue: any) => {
        if (acceptedFiles.length > 3) {
            alert(t('PleaseSelectMax'));
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        if (rejectedFiles.length > 0) {
            Utils.showAlert(2, 'Drop only .png or .jpg files');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
        }
        const hostedImage: any = [];

        for (let i = 0; i < acceptedFiles.length; i++) {
            if (
                acceptedFiles[i] &&
                (acceptedFiles[i].type === 'image/png' ||
                    acceptedFiles[i].type === 'image/jpg' ||
                    acceptedFiles[i].type === 'image/jpeg')
            ) {
                hostedImage.push(acceptedFiles[i]);

                if (hostedImage.length > 0) {
                    setLocalImage([...localImage, ...hostedImage]);
                    uploadImageToGlobalAdmin(acceptedFiles[i])
                        .then((fileURL: any) => {
                            dispatch(modifyStyleImage(fileURL, true));
                        })
                        .catch((error: any) => {
                            console.error(error);
                        });
                };
            } else {
                Utils.showAlert(2, 'Drop only .png or .jpg files');
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            }
        }
    };

    const getTotalSMV = () => {
        let totalSMV = 0;
        if (sewingData && sewingData.length > 0) {
            sewingData.forEach((data: any) => {
                totalSMV += data.smv;
            });
        }
        return Utils.CommonFunctions.maxDecimalValue(totalSMV, 4);
    };
    const getTotalTct = () => {
        let totalTct = 0;
        if (sewingData && sewingData.length > 0) {
            sewingData.forEach((data: any) => {
                totalTct += data.tct;
            });
        }
        return minuteToTime(totalTct);
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {t('Edit')} {t('Style')}
                </title>
            </Helmet>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    styleName: title,
                    code,
                    description: desc1,
                    customer,
                    season,
                    customerId,
                    seasonId,
                    image1,
                    image2,
                    image3,
                    title: title,
                    styleId,
                    isSaveas: false,
                    productTypeId: selectedProductId,
                    productTypeName: selectedProduct,
                    isActive: isActive,
                }}
                validationSchema={Schema.AddStyleSchema(t)}
                onSubmit={(values, { setSubmitting }) => {
                    if (!values.isSaveas) {
                        dispatch({
                            type: Utils.ActionName.GET_PRODUCT_TYPE_LIST,
                            payload: {
                                selectedProduct: values.productTypeName,
                                selectedProductId: values.productTypeId,
                            },
                        });
                        dispatch({
                            type: Utils.ActionName.GET_CUSTOMER_LIST,
                            payload: {
                                selectedCustomer: values.customer,
                                selectedCustomerId: values.customerId,
                            },
                        });
                        dispatch(editCurrentStyle(values, setSubmitting, history, handleClose));
                        const operationsCopy = [...sewingData];
                        let operationIds: any = [];

                        operationsCopy.forEach((operation: any) => {
                            operationIds.push(operation.id);
                        });
                        if (operationIds.length > 0) {
                            dispatch({
                                type: Utils.ActionName.UPDATE_SEWING_OPERATION,
                                payload: {
                                    sewingData: [],
                                    page: 1,
                                    totalSmv: 0,
                                },
                            });
                            dispatch(reorderOperations(setSubmitting, operationIds, styleId));
                        }
                    } else {
                        dispatch(saveAsStyle(history, styleId));
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    isValid,
                    dirty,
                    setFieldValue,
                }) => (
                    <React.Fragment>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.mainContainer}>
                                <div className={classes.leftContainer}>
                                    <div>
                                        {t('Edit')} {t('Style')}
                                    </div>
                                    <div>
                                        <FormControl fullWidth>
                                            <NormalInput
                                                onBlur={handleBlur}
                                                values={
                                                    values.styleName ? values.styleName.trimLeft() : values.styleName
                                                }
                                                onChange={(e: any) => {
                                                    handleChange(e);
                                                    setFieldValue('title', e.target.value);
                                                }}
                                                helperText={touched.styleName && errors.styleName}
                                                error={Boolean(touched.styleName && errors.styleName)}
                                                name={'styleName'}
                                                label={t('StyleName')}
                                                isRequired={true}
                                                type={'text'}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <NormalInput
                                                onBlur={handleBlur}
                                                values={values.code ? values.code.trimLeft() : values.code}
                                                onChange={(e: any) => {
                                                    handleChange(e);
                                                    dispatch({
                                                        type: Utils.ActionName.NEW_STYLE,
                                                        payload: {
                                                            styleCode: e.target.value,
                                                        },
                                                    });
                                                }}
                                                helperText={touched.code && errors.code}
                                                error={Boolean(touched.code && errors.code)}
                                                name={'code'}
                                                label={t('StyleCode')}
                                                isRequired={true}
                                                type={'text'}
                                                isDisabled={true}
                                            />
                                        </FormControl>

                                        <SelectPicker
                                            values={values.productTypeId}
                                            helperText={touched.productTypeId && errors.productTypeId}
                                            error={Boolean(touched.productTypeId && errors.productTypeId)}
                                            onChange={(event: any) => {
                                                const value = event.target.value;
                                                const index = productTypes.findIndex((x: any) => x.id === value);
                                                setFieldValue('productTypeId', value);
                                                setFieldValue('productTypeName', productTypes[index].productType);
                                            }}
                                            name={'productTypeId'}
                                            label={t('ProductType')}
                                            data={productTypeListing()}
                                            isRequired={true}
                                        />
                                        <SelectPicker
                                            values={values.customerId}
                                            onChange={(event: any) => {
                                                const value = event.target.value;
                                                const index = customers.findIndex((x: any) => x.id === value);
                                                setFieldValue('customerId', value);
                                                setFieldValue('customer', customers[index].customer);
                                            }}
                                            name={'customerId'}
                                            label={t('SelectCustomer')}
                                            data={customersListing()}
                                            isRequired={false}
                                        />
                                        <SelectPicker
                                            values={values.seasonId}
                                            onChange={(event: any) => {
                                                const value = event.target.value;
                                                const index = seasons.findIndex((x: any) => x.id === value);
                                                setFieldValue('seasonId', value);
                                                setFieldValue('season', seasons[index].season);
                                            }}
                                            name={'seasonId'}
                                            label={t('SelectSeason')}
                                            data={seasonsListing()}
                                            isRequired={false}
                                        />
                                        <FormControl fullWidth>
                                            <NormalInput
                                                onBlur={handleBlur}
                                                values={values.description}
                                                onChange={(e: any) => {
                                                    handleChange(e);
                                                    dispatch({
                                                        type: Utils.ActionName.NEW_STYLE,
                                                        payload: {
                                                            description: e.target.value,
                                                        },
                                                    });
                                                }}
                                                placeholder={t('DESCRIPTION')}
                                                helperText={touched.description && errors.description}
                                                error={Boolean(touched.description && errors.description)}
                                                name={'description'}
                                                label={t('DESCRIPTION')}
                                                type={'text'}
                                                isRequired={false}
                                            />
                                        </FormControl>
                                    </div>
                                    <div style={{ minHeight: '290px' }}>
                                        <div className={classes.styleImageTitle}>{t('StyleImage')}</div>
                                        <div className={classes.stylesContainer}>
                                            {localImage.length > 0 ? (
                                                <div className={classes.styleImageContainer}>
                                                    <WithRemoveImage
                                                        src={
                                                            localImage[0] instanceof File
                                                                ? URL.createObjectURL(localImage[0])
                                                                : localImage[0]
                                                        }
                                                        alt={t('Style')}
                                                        removeImage={() => removeImage(0, setFieldValue)}
                                                    />
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            {localImage.length > 1 ? (
                                                <div className={classes.styleImageContainer}>
                                                    <WithRemoveImage
                                                        src={
                                                            localImage[1] instanceof File
                                                                ? URL.createObjectURL(localImage[1])
                                                                : localImage[1]
                                                        }
                                                        alt={t('Style')}
                                                        removeImage={() => removeImage(1, setFieldValue)}
                                                    />
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            {localImage.length > 2 ? (
                                                <div className={classes.styleImageContainer}>
                                                    <WithRemoveImage
                                                        src={
                                                            localImage[2] instanceof File
                                                                ? URL.createObjectURL(localImage[2])
                                                                : localImage[2]
                                                        }
                                                        alt={t('Style')}
                                                        removeImage={() => removeImage(2, setFieldValue)}
                                                    />
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>

                                        {editHostedImages && editHostedImages.length < 3 ? (
                                            <React.Fragment>
                                                <Dropzone
                                                    onDrop={(acceptedFiles, rejectedFiles) => {
                                                        droppedFiles(acceptedFiles, rejectedFiles, setFieldValue);
                                                    }}
                                                    accept={['.jpg', '.png', '.jpeg']}
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div
                                                            className={classes.dropImagesContainer}
                                                            {...getRootProps()}
                                                        >
                                                            <div className={classes.dropIcon}>
                                                                <PublishIcon />
                                                            </div>
                                                            <div className={classes.dropDescription}>
                                                                {t('DropYour')}
                                                            </div>
                                                            <div className={classes.orBox}>{t('or')}</div>
                                                            <input
                                                                {...getInputProps()}
                                                                type="file"
                                                                id="styleImages"
                                                                onChange={handleFile}
                                                                style={{ display: 'none' }}
                                                                ref={uploadButtonRef}
                                                                accept=".png, .jpg, .jpeg"
                                                                multiple
                                                            />
                                                            <Button
                                                                color="primary"
                                                                size="large"
                                                                variant="contained"
                                                                className={classes.browseButton}
                                                                onClick={() => {
                                                                    uploadButtonRef.current.click();
                                                                }}
                                                            >
                                                                {t('Browse')}
                                                            </Button>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                <div className={classes.imageNote}>{t('UptoImages')}</div>
                                            </React.Fragment>
                                        ) : (
                                            ''
                                        )}
                                        <div className={classes.statusContainer}>
                                            <span className={classes.status}>{t('Status')}: </span>
                                            <RadioGroup
                                                id="isActive"
                                                value={String(values.isActive)}
                                                onChange={(event: any) => {
                                                    setFieldValue('isActive', `${event.target.value}`);
                                                }}
                                            >
                                                <FormControlLabel
                                                    value={`true`}
                                                    control={<Radio color="primary" />}
                                                    label={t('Active')}
                                                />
                                                <FormControlLabel
                                                    value={`false`}
                                                    control={<Radio color="primary" />}
                                                    label={t('Inactive')}
                                                />
                                            </RadioGroup>
                                        </div>
                                        <div className={classes.headingContainer}>
                                            <div>
                                                {t('MODIFIEDBY')}: {updatedBy === null ? 'N/A' : updatedBy}
                                            </div>
                                            <div>
                                                {t('DATEMODIFIED')}:
                                                {updatedDt !== null ? moment(updatedDt).format('YYYY-MM-DD') : '-'}
                                            </div>
                                            <div>
                                                {t('Createdby')}: {createdBy === null ? 'N/A' : createdBy}
                                            </div>
                                            <div>
                                                {t('DateCreated')}: {moment(createdDt).format('YYYY-MM-DD')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.rightContainer}>
                                    <div className={classes.rightInnerContainer}>
                                        {t('StyleInformation')} <span style={{ color: '#DD0D0D' }}>*</span>
                                    </div>
                                    <div
                                        className={`${classes.rightInnerMainContainer} ${classes.rightStickyContainer}`}
                                    >
                                        <div className={classes.leftTableDescription}>
                                            <div className={classes.heading}>{t('Features')}</div>
                                            <div className={classes.columns}></div>
                                        </div>
                                        <div className={classes.rightTableDescription}>
                                            <div className={classes.heading}>{t('SewingOperations')}</div>
                                            <div className={classes.categories}>
                                                <div>{t('STEP')}</div>
                                                <div>{t('OperationCode')}</div>
                                                <div>{t('DESCRIPTION')}</div>
                                                <div>{t('SkillType')}</div>
                                                <div>{t('Img')}</div>
                                                <div>{t('FREQ')}</div>
                                                <div>M/C</div>
                                                <div>{t('NEEDLETYPE')}</div>
                                                <div>{t('SMV')}</div>
                                                <div>{t('TCT')}</div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                    {sewingData && sewingData.length > 0 ? (
                                        <React.Fragment>
                                            <div className={classes.rightInnerMainContainer}>
                                                <div className={classes.leftTableDescriptionDrag}>
                                                    {sewingData.map((data: any, index: number) => {
                                                        return (
                                                            <div key={index}>{ppaSelect(data.featureId, data.id)}</div>
                                                        );
                                                    })}
                                                </div>
                                                <div className={classes.rightTableDescriptionDrag}>
                                                    <OperationList isSameUser={true} sewingData={sewingData} />
                                                </div>
                                            </div>
                                            <div className={classes.totalCategoriesContainer}>
                                                <div className={classes.totalCategories}>
                                                    <div>{t('THEEND')}</div>
                                                    <div>
                                                        {t('TotalSMV')} : {getTotalSMV()}
                                                    </div>
                                                    <div>{getTotalTct()}</div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <div>
                                            <div className={classes.noRecords}>{t('NoRecords')}</div>
                                            <div
                                                className={classes.addOperation}
                                                style={
                                                    !(isValid && dirty) ? { color: 'grey', pointerEvents: 'none' } : {}
                                                }
                                                onClick={() => handleOpen()}
                                            >
                                                {t('AddOperation')}
                                            </div>
                                            <SimpleModal
                                                handleOpen={handleOpen}
                                                isOpen={open}
                                                body={<FirstAddOperation handleClose={handleClose} />}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={classes.saveContainer}>
                                <Button
                                    variant="outlined"
                                    size="large"
                                    className={classes.cancelButton}
                                    onClick={() => {
                                        dispatch(cancelUpdatedStyle(styleId, history));
                                    }}
                                >
                                    {t('CANCEL')}
                                </Button>
                                <Button
                                    variant="outlined"
                                    size="large"
                                    type="submit"
                                    className={classes.cancelButton}
                                    onClick={() => {
                                        setFieldValue('isSaveas', true);
                                        dispatch({
                                            type: Utils.ActionName.GET_STYLE_DETAIL,
                                            payload: {
                                                isSaveas: true,
                                            },
                                        });
                                    }}
                                    disabled={isLoading}
                                >
                                    {t('SAVEAS')}
                                </Button>
                                <Button
                                    color="primary"
                                    disabled={
                                        isLoading ||
                                        // localStorage.getItem('userId') !== `${createdById}` ||
                                        createdById === null
                                    }
                                    onClick={() => {
                                        setFieldValue('isSaveas', false);
                                        dispatch({
                                            type: Utils.ActionName.GET_STYLE_DETAIL,
                                            payload: {
                                                isSaveas: false,
                                            },
                                        });
                                    }}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className={classes.saveButton}
                                >
                                    {t('Save')}
                                </Button>
                            </div>
                        </form>
                    </React.Fragment>
                )}
            </Formik>
        </React.Fragment>
    );
}
