import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            minHeight: '100vh',
            backgroundColor: '#F7F7FB',
        },
    }),
);

export default function AuthContainer({ children }: any) {
    const classes = styles();

    return (
        <div className={classes.mainContainer}>
            {children}
        </div>
    );
}
