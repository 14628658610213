import React from 'react';
import Modal from '@material-ui/core/Modal';

export default function SimpleModal({ isOpen, handleOpen, handleClose, body }: any) {
    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            {body}
        </Modal>
    );
}
