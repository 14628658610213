import React from 'react';
import { makeStyles, createStyles, Theme, Divider, FormControlLabel, Checkbox } from '@material-ui/core';
import Utils from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModel } from '../../model';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import { useTranslation } from 'react-i18next';
import { getTimestudyOperator } from '../../screens/admin/action';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            minWidth: '210px',
        },
        title: {
            padding: '0 16px 8px 16px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
        subTitle: {
            padding: '8px 16px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
        filtersBox: {
            padding: '8px 16px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
            display: 'flex',
            flexDirection: 'column',
            '& .MuiTypography-root': {
                textAlign: 'left',
                font: 'normal normal normal 12px/21px Roboto',
                letterSpacing: '0.07px',
                color: '#868686',
                opacity: 1,
            },
            '& .MuiCheckbox-root': {
                padding: '2px 7px',
            },
            '& .MuiFormGroup-root': { flexDirection: 'row!important' },
        },
        statusContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        status: {
            marginRight: '40px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
        indeterminateColor: {
            color: '#1E88E5',
        },
        disabledCheck: {
            '& .MuiTypography-body1': {
                fontStyle: 'italic',
                color: '#868686',
            },
        },
        naturalCheck: {
            '& .MuiTypography-root': {
                color: '#515151',
            },
        },
    }),
);

export default function ManageOperatorTimeStudyFilter({ skills, machines }: any) {
    const classes = styles();
    const { t } = useTranslation();
    const { operatorId } = useSelector((state: ReducersModel) => state.skillReducer);
    const { filterSkillIds, filterMachineIds } = useSelector((state: ReducersModel) => state.timeStudyOperatorReducer);
    const dispatch = useDispatch();
    return (
        <div className={classes.mainContainer}>
            <div className={classes.title}>{t('Filterby')}</div>
            <Divider />
            <div>
                <div className={classes.subTitle}>{t('SkillType')}</div>
                <div className={classes.filtersBox}>
                    <div>
                        {skills && skills.length > 2 && (
                            <FormControlLabel
                                className={classes.naturalCheck}
                                control={
                                    <Checkbox
                                        color={'primary'}
                                        checked={skills?.length === filterSkillIds.length}
                                        indeterminate={
                                            filterSkillIds.length !== 0 && skills?.length !== filterSkillIds.length
                                        }
                                        indeterminateIcon={
                                            <IndeterminateCheckBoxOutlinedIcon className={classes.indeterminateColor} />
                                        }
                                        inputProps={{
                                            'aria-label': 'decorative checkbox',
                                        }}
                                        onChange={(event: any) => {
                                            const target = event.target;
                                            if (target.checked) {
                                                let newArray = skills.map((a: any) => `${a.id}`);
                                                dispatch({
                                                    type: Utils.ActionName.GET_TIMESTUDY_OPERATOR_LIST,
                                                    payload: {
                                                        filterSkillIds: newArray,
                                                    },
                                                });
                                                dispatch(getTimestudyOperator(operatorId));
                                            } else {
                                                dispatch({
                                                    type: Utils.ActionName.GET_TIMESTUDY_OPERATOR_LIST,
                                                    payload: {
                                                        filterSkillIds: [],
                                                    },
                                                });
                                                dispatch(getTimestudyOperator(operatorId));
                                            }
                                        }}
                                    />
                                }
                                label={<div>{t('All')}</div>}
                            />
                        )}
                    </div>
                    {skills && skills.length > 0 ? (
                        skills.map((skill: any) => {
                            return (
                                <FormControlLabel
                                    value={skill.id}
                                    className={classes.naturalCheck}
                                    control={
                                        <Checkbox
                                            color={'primary'}
                                            name="skill"
                                            checked={filterSkillIds.includes(`${skill.id}`)}
                                            onChange={(event: any) => {
                                                const target = event.target;
                                                if (target.checked) {
                                                    if (!filterSkillIds.includes(target.value)) {
                                                        let newArray = [...filterSkillIds];

                                                        newArray.push(target.value);
                                                        dispatch({
                                                            type: Utils.ActionName.GET_TIMESTUDY_OPERATOR_LIST,
                                                            payload: {
                                                                filterSkillIds: newArray,
                                                            },
                                                        });
                                                        dispatch(getTimestudyOperator(operatorId));
                                                    }
                                                } else {
                                                    if (filterSkillIds.includes(target.value)) {
                                                        const index = filterSkillIds.indexOf(target.value);
                                                        let newArray = [...filterSkillIds];
                                                        if (index > -1) {
                                                            newArray.splice(index, 1);
                                                        }
                                                        dispatch({
                                                            type: Utils.ActionName.GET_TIMESTUDY_OPERATOR_LIST,
                                                            payload: {
                                                                filterSkillIds: newArray,
                                                            },
                                                        });
                                                        dispatch(getTimestudyOperator(operatorId));
                                                    }
                                                }
                                            }}
                                        />
                                    }
                                    label={skill.displayName}
                                />
                            );
                        })
                    ) : (
                        <div>{t('NoRecordfound')}</div>
                    )}
                </div>
            </div>
            <Divider />
            <div>
                <div className={classes.subTitle}>{t('MCCode')}</div>
                <div className={classes.filtersBox}>
                    <div>
                        {machines && machines.length > 2 && (
                            <FormControlLabel
                                className={classes.naturalCheck}
                                control={
                                    <Checkbox
                                        color={'primary'}
                                        checked={machines?.length === filterMachineIds.length}
                                        indeterminate={
                                            filterMachineIds.length !== 0 &&
                                            machines?.length !== filterMachineIds.length
                                        }
                                        indeterminateIcon={
                                            <IndeterminateCheckBoxOutlinedIcon className={classes.indeterminateColor} />
                                        }
                                        inputProps={{
                                            'aria-label': 'decorative checkbox',
                                        }}
                                        onChange={(event: any) => {
                                            const target = event.target;
                                            if (target.checked) {
                                                let newArray = machines.map((a: any) => `${a.id}`);
                                                dispatch({
                                                    type: Utils.ActionName.GET_TIMESTUDY_OPERATOR_LIST,
                                                    payload: {
                                                        filterMachineIds: newArray,
                                                    },
                                                });
                                                dispatch(getTimestudyOperator(operatorId));
                                            } else {
                                                dispatch({
                                                    type: Utils.ActionName.GET_TIMESTUDY_OPERATOR_LIST,
                                                    payload: {
                                                        filterMachineIds: [],
                                                    },
                                                });
                                                dispatch(getTimestudyOperator(operatorId));
                                            }
                                        }}
                                    />
                                }
                                label={<div>{t('All')}</div>}
                            />
                        )}
                    </div>
                    {machines && machines.length > 0 ? (
                        machines.map((machine: any) => {
                            return (
                                <FormControlLabel
                                    value={machine.id}
                                    className={classes.naturalCheck}
                                    control={
                                        <Checkbox
                                            color={'primary'}
                                            name="machine"
                                            checked={filterMachineIds.includes(`${machine.id}`)}
                                            onChange={(event: any) => {
                                                const target = event.target;
                                                if (target.checked) {
                                                    if (!filterMachineIds.includes(target.value)) {
                                                        let newArray = [...filterMachineIds];

                                                        newArray.push(target.value);
                                                        dispatch({
                                                            type: Utils.ActionName.GET_TIMESTUDY_OPERATOR_LIST,
                                                            payload: {
                                                                filterMachineIds: newArray,
                                                            },
                                                        });
                                                        dispatch(getTimestudyOperator(operatorId));
                                                    }
                                                } else {
                                                    if (filterMachineIds.includes(target.value)) {
                                                        const index = filterMachineIds.indexOf(target.value);
                                                        let newArray = [...filterMachineIds];
                                                        if (index > -1) {
                                                            newArray.splice(index, 1);
                                                        }
                                                        dispatch({
                                                            type: Utils.ActionName.GET_TIMESTUDY_OPERATOR_LIST,
                                                            payload: {
                                                                filterMachineIds: newArray,
                                                            },
                                                        });
                                                        dispatch(getTimestudyOperator(operatorId));
                                                    }
                                                }
                                            }}
                                        />
                                    }
                                    label={machine.subType}
                                />
                            );
                        })
                    ) : (
                        <div>{t('NoRecordfound')}</div>
                    )}
                </div>
            </div>
        </div>
    );
}
