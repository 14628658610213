import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiButtonBase-root': {
                border: '1px solid #1E88E5',
                borderRadius: '4px',
                font: 'normal normal normal 16px/20px Roboto',
                letterSpacing: '0.06px',
                color: '#1E88E5',
                textTransform: 'uppercase',
                opacity: 1,
                height: '36px',
            },
        },
        rootDisable: {
            '& .MuiButtonBase-root': {
                border: '1px solid #1E88E5',
                borderRadius: '4px',
                font: 'normal normal normal 16px/20px Roboto',
                letterSpacing: '0.06px',
                color: '#1E88E5',
                textTransform: 'uppercase',
                height: '36px',
                opacity: 0.3,
            },
        },
    }),
);

interface Props {
    icon: string;
    text: string;
    onClick: any;
    isDisabled?: any;
}

export default function MultimediaButton({ icon, text, onClick, isDisabled }: Props) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={isDisabled ? classes.rootDisable : classes.root}>
            <Button
                disabled={isDisabled ? isDisabled : false}
                onClick={onClick}
                variant="outlined"
                color="primary"
                startIcon={<img src={icon} alt={t('upload')} />}
            >
                {text}
            </Button>
        </div>
    );
}
