import React from 'react';
import { makeStyles, createStyles, Theme, Button } from '@material-ui/core';
// import { useTranslation } from 'react-i18next';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        saveButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#FFFFFF',
            opacity: 1,
            height: '36px',
        },
    }),
);

const Index = ({ isSubmitting = false, disabled = false, onPress = () => {}, type = 'button', text = '' }: any) => {
    const classes = styles();
    // const { t } = useTranslation();
    return (
        <Button
            color="primary"
            size="large"
            type={type}
            variant="contained"
            disabled={disabled}
            className={classes.saveButton}
            onClick={type === 'button' ? () => onPress() : undefined}
        >
            {text}
        </Button>
    );
};
export default Index;