import { 
  Box, 
  Button, 
  Dialog, 
  DialogTitle, 
  Typography, 
  TableCell, 
  TablePagination, 
} from '@material-ui/core';
import { styled, withStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import SyncIcon from '@material-ui/icons/Sync';
import { 
  ChevronRight, 
  ChevronLeft, 
  ZoomIn, 
  ZoomOut, 
} from '@material-ui/icons';
import { colors } from './helpers';

const styles = {
  AxisViewBy: {
    axis: { stroke: '#ccc', strokeWidth: 1 },
    ticks: { strokeWidth: 0 },
    tickLabels: { fill: '#595959', fontSize: 13, fontWeight: 'bold'}
  },
  AxisVertical: {
    grid: {
      stroke: ({ tick }: any) => (tick === -10 ? 'transparent' : '#888'),
      strokeWidth: 0.2,
      strokeDasharray: '1, 5'
    },
    axis: { stroke: '#ccc', strokeWidth: 1 },
    ticks: { strokeWidth: 0 },
    tickLabels: { fill: '#595959', fontSize: 13, fontWeight: 'bold' }
  },
  AxisHorizontal: {
    axis: { stroke: '#ccc', strokeWidth: 1 },
    tickLabels: { fill: '#595959', fontSize: 13, fontWeight: 'bold' }
  },
  VictoryLineLBR: {
    data: { stroke: colors['lbr'] }
  },
  VictoryScatterLBR: {
    data: { fill: '#fff', stroke: colors['lbr'], strokeWidth: 2 }
  },
  VictoryLineEFF: {
    data: { stroke: colors['eff'] }
  },
  VictoryScatterEFF: {
    data: { fill: '#fff', stroke: colors['eff'], strokeWidth: 2 }
  }
};

const LBRCustomZoomInButton = styled(ZoomIn)(({
  color: '#777',
  cursor: 'pointer',
  width: 28,
  height: 28,
}));
const LBRCustomZoomOutButton = styled(ZoomOut)(({
  color: '#777',
  cursor: 'pointer',
  width: 28,
  height: 28,
}));

const LBRCustomPreviousButton = styled(ChevronLeft)(({ disabled = true }: any) => ({
  position: 'absolute', 
  left: -5, 
  top: '30%', 
  transform: 'translateY(50%)',
  zIndex: 2,
  borderRadius: 40,
  background: '#fff',
  color: '#727272',
  boxShadow: '0px 2px 3px #00000014',
  border: '1px solid #DFDFDF',
  padding: 6,
  cursor: 'pointer',
  ...(disabled && {
    opacity: .5,
    pointerEvents: 'none',
  }),
}));

const LBRTableHeadCell = styled(TableCell)(({
  paddingTop: 10,
  paddingBottom: 10,
  fontSize: 13,
}));

const LBRTableCellLink = styled(TableCell)(({ isGreen = false, isRed = false }: any) => ({
  paddingTop: 10,
  paddingBottom: 10,
  color: 'blue',
  cursor: 'pointer',
}));

const LBRTableCell = styled(TableCell)(({ isGreen = false, isRed = false }: any) => ({
  paddingTop: 10,
  paddingBottom: 10,
  ...(isGreen && {
    color: 'green'
  }),
  ...(isRed && {
    color: 'red'
  }),
}));

const LBRTableCellPercentage = withStyles({
  root: ({
    sameMinMaxValue,
    isHightest,
    isLowest,
  }: any) => ({
    color: sameMinMaxValue
      ? '#000000de'
      : isHightest 
        ? 'green'
        : isLowest
          ? 'red'
          : '#000000de'
  })
})(TableCell);

const LBRTablePagination = styled(TablePagination)(({

}));

const LBRCustomNextButton = styled(ChevronRight)(({ disabled = false }: any) =>({
  position: 'absolute', 
  right: -5, 
  top: '30%', 
  transform: 'translateY(50%)',
  zIndex: 2,
  padding: 6,
  borderRadius: 40,
  background: '#fff',
  color: '#727272',
  boxShadow: '0px 2px 3px #00000014',
  border: '1px solid #DFDFDF',
  cursor: 'pointer',
  ...(disabled && {
    opacity: .5,
    pointerEvents: 'none',
  }),
}));

const LBRChartContainer = styled(Box)({
  background: '#fff',
  padding: 20,
  borderRadius: 0,
  marginBottom: 10,
});

const LBRTableContainer = styled(Box)({
  background: '#fff',
  padding: 20,
  borderRadius: 0,
  marginBottom: 10,
});

const LBRFlexWrapContainer = styled(Box)({
  display: 'flex',
  flexFlow: 'wrap',
  gap: '5px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  alignCntent: 'center',
  flex: 1,
});

const LBRFlexContainer = styled(Box)({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
  justifyContent: 'flex-start',
});

const LBRFlexCenterContainer = styled(Box)({
  display: 'flex',
  gap: 20,
  alignItems: 'center',
  justifyContent: 'space-between',
  '& div::last-child': {
    marginLeft: 'auto'
  }
});

const LBRDialog = styled(Dialog)({
  padding: 20,
  '& ::-webkit-scrollbar': {
    display: 'block',
    width: '5px',
    height: '5px'
  },
  '& ::-webkit-scrollbar-thumb': {
    borderRadius: '0',
    background: '#888',
  }
});

const LBRDialogHeaderContainer = styled(DialogTitle)({
  background: '#fff',
  padding: '20px 20px 10px 20px',
  boxShadow: '0px 0px 6px #666',
});

const LBRCloseButton = styled(CancelIcon)({
  color: '#b30f0f',
  cursor: 'pointer',
  width: '2rem',
  height: '2rem'
});

const LBRCustomSelectButton = styled(Button)({
  minWidth: 150,
  display: 'flex',
  justifyContent: 'space-between'
});

const LBRLabel = styled(Typography)(({ color = '#000' }: any) => ({
  color: color,
  fontSize: 12,
  textTransform: 'uppercase',
}));

const LBRButtonDateSelected = styled('span')({
  padding: '0 30px 0px 10px',
  height: '25px',
  fontSize: 14,
  lineHeight: '25px',
  background: '#1E88E5',
  color: '#fff',
  borderRadius: 3,
  position: 'relative',
  cursor: 'pointer',
  '&::before': {
    position: 'absolute',
    right: 15,
    top: 7.5,
    content: '" "',
    height: '10px',
    width: '2px',
    backgroundColor: '#fff',
    transform: 'rotate(45deg)',
    borderRadius: 5,
  },
  '&::after': {
    position: 'absolute',
    right: 15,
    top: 7.5,
    content: "' '",
    height: '10px',
    width: '2px',
    backgroundColor: '#fff',
    transform: 'rotate(-45deg)',
    borderRadius: 5,
  },
});

const LBRDateInformationContainer = styled('div')({
  display: 'flex',
  gap: 20,
});

const LBRDateInformationText = styled('span')(({ isBlue }: any) => ({
  color: '#999',
  ...(isBlue && {
    color: '#1689FA',
  }),
  fontSize: 14,
}));

const LBRLoadingButton = styled(SyncIcon)(({ isLoading }: any) => ({
  ...(isLoading && {
    animationName: 'spin',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  }),
}));

const LBRCustomTooltipContainer = styled(Box)(({ isLastPoint = false }: any) => ({
  width: 200, 
  backgroundColor: 'rgba(0,0,0, .8)', 
  padding: 15, 
  borderRadius: 4,
  position: 'relative',
  border: '1px solid transparent',
  '&::after': {
    position: 'absolute',
    top: '44%',
    content: '" "',
    width: 0,
    height: 0,
    borderTop: 'solid 10px transparent',
    borderBottom: 'solid 10px transparent',
    ...(isLastPoint ? { 
      borderLeft: 'solid 10px rgba(0,0,0, .8)',
      right: 0,
      transform: 'translateX(10px)',
    } : {
      borderRight: 'solid 10px rgba(0,0,0, .8)',
      left: 0,
      transform: 'translateX(-10px)',
    }),
  },
}));

const LBRCustomTooltipTitle = styled(Typography)({
  fontSize: 14,
  letterSpacing: '0.35px',
  fontWeight: 'bold',
  color: '#fff',
});

const LBRCustomTooltipSubTitle = styled(Typography)({
  fontSize: 14,
  letterSpacing: '0.35px',
  color: '#fff',
  marginBottom: 10,
});

const LBRCustomTooltipText= styled(Typography)({
  fontSize: 12,
  letterSpacing: '0.35px',
  color: '#fff',
});

const LBRCustomTooltipDivider= styled(Box)({
  display: 'block',
  width: '100%',
  height: 2,
  background: '#fff',
});

export {
  LBRTableCellLink,
  LBRTableCellPercentage,
  LBRCustomTooltipDivider,
  LBRCustomTooltipContainer,
  LBRCustomTooltipTitle,
  LBRCustomTooltipSubTitle,
  LBRCustomTooltipText,
  LBRCustomZoomInButton,
  LBRCustomZoomOutButton,
  LBRFlexWrapContainer,
  LBRButtonDateSelected,
  LBRTableHeadCell,
  LBRTableCell,
  LBRCustomNextButton,
  LBRCustomPreviousButton,
  LBRLoadingButton,
  LBRDateInformationContainer,
  LBRDateInformationText,
  LBRLabel,
  LBRCustomSelectButton,
  LBRChartContainer,
  LBRFlexCenterContainer,
  LBRFlexContainer,
  LBRDialogHeaderContainer,
  LBRDialog,
  LBRCloseButton,
  LBRTableContainer,
  LBRTablePagination,
  styles,
};
