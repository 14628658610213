
import * as React from 'react';
import { 
    TableRow, 
    TableHead, 
    TableSortLabel, 
    Checkbox,
} from '@material-ui/core';
import { tableHeaderCell } from '../constant';
import { DataTableProp, TableHeaderCellProp } from '../../../Interfaces';
import { LBRTableHeadCell } from '../../../styles';

interface TableHeaderProp {
    onChangeOrder: (orderBy: keyof DataTableProp) => void;
    orderDirection: 'asc' | 'desc';
    orderBy: keyof DataTableProp;
    onSelectAllClick: () => void;
    rowCount: number;
    numSelected: number;
}

const TableHeader: React.FC<TableHeaderProp> = ({
    onChangeOrder,
    orderBy,
    orderDirection,
    rowCount,
    numSelected,
    onSelectAllClick,
}) => {

    return (
        <TableHead>
            <TableRow style={{ background: '#00000014' }}>
                <LBRTableHeadCell padding="checkbox">
                    <Checkbox
                        color='primary'
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                    />
                </LBRTableHeadCell>
                {tableHeaderCell?.map((cell: TableHeaderCellProp) => (
                    <React.Fragment>
                        <LBRTableHeadCell
                            key={cell.id}
                            align={cell.align}
                            padding={cell.disablePadding}
                            sortDirection={orderBy === cell.id ? orderDirection : false}
                        >
                            <TableSortLabel
                                disabled={cell.disableOrder}
                                active={orderBy === cell.id}
                                direction={orderBy === cell.id ? orderDirection : 'asc'}
                                onClick={() => onChangeOrder(cell.id)}
                            >
                                {cell.label}
                            </TableSortLabel>
                        </LBRTableHeadCell>
                    </React.Fragment>
                ))}
            </TableRow>
        </TableHead>
    )
}

export default TableHeader;