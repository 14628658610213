import React, { useRef, useState } from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    Typography,
    Button,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import Schema from '../../../schema';
import { useTranslation } from 'react-i18next';
import NormalInput from '../../../components/inputs/normalInput';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModel } from '../../../model';
import { editPpa } from '../action';
import moment from 'moment';
import OutlineInput from '../../../components/inputs/outlineInput';
import { addMGM, updateMGM } from './action';
import LocalImages from '../../../utils/images';
import Utils from '../../../utils';
import SimpleModal from '../../../components/modal';
import Dropzone from 'react-dropzone';
// @ts-ignore
import PublishIcon from '@material-ui/icons/Publish';
import { uploadImage } from '../../manageStyle/action';
const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            '& .MuiTextField-root': {
                marginBottom: '10px!important',
            },
        },
        textStyl: {
            fontSize: '20px',
            color: 'var(--dark-grey)',
            margin: '44px 0 0 0',
        },
        paper: {
            position: 'absolute',
            width: '25%',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            overflow: 'scroll',
            maxHeight: '80vh',
        },
        saveContainer: {
            backgroundColor: 'white',
            padding: '10px 0',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '25px',
        },
        cancelButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#515151',
            opacity: 1,
            height: '36px',
        },
        saveButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#FFFFFF',
            opacity: 1,
            height: '36px',
        },
        subHeading: {
            textAlign: 'left',
            font: 'normal normal normal 12px/21px Roboto',
            letterSpacing: '0.07px',
            color: '#8C8C8C',
            opacity: 1,
            marginBottom: '3px',
        },
        author: {
            marginTop: '15px',
        },
        statusContainer: {
            display: 'flex',
            alignItems: 'center',
            '& .MuiFormGroup-root': { flexDirection: 'row' },
            '& .MuiTypography-root': {
                textAlign: 'left',
                font: 'normal normal normal 14px/21px Roboto',
                letterSpacing: '0.08px',
                color: '#515151',
                opacity: 1,
            },
        },
        status: {
            marginRight: '40px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
        styleImageTitle: {
            marginBottom: '10px',
            marginTop: '25px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#000000',
            opacity: 1,
        },
        stylesContainer: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        styleImageContainer: {
            position: 'relative',
            marginRight: '20px',
        },
        styleImageBG: {
            width: '96px',
            height: '96px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        deleteStyle: {
            position: 'absolute',
            top: '2%',
            right: '2%',
            cursor: 'pointer',
            height: '24px',
            width: '24px',
        },
        thumbnailPopup: {
            position: 'absolute',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: '30px',
            width: 'auto',
            '& img': {
                maxHeight: '70vh',
                width: 'auto',
            },
        },
        dropImagesContainer: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            border: '2px dashed rgb(112, 112, 112, 0.6)',
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '8%',
        },
        dropDescription: {
            textAlign: 'center',
            font: 'normal normal bold 16px/28px Roboto',
            letterSpacing: '0px',
            color: '#515151',
            opacity: 1,
        },
        browseButton: {
            margin: '0 15px',
            minWidth: '77.3%',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#FFFFFF',
            opacity: 1,
            height: '36px',
        },
        imageNote: {
            marginTop: '10px',
            textAlign: 'center',
            font: 'normal normal normal 14px/28px Roboto',
            letterSpacing: '0px',
            color: '#515151',
            opacity: 1,
        },
        orBox: {
            textAlign: 'center',
            font: 'normal normal normal 14px/28px Roboto',
            letterSpacing: '0px',
            color: '#515151',
            opacity: 1,
            marginBottom: '6px',
        },
        required: {
            color: '#D32F2F',
        },
    }),
);

export default function AddMDMForm({ handleClose }: any) {
    const classes = styles();
    const { t } = useTranslation();
    const {
        isEditPpA,
        ppaName,
        descEdit,
        idEdit,
        selectedisActive,
        createdBy,
        updatedBy,
        updatedDt,
        createdDt,
        selectedType,
        displayName,
        hostedImages,
        firstLangDataEdit,
        secondLangDataEdit,
    } = useSelector((state: ReducersModel) => state.ppaCodeReducer);
    const { firstLangDesc, secondLangDesc } = useSelector((state: ReducersModel) => state.globalLangDataReducer);

    const [openModalOne, setOpenModalOne] = useState(false);
    const [imageModal, setModalImage] = useState('');
    const [localImage, setLocalImage] = useState<Array<File>>([]);
    const [newImage, setNewImage] = useState(isEditPpA ? false : true);

    const dispatch = useDispatch();

    function getModalStyle() {
        const top = 50;
        const left = 50;
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const [modalStyle] = React.useState(getModalStyle);

    const removeImage = (index: number) => {
        setLocalImage([]);
        setNewImage(true);
        dispatch({
            type: Utils.ActionName.UPDATE_PPA_CODE,
            payload: {
                hostedImages: [],
            },
        });
    };

    const handleFile = (e: any) => {
        const { files } = e.target;
        setLocalImage(e.target.files);

        let tempHostedimages = [...hostedImages];
        if (tempHostedimages.length + e.target.files.length > 1) {
            alert(t('PleaseSelectOneMax'));
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const hostedImage: any = [];

        for (let i = 0; i < files.length; i += 1) {
            if (
                e.target.files[i] &&
                (e.target.files[i].type === 'image/png' ||
                    e.target.files[i].type === 'image/jpg' ||
                    e.target.files[i].type === 'image/jpeg')
            ) {
                hostedImage.push(files[i]);
            }
        }
        if (hostedImage.length > 0) dispatch(uploadImage(hostedImage, 1, 3));
    };

    const droppedFiles = (acceptedFiles: any, rejectedFiles: any) => {
        if (acceptedFiles.length > 1) {
            alert(t('PleaseSelectOneMax'));
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        if (rejectedFiles.length > 0) {
            Utils.showAlert(2, 'Drop only .png or .jpg files');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
        }
        const hostedImage: any = [];

        for (let i = 0; i < acceptedFiles.length; i++) {
            if (
                acceptedFiles[i] &&
                (acceptedFiles[i].type === 'image/png' ||
                    acceptedFiles[i].type === 'image/jpg' ||
                    acceptedFiles[i].type === 'image/jpeg')
            ) {
                hostedImage.push(acceptedFiles[i]);
                setLocalImage(acceptedFiles);

                if (hostedImage.length > 0) dispatch(uploadImage(hostedImage, 1, 3));
            } else {
                Utils.showAlert(2, 'Drop only .png or .jpg files');
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            }
        }
    };
    const uploadButtonRef = useRef<any>(null);

    const imageDropZone = () => {
        return (
            <div>
                <div className={classes.styleImageTitle}>{t('Attachement')}</div>
                <div className={classes.stylesContainer}>
                    {(localImage && localImage.length > 0) || hostedImages[0] ? (
                        <div className={classes.styleImageContainer}>
                            <div
                                onClick={() => {
                                    setOpenModalOne(true);
                                    setModalImage(hostedImages[0]);
                                }}
                            >
                                <img
                                    className={classes.styleImageBG}
                                    src={isEditPpA && !newImage ? hostedImages[0] : URL.createObjectURL(localImage[0])}
                                    alt={''}
                                />
                            </div>
                            <img
                                src={LocalImages.CANCEL}
                                alt={t('Close')}
                                className={classes.deleteStyle}
                                onClick={() => removeImage(0)}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <SimpleModal
                    handleOpen={() => {
                        setOpenModalOne(true);
                    }}
                    isOpen={openModalOne}
                    body={
                        <div className={classes.thumbnailPopup} style={modalStyle}>
                            <img src={imageModal} alt={t('Styles')} />
                            <img
                                src={LocalImages.CANCEL}
                                alt={t('Close')}
                                className={classes.deleteStyle}
                                onClick={() => setOpenModalOne(false)}
                            />
                        </div>
                    }
                />
                {localImage.length === 0 && !hostedImages[0] ? (
                    <React.Fragment>
                        <Dropzone
                            onDrop={(acceptedFiles, rejectedFiles) => {
                                droppedFiles(acceptedFiles, rejectedFiles);
                            }}
                            accept={['.jpg', '.png', '.jpeg']}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div className={classes.dropImagesContainer} {...getRootProps()}>
                                    <div>
                                        <PublishIcon />
                                    </div>
                                    <div className={classes.dropDescription}>Drop your .png or .jpg files here!</div>
                                    <div className={classes.orBox}>or</div>
                                    <input
                                        {...getInputProps()}
                                        type="file"
                                        id="styleImages"
                                        onChange={handleFile}
                                        style={{ display: 'none' }}
                                        ref={uploadButtonRef}
                                        accept=".png, .jpg, .jpeg"
                                        multiple
                                    />
                                    <Button
                                        color="primary"
                                        size="large"
                                        variant="contained"
                                        className={classes.browseButton}
                                        onClick={() => {
                                            uploadButtonRef.current.click();
                                        }}
                                    >
                                        {t('Browse')}
                                    </Button>
                                </div>
                            )}
                        </Dropzone>
                        <div className={classes.imageNote}>{t('UptoImage')}</div>
                    </React.Fragment>
                ) : (
                    ''
                )}
            </div>
        );
    };

    return (
        <div style={modalStyle} className={classes.paper}>
            <div className={classes.mainContainer}>
                <Typography className={classes.textStyl}>
                    {isEditPpA ? `${t('Edit')} ` : `${t('Add')} `}
                    {selectedType === 2
                        ? t('ProductType')
                        : selectedType === 3
                        ? t('MachinesType')
                        : selectedType === 4
                        ? t('NEEDLETYPE')
                        : selectedType === 6
                        ? t('Machines')
                        : t('SkillType')}
                </Typography>
                <div>
                    <Formik
                        initialValues={{
                            nameType: isEditPpA ? ppaName : '',
                            displayName: isEditPpA ? displayName : '',
                            description: isEditPpA ? descEdit : '',
                            isActive: `${selectedisActive}`,
                            firstLang: isEditPpA ? firstLangDataEdit : '',
                            secondLang: isEditPpA ? secondLangDataEdit : '',
                        }}
                        validationSchema={Schema.MDMSchema(t, selectedType)}
                        onSubmit={(values, { setSubmitting }) => {
                            if (isEditPpA) {
                                if (selectedType === 1) {
                                    dispatch(editPpa(values, setSubmitting, handleClose, idEdit));
                                } else {
                                    dispatch(updateMGM(values, setSubmitting, handleClose));
                                }
                            } else {
                                dispatch(addMGM(values, setSubmitting, handleClose));
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                        }) => (
                            <Form>
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.nameType}
                                    onChange={handleChange}
                                    helperText={touched.nameType && errors.nameType}
                                    error={Boolean(touched.nameType && errors.nameType)}
                                    name={'nameType'}
                                    label={
                                        selectedType === 4
                                            ? t('NeedlesTypeCode')
                                            : selectedType === 5
                                            ? t('SkillTypeCode')
                                            : selectedType === 3
                                            ? t('AttachmentCode')
                                            : selectedType === 6
                                            ? t('MachineCode')
                                            : t('ProductTypeCode')
                                    }
                                    isRequired={true}
                                    isDisabled={isEditPpA 
                                        ? true 
                                        : false
                                    }
                                />

                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.displayName}
                                    onChange={handleChange}
                                    helperText={touched.displayName && errors.displayName}
                                    error={Boolean(touched.displayName && errors.displayName)}
                                    name={'displayName'}
                                    label={t('DisplayName')}
                                    isRequired={true}
                                    placeholder={
                                        selectedType === 4
                                            ? t('NeedlesType')
                                            : selectedType === 5
                                            ? t('SkillType')
                                            : selectedType === 3
                                            ? t('MachinesType')
                                            : selectedType === 6
                                            ? t('MachineType')
                                            : t('ProductType')
                                    }
                                />

                                <OutlineInput
                                    values={values.firstLang}
                                    onChange={handleChange}
                                    name={'firstLang'}
                                    translation={true}
                                    translationHandle={() =>
                                        // translate(
                                        //     bay.name,
                                        //     Utils.constants.firstLanguage,
                                        //     'firstLang',
                                        //     index,
                                        //     'firstLangIsGoogle',
                                        // )
                                        console.log('heere')
                                    }
                                    helperText={touched.firstLang && errors.firstLang}
                                    error={Boolean(touched.firstLang && errors.firstLang)}
                                    label={`${t('DisplayName')} (${firstLangDesc} Translation)`}
                                    isRequired={false}
                                    placeholder={t('AddTranslatedText')}
                                />
                                <OutlineInput
                                    values={values.secondLang}
                                    name={'secondLang'}
                                    onChange={handleChange}
                                    translation={true}
                                    translationHandle={() =>
                                        // translate(
                                        //     bay.name,
                                        //     Utils.constants.secondLanguage,
                                        //     'secondLang',
                                        //     index,
                                        //     'secondLangIsGoogle',
                                        // )
                                        console.log('heere')
                                    }
                                    helperText={touched.secondLang && errors.secondLang}
                                    error={Boolean(touched.secondLang && errors.secondLang)}
                                    label={`${t('DisplayName')} (${secondLangDesc} Translation)`}
                                    isRequired={false}
                                    placeholder={t('AddTranslatedText')}
                                />
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.description}
                                    onChange={handleChange}
                                    helperText={touched.description && errors.description}
                                    error={Boolean(touched.description && errors.description)}
                                    name={'description'}
                                    label={t('DESCRIPTION')}
                                    isRequired={false}
                                />

                                {selectedType === 3 ? imageDropZone() : ''}
                                {isEditPpA && (
                                    <>
                                        <div className={classes.statusContainer}>
                                            <span className={classes.status}>{t('Status')}: </span>
                                            <RadioGroup
                                                id="isActive"
                                                value={values.isActive}
                                                onChange={(event: any) => {
                                                    setFieldValue('isActive', `${event.target.value}`);
                                                }}
                                            >
                                                <FormControlLabel
                                                    value={`true`}
                                                    control={<Radio color="primary" />}
                                                    label={t('Active')}
                                                />
                                                <FormControlLabel
                                                    value={`false`}
                                                    control={<Radio color="primary" />}
                                                    label={t('Inactive')}
                                                />
                                            </RadioGroup>
                                        </div>

                                        <div className={classes.author}>
                                            <div className={classes.subHeading}>
                                                {t('MODIFIEDBY')}: {updatedBy ? updatedBy : '-'}
                                            </div>
                                            <div className={classes.subHeading}>
                                                {t('DATEMODIFIED')}:{' '}
                                                {updatedDt !== null ? moment(updatedDt).format('YYYY-MM-DD') : '-'}
                                            </div>
                                            <div className={classes.subHeading}>
                                                {t('Createdby')}: {createdBy ? createdBy : '-'}
                                            </div>
                                            <div className={classes.subHeading}>
                                                {t('DateCreated')}:{' '}
                                                {createdDt ? moment(createdDt).format('YYYY-MM-DD') : '-'}
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div className={classes.saveContainer}>
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        className={classes.cancelButton}
                                        onClick={handleClose}
                                    >
                                        {t('CANCEL')}
                                    </Button>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className={classes.saveButton}
                                    >
                                        {isSubmitting ? `${t('Saving')}...` : t('Save')}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}
