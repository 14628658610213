class LoadingModel {
    isLoading: boolean = true;
    mainLoading: boolean = false;
    isTimeOut: boolean = false;
}

class LoadingFilterModel {
    isFilterLoading: boolean = true;
}

class signInModel {
    username: any = localStorage.getItem('username') === null ? '' : localStorage.getItem('username');
    password: any = '';
    accountError: string = '';
    rememberMe: boolean = localStorage.getItem('rememberMe') === null ? false : true;
    isTimeOut: boolean = false;
}
class lineSetuoModel {
    lineSetupId: number = 0;
    validationForn: boolean = false;
    openInfo: boolean = true;
    line: string = '';
    dateStart: string = '';
    target: string = '';
    quantity: string = '';
    duplicate: boolean = false;
    overlapping: boolean = false;
    approved: boolean = false;
    postLineSetupData: any = {};
    formLineSetupData: any = {};
    message: string = '';
}
class LineSetupInfo {
    infoData: any = [];
    shiftInfoData: any = [];
}
class LineSetupAssistInfo {
    infoData: any = [];
    totalSmv: number = 0;
    totalTct: number = 0;
    lbr: number = 0;
    isUpdated: boolean = false;
    updatedAt: string = new Date().toISOString();
}
class OperatorInPool {
    opeeratorData: any = [];
}

class styleListModel {
    count: number = 0;
    page: number = 1;
    limit: number = 10;
    data: any = [];
    search: string = '';
    sortBy: string = '';
    order: string = '';
    filterCustomerIds: any = [];
    filterFactoryIds: any = [];
    filterStatus: any = ['true'];
    filterSeasonIds: any = [];
    filterProductTypeIds: any = [];
}

class LineBalanceModel {
    isLbBalance: boolean = false;
}
class seasonListModel {
    count: string = '';
    page: number = 1;
    limit: number = 10;
    seasons: any = [];
    selectedSeason: any = '';
    selectedSeasonId: any = '';
}

class productTypeListModel {
    count: string = '';
    page: number = 1;
    limit: number = 10;
    productTypes: any = [];
    selectedProduct: any = '';
    selectedProductId: any = '';
}

class linesSetupModel {
    count: string = '';
    page: number = 1;
    limit: number = 10;
    lines: any = [];
    operationAndStation: any = [];
    totalSmv: number = 0;
    totalTct: number = 0;
    theoManPowerData: any = [];
    stationCycleTimeValues: any = [];
    operatorAssign: any = [];
    lineSetupId: number = 0;
    lineSetupmanpower: number = 0;
    lineSetupData: any = [];
}

class linesSetupModelStationWiseReplicaModel {
    count: string = '';
    page: number = 1;
    limit: number = 10;
    lines: any = [];
    operationAndStation: any = [];
    totalSmv: number = 0;
    theoManPowerData: any = [];
    stationCycleTimeValues: any = [];
    operatorAssign: any = [];
    lineSetupId: number = 0;
    lineSetupmanpower: number = 0;
    lineSetupData: any = [];
}

class shiftsModel {
    count: string = '';
    page: number = 1;
    limit: number = 10;
    shifts: any = [];
    currentActiveShift: any = {};
}

class customersListModel {
    count: string = '';
    page: number = 1;
    limit: number = 100;
    customers: any = [];
    selectedCustomer: any = '';
    selectedCustomerId: any = '';
}
class LineSetupHistoryTable {
    count: number = 0;
    page: number = 1;
    limit: number = 10;
    historyData: any = [];
    search: string = '';
    sortBy: string = '';
    order: string = '';
    filterCustomerIds: any = [];
    filterFactoryIds: any = [];
    filterLineIds: any = [];
    filterShiftIds: any = [];
    filterStatus: any = ['true'];
    filterStatusHistory: any = [];
}

class CustomerModel {
    customerData: any = [];
}

class NewStyleModel {
    styleName: string = '';
    styleCode: string = '';
    description: string = '';
    image1: string = '';
    image2: string = '';
    image3: string = '';
    styleIdAfterAdding: string = '';
    hostedImages: any = [];
    productTypeId: any = '';
}

class operationCodeListModel {
    count: string = '';
    page: number = 1;
    limit: number = 100;
    operationCodes: any = [];
    selectedOperationCode: any = '';
    selectedOperationCodeId: any = '';
    filterFactoryIds: any = [];
}

class machineListModel {
    count: string = '';
    page: number = 1;
    limit: number = 100;
    machines: any = [];
    selectedMachine: any = '';
    selectedMachineId: any = '';
}

class needleListModel {
    count: string = '';
    page: number = 1;
    limit: number = 10;
    needlesTypes: any = [];
    selectedNeedle: any = '';
    selectedNeedleId: any = '';
}

class NewOperationModel {
    styleName: string = '';
    styleCode: string = '';
    description: string = '';
    image1: string = '';
    image2: string = '';
    image3: string = '';
}

class styleDetailsModel {
    id: string = '';
    brand: string = '';
    season: string = '';
    desc1: string = '';
    desc2: string = '';
    image1: string = '';
    image2: string = '';
    image3: string = '';
    createdBy: string = '';
    createdDt: string = '';
    updatedBy: string = '';
    updatedDt: string = '';
    code: string = '';
    style: string = '';
    customerId: string = '';
    seasonId: string = '';
    customer: string = '';
    createdById: string = '';
    isSaveas: boolean = false;
    sam: string = '';
    totalSmv: string = '';
    title: string = '';
    editHostedImages: any = [];
    productTypeId: any = '';
    productType: string = '';
    isActive: boolean = false;
}

class SkillModel {
    count: string = '';
    page: number = 1;
    limit: number = 100;
    skillData: any = [];
    operatorId: number = 1;
    duplicateRecord: string = '';
    totalRecord: string = '';
    nameOfFile: string = '';
    wholeFile: any;
    isOperatorChange: boolean = false;
    selectedId: any = '';
    selectedOperatorId: any = '';
    selectedOperation: any = '';
    selectedMacSubTypeId: any = '';
    selectedEff: any = '';
    selectedOperationCode: any = '';
    selectedDescription: any = '';
}
class operatorModel {
    count: number = 0;
    page: number = 1;
    limit: number = 10;
    operatorData: any = [];
    searchOperator: string = '';
    sortBy: string = '';
    order: string = '';
    selectedOperatorId: string = '';
    selectedLineId: string = '';
    selectedLine: string = '';
    selectedShiftId: string = '';
    selectedShift: string = '';
    selectedOperatorName: string = '';
    selectedCardId: string = '';
    selectedisActive: boolean = false;
    createdBy: any = '';
    updatedBy: any = '';
    updatedDt: any = '';
    createdDt: any = '';
    selectedLineSetupId: any = '';
    filterLineIds: any = [];
    filterShiftIds: any = [];
    filterStatus: any = ['true'];
    selectedEmployeeId: string = '';
    operatorImage: string = '';
    description: string = '';
    activeLine: string = '';
}
class sewingOperationModel {
    count: string = '';
    page: number = 1;
    limit: number = 100;
    sewingData: any = [];
    featureWiseOperations: any = null;
    totalSmv: number = 0;
    totalTct: number = 0;
    editActive: boolean = false;
    reorder: boolean = false;
    postReorderRequest: boolean = false;
    defaultDataOfStyle: any = [];
}

class ppaOperationModel {
    count: string = '';
    page: number = 1;
    limit: number = 100;
    PPAData: any = [];
}

class mfgLinesModel {
    count: string = '';
    page: number = 1;
    limit: number = 100;
    mfgLines: any = [];
}

class sewingOperationForAddStyleModel {
    count: string = '';
    page: number = 1;
    limit: number = 100;
    sewingData: any = [];
    operationIdToDelete: any = '';
    styleIdOfOperationToDelete: any = '';
    //edit operation keys
    selectedOperationcode: string = '';
    selectedDescription: string = '';
    selectedFrequency: string = '';
    selectedMachine: string = '';
    selectedNeedleType: string = '';
    selectedSmv: string = '';
    selectedTct: number = 0;
    selectedMachineName: string = '';
    selectedNeedleTypeName: string = '';
    selectedOperatorId: string = '';
    listIsReordered: boolean = false;
    selectedOperationCodeId: string = '';
    selectedOperationCodeIndex: number = -1;
    selectedOperationStep: number = 1;
    selectedOperation: any = '';
}

class operationCodeModel {
    count: number = 0;
    page: number = 1;
    limit: number = 10;
    operationCodeData: any = [];
    isEditOperation: boolean = false;
    operationCodeEdit: string = '';
    displayNameEdit: string = '';
    firstLangDataEdit: string = '';
    secondLangDataEdit: string = '';
    smvEdit: string = '';
    tctEdit: number = 0;
    descEdit: string = '';
    selectedSkillType: any = '';
    selectedSkillTypeName: string = '';
    selectedSkillTypeCode: string = '';
    selectedMachineId1: number = 0;
    selectedMachineId2: number = 0;
    selectedMachineId3: number = 0;
    idEdit: number = 0;
    searchOperation: string = '';
    sortBy: string = '';
    order: string = '';
    filterFactoryIds: any = [];
    filterSkillIds: any = [];
    selectedisActive: boolean = false;
    createdBy: any = '';
    updatedBy: any = '';
    updatedDt: any = '';
    createdDt: any = '';
    factoryName: any = '';
    filterStatus: any = ['true'];
}
class ppaCodeModel {
    countPpa: number = 0;
    pagePpa: number = 1;
    limitPpa: number = 10;
    ppaCodeData: any = [];
    skillTypeData: any = [];
    idEdit: number = 0;
    descEdit: string = '';
    ppaName: string = '';
    displayName: string = '';
    isEditPpA: boolean = false;
    sortBy: string = 'id';
    order: string = 'DESC';
    selectedisActive: boolean = false;
    createdBy: any = '';
    updatedBy: any = '';
    updatedDt: any = '';
    createdDt: any = '';
    filterStatus: any = ['true'];
    selectedType: number = 1;
    searchMasterData: string = '';
    isActiveMDM: boolean = true;
    isInActiveMDM: boolean = false;
    filterCount: number = 0;
    hostedImages: any = [];
    firstLangDataEdit: string = '';
    secondLangDataEdit: string = '';
}
class ThemeModel {
    selectTheme: any = localStorage.getItem('selectTheme') === null ? 'light' : localStorage.getItem('selectTheme');
}

class factoriesListModel {
    count: string = '';
    page: number = 1;
    limit: number = 100;
    factoriesData: any = [];
}
class StationModel {
    stationData: any = [];
    totalOperator: any = {};
}

class lineSetuoReplicaModel {
    count: string = '';
    page: number = 1;
    limit: number = 10;
    lines: any = [];
    operationAndStationReplica: any = [];
    totalSmv: number = 0;
    theoManPowerData: any = [];
}

class searchOperatorInPoolModel {
    count: number = 0;
    page: number = 1;
    limit: number = 10;
    searchOperatorList: any = [];
    addOperatorsInPoolIds: any = [];
    searchOperatorText: string = '';
    loadingData: boolean = false;
    removedOperators: any = [];
}
class linesSetupDataModel {
    lineSetup: any = [];
    ppaVal: any = [];
    editPPaArray: any = [];
    lbr: number = 0;
    ppaValList: any = [];
    tempArray: any = [];
    checkPPA: any = [];
    once: boolean = true;
    lbrOperation: any = [];
    onceLBR: boolean = true;
    tickValueLbr: any = [];
    opertionSum: number = 0;
    grapghValSelect: any = {};
    finalLbr: number = 0;
    disableChart: boolean = false;
    changeLBRVAl: number = 0;
    targetEFF: number = 0;
    workingMinutes: number = 0;
    sewingOperators: any = [];
}
class linesAssistTable {
    lineSetup: any = [];
    ppaVal: any = [];
    lbr: number = 0;
    ppaValList: any = [];
    tempArray: any = [];
    LBRTgt: number = 0;
    checkPPA: any = [];
    openReport: boolean = false;
    lbrOperation: any = [];
    finalLbr: number = 0;
    disableChart: boolean = false;
    changeLBRVAl: number = 0;
}
class linesAssistReport {
    reportData: any = {};
    stepsData: any = [];
    tableData: any = [];
}
class nptCategoryListModel {
    count: number = 0;
    page: number = 1;
    limit: number = 10;
    nptCategoriesData: any = [];
    searchNpt: string = '';
    sortBy: string = '';
    order: string = '';
    filterStatus: any = ['true'];
}

class nptListModel {
    count: number = 0;
    page: number = 1;
    limit: number = 10;
    nptData: any = [];
    searchNpt: string = '';
    sortBy: string = '';
    order: string = '';
    filterLineIds: any = [];
    filterShiftIds: any = [];
    filterNptTypeIds: any = [];
    selectedEditNptId: any = '';
    selectedEditDate: any = '';
    selectedEditStartTime: any = '';
    selectedEditEndTime: any = '';
    selectedEditManpower: any = '';
    selectedEditLineId: any = '';
    selectedEditShiftId: any = '';
    selectedEditNptCategoryId: any = '';
    selectedEditNptTypeId: any = '';
    selectedEditNptTypeData: any = [];
}

class nptTypeListModel {
    count: number = 0;
    page: number = 1;
    limit: number = 10;
    nptTypeData: any = [];
    searchNptType: string = '';
    sortBy: string = '';
    order: string = '';
    createdDt: any = '';
    desc1: string = '';
    updatedDt: any = '';
    updatedBy: any = '';
    createdBy: any = '';
    nptCat: string = '';
    isActive: boolean = false;
    selectedNptId: any = '';
    selectedDisplayName: string = '';
    selectedDescription: string = '';
    selectedIsActive: boolean = false;
    selectedUpdatedDt: any = '';
    selectedUpdatedBy: any = '';
    selectedCreatedBy: any = '';
    selectedCreatedDt: any = '';
}

class allNptTypeListModel {
    allNptTypeData: any = [];
}
class DashboardModel {
    dashboardData: any = [];
    filterVal: number = 1;
    radioVal: number = 1;
    chartDate: any = [];
    chartData: any = [];
    effArrayData: any = [];
    searchData: any = [];
    isDashboard: boolean = true;
    lineName: string = '';
    shiftId: number = 1;
    targetEffData: any = [];
    totalCount: number = 0;
    currentPage: number = 1;
    pageSize: number = 15;
    moduleType: any;
    filterType: any;
    graphType: any;
}
class AnalyticsFiltersModel {
    buildingsData: any = [];
    filterBuildingIds: any = [];
    searchBuildingsText: string = '';

    areasData: any = [];
    filterAreasIds: any = [];
    searchAreasText: string = '';

    shiftsData: any = [];
    filterShiftsIds: any = [];
    searchShiftsText: string = '';

    productTypeData: any = [];
    filterProductIds: any = [];
    searchProductText: string = '';

    floorsData: any = [];
    filterFloorsIds: any = [];
    searchFloorsText: string = '';

    nptsData: any = [];
    filterNptsIds: any = [];
    searchNptsText: string = '';
    stylesData: any = [];

    filterStylesIds: any = [];
    searchStylesText: string = '';
    operatorsData: any = [];
    filterOperatorsIds: any = [];
    searchOperatorsText: string = '';
    linesData: any = [];
    filterLinesIds: any = [];
    searchLinesText: string = '';
    loadedAllFilters: boolean = false;
    loadingTemplate: boolean = false;
    filterType: number = 1;
    startDate: string = '';
    endDate: string = '';
    isChange: boolean = true;
}

class TotalSummationModel {
    attendedMin: number = 0;
    producedMin: number = 0;
    totalNpt: number = 0;
    output: number = 0;
    producedStyles: number = 0;
}

class AnalyticsEfficiencyModel {
    count: number = 0;
    page: number = 1;
    limit: number = 9;
    efficiencyData: any = [];
    total: TotalSummationModel = new TotalSummationModel();
    sortBy: string = '';
    order: string = '';
    selectedEfficiencyIds: any = [];
    selectedEfficiencyAll: boolean = true;
    selectedCollapseAll: boolean = true;
    notSelectedEfficiencyIds: any = [];
    touchedCheck: boolean = false;
}

class AnalyticsOperatorNPT {
    operatorData: any = [];
    nptData: any = [];
    sortBy: string = '';
    order: string = '';
}

class OverALLEffModel {
    eff: number = 0;
    percentage: number = 0;
}

class overallNptModel {
    percentage: number = 0;
    targetNpt: number = 0;
    time: number = 0;
}

class totalOutputModel {
    output: number = 0;
    percentage: number = 0;
    time: number = 0;
}

class StatsDataModel {
    activeLines: number = 0;
    overAllEff: OverALLEffModel = new OverALLEffModel();
    overallLbr: number = 0;
    overallNpt: overallNptModel = new overallNptModel();
    overallPerformance: number = 0;
    totalOutput: totalOutputModel = new totalOutputModel();
    targetLbr: number = 0;
    currentTime: string = `${new Date()}`;
}

class AnalyticsStatsModel {
    statsData: StatsDataModel = new StatsDataModel();
}

class AnalyticsSaveTemplateModel {
    uniqueTemplateName: string = '';
}

class AnalyticsTemplateListModel {
    templateList: any = [];
    filterId: string = '';
    filterData: string = '';
}
class DashboardFilterModel {
    buildingFilterData: any = [];
    floorFilterData: any = [];
    areaFilterData: any = [];
    lineFilterData: any = [];
    isLoaded: boolean = false;
    sortLbr: boolean = false;
    sortEff: boolean = false;
    sortAvgcycle: boolean = false;
    sortEffoperator: boolean = false;
    lineId: number = 0;
    filterCount: number = 0;
    BCount: number = 0;
    FCount: number = 0;
    ACount: number = 0;
    Lcount: number = 0;
}
class AnalyticsChart {
    overTimeChartData: any = [];
    overTimeLineData: any = [];
    nptChartData: any = [];
    nptChartDataPercentage: any = [];
    chartParameters: any = '';
}
class notificationModel {
    notificationData: any = [];
    isLoaded: boolean = false;
    isDeleteLoading: boolean = false;
    page: number = 1;
    unreadCount: number = 0;
    limit: number = 6;
    userName: any = '';
}

class timeStudyOperatorModel {
    count: number = 0;
    page: number = 1;
    limit: number = 10;
    timeStudyOperatorData: any = [];
    filterSkillIds: any = [];
    filterMachineIds: any = [];
    search: string = '';
}

class productSettingsModel {
    editEffEmails: any = [];
    editEffValue: number = 0;
    editLbrEmails: any = [];
    editLbrValue: number = 0;
    editNptEmails: any = [];
    editNptValue: number = 0;
    editTimeStudy: number = 0;
    editSkillMatrix: number = 0;
    cancelActive: boolean = false;
    skillmatrixmode: number = 1;
}
class FactoryModel {
    factoryData: any = [];
    activeFactoryId: number = 1;
    darwerOpen: boolean = false;
    factoryLanguages: any = [];
}

class AppDrawerModel {
    appDrawerData: any = [];
}
class ProfileAdminModel {
    userNameData: any = '';
}
class masterDataTranslation {
    statusAll: any = 1;
    language1Filter: any = 0;
    language2Filter: any = 0;
    translatedFilter: any = 0;
    untranslatedFilter: any = 0;
    isSingleClickTranslation: boolean = false;

    // Model for Table
    total: any = 0;
    count = 0;
    page = 1;
    limit = 10;
    search: string = '';
    firstLangCount: number = 0;
    secondLangCount: number = 0;
    totalLangCount: number = 0;
    styleData: any = [];
    ppaData: any = [];
    nptCategoryData: any = [];
    nptTypeData: any = [];
    machineData: any = [];
    needleTypeData: any = [];
    productTypeData: any = [];
    skillTypeData: any = [];
    machineAttachmentData: any = [];
    operationCodeData: any = [];
    isTranslated: boolean = false;
    nextPrev: any = 1;
    setTranslateModal: boolean = false;
    setDiscardModal: boolean = false;
    selectedTypeName: string = 'Features';
    selectedType: number = 2;
}
class LineShiftModel {
    wholeShiftData: any = [];
    uid: number = 0;
    shiftData: any = [];
    currentShiftActive: number = 0;
    isSaved: boolean = false;
    isEditLine: boolean = false;
    defaultLineData: any = [];
    fromShift: boolean = false;
    isSaveAs: boolean = false;
    fromEdit: boolean = false;
    lineSetupAfterSave: any = [];
    lineStatus: number = 0;
    keepCurrentLine: boolean = false;
    currentShiftToBeActive: number = 0;
    currShiftAtIndex = 1;
    fromHistory: boolean = false;
    fromAssist: boolean = false;
    isLBALine: boolean = true;
    isLineNameNull: boolean = true;
    fromCreateLineForm: boolean = true;
    fromLBRGraph: boolean = false;
}

class lineSetupList {
    count: number = 0;
    page: number = 1;
    limit: number = 10;
    lines: any = [];
    search: string = '';
    filterStatus: any = [];
    filterCustomerIds: any = [];
    filterLineIds: any = [];
    filterShiftIds: any = [];
    selectedLines: any = [];
    order: string = '';
    sortBy: string = '';
}

class globalLangData {
    globalLanguages: any = [];
    secondLang: any = 'chi';
    secondLangDesc: string = 'Chinese';
    secondLangCode: string = 'zh-CN';
    firstLang: any = 'khm';
    firstLangDesc: string = 'Khmer';
    firstLangCode: string = 'km';
}

class ReducersModel {
    globalLoaderReducer: LoadingModel = new LoadingModel();
    filterLoaderReducer: LoadingFilterModel = new LoadingFilterModel();
    signInReducer: signInModel = new signInModel();
    lineSetupReducer: lineSetuoModel = new lineSetuoModel();
    lineBalanceReducer: LineBalanceModel = new LineBalanceModel();
    themeReducer: ThemeModel = new ThemeModel();
    styleListReducer: styleListModel = new styleListModel();
    seasonListReducer: seasonListModel = new seasonListModel();
    customersListReducer: customersListModel = new customersListModel();
    productTypeListReducer: productTypeListModel = new productTypeListModel();
    styleDetailReducer: styleDetailsModel = new styleDetailsModel();
    lineSetupHistoryTableReducer: LineSetupHistoryTable = new LineSetupHistoryTable();
    customerReducer: CustomerModel = new CustomerModel();
    newStyleReducer: NewStyleModel = new NewStyleModel();
    operationCodeListReducer: operationCodeListModel = new operationCodeListModel();
    machineListReducer: machineListModel = new machineListModel();
    needleListReducer: needleListModel = new needleListModel();
    NewOperationReducer: NewOperationModel = new NewOperationModel();
    sewingOperationReducer: sewingOperationModel = new sewingOperationModel();
    operatorReducer: operatorModel = new operatorModel();
    skillReducer: SkillModel = new SkillModel();
    sewingOperationForAddStyleReducer: sewingOperationForAddStyleModel = new sewingOperationForAddStyleModel();
    linesSetupReducer: linesSetupModel = new linesSetupModel();
    shiftsReducer: shiftsModel = new shiftsModel();
    operationCodeReducer: operationCodeModel = new operationCodeModel();
    ppaCodeReducer: ppaCodeModel = new ppaCodeModel();
    ppaOperationReducer: ppaOperationModel = new ppaOperationModel();
    mfgLinesReducer: mfgLinesModel = new mfgLinesModel();
    factoriesListReducer: factoriesListModel = new factoriesListModel();
    lineSetupInfoReducer: LineSetupInfo = new LineSetupInfo();
    lineAssistInfoReducer: LineSetupAssistInfo = new LineSetupAssistInfo();
    operatorInPoolReducer: OperatorInPool = new OperatorInPool();
    stationReducer: StationModel = new StationModel();
    lineSetupReplicaReducer: lineSetuoReplicaModel = new lineSetuoReplicaModel();
    searchOperatorInPoolReducer: searchOperatorInPoolModel = new searchOperatorInPoolModel();
    infoLineSetupReducer: linesSetupDataModel = new linesSetupDataModel();
    assistTableLineSetup: linesAssistTable = new linesAssistTable();
    linesSetupModelStationWiseReplicaReducer: linesSetupModelStationWiseReplicaModel = new linesSetupModelStationWiseReplicaModel();
    nptCategoryListReducer: nptCategoryListModel = new nptCategoryListModel();
    nptTypeListReducer: nptTypeListModel = new nptTypeListModel();
    nptListReducer: nptListModel = new nptListModel();
    allNptTypeListReducer: allNptTypeListModel = new allNptTypeListModel();
    dashboardReducer: DashboardModel = new DashboardModel();
    AnalyticsFiltersReducer: AnalyticsFiltersModel = new AnalyticsFiltersModel();
    AnalyticsEfficiencyReducer: AnalyticsEfficiencyModel = new AnalyticsEfficiencyModel();
    AnalyticsOperatorNPTReducer: AnalyticsOperatorNPT = new AnalyticsOperatorNPT();
    AnalyticsStatsReducer: AnalyticsStatsModel = new AnalyticsStatsModel();
    AnalyticsSaveReducer: AnalyticsSaveTemplateModel = new AnalyticsSaveTemplateModel();
    AnalyticsTemplateListReducer: AnalyticsTemplateListModel = new AnalyticsTemplateListModel();
    dashboardFilterReducer: DashboardFilterModel = new DashboardFilterModel();
    analyticsChartReducer: AnalyticsChart = new AnalyticsChart();
    notificationReducer: notificationModel = new notificationModel();
    productSettingReducer: productSettingsModel = new productSettingsModel();
    factoryReducer: FactoryModel = new FactoryModel();
    appDrawerReducer: AppDrawerModel = new AppDrawerModel();
    userNameReducer: ProfileAdminModel = new ProfileAdminModel();
    masterDataTranslationReducer: masterDataTranslation = new masterDataTranslation();
    assistReportReducer: linesAssistReport = new linesAssistReport();
    timeStudyOperatorReducer: timeStudyOperatorModel = new timeStudyOperatorModel();
    lineSetupListReducer: lineSetupList = new lineSetupList();
    lineShiftReducer: LineShiftModel = new LineShiftModel();
    globalLangDataReducer: globalLangData = new globalLangData();
}

export {
    signInModel,
    LineSetupHistoryTable,
    LoadingModel,
    LoadingFilterModel,
    ReducersModel,
    styleDetailsModel,
    ThemeModel,
    lineSetuoModel,
    linesSetupDataModel,
    styleListModel,
    CustomerModel,
    customersListModel,
    productTypeListModel,
    LineBalanceModel,
    NewStyleModel,
    operationCodeListModel,
    machineListModel,
    operatorModel,
    needleListModel,
    NewOperationModel,
    sewingOperationModel,
    SkillModel,
    sewingOperationForAddStyleModel,
    linesSetupModel,
    operationCodeModel,
    shiftsModel,
    ppaCodeModel,
    ppaOperationModel,
    mfgLinesModel,
    factoriesListModel,
    LineSetupInfo,
    OperatorInPool,
    StationModel,
    lineSetuoReplicaModel,
    searchOperatorInPoolModel,
    linesSetupModelStationWiseReplicaModel,
    nptCategoryListModel,
    nptTypeListModel,
    LineSetupAssistInfo,
    linesAssistTable,
    nptListModel,
    allNptTypeListModel,
    DashboardModel,
    AnalyticsFiltersModel,
    AnalyticsEfficiencyModel,
    AnalyticsOperatorNPT,
    AnalyticsStatsModel,
    AnalyticsSaveTemplateModel,
    AnalyticsTemplateListModel,
    DashboardFilterModel,
    AnalyticsChart,
    notificationModel,
    productSettingsModel,
    FactoryModel,
    AppDrawerModel,
    ProfileAdminModel,
    masterDataTranslation,
    linesAssistReport,
    timeStudyOperatorModel,
    lineSetupList,
    LineShiftModel,
    globalLangData,
    seasonListModel
};
