import React from 'react';
import { makeStyles, createStyles, Theme, List, ListItem, MenuItem } from '@material-ui/core';
import { useHistory, useLocation, NavLink } from 'react-router-dom';
import LocalImages from '../../utils/images';
import { useDispatch } from 'react-redux';
import Utils from '../../utils';
import { useTranslation } from 'react-i18next';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            flexBasis: '4%',
            backgroundColor: 'var(--dark-blue)',
            height: '100vh',
            position: 'fixed',
            zIndex: 99,
            '& a': {
                textDecoration: 'none!important',
            },
        },
        orangeContainer: {
            backgroundColor: 'var(--orange)',
        },

        listContainer: {
            '& ul': {
                padding: '0',
            },
        },
        menuList: {
            position: 'relative',
        },
        menuBut: {
            position: 'relative',
            height: '48px',
        },
        popupMenu: {
            '& .MuiMenu-paper': {
                borderRadius: '0px',
                width: '170px',
                boxShadow: 'none',
            },
            '& ul': {
                padding: '0px',
            },
            '& li': {
                height: '48px',
                fontSize: '12px',
                color: 'var(--white)',
                backgroundColor: '#24455D',
                '&:hover': {
                    backgroundColor: 'var(--dark-green)',
                },
            },
        },
        dashboardMenu: {
            '& .MuiMenu-paper': {
                top: '102px !important',
                left: '60px !important',
            },
        },
        lineSetupMenu: {
            '& .MuiMenu-paper': {
                top: '150px !important',
                left: '60px !important',
            },
        },
        lbaMenu: {
            '& .MuiMenu-paper': {
                top: '198px !important',
                left: '60px !important',
            },
        },
        manageStyleMenu: {
            '& .MuiMenu-paper': {
                top: '246px !important',
                left: '60px !important',
            },
        },

        nptMenu: {
            '& .MuiMenu-paper': {
                top: '294px !important',
                left: '60px !important',
            },
        },
        analMenu: {
            '& .MuiMenu-paper': {
                top: '390px !important',
                left: '60px !important',
            },
        },
        highlightButton: {
            borderLeft: '4px solid var(--light-blue)',
            backgroundColor: 'var(--dark-green)',
        },
        secondaryMenuColor: {
            paddingLeft: '30px',
        },
        activeColorButton: {
            backgroundColor: '#325F80 !important',
        },
        admindMenu: {
            '& .MuiMenu-paper': {
                top: '342px !important',
                left: '60px !important',
            },
        },
        customMenu: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '12px 0px',
            '&:hover': {
                borderLeft: '4px solid var(--light-blue)',
                backgroundColor: '#325F80',
                boxSizing: 'border-box',
                '& > div': {
                    display: 'block',
                },
            },
        },
        customMenuList: {
            position: 'absolute',
            display: 'none',
            left: '58px',
            top: '0px',
            minWidth: '170px',
            '& ul': {
                padding: '0px',
            },
            '& li': {
                height: '48px',
                fontSize: '12px',
                color: 'var(--white)',
                backgroundColor: '#284F6C',
                '&:hover': {
                    backgroundColor: '#24455D',
                },
            },
        },
        isActive: {
            borderLeft: '4px solid var(--light-blue)',
            backgroundColor: '#325F80',
            boxSizing: 'border-box',
        },
        mainMenu: {
            backgroundColor: 'var(--dark-green) !important',
        },
    }),
);

export default function Sidebar() {
    const classes = styles();
    const { t } = useTranslation();
    const history = useHistory();
    const pathname = useLocation().pathname;
    const dispatch = useDispatch();

    return (
        <div className={classes.mainContainer}>
            <ListItem className={classes.orangeContainer}>
                <img alt="menu_icon" src={LocalImages.MENU_ONE} />
            </ListItem>
            <List className={classes.menuList} component="nav">
                <div
                    className={`${classes.customMenu} ${
                        pathname.split('/')[1] === 'dashboard' ? classes.isActive : ''
                    }`}
                >
                    <img alt="menu_icon" src={LocalImages.MENU_TWO} />
                    <div className={classes.customMenuList}>
                        <NavLink to={pathname}>
                            <MenuItem className={classes.mainMenu}>{t('DASHBOARD')}</MenuItem>
                        </NavLink>
                        <NavLink to="/dashboard">
                            <MenuItem
                                className={classes.secondaryMenuColor}
                                onClick={() => {
                                    pathname !== '/dashboard'
                                        ? dispatch({
                                              type: Utils.ActionName.GET_DASHBOARD,
                                              payload: {
                                                  dashboardData: [],
                                                  filterVal: 1,
                                                  radioVal: 1,
                                              },
                                          })
                                        : console.log('same page');
                                    history.push('/dashboard');
                                }}
                            >
                                {t('Overall')}
                            </MenuItem>
                        </NavLink>
                        <NavLink to="/dashboard/line-output">
                            <MenuItem
                                onClick={() => {
                                    dispatch({
                                        type: Utils.ActionName.GET_DASHBOARD_FILTER,
                                        payload: { isLoaded: false, filterCount: 0 },
                                    });
                                    pathname !== '/dashboard/line-output'
                                        ? dispatch({
                                              type: Utils.ActionName.GET_DASHBOARD,
                                              payload: {
                                                  dashboardData: [],
                                              },
                                          })
                                        : console.log('same page');
                                    history.push('/dashboard/line-output');
                                }}
                                className={classes.secondaryMenuColor}
                            >
                                {t('LineOutput')}
                            </MenuItem>
                        </NavLink>
                    </div>
                </div>

                <div
                    className={`${classes.customMenu} ${
                        pathname.split('/')[1] === 'lineSetup' ? classes.isActive : ''
                    }`}
                    onClick={() => history.push('/lineSetup')}
                >
                    <img alt="menu_icon" src={LocalImages.MENU_EIGHT} />
                    <div className={classes.customMenuList}>
                        <NavLink to="/lineSetup">
                            <MenuItem
                                onClick={() => {
                                    history.push('/lineSetup');
                                }}
                                className={classes.mainMenu}
                            >
                                {t('LineSetup')}
                            </MenuItem>
                        </NavLink>
                    </div>
                </div>

                <div
                    className={`${classes.customMenu} ${
                        pathname.split('/')[1] === 'line-balancing-assist' ? classes.isActive : ''
                    }`}
                    onClick={() => history.push('/line-balancing-assist')}
                >
                    <img alt="menu_icon" src={LocalImages.MENU_SIX} />
                    <div className={classes.customMenuList}>
                        <NavLink to="/line-balancing-assist">
                            <MenuItem
                                onClick={() => {
                                    history.push('/line-balancing-assist');
                                }}
                                className={classes.mainMenu}
                            >
                                {t('LineBalancingAssist')}
                            </MenuItem>
                        </NavLink>
                    </div>
                </div>

                <div
                    className={`${classes.customMenu} ${
                        pathname.split('/')[1] === 'manage-style' ? classes.isActive : ''
                    }`}
                    onClick={() => history.push('/manage-style/style-list')}
                >
                    <img alt="menu_icon" src={LocalImages.MENU_SEVEN} />
                    <div className={classes.customMenuList}>
                        <NavLink to="/manage-style/style-list">
                            <MenuItem
                                onClick={() => {
                                    history.push('/manage-style/style-list');
                                }}
                                className={classes.mainMenu}
                            >
                                {t('ManageStyle')}
                            </MenuItem>
                        </NavLink>
                    </div>
                </div>

                <div
                    className={`${classes.customMenu} ${pathname.split('/')[1] === 'npt' ? classes.isActive : ''}`}
                    onClick={() => {
                        // history.push('/npt');
                        dispatch({
                            type: Utils.ActionName.IS_LOADING,
                            payload: true,
                        });
                        window.location.replace('/npt');
                        dispatch({
                            type: Utils.ActionName.IS_LOADING,
                            payload: true,
                        });
                    }}
                >
                    <img alt="menu_icon" src={LocalImages.MENU_THREE} />
                    <div className={classes.customMenuList}>
                        <NavLink to="/npt">
                            <MenuItem
                                onClick={() => {
                                    // history.push('/npt');
                                    dispatch({
                                        type: Utils.ActionName.IS_LOADING,
                                        payload: true,
                                    });
                                    window.location.reload();
                                    dispatch({
                                        type: Utils.ActionName.IS_LOADING,
                                        payload: true,
                                    });
                                }}
                                className={classes.mainMenu}
                            >
                                {t('NPT')}
                            </MenuItem>
                        </NavLink>
                    </div>
                </div>

                <div className={`${classes.customMenu} ${pathname.split('/')[1] === 'admin' ? classes.isActive : ''}`}>
                    <img alt="menu_icon" src={LocalImages.MENU_FOUR} />
                    <div className={classes.customMenuList}>
                        <NavLink to={pathname}>
                            <MenuItem className={classes.mainMenu}>{t('Admin')}</MenuItem>
                        </NavLink>
                        <NavLink to="/admin/manage-operator">
                            <MenuItem
                                onClick={() => {
                                    history.push('/admin/manage-operator');
                                }}
                                className={classes.secondaryMenuColor}
                            >
                                {t('ManageOperator')}
                            </MenuItem>
                        </NavLink>
                        <NavLink to="/admin/operationCode">
                            <MenuItem
                                onClick={() => {
                                    history.push('/admin/operationCode');
                                }}
                                className={classes.secondaryMenuColor}
                            >
                                {t('ManageOperation')}
                            </MenuItem>
                        </NavLink>
                        <NavLink to="/admin/manage-npt">
                            <MenuItem
                                onClick={() => {
                                    history.push('/admin/manage-npt');
                                }}
                                className={classes.secondaryMenuColor}
                            >
                                {t('ManageNPT')}
                            </MenuItem>
                        </NavLink>
                        {/* <MenuItem className={classes.secondaryMenuColor}>{t('UserManagement')}</MenuItem> */}
                        <NavLink to="/admin/product-setting">
                            <MenuItem
                                onClick={() => {
                                    history.push('/admin/product-setting');
                                }}
                                className={classes.secondaryMenuColor}
                            >
                                {t('ProductSettings')}
                            </MenuItem>
                        </NavLink>
                    </div>
                </div>

                <div
                    className={`${classes.customMenu} ${
                        pathname.split('/')[1] === 'analytics' ? classes.isActive : ''
                    }`}
                    onClick={() => history.push('/analytics')}
                >
                    <img alt="menu_icon" src={LocalImages.MENU_FIVE} />
                    <div className={classes.customMenuList}>
                        <NavLink to="/analytics">
                            <MenuItem
                                onClick={() => {
                                    history.push('/analytics');
                                }}
                                className={classes.mainMenu}
                            >
                                {t('AnalyticsReporting')}
                            </MenuItem>
                        </NavLink>
                    </div>
                </div>
            </List>
        </div>
    );
}
