import React from 'react';
import { createStyles, makeStyles, TextField, Theme, MenuItem } from '@material-ui/core';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        inputField: {
            marginTop: 'unset',
            marginBottom: 'unset',

            '& .MuiInputBase-input': {
                font: 'normal normal normal 14px/14px Roboto',
                letterSpacing: '0.07px',
                opacity: 1,
            },
            '& .MuiFormLabel-root': {
                font: 'normal normal normal 12px/21px Roboto',
                letterSpacing: '0.07px',
                opacity: 1,
            },
            '& .Mui-focused': {
                borderBottomColor: 'var(--input-active)',
            },
            '& .MuiInput-underline:before': {
                borderBottomColor: 'var(--input-title)',
                '&:active': {
                    borderBottomColor: 'var(--input-active)',
                },
            },
            '& svg': {
                color: 'var(--input-title)',
            },
        },
        required: {
            color: '#D32F2F',
        },
    }),
);
interface Props {
    label?: string;
    placeholder?: string;
    id?: string;
    name?: string;
    type?: string;
    onBlur?: any;
    values?: any;
    onChange?: any;
    style?: any;
    helperText?: any;
    error?: any;
    touched?: any;
    errors?: any;
    data?: any;
    isRequired?: boolean;
    isDisabled?: boolean;
    onClear?: any;
}

export default function SelectPickerDefault({
    label,
    id,
    values,
    onChange,
    touched,
    errors,
    data,
    isRequired,
    isDisabled,
    onClear,
}: Props) {
    const classes = styles();

    return (
        <TextField
            className={classes.inputField}
            select
            id={id}
            label={
                <span>
                    {label}
                    {isRequired ? <span className={classes.required}> *</span> : <span></span>}
                </span>
            }
            value={values ? values : ''}
            onChange={onChange}
            helperText={touched ? errors : ''}
            error={touched && Boolean(errors)}
            margin="dense"
            fullWidth
            variant="standard"
            disabled={isDisabled ? isDisabled : false}
        >
            <MenuItem value="null" onClick={onClear}>
                -
            </MenuItem>
            {data}
        </TextField>
    );
}
