import axios from 'axios';
import moment from 'moment';
import Utils from '../../../utils';
import { getUserIdToken } from '../../../utils/session';

export const addingNptCategory = (values: any, setSubmitting: any, handleClose: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            setSubmitting(false);
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { displayName, description } = values;

        const dataToSend = {
            // companyCode: 'AI',
            // companyCode: 'STX-LTIE-PILOT',
            companyCode: localStorage.getItem('companyCodeAdmin') || JSON.stringify(localStorage.getItem('companyCodeAdmin')),
            // factoryId: activeFactoryId,
            // companyCode: Utils.constants.companyCodeBase,
            factoryId: localStorage.getItem("factoryId"),
            nptCat: displayName,
            desc1: description,
        };
        Utils.api.postApiCall(
            Utils.endPoints.nptCategory,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    setSubmitting(false);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch(getNptCategories());
                    handleClose();
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                setSubmitting(false);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getNptCategories = (limitManual?: any) => {
    return (dispatch: Function, getState: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const { page, limit, searchNpt, sortBy, order, filterStatus } = getState().nptCategoryListReducer;
        let parameters = '';
        if (limitManual === undefined) {
            parameters = `?page=${page}&limit=${limit}${
                order.length > 0
                    ? `&order=${order}&sortBy=${sortBy}`
                    : ''
            }${searchNpt.length > 0 ? `&search=${searchNpt}` : ''}${
                filterStatus.length === 1 ? `&status=${filterStatus[0]}` : ''
            } `;
        } else {
            parameters = `?page=1&limit=${limitManual}&status=true`;
        }

        Utils.api.getApiCall(
            Utils.endPoints.nptCategory,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.GET_NPT_CATEGORIES_LIST,
                        payload: {
                            nptCategoriesData: innerData.data,
                            page: innerData.page,
                            count: innerData.count,
                        },
                    });

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const validateNptBulkUpload = (operators: any, setFirstStepLoader: any, handleNext: any, errorCallBack: Function) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        var dataUpload = new FormData();
        dataUpload.append('npts', operators);

        const dataToSend = dataUpload;
        Utils.api.postApiCall(
            Utils.endPoints.validateBulkUploadNpt,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.UPDATE_SKILL_LIST,
                        payload: {
                            totalRecord: data.data.totalRecords,
                            duplicateRecord: data.data.duplicateRecords,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    Utils.showAlert(1, data.message);
                    errorCallBack("");
                    setFirstStepLoader(false);
                    handleNext();
                } else {
                    errorCallBack(data.message)
                    setFirstStepLoader(false);
                }
                
            },
            (error: any) => {
                let { data } = error;
                errorCallBack(data.message);
                setFirstStepLoader(false);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const nptBulkUpload = (setSecondStepLoader: any, handleNext: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const { wholeFile } = getState().skillReducer;

        var dataUpload = new FormData();
        dataUpload.append('npts', wholeFile);
        setSecondStepLoader(true);
        const dataToSend = dataUpload;
        Utils.api.postApiCall(
            Utils.endPoints.bulkUploadNpt,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.UPDATE_SKILL_LIST,
                        payload: {
                            totalRecord: data.data.totalRecords,
                            duplicateRecord: data.data.duplicateRecords,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    Utils.showAlert(1, data.message);
                    setSecondStepLoader(false);
                    handleNext();
                } else {
                    Utils.showAlert(2, data.message);
                    setSecondStepLoader(false);
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                setSecondStepLoader(false);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const addingNptType = (values: any, setSubmitting: any, handleClose: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            setSubmitting(false);
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { nptCatId, nptType, description } = values;

        const dataToSend = {
            nptCatId,
            nptType,
            desc1: description,
        };

        Utils.api.postApiCall(
            Utils.endPoints.nptType,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    setSubmitting(false);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch(getNptTypeByCategory(nptCatId));
                    handleClose();
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                setSubmitting(false);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getNptTypeByCategory = (categoryId: any) => {
    return (dispatch: Function, getState: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const {
            //  page, limit,
            searchNptType,
            sortBy,
            order,
        } = getState().nptTypeListReducer;

        let parameters = `?id=${categoryId}${
            order.length > 0
                ? `&order=${order}&sortBy=${sortBy}`
                : ''
        }${searchNptType.length > 0 ? `&search=${searchNptType}` : ''} `;

        Utils.api.getApiCall(
            Utils.endPoints.getNptTypesByCategory,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.GET_NPT_TYPES_LIST,
                        payload: {
                            nptTypeData: innerData[0].npttypes,
                            page: innerData[0].page,
                            count: innerData[0].count,
                            createdDt: innerData[0].createdDt,
                            desc1: innerData[0].desc1,
                            updatedDt: innerData[0].updatedDt,
                            nptCat: innerData[0].nptCat,
                            isActive: innerData[0].isActive,
                            updatedBy: innerData[0].updatedBy,
                            createdBy: innerData[0].createdBy,
                        },
                    });

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const deleteNPTType = (id: any, categoryId: any, handleCloseEdit: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const dataToSend = '';
        Utils.api.deleteApiCall(
            `${Utils.endPoints.nptType}?id=${id}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch(getNptTypeByCategory(categoryId));
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    handleCloseEdit();
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const editingNptType = (values: any, setSubmitting: any, handleClose: any, categoryId: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            setSubmitting(false);
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { nptTypeId, nptType, description, isActive } = values;

        const dataToSend = {
            nptType,
            desc1: description ? description : "",
            isActive: isActive === 'true' ? true : false,
        };

        Utils.api.putApiCall(
            `${Utils.endPoints.nptType}?id=${nptTypeId}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    setSubmitting(false);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch(getNptTypeByCategory(categoryId));
                    handleClose();
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                setSubmitting(false);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const editingNptCat = (values: any, setSubmitting: any, handleClose: any, categoryId: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            setSubmitting(false);
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { displayName, description, isActive } = values;

        const dataToSend = {
            nptCat: displayName,
            desc1: description ? description : "",
            isActive: isActive === 'true' ? true : false,
        };

        Utils.api.putApiCall(
            `${Utils.endPoints.nptCategory}?id=${categoryId}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    setSubmitting(false);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch(getNptTypeByCategory(categoryId));
                    handleClose();
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                setSubmitting(false);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const deleteNPTCat = (categoryId: any, handleCloseEdit: any, history: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const dataToSend = '';
        Utils.api.deleteApiCall(
            `${Utils.endPoints.nptCategory}?id=${categoryId}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    history.push('/admin/manage-npt');
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    handleCloseEdit();
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const handleDownloadNpt = async () => {
    let date = moment().format('YYYY-MM-DD');

    const d = new Date();
    const offsetValue = -1*(d.getTimezoneOffset());
    
    const req = await axios({
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getUserIdToken()}`,
            "offsetValue": offsetValue,
        },
        method: 'get',
        url: Utils.constants.apiUrl + `user/npt/download-npts`,
        responseType: 'blob',
    });
    var blob = new Blob([req.data], {
        type: req.headers['content-type'],
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `latestNPTs_${date}.xlsx`;
    link.click();
};

export const handleDownloadNptTemplate = async () => {

    const d = new Date();
    const offsetValue = -1*(d.getTimezoneOffset());
    
    const req = await axios({
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getUserIdToken()}`,
            "offsetValue": offsetValue,
        },
        method: 'get',
        url: Utils.constants.apiUrl + `user/npt/download-npts-template-file`,
        responseType: 'blob',
    });
    var blob = new Blob([req.data], {
        type: req.headers['content-type'],
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `NPT_Template.xlsx`;
    link.click();
}; 