import * as React from 'react';
import { 
    Box, 
    Button, 
    Divider, 
    Typography,
} from '@material-ui/core';
import { 
    LBRButtonDateSelected, 
    LBRFlexContainer, 
    LBRFlexWrapContainer, 
} from '../../../styles';

const Header:React.FC<{ 
    selected: number[], 
    selectedLabels: string[],
    onDeselectedLabels: (index: any) => void
}> = ({ 
    selected, 
    selectedLabels,
    onDeselectedLabels,
}) => {

    const handleDeselectedLabel = (label: string) => {
        onDeselectedLabels(
            selectedLabels.filter((selectedLabel) => selectedLabel !== label)
        );
    };

    return (
        <Box marginBottom={3}>
            <LBRFlexContainer marginBottom={2}>
                <Typography variant='h5'>{'Line Setup History'}</Typography>
                <LBRFlexWrapContainer>
                    {selectedLabels?.map((label, index) => (
                        <LBRButtonDateSelected onClick={() => handleDeselectedLabel(label)}>{label}</LBRButtonDateSelected>
                    ))}
                </LBRFlexWrapContainer>
            </LBRFlexContainer>
            <LBRFlexContainer>
                <Typography variant='caption'>
                    {'Selected '}{`(${selected.length})`}{' on this page.'}
                </Typography>
                {/* Hidde Button Download TA-475 */}
                {/* <Divider orientation="vertical" flexItem />
                <Button variant='outlined' color='primary' >
                    {'DOWNLOAD LINE SETUP REPORT'}
                </Button> */}
            </LBRFlexContainer>
        </Box>
    )
}

export default Header;