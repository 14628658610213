import React from 'react';
import { makeStyles, createStyles, Theme, Divider, FormControlLabel, Checkbox } from '@material-ui/core';
import Utils from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { getLineSetupHistory } from '../../screens/manageStyle/action';
import { useTranslation } from 'react-i18next';
import { ReducersModel } from '../../model';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            minWidth: '210px',
        },
        title: {
            padding: '0 16px 8px 16px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
        subTitle: {
            padding: '8px 16px 0 16px',
            textAlign: 'left',
            font: 'normal normal 500 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
        filtersBox: {
            padding: '0 16px 8px 16px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
            display: 'flex',
            flexDirection: 'column',
            '& .MuiTypography-root': {
                textAlign: 'left',
                font: 'normal normal normal 12px/21px Roboto',
                letterSpacing: '0.07px',
                color: '#868686',
                opacity: 1,
            },
            '& .MuiCheckbox-root': {
                padding: '2px 7px',
            },
            '& .MuiFormGroup-root': { flexDirection: 'row!important' },
        },
        statusContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        status: {
            marginRight: '40px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
        indeterminateColor: {
            color: '#1E88E5',
        },
        disabledCheck: {
            '& .MuiTypography-body1': {
                fontStyle: 'italic',
                color: '#868686',
            },
        },
        naturalCheck: {
            '& .MuiTypography-root': {
                color: '#515151',
            },
        },
    }),
);

export default function LineSetupHistoryListFilter({ customers, styleId, lines, shifts }: any) {
    const classes = styles();
    const { t } = useTranslation();
    const { filterLineIds, filterCustomerIds, filterShiftIds, filterStatusHistory } = useSelector(
        (state: ReducersModel) => state.lineSetupHistoryTableReducer,
    );
    const dispatch = useDispatch();
    return (
        <div className={classes.mainContainer}>
            <div className={classes.title}>{t('Filterby')}</div>
            <Divider />
            <div className={classes.subTitle}>{t('Status')}</div>
            <div className={classes.filtersBox}>
                <FormControlLabel
                    value={4}
                    className={classes.naturalCheck}
                    control={
                        <Checkbox
                            color={'primary'}
                            checked={filterStatusHistory.includes('4')}
                            name="status"
                            onChange={(event: any) => {
                                const target = event.target;
                                if (target.checked) {
                                    if (!filterStatusHistory.includes(target.value)) {
                                        let newArray = [...filterStatusHistory];

                                        newArray.push(target.value);
                                        dispatch({
                                            type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
                                            payload: {
                                                filterStatusHistory: newArray,
                                            },
                                        });
                                        if (styleId) dispatch(getLineSetupHistory(styleId));
                                    }
                                } else {
                                    if (filterStatusHistory.includes(target.value)) {
                                        const index = filterStatusHistory.indexOf(target.value);
                                        let newArray = [...filterStatusHistory];
                                        if (index > -1) {
                                            newArray.splice(index, 1);
                                        }
                                        dispatch({
                                            type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
                                            payload: {
                                                filterStatusHistory: newArray,
                                            },
                                        });
                                        if (styleId) dispatch(getLineSetupHistory(styleId));
                                    }
                                }
                            }}
                        />
                    }
                    label={t('Closed')}
                />
                <FormControlLabel
                    value={3}
                    className={classes.naturalCheck}
                    control={
                        <Checkbox
                            color={'primary'}
                            name="status"
                            checked={filterStatusHistory.includes('3')}
                            onChange={(event: any) => {
                                const target = event.target;
                                if (target.checked) {
                                    if (!filterStatusHistory.includes(target.value)) {
                                        let newArray = [...filterStatusHistory];

                                        newArray.push(target.value);
                                        dispatch({
                                            type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
                                            payload: {
                                                filterStatusHistory: newArray,
                                            },
                                        });
                                        if (styleId) dispatch(getLineSetupHistory(styleId));
                                    }
                                } else {
                                    if (filterStatusHistory.includes(target.value)) {
                                        const index = filterStatusHistory.indexOf(target.value);
                                        let newArray = [...filterStatusHistory];
                                        if (index > -1) {
                                            newArray.splice(index, 1);
                                        }
                                        dispatch({
                                            type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
                                            payload: {
                                                filterStatusHistory: newArray,
                                            },
                                        });
                                        if (styleId) dispatch(getLineSetupHistory(styleId));
                                    }
                                }
                            }}
                        />
                    }
                    label={t('Completed')}
                />
            </div>
            <Divider />
            <div>
                <div className={classes.subTitle}>{t('Line')}</div>
                <div className={classes.filtersBox}>
                    <div>
                        {lines && lines.length > 2 && (
                            <FormControlLabel
                                className={classes.naturalCheck}
                                control={
                                    <Checkbox
                                        color={'primary'}
                                        checked={lines?.length === filterLineIds.length}
                                        indeterminate={
                                            filterLineIds.length !== 0 && lines?.length !== filterLineIds.length
                                        }
                                        indeterminateIcon={
                                            <IndeterminateCheckBoxOutlinedIcon className={classes.indeterminateColor} />
                                        }
                                        inputProps={{
                                            'aria-label': 'decorative checkbox',
                                        }}
                                        onChange={(event: any) => {
                                            const target = event.target;
                                            if (target.checked) {
                                                let newArray = lines.map((a: any) => `${a.id}`);
                                                dispatch({
                                                    type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
                                                    payload: {
                                                        filterLineIds: newArray,
                                                    },
                                                });
                                                if (styleId) dispatch(getLineSetupHistory(styleId));
                                            } else {
                                                dispatch({
                                                    type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
                                                    payload: {
                                                        filterLineIds: [],
                                                    },
                                                });
                                                if (styleId) dispatch(getLineSetupHistory(styleId));
                                            }
                                        }}
                                    />
                                }
                                label={<div>{t('All')}</div>}
                            />
                        )}
                    </div>
                    {lines && lines.length > 0 ? (
                        lines.map((line: any) => {
                            return (
                                <FormControlLabel
                                    value={line.id}
                                    className={classes.naturalCheck}
                                    control={
                                        <Checkbox
                                            color={'primary'}
                                            name="line"
                                            checked={filterLineIds.includes(`${line.id}`)}
                                            onChange={(event: any) => {
                                                const target = event.target;
                                                if (target.checked) {
                                                    if (!filterLineIds.includes(target.value)) {
                                                        let newArray = [...filterLineIds];

                                                        newArray.push(target.value);
                                                        dispatch({
                                                            type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
                                                            payload: {
                                                                filterLineIds: newArray,
                                                            },
                                                        });
                                                        if (styleId) dispatch(getLineSetupHistory(styleId));
                                                    }
                                                } else {
                                                    if (filterLineIds.includes(target.value)) {
                                                        const index = filterLineIds.indexOf(target.value);
                                                        let newArray = [...filterLineIds];
                                                        if (index > -1) {
                                                            newArray.splice(index, 1);
                                                        }
                                                        dispatch({
                                                            type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
                                                            payload: {
                                                                filterLineIds: newArray,
                                                            },
                                                        });
                                                        if (styleId) dispatch(getLineSetupHistory(styleId));
                                                    }
                                                }
                                            }}
                                        />
                                    }
                                    label={line.mfgLine}
                                />
                            );
                        })
                    ) : (
                        <div>{t('NoRecordfound')}</div>
                    )}
                </div>
            </div>
            <Divider />
            <div>
                <div className={classes.subTitle}>{t('Shift')}</div>
                <div className={classes.filtersBox}>
                    <div>
                        {shifts && shifts.length > 2 && (
                            <FormControlLabel
                                className={classes.naturalCheck}
                                control={
                                    <Checkbox
                                        color={'primary'}
                                        checked={shifts?.length === filterShiftIds.length}
                                        indeterminate={
                                            filterShiftIds.length !== 0 && shifts?.length !== filterShiftIds.length
                                        }
                                        indeterminateIcon={
                                            <IndeterminateCheckBoxOutlinedIcon className={classes.indeterminateColor} />
                                        }
                                        inputProps={{
                                            'aria-label': 'decorative checkbox',
                                        }}
                                        onChange={(event: any) => {
                                            const target = event.target;
                                            if (target.checked) {
                                                let newArray = shifts.map((a: any) => `${a.id}`);
                                                dispatch({
                                                    type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
                                                    payload: {
                                                        filterShiftIds: newArray,
                                                    },
                                                });
                                                if (styleId) dispatch(getLineSetupHistory(styleId));
                                            } else {
                                                dispatch({
                                                    type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
                                                    payload: {
                                                        filterShiftIds: [],
                                                    },
                                                });
                                                if (styleId) dispatch(getLineSetupHistory(styleId));
                                            }
                                        }}
                                    />
                                }
                                label={<div>{t('All')}</div>}
                            />
                        )}
                    </div>
                    {shifts && shifts.length > 0 ? (
                        shifts.map((shift: any) => {
                            return (
                                <FormControlLabel
                                    key={shift.id}
                                    value={shift.id}
                                    className={classes.naturalCheck}
                                    control={
                                        <Checkbox
                                            color={'primary'}
                                            name="shift"
                                            checked={filterShiftIds.includes(`${shift.id}`)}
                                            onChange={(event: any) => {
                                                const target = event.target;
                                                if (target.checked) {
                                                    if (!filterShiftIds.includes(target.value)) {
                                                        let newArray = [...filterShiftIds];

                                                        newArray.push(target.value);
                                                        dispatch({
                                                            type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
                                                            payload: {
                                                                filterShiftIds: newArray,
                                                            },
                                                        });
                                                        if (styleId) dispatch(getLineSetupHistory(styleId));
                                                    }
                                                } else {
                                                    if (filterShiftIds.includes(target.value)) {
                                                        const index = filterShiftIds.indexOf(target.value);
                                                        let newArray = [...filterShiftIds];
                                                        if (index > -1) {
                                                            newArray.splice(index, 1);
                                                        }
                                                        dispatch({
                                                            type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
                                                            payload: {
                                                                filterShiftIds: newArray,
                                                            },
                                                        });
                                                        if (styleId) dispatch(getLineSetupHistory(styleId));
                                                    }
                                                }
                                            }}
                                        />
                                    }
                                    label={shift.shift}
                                />
                            );
                        })
                    ) : (
                        <div>{t('NoRecordfound')}</div>
                    )}
                </div>
            </div>
            <Divider />
            <div>
                <div className={classes.subTitle}>{t('Customers')}</div>
                <div className={classes.filtersBox}>
                    <div>
                        {customers && customers.length > 2 && (
                            <FormControlLabel
                                className={classes.naturalCheck}
                                control={
                                    <Checkbox
                                        color={'primary'}
                                        checked={customers?.length === filterCustomerIds.length}
                                        indeterminate={
                                            filterCustomerIds.length !== 0 &&
                                            customers?.length !== filterCustomerIds.length
                                        }
                                        indeterminateIcon={
                                            <IndeterminateCheckBoxOutlinedIcon className={classes.indeterminateColor} />
                                        }
                                        inputProps={{
                                            'aria-label': 'decorative checkbox',
                                        }}
                                        onChange={(event: any) => {
                                            const target = event.target;
                                            if (target.checked) {
                                                let newArray = customers.map((a: any) => `${a.id}`);
                                                dispatch({
                                                    type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
                                                    payload: {
                                                        filterCustomerIds: newArray,
                                                    },
                                                });
                                                if (styleId) dispatch(getLineSetupHistory(styleId));
                                            } else {
                                                dispatch({
                                                    type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
                                                    payload: {
                                                        filterCustomerIds: [],
                                                    },
                                                });
                                                if (styleId) dispatch(getLineSetupHistory(styleId));
                                            }
                                        }}
                                    />
                                }
                                label={<div>{t('All')}</div>}
                            />
                        )}
                    </div>
                    {customers && customers.length > 0 ? (
                        customers.map((customer: any) => {
                            return (
                                <FormControlLabel
                                    value={customer.id}
                                    className={classes.naturalCheck}
                                    control={
                                        <Checkbox
                                            color={'primary'}
                                            name="customer"
                                            checked={filterCustomerIds.includes(`${customer.id}`)}
                                            onChange={(event: any) => {
                                                const target = event.target;
                                                if (target.checked) {
                                                    if (!filterCustomerIds.includes(target.value)) {
                                                        let newArray = [...filterCustomerIds];

                                                        newArray.push(target.value);
                                                        dispatch({
                                                            type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
                                                            payload: {
                                                                filterCustomerIds: newArray,
                                                            },
                                                        });
                                                        if (styleId) dispatch(getLineSetupHistory(styleId));
                                                    }
                                                } else {
                                                    if (filterCustomerIds.includes(target.value)) {
                                                        const index = filterCustomerIds.indexOf(target.value);
                                                        let newArray = [...filterCustomerIds];
                                                        if (index > -1) {
                                                            newArray.splice(index, 1);
                                                        }
                                                        dispatch({
                                                            type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
                                                            payload: {
                                                                filterCustomerIds: newArray,
                                                            },
                                                        });
                                                        if (styleId) dispatch(getLineSetupHistory(styleId));
                                                    }
                                                }
                                            }}
                                        />
                                    }
                                    label={customer.customer}
                                />
                            );
                        })
                    ) : (
                        <div>{t('NoRecordfound')}</div>
                    )}
                </div>
            </div>
        </div>
    );
}
