import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme, Typography, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';
import Schema from '../../schema';
import { useTranslation } from 'react-i18next';
import NormalInput from '../../components/inputs/normalInput';
import DatePicker from '../../components/datePicker/index';
import TimePicker from '../../components/datePicker/muiTime';
import AutoComplete from '../../components/inputs/autoComplete';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModel } from '../../model';
import { editingNptType } from './action';
import Utils from '../../utils';
import moment from 'moment';
const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {},
        textStyl: {
            fontSize: '20px',
            color: 'var(--dark-grey)',
            marginTop: '44px',
        },
        paper: {
            position: 'absolute',
            width: '25%',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        saveContainer: {
            backgroundColor: 'white',
            padding: '10px 0',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '41px',
        },
        cancelButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#515151',
            opacity: 1,
            height: '36px',
        },
        saveButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#FFFFFF',
            opacity: 1,
            height: '36px',
        },
    }),
);

export default function EditNPT({ handleClose }: any) {
    const classes = styles();
    const { t } = useTranslation();

    const {
        selectedEditDate,
        selectedEditNptId,
        selectedEditStartTime,
        selectedEditEndTime,
        selectedEditManpower,
        selectedEditLineId,
        selectedEditNptCategoryId,
        selectedEditNptTypeId,
        selectedEditNptTypeData,
    } = useSelector((state: ReducersModel) => state.nptListReducer);

    const { mfgLines } = useSelector((state: ReducersModel) => state.mfgLinesReducer);
    const { nptCategoriesData } = useSelector((state: ReducersModel) => state.nptCategoryListReducer);
    const [nptTypeData, setNptTypeData] = useState<any>([...selectedEditNptTypeData]);

    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const [modalStyle] = React.useState(getModalStyle);

    const handleStartTimeChange = (date: any, setFieldValue: any) => {
        setSelectedStartTime(date);
        setFieldValue('startTime', date);
    };
    
    const [selectedStartTime, setSelectedStartTime] = React.useState<any>(
        moment(`${moment().format("YYYY-MM-DD")}T${selectedEditStartTime}Z`).format("YYYY-MM-DDTHH:mm:ss")
    )
        
    const [selectedEndTime, setSelectedEndTime] = React.useState<any>(
        moment(`${moment().format("YYYY-MM-DD")}T${selectedEditEndTime}Z`).format("YYYY-MM-DDTHH:mm:ss")
    )
            
    const handleEndTimeChange = (date: any, setFieldValue: any) => {
        setSelectedEndTime(date);
        setFieldValue('endTime', date);
    };
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch({
                type: Utils.ActionName.GET_NPT,
                payload: {
                    selectedEditNptTypeData: [],
                },
            });
        };
    }, [dispatch]);

    return (
        <div style={modalStyle} className={classes.paper}>
            <div className={classes.mainContainer}>
                <Typography className={classes.textStyl}>{t("EditNPT")}</Typography>
                <div>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            date: moment(`${selectedEditDate}T${selectedEditStartTime}Z`).format('YYYY-MM-DD'),
                            startTime: moment(selectedStartTime).format("HH:mm:ss"),
                            endTime: moment(selectedEndTime).format("HH:mm:ss"),
                            manpower: selectedEditManpower,
                            lineId: selectedEditLineId,
                            categoryId: selectedEditNptCategoryId,
                            nptTypeId: selectedEditNptTypeId,
                        }}
                        validationSchema={Schema.AddNPT(t)}
                        onSubmit={(values, { setSubmitting }) => {
                            let startTime = values.startTime;
                            let endTime = values.endTime;

                            if (typeof startTime === 'string') {
                                //@ts-ignore
                                startTime = new Date(`${moment().format('YYYY-MM-DD')} ${startTime}`);
                            }
                            if (typeof endTime === 'string') {
                                //@ts-ignore
                                endTime = new Date(`${moment().format('YYYY-MM-DD')} ${endTime}`);
                            }

                            // @ts-ignore
                            if (isNaN(startTime.getTime()) || isNaN(endTime.getTime())) {
                                Utils.showAlert(2, t("AddTimeaFormat"));
                                setSubmitting(false);
                                return;
                            }
                            dispatch(
                                editingNptType(
                                    values,
                                    setSubmitting,
                                    handleClose,
                                    selectedEditNptId,
                                    startTime,
                                    endTime,
                                ),
                            );
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                        }) => (
                            <Form>
                                <DatePicker
                                    onChange={(event: any, index: any, value: any) => {
                                        setFieldValue('date', event.target.value);
                                    }}
                                    isRequired={true}
                                    label={t("Date")}
                                    values={values.date}
                                    name={'date'}
                                    onBlur={handleBlur}
                                    helperText={touched.date && errors.date}
                                    error={Boolean(touched.date && errors.date)}
                                    stopPastDate={false}
                                />

                                <TimePicker
                                    label={t("StartTime")}
                                    isRequired={true}
                                    onChange={(date: any) => {
                                        handleStartTimeChange(date, setFieldValue);
                                    }}
                                    values={selectedStartTime}
                                    name={'startTime'}
                                    onBlur={handleBlur}
                                    helperText={touched.startTime && errors.startTime}
                                    error={Boolean(touched.startTime && errors.startTime)}
                                />
                                <TimePicker
                                    label={t("EndTime")}
                                    isRequired={true}
                                    onChange={(date: any) => {
                                        handleEndTimeChange(date, setFieldValue);
                                    }}
                                    values={selectedEndTime}
                                    name={'endTime'}
                                    onBlur={handleBlur}
                                    helperText={touched.endTime && errors.endTime}
                                    error={Boolean(touched.endTime && errors.endTime)}
                                />
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.manpower}
                                    onChange={handleChange}
                                    helperText={touched.manpower && errors.manpower}
                                    error={Boolean(touched.manpower && errors.manpower)}
                                    name={'manpower'}
                                    label={t("Manpower")}
                                    isRequired={true}
                                />

                                <AutoComplete
                                    onBlur={handleBlur}
                                    values={values.lineId}
                                    onInputChange={(event: any) => {
                                        if (event !== null) {
                                            // Do Nothing
                                        }
                                    }}
                                    onChange={(event: any, value: any) => {
                                        if (value !== null) {
                                            const index = mfgLines.findIndex((x: any) => x.id === value.id);
                                            handleChange(event);
                                            if (index >= 0) {
                                                setFieldValue('lineId', value.id);
                                            }
                                        }
                                    }}
                                    helperText={touched.lineId && errors.lineId}
                                    error={Boolean(touched.lineId && errors.lineId)}
                                    name={'lineId'}
                                    label={t("Line")}
                                    touched={touched.lineId}
                                    options={mfgLines}
                                    getOptionLabel={(option: any) => option.mfgLine}
                                    isRequired={true}
                                    getOptionSelected={(option: any, value: any) => {
                                        return `${option.id}` === `${value.id}`;
                                    }}
                                    defaultValue={() => {
                                        const index = mfgLines.findIndex((x: any) => x.id === values.lineId);
                                        return mfgLines[index];
                                    }}
                                />

                                <AutoComplete
                                    onBlur={handleBlur}
                                    values={values.categoryId}
                                    onInputChange={(event: any) => {
                                        if (event !== null) {
                                            // Do Nothing
                                        }
                                    }}
                                    onChange={(event: any, value: any) => {
                                        if (value !== null) {
                                            setNptTypeData(value.npttypes);
                                            const index = nptCategoriesData.findIndex((x: any) => x.id === value.id);
                                            handleChange(event);
                                            if (index >= 0) {
                                                setFieldValue('categoryId', value.id);
                                                if (value.nptCat) {
                                                    setFieldValue('nptTypeId', '');
                                                    setFieldValue('category', value.mfgLine);
                                                }
                                            }
                                        }
                                    }}
                                    helperText={touched.categoryId && errors.categoryId}
                                    error={Boolean(touched.categoryId && errors.categoryId)}
                                    name={'categoryId'}
                                    label={t("NPTCategory")}
                                    touched={touched.categoryId}
                                    options={nptCategoriesData}
                                    getOptionLabel={(option: any) => option.nptCat}
                                    isRequired={true}
                                    getOptionSelected={(option: any, value: any) => {
                                        return `${option.id}` === `${value.id}`;
                                    }}
                                    defaultValue={() => {
                                        if (values.nptTypeId) {
                                            const index = nptCategoriesData.findIndex(
                                                (x: any) => x.id === values.categoryId,
                                            );
                                            return nptCategoriesData[index];
                                        }
                                    }}
                                />

                                <AutoComplete
                                    onBlur={handleBlur}
                                    values={values.nptTypeId}
                                    onInputChange={(event: any) => {
                                        if (event !== null) {
                                            //Do Nothing
                                        }
                                    }}
                                    onChange={(event: any, value: any) => {
                                        if (value !== null) {
                                            const index = nptTypeData.findIndex((x: any) => x.id === value.id);
                                            handleChange(event);
                                            if (index >= 0) {
                                                setFieldValue('nptTypeId', value.id);
                                            }
                                        }
                                    }}
                                    helperText={touched.nptTypeId && errors.nptTypeId}
                                    error={Boolean(touched.nptTypeId && errors.nptTypeId)}
                                    name={'nptTypeId'}
                                    label={t("NPTType")}
                                    touched={touched.nptTypeId}
                                    options={nptTypeData}
                                    getOptionLabel={(option: any) => option.nptType}
                                    isRequired={true}
                                    getOptionSelected={(option: any, value: any) => {
                                        return `${option.id}` === `${value.id}`;
                                    }}
                                    isDisabled={nptTypeData.length === 0}
                                    defaultValue={() => {
                                        if (values.categoryId) {
                                            const index = nptTypeData.findIndex(
                                                (x: any) => x.id === selectedEditNptTypeId,
                                            );
                                            return nptTypeData[index];
                                        }
                                    }}
                                />

                                <div className={classes.saveContainer}>
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        className={classes.cancelButton}
                                        onClick={handleClose}
                                    >
                                        {t("CANCEL")}
                                    </Button>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className={classes.saveButton}
                                    >
                                        {isSubmitting ? `${t("Saving")}...` : `${t("Save")}`}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}
