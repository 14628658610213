import React from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    Divider,
    FormControlLabel,
    Checkbox,
    RadioGroup,
    Radio,
} from '@material-ui/core';
import Utils from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import {
    getNeedleTypesAll,
    getFeaturesAll,
    getMachinesAll,
    getStylesAll,
    getNPTTypesAll,
    getNPTCategoriesAll,
    getSkillAll,
    getProductAll,
    getmachineAttachmentAll,
    getoperationCodeTranslationAll,
} from '../../screens/admin/productSettings/action';
import { ReducersModel } from '../../model';
import { useTranslation } from 'react-i18next';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            minWidth: '210px',
        },
        title: {
            padding: '0 16px 8px 16px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
        subTitle: {
            padding: '8px 16px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
        filtersBox: {
            padding: '8px 16px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
            display: 'flex',
            maxHeight: '200px',
            overflowY: 'auto',
            width: '75%',
            wordBreak: 'break-word',
            flexDirection: 'column',
            '& .MuiTypography-root': {
                textAlign: 'left',
                font: 'normal normal normal 12px/21px Roboto',
                letterSpacing: '0.07px',
                color: '#868686',
                opacity: 1,
            },
            '& .MuiCheckbox-root': {
                padding: '2px 7px',
            },
            // '& .MuiFormGroup-root': { flexDirection: 'row!important' },
            '& .MuiRadio-root': {
                padding: '3px 9px',
            },
        },
    }),
);

export default function ProductSettingsFilter(props: any) {
    const classes = styles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { statusAll, language1Filter, language2Filter } = useSelector(
        (state: ReducersModel) => state.masterDataTranslationReducer,
    );
    const updateList = () => {
        const masterDataSelected = props.masterDataSelected;
        if (props.masterDataSelected) {
            if (masterDataSelected === Utils.constants.masterDataSelection.needleType.index) {
                dispatch(getNeedleTypesAll());
                return;
            }

            if (masterDataSelected === Utils.constants.masterDataSelection.ppa.index) {
                dispatch(getFeaturesAll());
                return;
            }

            if (masterDataSelected === Utils.constants.masterDataSelection.nptCategory.index) {
                dispatch(getNPTCategoriesAll());
                return;
            }

            if (masterDataSelected === Utils.constants.masterDataSelection.nptType.index) {
                dispatch(getNPTTypesAll());
                return;
            }

            if (masterDataSelected === Utils.constants.masterDataSelection.machines.index) {
                dispatch(getMachinesAll());
                return;
            }

            if (masterDataSelected === Utils.constants.masterDataSelection.styles.index) {
                dispatch(getStylesAll());
                return;
            }
            if (masterDataSelected === Utils.constants.masterDataSelection.productType.index) {
                dispatch(getProductAll());
                return;
            }

            if (masterDataSelected === Utils.constants.masterDataSelection.skillType.index) {
                dispatch(getSkillAll());
                return;
            }
            if (masterDataSelected === Utils.constants.masterDataSelection.machineAttachment.index) {
                dispatch(getmachineAttachmentAll());
                return;
            }
            if (masterDataSelected === Utils.constants.masterDataSelection.operationCode.index) {
                dispatch(getoperationCodeTranslationAll());
                return;
            }
        }
    };

    return (
        <div className={classes.mainContainer}>
            <div className={classes.title}>{t('Filterby')} </div>
            <Divider />
            <div>
                <div className={classes.subTitle}>{t('StringTranslateStatus')} </div>
                <div className={classes.filtersBox}>
                    <RadioGroup
                        id="status"
                        value={statusAll}
                        onChange={(event: any) => {
                            dispatch({
                                type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                                payload: {
                                    statusAll: +event.target.value,
                                },
                            });
                            updateList();
                        }}
                    >
                        <FormControlLabel value={1} control={<Radio color="primary" />} label={t('All')} />{' '}
                        <FormControlLabel
                            value={2}
                            control={<Radio color="primary" />}
                            label={t('TranslatedStrings')}
                        />
                        <FormControlLabel
                            value={3}
                            control={<Radio color="primary" />}
                            label={t('UntranslatedStrings')}
                        />
                    </RadioGroup>
                </div>
            </div>
            <Divider />

            <div>
                <div className={classes.subTitle}>{t('Language')} </div>
                <div className={classes.filtersBox}>
                    <FormControlLabel
                        value={'true'}
                        control={
                            <Checkbox
                                color={'primary'}
                                name="shift"
                                checked={language1Filter}
                                onChange={(event: any) => {
                                    const target = event.target;
                                    dispatch({
                                        type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                                        payload: {
                                            language1Filter: target.checked,
                                        },
                                    });
                                    updateList();
                                }}
                            />
                        }
                        label={t('ChineseSimplified')}
                    />

                    <FormControlLabel
                        value={'false'}
                        checked={language2Filter}
                        control={
                            <Checkbox
                                color={'primary'}
                                name="shift"
                                onChange={(event: any) => {
                                    const target = event.target;
                                    dispatch({
                                        type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                                        payload: {
                                            language2Filter: target.checked,
                                        },
                                    });
                                    updateList();
                                }}
                            />
                        }
                        label={t('Khmer')}
                    />
                </div>
            </div>
        </div>
    );
}
