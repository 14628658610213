import React from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    Typography,
    Button,
    FormControlLabel,
    Radio,
    RadioGroup,
    MenuItem,
    TextField,
    InputAdornment,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import Schema from '../../../schema';
import { useTranslation } from 'react-i18next';
import NormalInput from '../../../components/inputs/normalInput';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModel } from '../../../model';
import { addOperationCode, editOperatorCode } from '../action';
import OutlineInput from '../../../components/inputs/outlineInput';
import moment from 'moment';
import SelectPicker from '../../../components/selectPicker';
import LocalImages from '../../../utils/images';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Utils from '../../../utils';
import TimeInput from '../../../components/inputs/TimeInput';
import { minuteToTime } from '../../../utils/convertTime';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            '& .MuiTextField-root': {
                marginBottom: '10px!important',
            },
        },
        textStyl: {
            fontSize: '20px',
            color: 'var(--dark-grey)',
            margin: '44px 0 0 0',
        },
        attchament: {
            margin: '10px 0 0 0',
        },
        paper: {
            position: 'absolute',
            width: '25%',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            maxHeight: '80vh',
            overflow: 'auto',
        },
        saveContainer: {
            backgroundColor: 'white',
            padding: '10px 0',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '12px',
        },
        cancelButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#515151',
            opacity: 1,
            height: '36px',
        },
        saveButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#FFFFFF',
            opacity: 1,
            height: '36px',
        },
        subHeading: {
            textAlign: 'left',
            font: 'normal normal normal 12px/21px Roboto',
            letterSpacing: '0.07px',
            color: '#8C8C8C',
            opacity: 1,
            marginBottom: '3px',
        },
        author: {
            marginTop: '15px',
        },
        statusContainer: {
            display: 'flex',
            alignItems: 'center',
            '& .MuiFormGroup-root': { flexDirection: 'row' },
            '& .MuiTypography-root': {
                textAlign: 'left',
                font: 'normal normal normal 14px/21px Roboto',
                letterSpacing: '0.08px',
                color: '#515151',
                opacity: 1,
            },
        },
        status: {
            marginRight: '40px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
        searchInput: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            width: '100%',
            padding: '10px 0',
            '& .MuiInputBase-root': {
                height: '36px',
                font: 'normal normal normal 14px/20px Roboto',
                letterSpacing: '0.05px',
                color: '#515151',
                opacity: 1,
            },
        },
        searchIcon: {
            cursor: 'pointer',
        },
        imgContainer: {
            display: 'flex',
        },
        innerImgContainer: {
            marginRight: '20px',
            position: 'relative',
            '& .MuiTypography-body1': {
                font: 'normal normal normal 11px/21px Roboto',
                opacity: 1,
            },
        },
        attachmentImage: {
            height: '64px',
            width: '64px',
            border: '1px solid black',
        },
        deleteStyle: {
            position: 'absolute',
            top: '5%',
            right: '5%',
            cursor: 'pointer',
            height: '16px',
            width: '16px',
        },
        machineCode: {
            margin: '7px 0 5px 0',
            '& .MuiOutlinedInput-root': {
                padding: '2px 9px',
            },
            '& .MuiAutocomplete-endAdornment': {
                display: 'none',
            },
            '& .MuiInputBase-root': {
                fontSize: '14px',
            },
        },
    }),
);

export default function AddOperationCodeForm({ handleClose }: any) {
    const classes = styles();
    const { t } = useTranslation();
    const {
        isEditOperation,
        operationCodeEdit,
        smvEdit,
        tctEdit,
        descEdit,
        idEdit,
        selectedisActive,
        createdBy,
        updatedBy,
        updatedDt,
        createdDt,
        displayNameEdit,
        selectedSkillType,
        selectedSkillTypeCode,
        selectedSkillTypeName,
        factoryName,
        firstLangDataEdit,
        secondLangDataEdit,
        selectedMachineId1,
        selectedMachineId2,
        selectedMachineId3,
    } = useSelector((state: ReducersModel) => state.operationCodeReducer);
    const { ppaCodeData, skillTypeData } = useSelector((state: ReducersModel) => state.ppaCodeReducer);
    const { firstLangDesc, secondLangDesc } = useSelector((state: ReducersModel) => state.globalLangDataReducer);

    const dispatch = useDispatch();

    function getModalStyle() {
        const top = 50;
        const left = 50;
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const skillListing = () => {
        return skillTypeData && skillTypeData.length > 0
            ? skillTypeData.map((data: any, index: any) => {
                  return (
                      <MenuItem disabled={data.isActive ? false : true} key={data.id} value={data.id}>
                          {data.skillType}
                      </MenuItem>
                  );
              })
            : null;
    };

    const getMachines = () => {
        const tempMachine = ppaCodeData?.filter((machine: any) => {
            return (
                machine.id === selectedMachineId1 ||
                machine.id === selectedMachineId2 ||
                machine.id === selectedMachineId3
            );
        });
        return tempMachine;
    };

    const [modalStyle] = React.useState(getModalStyle);

    return (
        <div style={modalStyle} className={classes.paper}>
            <div className={classes.mainContainer}>
                <Typography className={classes.textStyl}>
                    {isEditOperation ? t('EditOperation') : t('AddOperation')}
                </Typography>
                <div>
                    <Formik
                        initialValues={{
                            operationCode: isEditOperation ? operationCodeEdit : '',
                            displayName: isEditOperation ? displayNameEdit : '',
                            firstLangData: (isEditOperation && firstLangDataEdit) || '',
                            secondLangData: (isEditOperation && secondLangDataEdit) || '',
                            description: isEditOperation ? descEdit : '',
                            smv: isEditOperation ? smvEdit : '',
                            tct: isEditOperation ? minuteToTime(tctEdit) : '',
                            isActive: `${selectedisActive}`,
                            skillTypeId: isEditOperation ? selectedSkillType : '',
                            skillTypeName: isEditOperation ? selectedSkillTypeName : '',
                            skillTypeCode: isEditOperation ? selectedSkillTypeCode : '',
                            firstLangIsGoogle: false,
                            secondLangIsGoogle: false,
                            machineCodes: isEditOperation ? getMachines() : [],
                        }}
                        validationSchema={Schema.AddOperationCodeSchema(t)}
                        onSubmit={(values, { setSubmitting }) => {
                            if (isEditOperation) {
                                dispatch(editOperatorCode(values, setSubmitting, handleClose, idEdit));
                            } else {
                                dispatch(addOperationCode(values, setSubmitting, handleClose));
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, isSubmitting, touched, values, setFieldValue }) => (
                            <Form>
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.operationCode}
                                    onChange={handleChange}
                                    helperText={touched.operationCode && errors.operationCode}
                                    error={Boolean(touched.operationCode && errors.operationCode)}
                                    name={'operationCode'}
                                    label={t('OperationCode')}
                                    isRequired={true}
                                />
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.displayName}
                                    onChange={handleChange}
                                    helperText={touched.displayName && errors.displayName}
                                    error={Boolean(touched.displayName && errors.displayName)}
                                    name={'displayName'}
                                    label={t('DisplayName')}
                                    placeholder={t('OperationName')}
                                    isRequired={true}
                                />
                                <OutlineInput
                                    values={values.firstLangData}
                                    onChange={handleChange}
                                    name={'firstLangData'}
                                    translation={true}
                                    translationHandle={() =>
                                        // translate(
                                        //     bay.name,
                                        //     Utils.constants.firstLanguage,
                                        //     'firstLang',
                                        //     index,
                                        //     'firstLangIsGoogle',
                                        // )
                                        console.log('heere')
                                    }
                                    helperText={touched.firstLangData && errors.firstLangData}
                                    error={Boolean(touched.firstLangData && errors.firstLangData)}
                                    label={`Display Name (${firstLangDesc} Translation)`}
                                    isRequired={false}
                                />
                                <OutlineInput
                                    values={values.secondLangData}
                                    name={'secondLangData'}
                                    onChange={handleChange}
                                    translation={true}
                                    translationHandle={() =>
                                        // translate(
                                        //     bay.name,
                                        //     Utils.constants.secondLanguage,
                                        //     'secondLang',
                                        //     index,
                                        //     'secondLangIsGoogle',
                                        // )
                                        console.log('heere')
                                    }
                                    helperText={touched.secondLangData && errors.secondLangData}
                                    error={Boolean(touched.secondLangData && errors.secondLangData)}
                                    label={`Display Name (${secondLangDesc} Translation)`}
                                    isRequired={false}
                                />
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.description}
                                    onChange={handleChange}
                                    helperText={touched.description && errors.description}
                                    error={Boolean(touched.description && errors.description)}
                                    name={'description'}
                                    label={t('DESCRIPTION')}
                                    isRequired={true}
                                />
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.smv}
                                    onChange={handleChange}
                                    helperText={touched.smv && errors.smv}
                                    error={Boolean(touched.smv && errors.smv)}
                                    name={'smv'}
                                    label={t('SMV')}
                                    isRequired={true}
                                />
                                <TimeInput
                                    onBlur={handleBlur}
                                    values={values.tct}
                                    onChange={handleChange}
                                    helperText={touched.tct && errors.tct}
                                    error={Boolean(touched.tct && errors.tct)}
                                    name={'tct'}
                                    label={t('Target Cycle Time (mm:ss.ms)')}
                                    placeholder="mm:ss.ms"
                                    isRequired={false}
                                />
                                <SelectPicker
                                    values={values.skillTypeId}
                                    onChange={(event: any) => {
                                        const value = event.target.value;
                                        const index = skillTypeData.findIndex((x: any) => x.id === value);
                                        setFieldValue('skillTypeId', value);
                                        setFieldValue('skillTypeName', skillTypeData[index].displayName);
                                        setFieldValue('skillTypeCode', skillTypeData[index].skillType);
                                    }}
                                    name={'skillTypeId'}
                                    label={t('SkillType')}
                                    data={skillListing()}
                                    isRequired={true}
                                    onBlur={handleBlur}
                                    touched={touched.skillTypeId}
                                    errors={errors.skillTypeId}
                                />
                                <Typography className={`${classes.textStyl} ${classes.attchament}`}>
                                    {t('Attachment')}
                                </Typography>
                                <Autocomplete
                                    options={ppaCodeData}
                                    multiple
                                    value={values.machineCodes}
                                    className={classes.machineCode}
                                    getOptionLabel={(option) => option.attachmentCode}
                                    getOptionDisabled={(option: any) => {
                                        if (option.isActive) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    }}
                                    onChange={(e: any, value: any) => {
                                        setFieldValue('machineCodes', value);
                                    }}
                                    disabled={values.machineCodes.length >= 3}
                                    onBlur={handleBlur}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name={'machineCodes'}
                                            variant="outlined"
                                            placeholder={`${t('Search')} ${t('AttachmentCode')}`}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <img
                                                            alt={t('Search')}
                                                            src={LocalImages.SEARCH_ICON}
                                                            className={classes.searchIcon}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                                <div>
                                    {values.machineCodes.length > 0 && (
                                        <div className={classes.imgContainer}>
                                            {values.machineCodes.map((machine: any) => {
                                                const { image1, id, attachmentCode } = machine;
                                                if (image1 !== null) {
                                                    return (
                                                        <div className={classes.innerImgContainer} key={id}>
                                                            <img
                                                                alt={t('AttachmentCode')}
                                                                src={Utils.CommonFunctions.getImage(image1)}
                                                                className={classes.attachmentImage}
                                                            />
                                                            <Typography>{attachmentCode}</Typography>
                                                            <img
                                                                src={LocalImages.CANCEL}
                                                                alt={t('Close')}
                                                                className={classes.deleteStyle}
                                                                onClick={() => {
                                                                    const tempMachineArray = values.machineCodes.filter(
                                                                        (machine: any) => {
                                                                            return machine.id !== id;
                                                                        },
                                                                    );
                                                                    setFieldValue('machineCodes', tempMachineArray);
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                } else {
                                                    return;
                                                }
                                            })}
                                        </div>
                                    )}
                                </div>

                                {isEditOperation && (
                                    <>
                                        <div className={classes.statusContainer}>
                                            <span className={classes.status}>{t('Status')}: </span>
                                            <RadioGroup
                                                id="isActive"
                                                value={values.isActive}
                                                onChange={(event: any) => {
                                                    setFieldValue('isActive', `${event.target.value}`);
                                                }}
                                            >
                                                <FormControlLabel
                                                    value={`true`}
                                                    control={<Radio color="primary" />}
                                                    label={t('Active')}
                                                />
                                                <FormControlLabel
                                                    value={`false`}
                                                    control={<Radio color="primary" />}
                                                    label={t('Inactive')}
                                                />
                                            </RadioGroup>
                                        </div>

                                        <div className={classes.author}>
                                            <div className={classes.subHeading}>
                                                {t('MODIFIEDBY')}: {updatedBy ? updatedBy : '-'}
                                            </div>
                                            <div className={classes.subHeading}>
                                                {t('DATEMODIFIED')}:{' '}
                                                {updatedDt !== null ? moment(updatedDt).format('YYYY-MM-DD') : '-'}
                                            </div>
                                            <div className={classes.subHeading}>
                                                {t('Createdby')}: {createdBy ? createdBy : '-'}
                                            </div>
                                            <div className={classes.subHeading}>
                                                {t('CREATEDBYFACTORY')}: {factoryName ? factoryName : '-'}
                                            </div>
                                            <div className={classes.subHeading}>
                                                {t('DateCreated')}:{' '}
                                                {createdDt ? moment(createdDt).format('YYYY-MM-DD') : '-'}
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div className={classes.saveContainer}>
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        className={classes.cancelButton}
                                        onClick={handleClose}
                                    >
                                        {t('CANCEL')}
                                    </Button>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className={classes.saveButton}
                                    >
                                        {isSubmitting ? `${t('Saving')}...` : t('Save')}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}
