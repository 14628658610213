import * as React from 'react';
import {
    Grid,
    Box,
} from '@material-ui/core';
import {
    HeadTitle,
    Title,
    BoxContainer,
    CustomGrid,
} from './../styles';

const BoxRowHeader = () => {
    return (
        <Box>
            <Grid container style={{ gap: 10, flexWrap: 'nowrap' }}>
                <CustomGrid item xs={2}>
                    <HeadTitle>STATION</HeadTitle>
                    <BoxContainer>
                            <Title 
                                style={{ textAlign: 'center', maxWidth: '100%', width: '100%' }}>
                                #
                            </Title>
                    </BoxContainer>
                </CustomGrid>
                <CustomGrid item xs={6}>
                    <HeadTitle>FEATURE & SEWING OPERATIONS</HeadTitle>
                    <BoxContainer>
                        <Grid container>
                            <Grid item xs={1}><Title>STEP</Title></Grid>
                            <Grid item xs={3}><Title>Description</Title></Grid>
                            <Grid item xs={1}><Title>M/C</Title></Grid>
                            <Grid item xs={1}><Title>Needle Type</Title></Grid>
                            <Grid item xs={1}><Title>Img</Title></Grid>
                            <Grid item xs={1}><Title>SMV</Title></Grid>
                            <Grid item xs={2}><Title>TCT</Title></Grid>
                            <Grid item xs={1}><Title>THEOR MPWR</Title></Grid>
                        </Grid>
                    </BoxContainer>
                </CustomGrid>
                <CustomGrid item xs={2}>
                    <HeadTitle>OPERATOR IN LINE</HeadTitle>
                    <BoxContainer>
                        <Grid container>
                            <Grid item xs={4}><Title>Operator</Title></Grid>
                            <Grid item xs={4}><Title>ACT MPWR</Title></Grid>
                            <Grid item xs={4}><Title>EFF</Title></Grid>
                        </Grid>
                    </BoxContainer>
                </CustomGrid>
                <Grid item xs={2}>
                </Grid>
            </Grid>
        </Box>
    )
}

export default BoxRowHeader;