import React from 'react';
import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        inputField: {
            '& .MuiInputBase-input': {
                font: 'normal normal normal 14px/14px Roboto',
                letterSpacing: '0.07px',
                opacity: 1,
            },
            '& .MuiFormLabel-root': {
                font: 'normal normal normal 12px/21px Roboto',
                letterSpacing: '0.07px',
                opacity: 1,
            },
            '& .MuiFormControl-marginNormal': {
                marginTop: 'unset',
            },
        },
        shiftInputField: {
            '& .MuiFormControl-marginNormal': {
                marginTop: 'unset',
                marginBottom: '-3px',
            },
        },
        errorMessage: {},
        required: {
            color: '#D32F2F',
        },
    }),
);
interface Props {
    label?: string;
    placeholder?: string;
    id?: string;
    name?: string;
    type?: string;
    onBlur?: any;
    values?: any;
    onChange?: any;
    style?: any;
    helperText?: any;
    error?: any;
    isRequired?: boolean;
    touched?: any;
    options: any;
    // defaultProps?: any;
    getOptionLabel: any;
    onInputChange: any;
    getOptionSelected?: any;
    inputValue?: any;
    onClose?: any;
    defaultValue?: any;
    isDisabled?: any;
    getOptionDisabled?: any;
    addStyle?: boolean;
    autoCompleteValue?: any;
}

export default function AutoComplete({
    label,
    placeholder,
    id,
    name,
    type,
    onBlur,
    values,
    onChange,
    style,
    helperText,
    isRequired,
    error,
    options,
    getOptionLabel,
    onInputChange,
    getOptionSelected,
    inputValue,
    defaultValue,
    onClose,
    isDisabled,
    getOptionDisabled,
    addStyle,
    autoCompleteValue,
}: // defaultProps,
Props) {
    const classes = styles();

    return (
        <React.Fragment>
            <Autocomplete
                // {...defaultProps}
                getOptionLabel={getOptionLabel}
                id="auto-select"
                autoSelect={false}
                options={options}
                // defaultValue={''}
                disableClearable
                onClose={onClose}
                // inputValue={inputValue ? inputValue : ''}
                // getOptionSelected={(item: any, current: any) => item.value === current.value}
                onInputChange={onInputChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        // label={label}
                        disabled={isDisabled}
                        label={
                            <span>
                                {label}
                                {isRequired ? <span className={classes.required}> *</span> : <span></span>}
                            </span>
                        }
                        margin="normal"
                        helperText={helperText}
                        error={error}
                        name={name}
                        type={type}
                        value={values ? values : ''}
                        defaultValue={values}
                        // defaultValue={''}
                        // required={isRequired}
                    />
                )}
                onBlur={onBlur}
                // value={values}
                onChange={onChange}
                value={autoCompleteValue}
                placeholder={placeholder}
                fullWidth
                size={'small'}
                className={`${classes.inputField} ${addStyle && classes.shiftInputField}`}
                // getOptionSelected={getOptionSelected}
                // defaultValue={'-'}
                defaultValue={defaultValue}
                getOptionDisabled={getOptionDisabled}
            />
        </React.Fragment>
    );
}
