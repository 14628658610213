import { Reducer, combineReducers } from 'redux';
import dataGlobalReducer from './dataGlobalReducer';
import dataGraphReducer from './dataGraphReducer';
import dataListReducer from './dataListReducer';

const reducers: any = {
    lbrGraph: dataGraphReducer,
    lbrRows: dataListReducer,
    lbrGlobal: dataGlobalReducer,
};

export default reducers;