import { LoadingModel } from '../model';
import Utils from '../utils';

/**
 * Global Loading reducer
 */
export const globalLoaderReducer = (state: LoadingModel = new LoadingModel(), action: any) => {
    //@ts-ignore
    // const { payload } = action;
    switch (action.type) {
        case Utils.ActionName.LOADING:
            return { ...state, mainLoading: action.payload };
        case Utils.ActionName.IS_LOADING:
            return { ...state, isLoading: action.payload };
        // return Object.assign({}, state, {
        //   //@ts-ignore
        //   [`${payload.scope}Loading`]: payload.isLoading
        // });
        default:
            return state;
    }
};
