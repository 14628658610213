import Utils from '../../utils';

/**
 * Storing the data from global LT API for session for further process
 */
const saveResponseDataForSession = (data: any) => {
    localStorage.setItem('accessToken', data.data.accessToken);
    localStorage.setItem('refreshToken', data.data.refreshToken);
    localStorage.setItem('userId', data.data.userId);
    if (localStorage.getItem('browserLanguageCode')) {
        Utils.constants.axios.defaults.headers.common['lang'] = localStorage.getItem('browserLanguageCode');
    } else {
        Utils.constants.axios.defaults.headers.common['lang'] = 'en';
    }
    Utils.constants.setAuthorizationToken(data.data.accessToken);
};

const langHeader: any = {
    'zh-CN': 'chi', //Chinese (Simplified)
    'zh-TW': 'chi', //Chinese (Traditional)
    in: 'ind', //Indonesian
    km: 'khm', //Khmer
    es: 'spa', //Spanish
    vi: 'vie', //Vietnamese,
    bhs: 'bhs', //Bahasa
    ind: 'ind', //Bahasa
};

export const OnSubmit = (values: any, setSubmitting: any, history: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const { username, password } = values;
        const dataToSend = {
            username,
            password,
            // companyCode: 'AI',
            // companyCode: 'AI-LTIE-STG',
            // companyCode: 'AI-LTIE-QA',
            // companyCode: 'STX-LTIE-PILOT',
            companyCode: Utils.constants.companyCodeBase,
        };
        console.log(dataToSend);
        Utils.api.postApiCall(
            Utils.endPoints.login,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.SIGN_IN,
                        payload: {
                            username: dataToSend.username,
                            password: dataToSend.password,
                            accountError: '',
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });

                    setSubmitting(false);
                    Utils.showAlert(1, 'Successfully logged in!');
                    saveResponseDataForSession(data);

                    // localStorage.setItem('accessToken', data.data.accessToken);
                    // localStorage.setItem('refreshToken', data.data.refreshToken);
                    // localStorage.setItem('userId', data.data.userId);
                    // Utils.constants.setAuthorizationToken(data.data.accessToken);
                    history.push(Utils.constants.paths.DASHBOARD);
                    dispatch(getSelectedFactory());
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.SIGN_IN,
                        payload: {
                            username: '',
                            password: '',
                            accountError: data.message,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                setSubmitting(false);
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.SIGN_IN,
                    payload: {
                        username: '',
                        password: '',
                        accountError: data.message,
                    },
                });
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getFactory = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = '';
        Utils.api.getApiCallFactory(
            Utils.constants.factoryURLBase,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.UPDATE_FACTORY,
                        payload: {
                            factoryData: innerData,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getLanguage = () => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        let parameters = `/${localStorage.getItem('factoryId')}`;
        Utils.api.getApiCallLanguage(
            Utils.constants.languageURLBase,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.success === true) {
                    const innerData = data.data;
                    const { listLanguages } = innerData[0];
                    dispatch({
                        type: Utils.ActionName.UPDATE_FACTORY,
                        payload: {
                            factoryLanguages: innerData,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    if (listLanguages.length === 1) {
                        if (
                            listLanguages[0].language !== 'zh-CN' &&
                            listLanguages[0].language !== 'km' &&
                            listLanguages[0].language !== 'zh-TW'
                        ) {
                            dispatch({
                                type: Utils.ActionName.GLOBAL_LANG_DATA,
                                payload: {
                                    globalLanguages: listLanguages,
                                    firstLang: langHeader[listLanguages[0].language],
                                    firstLangData: '',
                                    firstLangDesc: listLanguages[0].desc1,
                                    firstLangCode: listLanguages[0].language,
                                },
                            });
                        } else {
                            dispatch({
                                type: Utils.ActionName.GLOBAL_LANG_DATA,
                                payload: {
                                    globalLanguages: listLanguages,
                                },
                            });
                        }
                    }
                    if (listLanguages.length === 2) {
                        dispatch({
                            type: Utils.ActionName.GLOBAL_LANG_DATA,
                            payload: {
                                globalLanguages: listLanguages,
                                firstLang: langHeader[listLanguages[0].language],
                                firstLangData: '',
                                firstLangDesc: listLanguages[0].desc1,
                                firstLangCode: listLanguages[0].language,
                                secondLang: langHeader[listLanguages[1].language],
                                secondLangData: '',
                                secondLangDesc: listLanguages[1].desc1,
                                secondLangCode: listLanguages[1].language,
                            },
                        });
                    }
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const selectFactory = (factoryId: number) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = {
            factoryId,
            accessToken: localStorage.getItem('accessToken'),
        };
        Utils.api.postApiCallFactory(
            Utils.endPoints.factoryChange,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.status === Utils.constants.api_success_code.success) {
                    localStorage.setItem('refreshToken', data.refreshToken);
                    localStorage.setItem('accessToken', data.accessToken);
                    dispatch({
                        type: Utils.ActionName.UPDATE_FACTORY,
                        payload: {
                            activeFactoryId: factoryId,
                            darwerOpen: false,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    localStorage.setItem('factoryId', factoryId.toString());
                    window.location.reload();
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getSelectedFactory = () => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = '';

        Utils.api.getApiCall(
            Utils.endPoints.tokenDetails,
            parameters,
            (respData: any) => {
                let { data } = respData;
                // For users data
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.UPDATE_FACTORY,
                        payload: {
                            activeFactoryId: data.data.factoryId,
                        },
                    });
                    localStorage.setItem('usernamedata', data.data.username);
                    localStorage.setItem('factoryId', data.data.factoryId);
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const companyCodeLogin = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = {
            companyCode: localStorage.getItem('companyCode'),
        };
        Utils.api.postApiCall(
            Utils.endPoints.companyCodeLoginAdmin,
            parameters,
            (respData: any) => {
                let { data } = respData;
                localStorage.setItem('companyCodeURLAdmin', data.url);
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};
