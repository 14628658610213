import * as React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { CustomBox } from '../../styles';

export default ({ rows }: any) => {
    return (
        <Grid item xs={2}>
            {rows?.map((item: any) => {
                return (
                    item.operatorInlines?.map((operator: any) => {
                        return (
                            <CustomBox
                                style={{ padding: '0 5px' }}
                                background={operator.code ? '#fff' : 'transparent'}
                                height={operator.height}>
                                {operator.code 
                                ? (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}>
                                        <div>
                                            <Typography variant='subtitle2'>{operator.code}</Typography>
                                            <Typography variant='caption'>
                                                {operator.skills.length > 0 && operator.skills.join(',')}
                                            </Typography>
                                        </div>
                                        <div>
                                            {parseInt(operator.mpwr)}
                                        </div>
                                        <Typography style={{ fontWeight: 'bold', fontSize: 17 }}>{operator.eff}</Typography>
                                    </div>
                                )
                                :  (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                        <Typography style={{ fontWeight: 'bold', fontSize: 13 }}>Not Assigned</Typography>
                                    </div>
                                )}
                            </CustomBox>
                        )
                    })
                )
            })}
        </Grid>
    )
}