import { productSettingsModel, masterDataTranslation } from '../../../model';
import Utils from '../../../utils';

export const productSettingReducer = (state: productSettingsModel = new productSettingsModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_PRODUCT_SETTINGS: {
            return { ...state, ...action.payload };
        }
        default:
            return state;
    }
};

export const masterDataTranslationReducer = (
    state: masterDataTranslation = new masterDataTranslation(),
    action: any,
) => {
    switch (action.type) {
        case Utils.ActionName.MASTER_DATA_TRANSLATION:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
