import * as React from 'react';
import { LBRTablePagination } from '../../../styles';
import { TableFOoterProp } from '../../../Interfaces';

const TableFooter: React.FC<TableFOoterProp> = ({
    count,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
}) => {
    return (
        <LBRTablePagination
            count={count}
            page={page}
            onPageChange={(__, page) => onChangePage(page)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onChangeRowsPerPage}
        />
    )
}

export default TableFooter;