import React from 'react';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    makeStyles,
    Theme,
    createStyles,
    TablePagination,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReducersModel } from '../../../model';
import Utils from '../../../utils';
//@ts-ignore
import Highlighter from 'react-highlight-words';
import OutlineInput from '../../../components/inputs/outlineInput';
import SimpleModal from '../../../components/modal';
import TranslationModal from '../../../components/modal/translationModal';
import {
    needleTypetranslation,
    ppaTranslationUpdate,
    nptCategoriesTranslationUpdate,
    nptTypesTranslationUpdate,
    machinesTranslationUpdate,
    stylesTranslationUpdate,
    productTypesTranslationUpdate,
    skillTypesTranslationUpdate,
    machineAttachmentTranslationUpdate,
} from './action';
//@ts-ignore
var googleTranslate = require('google-translate')('AIzaSyBZ4GpFQP8mZ0swkJyM-dbr0FWRGBjGGbU');

const styles = makeStyles((theme: Theme) =>
    createStyles({
        categories: {
            '& th': {
                font: 'normal normal normal 14px/21px Roboto',
                letterSpacing: '0.08px',
                color: '#000000DE',
                textTransform: 'uppercase',
                opacity: 1,
                padding: '16px 0px',
                background: '#F7F8FA 0% 0% no-repeat padding-box',
                verticalAlign: 'top',
                '&>span': {
                    cursor: 'pointer',
                },
            },
        },
        categoriesItems: {
            padding: '7px',
            borderBottom: '1px solid #cbcbcb',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#000000DE',
            textTransform: 'capitalize',
            opacity: 1,
            '& td': {
                padding: '16px 16px 16px 0',
                wordBreak: 'break-word',
                verticalAlign: 'top',
                '-webkit-line-clamp': '1',
                '-webkit-box-orient': 'vertical',
            },
            '& td:nth-child(1)': {
                width: '4%',
            },
            '& td:nth-child(2)': {
                width: '14%',
                textAlign: 'left',
            },
            '& td:nth-child(3)': {
                width: '10%',
            },
            '& td:nth-child(4)': {
                width: '10%',
            },
            '& td:nth-child(5)': {
                width: '10%',
            },
            '& td:nth-child(6)': {
                width: '10%',
            },
            '& td:nth-child(7)': {
                width: '10%',
            },
            '& td:nth-child(8)': {
                width: '19%',
            },
            '& td:nth-child(9)': {
                width: '14%',
            },
        },
        noRecords: {
            marginTop: '25px',
            marginBottom: '14px',
            textAlign: 'center',
            font: 'normal normal normal 16px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#707070',
            opacity: 1,
        },
        tableContainer: {
            overflowX: 'unset',
            '& .MuiTableHead-root': {
                position: 'sticky',
                top: '-2px',
                zIndex: 1,
            },
        },
        paginationContainer: {
            marginTop: '40px',
            marginBottom: '50px',
            backgroundColor: '#F7F8FA',
            '& .MuiTypography-root': {
                textAlign: 'left',
                font: 'normal normal normal 14px/14px Roboto',
                letterSpacing: '0px',
                color: '#515151',
                opacity: 1,
            },
        },
    }),
);

function MasterDataTransation({ listData, listName, resetTranslation, tableName }: any) {
    const { t } = useTranslation();
    const classes = styles();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state: ReducersModel) => state.globalLoaderReducer);
    const [transateDiscardPopup, setTranslateDiscardPopup] = React.useState(false);
    /**
     * handle change of first language field
     */
    const {
        search,
        firstLangCount,
        secondLangCount,
        totalLangCount,
        page,
        limit,
        count,
        isTranslated,
        nextPrev,
    } = useSelector((state: ReducersModel) => state.masterDataTranslationReducer);

    const handleLang1 = (event: any, id: any, index: any) => {
        dispatch({
            type: Utils.ActionName.MASTER_DATA_TRANSLATION,
            payload: {
                isTranslated: true,
            },
        });
        let list = listData;
        list[index].firstLang = event.target.value;
        list[index]['firstLangIsGoogle'] = false;
        list[index]['firstLangUpdated'] = true;
        list[index]['isFirstManualUpdate'] = true;
        dispatchNewData(list);
    };

    const handleLang2 = (event: any, id: any, index: any) => {
        dispatch({
            type: Utils.ActionName.MASTER_DATA_TRANSLATION,
            payload: {
                isTranslated: true,
            },
        });
        let list = listData;
        list[index].secondLang = event.target.value;
        list[index]['secondLangIsGoogle'] = false;
        list[index]['secondLangUpdated'] = true;
        list[index]['isSecondManualUpdate'] = true;
        dispatchNewData(list);
    };
    /*******
     * Translation
     * ********/
    const translate = async (text: string, target: any, nameLanguage: any, index: any, isTrans: any) => {
        dispatch({
            type: Utils.ActionName.MASTER_DATA_TRANSLATION,
            payload: {
                isTranslated: true,
            },
        });
        let list = listData;

        googleTranslate.translate(text, target, function (err: any, translation: any) {
            list[index][nameLanguage] = translation.translatedText;
            list[index][isTrans] = true;
            list[index][`${nameLanguage}Updated`] = true;
            if (nameLanguage === 'firstLang') {
                list[index]['isFirstManualUpdate'] = false;
                list[index]['firstLangIsGoogle'] = true;
            }
            if (nameLanguage === 'secondLang') {
                list[index]['isSecondManualUpdate'] = false;
                list[index]['secondLangIsGoogle'] = true;
            }
            dispatchNewData(list);
        });
    };
    const dispatchNewData = (list: any) => {
        switch (listName) {
            case 'Needle Type':
                dispatch({
                    type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                    payload: {
                        needleTypeData: list,
                    },
                });
                break;
            case 'Features':
                dispatch({
                    type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                    payload: {
                        ppaData: list,
                    },
                });
                break;
            case 'NPT Category':
                dispatch({
                    type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                    payload: {
                        nptCategoryData: list,
                    },
                });
                break;
            case 'NPT Type':
                dispatch({
                    type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                    payload: {
                        nptTypeData: list,
                    },
                });
                break;
            case 'Machines':
                dispatch({
                    type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                    payload: {
                        machineData: list,
                    },
                });
                break;
            case 'Styles':
                dispatch({
                    type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                    payload: {
                        styleData: list,
                    },
                });
                break;
            case 'Product Type':
                dispatch({
                    type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                    payload: {
                        productTypeData: list,
                    },
                });
                break;
            case 'Skill Type':
                dispatch({
                    type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                    payload: {
                        skillTypeData: list,
                    },
                });
                break;
            case 'Machine Attachment':
                dispatch({
                    type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                    payload: {
                        machineAttachmentData: list,
                    },
                });
                break;
        }
    };

    /*******Handle pagination**********/
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        dispatch({
            type: Utils.ActionName.MASTER_DATA_TRANSLATION,
            payload: {
                nextPrev: newPage,
            },
        });
        if (isTranslated) {
            setTranslateDiscardPopup(true);
        } else {
            dispatch({
                type: Utils.ActionName.MASTER_DATA_TRANSLATION,
                payload: {
                    page: newPage + 1,
                },
            });
            resetTranslation();
        }
    };

    /***************Handle row per page*******************/
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({
            type: Utils.ActionName.MASTER_DATA_TRANSLATION,
            payload: {
                limit: event.target.value,
                page: 1,
            },
        });
        resetTranslation();
    };

    const discardChanges = () => {
        setTranslateDiscardPopup(false);
        dispatch({
            type: Utils.ActionName.MASTER_DATA_TRANSLATION,
            payload: {
                page: nextPrev + 1,
                isTranslated: false,
            },
        });
        resetTranslation();
    };

    const handleSave = () => {
        switch (listName) {
            case 'Needle Type':
                dispatch(needleTypetranslation());
                break;
            case 'Features':
                dispatch(ppaTranslationUpdate());
                break;
            case 'NPT Category':
                dispatch(nptCategoriesTranslationUpdate());
                break;
            case 'NPT Type':
                dispatch(nptTypesTranslationUpdate());
                break;
            case 'Machines':
                dispatch(machinesTranslationUpdate());
                break;
            case 'Styles':
                dispatch(stylesTranslationUpdate());
                break;
            case 'Product Type':
                dispatch(productTypesTranslationUpdate());
                break;
            case 'Skill Type':
                dispatch(skillTypesTranslationUpdate());
                break;
            case 'Machine Attachment':
                dispatch(machineAttachmentTranslationUpdate());
                break;
        }
    };

    const renderFirstName = (bay: any) => {
        if (bay.isFirstManualUpdate) {
            return localStorage.getItem('usernamedata');
        } else {
            if (bay.firstLangIsGoogle) return 'Google';
        }
        return bay.firstLangUpdatedBy;
    };

    const renderSecondName = (bay: any) => {
        if (bay.isSecondManualUpdate) {
            return localStorage.getItem('usernamedata');
        } else {
            if (bay.secondLangIsGoogle) return 'Google';
        }
        return bay.secondLangUpdatedBy;
    };

    const bayRowWithSorting = () => {
        return listData?.map((bay: any, index: any) => {
            return (
                <TableRow className={classes.categoriesItems}>
                    <TableCell width={'5%'}></TableCell>
                    <TableCell align="center" width={'25%'}>
                        <Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={[search]}
                            autoEscape={true}
                            textToHighlight={bay.name}
                        />
                    </TableCell>
                    <TableCell width={'20%'}>
                        <OutlineInput
                            variantStyle={'outlined'}
                            values={bay.firstLang ? bay.firstLang : ''}
                            onChange={(e: any) => handleLang1(e, bay.id, index)}
                            name={'firstLang'}
                            translation={true}
                            translationHandle={() =>
                                translate(
                                    bay.name,
                                    Utils.constants.firstLanguage,
                                    'firstLang',
                                    index,
                                    'firstLangIsGoogle',
                                )
                            }
                        />
                    </TableCell>
                    <TableCell width={'15%'}>{renderFirstName(bay)}</TableCell>
                    <TableCell width={'20%'}>
                        <OutlineInput
                            variantStyle={'outlined'}
                            values={bay.secondLang ? bay.secondLang : ''}
                            name={'secondLanguage'}
                            onChange={(e: any) => handleLang2(e, bay.id, index)}
                            translation={true}
                            translationHandle={() =>
                                translate(
                                    bay.name,
                                    Utils.constants.secondLanguage,
                                    'secondLang',
                                    index,
                                    'secondLangIsGoogle',
                                )
                            }
                        />
                    </TableCell>
                    <TableCell width={'15%'}>{renderSecondName(bay)}</TableCell>
                </TableRow>
            );
        });
    };

    return (
        <>
            <TableContainer className={classes.tableContainer}>
                <Table aria-label="simple table">
                    <TableHead style={{ borderTop: '1px solid #e2dcdc' }}>
                        <TableRow className={classes.categories}>
                            <TableCell width={'5%'}></TableCell>
                            <TableCell width={'25%'}>{t('SourceStringEng')}</TableCell>
                            <TableCell width={'20%'}>{t('Khmer')} </TableCell>
                            <TableCell width={'15%'}>{t('Translatedby')} </TableCell>
                            <TableCell width={'20%'}>{t('ChineseSimplified')}</TableCell>
                            <TableCell width={'15%'}>{t('Translatedby')} </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listData?.length ? (
                            <>
                                <TableRow className={classes.categoriesItems}>
                                    <TableCell width={'5%'}></TableCell>
                                    <TableCell align="center" width={'25%'}>
                                        {tableName}
                                    </TableCell>
                                    <TableCell width={'20%'}>
                                        {firstLangCount}/{totalLangCount} (
                                        {Utils.CommonFunctions.percentage(firstLangCount, totalLangCount)}%)
                                    </TableCell>
                                    <TableCell width={'15%'}>{''}</TableCell>
                                    <TableCell width={'20%'}>
                                        {secondLangCount}/{totalLangCount} (
                                        {Utils.CommonFunctions.percentage(secondLangCount, totalLangCount)}%)
                                    </TableCell>
                                    <TableCell width={'15%'}>{''}</TableCell>
                                </TableRow>
                                {bayRowWithSorting()}
                            </>
                        ) : (
                            <TableRow className={classes.categories}>
                                <TableCell align="center" colSpan={9}>
                                    <div className={classes.noRecords}>{isLoading ? t('Loading') : t('NoRecords')}</div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={classes.paginationContainer}>
                <TablePagination
                    component="div"
                    count={count}
                    page={page - 1}
                    onPageChange={handleChangePage}
                    rowsPerPage={limit}
                    labelDisplayedRows={({ from, to, count, page }) => `  ${from}-${to} of  ${count} `}
                    rowsPerPageOptions={[5, 10, 30]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
            <SimpleModal
                isOpen={transateDiscardPopup}
                body={
                    <TranslationModal
                        handleClose={discardChanges}
                        onDelete={handleSave}
                        title={t('LeaveThisPage')}
                        desc={t('AreYouSure')}
                        secondPara={t('ChangesYouMadeNotSaved')}
                        deleteBtn={t('Save')}
                        canceBtn={t('Discard')}
                        isDelete={false}
                    />
                }
            />
        </>
    );
}

export default MasterDataTransation;
