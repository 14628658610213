import store from '../../store/store';
import ajax from '../../utils/ajax';
import constant from '../../utils/constants';
import Utils from '../../utils';
import { getLBAInfo, getLBATable, updateStyleInfo } from '../lineBalancingAssist/action';

export const OnSubmit = (values: any, setSubmitting: any, history: any, handleCloseForm: any, isStyleChange?: any) => {
    return (dispatch: Function, getState: Function) => {
        const { isSaveAs, currentShiftActive, uid, fromHistory, fromAssist } = getState().lineShiftReducer;
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const { approved } = getState().lineSetupReducer;
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const {
            stylcode,
            styleId,
            line,
            order,
            customer,
            customerName,
            shift,
            shiftName,
            target,
            quantity,
            lineId,
            dateStart,
            dateEnd,
            endShiftName,
            endShift,
            lineName,
        } = values;
        let dataToSend = {
            styleCode: stylcode,
            styleId: styleId,
            line: line,
            lineId: lineId,
            orderNo: order,
            customer: customerName,
            customerId: customer,
            desc1: 'test',
            startDate: dateStart,
            startShift: shiftName,
            startShiftId: shift,
            endDate: dateEnd,
            endShift: endShiftName,
            endShiftId: endShift,
            quantity: quantity,
            targetEff: target,
            duplicate: approved,
            lineName,
        };
        // if (isSaveAs) {
        //     Object.assign(dataToSend, { lineName: lineName });
        // }
        dispatch({
            type: Utils.ActionName.LINE_SETUP,
            payload: {
                formLineSetupData: dataToSend,
            },
        });
        Utils.api.postApiCall(
            isSaveAs ? `${Utils.endPoints.saveAs}?uid=${uid}&id=${currentShiftActive}` : Utils.endPoints.lineSetup,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                let useData = data.data;
                const { innerData } = useData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    setSubmitting(false);
                    dispatch({
                        type: Utils.ActionName.LINE_SETUP_DATA,
                        payload: {
                            targetEFF: target,
                        },
                    });

                    if (useData.duplicate) {
                        dispatch({
                            type: Utils.ActionName.LINE_SETUP,
                            payload: {
                                duplicate: useData.duplicate,
                                postLineSetupData: useData,
                            },
                        });
                        dispatch({
                            type: Utils.ActionName.IS_LOADING,
                            payload: false,
                        });
                    } else if (useData.overlapping) {
                        dispatch({
                            type: Utils.ActionName.LINE_SETUP,
                            payload: {
                                overlapping: useData.overlapping,
                                postLineSetupData: useData || {},
                            },
                        });
                        dispatch({
                            type: Utils.ActionName.IS_LOADING,
                            payload: false,
                        });
                    } else {
                        dispatch({
                            type: Utils.ActionName.IS_LOADING,
                            payload: false,
                        });
                        // dispatch({
                        //     type: Utils.ActionName.GET_LINE_SHIFTS,
                        //     payload: {
                        //         isEditLine: true,
                        //         currentShiftActive: data.data.lineSetupId,
                        //         currentShiftToBeActive: data.data.shiftId,
                        //         isSaved: true,
                        //         uid: data.data.id,
                        //         isLBALine: false,
                        //     },
                        // });
                        Utils.showAlert(1, 'Success');
                        if(handleCloseForm) handleCloseForm();
                        const isNewParam = isSaveAs ? '' : '&isNew=true';
                        const redirectUrl = `/line-setup-view?id=${data.data.id}&currentShiftActive=${data.data.lineSetupId}&currentShiftToBeActive=${data.data.shiftId}${isNewParam}`;
                        setTimeout(() => {
                            if(isSaveAs) {
                                openNewTab(redirectUrl);
                            } else {
                                window.location.href = redirectUrl;
                            }
                        }, 100)
                    }
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                setSubmitting(false);
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

function openNewTab(url: string): void {
    const button: any = document.getElementById('btn-open-new-tab');
    button.addEventListener('click', () => {
        window.open(url, '_blank');
    });
    button.click();
}

export const lineEditApiCall = (values: any, setSubmitting: any, history: any) => {
    return (dispatch: Function, getState: Function) => {
        const { currentShiftActive, uid } = getState().lineShiftReducer;

        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const {
            stylcode,
            styleId,
            line,
            order,
            customer,
            customerName,
            shift,
            shiftName,
            target,
            quantity,
            lineId,
            lineName,
            dateStart,
            dateEnd,
            endShiftName,
            endShift,
        } = values;
        const dataToSend = {
            styleCode: stylcode,
            styleId: styleId,
            line: line,
            lineId: lineId,
            orderNo: order,
            customer: customerName,
            customerId: customer,
            desc1: 'test',
            startDate: dateStart,
            startShift: shiftName,
            startShiftId: shift,
            endDate: dateEnd,
            endShift: endShiftName,
            endShiftId: endShift,
            quantity: quantity,
            targetEff: target,
            lineName,
        };
        Utils.api.putApiCall(
            `${Utils.endPoints.lineSetup}?uid=${uid}&id=${currentShiftActive}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.data.overlapping) {
                    dispatch({
                        type: Utils.ActionName.LINE_SETUP,
                        payload: {
                            overlapping: data.data.overlapping,
                            postLineSetupData: data.data || {},
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch(getlineSetupInfo(currentShiftActive));
                    dispatch(getLBATable(currentShiftActive));
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const OnSubmitDuplicate = (history: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const { formLineSetupData } = getState().lineSetupReducer;

        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        formLineSetupData.duplicate = true;

        Utils.api.postApiCall(
            Utils.endPoints.lineSetup,
            formLineSetupData,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.LINE_SETUP,
                        payload: {
                            duplicate: false,
                        },
                    });

                    history.push(`lineSetup/line-setup-view?id=${data.data.id}`);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getValidation = (values: any, setSubmitting: any, history: any) => {
    return (dispatch: Function, getState: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }

        dispatch(OnSubmit(values, setSubmitting, history, null));
    };
};

export const getlineSetupInfo = (id: any, isNew: boolean = false) => {
    return (dispatch: Function, getState: Function) => {
        const { isSaved } = getState().lineShiftReducer;

        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }

        let parameters = `?id=${id}`;

        Utils.api.getApiCall(
            Utils.endPoints.lineSeupInfo,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.GET_LINE_SETUP_INFO,
                        payload: {
                            infoData: innerData,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.GET_LINE_SHIFTS,
                        payload: {
                            lineStatus: innerData.status,
                            currentShiftToBeActive: innerData.shiftId,
                            uid: innerData.uid,
                            currentShiftActive: innerData.id,
                            isLineNameNull: innerData.lineName === null ? true : false,
                        },
                    });

                    dispatch({
                        type: Utils.ActionName.LINE_SETUP_DATA,
                        payload: {
                            ppaVal: JSON.parse(innerData.ppaVal === null ? '[]' : innerData.ppaVal),
                            targetEFF: innerData.targetEff,
                            workingMinutes: innerData.workingMinutes,
                        },
                    });
                    
                    if (isSaved === false) {
                    }
                    dispatch(getSweingOperationsForLine(innerData.styleId, id, isNew));
                    dispatch(getOperatorInPool(innerData.lineId, innerData.id, innerData.shiftId));

                    dispatch(getShiftFomData(innerData.id));

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getShiftsLine = (id: any, isNew: boolean = false, fromForm?: true) => {
    return (dispatch: Function, getState: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }

        let { fromCreateLineForm } = getState().lineShiftReducer;

        let parameters = `?uid=${id}`;

        Utils.api.getApiCall(
            Utils.endPoints.fetchShift,
            parameters,
            (respData: any) => {
                let shiftTempArray: any = [];
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    for (let i = 0; i < innerData.length; i++) {
                        let shiftIdDataTemp = {
                            lineID: innerData[i].id,
                            shiftId: innerData[i].shiftData.id,
                            fromTime: innerData[i].shiftData.fromTime,
                            toTime: innerData[i].shiftData.toTime,
                            lineBreaktime: innerData[i].lineBreaktime,
                            lineOvertime: innerData[i].lineOvertime,
                            isEdited: false,
                        };
                        shiftTempArray.push(shiftIdDataTemp);
                    }
                    dispatch({
                        type: Utils.ActionName.GET_LINE_SHIFTS,
                        payload: {
                            lineShiftData: innerData.shiftData,
                            wholeShiftData: innerData,
                            uid: innerData[0].uid,
                            shiftData: shiftTempArray,
                            currentShiftActive: innerData[0].id,
                        },
                    });
                    if (fromCreateLineForm) {
                        dispatch(getlineSetupInfo(innerData[0].id, isNew));
                        dispatch(getShiftFomData(innerData[0].id));
                    }
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getOperatorInPool = (id: any, line: number, shiftId: number) => {
    return (dispatch: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }

        let parameters = `?mfglineId=${id}&currentLine=${line}&shiftId=${shiftId}`;

        Utils.api.getApiCall(
            Utils.endPoints.operatorInPool,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.GET_OPERATOR_POOL,
                        payload: {
                            opeeratorData: innerData,
                        },
                    });

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getStations = (id: any) => {
    return (dispatch: Function, getState: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }

        let parameters = `?styleId=${id}`;

        Utils.api.getApiCall(
            Utils.endPoints.stations,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.GET_STATION,
                        payload: {
                            stationData: innerData.data,
                        },
                    });

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

let lengthCheck = 0;
var lbrFinalArray: any = [];
var stationFinalArray: any = [];
let finalOpeerationArray: any = [];
let realManpowerArray: any = [];
let sameDisplay = 0;
let foundLessVal = false;

export const lineSetupUpdateLoop = (manPower: any) => {
    return (dispatch: Function, getState: Function) => {
        let flag = 0;
        lengthCheck = 0;
        let once = true;
        finalOpeerationArray = [];
        lbrFinalArray = [];
        realManpowerArray = [];
        sameDisplay = 0;
        stationFinalArray = [];
        dispatch({
            type: Utils.ActionName.LINE_SETUP_DATA,
            payload: {
                disableChart: false,
            },
        });
        let updatedManpower = manPower;
        for (let i = 0; i < 60; i++) {
            if (lbrFinalArray.length < 4 && i < 30) {
                dispatch(lineSetupUpdate(manPower - flag, false));
                updatedManpower = manPower - flag;
            } else {
                if (once) {
                    flag = 1;
                    once = false;
                }
                dispatch(lineSetupUpdate(manPower + flag, false));
                updatedManpower = manPower + flag;
            }

            //new logic

            if (lbrFinalArray.length < 4 && i < 30) {
                lbrFinalArray.unshift(lengthCheck);
                var input = lbrFinalArray;

                var duplicates = input.reduce(function (acc: any, el: any, i: any, arr: any) {
                    if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el);
                    return acc;
                }, []);
                if (duplicates.length === 0) {
                    realManpowerArray.unshift(updatedManpower);
                } else {
                    lbrFinalArray = lbrFinalArray.filter(function (item: any, index: any) {
                        if (lbrFinalArray.indexOf(item) === index) return item;
                    });
                }
                flag = flag + 1;
            } else {
                lbrFinalArray.push(lengthCheck);
                let input = lbrFinalArray;

                let duplicates = input.reduce(function (acc: any, el: any, i: any, arr: any) {
                    if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el);
                    return acc;
                }, []);
                if (duplicates.length === 0) {
                    realManpowerArray.push(updatedManpower);
                } else {
                    lbrFinalArray = lbrFinalArray.filter(function (item: any, index: any) {
                        if (lbrFinalArray.indexOf(item) === index) return item;
                    });
                }
                flag = flag + 1;
            }

            if (lbrFinalArray.length === 7) {
                break;
            }
        }

        const indexOfManpower = lbrFinalArray.indexOf(manPower);

        let findMid = Math.floor(realManpowerArray.length / 2);
        if (realManpowerArray.length === 7) {
            for (let i = 0; i < realManpowerArray.length; i++) {
                dispatch(lineSetupUpdate(realManpowerArray[i], false));
            }
        }
        if (foundLessVal) {
            for (let i = 0; i < realManpowerArray.length; i++) {
                dispatch(lineSetupUpdate(realManpowerArray[i], false));
            }
        }
        dispatch(
            lineSetupUpdate(
                indexOfManpower !== -1 ? realManpowerArray[indexOfManpower] : realManpowerArray[findMid],
                true,
            ),
        );
    };
};

export const lineSetupUpdate = (manPower: any, onceLBR: boolean) => {
    return (dispatch: Function, getState: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        let { sewingData } = getState().sewingOperationReducer;
        let { sewingOperators } = getState().infoLineSetupReducer;

        //TA-309 Fixed during call endpoint get duplicate data then it effects to totalSmv
        const uniqueSewingDataSet = new Set();
        const uniqueSewingData = sewingData.filter((obj: any) => {
            if(!uniqueSewingDataSet.has(obj.operationId)){
                uniqueSewingDataSet.add(obj.operationId);
                return true;
            }
        });
        sewingData = uniqueSewingData;

        //TA-342 Fixed during sewindData is not the same as sewingOperators
        let filterSeWindDataByOperators : any = {}
        let indexOf = 0
        if (sewingOperators.length > 0 && uniqueSewingData.length != sewingOperators.length){
            for (let i = 0; i < uniqueSewingData.length; i++) {
                for (let j = 0; j < sewingOperators.length; j++) {
                    if (uniqueSewingData[i].operationId === sewingOperators[j][0].operationId) {
                        filterSeWindDataByOperators[indexOf] = uniqueSewingData[i]
                        indexOf ++
                    }
                }
            }
            sewingData = Object.values(filterSeWindDataByOperators);
        }

        const { ppaVal, lineSetup } = getState().infoLineSetupReducer;
        const { isSaved, fromLBRGraph } = getState().lineShiftReducer;

        var newTMArray: any = [];
        const tempOperationData: any = [];
        const dummyOperation: any = [];
        const finalArray: any = [];
        const filteredArr: any = [];
        const ActualArr: any = [];
        let tempSum = 0;
        for (let i = 0; i < sewingData.length; i++) {
            if (sewingData[i].feature !== null) {
                filteredArr.push(sewingData[i]?.featureData?.feature);
            }
        }
        let ppaArray = filteredArr.filter(function (item: any, index: any) {
            if (filteredArr.indexOf(item) === index) return item;
            else return null;
        });
        dispatch({
            type: Utils.ActionName.LINE_SETUP_DATA,
            payload: {
                ppaValList: ppaArray,
            },
        });
        //---calculating operation start

        for (let i = 0; i < sewingData.length; i++) {
            if (ppaVal.length > 0) {
                let ppaValTemp = ppaVal.filter(function (item: any, index: any) {
                    if (ppaVal.indexOf(item) === index) return item;
                });
                for (let j = 0; j < ppaValTemp.length; j++) {
                    if (sewingData[i].feature === ppaValTemp[j]) {
                        tempOperationData.unshift(sewingData[i]);
                        tempSum = tempSum + sewingData[i].smv;
                    } else if (sewingData[i].feature === null) {
                        tempOperationData.push(sewingData[i]);
                        tempSum = tempSum + sewingData[i].smv;
                        break;
                    }
                }
            } else {
                /**
                 * IF condition will not use currently due to unknow business logic.
                 * This will be block autoSave LineSetup function for ticket #TA-65
                 */

                // if (sewingData[i].feature === null) {
                    tempOperationData.push(sewingData[i]);
                    tempSum = tempSum + sewingData[i].smv;
                // }
            }
        }

        //--end
        const temp = Math.round((tempSum + Number.EPSILON) * 100) / 100;
        dispatch({
            type: Utils.ActionName.GET_LINES_SETUP,
            payload: {
                totalSmv: Math.round((tempSum + Number.EPSILON) * 100) / 100,
            },
        });

        for (let i = 0; i < tempOperationData.length; i++) {
            newTMArray.push((tempOperationData[i].smv * manPower) / temp);
        }
        dispatch({
            type: Utils.ActionName.GET_LINES_SETUP,
            payload: {
                theoManPowerData: newTMArray,
            },
        });

        //--addnig theoritical manpoer in operation
        for (let i = 0; i < tempOperationData.length; i++) {
            let operationInsideData = {
                ppa: tempOperationData[i].featureData === null ? null : tempOperationData[i].featureData?.feature,
                thManpower: Math.round((newTMArray[i] + Number.EPSILON) * 100) / 100,
                createdBy: tempOperationData[i].createdBy,
                id: tempOperationData[i].id,
                step: tempOperationData[i].step,
                desc1: tempOperationData[i].desc1,
                operations: tempOperationData[i].operation,
                macSubTypeData: tempOperationData[i].macSubTypeData.subType,
                needleType: tempOperationData[i].needleTypeData.needleType,
                smv: Math.round((tempOperationData[i].smv + Number.EPSILON) * 10000) / 10000,
                tct: Math.round((tempOperationData[i].tct + Number.EPSILON) * 10000) / 10000,
                operationId: tempOperationData[i].operationId,
                macSubTypeId: tempOperationData[i].macSubTypeId,
                skillType: tempOperationData[i].skillTypeData.skillTypeCode,
                styleDataImage: [
                    tempOperationData[i].machineAttachment1Data,
                    tempOperationData[i].machineAttachment2Data,
                    tempOperationData[i].machineAttachment3Data,
                ],
            };

            dummyOperation.push(operationInsideData);
        }
        //---end

        // calcultaing the main algo of station assign
        dummyOperation.sort((a: any, b: any) => a.step - b.step);

        //claculating the rounded and actual val
        for (let i = 0; i < dummyOperation.length; i++) {
            let actulaVAl = 0;
            let largeRound = Math.floor(dummyOperation[i].thManpower);
            let pointVal = dummyOperation[i].thManpower - largeRound;
            if (dummyOperation[i].thManpower <= 0.25) {
                actulaVAl = 0.5;
            } else if (pointVal <= 0.25) {
                actulaVAl = largeRound;
            } else if (pointVal > 0.25 && pointVal <= 0.75) {
                actulaVAl = largeRound + 0.5;
            } else {
                actulaVAl = largeRound + 1;
            }
            ActualArr.push(actulaVAl);
        }
        //end

        for (let i = 0; i < dummyOperation.length; i++) {
            let noOfPointFive = ActualArr[i] / 0.5;

            if (i !== dummyOperation.length - 1 && dummyOperation.length !== 1) {
                if (noOfPointFive % 2 !== 0) {
                    // odd condition

                    if (dummyOperation[i + 1].ppa === dummyOperation[i].ppa) {
                        //Checking for same ppa
                        let nextPointFive = ActualArr[i + 1] / 0.5;
                        if (nextPointFive % 2 !== 0) {
                            // next point five is odd
                            let stattionLength =
                                ActualArr.length - 1 !== i ? ActualArr[i + 1] + ActualArr[i] : ActualArr[i];
                            let mergeStaion = Math.ceil(ActualArr[i]);
                            for (let j = 1; j <= stattionLength; j++) {
                                var newInsideOppArray: any = [];

                                if (j === mergeStaion) {
                                    newInsideOppArray.push(dummyOperation[i]);
                                    newInsideOppArray.push(dummyOperation[i + 1]);
                                } else if (mergeStaion < j) {
                                    newInsideOppArray.push(dummyOperation[i + 1]);
                                } else {
                                    newInsideOppArray.push(dummyOperation[i]);
                                }
                                const getAllData = {
                                    operations: newInsideOppArray,
                                    cycleTime: 27.378,
                                    operator: {},
                                    checked: false,
                                };
                                finalArray.push(getAllData);
                            }
                            i = i + 1;
                        } else {
                            // next point five is even
                            let stationLength = Math.ceil(ActualArr[i]);
                            for (let j = 0; j < stationLength; j++) {
                                let newInsideOppArray: any = [];

                                newInsideOppArray.push(dummyOperation[i]);
                                const getAllData = {
                                    operations: newInsideOppArray,
                                    cycleTime: 27.378,
                                    operator: {},
                                    checked: false,
                                };
                                finalArray.push(getAllData);
                            }
                        }
                    } else {
                        //not same ppa
                        let stationLength = Math.ceil(ActualArr[i]);
                        for (let j = 0; j < stationLength; j++) {
                            let newInsideOppArray: any = [];

                            newInsideOppArray.push(dummyOperation[i]);
                            const getAllData = {
                                operations: newInsideOppArray,
                                cycleTime: 27.378,
                                operator: {},
                                checked: false,
                            };
                            finalArray.push(getAllData);
                        }
                    }
                } else {
                    // even condition
                    for (let j = 0; j < noOfPointFive / 2; j++) {
                        let newInsideOppArray: any = [];

                        newInsideOppArray.push(dummyOperation[i]);
                        const getAllData = {
                            operations: newInsideOppArray,
                            cycleTime: 27.378,
                            operator: {},
                            checked: false,
                        };
                        finalArray.push(getAllData);
                    }
                }
            } else {
                let stationLength = Math.ceil(ActualArr[i]);
                for (let j = 0; j < stationLength; j++) {
                    let newInsideOppArray: any = [];

                    newInsideOppArray.push(dummyOperation[i]);
                    const getAllData = {
                        operations: newInsideOppArray,
                        cycleTime: 27.378,
                        operator: {},
                        checked: false,
                    };
                    finalArray.push(getAllData);
                }
            }

            dispatch({
                type: Utils.ActionName.LINE_SETUP_DATA,
                payload: {
                    lineSetup: isSaved && !fromLBRGraph ? lineSetup : fromLBRGraph ? finalArray : finalArray,
                    once: true,
                },
            });
            let opertionSum = 0;
            lengthCheck = finalArray.length;
            if (realManpowerArray.length === 7 && lengthCheck === lbrFinalArray[sameDisplay]) {
                stationFinalArray.push(finalArray);
                sameDisplay = sameDisplay + 1;
            }

            if (stationFinalArray.length === 7 && onceLBR) {
                for (let j = 0; j < dummyOperation.length; j++) {
                    let currentValue = dummyOperation[j].smv * 60;
                    opertionSum = opertionSum + currentValue;
                }
                dispatch(lbrClaculate(opertionSum));
                dispatch({
                    type: Utils.ActionName.LINE_SETUP_DATA,
                    payload: {
                        opertionSum: opertionSum,
                    },
                });
            } else {
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            }
        }
    };
};
export const lbrClaculate = (opertionSum: any) => {
    return (dispatch: Function) => {
        if (finalOpeerationArray.length < 7) {
            for (let k = 0; k < stationFinalArray.length; k++) {
                let opCycleArray: any = [];
                let newSTationArray: any = [];

                for (let i = 0; i < stationFinalArray[k].length; i++) {
                    for (let j = 0; j < stationFinalArray[k][i].operations.length; j++) {
                        //sum of smv of same station
                        let data = {
                            station: i + 1,
                            smv: stationFinalArray[k][i].operations[j].smv,
                            step: stationFinalArray[k][i].operations[j].step,
                        };
                        newSTationArray.push(data);
                    }
                }

                let preStep = 0;
                let preStation = 0;
                let onNumberOfStations = 2;
                let sameStepFlag = false;
                let preSmv = 0;

                for (let i = 0; i < newSTationArray.length; i++) {
                    if (sameStepFlag) {
                        //check same step true
                        if (preStation === newSTationArray[i].station) {
                            //checking station are same
                            if (newSTationArray.length - 1 !== i) {
                                //not last value
                                if (newSTationArray[i].step === newSTationArray[i + 1].step) {
                                    //for 3 number of stations
                                    const prevSmvPart = preSmv / 3;
                                    const currentSmvPart = newSTationArray[i].smv / 3;
                                    opCycleArray.push(prevSmvPart * 2);
                                    opCycleArray.push(prevSmvPart + currentSmvPart);
                                    opCycleArray.push(currentSmvPart * 2);
                                    i = i + 1;
                                    preSmv = 0;
                                    preStation = 0;
                                    preStep = 0;
                                    sameStepFlag = false;
                                } else {
                                    //for 2 number of stations
                                    const prevSmvPart = preSmv / 3;
                                    opCycleArray.push(prevSmvPart * 2);
                                    opCycleArray.push(prevSmvPart + newSTationArray[i].smv);
                                    preSmv = 0;
                                    preStation = 0;
                                    preStep = 0;
                                    sameStepFlag = false;
                                }
                            } else {
                                //that means it only has two at last

                                const prevSmvPart = preSmv / 3;
                                opCycleArray.push(prevSmvPart * 2);
                                opCycleArray.push(prevSmvPart + newSTationArray[i].smv);
                                preSmv = 0;
                                preStation = 0;
                                preStep = 0;
                                sameStepFlag = false;
                            }
                        } else {
                            //station not same
                            if (preStep === newSTationArray[i].step) {
                                //checking if staeps are same
                                onNumberOfStations = onNumberOfStations + 1;
                            } else {
                                //step are not same
                                const smvCal = preSmv / onNumberOfStations;
                                opCycleArray.push(smvCal);
                                preSmv = newSTationArray[i].smv;
                                preStep = newSTationArray[i].step;
                                preStation = newSTationArray[i].station;
                                sameStepFlag = false;
                                onNumberOfStations = 2;
                            }
                        }
                    } else {
                        if (preSmv === 0) {
                            preSmv = newSTationArray[i].smv;
                            preStep = newSTationArray[i].step;
                            preStation = newSTationArray[i].station;
                        } else {
                            if (preStep === newSTationArray[i].step) {
                                //checking if steps are eqaual
                                sameStepFlag = true;
                                preSmv = newSTationArray[i].smv;
                                preStep = newSTationArray[i].step;
                                preStation = newSTationArray[i].station;
                            } else {
                                if (preStation === newSTationArray[i].station) {
                                    //but if stations are same
                                    if (newSTationArray.length - 1 !== i) {
                                        if (newSTationArray[i].station === newSTationArray[i + 1].station) {
                                            // totalStationSmv = preSmv+;
                                            preSmv = preSmv + newSTationArray[i].smv;
                                        } else {
                                            preSmv = preSmv + newSTationArray[i].smv;

                                            opCycleArray.push(preSmv);
                                            i = i + 1;
                                            preSmv = newSTationArray[i].smv;
                                        }
                                        preStep = newSTationArray[i].step;
                                        preStation = newSTationArray[i].station;
                                        //not last station
                                        // const currentSmvPart = newSTationArray[i].smv / 3;
                                        // opCycleArray.push(currentSmvPart + newSTationArray[i - 1].smv);
                                        // opCycleArray.push(currentSmvPart * 2);
                                        // i = i + 1;
                                        // preSmv = 0;
                                        // preStation = 0;
                                        // preStep = 0;
                                    }
                                } else {
                                    opCycleArray.push(preSmv);
                                    preSmv = newSTationArray[i].smv;
                                    preStep = newSTationArray[i].step;
                                    preStation = newSTationArray[i].station;
                                }
                                // steps are  not eqaual
                            }
                        }
                    }
                    if (i === newSTationArray.length - 1 && newSTationArray.length > 1) {
                        if (
                            newSTationArray[i].station !== newSTationArray[i - 1].station &&
                            newSTationArray[i].step !== newSTationArray[i - 1].step
                        )
                            opCycleArray.push(newSTationArray[i].smv);
                    } else {
                        opCycleArray.push(newSTationArray[i].smv);
                    }
                }

                //end

                let max = Math.max.apply(
                    Math,
                    opCycleArray.map(function (o: any) {
                        return o;
                    }),
                );
                max = max * 60;

                let denominator = max * stationFinalArray[k].length;
                let sum = opertionSum / denominator;
                sum = sum * 100;
                let finalData = {
                    percentage: Math.round((sum + Number.EPSILON) * 10) / 10,
                    rate: stationFinalArray[k].length,
                    manpowerReal: realManpowerArray[k],
                };
                finalOpeerationArray.push(finalData);
            }
            dispatch({
                type: Utils.ActionName.LINE_SETUP_DATA,
                payload: {
                    lbrOperation: finalOpeerationArray,
                    tickValueLbr: lbrFinalArray,
                },
            });
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
        }
    };
};

export const lineSetupAlgoUpdate = (manPower: any) => {
    return (dispatch: Function, getState: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const { sewingData } = getState().sewingOperationReducer;

        const { ppaVal } = getState().infoLineSetupReducer;
        var newTMArray: any = [];
        const tempOperationData: any = [];
        const dummyOperation: any = [];
        const finalArray: any = [];
        const filteredArr: any = [];

        let tempSum = 0;
        for (let i = 0; i < sewingData.length; i++) {
            if (sewingData[i].feature !== null) {
                filteredArr.push(sewingData[i]?.featureData?.feature);
            }
        }
        let ppaArray = filteredArr.filter(function (item: any, index: any) {
            if (filteredArr.indexOf(item) === index) return item;
            else return null;
        });
        dispatch({
            type: Utils.ActionName.LINE_SETUP_DATA,
            payload: {
                ppaValList: ppaArray,
            },
        });
        //---calculating operation start

        for (let i = 0; i < sewingData.length; i++) {
            if (ppaVal.length > 0) {
                for (let j = 0; j < ppaVal.length; j++) {
                    if (sewingData[i].feature === ppaVal[j]) {
                        tempOperationData.unshift(sewingData[i]);
                        tempSum = tempSum + sewingData[i].smv;
                    } else if (sewingData[i].feature === null) {
                        tempOperationData.push(sewingData[i]);
                        tempSum = tempSum + sewingData[i].smv;
                        break;
                    }
                }
            } else {
                if (sewingData[i].feature === null) {
                    tempOperationData.push(sewingData[i]);
                    tempSum = tempSum + sewingData[i].smv;
                }
            }
        }

        //--end
        const temp = Math.round((tempSum + Number.EPSILON) * 100) / 100;
        dispatch({
            type: Utils.ActionName.GET_LINES_SETUP,
            payload: {
                totalSmv: Math.round((tempSum + Number.EPSILON) * 100) / 100,
            },
        });

        for (let i = 0; i < tempOperationData.length; i++) {
            newTMArray.push((tempOperationData[i].smv * manPower) / temp);
        }
        dispatch({
            type: Utils.ActionName.GET_LINES_SETUP,
            payload: {
                theoManPowerData: newTMArray,
            },
        });

        //--addnig tM in operation
        for (let i = 0; i < tempOperationData.length; i++) {
            let operationInsideData = {
                ppa: tempOperationData[i].featureData === null ? null : tempOperationData[i].featureData?.feature,
                thManpower: Math.round((newTMArray[i] + Number.EPSILON) * 100) / 100,
                createdBy: tempOperationData[i].createdBy,
                id: tempOperationData[i].id,
                step: tempOperationData[i].step,
                desc1: tempOperationData[i].desc1,
                operations: tempOperationData[i].operation,
                macSubTypeData: tempOperationData[i].macSubTypeData.subType,
                needleType: tempOperationData[i].needleTypeData.needleType,
                smv: Math.round((tempOperationData[i].smv + Number.EPSILON) * 10000) / 10000,
                operationId: tempOperationData[i].operationId,
                styleDataImage: [
                    tempOperationData[i].machineAttachment1Data,
                    tempOperationData[i].machineAttachment2Data,
                    tempOperationData[i].machineAttachment3Data,
                ],
                skillType: tempOperationData[i].skillTypeData.skillTypeCode,
            };

            dummyOperation.push(operationInsideData);
        }
        //---end

        // calcultaing the main algo of station assign
        dummyOperation.sort((a: any, b: any) => a.step - b.step);

        let flag = 0;
        let stationFlag = 0;
        for (let i = 0; i < manPower; i++) {
            let sumTemp = 0;
            var newInsideArray: any = [];

            for (let j = flag; j < dummyOperation.length; j++) {
                if (dummyOperation[j].thManpower < 1.5) {
                    sumTemp = sumTemp + dummyOperation[j].thManpower;
                    if (sumTemp <= 1.5) {
                        newInsideArray.push(dummyOperation[j]);
                        flag = j + 1;
                    }
                } else {
                    if (sumTemp === 0) {
                        stationFlag = stationFlag + 1;
                        newInsideArray.push(dummyOperation[j]);
                    }
                    if (stationFlag === Math.round(dummyOperation[j].thManpower)) {
                        flag = j + 1;
                        stationFlag = 0;
                    }
                    break;
                }
            }

            const getAllData = {
                operations: newInsideArray,
                cycleTime: 27.378,
                operator: {},
                checked: false,
            };
            finalArray.push(getAllData);
        }

        dispatch({
            type: Utils.ActionName.LINE_SETUP_DATA,
            payload: {
                lineSetup: finalArray,
            },
        });
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: false,
        });
    };
};

export const getLineSetupDataReplica = (manPower: any) => {
    return (dispatch: Function, getState: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const { sewingData } = getState().sewingOperationReducer;
        const tempArray: any = [];

        var newTMArray: any = [];
        let tempSum = 0;
        for (let i = 0; i < sewingData.length; i++) {
            if (sewingData[i].feature === null) {
                tempSum = tempSum + sewingData[i].smv;
            }
        }
        const temp = Math.round((tempSum + Number.EPSILON) * 10000) / 10000;
        dispatch({
            type: Utils.ActionName.GET_LINES_SETUP_REPLICA,
            payload: {
                totalSmv: Math.round((tempSum + Number.EPSILON) * 10000) / 10000,
            },
        });

        for (let i = 0; i < sewingData.length; i++) {
            if (sewingData[i].feature === null) {
                newTMArray.push((sewingData[i].smv * manPower) / temp);
            }
        }
        dispatch({
            type: Utils.ActionName.GET_LINES_SETUP_REPLICA,
            payload: {
                theoManPowerData: newTMArray,
            },
        });
        let stationFlag = 1;
        for (let i = 0; i < newTMArray.length; i++) {
            var newStationArray: any = [];

            if (newTMArray[i] <= 1) {
                newStationArray.push(stationFlag);
                stationFlag = stationFlag + 1;
            } else {
                let tempSum = 0;
                let roundStation = 0;
                tempSum = tempSum + newTMArray[i];
                if (tempSum <= 1.5) {
                    newStationArray.push(stationFlag);
                    stationFlag = stationFlag + 1;
                } else {
                    roundStation = Math.round(tempSum);
                    let tempStFlag = stationFlag;
                    for (let k = 0; k < roundStation; k++) {
                        newStationArray.push(tempStFlag);
                        tempStFlag = tempStFlag + 1;
                    }
                    stationFlag = stationFlag + roundStation;
                }
            }
            const getallData = {
                stationData: newStationArray,
                operationData: sewingData[i],
                theManPower: newTMArray[i],
            };
            tempArray.push(getallData);
        }

        dispatch({
            type: Utils.ActionName.GET_LINES_SETUP_REPLICA,
            payload: {
                operationAndStationReplica: tempArray,
            },
        });
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: false,
        });
    };
};

export const getOperatorInPoolSearch = (isLBA?: any) => {
    return (dispatch: Function, getState: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const { infoData } = getState().lineSetupInfoReducer;
        const { infoData: assistInfoData } = getState().lineAssistInfoReducer;
        const { searchOperatorText, page, limit, searchOperatorList } = getState().searchOperatorInPoolReducer;

        dispatch({
            type: Utils.ActionName.GET_OPERATOR_IN_POOL_SEARCH,
            payload: {
                loadingData: true,
            },
        });

        let parameters = '';
        if (isLBA === true) {
            parameters = `?shiftId=${assistInfoData.shiftId}&mfglineId=${assistInfoData.lineId}&currentLine=${
                assistInfoData.id
            }${searchOperatorText.length > 0 ? `&search=${searchOperatorText}` : ''}&page=${page}${
                searchOperatorText.length > 0 ? `&limit=1000` : `&limit=${limit}`
            }`;
        } else {
            parameters = `?shiftId=${infoData.shiftId}&mfglineId=${infoData.lineId}&currentLine=${infoData.id}${
                searchOperatorText.length > 0 ? `&search=${searchOperatorText}` : ''
            }&page=${page}${searchOperatorText.length > 0 ? `&limit=1000` : `&limit=${limit}`}`;
        }

        Utils.api.getApiCall(
            Utils.endPoints.searchForOperatorInPoolInOtherLine,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    if (page === 1) {
                        dispatch({
                            type: Utils.ActionName.GET_OPERATOR_IN_POOL_SEARCH,
                            payload: {
                                searchOperatorList: [...innerData.data],
                                count: innerData.count,
                            },
                        });
                    } else {
                        dispatch({
                            type: Utils.ActionName.GET_OPERATOR_IN_POOL_SEARCH,
                            payload: {
                                searchOperatorList: [...searchOperatorList, ...innerData.data],
                                page: innerData.page,
                                count: innerData.count,
                            },
                        });
                    }

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
                dispatch({
                    type: Utils.ActionName.GET_OPERATOR_IN_POOL_SEARCH,
                    payload: {
                        loadingData: false,
                    },
                });
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.GET_OPERATOR_IN_POOL_SEARCH,
                    payload: {
                        loadingData: false,
                    },
                });
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

// Triggered on Click of Add from (+) Operator Pool Modal
export const addOperatorInPool = (operatorIds: any, isLBA?: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const { infoData } = getState().lineSetupInfoReducer;
        const { infoData: assistInfoData } = getState().lineAssistInfoReducer;
        const { searchOperatorList } = getState().searchOperatorInPoolReducer;
        const { opeeratorData } = getState().operatorInPoolReducer;

        const dataToSend = {
            operatorIds: operatorIds.toString(),
            mfglineId: isLBA === true ? assistInfoData.lineId : infoData.lineId,
            currentLine: isLBA === true ? assistInfoData.id : infoData.id,
            shiftId: isLBA === true ? assistInfoData.shiftId : infoData.shiftId,
        };

        Utils.api.postApiCall(
            Utils.endPoints.addOperatorInPool,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.CommonFunctions.showCustomToast(data.message, 1);

                    let selectedOplist: any = [];
                    searchOperatorList.forEach((availableOp: any) => {
                        operatorIds.forEach((addedOp: any) => {
                            if (availableOp.id.toString() === addedOp) {
                                selectedOplist.push(availableOp);
                            }
                        });
                    });

                    if (selectedOplist.length > 0) {
                        selectedOplist = [...opeeratorData, ...selectedOplist];
                        dispatch({
                            type: Utils.ActionName.GET_OPERATOR_POOL,
                            payload: {
                                opeeratorData: selectedOplist,
                            },
                        });
                    }

                    dispatch({
                        type: Utils.ActionName.GET_OPERATOR_IN_POOL_SEARCH,
                        payload: {
                            addOperatorsInPoolIds: [],
                            searchOperatorText: '',
                        },
                    });
                    if (isLBA === true) {
                        dispatch(getOperatorInPoolSearch(true));
                        dispatch(getOperatorInPool(assistInfoData.lineId, assistInfoData.id, assistInfoData.shiftId));
                    } else {
                        dispatch(getOperatorInPoolSearch());
                        dispatch(getOperatorInPool(infoData.lineId, infoData.id, infoData.shiftId));
                    }

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.CommonFunctions.showCustomToast(data.message, 2);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch({
                        type: Utils.ActionName.GET_OPERATOR_IN_POOL_SEARCH,
                        payload: {
                            addOperatorsInPoolIds: [],
                            searchOperatorText: '',
                        },
                    });
                    dispatch(getOperatorInPoolSearch());
                }
            },
            (error: any) => {
                let { data } = error;
                dispatch({
                    type: Utils.ActionName.GET_OPERATOR_IN_POOL_SEARCH,
                    payload: {
                        addOperatorsInPoolIds: [],
                        searchOperatorText: '',
                    },
                });
                if (data.statusCode === 400) {
                    Utils.CommonFunctions.showCustomToast(data.message, 2);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

let currentLIneTemp = 0;
let countInitialShift = false;
export const savingLinesWithMultipleShift = (values: any, setSubmitting: any, handleClose: any, history: any) => {
    return (dispatch: Function, getState: Function) => {
        const { shiftData, currentShiftActive } = getState().lineShiftReducer;
        let shiftLeng = shiftData.length;
        for (let i = 0; i < shiftData.length; i++) {
            currentLIneTemp = shiftData[i].lineID;

            dispatch(addStyleInfo(values, setSubmitting, handleClose, history, currentShiftActive, i, shiftLeng));
            if (values.keepDefault) {
                countInitialShift = true;
            }
        }
        dispatch({
            type: Utils.ActionName.GET_LINE_SHIFTS,
            payload: {
                isSaved: true,
            },
        });
        countInitialShift = false;
    };
};

export const savingLinesWithMultipleShiftV2 = (
    values: any,
    onProgress: () => void,
    isCompleted: () => void, 
    onError: (error: any) => void,
) => {
    onProgress();
    const { shiftData } = store.getState().lineShiftReducer;
    const promises = shiftData.map( async ({ lineID }: any) => {
        const dataToSend = getDataToSendForLineSetup(values.lineName, lineID, values.keepDefault);
        return await postSaveLineSetup(dataToSend);
    });
    Promise.all(promises).then(() => {
        isCompleted();
    }).catch((error: any) => {
        onError(error);
    });
}

export const getDataToSendForLineSetup = (
    lineName: string, 
    lineID: number,
    countInitShift: boolean,
) => {
    const lineSetUpState = store.getState().linesSetupReducer;
    const infoLineSetupState = store.getState().infoLineSetupReducer;
    const lineShiftState = store.getState().lineShiftReducer;
    const { infoData } = store.getState().lineSetupInfoReducer;

    const graphData = { 
        lbrOperation: infoLineSetupState.lbrOperation, 
        grapghValSelect: infoLineSetupState.grapghValSelect
    };
    const graphDataString = JSON.stringify(graphData);
    const ppaDataString = JSON.stringify(infoLineSetupState.ppaVal);
    return {
        data: countInitShift 
            ? lineShiftState.defaultLineData 
            : infoLineSetupState.lineSetup,
        lineSetupId: lineShiftState.isEditLine 
            ? lineSetUpState.lineSetupId 
            : lineID,
        lineSetupmanpower: infoLineSetupState.lineSetup.length,
        styleId: lineSetUpState.styleId,
        lineName: lineName,
        lbr: infoLineSetupState.finalLbr,
        totalWebSmv: lineSetUpState.totalSmv,
        totalWebTct: infoData.totalTct,
        eff: infoLineSetupState.targetEFF,
        effTgt: 0,
        totalOutputTgt: 0,
        uid: lineShiftState.uid,
        status: lineShiftState.lineStatus,
        lbrGraph: graphDataString,
        targetEff: infoLineSetupState.targetEFF,
        ppaVal: ppaDataString,
        workingMinutes: infoLineSetupState.workingMinutes,
    };
}

const postSaveLineSetup  = async ( dataToSend: any )  => {
    const pathUrl = constant.apiUrl + Utils.endPoints.stationSave;
    return await ajax(pathUrl, {
        method: 'POST',
        data: dataToSend,
    });
};


export const addStyleInfo = (values: any, setSubmitting: any, handleClose: any, history: any, currentShiftActive: any, index: any, shiftLeng: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            if(setSubmitting) setSubmitting(false);
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const {
            lineSetupId,
            totalSmv,

            styleId,
        } = getState().linesSetupReducer;
        const { infoData } = getState().lineSetupInfoReducer;
        const { lineName } = values;
        const {
            ppaVal,
            lineSetup,
            finalLbr,
            targetEFF,
            lbrOperation,
            grapghValSelect,
            workingMinutes,
        } = getState().infoLineSetupReducer;
        const { uid, isEditLine, defaultLineData, lineStatus } = getState().lineShiftReducer;
        let graphData = { lbrOperation, grapghValSelect };
        var graphDataString = JSON.stringify(graphData);
        var ppaDataString = JSON.stringify(ppaVal);
        const dataToSend = {
            data: countInitialShift ? defaultLineData : lineSetup,
            lineSetupId: isEditLine ? lineSetupId : currentLIneTemp,
            lineSetupmanpower: lineSetup.length,
            styleId,
            lineName,
            lbr: finalLbr,
            totalWebSmv: totalSmv,
            totalWebTct: infoData.totalTct,
            eff: targetEFF,
            effTgt: 0,
            totalOutputTgt: 0,
            uid,
            status: lineStatus,
            lbrGraph: graphDataString,
            targetEff: targetEFF,
            ppaVal: ppaDataString,
            workingMinutes: workingMinutes,
        };

        Utils.api.postApiCall(
            Utils.endPoints.stationSave,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    setSubmitting(false);
                    if(index === (shiftLeng -1)){
                        dispatch({
                            type: Utils.ActionName.IS_LOADING,
                            payload: false,
                        });
                        if(handleClose) handleClose();
                    }
                    
                    let { currentShiftActive } = getState().lineShiftReducer;
                    dispatch(getLBAInfo(currentShiftActive));
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                if(setSubmitting) setSubmitting(false);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getSweingOperationsForLine = (id: any, lineSetupId: any, isNew: boolean = false) => {
    return (dispatch: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        let isNewLineSetup = isNew ? `&isNew=${isNew}` : '';
        let parameters = `?styleId=${id}&lineSetUpId=${lineSetupId}${isNewLineSetup}`;
        Utils.api.getApiCall(
            Utils.endPoints.operationListForLine,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data.data;

                    dispatch({
                        type: Utils.ActionName.UPDATE_SEWING_OPERATION,
                        payload: {
                            sewingData: innerData,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const addSaveAs = (values: any, setSubmitting: any, handleClose: any, history: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            setSubmitting(false);
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const {
            lineSetupId,
            totalSmv,

            styleId,
        } = getState().linesSetupReducer;
        const { lineName } = values;

        const { lineSetup, finalLbr, targetEFF } = getState().infoLineSetupReducer;
        const { uid, isSaved } = getState().lineShiftReducer;
        const dataToSend = {
            lineSetupId: isSaved ? lineSetupId : currentLIneTemp,
            lineSetupmanpower: lineSetup.length,
            styleId,
            lineName,
            lbr: finalLbr,
            totalWebSmv: totalSmv,
            eff: targetEFF,
            effTgt: 0,
            totalOutputTgt: 0,
            uid,
            status: 0,
            targetEFF,
        };

        Utils.api.postApiCall(
            Utils.endPoints.saveAs,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    setSubmitting(false);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    handleClose();
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                setSubmitting(false);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getLineSetupList = () => {
    return (dispatch: Function, getState: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }

        const {
            order,
            page,
            sortBy,
            limit,
            search,
            filterStatus,
            filterCustomerIds,
            filterLineIds,
            filterShiftIds,
        } = getState().lineSetupListReducer;

        let parameters = `?page=${page}&limit=${limit}${search.length > 0 ? `&search=${search}` : ''}${
            filterStatus.length === 1 ? `&status=${filterStatus[0] === '0' ? 0 : 1}` : ''
        }${filterCustomerIds.length > 0 ? `&customerId=${filterCustomerIds.toString()}` : ''}${
            filterLineIds.length > 0 ? `&lineId=${filterLineIds.toString()}` : ''
        }${filterShiftIds.length > 0 ? `&shiftId=${filterShiftIds.toString()}` : ''}${
            order.length > 0 ? `&order=${order}&sortBy=${sortBy}` : ''
        }`;
        Utils.api.getApiCall(
            Utils.endPoints.lineSetupListGet,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;

                    dispatch({
                        type: Utils.ActionName.LINE_SETUP_LIST_DATA,
                        payload: {
                            lines: innerData.data,
                            count: innerData.count,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const deleteLineSetupLines = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const dataToSend = '';
        const { selectedLines } = getState().lineSetupListReducer;
        Utils.api.deleteApiCall(
            `${Utils.endPoints.lineSetupListDelete}?uids=${selectedLines.toString()}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch(getLineSetupList());
                    dispatch({
                        type: Utils.ActionName.LINE_SETUP_LIST_DATA,
                        payload: {
                            selectedLines: [],
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const scheduleLineSetupLines = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const dataToSend = { status: 1 };
        const { selectedLines } = getState().lineSetupListReducer;
        Utils.api.putApiCall(
            `${Utils.endPoints.lineSetupListStatus}?ids=${selectedLines.toString()}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch(getLineSetupList());
                    dispatch({
                        type: Utils.ActionName.LINE_SETUP_LIST_DATA,
                        payload: {
                            selectedLines: [],
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

/**
 * Edit status api call
 */
export const editStatusApiCall = (statusId: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const { currentShiftActive, uid } = getState().lineShiftReducer;
        const dataToSend = { status: statusId };
        Utils.api.putApiCall(
            `${Utils.endPoints.lineStatus}?uid=${uid}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    if(data.httpCode === 201){
                        Utils.showAlert(4, data.message);
                    }else{
                        Utils.showAlert(1, data.message);
                    }
                    dispatch({
                        type: Utils.ActionName.GET_LINE_SHIFTS,
                        payload: {
                            lineStatus: statusId,
                        },
                    });
                    dispatch(getlineSetupInfo(currentShiftActive));
                    dispatch(getLBATable(currentShiftActive));
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};
/**
 * form data break time api call edit
 */

export const getShiftFomData = (id: any, updateLbr = false, handleClose = '') => {
    return (dispatch: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }

        let parameters = `?lineSetupId=${id}`;

        Utils.api.getApiCall(
            Utils.endPoints.getEditShiftForm,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.GET_LINE_SETUP_INFO,
                        payload: {
                            shiftInfoData: innerData,
                        },
                    });

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });

                    if(updateLbr){
                        dispatch(
                            updateStyleInfo(
                                handleClose,
                                true, 
                                false, 
                                innerData.workingTime,
                            )
                        );
                    }
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};
/**
 * form data break time api call edit
 */

export const editShiftForm = (values: any, setSubmitting: any, handleClose: any, isAssist: boolean) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const { currentShiftToBeActive, wholeShiftData } = getState().lineShiftReducer;
        const { startTime, endTime, breakTime, overTime } = values;
        const { infoData: setupData } = getState().lineSetupInfoReducer;
        const { infoData: assistData } = getState().lineAssistInfoReducer;
        const infoData = isAssist ? assistData : setupData;
        const { id, lineId } = infoData;

        const dataToSend: any = {
            lineSetupId: id,
            lineId,
            shift: wholeShiftData.filter((shift: any) => shift.shiftData.id === currentShiftToBeActive)[0].shiftData
                .shift,
            shiftId: currentShiftToBeActive,
            startTime,
            endTime,
            startDate: startTime,
            breakTime1: breakTime.length > 0 ? breakTime[0].breakStartTime : null,
            breakTime2: breakTime.length > 1 ? breakTime[1].breakStartTime : null,
            breakTime3: breakTime.length > 2 ? breakTime[2].breakStartTime : null,
            breakTime4: breakTime.length > 3 ? breakTime[3].breakStartTime : null,
            breakTime5: breakTime.length > 4 ? breakTime[4].breakStartTime : null,
            overTime1: overTime.length > 0 ? overTime[0].overStartTime : null,
            overTime2: overTime.length > 1 ? overTime[1].overStartTime : null,
            overTime3: overTime.length > 2 ? overTime[2].overStartTime : null,
            overTime4: overTime.length > 3 ? overTime[3].overStartTime : null,
            overTime5: overTime.length > 4 ? overTime[4].overStartTime : null,
            durationBreakTime1: breakTime.length > 0 ? breakTime[0].breakDuration : 0,
            durationBreakTime2: breakTime.length > 1 ? breakTime[1].breakDuration : 0,
            durationBreakTime3: breakTime.length > 2 ? breakTime[2].breakDuration : 0,
            durationBreakTime4: breakTime.length > 3 ? breakTime[3].breakDuration : 0,
            durationBreakTime5: breakTime.length > 4 ? breakTime[4].breakDuration : 0,
            durationOverTime1: overTime.length > 0 ? overTime[0].overDuration : 0,
            durationOverTime2: overTime.length > 1 ? overTime[1].overDuration : 0,
            durationOverTime3: overTime.length > 2 ? overTime[2].overDuration : 0,
            durationOverTime4: overTime.length > 3 ? overTime[3].overDuration : 0,
            durationOverTime5: overTime.length > 4 ? overTime[4].overDuration : 0,
        };

        Utils.api.putApiCall(
            Utils.endPoints.editShiftDataForm,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
                setSubmitting(false);
                handleClose();
                dispatch(getShiftFomData(id, true, handleClose));
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
                setSubmitting(false);
                handleClose();
            },
        );
    };
};


/**
 * Integrate from linesetup to ltm co api call
 */
export const integrateLinesetupToLTmCO = (lineSetupId: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        Utils.api.postApiCall(
            `${Utils.endPoints.toCorequest}?lineSetupId=${lineSetupId}`,
            {},
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch(getlineSetupInfo(lineSetupId));
                    dispatch(getLBATable(lineSetupId));
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};
