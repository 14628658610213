import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme, Typography, Button, LinearProgress } from '@material-ui/core';
import { Formik, Form } from 'formik';
import Schema from '../../schema';
import { useTranslation } from 'react-i18next';
import NormalInput from '../../components/inputs/normalInput';
import DatePicker from '../../components/datePicker/index';
import { getValidation, OnSubmit, lineEditApiCall } from './action';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getCustomers, getStyleList } from '../manageStyle/action';
import { getShiftsList, getMfgLinesList } from '../admin/action';
import { ReducersModel } from '../../model';
import SimpleModal from '../../components/modal';
import NotFound from './notFound';
import OverLapping from './overlapping';
import { useHistory } from 'react-router-dom';
import AutoComplete from '../../components/inputs/autoComplete';
import moment from 'moment';
import Utils from '../../utils';
import { orderingByAlphabetic } from '../../utils/helpers';
import ajax from '../../utils/ajax';
import constant from '../../utils/constants';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            margin: '10px auto',
            width: '360px',
            backgroundColor: 'var(--white)',
            padding: '30px',
        },
        saveAsContainer: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            width: '360px',
            backgroundColor: 'var(--white)',
            padding: '30px',
        },
        textStyl: {
            fontSize: '20px',
            color: 'var(--dark-grey)',
            margin: '18px 0 10px 0',
        },
        btnContainer: {
            width: '96%',
            backgroundColor: 'white',
            height: '56px',
            textAlign: 'center',
            bottom: 0,
            left: '62px',
            marginTop: '20px',
        },
        submitButton: {
            width: '160px',
            marginTop: '10px',
        },
        submitButtonSaveAs: {
            width: '100px',
            height: '36px',
            fontSize: '16px',
            margin: '0px 10px',
        },
        shiftContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '7px 0 0 0',
            '& > div': { width: '47%' },
        },
        populateField: {
            marginBottom: '12px',
            '& div': {
                wordBreak: 'break-word',
            },
            '& div:nth-child(1)': {
                font: 'normal normal normal 9px/21px Roboto',
                opacity: 1,
                color: 'rgba(0, 0, 0, 0.54)',
                letterSpacing: '0.07px',
                textTransform: 'capitalize',
            },
            '& div:nth-child(2)': {
                font: 'normal normal normal 14px/14px Roboto',
                opacity: 1,
                letterSpacing: '0.07px',
                color: 'rgba(0, 0, 0, 0.87)',
                marginTop: '2px',
            },
        },
        cancelBtn: {
            color: 'var(--input-fill)',
        },
        saveBtn: {
            color: 'var(--white)',
            backgroundColor: 'var(--blue)',
            '&:hover': {
                backgroundColor: 'var(--dark-blue)',
            },
        },
        error: { minHeight: '20px', marginTop: '4px', fontSize: '12px', letterSpacing: '0.1px', color: '#f44336' },
    }),
);

export default function LineSetupForm({ saveAs, handleClose, edit, assist }: any) {
    const classes = styles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [prevCode, setPrevCode] = useState('');

    useEffect(() => {
        dispatch(getCustomers());
        // dispatch(getLineSetupHistory());
        dispatch(getShiftsList());
        dispatch(getMfgLinesList());
    }, [dispatch]);

    const [searchValue, setSearchValue] = useState('');
    const [data, setData] = useState<any>([]);
    const handleSearchChange = async (e: any, Newvalue: any) => {
        setSearchValue(Newvalue);
    };

    const getStyleList = async (searchData: any, currentStyle: any) => {
        try {
            const baseUrl = constant.apiUrl;
            let query = '?limit=10&page=1';
            if (searchData !== '') {
                query += `&search=${escapePlusSign(searchData)}`;
            }
            const responseStylelist = await ajax(`${baseUrl}user/style${query}`);
            const styles = responseStylelist.data.data.data;
            setData(currentStyle ? [...styles, currentStyle] : styles);
        } catch (error) {
            console.log(error);
        }
    };

    const escapePlusSign = (queryString: string) => {
        return queryString.replace(/\+/g, '%2B');
    };

    const { infoData: assistData } = useSelector((state: ReducersModel) => state.lineAssistInfoReducer);
    const { infoData: setupData } = useSelector((state: ReducersModel) => state.lineSetupInfoReducer);
    const infoData = assist ? assistData : setupData;

    const { shifts, currentActiveShift } = useSelector((state: ReducersModel) => state.shiftsReducer);
    const { customers } = useSelector((state: ReducersModel) => state.customersListReducer);
    const { mfgLines } = useSelector((state: ReducersModel) => state.mfgLinesReducer);
    const { postLineSetupData, duplicate, overlapping } = useSelector((state: ReducersModel) => state.lineSetupReducer);
    const { fromEdit } = useSelector((state: ReducersModel) => state.lineShiftReducer);

    useEffect(() => {
        if (infoData) {
            setPrevCode(infoData.styleCode);
        }
    }, [infoData]);

    const handleOpen = () => {
        // setOpen(true);
    };

    //Fetch & Search Style List
    useEffect(() => {
        if (infoData.styleCode && infoData.styleId) {
            getStyleList(searchValue, {
                id: infoData.styleId,
                code: infoData.styleCode,
            });
        } else {
            getStyleList(searchValue, false);
        }
    }, [searchValue, infoData]);

    const handleGetDefaultValue = (values: any) => {
        const result = data.filter((val: any) => val.id === values.styleId)[0] || ({} as typeof data[0]);
        return result;
    };

    //Checking If date is less than present date
    const validatingDate = (date: string) => {
        return moment(date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
            ? moment().format('YYYY-MM-DD')
            : moment(date).format('YYYY-MM-DD');
    };

    //Filtering Start Shifts on Save As form
    const filterStartShifts = (startDate: any) => {
        let curTime = moment().format('HH:mm:ss');
        let curDate = moment().format('YYYY-MM-DD');
        if (curDate === validatingDate(startDate)) {
            const newShifts = shifts.filter((val: any) => {
                return (
                    val.startTime > curTime ||
                    (val.startTime < curTime && val.endTime > curTime) ||
                    (val.startTime > val.endTime && curTime >= val.startTime && curTime <= '23:59:59') ||
                    (curTime >= '00:00:00' && curTime < val.endTime)
                );
            });
            return newShifts;
        } else {
            return shifts;
        }
    };

    //Filtering End Shifts on Save As form
    const filterEndShifts = (infoData: any) => {
        if (validatingDate(infoData.startDate) === validatingDate(infoData.endDate)) {
            const startShift = shifts.filter((val: any) => {
                return val.id === infoData.startShiftId;
            });
            const newShifts = shifts.filter((val: any) => {
                return val.startTime >= startShift[0]?.startTime;
            });
            return newShifts;
        } else {
            return shifts;
        }
    };

    //Get Filled Shift already Ended or not
    const isPastShift = (shifts: any, shiftId: number, date: string) => {
        let curDate = moment().format('YYYY-MM-DD');

        if (validatingDate(date) > curDate) {
            return false;
        } else if (validatingDate(date) === curDate) {
            return isValidShift(shifts, shiftId);
        } else {
            return true;
        }
    };

    //Checking onChange when start Date and end date is same
    const isValidShift = (filShifts: any[], selShiftId: number) => {
        const temp = filShifts.filter((shift: any) => selShiftId === shift.id);
        return temp.length === 0 ? true : false;
    };

    const history = useHistory();
    const filterStartShiftsArr = filterStartShifts(infoData.startDate);
    const filterEndShiftArr = filterEndShifts(infoData);
    let debounceTimeout: any = null;
    return (
        <div className={saveAs || edit ? classes.saveAsContainer : classes.mainContainer}>
            <Typography className={classes.textStyl}>
                {saveAs ? t('SaveAsNewLineSetup') : edit ? t('EditLineSetup') : t('LineSetup')}
            </Typography>
            {mfgLines.length > 0 && customers.length > 0 && shifts.length > 0 ? (
                <div>
                    <Formik
                        initialValues={{
                            stylcode: saveAs || edit ? infoData.styleCode : '',
                            styleId: saveAs || edit ? infoData.styleId : '',
                            customer: saveAs || edit ? infoData.customerId : '',
                            customerName: saveAs || edit ? infoData.customer : '',
                            order: saveAs || edit ? (infoData.orderNo !== null ? infoData.orderNo : '') : '',
                            dateStart: saveAs || edit ? validatingDate(infoData.lineStartDate) : '',
                            dateEnd: saveAs || edit ? validatingDate(infoData.lineEndDate) : '',
                            startSelectedShifts: saveAs || edit ? filterStartShiftsArr : shifts,
                            endSelectedShifts: saveAs || edit ? filterEndShiftArr : shifts,
                            shift:
                                saveAs || edit
                                    ? isPastShift(filterStartShiftsArr, infoData.startShiftId, infoData.startDate)
                                        ? currentActiveShift.id
                                        : infoData.startShiftId
                                    : '',
                            shiftName:
                                saveAs || edit
                                    ? isPastShift(filterStartShiftsArr, infoData.startShiftId, infoData.startDate)
                                        ? currentActiveShift.shift
                                        : infoData.startShift
                                    : '',
                            endShift:
                                saveAs || edit
                                    ? isPastShift(filterEndShiftArr, infoData.endShiftId, infoData.endDate)
                                        ? currentActiveShift.id
                                        : infoData.endShiftId
                                    : '',
                            endShiftName:
                                saveAs || edit
                                    ? isPastShift(filterEndShiftArr, infoData.endShiftId, infoData.endDate)
                                        ? currentActiveShift.shift
                                        : infoData.endShift
                                    : '',
                            target: saveAs || edit ? infoData.targetEff : '',
                            quantity: saveAs || edit ? infoData.quantity : '',
                            lineId: saveAs || edit ? infoData.lineId : '',
                            line: saveAs || edit ? infoData.line : '',
                            lineName: saveAs || edit ? infoData.lineName : '',
                        }}
                        validationSchema={Schema.lineSetupSchema(t)}
                        onSubmit={(values, { setSubmitting }) => {
                            if (prevCode !== values.stylcode && fromEdit) {
                                dispatch(OnSubmit(values, setSubmitting, history, handleClose, true));
                            } else {
                                if (edit) {
                                    dispatch(lineEditApiCall(values, setSubmitting, history));
                                    handleClose();
                                } else {
                                    if (!saveAs) {
                                        dispatch(getValidation(values, setSubmitting, history));
                                        handleClose();
                                    } else {
                                        dispatch(OnSubmit(values, setSubmitting, history, handleClose));
                                    }
                                }
                            }
                            dispatch({
                                type: Utils.ActionName.GET_LINE_SHIFTS,
                                payload: {
                                    fromCreateLineForm: true,
                                },
                            });
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                            setErrors,
                        }) => (
                            <Form>
                                {saveAs ? (
                                    <div className={classes.populateField}>
                                        <div>{t('StyleCode')}</div>
                                        <div>{values.stylcode === '' ? '-' : values.stylcode}</div>
                                    </div>
                                ) : (
                                    <AutoComplete
                                        onBlur={handleBlur}
                                        values={values.stylcode}
                                        onInputChange={(event: any, newValue: any) => {
                                            if (event !== null && newValue !== '') {
                                                clearTimeout(debounceTimeout);
                                                debounceTimeout = setTimeout(() => {
                                                    handleSearchChange(event, newValue);
                                                }, 300);
                                            }
                                        }}
                                        onChange={(event: any, value: any) => {
                                            if (value !== null) {
                                                const index = data.findIndex((x: any) => x.id === value.id);
                                                if (index >= 0) {
                                                    setFieldValue('styleId', value.id);
                                                    setFieldValue('stylcode', data[index].code);
                                                }
                                            }
                                        }}
                                        autoCompleteValue={handleGetDefaultValue(values)}
                                        defaultValue={handleGetDefaultValue(values)}
                                        getOptionSelected={(option: any, value: any) => {
                                            return `${option.id}` === `${value.id}`;
                                        }}
                                        helperText={touched.stylcode && errors.stylcode}
                                        error={Boolean(touched.stylcode && errors.stylcode)}
                                        name={'stylcode'}
                                        label={t('StyleCode')}
                                        touched={touched.stylcode}
                                        options={data}
                                        getOptionLabel={(option: any) => option.code || ''}
                                        isRequired={true}
                                    />
                                )}
                                {saveAs && (
                                    <div className={classes.populateField}>
                                        <div>{t('customer')}</div>
                                        <div>{values.customerName === '' ? '-' : values.customerName}</div>
                                    </div>
                                )}
                                <AutoComplete
                                    onBlur={handleBlur}
                                    values={values.lineId}
                                    onInputChange={(event: any) => {
                                        if (event !== null) {
                                            //Do nothing
                                        }
                                    }}
                                    options={orderingByAlphabetic(mfgLines, 'mfgLine')}
                                    defaultValue={
                                        orderingByAlphabetic(mfgLines, 'mfgLine').filter(
                                            (val: any) => val.id === values.lineId,
                                        )[0] || ({} as typeof mfgLines[0])
                                    }
                                    getOptionLabel={(option: any) => option.mfgLine}
                                    getOptionSelected={(option: any, value: any) => {
                                        return `${option.id}` === `${value.id}`;
                                    }}
                                    onChange={(event: any, value: any) => {
                                        if (value !== null) {
                                            const index = mfgLines.findIndex((x: any) => x.id === value.id);
                                            handleChange(event);
                                            if (index >= 0) {
                                                setFieldValue('lineId', value.id);
                                                if (value.mfgLine) {
                                                    setFieldValue('line', value.mfgLine);
                                                }
                                            }
                                        }
                                    }}
                                    helperText={touched.lineId && errors.lineId}
                                    error={Boolean(touched.lineId && errors.lineId)}
                                    name={'lineId'}
                                    label={t('Line')}
                                    touched={touched.lineId}
                                    isRequired={true}
                                />
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.order}
                                    onChange={handleChange}
                                    placeholder={t('OrderNo')}
                                    helperText={touched.order && errors.order}
                                    error={Boolean(touched.order && errors.order)}
                                    name={'order'}
                                    label={t('OrderNo')}
                                />

                                {!saveAs && (
                                    <AutoComplete
                                        onBlur={handleBlur}
                                        values={values.customer}
                                        onInputChange={(event: any) => {
                                            if (event !== null) {
                                                //Do nothing
                                            }
                                        }}
                                        onChange={(event: any, value: any) => {
                                            if (value !== null) {
                                                const index = customers.findIndex((x: any) => x.id === value.id);
                                                handleChange(event);
                                                setFieldValue('customer', value.id);

                                                if (index >= 0) {
                                                    setFieldValue('customerName', customers[index].customer);
                                                }
                                            }
                                        }}
                                        defaultValue={
                                            customers.filter((val: any) => val.id === values.customer)[0] ||
                                            ({} as typeof customers[0])
                                        }
                                        autoCompleteValue={
                                            customers.filter((val: any) => val.id === values.customer)[0] ||
                                            ({} as typeof customers[0])
                                        }
                                        getOptionSelected={(option: any, value: any) => {
                                            return `${option.id}` === `${value.id}`;
                                        }}
                                        helperText={touched.customer && errors.customer}
                                        error={Boolean(touched.customer && errors.customer)}
                                        name={'customer'}
                                        label={t('SelectCustomer')}
                                        touched={touched.customer}
                                        options={customers}
                                        getOptionLabel={(option: any) => option.customer}
                                        isRequired={true}
                                    />
                                )}

                                <div className={classes.shiftContainer}>
                                    <div>
                                        <DatePicker
                                            id={values.dateStart}
                                            onBlur={handleBlur}
                                            values={values.dateStart}
                                            onChange={(event: any) => {
                                                const tempStartShifts = filterStartShifts(event.target.value);
                                                const tempEndShifts = filterEndShifts({
                                                    startDate: event.target.value,
                                                    endDate: values.dateEnd,
                                                    startShiftId: values.shift,
                                                });
                                                setFieldValue('dateStart', event.target.value);
                                                setFieldValue('startSelectedShifts', tempStartShifts);
                                                if (values.dateEnd !== '' && values.shift !== '') {
                                                    setFieldValue('endSelectedShifts', tempEndShifts);
                                                }
                                                if (values.dateEnd !== '' && values.dateEnd === event.target.value) {
                                                    if (isValidShift(tempStartShifts, values.shift)) {
                                                        setFieldValue('shift', '');
                                                        setFieldValue('shiftName', '');
                                                    }
                                                    if (isValidShift(tempEndShifts, values.endShift)) {
                                                        setFieldValue('endShift', '');
                                                        setFieldValue('endShiftName', '');
                                                    }
                                                }
                                                if (isPastShift(tempStartShifts, values.shift, event.target.value)) {
                                                    setFieldValue('shift', '');
                                                    setFieldValue('shiftName', '');
                                                }
                                                if (isPastShift(tempEndShifts, values.endShift, values.dateEnd)) {
                                                    setFieldValue('endShift', '');
                                                    setFieldValue('endShiftName', '');
                                                }
                                            }}
                                            error={Boolean(touched.dateStart && errors.dateStart)}
                                            name={'dateStart'}
                                            label={t('StartDate')}
                                            touched={touched.dateStart}
                                            errors={errors.dateStart}
                                            isRequired={true}
                                            stopPastDate={true}
                                        />
                                        {Boolean(
                                            (touched.dateStart && errors.dateStart) || (touched.shift && errors.shift),
                                        ) && (
                                            <Typography component={'div'} variant={'caption'} className={classes.error}>
                                                {errors.dateStart}
                                            </Typography>
                                        )}
                                    </div>
                                    <div>
                                        <AutoComplete
                                            onBlur={handleBlur}
                                            values={values.shift}
                                            onInputChange={(event: any) => {
                                                if (event !== null) {
                                                    //Do nothing
                                                }
                                            }}
                                            onChange={(event: any, value: any) => {
                                                if (value !== null) {
                                                    const { startSelectedShifts } = values;
                                                    const index = startSelectedShifts.findIndex(
                                                        (x: any) => x.id === value.id,
                                                    );
                                                    handleChange(event);
                                                    const tempEndShifts = filterEndShifts({
                                                        startDate: values.dateStart,
                                                        endDate: values.dateEnd,
                                                        startShiftId: value.id,
                                                    });

                                                    if (index >= 0) {
                                                        setFieldValue('shift', value.id);
                                                        if (value.shift) {
                                                            setFieldValue(
                                                                'shiftName',
                                                                startSelectedShifts[index].shift,
                                                            );
                                                        }
                                                    }
                                                    setFieldValue('endSelectedShifts', tempEndShifts);
                                                    if (values.dateEnd !== '' && values.dateEnd === values.dateStart) {
                                                        if (isValidShift(tempEndShifts, values.endShift)) {
                                                            setFieldValue('endShift', '');
                                                            setFieldValue('endShiftName', '');
                                                        }
                                                    }
                                                }
                                            }}
                                            defaultValue={
                                                values.startSelectedShifts.filter(
                                                    (val: any) => val.id === values.shift,
                                                )[0] || ({} as typeof values.startSelectedShifts[0])
                                            }
                                            autoCompleteValue={
                                                values.startSelectedShifts.filter(
                                                    (val: any) => val.id === values.shift,
                                                )[0] || ({} as typeof values.startSelectedShifts[0])
                                            }
                                            error={Boolean(touched.shift && errors.shift)}
                                            name={'shift'}
                                            label={t('StartShift')}
                                            touched={touched.shift}
                                            options={values.startSelectedShifts}
                                            getOptionLabel={(option: any) => option.shift}
                                            isRequired={true}
                                            addStyle={true}
                                            getOptionSelected={(option: any, value: any) => {
                                                return `${option.id}` === `${value.id}`;
                                            }}
                                        />
                                        {Boolean(
                                            (touched.dateStart && errors.dateStart) || (touched.shift && errors.shift),
                                        ) && (
                                            <Typography component={'div'} variant={'caption'} className={classes.error}>
                                                {errors.shift}
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                                <div className={classes.shiftContainer}>
                                    <div>
                                        <DatePicker
                                            id={values.dateEnd}
                                            onBlur={handleBlur}
                                            values={values.dateEnd}
                                            onChange={(event: any) => {
                                                const tempEndShifts = filterEndShifts({
                                                    startDate: values.dateStart,
                                                    endDate: event.target.value,
                                                    startShiftId: values.shift,
                                                });
                                                setFieldValue('dateEnd', event.target.value);
                                                setFieldValue('endSelectedShifts', tempEndShifts);
                                                if (values.endShift !== '' && event.target.value === values.dateStart) {
                                                    if (isValidShift(values.startSelectedShifts, values.shift)) {
                                                        setFieldValue('shift', '');
                                                        setFieldValue('shiftName', '');
                                                    }
                                                    if (isValidShift(tempEndShifts, values.endShift)) {
                                                        setFieldValue('endShift', '');
                                                        setFieldValue('endShiftName', '');
                                                    }
                                                }
                                            }}
                                            error={Boolean(touched.dateEnd && errors.dateEnd)}
                                            name={'dateEnd'}
                                            label={t('EndDate')}
                                            touched={touched.dateEnd}
                                            errors={errors.dateEnd}
                                            isRequired={true}
                                            stopPastDate={true}
                                        />
                                        {Boolean(
                                            (touched.dateEnd && errors.dateEnd) ||
                                                (touched.endShift && errors.endShift),
                                        ) && (
                                            <Typography component={'div'} variant={'caption'} className={classes.error}>
                                                {errors.dateEnd}
                                            </Typography>
                                        )}
                                    </div>
                                    <div>
                                        <AutoComplete
                                            onBlur={handleBlur}
                                            values={values.endShift}
                                            onInputChange={(event: any) => {
                                                if (event !== null) {
                                                    //Do nothing
                                                }
                                            }}
                                            onChange={(event: any, value: any) => {
                                                if (value !== null) {
                                                    const index = shifts.findIndex((x: any) => x.id === value.id);
                                                    handleChange(event);
                                                    if (index >= 0) {
                                                        setFieldValue('endShift', value.id);
                                                        if (value.shift) {
                                                            setFieldValue('endShiftName', shifts[index].shift);
                                                        }
                                                    }
                                                    if (values.dateEnd !== '' && values.dateEnd === values.dateStart) {
                                                        if (isValidShift(values.startSelectedShifts, values.shift)) {
                                                            setFieldValue('shift', '');
                                                            setFieldValue('shiftName', '');
                                                        }
                                                    }
                                                }
                                            }}
                                            autoCompleteValue={
                                                values.endSelectedShifts.filter(
                                                    (val: any) => val.id === values.endShift,
                                                )[0] || ({} as typeof values.endSelectedShifts[0])
                                            }
                                            error={Boolean(touched.endShift && errors.endShift)}
                                            name={'endShift'}
                                            label={t('EndShift')}
                                            touched={touched.endShift}
                                            options={values.endSelectedShifts}
                                            getOptionLabel={(option: any) => option.shift}
                                            isRequired={true}
                                            addStyle={true}
                                            defaultValue={
                                                values.endSelectedShifts.filter(
                                                    (val: any) => val.id === values.endShift,
                                                )[0] || ({} as typeof values.endSelectedShifts[0])
                                            }
                                            getOptionSelected={(option: any, value: any) => {
                                                return `${option.id}` === `${value.id}`;
                                            }}
                                        />
                                        {Boolean(
                                            (touched.dateEnd && errors.dateEnd) ||
                                                (touched.endShift && errors.endShift),
                                        ) && (
                                            <Typography component={'div'} variant={'caption'} className={classes.error}>
                                                {errors.endShift}
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.quantity}
                                    onChange={handleChange}
                                    placeholder={t('Order')}
                                    helperText={touched.quantity && errors.quantity}
                                    error={Boolean(touched.quantity && errors.quantity)}
                                    name={'quantity'}
                                    label={t('QuantityPcs')}
                                    isRequired={true}
                                />
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.target}
                                    onChange={handleChange}
                                    placeholder={t('TargetEFF')}
                                    helperText={touched.target && errors.target}
                                    error={Boolean(touched.target && errors.target)}
                                    name={'target'}
                                    label={t('TargetEFF')}
                                    isRequired={true}
                                />
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.lineName}
                                    onChange={handleChange}
                                    helperText={touched.lineName && errors.lineName}
                                    error={Boolean(touched.lineName && errors.lineName)}
                                    name={'lineName'}
                                    label={t('LineSetupname')}
                                    isRequired={true}
                                />

                                {saveAs || edit ? (
                                    <div className={classes.btnContainer}>
                                        <Button
                                            variant="outlined"
                                            onClick={handleClose}
                                            className={`${classes.submitButtonSaveAs} ${classes.cancelBtn}`}
                                        >
                                            {t('Cancel')}
                                        </Button>
                                        <Button
                                            type="submit"
                                            disabled={isSubmitting}
                                            variant="contained"
                                            className={`${classes.submitButtonSaveAs} ${classes.saveBtn}`}
                                        >
                                            {saveAs ? t('Save') : t('OK')}
                                        </Button>
                                    </div>
                                ) : (
                                    <div className={classes.btnContainer}>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={isSubmitting}
                                            variant="contained"
                                            className={classes.submitButton}
                                        >
                                            {t('Apply')}
                                        </Button>
                                    </div>
                                )}
                            </Form>
                        )}
                    </Formik>
                </div>
            ) : (
                <>
                    Fetching line setup form data...
                    <div style={{ padding: '10px 1px' }}>
                        <LinearProgress />
                    </div>
                </>
            )}

            <SimpleModal
                // handleClose={handleClose}
                handleOpen={handleOpen}
                isOpen={duplicate}
                body={<NotFound data={postLineSetupData} history={history} />}
            />
            <SimpleModal
                handleOpen={handleOpen}
                isOpen={overlapping}
                body={<OverLapping data={postLineSetupData} history={history} />}
            />
        </div>
    );
}
