import React from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    Typography,
    MenuItem,
    Button,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import Schema from '../../../schema';
import { useTranslation } from 'react-i18next';
import SelectPicker from '../../../components/selectPicker/index';
import NormalInput from '../../../components/inputs/normalInput';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReducersModel } from '../../../model';
import moment from 'moment';
import { editOperator } from '../action';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            '& .MuiTextField-root': {
                marginBottom: '10px!important',
            },
        },
        textStyl: {
            fontSize: '20px',
            color: 'var(--dark-grey)',
        },
        paper: {
            position: 'absolute',
            width: '25%',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        saveContainer: {
            backgroundColor: 'white',
            padding: '10px 0',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '41px',
        },
        cancelButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#515151',
            opacity: 1,
            height: '36px',
        },
        saveButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#FFFFFF',
            opacity: 1,
            height: '36px',
        },
        statusContainer: {
            display: 'flex',
            alignItems: 'center',
            '& .MuiFormGroup-root': { flexDirection: 'row' },
            '& .MuiTypography-root': {
                textAlign: 'left',
                font: 'normal normal normal 14px/21px Roboto',
                letterSpacing: '0.08px',
                color: '#515151',
                opacity: 1,
            },
        },
        status: {
            marginRight: '40px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
        subHeading: {
            textAlign: 'left',
            font: 'normal normal normal 12px/21px Roboto',
            letterSpacing: '0.07px',
            color: '#8C8C8C',
            opacity: 1,
            marginBottom: '3px',
        },
        author: {
            marginTop: '15px',
        },
        deleteIcon: {
            display: 'flex',
            margin: "44px 0 7px 0",
            justifyContent: "space-between",          
            '& svg': {
                cursor: 'pointer',
            },
        },
    }),
);

export default function EditOperator({ handleClose, handleOpenDelete }: any) {
    const classes = styles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { shifts } = useSelector((state: ReducersModel) => state.shiftsReducer);

    function getModalStyle() {
        const top = 50;
        const left = 50;
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const linesListing = () => {
        //Note: using lineId here from line setup listing for id, because when using id it was throwing message of "line setup not found"
        return mfgLines && mfgLines.length > 0
            ? mfgLines.map((data: any, index: any) => {
                  return (
                      <MenuItem id={data.id} value={data.id}>
                          {data.mfgLine}
                      </MenuItem>
                  );
              })
            : null;
    };

    const shiftsListing = () => {
        return shifts && shifts.length > 0
            ? shifts.map((data: any, index: any) => {
                  return (
                      <MenuItem id={data.id} value={data.id}>
                          {data.shift}
                      </MenuItem>
                  );
              })
            : null;
    };

    const {
        selectedOperatorId,
        selectedLine,
        selectedShiftId,
        selectedShift,
        selectedOperatorName,
        selectedCardId,
        selectedisActive,
        createdBy,
        updatedBy,
        updatedDt,
        createdDt,
        selectedEmployeeId,
        description,
        selectedLineSetupId,
    } = useSelector((state: ReducersModel) => state.operatorReducer);

    const { mfgLines } = useSelector((state: ReducersModel) => state.mfgLinesReducer);

    const [modalStyle] = React.useState(getModalStyle);

    return (
        <div style={modalStyle} className={classes.paper}>
            <div className={classes.mainContainer}>
                <div className={classes.deleteIcon}>
                    <Typography className={classes.textStyl}>
                        {t('Edit')} {t('OPERATOR')}
                    </Typography>
                    <DeleteIcon color={'primary'} onClick={handleOpenDelete} />
                </div>
                <div>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            lineId: selectedLineSetupId,
                            line: selectedLine,
                            shiftId: selectedShiftId,
                            shift: selectedShift,
                            operatorName: selectedOperatorName,
                            cardId: selectedCardId,
                            operatorId: selectedOperatorId,
                            isActive: `${selectedisActive}`,
                            employeeId: selectedEmployeeId,
                            description: description,
                        }}
                        validationSchema={Schema.AddOperatorSchema(t)}
                        onSubmit={(values, { setSubmitting }) => {
                            dispatch(editOperator(values, setSubmitting, history, handleClose, selectedLine));
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                        }) => (
                            <Form>
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.operatorName}
                                    onChange={handleChange}
                                    helperText={touched.operatorName && errors.operatorName}
                                    error={Boolean(touched.operatorName && errors.operatorName)}
                                    name={'operatorName'}
                                    label={t('OperatorName')}
                                    isRequired={true}
                                />

                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.description}
                                    onChange={handleChange}
                                    helperText={touched.description && errors.description}
                                    error={Boolean(touched.description && errors.description)}
                                    name={'description'}
                                    label={t('DESCRIPTION')}
                                />
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.employeeId}
                                    onChange={handleChange}
                                    helperText={touched.employeeId && errors.employeeId}
                                    error={Boolean(touched.employeeId && errors.employeeId)}
                                    name={'employeeId'}
                                    label={t('EmployeeID')}
                                    isRequired={true}
                                />
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.cardId}
                                    onChange={handleChange}
                                    helperText={touched.cardId && errors.cardId}
                                    error={Boolean(touched.cardId && errors.cardId)}
                                    name={'cardId'}
                                    label={t('CardID')}
                                    isRequired={true}
                                />

                                <SelectPicker
                                    id={values.lineId}
                                    onBlur={handleBlur}
                                    values={values.lineId}
                                    onChange={(event: any, index: any, value: any) => {
                                        setFieldValue('lineId', event.target.value);
                                        const indexItem = mfgLines.findIndex((x: any) => x.id === event.target.value);
                                        setFieldValue('line', mfgLines[indexItem].mfgLine);
                                    }}
                                    helperText={touched.lineId && errors.lineId}
                                    error={Boolean(touched.lineId && errors.lineId)}
                                    name={'lineId'}
                                    label={t('Line')}
                                    isRequired={true}
                                    touched={touched.lineId}
                                    errors={errors.lineId}
                                    data={linesListing()}
                                />
                                <SelectPicker
                                    id={values.shiftId}
                                    onBlur={handleBlur}
                                    values={values.shiftId}
                                    onChange={(event: any) => {
                                        setFieldValue('shiftId', event.target.value);
                                        const index = shifts.findIndex((x: any) => x.id === event.target.value);
                                        setFieldValue('shift', shifts[index].shift);
                                    }}
                                    helperText={touched.shiftId && errors.shiftId}
                                    error={Boolean(touched.shiftId && errors.shiftId)}
                                    name={'shiftId'}
                                    label={t('Shift')}
                                    isRequired={true}
                                    touched={touched.shiftId}
                                    errors={errors.shiftId}
                                    data={shiftsListing()}
                                />

                                <div className={classes.statusContainer}>
                                    <span className={classes.status}>{t('Status')}: </span>
                                    <RadioGroup
                                        id="isActive"
                                        value={values.isActive}
                                        onChange={(event: any) => {
                                            setFieldValue('isActive', `${event.target.value}`);
                                        }}
                                    >
                                        <FormControlLabel
                                            value={`true`}
                                            control={<Radio color="primary" />}
                                            label={t('Active')}
                                        />
                                        <FormControlLabel
                                            value={`false`}
                                            control={<Radio color="primary" />}
                                            label={t('Inactive')}
                                        />
                                    </RadioGroup>
                                </div>

                                <div className={classes.author}>
                                    <div className={classes.subHeading}>
                                        {t('MODIFIEDBY')}: {updatedBy}
                                    </div>
                                    <div className={classes.subHeading}>
                                        {t('DATEMODIFIED')}: {updatedDt ? moment(updatedDt).format('YYYY-MM-DD') : '-'}
                                    </div>
                                    <div className={classes.subHeading}>
                                        {t('Createdby')}: {createdBy}
                                    </div>
                                    <div className={classes.subHeading}>
                                        {t('DateCreated')}: {moment(createdDt).format('YYYY-MM-DD')}
                                    </div>
                                </div>

                                <div className={classes.saveContainer}>
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        className={classes.cancelButton}
                                        onClick={handleClose}
                                    >
                                        {t('CANCEL')}
                                    </Button>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className={classes.saveButton}
                                    >
                                        {isSubmitting ? `${t('Saving')}...` : t('Save')}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}
