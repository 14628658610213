import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LocalImages from '../../../../utils/images';
import { Paper, TextField, Typography, createStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { saveLBRHistory, updateStyleInfo } from '../../action';
import { ReducersModel } from '../../../../model';
import { formatDate } from '../LBRHistory/helpers';
import { getValueTctOrSmv } from '../../../../utils/helpers';

const useStyles = makeStyles(() => 
    createStyles({
        title: {
            color: '#515151',
            font: 'normal normal normal 20px/20px Roboto',
            letterSpacing: '0.07px',
            opacity: 1,
        },
        subTitle: {
            font: 'normal normal normal 12px/21px Roboto',
            letterSpacing: '0.07px',
            color: '#8C8C8C',
            opacity: 1,
        },
        subTitleValue: {
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#000000',
            opacity: 1,
            marginBottom: '9px',
        },
        btnSty: {
            width: '80px',
            height: '30px',
            fontSize: '14px',
            margin: '0px 10px',
        },
        cancelBtn: {
            color: 'var(--input-fill)',
        },
        saveBtn: {
            color: 'var(--white)',
            backgroundColor: 'var(--blue)',
            '&:hover': {
                backgroundColor: 'var(--dark-blue)',
            },
        },
    })
)

export default function SaveLBRDialog({ open, onClose, lbrRestoreUi }: any) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { infoData, lbr } = useSelector((state: ReducersModel) => state.lineAssistInfoReducer);
    const { lineSetup } = useSelector((state: ReducersModel) => state.assistTableLineSetup);
    const [remark, setRemark] = useState('');
    const [currentShiftEff, setCurrentShiftEff] = useState(0);

    const getEffValues = async () => {
        const currentShiftEff = await getValueTctOrSmv(infoData, 'currentShiftEff', 'currentShiftEffTct');
        setCurrentShiftEff(currentShiftEff);
    }

    const handleChange = (event: any) => {
        setRemark(event.target.value);
    }

    const onCompleted = () => {
        dispatch(saveLBRHistory(infoData?.id, infoData?.uid, infoData?.shiftId, remark, lbrRestoreUi, true));
    }

    const handleSubmit = () => {
        onClose();
        dispatch(updateStyleInfo(onCompleted, false, false));
        setRemark('');
    }

    const handleRemoveRemark = () => {
        onClose();
        setRemark('');
    }

    React.useEffect(() => {
        infoData.styleCode && getEffValues();
    }, [infoData]);

    return (
        <Dialog
            open={open}
            onClose={handleRemoveRemark}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            PaperComponent={(props: any) => (
                <Paper {...props} style={{ width: '320px'}} />
            )}
        >
            <div style={{display: 'flex'}}>
                <DialogTitle id="alert-dialog-title"
                    className={classes.title}
                >
                    {"SAVE LBR"}
                </DialogTitle>
                <img src={LocalImages.BETA} alt="beta" />
            </div>
            <DialogContent>
                {
                    [infoData] && [infoData].map((data: any) => (
                        <>
                            <Typography className={classes.subTitle}>LBR</Typography>
                            <Typography className={classes.subTitleValue}>{(infoData.actualLbr)?.toFixed(2)}%</Typography>
                            <Typography className={classes.subTitle}>Manpower</Typography>
                            <Typography className={classes.subTitleValue}>{lineSetup.length}</Typography>
                            <Typography className={classes.subTitle}>EFF</Typography>
                            <Typography className={classes.subTitleValue}>{currentShiftEff.toFixed(2)}%</Typography>
                            <Typography className={classes.subTitle}>Output by pcs</Typography>
                            <Typography className={classes.subTitleValue}>{data.totalOutput}/{data.quantity}</Typography>
                            <Typography className={classes.subTitle}>Output by mins</Typography>
                            <Typography className={classes.subTitleValue}>{(data.totalOutputMin)?.toFixed(0)}/{data.totalWebSmv * data.quantity}</Typography>
                            <Typography className={classes.subTitle}>Last Time Study</Typography>
                            <Typography className={classes.subTitleValue}>
                                { formatDate(data.updatedDt, 'yyyy-MM-DD HH:mm') }
                            </Typography>
                            <Typography className={classes.subTitle}>Remarks</Typography>
                            <div style={{marginTop: '-7px'}}>
                                <TextField variant="standard"
                                    value={remark}
                                    fullWidth
                                    autoFocus
                                    onChange={(e: any) => handleChange(e)}
                                />
                            </div>
                        </>
                    ))
                }
            </DialogContent>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '15px',
                    marginBottom: '35px'
                }}
            >
                <Button
                    variant="outlined"
                    className={`${classes.btnSty} ${classes.cancelBtn}`}
                    onClick={handleRemoveRemark}
                >
                    {t('CANCEL')}
                </Button>
                <Button
                    className={`${classes.btnSty} ${classes.saveBtn}`}
                    onClick={handleSubmit}
                >
                    {t('Ok')}
                </Button>
            </div>
        </Dialog>
    );
}
