import React, { useEffect, useRef } from 'react';
import { makeStyles, createStyles, Theme, TextField, InputAdornment, Button, Avatar, Menu } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import LocalImages from '../../utils/images';
import AddIcon from '@material-ui/icons/Add';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import LineSetupHistoryTable from '../../components/tables/lineSetupHistory';
import { useDispatch, useSelector } from 'react-redux';
import Utils from '../../utils';
import { getLineSetupHistory } from './action';
import LineSetupHistoryListFilter from '../../components/filters/lineSetupHistoryList';
import { ReducersModel } from '../../model';
import { useTranslation } from 'react-i18next';
import { getMfgLinesList, getShiftsList } from '../admin/action';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            width: '95.5%',
            margin: '-30px auto 16px auto',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '18px 1.2%',
            marginBottom: '20px',
            '& .MuiPaper-elevation1': {
                boxShadow: 'unset',
            },
        },
        heading: {
            font: 'normal normal normal 20px/24px Roboto',
            letterSpacing: '0.07px',
            color: '#515151',
            opacity: 1,
            marginBottom: '18px',
        },
        searchInput: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            width: '300px',
            '& .MuiInputBase-root': {
                height: '36px',
                font: 'normal normal normal 14px/20px Roboto',
                letterSpacing: '0.05px',
                color: '#515151',
                opacity: 1,
            },
        },
        searchIcon: {
            cursor: 'pointer',
        },
        styleOperations: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '18px',
        },
        leftStyleOperations: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
        },
        addButton: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            opacity: 1,
            margin: '0 15px',
            border: '1px solid #1E88E5',
            font: 'normal normal normal 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#1E88E5',
            textTransform: 'uppercase',
            height: '36px',
        },
        filterButton: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            opacity: 1,
            border: '1px solid #1E88E5',
            font: 'normal normal normal 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#1E88E5',
            textTransform: 'uppercase',
            height: '36px',
        },
        filterCount: {
            minWidth: '24px',
            minHeight: '24px',
            width: '1em',
            height: '1em',
            textAlign: 'center',
            font: 'normal normal normal 14px/20px Roboto!important',
            letterSpacing: '0.05px',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            opacity: 1,
            backgroundColor: '#1E88E5',
        },
        filterMenu: {
            '& .MuiList-root': {
                width: '194px',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: '0px 0px 6px #00000029',
                opacity: 1,
            },
        },
    }),
);

export default function LineSetupHistory({ history }: any) {
    const classes = styles();
    const dispatch = useDispatch();
    const styleId = new URL(window.location.href).searchParams.get('id');
    const { t } = useTranslation();

    const debounceCount = useRef(0);
    const api = () =>
        new Promise((res) => {
            setTimeout(() => {
                res(1);
            }, 500);
        });

    const handleSearch = (event: any) => {
        dispatch({
            type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
            payload: {
                search: event.target.value,
            },
        });
        debounceCount.current += 1;
        const localDebounceCount = debounceCount.current;
        api().then((d) => {
            if (localDebounceCount !== debounceCount.current) {
                return;
            }
            dispatch(getLineSetupHistory(styleId));
        });
    };

    useEffect(() => {
        dispatch(getMfgLinesList());
        dispatch(getShiftsList());

        return () => {
            dispatch({
                type: Utils.ActionName.GET_LINE_SETUP_INFO,
                payload: {
                    infoData: [],
                },
            });
        };
    }, []);

    const { filterCustomerIds, filterLineIds, filterShiftIds, filterStatusHistory } = useSelector(
        (state: ReducersModel) => state.lineSetupHistoryTableReducer,
    );

    const getFilterCount = () => {
        return +filterCustomerIds.length + filterLineIds.length + filterShiftIds.length + filterStatusHistory.length;
    };
    // for filter
    const { mfgLines } = useSelector((state: ReducersModel) => state.mfgLinesReducer);
    const { shifts } = useSelector((state: ReducersModel) => state.shiftsReducer);
    const { customers } = useSelector((state: ReducersModel) => state.customersListReducer);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{t('LineSetupHistory')}</title>
            </Helmet>
            <div className={classes.mainContainer}>
                <div className={classes.styleOperations}>
                    <div className={classes.leftStyleOperations}>
                        <TextField
                            className={classes.searchInput}
                            variant="outlined"
                            placeholder={t('Search')}
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img
                                            alt={t('Search')}
                                            src={LocalImages.SEARCH_ICON}
                                            className={classes.searchIcon}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<AddIcon />}
                            className={classes.addButton}
                            onClick={() => {
                                history.push('/lineSetup');
                            }}
                        >
                            {t('Add')}
                        </Button>
                    </div>
                    <div>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<FormatListBulletedIcon />}
                            className={classes.filterButton}
                            onClick={handleOpenFilter}
                            endIcon={<Avatar className={classes.filterCount}>{getFilterCount()}</Avatar>}
                        >
                            {t('Filter')}
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseFilter}
                            className={classes.filterMenu}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            getContentAnchorEl={null}
                        >
                            <LineSetupHistoryListFilter
                                styleId={styleId}
                                customers={customers}
                                lines={mfgLines}
                                shifts={shifts}
                            />
                        </Menu>
                    </div>
                </div>

                <LineSetupHistoryTable />
            </div>
        </React.Fragment>
    );
}
