import React, { useEffect, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import SaveLBRDialog from "./saveLbrDialog";
import LBRHistory from '../LBRHistory';
import ajax from "../../../../utils/ajax";
import Utils from "../../../../utils";
import { useSelector } from "react-redux";
import { ReducersModel } from "../../../../model";
import { formatDate } from "../LBRHistory/helpers";
import LBRTooltip from "../../components/LBRTooltip";

export default function LBRCard ({
    classes, 
    lbr, 
    onOpenDialog,
    lbrRestoreUi,
}: any) {
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = useState(false);
    const [openHistory, setOpenHistory] = useState(false);
    const [lbrRange, setLbrRange] = useState<any>({ lowest_lbr: null, highest_lbr: null });
    const { infoData } = useSelector((state: ReducersModel) => state.lineAssistInfoReducer);
    
    const handleOpenDialog = () => {
        setOpenDialog(true);
        onOpenDialog();
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    const getLbrHistroyRange = async () => {
        const query = {
            lineSetupId: infoData.id,
            startDateShift: formatDate(infoData.lineStartDate, 'yyyy-MM-DD'),
            endDateShift: formatDate(infoData.lineEndDate, 'yyyy-MM-DD'),
        }
        const baseUrl = Utils.constants.apiUrl;
        const pathUrl = Utils.endPoints.lbrHistoryRange;
        const { data: response } = await ajax(`${baseUrl}${pathUrl}?${new URLSearchParams(query)}`);
        setLbrRange(response.data);
    };

    useEffect(() => {
        if(!infoData.id) return;
        getLbrHistroyRange();
    }, [infoData]);

    return (
        <>
            <div className={classes.lbrContainer}>
                <div>
                    <Typography className={classes.lbrText}>{t('LBR')}</Typography>
                    <LBRTooltip />
                </div>
                <div style={{marginTop: '10px'}}>
                    <Typography className={classes.highestLbr}>{lbrRange.highest_lbr ? lbrRange.highest_lbr.toFixed(2) : '-'} %</Typography>
                    <Typography className={classes.lowestLbr}>{lbrRange.lowest_lbr ? lbrRange.lowest_lbr.toFixed(2) :  '-'} %</Typography>
                </div>
                <div style={{marginRight: '-10px'}}>
                    <Button
                        size='small'
                        color='primary'
                        variant="contained"
                        onClick={() => handleOpenDialog()}
                    >
                        {t('Save')}
                    </Button>
                    <Typography 
                        onClick={() => setOpenHistory(true)} 
                        className={classes.historyText}>
                            HISTORY
                    </Typography>
                    <LBRHistory 
                        lineSetupId={infoData.id}
                        uid={infoData.uid}
                        onClose={() => setOpenHistory(false)} 
                        open={openHistory} />
                </div>
            </div>
            <SaveLBRDialog 
                open={openDialog}
                onClose={() => handleCloseDialog()}
                lbrRestoreUi={lbrRestoreUi}
            />
        </>
    )
}