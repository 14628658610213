import * as React from 'react';
import { Grid } from '@material-ui/core';
import { CustomBox, NumberStep, OperationDesc } from '../../styles';
import { minuteToTime } from '../../../../../../utils/convertTime';

export default ({ rows }: any) => {
    return (
        <Grid item xs={6}>
            {rows?.map((item: any) => {
                return (
                    item.operations?.map((operation: any) => (
                        <CustomBox
                            style={{ 
                                padding: '0', 
                                display: 'flex',
                                fontSize: 14,
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 0,
                            }}
                            background={'#fff'}
                            height={operation.height}>
                            <Grid item xs={1}><NumberStep>{operation.step}</NumberStep></Grid>
                            <Grid item xs={3}><OperationDesc>{operation.desc1}</OperationDesc></Grid>
                            <Grid item xs={1}><span>{operation.macSubTypeData}</span></Grid>
                            <Grid item xs={1}><span>{operation.needleType}</span></Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={1}><span>{operation.smv}</span></Grid>
                            <Grid item xs={2}><span>{minuteToTime(operation.tct)}</span></Grid>
                            <Grid item xs={1}><span>{operation.thManpower}</span></Grid>
                        </CustomBox>
                    ))
                )
            })}
        </Grid>
    )
}