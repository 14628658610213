import Utils from '../../utils';
import { signInModel, ThemeModel, FactoryModel, globalLangData } from '../../model';

export const signInReducer = (state: signInModel = new signInModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.SIGN_IN:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const themeReducer = (state: ThemeModel = new ThemeModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.UPDATE_THEME_VALUE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const factoryReducer = (state: FactoryModel = new FactoryModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.UPDATE_FACTORY:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const globalLangDataReducer = (state: globalLangData = new globalLangData(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GLOBAL_LANG_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
