import React from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            '& svg': {
                cursor: 'pointer',
                marginLeft: '15px',
            },
        },
        categories: {
            padding: '7px',
            letterSpacing: '0.08px',
            textTransform: 'uppercase',
            opacity: 1,
            background: 'var(--background-grey) 0% 0% no-repeat padding-box',
            '& th': {
                font: 'normal normal bold 14px/21px Roboto;',
                fontWeight: 'bold',
                padding: '5px 14px',
                height: '30px',
                color: '#515151',
            },
            '&>th:nth-child(1)': {
                width: '20%',
            },
            '&>th:nth-child(2)': {
                width: '10%',
            },
            '&>th:nth-child(3)': {
                width: '15%',
            },
            '&>th:nth-child(4)': {
                width: '10%',
            },
            '&>th:nth-child(5)': {
                width: '15%',
            },
            '&>th:nth-child(6)': {
                width: '30%',
            },
        },
        categoriesItems: {
            borderLeft: 'solid 6px rgb(0,0,0,0)',
            '&:hover': {
                borderLeft: 'solid 6px var(--blue)',

                '&>td:nth-child(4)': {
                    '& svg': {
                        color: 'var(--blue)',
                        transition: '1s all',
                    },
                },
            },
            '&>td:nth-child(5)': {
                '& svg': {
                    cursor: 'pointer',
                    color: 'transparent',
                    width: '30px',
                },
            },
            '& td': {
                font: 'normal normal normal 14px/14px Roboto',
                padding: '6px 14px',
                textTransform: 'uppercase',
                letterSpacing: '0px',
                color: '#515151',
                opacity: 1,
                wordBreak: 'break-word',
            },
        },
        noRecords: {
            marginTop: '25px',
            marginBottom: '14px',
            textAlign: 'center',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#707070',
            opacity: 1,
        },
        boldItems: {
            '& td': {
                fontWeight: 500,
            },
        },
    }),
);

export default function UserAccess() {
    const classes = styles();
    const { t } = useTranslation();

    return (
        <div className={classes.mainContainer}>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow className={classes.categories}>
                            <TableCell>{t('Name')}</TableCell>
                            <TableCell>{t('Code')}</TableCell>
                            <TableCell>{'Type'}</TableCell>
                            <TableCell>{t('DESCRIPTION')}</TableCell>
                            <TableCell>{t('Status')}</TableCell>
                            <TableCell align="right">{t('Access')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow className={`${classes.categoriesItems} ${classes.boldItems}`} key={'1'}>
                            <TableCell>{t('User')}</TableCell>
                            <TableCell>{t('User')}</TableCell>
                            <TableCell>{t('User')}</TableCell>
                            <TableCell>{t('User')}</TableCell>
                            <TableCell>{t('User')}</TableCell>
                            <TableCell align="right">{t('User')}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}