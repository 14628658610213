import * as React from 'react';
import {
    Box,
    Grid,
    withStyles,
    Typography,
    Button,
} from '@material-ui/core';
import { styled } from '@material-ui/core';

export const CustomBox = withStyles({
    root: (props: any) => ({
        height: props.height,
        background: props.background,
        outline: '1px solid #707070',
        marginBottom: 10,
        padding: '0px 20px',
    }),
})(Box);

export const LBRRestoreContainer = styled(Box)({
    padding: 10,
    position: 'relative',
    overflow: 'hidden',
});

export const ButtonRestore = styled(Button)({
    fontSize: 19,
    borderRadius: 50,
    padding: '5px 15px',
});

export const LBRRestoreContainerOverlay = styled(Box)({
    position: 'absolute',
    inset: 0,
    width: '100%',
    height: '100%',
    background: '#70707043',
    zIndex: 2,
    marginTop: 10,
    borderTop: '1px solid #000',
    overflow: 'hidden',
});

export const TextSmallEllipsis = styled('span')({
    fontSize: 10, 
    fontWeight: 'bold', 
    color: '#000', 
    maxWidth: '60px', 
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    whiteSpace: 'nowrap',
    display: 'block',
});


export const TextPercentEllipsis = styled('span')({
    fontSize: 17, 
    fontWeight: 'bold', 
    color: '#000',
});

export const BoxPercentage = withStyles({
    root: {
        position: 'relative',
        padding: 5,
        width: '100%',
        background: '#eee',
        '&:before': {
            position: 'absolute',
            content: '" "',
            inset: 0,
            height: '100%',
            width: 3,
            background: '#777',
        }
    }
})((props: any) => (
    <Box {...props}>
        <TextSmallEllipsis>{props.subTitle}</TextSmallEllipsis>
        <TextPercentEllipsis>{props.title}</TextPercentEllipsis>
    </Box>
));

export const HeadTitle = styled(Typography)({
    padding: '20px 15px',
});

export const Title = styled('div')({
    fontSize: '14px',
    wordBreak: 'break-all',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '161px',
    width: '100%',
    overflow: 'hidden',
}); 

export const ContainerTktMinMaxMinute = styled('div')({
    width: '100%',
    position: 'relative',
    alignItems: 'center',
    background: '#b8b8b8', 
    zIndex: 2,
    padding: '6px 0', 
    minHeight: 28,
    display: 'flex',
    justifyContent: 'space-evenly',
});

export const TktMinMaxMinuteItem = withStyles({
    root: (props: any) => ({
        width: '100%',
        textAlign: 'center',
        fontSize: 13,
        position: 'relative',
        '&::before':{
            position: 'absolute',
            zIndex: -1,
            left: '50%',
            content: '" "',
            width: 1,
            height: '9999px',
            background: '#b8b8b8',
        }
    })
})((props: any) => 
    <Typography {...props}>{props.minute}</Typography>
);

export const AvgCyleTimeLine = withStyles({
    root: ({ offset }: any) => ({
        width: 2,
        background: 'orange',
        display: 'block',
        height: '100%',
        position: 'absolute',
        inset: `0px ${offset || 0}%`,
        zIndex: 4,
        ...(offset && {
            marginLeft: 10,
        })
    }),
})(Box);

export const AvgTakTimeLine = withStyles({
    root: ({ offset }: any) => ({
        width: 2,
        background: '#1e88e5',
        display: 'block',
        height: '100%',
        position: 'absolute',
        inset: `0px ${offset || 0}%`,
        zIndex: 4,
        ...(offset && {
            marginLeft: 10,
        })
    }),
})(Box);

export const CycleTimeLine = withStyles({
    root: (props: any) => ({
        width: `${props.width}%`,
        background: props.background,
        display: 'block',
        position: 'absolute',
        inset: 0,
        height: '13px',
        zIndex: 2,
        transform: 'translateY(19px)',
        ...(props.prevWith && ({
            width: (props.width - props.prevWith) + '%',
            inset: `0px ${props.prevWith}%`,
        }))
    }),
})(Box);

export const TctLine = withStyles({
    root: (props: any) => ({
        width:  '10px',
        background: '#1e88e5',
        display: 'block',
        position: 'absolute',
        inset: `0px ${props.width}%`,
        height: '30px',
        zIndex: 3,
        transform: 'translateY(10px)',
        ...(props.prevWith && ({
            inset: `0px ${(props.width + props.prevWith)}%`,
        }))
    }),
})(Box);

export const TktminuteLine = styled('div')({
    width: '100%',
    textAlign: 'right',
    '&:before': {
        position: 'absolute',
        top: 0,
        content: "' '",
        width: '1px',
        height: '100%',
        background: '#d2d2d2',
    },
});

export const BoxContainer = styled(Box)({
    background: '#d2d2d2', 
    padding: 6, 
    minHeight: 30,
    display: 'flex',
    alignItems: 'center',
});

export const CustomGrid = withStyles({
    root: {
        border: '1px solid #707070',
        marginBottom: 10,
        background: '#fff',
    }
})((props: any) => <Grid {...props} />);

export const OperationContainer = styled('div')({
    border: '1px solid #ccc', 
    padding: 10, 
    marginBottom: 5,
    display: 'flex',
    fontSize: 14,
    background: '#fff',
});

export const OperatorContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    height: '100%',
});

export const CustomerRow = styled(Grid)({
    height: 50,
});

export const OperationDesc = styled('div')({
    maxWidth: '140px',
    wordBreak: 'break-all',
    overflowY: 'scroll',
    maxHeight: 50,
});

export const NumberStation = styled(Typography)({
    fontSize: 18,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    height: '100%',
});

export const EffRow = withStyles({
    root: {
        display: 'flex', 
        height: 50, 
        alignItems: 'center',
        padding: 10, 
        marginBottom: 5,
    }
})((props: any) => (
    <Box {...props}>
        <Typography 
            variant='h6' 
            style={{ fontWeight: 'bold' }}>
            {props.value}
        </Typography>
    </Box>
));

export const ActMpwrRow = withStyles({
    root: {
        display: 'flex', 
        flexDirection: 'column',
        height: 50, 
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10, 
        marginBottom: 5,
    }
})((props: any) => (
    <Box {...props}>
        <span style={{ alignSelf: 'flex-end' }}>{props.value}</span>
        <span style={{ 
            height: '1px',
            width: '100%',
            borderBottom:' 1px solid #ccc',
        }}></span>
    </Box>
));

export const NumberStep = withStyles({
    root: {
        border: '1px solid #ccc',
        height: 20,
        width: 20,
        borderRadius: 20,
        textAlign: 'center',
        fontSize: 14,
    }
})((props: any) => (
    <Box style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'center',
        height: '100%',
    }}>
        <Typography {...props} />
    </Box>
));