import { tableHeaderCell } from "../../Components/LBRHistoryTable/constant";
import { getlbrViewBy } from "../../helpers";
import {
    UPDATE_DATE_RANGE,
    UPDATE_SHIFTS,
    REQUEST_API_ROWS,
    REQUEST_API_SUCCESS_ROWS,
    REQUEST_API_GRAPH,
    REQUEST_API_SUCCESS_GRAPH,
    REQUEST_API_SHIFTS,
    REQUEST_API_SUCCESS_SHIFTS,
    REQUEST_API_ERROR_SHIFTS,
    UPDATE_MULTIPLE_VALUES,
    UPDATE_SHIFT_ID,
    UPDATE_QUERY_ROWS,
} from "../constant";

const reducers = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case REQUEST_API_SHIFTS: return { ...state, isLoadingShifts: true };
        case REQUEST_API_SUCCESS_SHIFTS: return { ...state, isLoadingShifts: false };
        case REQUEST_API_ERROR_SHIFTS: return { ...state, errorShift: true, isLoadingShifts: false };

        case REQUEST_API_ROWS: return { ...state, isLoadingRows: true };
        case REQUEST_API_SUCCESS_ROWS: return { ...state, isLoadingRows: false };

        case REQUEST_API_GRAPH: return { ...state, isLoadingGraph: true };
        case REQUEST_API_SUCCESS_GRAPH: return { ...state, isLoadingGraph: false };

        case UPDATE_DATE_RANGE: return { ...state, dateRange: payload };
        case UPDATE_SHIFTS: return { ...state, shifts: payload };
        case UPDATE_SHIFT_ID: return { ...state, shiftId: payload };

        case UPDATE_QUERY_ROWS: return { ...state, queryLbrRows: {
            ...state.queryLbrRows,
            ...payload
        }};

        case UPDATE_SHIFT_ID: return { ...state, shiftId: payload };

        case UPDATE_MULTIPLE_VALUES: return {...state, ...payload};
        default: return state;
    }
}

const initialState = {
    /** global query */
    dateRange: null,
    shiftId: null,
    uid: null,
    lineSetupId: null,
    isLoadingShifts: false,
    isLoadingGraph: false,
    isLoadingRows: false,
    errorShift: false,
    shifts: [],
    /** global query */

    queryLbrGraph: {
        orderBy: 1,
        viewBy: getlbrViewBy(),
    },

    queryLbrRows: {
        orderBy: tableHeaderCell[1]['id'],
        orderDirection: 'desc',
        limit: 10,
        page: 1,
        count: 0,
    }
};

export default reducers;