import React from 'react';
import { makeStyles, createStyles, Theme, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            '& .MuiTextField-root': {
                marginBottom: '10px!important',
            },
        },
        textStyl: {
            margin: '44px 0 35px 0',
            textAlign: 'left',
            font: 'normal normal normal 20px/20px Roboto',
            letterSpacing: '0.07px',
            color: '#B90000',
            opacity: 1,
        },
        paper: {
            position: 'absolute',
            width: '20%',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        saveContainer: {
            backgroundColor: 'white',
            padding: '10px 0',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '41px',
        },
        cancelButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#515151',
            opacity: 1,
            height: '36px',
        },
        saveButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#FFFFFF',
            opacity: 1,
            height: '36px',
            backgroundColor: '#D32F2F',
        },
        info: {
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#000000',
            opacity: 1,
        },
    }),
);

export default function DeleteModal({ handleClose, onDelete, title, info }: any) {
    const classes = styles();
    const { t } = useTranslation();
    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const [modalStyle] = React.useState(getModalStyle);

    return (
        <div style={modalStyle} className={classes.paper}>
            <div className={classes.mainContainer}>
                <Typography className={classes.textStyl}>{title}</Typography>
                <div className={classes.info}>{t('TheRecordCanNotBeRecovered')}</div>
                <div className={classes.saveContainer}>
                    <Button variant="outlined" size="large" className={classes.cancelButton} onClick={handleClose}>
                        {t('CANCEL')}
                    </Button>
                    <Button
                        color="secondary"
                        size="large"
                        type="submit"
                        variant="contained"
                        className={classes.saveButton}
                        onClick={() => {
                            onDelete();
                            handleClose();
                        }}
                    >
                        {t('DELETE')}
                    </Button>
                </div>
            </div>
        </div>
    );
}
