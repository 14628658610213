import React, { useRef } from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    Typography,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
} from '@material-ui/core';
// @ts-ignore
import S3FileUpload from 'react-s3';
import PersonIcon from '@material-ui/icons/Person';
import SimpleModal from '../../../components/modal';
import Skeleton from '@material-ui/lab/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModel } from '../../../model';
import EditSkill from './EditSkill';
import { deleteOperator, editOperatorImage } from '../action';
import Utils from '../../../utils';
import DeleteModal from '../../../components/modal/deleteModal';
import LocalImages from '../../../utils/images';
import utils from '../../../utils';
import { useTranslation } from 'react-i18next';
import MultimediaButton from '../../../components/buttons/multimediaButton';
import EditOperator from './editOperator';
import TimeStudy from './timeStudy';
import moment from 'moment';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {},
        heading: {
            font: 'normal normal normal 20px/24px Roboto',
            letterSpacing: '0.07px',
            color: '#515151',
            opacity: 1,
            marginBottom: '18px',
        },
        liteText: {
            font: 'normal normal normal 12px/21px Roboto',
            letterSpacing: '0.07px',
            opacity: 1,
            color: '#8C8C8C',
            marginTop: '10px',
        },
        strngText: {
            font: ' normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            opacity: 1,
            wordBreak: 'break-all',
        },
        infoContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            marginBottom: '42px',
            '&>div': {
                display: 'flex',
                '&>div': {
                    marginRight: '40px',
                },
            },
        },
        infoInnerContainer: {
            maxWidth: '23%',
        },
        categories: {
            padding: '7px',
            letterSpacing: '0.08px',
            textTransform: 'uppercase',
            opacity: 1,
            background: 'var(--background-grey) 0% 0% no-repeat padding-box',
            '& th': {
                font: 'normal normal bold 14px/21px Roboto;',
                fontWeight: 'bold',
                padding: '5px 14px',
                height: '30px',
                color: '#515151',
                verticalAlign: 'top',
                whiteSpace: 'nowrap',
            },
            '&>th:nth-child(1)': {
                width: '5%',
            },
            '&>th:nth-child(2)': {
                width: '15%',
            },
            '&>th:nth-child(3)': {
                width: '20%',
            },
            '&>th:nth-child(4)': {
                width: '20%',
            },
            '&>th:nth-child(5)': {
                width: '20%',
            },
            '&>th:nth-child(6)': {
                width: '20%',
            },
        },
        categoriesItems: {
            borderLeft: 'solid 6px rgb(0,0,0,0)',
            '& td': {
                font: 'normal normal normal 14px/14px Roboto',
                padding: '10px 14px',
                textTransform: 'uppercase',
                letterSpacing: '0px',
                color: '#515151',
                opacity: 1,
                verticalAlign: 'top',
            },
        },
        headStyl: {
            font: 'normal normal normal 20px/24px Roboto',
            letterSpacing: '0.07px',
            color: 'var(--white)',
            textTransform: 'uppercase',
            opacity: '1',
            width: '100%',
            backgroundColor: 'var(--blue)',
            marginTop: '24px',
        },
        skelAnim: {
            height: '50px',
            marginBottom: '5px',
            marginTop: '-13.3px',
        },
        noRecords: {
            marginTop: '25px',
            marginBottom: '14px',
            textAlign: 'center',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#707070',
            opacity: 1,
        },
        addOperation: {
            textAlign: 'center',
            font: 'normal normal medium 16px/21px Roboto',
            letterSpacing: '0.09px',
            color: '#1689FA',
            opacity: 1,
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            width: 'fit-content',
            margin: 'auto',
        },
        addButton: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            opacity: 1,
            margin: '0 15px',
            border: '1px solid #1E88E5',
            font: 'normal normal normal 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#1E88E5',
            textTransform: 'uppercase',
            height: '36px',
            width: '72px',
        },
        operatorImageContainer: {
            width: '78px',
            height: '78px',
            background: '#EAEAEA 0% 0% no-repeat padding-box',
            border: '1px solid #D0D0D0',
            borderRadius: '39px',
            opacity: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
        },
        skillsTopContainer: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        defaultImage: {
            color: '#FFFFFF',
            display: 'flex',
            width: '2.8em',
            height: '2.8em',
        },
        cameraIcon: {
            position: 'absolute',
            bottom: 0,
            right: 0,
            cursor: 'pointer',
        },
        operatorImage: {
            width: '78px',
            height: '78px',
            borderRadius: '39px',
            opacity: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
        },
        status: {
            position: 'absolute',
            top: '92px',
            color: 'white',
            padding: '3px 4px',
            textTransform: 'uppercase',
            fontSize: '11px',
            borderRadius: '2px',
        },
        active: {
            background: 'var(--bar-green)',
        },
        inactive: {
            background: '#A4A4A4',
        },
        tableContainer: {
            margin: '15px 0',
        },
    }),
);

export default function Skills() {
    const classes = styles();
    const { t } = useTranslation();

    // for edit skill
    const [openEdit, setOpenEdit] = React.useState(false);
    const [openEditOperator, setOpenEditOperator] = React.useState(false);
    const [openTimeStudy, setOpenTimeStudy] = React.useState(false);

    const handleOpenEdit = () => {
        setOpenEdit(true);
    };
    const handleCloseEdit = () => {
        setOpenEdit(false);
    };
    const handleOpenEditOperator = () => {
        setOpenEditOperator(true);
    };

    const handleCloseEditOperator = () => {
        setOpenEditOperator(false);
    };
    const handleOpenTimeStudy = () => {
        setOpenTimeStudy(true);
    };

    const handleCloseTimeStudy = () => {
        setOpenTimeStudy(false);
    };

    // for delete operator
    const [openOperatorDelete, setOpenOperatorDelete] = React.useState(false);
    const handleOpenOperatorDelete = () => {
        setOpenOperatorDelete(true);
    };
    const handleCloseOperatorDelete = () => {
        setOpenOperatorDelete(false);
    };

    const dispatch = useDispatch();

    const { skillData, operatorId, isOperatorChange } = useSelector((state: ReducersModel) => state.skillReducer);

    const {
        selectedLine,
        selectedOperatorName,
        selectedEmployeeId,
        selectedisActive,
        operatorImage,
        selectedCardId,
        description,
        activeLine,
        selectedShift,
        selectedOperatorId,
    } = useSelector((state: ReducersModel) => state.operatorReducer);

    const skillsTable = () => {
        return (
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow className={classes.categories}>
                            <TableCell>{'#'}</TableCell>
                            <TableCell>{t('SkillType')}</TableCell>
                            <TableCell>{t('MCCode')}</TableCell>
                            <TableCell>{t('AverageEff')}</TableCell>
                            <TableCell>{t('LastEff')}</TableCell>
                            <TableCell>{t('DATEMODIFIED')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {skillData.map((row: any, i: number) => (
                            <TableRow className={classes.categoriesItems} key={'1'}>
                                <TableCell>{i + 1}</TableCell>
                                <TableCell>
                                    {row.skillTypeCode ? row.skillTypeCode : '-'}
                                </TableCell>
                                <TableCell>{row.subType ? row.subType : ''}</TableCell>
                                <TableCell>
                                    {row.avgEff 
                                        ? `${Utils.CommonFunctions.maxDecimalValue(row.avgEff, 2)}%`
                                        : '-'}
                                </TableCell>
                                <TableCell>{`${Utils.CommonFunctions.maxDecimalValue(row.lastEff, 2)}%`}</TableCell>
                                <TableCell>
                                    {row.lastModified !== null ? moment(row.lastModified).format('YYYY-MM-DD') : '-'}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const uploadButtonRef = useRef<any>(null);

    const handleUploadImageToS3 = (e: any) => {
        const pathURL = Utils.endPoints.globalAdminImageUpload;
        const image =  e.target.files[0];
        const data = new FormData();
        data.append('image', image);
        
        Utils.api.postApiGlobalCall(pathURL, data, ({ data }: any) => {
            const imageUrl = data.data;
            dispatch(editOperatorImage(imageUrl, operatorId));
            }, () => {
                dispatch({ type:  Utils.ActionName.IS_LOADING, payload: false});
            },
        );
    };

    return (
        <div className={classes.mainContainer}>
            <div className={classes.skillsTopContainer}>
                <div className={classes.infoContainer}>
                    <Typography className={classes.heading}>{t('OperatorProfile')}</Typography>
                    <MultimediaButton
                        text={t('Edit')}
                        onClick={handleOpenEditOperator}
                        icon={LocalImages.EDIT_PENCIL}
                    />

                    <div>
                        <div className={classes.infoInnerContainer}>
                            <div>
                                <Typography className={classes.liteText}>{t('OperatorName')}</Typography>
                                <Typography className={classes.strngText}>
                                    {selectedOperatorName !== '' ? selectedOperatorName : '-'}
                                </Typography>
                            </div>
                            <div>
                                <Typography className={classes.liteText}>{t('Description')}</Typography>
                                <Typography className={classes.strngText}>
                                    {description !== null ? description : '-'}
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.infoInnerContainer}>
                            <div>
                                <Typography className={classes.liteText}>{t('EmployeeID')}</Typography>
                                <Typography className={classes.strngText}>
                                    {selectedEmployeeId !== '' ? selectedEmployeeId : '-'}
                                </Typography>
                            </div>
                            <div>
                                <Typography className={classes.liteText}>{t('CardID')}</Typography>
                                <Typography className={classes.strngText}>
                                    {selectedCardId !== '' ? selectedCardId : '-'}
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.infoInnerContainer}>
                            <div>
                                <Typography className={classes.liteText}>{t('DefaultLine')}</Typography>
                                <Typography className={classes.strngText}>
                                    {selectedLine !== '' ? selectedLine : '-'}
                                </Typography>
                            </div>
                            <div>
                                <Typography className={classes.liteText}>{t('Shift')}</Typography>
                                <Typography className={classes.strngText}>{selectedShift}</Typography>
                            </div>
                        </div>
                        <div className={classes.infoInnerContainer}>
                            <div>
                                <Typography className={classes.liteText}>{t('CurrentLine')}</Typography>
                                <Typography className={classes.strngText}>
                                    {activeLine !== '' ? activeLine : '-'}
                                </Typography>
                            </div>
                            <div>
                                <Typography className={classes.liteText}>{t('CurrentStatus')}</Typography>
                                <Typography className={classes.strngText}>
                                    {selectedLine !== ''
                                        ? selectedisActive === true
                                            ? t('Available')
                                            : t('Unavailable')
                                        : '-'}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.operatorImageContainer}>
                    <div>
                        {operatorImage !== '' && operatorImage !== null ? (
                            <img alt={t('OPERATOR')} className={classes.operatorImage} src={operatorImage} />
                        ) : (
                            <PersonIcon className={classes.defaultImage} />
                        )}
                        <input
                            type="file"
                            id="styleImages"
                            onChange={handleUploadImageToS3}
                            style={{ display: 'none' }}
                            ref={uploadButtonRef}
                            accept=".png, .jpg, .jpeg"
                        />
                        <img
                            className={classes.cameraIcon}
                            src={LocalImages.CAMERA_SMALL}
                            alt={'camera'}
                            onClick={() => {
                                uploadButtonRef.current.click();
                            }}
                        />
                    </div>
                    {selectedisActive ? (
                        <div className={`${classes.status} ${classes.active}`}>{t('Active')}</div>
                    ) : (
                        <div className={`${classes.status} ${classes.inactive}`}>{t('Inactive')}</div>
                    )}
                </div>
            </div>
            <div>
                <Typography className={classes.heading}>{t('SkillMatrix')}</Typography>
                <MultimediaButton text={t('TimeStudy')} onClick={handleOpenTimeStudy} icon={LocalImages.CLOCK} />
                {isOperatorChange ? (
                    <>
                        <Skeleton className={classes.skelAnim} animation="wave" />
                    </>
                ) : (
                    skillsTable()
                )}
            </div>
            <SimpleModal
                handleOpen={handleOpenEdit}
                isOpen={openEdit}
                body={<EditSkill handleClose={handleCloseEdit} />}
            />
            <SimpleModal
                handleOpen={handleOpenEditOperator}
                isOpen={openEditOperator}
                body={
                    <EditOperator handleClose={handleCloseEditOperator} handleOpenDelete={handleOpenOperatorDelete} />
                }
            />
            <SimpleModal
                handleOpen={handleOpenTimeStudy}
                isOpen={openTimeStudy}
                body={<TimeStudy handleClose={handleCloseTimeStudy} />}
            />
            <SimpleModal
                handleOpen={handleOpenOperatorDelete}
                isOpen={openOperatorDelete}
                body={
                    <DeleteModal
                        title={`${t('Delete')} ${t('OPERATOR')}?`}
                        onDelete={() => {
                            dispatch(deleteOperator(selectedOperatorId, handleCloseEdit));
                            handleCloseEditOperator();
                        }}
                        handleClose={handleCloseOperatorDelete}
                    />
                }
            />
        </div>
    );
}
