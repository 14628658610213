import React from 'react';
import { makeStyles, createStyles, Theme, Typography, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';
import Schema from '../../../schema';
import { useTranslation } from 'react-i18next';
import NormalInput from '../../../components/inputs/normalInput';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReducersModel } from '../../../model';
import { addOperator } from '../action';
import AutoComplete from '../../../components/inputs/autoComplete';
const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            '& .MuiTextField-root': {
                marginBottom: '10px!important',
            },
        },
        textStyl: {
            fontSize: '20px',
            color: 'var(--dark-grey)',
            margin: '44px 0 0 0',
        },
        paper: {
            position: 'absolute',
            width: '25%',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        saveContainer: {
            backgroundColor: 'white',
            padding: '10px 0',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '25px',
        },
        cancelButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#515151',
            opacity: 1,
            height: '36px',
        },
        saveButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#FFFFFF',
            opacity: 1,
            height: '36px',
        },
    }),
);

export default function AddOperator({ handleClose }: any) {
    const classes = styles();
    const { t } = useTranslation();
    const { mfgLines } = useSelector((state: ReducersModel) => state.mfgLinesReducer);
    const { shifts } = useSelector((state: ReducersModel) => state.shiftsReducer);
    const dispatch = useDispatch();
    const history = useHistory();

    function getModalStyle() {
        const top = 50;
        const left = 50;
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const [modalStyle] = React.useState(getModalStyle);

    return (
        <div style={modalStyle} className={classes.paper}>
            <div className={classes.mainContainer}>
                <Typography className={classes.textStyl}>{t('AddOperator')}</Typography>
                <div>
                    <Formik
                        initialValues={{
                            lineId: '',
                            line: '',
                            shiftId: '',
                            shift: '',
                            operatorName: '',
                            cardId: '',
                            isActive: true,
                            employeeId: '',
                            description: '',
                        }}
                        validationSchema={Schema.AddOperatorSchema(t)}
                        onSubmit={(values, { setSubmitting }) => {
                            dispatch(addOperator(values, setSubmitting, history, handleClose));
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                        }) => (
                            <Form>
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.operatorName}
                                    onChange={handleChange}
                                    helperText={touched.operatorName && errors.operatorName}
                                    error={Boolean(touched.operatorName && errors.operatorName)}
                                    name={'operatorName'}
                                    label={t('OperatorName')}
                                    isRequired={true}
                                />
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.description}
                                    onChange={handleChange}
                                    helperText={touched.description && errors.description}
                                    error={Boolean(touched.description && errors.description)}
                                    name={'description'}
                                    label={t('DESCRIPTION')}
                                />
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.employeeId}
                                    onChange={handleChange}
                                    helperText={touched.employeeId && errors.employeeId}
                                    error={Boolean(touched.employeeId && errors.employeeId)}
                                    name={'employeeId'}
                                    label={t('EmployeeID')}
                                    isRequired={true}
                                />
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.cardId}
                                    onChange={handleChange}
                                    helperText={touched.cardId && errors.cardId}
                                    error={Boolean(touched.cardId && errors.cardId)}
                                    name={'cardId'}
                                    label={t('CardID')}
                                    isRequired={true}
                                />
                                <AutoComplete
                                    onBlur={handleBlur}
                                    values={values.lineId}
                                    onInputChange={(event: any) => {
                                        if (event !== null) {
                                            // Do Nothing
                                        }
                                    }}
                                    onChange={(event: any, value: any) => {
                                        if (value !== null) {
                                            const index = mfgLines.findIndex((x: any) => x.id === value.id);
                                            handleChange(event);
                                            if (index >= 0) {
                                                setFieldValue('lineId', value.id);
                                                if (value.mfgLine) {
                                                    setFieldValue('line', value.mfgLine);
                                                }
                                            }
                                        }
                                    }}
                                    helperText={touched.lineId && errors.lineId}
                                    error={Boolean(touched.lineId && errors.lineId)}
                                    name={'lineId'}
                                    label={t('Line')}
                                    touched={touched.lineId}
                                    options={mfgLines}
                                    getOptionLabel={(option: any) => option.mfgLine}
                                    isRequired={true}
                                    getOptionSelected={(option: any, value: any) => {
                                        return `${option.id}` === `${value.id}`;
                                    }}
                                />
                                <AutoComplete
                                    onBlur={handleBlur}
                                    values={values.shiftId}
                                    onInputChange={(event: any) => {
                                        if (event !== null) {
                                            // Do Nothing
                                        }
                                    }}
                                    onChange={(event: any, value: any) => {
                                        if (value !== null) {
                                            const index = shifts.findIndex((x: any) => x.id === value.id);
                                            handleChange(event);
                                            if (index >= 0) {
                                                setFieldValue('shiftId', value.id);
                                                if (value.shift) {
                                                    setFieldValue('shift', value.shift);
                                                }
                                            }
                                        }
                                    }}
                                    helperText={touched.shiftId && errors.shiftId}
                                    error={Boolean(touched.shiftId && errors.shiftId)}
                                    name={'shiftId'}
                                    label={t('Shift')}
                                    touched={touched.shiftId}
                                    options={shifts}
                                    getOptionLabel={(option: any) => option.shift}
                                    isRequired={true}
                                    getOptionSelected={(option: any, value: any) => {
                                        return `${option.id}` === `${value.id}`;
                                    }}
                                />

                                <div className={classes.saveContainer}>
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        className={classes.cancelButton}
                                        onClick={handleClose}
                                    >
                                        {t('CANCEL')}
                                    </Button>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className={classes.saveButton}
                                    >
                                        {isSubmitting ? `${t('Saving')}...` : t('Save')}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}
