import React, { useState, useEffect } from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SimpleModal from '../../../components/modal';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModel } from '../../../model';
import Utils from '../../../utils';
import DeleteModal from '../../../components/modal/deleteModal';
import moment from 'moment';
import AddNPTType from './addNptType';
import EditNPTType from './editNptType';
import { deleteNPTCat, deleteNPTType } from './action';
import EditNPTCategory from './editNptCategory';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            '& svg': {
                cursor: 'pointer',
                marginLeft: '15px',
            },
        },
        heading: {
            font: 'normal normal normal 20px/24px Roboto',
            letterSpacing: '0.07px',
            color: '#515151',
            opacity: 1,
            marginBottom: '18px',
        },
        liteText: {
            font: 'normal normal normal 12px/21px Roboto',
            letterSpacing: '0.07px',
            opacity: 1,
            color: '#8C8C8C',
        },
        strngText: {
            font: ' normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            opacity: 1,
        },
        infoContainer: {
            display: 'flex',
            marginBottom: '42px',
            '& div': {
                marginRight: '80px',
            },
        },
        categories: {
            padding: '7px',
            letterSpacing: '0.08px',
            textTransform: 'uppercase',
            opacity: 1,
            background: 'var(--background-grey) 0% 0% no-repeat padding-box',
            '& th': {
                font: 'normal normal bold 14px/21px Roboto;',
                fontWeight: 'bold',
                padding: '5px 14px',
                height: '30px',
                color: '#515151',
                verticalAlign: 'top',
            },
            '&>th:nth-child(1)': {
                width: '20%',
            },
            '&>th:nth-child(2)': {
                width: '10%',
            },
            '&>th:nth-child(3)': {
                width: '15%',
            },
            '&>th:nth-child(4)': {
                width: '10%',
            },
            '&>th:nth-child(5)': {
                width: '15%',
            },
            '&>th:nth-child(6)': {
                width: '30%',
            },
        },
        categoriesItems: {
            borderLeft: 'solid 6px rgb(0,0,0,0)',
            '&:hover': {
                borderLeft: 'solid 6px var(--blue)',

                '&>td:nth-child(4)': {
                    '& svg': {
                        color: 'var(--blue)',
                        transition: '1s all',
                    },
                },
            },
            '&>td:nth-child(5)': {
                '& svg': {
                    cursor: 'pointer',
                    color: 'transparent',
                    width: '30px',
                },
            },
            '& td': {
                font: 'normal normal normal 14px/14px Roboto',
                padding: '6px 14px',
                textTransform: 'uppercase',
                letterSpacing: '0px',
                color: '#515151',
                opacity: 1,
                wordBreak: 'break-word',
                verticalAlign: 'top',
            },
        },

        headStyl: {
            font: 'normal normal normal 20px/24px Roboto',
            letterSpacing: '0.07px',
            color: 'var(--white)',
            textTransform: 'uppercase',
            opacity: '1',
            width: '100%',
            backgroundColor: 'var(--blue)',
            marginTop: '24px',
        },
        skelAnim: {
            height: '50px',
            marginBottom: '5px',
            marginTop: '-13.3px',
        },
        noRecords: {
            marginTop: '25px',
            marginBottom: '14px',
            textAlign: 'center',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#707070',
            opacity: 1,
        },
        addOperation: {
            textAlign: 'center',
            font: 'normal normal medium 16px/21px Roboto',
            letterSpacing: '0.09px',
            color: '#1689FA',
            opacity: 1,
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            width: 'fit-content',
            margin: 'auto',
        },
        boldItems: {
            '& td': {
                fontWeight: 500,
            },
        },
    }),
);

export default function NPTTypesListing({ categoryId }: any) {
    const classes = styles();
    const history = useHistory();
    const { t } = useTranslation();

    // for add skill
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // for edit npt type
    const [openEdit, setOpenEdit] = React.useState(false);
    const handleOpenEdit = () => {
        setOpenEdit(true);
    };
    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    // for edit npt category
    const [openCatEdit, setOpenCatEdit] = React.useState(false);
    const handleOpenCatEdit = () => {
        setOpenCatEdit(true);
    };
    const handleCloseCatEdit = () => {
        setOpenCatEdit(false);
    };

    // for delete npt type
    const [openDelete, setOpenDelete] = React.useState(false);
    const handleOpenDelete = () => {
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    // for delete npt category
    const [openNptCatDelete, setOpenNptCatDelete] = React.useState(false);
    const handleOpenNptCatDelete = () => {
        setOpenNptCatDelete(true);
    };
    const handleCloseNptCatDelete = () => {
        setOpenNptCatDelete(false);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch({
                type: Utils.ActionName.GET_NPT_TYPES_LIST,
                payload: {
                    nptTypeData: [],
                    page: 1,
                    createdDt: '',
                    desc1: '',
                    updatedDt: '',
                    nptCat: '',
                    isActive: false,
                },
            });
        };
    }, [dispatch]);

    const { nptTypeData, desc1, updatedDt, nptCat, isActive } = useSelector(
        (state: ReducersModel) => state.nptTypeListReducer,
    );
    const [nptIdToDelete, setNptIdToDelete] = useState('');

    const nptListingTable = () => {
        return (
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow className={classes.categories}>
                            <TableCell>{t('DisplayName')}</TableCell>
                            <TableCell>{t('Type')}</TableCell>
                            <TableCell>{t('DESCRIPTION')}</TableCell>
                            <TableCell>{t('Status')}</TableCell>
                            <TableCell>{t('DATEMODIFIED')}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow className={`${classes.categoriesItems} ${classes.boldItems}`} key={'1'}>
                            <TableCell>{nptCat !== '' ? nptCat : '-'}</TableCell>
                            <TableCell>{nptCat !== '' ? 'NPT Category' : '-'}</TableCell>
                            <TableCell>{desc1 !== '' ? desc1 : '-'}</TableCell>
                            <TableCell>
                                {updatedDt !== null ? (isActive && isActive ? 'Active' : 'Inactive') : '-'}
                            </TableCell>
                            <TableCell>{updatedDt !== null ? moment(updatedDt).format('YYYY-MM-DD') : '-'}</TableCell>

                            <TableCell align="right">
                                <AddIcon color={'primary'} onClick={handleOpen} />{' '}
                                <EditIcon
                                    color={'primary'}
                                    onClick={() => {
                                        handleOpenCatEdit();
                                    }}
                                />{' '}
                                <DeleteIcon
                                    color={'primary'}
                                    onClick={() => {
                                        handleOpenNptCatDelete();
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                        {nptTypeData.map((row: any) => (
                            <TableRow className={classes.categoriesItems} key={'1'}>
                                <TableCell>{row.nptType ? row.nptType : '-'}</TableCell>
                                <TableCell>{t('NPTType')}</TableCell>
                                <TableCell>{row.desc1 ? row.desc1 : '-'}</TableCell>
                                <TableCell>{row.isActive && row.isActive ? t('Active') : t('Inactive')}</TableCell>
                                <TableCell>
                                    {row.updatedDt !== null ? moment(row.updatedDt).format('YYYY-MM-DD') : '-'}
                                </TableCell>

                                <TableCell align="right">
                                    <EditIcon
                                        color={'primary'}
                                        onClick={() => {
                                            handleOpenEdit();
                                            dispatch({
                                                type: Utils.ActionName.GET_NPT_TYPES_LIST,
                                                payload: {
                                                    selectedNptId: row.id,
                                                    selectedDisplayName: row.nptType,
                                                    selectedDescription: row.desc1,
                                                    selectedIsActive: row.isActive,
                                                    selectedUpdatedDt: row.updatedDt,
                                                    selectedUpdatedBy: row.updatedBy,
                                                    selectedCreatedBy: row.createdBy,
                                                    selectedCreatedDt: row.createdDt,
                                                },
                                            });
                                        }}
                                    />{' '}
                                    <DeleteIcon
                                        color={'primary'}
                                        onClick={() => {
                                            handleOpenDelete();
                                            setNptIdToDelete(row.id);
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };
    return (
        <div className={classes.mainContainer}>
            {nptListingTable()}

            <SimpleModal
                handleOpen={handleOpen}
                isOpen={open}
                body={<AddNPTType categoryId={categoryId} handleClose={handleClose} />}
            />
            <SimpleModal
                handleOpen={handleOpenCatEdit}
                isOpen={openCatEdit}
                body={<EditNPTCategory categoryId={categoryId} handleClose={handleCloseCatEdit} />}
            />
            <SimpleModal
                handleOpen={handleOpenEdit}
                isOpen={openEdit}
                body={<EditNPTType categoryId={categoryId} handleClose={handleCloseEdit} />}
            />
            <SimpleModal
                handleOpen={handleOpenDelete}
                isOpen={openDelete}
                body={
                    <DeleteModal
                        title={t('DeleteNPTtype')}
                        onDelete={() => {
                            dispatch(deleteNPTType(nptIdToDelete, categoryId, handleCloseDelete));
                        }}
                        handleClose={handleCloseDelete}
                    />
                }
            />
            <SimpleModal
                handleOpen={handleOpenNptCatDelete}
                isOpen={openNptCatDelete}
                body={
                    <DeleteModal
                        title={t('DeleteNPTcategory')}
                        onDelete={() => {
                            dispatch(deleteNPTCat(categoryId, handleCloseNptCatDelete, history));
                        }}
                        handleClose={handleCloseNptCatDelete}
                    />
                }
            />
        </div>
    );
}
