import React from 'react';
import { VictoryLabel } from 'victory';
import { LBHistoryLablesProp } from '../Interfaces';

const LBHistoryLables: React.FC<LBHistoryLablesProp> = ({
  LeftLabel = 'LBR / EFF',
  RightLabel = 'MPWR'
}) => {
  const style = {
    color: '#595959',
    fontWeight: 'bold',
    fontSize: 14
  };

  return (
    <React.Fragment>
      <VictoryLabel style={style} angle={-90} x={45} y={155} text={LeftLabel} />
      <VictoryLabel style={style} angle={-90} x={1355} y={155} text={RightLabel} />
    </React.Fragment>
  );
};

export default LBHistoryLables;
