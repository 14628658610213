import React from 'react';
import { makeStyles, createStyles, Theme, Divider, FormControlLabel, Checkbox } from '@material-ui/core';
import Utils from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { getNptCategories } from '../../screens/admin/manageNpt/action';
import { ReducersModel } from '../../model';
import { useTranslation } from 'react-i18next';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            minWidth: '210px',
        },
        title: {
            padding: '0 16px 8px 16px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
        subTitle: {
            padding: '8px 16px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
        filtersBox: {
            padding: '8px 16px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
            display: 'flex',
            flexDirection: 'column',
            '& .MuiTypography-root': {
                textAlign: 'left',
                font: 'normal normal normal 12px/21px Roboto',
                letterSpacing: '0.07px',
                color: '#868686',
                opacity: 1,
            },
            '& .MuiCheckbox-root': {
                padding: '2px 7px',
            },
            '& .MuiFormGroup-root': { flexDirection: 'row!important' },
        },
        statusContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        status: {
            marginRight: '40px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
        naturalCheck: {
            "& .MuiTypography-root": {
                color: "#515151"
            }
        }
    }),
);

export default function ManageNPTFilter() {
    const classes = styles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { filterStatus } = useSelector((state: ReducersModel) => state.nptCategoryListReducer);

    return (
        <div className={classes.mainContainer}>
            <div className={classes.title}>{t("Filterby")}</div>
            <Divider />

            <div>
                <div className={classes.subTitle}>{t("Status")}</div>
                <div className={classes.filtersBox}>
                    <FormControlLabel
                        value={'true'}
                        className={classes.naturalCheck}
                        control={
                            <Checkbox
                                color={'primary'}
                                checked={filterStatus.includes("true")}
                                name="shift"
                                onChange={(event: any) => {
                                    const target = event.target;
                                    if (target.checked) {
                                        if (!filterStatus.includes(target.value)) {
                                            let newArray = [...filterStatus];

                                            newArray.push(target.value);
                                            dispatch({
                                                type: Utils.ActionName.GET_NPT_CATEGORIES_LIST,
                                                payload: {
                                                    filterStatus: newArray,
                                                },
                                            });
                                            dispatch(getNptCategories());
                                        }
                                    } else {
                                        if (filterStatus.includes(target.value)) {
                                            const index = filterStatus.indexOf(target.value);
                                            let newArray = [...filterStatus];
                                            if (index > -1) {
                                                newArray.splice(index, 1);
                                            }
                                            dispatch({
                                                type: Utils.ActionName.GET_NPT_CATEGORIES_LIST,
                                                payload: {
                                                    filterStatus: newArray,
                                                },
                                            });
                                            dispatch(getNptCategories());
                                        }
                                    }
                                }}
                            />
                        }
                        label={t("Active")}
                    />
                    <FormControlLabel
                        value={'false'}
                        className={classes.naturalCheck}
                        control={
                            <Checkbox
                                color={'primary'}
                                name="shift"
                                checked={filterStatus.includes("false")}
                                onChange={(event: any) => {
                                    const target = event.target;
                                    if (target.checked) {
                                        if (!filterStatus.includes(target.value)) {
                                            let newArray = [...filterStatus];

                                            newArray.push(target.value);
                                            dispatch({
                                                type: Utils.ActionName.GET_NPT_CATEGORIES_LIST,
                                                payload: {
                                                    filterStatus: newArray,
                                                },
                                            });
                                            dispatch(getNptCategories());
                                        }
                                    } else {
                                        if (filterStatus.includes(target.value)) {
                                            const index = filterStatus.indexOf(target.value);
                                            let newArray = [...filterStatus];
                                            if (index > -1) {
                                                newArray.splice(index, 1);
                                            }
                                            dispatch({
                                                type: Utils.ActionName.GET_NPT_CATEGORIES_LIST,
                                                payload: {
                                                    filterStatus: newArray,
                                                },
                                            });
                                            dispatch(getNptCategories());
                                        }
                                    }
                                }}
                            />
                        }
                        label={t("Inactive")}
                    />
                </div>
            </div>
        </div>
    );
}
