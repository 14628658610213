import React, { useState } from 'react';
import { makeStyles, createStyles, Theme, Typography, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';
import Schema from '../../schema';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModel } from '../../model';
import { addOperation } from './action';
import { useHistory } from 'react-router-dom';
import AutoComplete from '../../components/inputs/autoComplete';
import SimpleModal from '../../components/modal';
import { ImageCaurosel } from '../../components/caurosel/imagecaurosel';
import Utils from '../../utils';
import { minuteToTime } from '../../utils/convertTime';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            '& .MuiTextField-root': {
                marginBottom: '10px!important',
            },
        },
        textStyl: {
            fontSize: '20px',
            color: 'var(--dark-grey)',
            margin: '19px 0 0 0',
        },
        paper: {
            position: 'absolute',
            width: '25%',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        saveContainer: {
            backgroundColor: 'white',
            padding: '10px 0',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '13px',
        },
        cancelButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#515151',
            opacity: 1,
            height: '36px',
        },
        saveButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#FFFFFF',
            opacity: 1,
            height: '36px',
        },
        populateField: {
            marginBottom: '12px',
            '& div': {
                wordBreak: 'break-word',
            },
            '& div:nth-child(1)': {
                font: 'normal normal normal 9px/21px Roboto',
                opacity: 1,
                color: 'rgba(0, 0, 0, 0.54)',
                letterSpacing: '0.07px',
            },
            '& div:nth-child(2)': {
                font: 'normal normal normal 14px/14px Roboto',
                opacity: 1,
                letterSpacing: '0.07px',
                color: 'rgba(0, 0, 0, 0.87)',
                marginTop: '2px',
            },
        },
        populateFieldAttachment: {
            marginBottom: '12px',
            '& div': {
                font: 'normal normal normal 9px/21px Roboto',
                opacity: 1,
                color: 'rgba(0, 0, 0, 0.54)',
                letterSpacing: '0.07px',
            },
        },
        imgContainer: {
            display: 'flex',
        },
        innerImgContainer: {
            marginRight: '20px',
            '& .MuiTypography-body1': {
                font: 'normal normal normal 11px/21px Roboto',
                opacity: 1,
            },
        },
        attachmentImage: {
            height: '64px',
            width: '64px',
            border: '1px solid black',
        },
    }),
);

export default function FirstAddOperation({ handleClose }: any) {
    const classes = styles();
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const history = useHistory();
    const [openImage, setOpenImage] = useState(false);
    const [imgToOpen, setImgToOpen] = useState(0);
    const { needlesTypes } = useSelector((state: ReducersModel) => state.needleListReducer);
    const { operationCodeData } = useSelector((state: ReducersModel) => state.operationCodeReducer);
    const { selectedOperationStep } = useSelector((state: ReducersModel) => state.sewingOperationForAddStyleReducer);
    const { machines } = useSelector((state: ReducersModel) => state.machineListReducer);

    const handleImageOpen = (idx: number) => {
        setImgToOpen(idx);
        setOpenImage(true);
    };

    const handleImageClose = () => {
        setOpenImage(false);
    };

    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const [modalStyle] = React.useState(getModalStyle);

    return (
        <div style={modalStyle} className={classes.paper}>
            <div className={classes.mainContainer}>
                <Typography className={classes.textStyl}>{t('AddOperation')}</Typography>
                <div>
                    <Formik
                        initialValues={{
                            operationcode: '',
                            operationCodeId: '',
                            description: '',
                            machine: '',
                            needleType: '',
                            smv: '',
                            tct: 0,
                            machineName: '',
                            needleTypeName: '',
                            skillTypeName: '',
                            skillTypeCode: '',
                            skillTypeId: '',
                            images: [],
                            imageCodes: [],
                            imageIds: [],
                            selectedOperationStep,
                        }}
                        validationSchema={Schema.AddOperationSchema(t)}
                        onSubmit={(values, { setSubmitting }) => {
                            const styleId = new URL(window.location.href).searchParams.get('id');

                            if (styleId) {
                                dispatch(addOperation(setSubmitting, history, values, styleId, handleClose));
                            } else {
                                dispatch(
                                    addOperation(
                                        setSubmitting,
                                        history,
                                        values,
                                        localStorage.getItem('styleAddedId'),
                                        handleClose,
                                    ),
                                );
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                        }) => (
                            <Form>
                                <AutoComplete
                                    onBlur={handleBlur}
                                    values={values.operationCodeId}
                                    onInputChange={(event: any) => {
                                        if (event !== null) {
                                            // Do Nothing
                                        }
                                    }}
                                    onChange={(event: any, value: any) => {
                                        if (value !== null) {
                                            const index = operationCodeData.findIndex((x: any) => x.id === value.id);
                                            const operation = operationCodeData.find((x: any) => x.id === value.id);
                                            handleChange(event);
                                            if (index >= 0) {
                                                setFieldValue('operationCodeId', value.id);
                                                setFieldValue('operationcode', operationCodeData[index].code);
                                                setFieldValue('smv', operationCodeData[index].smv);
                                                setFieldValue('tct', operationCodeData[index].tct);
                                                setFieldValue('description', operationCodeData[index].desc1);
                                                setFieldValue('skillTypeName', operation.skillTypeName);
                                                setFieldValue('skillTypeCode', operation?.skillTypeData?.skillTypeCode);
                                                setFieldValue('skillTypeId', operation.skillTypeId);
                                                setFieldValue('images', [
                                                    operation.machineAttachmentImage1,
                                                    operation.machineAttachmentImage2,
                                                    operation.machineAttachmentImage3,
                                                ]);
                                                setFieldValue('imageCodes', [
                                                    operation?.machineAttachment1Data?.displayName,
                                                    operation?.machineAttachment2Data?.displayName,
                                                    operation?.machineAttachment3Data?.displayName,
                                                ]);
                                                setFieldValue('imageIds', [
                                                    operation.machineAttachmentId1,
                                                    operation.machineAttachmentId2,
                                                    operation.machineAttachmentId3,
                                                ]);
                                            }
                                        }
                                    }}
                                    helperText={touched.operationCodeId && errors.operationCodeId}
                                    error={Boolean(touched.operationCodeId && errors.operationCodeId)}
                                    name={'operation'}
                                    label={t('OperationCode')}
                                    touched={touched.operationCodeId}
                                    options={operationCodeData}
                                    getOptionLabel={(option: any) => option.code}
                                    isRequired={true}
                                />

                                <div className={classes.populateField}>
                                    <div>{t('DESCRIPTION')}</div>
                                    <div>{values.description === '' ? '-' : values.description}</div>
                                </div>

                                <div className={classes.populateField}>
                                    <div>{t('SMV')}</div>
                                    <div>{values.smv === '' ? '-' : values.smv}</div>
                                </div>
                                <div className={classes.populateField}>
                                    <div>{t('Target Cycle Time (mm:ss.ms)')}</div>
                                    <div>{values.tct === 0 ? '-' : minuteToTime(values.tct)}</div>
                                </div>
                                <div className={classes.populateField}>
                                    <div>{t('SkillTypeName')}</div>
                                    <div>{values.skillTypeName === '' ? '-' : values.skillTypeName}</div>
                                </div>
                                <div className={classes.populateField}>
                                    <div>{t('SkillTypeCode')}</div>
                                    <div>{values.skillTypeCode === '' ? '-' : values.skillTypeCode}</div>
                                </div>
                                <AutoComplete
                                    onBlur={handleBlur}
                                    values={values.machine}
                                    onInputChange={(event: any) => {
                                        if (event !== null) {
                                            // Do Nothing
                                        }
                                    }}
                                    onChange={(event: any, value: any) => {
                                        if (value !== null) {
                                            const index = machines.findIndex((x: any) => x.id === value.id);
                                            handleChange(event);
                                            if (index >= 0) {
                                                setFieldValue('machine', value.id);
                                                setFieldValue('machineName', machines[index].subType);
                                            }
                                        }
                                    }}
                                    helperText={touched.machine && errors.machine}
                                    error={Boolean(touched.machine && errors.machine)}
                                    name={'machine'}
                                    label={'M/C'}
                                    touched={touched.machine}
                                    options={machines}
                                    getOptionLabel={(option: any) => option.subType}
                                    isRequired={true}
                                />

                                <AutoComplete
                                    onBlur={handleBlur}
                                    values={values.needleType}
                                    onInputChange={(event: any) => {
                                        if (event !== null) {
                                            // Do Nothing
                                        }
                                    }}
                                    onChange={(event: any, value: any) => {
                                        if (value !== null) {
                                            const index = needlesTypes.findIndex((x: any) => x.id === value.id);
                                            handleChange(event);
                                            if (index >= 0) {
                                                setFieldValue('needleType', value.id);
                                                setFieldValue('needleTypeName', needlesTypes[index].needleType);
                                            }
                                        }
                                    }}
                                    helperText={touched.needleType && errors.needleType}
                                    error={Boolean(touched.needleType && errors.needleType)}
                                    name={'needleType'}
                                    label={t('NEEDLETYPE')}
                                    touched={touched.needleType}
                                    options={needlesTypes}
                                    getOptionLabel={(option: any) => option.needleType}
                                    isRequired={true}
                                />

                                <div className={classes.populateFieldAttachment}>
                                    <div>{t('Attachment')}</div>
                                    {values.images.length > 0 ? (
                                        <div className={classes.imgContainer}>
                                            {values.images.map((imgLink, idx) => {
                                                if (imgLink !== null) {
                                                    return (
                                                        <div
                                                            className={classes.innerImgContainer}
                                                            onClick={() => handleImageOpen(idx)}
                                                            key={idx}
                                                        >
                                                            <img
                                                                src={Utils.CommonFunctions.getImage(imgLink)}
                                                                className={classes.attachmentImage}
                                                                alt={t('Attachment')}
                                                            />
                                                            <Typography>{values.imageCodes[idx]}</Typography>
                                                        </div>
                                                    );
                                                } else {
                                                    return '';
                                                }
                                            })}
                                        </div>
                                    ) : (
                                        <div>{'-'}</div>
                                    )}
                                </div>

                                <div className={classes.saveContainer}>
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        className={classes.cancelButton}
                                        onClick={handleClose}
                                    >
                                        {t('CANCEL')}
                                    </Button>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className={classes.saveButton}
                                    >
                                        {isSubmitting ? `${t('Saving')}...` : t('Save')}
                                    </Button>
                                </div>
                                <SimpleModal
                                    isOpen={openImage}
                                    body={
                                        <ImageCaurosel
                                            handleClose={handleImageClose}
                                            items={values.images.filter((val) => {
                                                return val !== null;
                                            })}
                                            imgToOpen={imgToOpen}
                                            alt={t('Attachment')}
                                        />
                                    }
                                />
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}
