import React, { useEffect } from 'react';
import { makeStyles, createStyles, Theme, TextField, InputAdornment } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import utils from '../../utils';
import LocalImages from '../../utils/images';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        notificationContainer: {
          display: "grid",
          gridTemplateColumns: "320px auto",
          gridGap: "8px",
          margin: "8px",
          "& > div": {
            background: "#FFFFFF",
            boxShadow: "0px 0px 14px #0000000D",
            height: "calc(100vh - 135px)",
            padding: "16px",
            boxSizing: "border-box",
            font: "normal normal normal 20px/20px Roboto",
            letterSpacing: "0.07px",
            color: "#515151",
          }
        },
        notifications: {
            overflow: "auto",
            height: "calc(100vh - 231px)",
        },
        notification: {
            borderTop: "1px solid #EAEAEA",
            font: "normal normal normal 12px/21px Roboto",
            letterSpacing: "0.07px",
            color: "#000000",
            display: "flex",
            padding: "8px 0px",
            "& svg": {
                color: "#1E88E5",
                fontSize: "10px",
            },
            "& > div": {
                "&:first-child": {
                    width: "10px",
                },
                "&:last-child": {
                    paddingLeft: "8px",
                    width: "100%",
                    "& > div": {
                        "&:first-child": {
                            color: "#515151",
                            font: "normal normal bold 14px/21px Roboto",
                            letterSpacing: "0.08px"
                        },
                        "&:last-child": {
                            color: "#868686",
                        }
                    }
                }
            }
        },
        read: {
            "& svg": {
                color: "#ffffff",
            }
        },
        searchInput: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            margin: "16px 0px 8px 0px",
            width: '100%',
            '& .MuiInputBase-root': {
                height: '36px',
                font: 'normal normal normal 14px/20px Roboto',
                letterSpacing: '0.05px',
                color: '#515151',
                opacity: 1,
            },
        },
        searchIcon: {
            cursor: 'pointer',
        },
        currentNotification: {
            borderBottom: "1px solid #EAEAEA",
            paddingBottom: "60px"
        },
        notificationDetail: {
            overflow: "auto",
            height: "calc(100% - 66px)",
            "& > div": {
                "&:first-child": {
                    "& > div":{
                        margin: "14px 0px",
                        "& > div": {
                            font: "normal normal normal 12px/21px Roboto",
                            letterSpacing: "0.07px",
                            color: "#0000008A",
                        },
                        "& > span":{
                            font: "normal normal normal 14px/21px Roboto",
                            letterSpacing: "0.08px",
                            color: "#000000",
                        }
                    },
                },
                "&:last-child": {
                    marginTop: "40px",
                    marginBottom: "34px",
                    font: "normal normal normal 12px/21px Roboto",
                    letterSpacing: "0.07px",
                    color: "#8C8C8C",
                    "& > div": {
                        marginBottom: "8px",
                    }
                }
            }
        }
    }),
);

export default function Notifications() {
    const classes = styles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch({
            type: utils.ActionName.IS_LOADING,
            payload: false,
        });
    }, [dispatch]);

    const handleSearch = (event: any) => {
        // Logic for Search in Inbox
    };

    return (
        <React.Fragment>
        <Helmet>
            <title>{t("Inbox")}</title>
        </Helmet>
        <div className={classes.notificationContainer}>
           <div>
               <div>
                   <span>{t("Inbox")}</span>
                    <TextField
                        className={classes.searchInput}
                        variant="outlined"
                        placeholder={t("Search")}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img alt={t("Search")} src={LocalImages.SEARCH_ICON} className={classes.searchIcon} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
               <div className={classes.notifications}>
                    <div className={classes.notification}>
                        <div>
                            <FiberManualRecordIcon />
                        </div>
                        <div>
                            <div>{t("OperatorRequest")}</div>
                            <div>#3 CI-K0026 Water Zhang Z</div>
                            <div>Jan 23 2021 to Feb 8 2021 at YT1-18</div>
                            <div>2021-01-22 10:52</div>
                        </div>
                    </div>
                    <div className={classes.notification}>
                        <div>
                            <FiberManualRecordIcon />
                        </div>
                        <div>
                            <div>{t("OperatorRequest")}</div>
                            <div>#3 CI-K0026 Water Zhang Z</div>
                            <div>Jan 23 2021 to Feb 8 2021 at YT1-18</div>
                            <div>2021-01-22 10:52</div>
                        </div>
                    </div>
                    <div className={classes.notification}>
                        <div>
                            <FiberManualRecordIcon />
                        </div>
                        <div>
                            <div>{t("OperatorRequest")}</div>
                            <div>#3 CI-K0026 Water Zhang Z</div>
                            <div>Jan 23 2021 to Feb 8 2021 at YT1-18</div>
                            <div>2021-01-22 10:52</div>
                        </div>
                    </div>
                    <div className={classes.notification}>
                        <div className={classes.read}>
                            <FiberManualRecordIcon/>
                        </div>
                        <div>
                            <div>{t("OperatorRequest")}</div>
                            <div>#3 CI-K0026 Water Zhang Z</div>
                            <div>Jan 23 2021 to Feb 8 2021 at YT1-18</div>
                            <div>2021-01-22 10:52</div>
                        </div>
                    </div>
                    <div className={classes.notification}>
                        <div className={classes.read}>
                            <FiberManualRecordIcon />
                        </div>
                        <div>
                            <div>{t("OperatorRequest")}</div>
                            <div>#3 CI-K0026 Water Zhang Z</div>
                            <div>Jan 23 2021 to Feb 8 2021 at YT1-18</div>
                            <div>2021-01-22 10:52</div>
                        </div>
                    </div>
                    <div className={classes.notification}>
                        <div className={classes.read}>
                            <FiberManualRecordIcon />
                        </div>
                        <div>
                            <div>{t("OperatorRequest")}</div>
                            <div>#3 CI-K0026 Water Zhang Z</div>
                            <div>Jan 23 2021 to Feb 8 2021 at YT1-18</div>
                            <div>2021-01-22 10:52</div>
                        </div>
                    </div>
                    <div className={classes.notification}>
                        <div className={classes.read}>
                            <FiberManualRecordIcon />
                        </div>
                        <div>
                            <div>{t("OperatorRequest")}</div>
                            <div>#3 CI-K0026 Water Zhang Z</div>
                            <div>Jan 23 2021 to Feb 8 2021 at YT1-18</div>
                            <div>2021-01-22 10:52</div>
                        </div>
                    </div>
                    <div className={classes.notification}>
                        <div className={classes.read}>
                            <FiberManualRecordIcon />
                        </div>
                        <div>
                            <div>{t("OperatorRequest")}</div>
                            <div>#3 CI-K0026 Water Zhang Z</div>
                            <div>Jan 23 2021 to Feb 8 2021 at YT1-18</div>
                            <div>2021-01-22 10:52</div>
                        </div>
                    </div>
                    <div className={classes.notification}>
                        <div className={classes.read}>
                            <FiberManualRecordIcon />
                        </div>
                        <div>
                            <div>{t("OperatorRequest")}</div>
                            <div>#3 CI-K0026 Water Zhang Z</div>
                            <div>Jan 23 2021 to Feb 8 2021 at YT1-18</div>
                            <div>2021-01-22 10:52</div>
                        </div>
                    </div>
                    <div className={classes.notification}>
                        <div className={classes.read}>
                            <FiberManualRecordIcon />
                        </div>
                        <div>
                            <div>{t("OperatorRequest")}</div>
                            <div>#3 CI-K0026 Water Zhang Z</div>
                            <div>Jan 23 2021 to Feb 8 2021 at YT1-18</div>
                            <div>2021-01-22 10:52</div>
                        </div>
                    </div>
                    <div className={classes.notification}>
                        <div>
                            <FiberManualRecordIcon />
                        </div>
                        <div>
                            <div>{t("OperatorRequest")}</div>
                            <div>#3 CI-K0026 Water Zhang Z</div>
                            <div>Jan 23 2021 to Feb 8 2021 at YT1-18</div>
                            <div>2021-01-22 10:52</div>
                        </div>
                    </div>
                </div>
           </div>
           <div>
               <div className={classes.currentNotification}>{t("OperatorRequest")}</div>
               <div className={classes.notificationDetail}>
                    <div>
                        <div>
                            <div>{t("RequestedLine")}</div>
                            <span>YTI-38</span>
                        </div>
                        <div>
                            <div>{t("RequestedOperator")}</div>
                            <span>CI-K0001 Water Zhang A</span>
                        </div>
                        <div>
                            <div>{t("OperatorExistingLine")}</div>
                            <span>YTI-22</span>
                        </div>
                        <div>
                            <div>{t("StartDate")}</div>
                            <span>Jan 26 2021</span>
                        </div>
                        <div>
                            <div>{t("EndDate")}</div>
                            <span>Feb 8 2021</span>
                        </div>
                        <div>
                            <div>{t("StyleName")}</div>
                            <span>F20081202_TC OB_V1_KTEE SS</span>
                        </div>
                        <div>
                            <div>{t("StyleCode")}</div>
                            <span>F20081202</span>
                        </div>
                        <div>
                            <div>{t("OperationName")}</div>
                            <span>Measure&Cut Trim (short)*1 MSTD</span>
                        </div>
                        <div>
                            <div>{t("OperationCode")}</div>
                            <span>YTJKDSCT0008</span>
                        </div>
                    </div>
                    <div>
                        <div>{t("MODIFIEDBY")}: WaterZ</div>
                        <div>{t("DATEMODIFIED")}: 2020-10-29</div>
                        <div>{t("Createdby")}: WaterZ</div>
                        <div>{t("DateCreated")}: 2020-10-29</div>
                    </div>
               </div>
           </div>
        </div>
        </React.Fragment>
    );
}
