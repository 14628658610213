import { allNptTypeListModel, nptListModel } from '../../model';
import Utils from '../../utils';

export const nptListReducer = (state: nptListModel = new nptListModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_NPT:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const allNptTypeListReducer = (state: allNptTypeListModel = new allNptTypeListModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.GET_ALL_NPT_TYPES:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
