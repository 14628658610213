export const GET_ROWS = 'GET_ROWS';

export const UPDATE_DATA_GRAPH = 'UPDATE_DATA_GRAPH';
export const UPDATE_GRAPH_ORDER_BY = 'UPDATE_GRAPH_ORDER_BY';
export const UPDATE_GRAPH_VIEW_BY = 'UPDATE_GRAPH_VIEW_BY';
export const UPDATE_GRAPH_SELECTED_LABELS = 'UPDATE_GRAPH_SELECTED_LABELS';

export const UPDATE_DATE_RANGE = 'UPDATE_DATE_RANGE';
export const UPDATE_SHIFTS = 'UPDATE_SHIFTS';
export const UPDATE_SHIFT_ID = 'UPDATE_SHIFT_ID';
export const UPDATE_MULTIPLE_VALUES = 'UPDATE_MULTIPLE_VALUES';
export const UPDATE_QUERY_ROWS = 'UPDATE_QUERY_ROWS';

export const REQUEST_API_SHIFTS = 'REQUEST_API_SHIFTS';
export const REQUEST_API_SUCCESS_SHIFTS = 'REQUEST_API_SUCCESS_SHIFTS';
export const REQUEST_API_ERROR_SHIFTS = 'REQUEST_API_ERROR_SHIFTS';

export const REQUEST_API_ROWS = 'REQUEST_API_ROWS';
export const REQUEST_API_SUCCESS_ROWS = 'REQUEST_API_SUCCESS_ROWS';
export const REQUEST_API_ERROR_ROWS = 'REQUEST_API_ERROR_ROWS';

export const REQUEST_API_GRAPH = 'REQUEST_API_GRAPH';
export const REQUEST_API_SUCCESS_GRAPH = 'REQUEST_API_SUCCESS_GRAPH';
export const REQUEST_API_ERROR_GRAPH = 'REQUEST_API_ERROR_GRAPH';
