import React from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    Typography,
    Button,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import Schema from '../../../schema';
import { useTranslation } from 'react-i18next';
import NormalInput from '../../../components/inputs/normalInput';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModel } from '../../../model';
import { addPpa, editPpa } from '../action';
import moment from 'moment';
import OutlineInput from '../../../components/inputs/outlineInput';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            '& .MuiTextField-root': {
                marginBottom: '10px!important',
            },
        },
        textStyl: {
            fontSize: '20px',
            color: 'var(--dark-grey)',
            margin: '44px 0 0 0',
        },
        paper: {
            position: 'absolute',
            width: '25%',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        saveContainer: {
            backgroundColor: 'white',
            padding: '10px 0',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '25px',
        },
        cancelButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#515151',
            opacity: 1,
            height: '36px',
        },
        saveButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#FFFFFF',
            opacity: 1,
            height: '36px',
        },
        subHeading: {
            textAlign: 'left',
            font: 'normal normal normal 12px/21px Roboto',
            letterSpacing: '0.07px',
            color: '#8C8C8C',
            opacity: 1,
            marginBottom: '3px',
        },
        author: {
            marginTop: '15px',
        },
        statusContainer: {
            display: 'flex',
            alignItems: 'center',
            '& .MuiFormGroup-root': { flexDirection: 'row' },
            '& .MuiTypography-root': {
                textAlign: 'left',
                font: 'normal normal normal 14px/21px Roboto',
                letterSpacing: '0.08px',
                color: '#515151',
                opacity: 1,
            },
        },
        status: {
            marginRight: '40px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
    }),
);

export default function AddppaForm({ handleClose }: any) {
    const classes = styles();
    const { t } = useTranslation();
    const {
        isEditPpA,
        ppaName,
        descEdit,
        idEdit,
        selectedisActive,
        createdBy,
        updatedBy,
        updatedDt,
        createdDt,
        firstLangDataEdit,
        secondLangDataEdit,
    } = useSelector((state: ReducersModel) => state.ppaCodeReducer);
    const { firstLangDesc, secondLangDesc } = useSelector((state: ReducersModel) => state.globalLangDataReducer);

    const dispatch = useDispatch();

    function getModalStyle() {
        const top = 50;
        const left = 50;
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const [modalStyle] = React.useState(getModalStyle);

    return (
        <div style={modalStyle} className={classes.paper}>
            <div className={classes.mainContainer}>
                <Typography className={classes.textStyl}>
                    {isEditPpA ? `${t('Edit')} ${t('Features')}` : `${t('Add')} ${t('Features')}`}
                </Typography>
                <div>
                    <Formik
                        initialValues={{
                            ppaName: isEditPpA ? ppaName : '',
                            description: isEditPpA ? descEdit : '',
                            isActive: `${selectedisActive}`,
                            firstLang: isEditPpA ? firstLangDataEdit : '',
                            secondLang: isEditPpA ? secondLangDataEdit : '',
                        }}
                        validationSchema={Schema.AddPpaSchema(t)}
                        onSubmit={(values, { setSubmitting }) => {
                            if (isEditPpA) {
                                dispatch(editPpa(values, setSubmitting, handleClose, idEdit));
                            } else {
                                dispatch(addPpa(values, setSubmitting, handleClose));
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                        }) => (
                            <Form>
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.ppaName}
                                    onChange={handleChange}
                                    helperText={touched.ppaName && errors.ppaName}
                                    error={Boolean(touched.ppaName && errors.ppaName)}
                                    name={'ppaName'}
                                    placeholder={t('Feature')}
                                    label={t('DisplayName')}
                                    isRequired={true}
                                />
                                <OutlineInput
                                    values={values.firstLang}
                                    onChange={handleChange}
                                    name={'firstLang'}
                                    translation={true}
                                    translationHandle={() =>
                                        // translate(
                                        //     bay.name,
                                        //     Utils.constants.firstLanguage,
                                        //     'firstLang',
                                        //     index,
                                        //     'firstLangIsGoogle',
                                        // )
                                        console.log('heere')
                                    }
                                    helperText={touched.firstLang && errors.firstLang}
                                    error={Boolean(touched.firstLang && errors.firstLang)}
                                    label={`${t('DisplayName')} (${firstLangDesc} Translation)`}
                                    isRequired={false}
                                    placeholder={t('AddTranslatedText')}
                                />
                                <OutlineInput
                                    values={values.secondLang}
                                    name={'secondLang'}
                                    onChange={handleChange}
                                    translation={true}
                                    translationHandle={() =>
                                        // translate(
                                        //     bay.name,
                                        //     Utils.constants.secondLanguage,
                                        //     'secondLang',
                                        //     index,
                                        //     'secondLangIsGoogle',
                                        // )
                                        console.log('heere')
                                    }
                                    helperText={touched.secondLang && errors.secondLang}
                                    error={Boolean(touched.secondLang && errors.secondLang)}
                                    label={`${t('DisplayName')} (${secondLangDesc} Translation)`}
                                    isRequired={false}
                                    placeholder={t('AddTranslatedText')}
                                />

                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.description}
                                    onChange={handleChange}
                                    helperText={touched.description && errors.description}
                                    error={Boolean(touched.description && errors.description)}
                                    name={'description'}
                                    label={t('DESCRIPTION')}
                                    isRequired={false}
                                />

                                {isEditPpA && (
                                    <>
                                        <div className={classes.statusContainer}>
                                            <span className={classes.status}>{t('Status')}: </span>
                                            <RadioGroup
                                                id="isActive"
                                                value={values.isActive}
                                                onChange={(event: any) => {
                                                    setFieldValue('isActive', `${event.target.value}`);
                                                }}
                                            >
                                                <FormControlLabel
                                                    value={`true`}
                                                    control={<Radio color="primary" />}
                                                    label={t('Active')}
                                                />
                                                <FormControlLabel
                                                    value={`false`}
                                                    control={<Radio color="primary" />}
                                                    label={t('Inactive')}
                                                />
                                            </RadioGroup>
                                        </div>

                                        <div className={classes.author}>
                                            <div className={classes.subHeading}>
                                                {t('MODIFIEDBY')}: {updatedBy ? updatedBy : '-'}
                                            </div>
                                            <div className={classes.subHeading}>
                                                {t('DATEMODIFIED')}:{' '}
                                                {updatedDt !== null ? moment(updatedDt).format('YYYY-MM-DD') : '-'}
                                            </div>
                                            <div className={classes.subHeading}>
                                                {t('Createdby')}: {createdBy ? createdBy : '-'}
                                            </div>
                                            <div className={classes.subHeading}>
                                                {t('DateCreated')}:{' '}
                                                {createdDt ? moment(createdDt).format('YYYY-MM-DD') : '-'}
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div className={classes.saveContainer}>
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        className={classes.cancelButton}
                                        onClick={handleClose}
                                    >
                                        {t('CANCEL')}
                                    </Button>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className={classes.saveButton}
                                    >
                                        {isSubmitting ? `${t('Saving')}...` : t('Save')}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}
