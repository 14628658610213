const ARTICLE_LOGO = '../images/poweredby_LTLabs_logo.svg';
const HEADER_LOGO = '../images/header_logo.png';
const LOGOUT = '../images/logout.svg';
const GLOBE = '../images/globe.svg';
const MENU_ONE = '../images/menu_one.svg';
const MENU_TWO = '../images/menu_two.svg';
const MENU_THREE = '../images/menu_three.svg';
const MENU_FOUR = '../images/menu_four.svg';
const MENU_FIVE = '../images/menu_five.svg';
const MENU_SIX = '../images/ic_line balancing sheet.svg';
const MENU_SEVEN = '../images/ic_manage style.svg';
const MENU_EIGHT = '../images/ic_line setup.svg';
const EYE_OPEN = '../images/Eye Icon.svg';
const EYE_CLOSE = '../images/Icon ionic-md-eye-off.svg';
const SEARCH_ICON = '../images/Group 1535.svg';
const CLOUD_UPLOAD = '../images/cloud-upload-alt.svg';
const STYLE_BASIC = '../images/Image 217.png';
const RIGHT_HEADER = '../images/right_header.svg';
const RIGHT_MENU_FISRT = '../images/right_menu_first.png';
const SETTING_ICON = '../images/ic_settings_white.svg';
const RIGHT_MENU_SECOND = '../images/ic_lt qualtiy.svg';
const RIGHT_MENU_THIRD = '../images/LTwarehouse@2x.png';
const RIGHT_MENU_FOURTH = '../images/LTie@2x.png';
const RIGHT_MENU_FIVE = '../images/LTmechanic@2x.png';
const RIGHT_MENU_SIXTH = '../images/LTdevelopment@2x.png';
const RIGHT_MENU_SEVENTH = '../images/LTcutting@2x.png';
const RIGHT_MENU_EIGHT = '../images/LTperformance@2x.png';
const RIGHT_MENU_NINE = '../images/LTbrand@2x.png';
const RIGHT_MENU_TEN = '../images/LTcompliance@2x.png';
const RIGHT_MENU_ELEVEN = '../images/LTadmin@2x.png';
const LINE_SETUP_IMGAE_ONE_INFO = '../images/Image 10@2x.png';
const LINE_SETUP_IMGAE_TWO_INFO = '../images/Image 11@2x.png';
const EDIT_PENCIL = '../images/Group 3743.svg';
const SHIRT_STYLE_IMAGE = '../images/Image 10.png';
const DUSTBIN = '../images/Component 208 – 10.svg';
const PLUS = '../images/Group 2821.svg';
const LARGE_SHIRT_STYLE = '../images/largeshirt.png';
const OPAQUE_CLOSE = '../images/closeIconn.png';
const CANCEL = '../images/cancel.svg';
const INVALID_IMAGE = '../images/invalidImagePlaceholder.png';
const MERGE = '../images/merge.svg';
const SPLIT = '../images/split.svg';
const ALERT = '../images/operatorAlert.svg';
const TSHIRT_DUMMY = '../images/tshirt.png';
const CAMERA_SMALL = '../images/camera.svg';
const ACT_EFF = '../images/ic_act eff.svg';
const IC_NPT = '../images/ic_npt.svg';
const TGT_EFF = '../images/ic_tgt eff.svg';
const LOGO_WHITE = '../images/ltlabs_logo_white.svg';
const DOWNLOAD = '../images/downloadicon.svg';
const CLOCK = '../images/clock.svg';
const COPY = '../images/copy.svg';
const CLOSE = '../images/close.svg';
const COPY1 = '../images/copy1.svg';
const NOIMAGE = "../images/noImageAttachment.png";
const LB_GREEN = "../images/LBgreen.svg";
const LB_ORANGE = "../images/LBorange.svg";
const LB_RED = "../images/LBred.svg";
const LB_DOWN_GREEN = "../images/LBdownGreen.svg";
const LB_UP_RED = "../images/LBupRed.svg";
const BETA = "../images/beta.svg";
const EXCEL = '../images/excel.svg';
const PDF = '../images/adobe_pdf.png';

const LocalImages = {
    ACT_EFF,
    IC_NPT,
    TGT_EFF,
    SPLIT,
    MERGE,
    ARTICLE_LOGO,
    HEADER_LOGO,
    GLOBE,
    LOGOUT,
    MENU_ONE,
    MENU_TWO,
    MENU_THREE,
    MENU_FOUR,
    MENU_FIVE,
    MENU_SIX,
    MENU_SEVEN,
    MENU_EIGHT,
    EYE_OPEN,
    EYE_CLOSE,
    SEARCH_ICON,
    CLOUD_UPLOAD,
    STYLE_BASIC,
    RIGHT_HEADER,
    RIGHT_MENU_FISRT,
    SETTING_ICON,
    RIGHT_MENU_SECOND,
    RIGHT_MENU_THIRD,
    RIGHT_MENU_FOURTH,
    RIGHT_MENU_FIVE,
    RIGHT_MENU_SIXTH,
    RIGHT_MENU_SEVENTH,
    RIGHT_MENU_EIGHT,
    RIGHT_MENU_NINE,
    RIGHT_MENU_TEN,
    RIGHT_MENU_ELEVEN,
    LINE_SETUP_IMGAE_ONE_INFO,
    LINE_SETUP_IMGAE_TWO_INFO,
    EDIT_PENCIL,
    SHIRT_STYLE_IMAGE,
    DUSTBIN,
    PLUS,
    LARGE_SHIRT_STYLE,
    OPAQUE_CLOSE,
    CANCEL,
    INVALID_IMAGE,
    ALERT,
    TSHIRT_DUMMY,
    CAMERA_SMALL,
    LOGO_WHITE,
    DOWNLOAD,
    CLOCK,
    COPY,
    CLOSE,
    COPY1,
    NOIMAGE,
    LB_ORANGE,
    LB_GREEN,
    LB_RED,
    LB_DOWN_GREEN,
    LB_UP_RED,
    BETA,
    EXCEL,
    PDF,
};

export default LocalImages;
