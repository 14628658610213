import React, { useEffect, useRef } from 'react';
import { Button, createStyles, InputAdornment, makeStyles, TextField, Theme, Menu, Avatar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import LocalImages from '../../../utils/images';
import TimeStudyTableList from './timeStudyTableList';
import ManageOperatorTimeStudyFilter from '../../../components/filters/manageOperatorTimeStudy';
import SimpleModal from '../../../components/modal';
import AddSkill from './addSkill';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModel } from '../../../model';
import { getSkillMDMApi } from '../productSettings/action';
import Utils from '../../../utils';
import { getTimestudyOperator } from '../action';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            width: 'fit-content',
            maxWidth: '90%',
            margin: '16px auto',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '18px 1.2%',
            position: 'absolute',
        },
        heading: {
            font: 'normal normal normal 20px/24px Roboto',
            letterSpacing: '0.07px',
            color: '#515151',
            opacity: 1,
            marginBottom: '13px',
        },
        searchInput: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            width: '300px',
            '& .MuiInputBase-root': {
                height: '36px',
                font: 'normal normal normal 14px/20px Roboto',
                letterSpacing: '0.05px',
                color: '#515151',
                opacity: 1,
            },
        },
        searchIcon: {
            cursor: 'pointer',
        },
        styleOperations: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '18px',
        },
        leftStyleOperations: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
        },
        addButton: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            opacity: 1,
            margin: '0 15px',
            border: '1px solid #1E88E5',
            font: 'normal normal normal 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#1E88E5',
            textTransform: 'uppercase',
            height: '36px',
        },
        darkButton: {
            textAlign: 'center',
            font: 'normal normal medium 14px/20px Roboto',
            letterSpacing: '0.05px',
            color: '#707070',
            textTransform: 'uppercase',
            opacity: 1,
            border: '1px solid rgb(153 153 153 / 50%)',
            marginLeft: '15px',
            '&:hover': {
                border: '1px solid rgb(153 153 153 / 50%)',
                backgroundColor: 'var(--background-grey)',
            },
        },
        filterCount: {
            minWidth: '24px',
            minHeight: '24px',
            width: '1em',
            height: '1em',
            textAlign: 'center',
            font: 'normal normal normal 14px/20px Roboto!important',
            letterSpacing: '0.05px',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            opacity: 1,
            backgroundColor: '#1E88E5',
        },
        filterMenu: {
            '& .MuiList-root': {
                width: '194px',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: '0px 0px 6px #00000029',
                opacity: 1,
            },
        },
        closeContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '15px',
        },
    }),
);

export default function TimeStudy({ handleClose }: any) {
    const classes = styles();
    const { t } = useTranslation();
    const { operatorId } = useSelector((state: ReducersModel) => state.skillReducer);
    const { skillTypeData } = useSelector((state: ReducersModel) => state.ppaCodeReducer);
    const { machines } = useSelector((state: ReducersModel) => state.machineListReducer);
    const { search, filterSkillIds, filterMachineIds } = useSelector(
        (state: ReducersModel) => state.timeStudyOperatorReducer,
    );

    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    // for add skill
    const [open, setOpen] = React.useState(false);
    const handleOpenSkill = () => {
        setOpen(true);
    };
    const handleCloseSkill = () => {
        setOpen(false);
    };

    useEffect(() => {
        dispatch(getSkillMDMApi());
    }, []);

    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const [modalStyle] = React.useState(getModalStyle);

    const getFilterCount = () => {
        return +filterSkillIds.length + filterMachineIds.length;
    };

    const debounceCount = useRef(0);
    const api = () =>
        new Promise((res) => {
            setTimeout(() => {
                res(1);
            }, 500);
        });

    const handleSearch = (event: any) => {
        event.persist();
        dispatch({
            type: Utils.ActionName.GET_TIMESTUDY_OPERATOR_LIST,
            payload: {
                search: event.target.value,
            },
        });
        debounceCount.current += 1;
        const localDebounceCount = debounceCount.current;
        api().then((d) => {
            if (localDebounceCount !== debounceCount.current) {
                return;
            }
            dispatch({
                type: Utils.ActionName.GET_TIMESTUDY_OPERATOR_LIST,
                payload: {
                    search: event.target.value,
                },
            });
            dispatch(getTimestudyOperator(operatorId));
        });
    };

    return (
        <div style={modalStyle} className={classes.mainContainer}>
            <div className={classes.heading}>{t('TimeStudy')}</div>
            <div className={classes.styleOperations}>
                <div className={classes.leftStyleOperations}>
                    <TextField
                        className={classes.searchInput}
                        variant="outlined"
                        placeholder={t('Search')}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img
                                        alt={t('Search')}
                                        src={LocalImages.SEARCH_ICON}
                                        className={classes.searchIcon}
                                    />
                                </InputAdornment>
                            ),
                        }}
                        value={search}
                    />
                    <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon />}
                        className={classes.addButton}
                        onClick={handleOpenSkill}
                    >
                        {t('Add')}
                    </Button>
                </div>
                <div>
                    <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<FormatListBulletedIcon />}
                        className={classes.darkButton}
                        endIcon={<Avatar className={classes.filterCount}>{getFilterCount()}</Avatar>}
                        onClick={handleOpenFilter}
                    >
                        {t('Filter')}
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseFilter}
                        className={classes.filterMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        getContentAnchorEl={null}
                    >
                        <ManageOperatorTimeStudyFilter skills={skillTypeData} machines={machines} />
                    </Menu>
                </div>
            </div>
            <TimeStudyTableList />
            <div className={classes.closeContainer}>
                <Button variant="outlined" color="primary" className={classes.darkButton} onClick={handleClose}>
                    {t('Close')}
                </Button>
            </div>
            <SimpleModal
                handleOpen={handleOpenSkill}
                isOpen={open}
                body={<AddSkill operatorId={operatorId} handleClose={handleCloseSkill} />}
            />
        </div>
    );
}
