import React from 'react';
import { LBRCustomSelect, LBRVictoryChart, LBRCustomRadio } from '../../Components';
import { LBRChartContainer, LBRFlexCenterContainer, LBRLabel, LBRFlexContainer, LBRCustomZoomOutButton, LBRCustomZoomInButton } from '../../styles';
import {
    directionOption,
    legends,
    viewByOptions,
} from '../../helpers';
import { LBRLegen } from '../../Icons';
import { useDispatch, useSelector } from 'react-redux';
import { callUpdateReducer, getLbrGraph } from '../../Redux/actions';
import { 
    UPDATE_GRAPH_ORDER_BY, 
    UPDATE_GRAPH_SELECTED_LABELS, 
    UPDATE_GRAPH_VIEW_BY,
} from '../../Redux/constant';

const LBRChart: React.FC = () => {
    const { 
        data, 
        query:{ orderBy, viewBy },
        selectedLabels,
    } = useSelector((state: any) => state.lbrGraph);
    const { isLoadingGraph } = useSelector((state: any) => state.lbrGlobal);
    const dispatch = useDispatch();
    const lbrHistoryChartRef = React.useRef<any>();

    const handleChangeViewBy = (viewBy: any) => {
        dispatch(callUpdateReducer(
            UPDATE_GRAPH_VIEW_BY,
            viewByOptions?.find(({ id }: any) => id === viewBy),
        ));
        reFreshData();
    };

    const handleChangeOrderBy = (orderBy: number) => {
        dispatch(callUpdateReducer(
            UPDATE_GRAPH_ORDER_BY,
            orderBy,
        ));
        reFreshData();
    };

    const reFreshData = React.useCallback(() => {
        dispatch(getLbrGraph());
    }, [orderBy, viewBy]);

    return (
        <LBRChartContainer boxShadow={3}>
            <LBRFlexCenterContainer>
                <LBRCustomRadio
                    onChange={handleChangeOrderBy}
                    defaultValue={orderBy}
                    data={directionOption}
                    isLoading={isLoadingGraph}
                />
                <LBRFlexContainer>
                    {[...legends,
                    {
                        name: viewBy['name'],
                        color: viewBy['color'],
                        type: viewBy['type'],
                    }
                    ].map(({ name, type, color }: any) => (
                        <LBRFlexContainer>
                            <LBRLegen color={color} type={type} />
                            <LBRLabel>{name}</LBRLabel>
                        </LBRFlexContainer>
                    ))}
                </LBRFlexContainer>
                <LBRFlexContainer>
                    <LBRFlexContainer>
                        <LBRCustomZoomOutButton onClick={() => lbrHistoryChartRef.current.handleZoomInOut('zoomOut')} />
                        <LBRCustomZoomInButton onClick={() => lbrHistoryChartRef.current.handleZoomInOut('zoomIn')} />
                    </LBRFlexContainer>
                    <LBRLabel>View By</LBRLabel>
                    <LBRCustomSelect
                        defaultValue={viewBy['id']}
                        onChange={handleChangeViewBy}
                        data={viewByOptions}
                        isLoading={isLoadingGraph}
                    />
                </LBRFlexContainer>
            </LBRFlexCenterContainer>
            <LBRVictoryChart
                ref={lbrHistoryChartRef}
                selectedLabels={selectedLabels}
                onSelectedBar={(labels: any) => dispatch(callUpdateReducer(
                    UPDATE_GRAPH_SELECTED_LABELS,
                    labels,
                ))}
                viewByLabel={viewBy['name']}
                viewByKey={viewBy['key']}
                viewByBarColor={viewBy['color']}
                isLoading={isLoadingGraph}
                data={data} />
        </LBRChartContainer>
    );
}

export default LBRChart;