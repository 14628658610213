import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
const styles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      "& .MuiTextField-root": {
        marginBottom: "10px!important",
      },
    },
    textStyl: {
      margin: "44px 0 35px 0",
      textAlign: "left",
      font: "normal normal normal 20px/20px Roboto",
      letterSpacing: "0.07px",
      color: "#B90000",
      opacity: 1,
    },
    textStyle1: {
      margin: "44px 0 35px 0",
      textAlign: "left",
      font: "normal normal normal 20px/20px Roboto",
      letterSpacing: "0.07px",
      color: "#515151",
      opacity: 1,
    },
    paper: {
      position: "absolute",
      width: "20%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    saveContainer: {
      backgroundColor: "white",
      padding: "10px 0",
      display: "flex",
      justifyContent: "center",
      marginTop: "41px",
    },
    cancelButton: {
      margin: "0 15px",
      minWidth: "100px",
      textAlign: "center",
      font: "normal normal medium 16px/20px Roboto",
      letterSpacing: "0.06px",
      color: "#515151",
      opacity: 1,
      height: "36px",
    },
    saveButton: {
      margin: "0 15px",
      minWidth: "100px",
      textAlign: "center",
      font: "normal normal medium 16px/20px Roboto",
      letterSpacing: "0.06px",
      color: "#FFFFFF",
      opacity: 1,
      height: "36px",
      "&:hover": {
        backgroundColor: "#D32F2F !important",
      }
    },
    info: {
      textAlign: "left",
      font: "normal normal normal 14px/21px Roboto",
      letterSpacing: "0.08px",
      color: "#000000",
      opacity: 1,
    },
    saveButton1: {
      margin: "0 15px",
      minWidth: "100px",
      textAlign: "center",
      font: "normal normal medium 16px/20px Roboto",
      letterSpacing: "0.06px",
      color: "#FFFFFF",
      opacity: 1,
      height: "36px",
      backgroundColor: "#1689FA",
      "&:hover": {
        backgroundColor: "rgb(21, 95, 160) !important",
      }
    },
    deleteStyle: {
      position: 'absolute',
      top: '2%',
      right: '2%',
      cursor: 'pointer',
      height: '24px',
      width: '24px',
    },
  })
);

export default function TranslationModal({
  handleClose,
  onDelete,
  title,
  info,
  desc,
  secondPara,
  deleteBtn = "",
  canceBtn = "",
  isDelete = true,
}: any) {
  const classes = styles();
  const { t } = useTranslation();
  function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div style={modalStyle} className={classes.paper}>
      <div className={classes.mainContainer}>
        <Typography
          className={isDelete ? classes.textStyl : classes.textStyle1}
        >
          {title}
        </Typography>
        <div className={classes.info}>
          {desc ? desc : t("THE_RECORD_CANT_RECOVERED")}
        </div>
        {secondPara ? (
          <p className={classes.info}>{secondPara}</p>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <div className={classes.saveContainer}>
          <Button
            variant="outlined"
            size="large"
            className={classes.cancelButton}
            onClick={handleClose}
          >
            {canceBtn ? canceBtn : t("CANCEL")}
          </Button>
          <Button
            size="large"
            type="submit"
            variant="contained"
            className={isDelete ? classes.saveButton : classes.saveButton1}
            onClick={() => {
              handleClose();
              onDelete();
            }}
          >
            {deleteBtn ? deleteBtn : t("DELETE")}
          </Button>
        </div>
      </div>
    </div>
  );
}