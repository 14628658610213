import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import {
  addDays,
  endOfDay,
  startOfDay,
  isSameDay,
  endOfMonth,
  eachDayOfInterval,
  format,
} from 'date-fns';
import moment from 'moment';
import { DateRange, StaticRangeHandler } from './Interfaces';

const formatDate = (date: Date, format = 'DD/MM/yyyy') => {
  if(!date) return '-';
  return moment(date).format(format);
}

const handleDisplayTick = (value: any) => {
  if(!value) return 0;
  if (value >= 1000) {
    return (value / 1000).toFixed(1) + "K";
  } else {
    return value;
  }
}

const defineds = {
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  endOfTheMonth: endOfDay(endOfMonth(new Date())),
  startOf7DaysAgo: startOfDay(addDays(new Date(), -7)),
  startOf30DaysAgo: startOfDay(addDays(new Date(), -30)),
  startOf90DaysAgo: startOfDay(addDays(new Date(), -90))
};

const staticRangeHandler: StaticRangeHandler = {
  range: { startDate: new Date(), endDate: new Date() },
  isSelected(range: DateRange) {
    const definedRange = this.range;
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  }
};

const createStaticRanges = (ranges: any) => {
  return ranges.map((range: any) => ({ ...staticRangeHandler, ...range }));
};

const staticRanges = createStaticRanges([
  {
    label: 'Last 24 hours',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfToday
    })
  },
  {
    label: 'Last 7 Days',
    range: () => ({
      startDate: defineds.startOf7DaysAgo,
      endDate: defineds.endOfToday
    })
  },
  {
    label: 'Last 30 Days',
    range: () => ({
      startDate: defineds.startOf30DaysAgo,
      endDate: defineds.endOfToday
    })
  }
]);

const defaultState = {
  startDate: new Date(),
  endDate: addDays(new Date(), 30),
  key: 'selection'
};

const handleDefinedRange = (date: any) => {
  return staticRanges.find((r: any) => {
    const range = r.range();
    return (
      isSameDay(range.startDate, date?.startDate) &&
      isSameDay(range.endDate, date?.endDate)
    );
  });
};

const handleSelectionFormat = (date: any) => {
  const FORMAT = 'MMM DD YYYY';
  return `${moment(date.startDate).format(FORMAT)} - ${moment(
    date.endDate
  ).format(FORMAT)}`;
};

const eachInternal = ({ startDate, endDate }: any) => {
  return eachDayOfInterval({
    start: startDate,
    end: endDate
  }).map((d) => ({
    key: parseInt(format(d, 'd')),
    label: format(d, 'MMMM dd'),
    date: format(d, 'Y-MM-dd'),
  }));
};

const getValueChart = (field: any, ticks: any, data: any) => {
  return ticks.map(({ date }: any, key: number) => {
    const found = data.find(({ createdDt }: any) => createdDt.startsWith(date));
    return ({
      x: key + 1,
      y: found ? found[field] : 0,
    })
  })
};

const normalizeDataChart = (ticks: any, key = 'mpwr', data: any[]) => {
 const result:any = {
    axisBottom: ticks.map(({ label }: any) => label),
    mpwr: getValueChart('mpwr', ticks, data),
    output_mins: getValueChart('output_mins', ticks, data),
    output_psc: getValueChart('output_psc', ticks, data),
    eff: getValueChart('eff', ticks, data),
    lbr: getValueChart('lbr', ticks, data),
  };
  const viewByMin = Math.min(...result[key].map(({ y }: any) => y));
  const viewByMax = Math.max(...result[key].map(({ y }: any) => y))
  return {
    ...result,
    viewByDomain: [viewByMin,viewByMax],
  }
};

/** 
 * Sort object & modify object field from respone data.
 */
const handleSortBy = (data = [], direction = "asc", field = "lbr") => {
  const result = data.map((item: any) => ({
    mpwr: item.manPower,
    output_mins: item.outputByMins,
    output_psc: item.outputByPcs,
    eff: item.currentShiftEff,
    lbr: item.lbr,
    ...item,
  }));
  if (direction === "asc") {
    return result.sort((a, b) => a[field] - b[field]);
  }
  return result.sort((a, b) => b[field] - a[field]);
};

const distinceByCreatedDt = (array: any[]) => {
  const seen = new Set();
  return array.filter((item) => {
    const createDt = moment(item.createdDt).format("YYYY-MM-DD");
    if (!seen.has(createDt)) {
      seen.add(createDt);
      return true;
    }
    return false;
  });
};

const colors: any = {
  lbr: '#9F05F0' ,
  eff: '#308DD6',
  manpower: '#EF8AB5',
  output_by_psc: '#70C4AD',
  output_by_psc_mins: '#8ACB61',
}

const viewByOptions: any = [
  { id: 1, name: 'Manpower', key: 'mpwr', icon: true, color: colors['manpower'], type: 'bar' },
  { id: 2, name: 'Outputby psc', key: 'output_psc', icon: true, color: colors['output_by_psc'], type: 'bar' },
  { id: 3, name: 'Outputby mins', key: 'output_mins', icon: true, color: colors['output_by_psc_mins'], type: 'bar' },
];

const legends: any = [
  { name: 'LBR', color: colors['lbr'], type: 'line', key: 'lbr', },
  { name: 'EFF', color: colors['eff'], type: 'line', key: 'eff', },
];

const shiftOption: any = [
  { id: 1, name: 'Day Shift' },
  { id: 2, name: 'Night Shift' }
];

const directionOption: any = [
  { id: 1, name: 'Best LBR' },
  { id: 2, name: 'Worst LBR' }
];

const calculateAngle = (data: any) => {
  let angle = -90;
  if (data <= 90) {
    angle = -data;
  }
  return angle;
}

const handleAllBarPointMutation = (
  props: any, 
  selectedLabels: string[],
  labels: any,
  viewByBarColor: any,
  disabledColor: any
) => {
  if(!labels || !viewByBarColor) return false;
  const { index } = props;
  const selectedLabelsIndex: number[] =  selectedLabels?.map((label: any) => labels?.indexOf(label))
    .filter((index: number) => index !== -1);
  return {
    style: {
      fill: selectedLabelsIndex.includes(index) || selectedLabels.length === 0 
        ? viewByBarColor
        : disabledColor,
      width: 30,
    }
  }
};

const handleSingleBarPointMutation = (
  props: any, 
  labels: any, 
  selectedLabels: string[], 
  callback: (result: string[]) => void,
) => {
  const index = props.index;
  const label = labels.find((__: any, labelIndex: any) => labelIndex == index);
  const selectedCondition = selectedLabels.includes(label);
  const result: any = selectedCondition 
    ? selectedLabels.filter((item: any) => item !== label) 
    : [...selectedLabels, label];
  callback(result);
};

const handleAxisLabelsStyle = (
  sizePoint: any, 
  selectedLabels: string[],
) => {
  return {
    angle: calculateAngle(sizePoint),
    ...(selectedLabels.length > 0 && {
      textDecoration: ({ text }: any) => selectedLabels.includes(text) ? '' : 'line-through',
      fill: ({ text }: any) => selectedLabels.includes(text) ? '#000' : '#888',
    })
  }
}

const getlbrViewBy = () => {
  const viewByDefaultId = localStorage.getItem('viewByDefaultId');
  return viewByDefaultId 
    ? JSON.parse(viewByDefaultId)
    : viewByOptions[0];
}

const formatNumber = (number: number) => {
  if (isNaN(number)) {
    return 0;
  }
  return number.toString().replace(/\d(?=(\d{3})+\.)/g, "$&,");
}

const formatPercent = (number: number) => {
  if (isNaN(number)) {
    return `0%`;
  }
  return number.toFixed(2) + '%';
}

const formatNumberWithCommas = (number: number) => {
  return number.toLocaleString();
}

const isFloatingPoint = (value: any) => {
  return Number.isFinite(value) && value % 1 !== 0;
}

export {
  isFloatingPoint,
  formatNumberWithCommas,
  handleSortBy,
  distinceByCreatedDt,
  formatNumber,
  formatPercent,
  handleAxisLabelsStyle,
  handleSingleBarPointMutation,
  handleAllBarPointMutation,
  calculateAngle,
  normalizeDataChart,
  eachInternal,
  staticRanges,
  defaultState,
  handleDefinedRange,
  handleSelectionFormat,
  shiftOption,
  viewByOptions,
  getlbrViewBy,
  directionOption,
  legends,
  colors,
  formatDate,
  handleDisplayTick,
};
