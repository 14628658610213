import React from 'react';
import { makeStyles, createStyles, Theme, Typography, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';
import Schema from '../../../schema';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import NormalInput from '../../../components/inputs/normalInput';
import { useSelector, useDispatch } from 'react-redux';
import { ReducersModel } from '../../../model';
import { addSkill, getOperationCode } from '../action';
import AutoComplete from '../../../components/inputs/autoComplete';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            '& .MuiTextField-root': {
                marginBottom: '10px!important',
            },
        },
        textStyl: {
            fontSize: '20px',
            color: 'var(--dark-grey)',
            marginTop: '44px',
        },
        paper: {
            position: 'absolute',
            width: '25%',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        saveContainer: {
            backgroundColor: 'white',
            padding: '10px 0',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '41px',
        },
        cancelButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#515151',
            opacity: 1,
            height: '36px',
        },
        saveButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#FFFFFF',
            opacity: 1,
            height: '36px',
        },
        populateField: {
            marginBottom: '12px',
            '& div': {
                wordBreak: 'break-word',
            },
            '& div:nth-child(1)': {
                font: 'normal normal normal 9px/21px Roboto',
                opacity: 1,
                color: 'rgba(0, 0, 0, 0.54)',
                letterSpacing: '0.07px',
            },
            '& div:nth-child(2)': {
                font: 'normal normal normal 14px/14px Roboto',
                opacity: 1,
                letterSpacing: '0.07px',
                color: 'rgba(0, 0, 0, 0.87)',
                marginTop: '2px',
            },
        },
    }),
);

export default function AddSkill({ operatorId, handleClose }: any) {
    const classes = styles();
    const { t } = useTranslation();
    const { machines } = useSelector((state: ReducersModel) => state.machineListReducer);
    const { operationCodeData } = useSelector((state: ReducersModel) => state.operationCodeReducer);

    const dispatch = useDispatch();
    const history = useHistory();

    function getModalStyle() {
        const top = 50;
        const left = 50;
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const [modalStyle] = React.useState(getModalStyle);

    return (
        <div style={modalStyle} className={classes.paper}>
            <div className={classes.mainContainer}>
                <Typography className={classes.textStyl}>{t('AddSkill')}</Typography>
                <div>
                    <Formik
                        initialValues={{
                            operatorId,
                            operation: '',
                            macSubTypeId: '',
                            eff: '',
                            description: '',
                            skillType: '',
                            time: '',
                        }}
                        validationSchema={Schema.AddSkillSchema(t)}
                        onSubmit={(values, { setSubmitting }) => {
                            dispatch(addSkill(values, setSubmitting, history, handleClose));
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                        }) => (
                            <Form>
                                <AutoComplete
                                    onBlur={handleBlur}
                                    values={values.operation}
                                    onInputChange={(event: any) => {
                                        if (event !== null) {
                                            const searchString = event.target.value;
                                            dispatch(
                                                getOperationCode(10, searchString)
                                            )
                                        }
                                    }}
                                    onChange={(event: any, value: any) => {
                                        if (value !== null) {
                                            const index = operationCodeData.findIndex((x: any) => x.id === value.id);
                                            handleChange(event);
                                            if (index >= 0) {
                                                setFieldValue('operation', value.id);
                                                setFieldValue('description', operationCodeData[index].desc1);
                                                setFieldValue('skillType', operationCodeData[index].skillTypeCode);
                                            }
                                        }
                                    }}
                                    helperText={touched.operation && errors.operation}
                                    error={Boolean(touched.operation && errors.operation)}
                                    name={'operation'}
                                    label={t('OperationCode')}
                                    touched={touched.operation}
                                    options={operationCodeData}
                                    getOptionLabel={(option: any) => option.code}
                                    isRequired={true}
                                />

                                <div className={classes.populateField}>
                                    <div>{t('DESCRIPTION')}</div>
                                    <div>{values.description === '' ? '-' : values.description}</div>
                                </div>

                                <div className={classes.populateField}>
                                    <div>{t('SkillType')}</div>
                                    <div>{values.skillType === '' ? '-' : values.skillType}</div>
                                </div>

                                <AutoComplete
                                    onBlur={handleBlur}
                                    values={values.macSubTypeId}
                                    onInputChange={(event: any) => {
                                        if (event !== null) {
                                            // Do Nothing
                                        }
                                    }}
                                    onChange={(event: any, value: any) => {
                                        if (value !== null) {
                                            const index = machines.findIndex((x: any) => x.id === value.id);
                                            handleChange(event);
                                            if (index >= 0) {
                                                setFieldValue('macSubTypeId', value.id);
                                            }
                                        }
                                    }}
                                    helperText={touched.macSubTypeId && errors.macSubTypeId}
                                    error={Boolean(touched.macSubTypeId && errors.macSubTypeId)}
                                    name={'macSubTypeId'}
                                    label={t('MCCode')}
                                    touched={touched.macSubTypeId}
                                    options={machines}
                                    getOptionLabel={(option: any) => option.subType}
                                    isRequired={true}
                                />
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.time}
                                    onChange={handleChange}
                                    name={'time'}
                                    label={t('TimeStudy')}
                                />
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.eff}
                                    onChange={handleChange}
                                    placeholder={t('EFF')}
                                    helperText={touched.eff && errors.eff}
                                    error={Boolean(touched.eff && errors.eff)}
                                    name={'eff'}
                                    label={t('EFF')}
                                    isRequired={true}
                                />

                                <div className={classes.saveContainer}>
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        className={classes.cancelButton}
                                        onClick={handleClose}
                                    >
                                        {t('CANCEL')}
                                    </Button>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className={classes.saveButton}
                                    >
                                        {isSubmitting ? `${t('Saving')}...` : t('Save')}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}
