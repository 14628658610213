import React from 'react';
import { makeStyles, createStyles, Theme, Divider, FormControlLabel, Checkbox } from '@material-ui/core';
import Utils from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { getNpt } from '../../screens/npt/action';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import { ReducersModel } from '../../model';
import { useTranslation } from 'react-i18next';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            minWidth: '210px',
        },
        title: {
            padding: '0 16px 8px 16px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
        subTitle: {
            padding: '8px 16px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
        filtersBox: {
            padding: '8px 16px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
            display: 'flex',
            maxHeight: '100px',
            overflowY: 'auto',
            flexDirection: 'column',
            '& .MuiTypography-root': {
                textAlign: 'left',
                font: 'normal normal normal 12px/21px Roboto',
                letterSpacing: '0.07px',
                color: '#868686',
                opacity: 1,
            },
            '& .MuiCheckbox-root': {
                padding: '2px 7px',
            },
            '& .MuiFormGroup-root': { flexDirection: 'row!important' },
        },
        statusContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        status: {
            marginRight: '40px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
        indeterminateColor: {
            color: "#1E88E5",
        },
        disabledCheck: {
            "& .MuiTypography-body1": {
                fontStyle: "italic",
                color: "#868686"
            }
        },
        naturalCheck: {
            "& .MuiTypography-root": {
                color: "#515151"
            }
        }
    }),
);

export default function NPTFilter({ mfgLines, shifts, allNptTypes }: any) {
    const classes = styles();
    const { t } = useTranslation();
    const { filterLineIds, filterNptTypeIds } = useSelector((state: ReducersModel) => state.nptListReducer);
    const dispatch = useDispatch();

    return (
        <div className={classes.mainContainer}>
            <div className={classes.title}>{t("Filterby")}</div>
            <Divider />
            <div>
                <div className={classes.subTitle}>{t("Line")}</div>

                <div className={classes.filtersBox}>
                <div>
                {mfgLines && mfgLines.length > 2 &&
                    <FormControlLabel
                        className={classes.naturalCheck}
                        control={
                            <Checkbox
                                color={'primary'}
                                checked={
                                    mfgLines?.length === filterLineIds.length
                                }
                                indeterminate={filterLineIds.length !== 0 && mfgLines?.length !== filterLineIds.length}
                                indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon className={classes.indeterminateColor}/>}
                                inputProps={{
                                    'aria-label': 'decorative checkbox',
                                }}
                                onChange={(event: any) => {
                                    const target = event.target;
                                    if (target.checked) {
                                        let newArray = mfgLines.map(
                                            (a: any) => `${a.id}`,
                                        );
                                        dispatch({
                                            type:
                                                Utils.ActionName
                                                    .GET_NPT,
                                            payload: {
                                                filterLineIds: newArray,
                                            },
                                        });
                                        dispatch(getNpt());
                                    } else {
                                        dispatch({
                                            type:
                                                Utils.ActionName
                                                    .GET_NPT,
                                            payload: {
                                                filterLineIds: [],
                                            },
                                        });
                                        dispatch(getNpt());
                                    }
                                }}
                            />
                        }
                        label={<div>{t("All")}</div>}
                    />
                }
                </div>
                    {mfgLines && mfgLines.length > 0 ? (
                        mfgLines.map((mfgLine: any) => {
                            return (
                                <FormControlLabel
                                    value={mfgLine.id}
                                    className={mfgLine.isActive ? classes.naturalCheck : classes.disabledCheck}
                                    control={
                                        <Checkbox
                                            color={'primary'}
                                            checked={filterLineIds.includes(`${mfgLine.id}`)}
                                            onChange={(event: any) => {
                                                const target = event.target;
                                                if (target.checked) {
                                                    if (!filterLineIds.includes(target.value)) {
                                                        let newArray = [...filterLineIds];

                                                        newArray.push(target.value);
                                                        dispatch({
                                                            type: Utils.ActionName.GET_NPT,
                                                            payload: {
                                                                filterLineIds: newArray,
                                                            },
                                                        });
                                                        dispatch(getNpt());
                                                    }
                                                } else {
                                                    if (filterLineIds.includes(target.value)) {
                                                        const index = filterLineIds.indexOf(target.value);
                                                        let newArray = [...filterLineIds];
                                                        if (index > -1) {
                                                            newArray.splice(index, 1);
                                                        }
                                                        dispatch({
                                                            type: Utils.ActionName.GET_NPT,
                                                            payload: {
                                                                filterLineIds: newArray,
                                                            },
                                                        });
                                                        dispatch(getNpt());
                                                    }
                                                }
                                            }}
                                            name="line"
                                        />
                                    }
                                    label={mfgLine.mfgLine}
                                />
                            );
                        })
                    ) : (
                        <div>{t("NoRecordfound")}</div>
                    )}
                </div>
                <Divider />
            </div>

            <div>
                <div className={classes.subTitle}>{t("NPTType")}</div>

                <div className={classes.filtersBox}>
                <div>
                {allNptTypes && allNptTypes.length > 2 &&
                    <FormControlLabel
                        className={classes.naturalCheck}
                        control={
                            <Checkbox
                                color={'primary'}
                                checked={
                                    allNptTypes?.length === filterNptTypeIds.length
                                }
                                indeterminate={filterNptTypeIds.length !== 0 && allNptTypes?.length !== filterNptTypeIds.length}
                                indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon className={classes.indeterminateColor}/>}
                                inputProps={{
                                    'aria-label': 'decorative checkbox',
                                }}
                                onChange={(event: any) => {
                                    const target = event.target;
                                    if (target.checked) {
                                        let newArray = allNptTypes.map(
                                            (a: any) => `${a.id}`,
                                        );
                                        dispatch({
                                            type:
                                                Utils.ActionName
                                                    .GET_NPT,
                                            payload: {
                                                filterNptTypeIds: newArray,
                                            },
                                        });
                                        dispatch(getNpt());
                                    } else {
                                        dispatch({
                                            type:
                                                Utils.ActionName
                                                    .GET_NPT,
                                            payload: {
                                                filterNptTypeIds: [],
                                            },
                                        });
                                        dispatch(getNpt());
                                    }
                                }}
                            />
                        }
                        label={<div>{t("All")}</div>}
                    />
                }
                </div>
                    {allNptTypes && allNptTypes.length > 0 ? (
                        allNptTypes.map((nptType: any) => {
                            return (
                                <FormControlLabel
                                    value={nptType.id}
                                    className={nptType.isActive === false ? classes.disabledCheck : classes.naturalCheck}
                                    control={
                                        <Checkbox
                                            color={'primary'}
                                            checked={filterNptTypeIds.includes(`${nptType.id}`)}
                                            onChange={(event: any) => {
                                                const target = event.target;
                                                if (target.checked) {
                                                    if (!filterNptTypeIds.includes(target.value)) {
                                                        let newArray = [...filterNptTypeIds];

                                                        newArray.push(target.value);
                                                        dispatch({
                                                            type: Utils.ActionName.GET_NPT,
                                                            payload: {
                                                                filterNptTypeIds: newArray,
                                                            },
                                                        });
                                                        dispatch(getNpt());
                                                    }
                                                } else {
                                                    if (filterNptTypeIds.includes(target.value)) {
                                                        const index = filterNptTypeIds.indexOf(target.value);
                                                        let newArray = [...filterNptTypeIds];
                                                        if (index > -1) {
                                                            newArray.splice(index, 1);
                                                        }
                                                        dispatch({
                                                            type: Utils.ActionName.GET_NPT,
                                                            payload: {
                                                                filterNptTypeIds: newArray,
                                                            },
                                                        });
                                                        dispatch(getNpt());
                                                    }
                                                }
                                            }}
                                            name="line"
                                        />
                                    }
                                    label={nptType.nptType}
                                />
                            );
                        })
                    ) : (
                        <div>{t("NoRecordfound")}</div>
                    )}
                </div>
            </div>
        </div>
    );
}
