import * as React from 'react';
import {
    Tooltip,
    withStyles,
    Typography,
    Divider,
    Box,
    makeStyles,
    Theme,
    createStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ReducersModel } from '../../../model';
import { normalizeStationForCalculationLbr } from '../../../utils/helpers';

const LBRTooltip = () => {
    const classes = useStyle();
    const [actualLbr, setActualLbr] = React.useState<any>(0);
    const [totalCycleTimeAllProcesses, setTotalCycleTimeAllProcesses] = React.useState(0);
    const [longestCycleTime, setLongestCycleTime] = React.useState(0);
    const { infoData } = useSelector((state: ReducersModel) => state.lineAssistInfoReducer);
    const { lineSetup } = useSelector((state: ReducersModel) => state.assistTableLineSetup);

    const handleStations = async () => {
        const stations: any = await normalizeStationForCalculationLbr(lineSetup, infoData);
        setActualLbr(infoData.actualLbr ? infoData.actualLbr : 0);
        const totalCycleTimeAllProcesses = stations.reduce((sum: any, { operationCycleOperatorEff }: any) => sum + operationCycleOperatorEff, 0);
        const longestCycleTime = Math.max(...stations.map(({ operationCycleOperatorEff }: any) => operationCycleOperatorEff));
        setTotalCycleTimeAllProcesses(totalCycleTimeAllProcesses);
        setLongestCycleTime(longestCycleTime);
    }

    React.useEffect(() => {
        handleStations();
    }, [infoData, lineSetup]);
    
    return (
        <CustomTooltip
            title={
                <Box>
                    <Typography className={classes.title}>{`LINE BALANCING RATIO = ${actualLbr.toFixed(2)}%`}</Typography>
                    <Typography className={classes.subTitle} variant='h6'>{`Formular:`}</Typography>
                    <Typography className={classes.text} >
                        {`LBR% = Total Cycle Time of All Processes / (Longest cycle time x # of stations)`}
                    </Typography>
                    <Box className={classes.borderBottom} />
                    <Divider light orientation='horizontal' variant='fullWidth'  />
                    <Box className={classes.flexBetween}>
                        <Typography className={classes.text}>{`Total Cycle Time of All Processes`}</Typography>
                        <Typography className={classes.text}>{`${totalCycleTimeAllProcesses.toFixed(2)}s`}</Typography>
                    </Box>
                    <Box className={classes.flexBetween}>
                        <Typography className={classes.text}>{`Longest Cycle Time`}</Typography>
                        <Typography className={classes.text}>{`${longestCycleTime.toFixed(2)}s`}</Typography>
                    </Box>
                    <Box className={classes.flexBetween}>
                        <Typography className={classes.text}>{`Stations`}</Typography>
                        <Typography className={classes.text}>{`${lineSetup.length}`}</Typography>
                    </Box>
                </Box>
            }
            placement="right"
            arrow={true}
        >
            <Typography className={classes.lbrText}>{`${actualLbr.toFixed(2)}%`}</Typography> 
        </CustomTooltip>
    )
}

const useStyle = makeStyles((theme: Theme) => 
    createStyles({
        flexBetween: { display: 'flex', justifyContent: 'space-between' },
        lbrText: { font: 'normal normal bold 25px/24px Roboto' },
        text: { font: 'normal normal normal 12px/14px Roboto' },
        title: { font: 'normal normal normal 18px/17px Roboto', marginBottom: 15 },
        subTitle: { font: 'normal normal normal 14px/13px Roboto',marginBottom: 10 },
        borderBottom: { borderBottom: '2px solid #fff', margin: '5px 0', },
    }),
);

const CustomTooltip = withStyles({
    tooltip: { background: '#1E3C50', borderRadius: '4px', padding: '15px'},
    arrow: { '&::before': { background: '#1E3C50' } },
})(Tooltip);

export default LBRTooltip;