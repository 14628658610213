import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import LocalImages from '../../utils/images';
import SimpleModal from '../../components/modal';
import { useTranslation } from 'react-i18next';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        deleteStyle: {
            position: 'absolute',
            top: '2%',
            right: '2%',
            cursor: 'pointer',
            height: '24px',
            width: '24px',
        },
        thumbnailPopup: {
            position: 'absolute',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: '30px',
            width: 'auto',
            '& img': {
                maxHeight: '70vh',
                width: 'auto',
            },
        },
        styleImageBG: {
            width: '96px',
            height: '96px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
    }),
);
interface Props {
    alt: string;
    src: string;
    removeImage: any;
}

export default function WithRemoveImage({ alt, src, removeImage }: Props) {
    const classes = styles();
    const { t } = useTranslation();
    const [openModalOne, setOpenModalOne] = useState(false);
    const [imageModal, setModalImage] = useState('');

    function getModalStyle() {
        const top = 50;
        const left = 50;
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const [modalStyle] = React.useState(getModalStyle);

    return (
        <>
            <div style={src === null ? { display: 'none' } : {}}>
                <div
                    onClick={() => {
                        setOpenModalOne(true);
                        setModalImage(src);
                    }}
                >
                    <img src={src} alt={''} className={classes.styleImageBG} />
                </div>
                <img
                    src={LocalImages.CANCEL}
                    alt={t('Close')}
                    className={classes.deleteStyle}
                    onClick={() => removeImage(0)}
                />
            </div>
            <SimpleModal
                // handleClose={() => {
                //     setOpenModalOne(false);
                // }}
                handleOpen={() => {
                    setOpenModalOne(true);
                }}
                isOpen={openModalOne}
                body={
                    <div className={classes.thumbnailPopup} style={modalStyle}>
                        <img src={imageModal} alt={alt} />
                        <img
                            src={LocalImages.CANCEL}
                            alt={t('Close')}
                            className={classes.deleteStyle}
                            onClick={() => setOpenModalOne(false)}
                        />
                    </div>
                }
            />
        </>
    );
}
