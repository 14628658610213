import React from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    Typography,
    Button,
    RadioGroup,
    Radio,
    FormControlLabel,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import Schema from '../../../schema';
import { useTranslation } from 'react-i18next';
import NormalInput from '../../../components/inputs/normalInput';
import { useSelector, useDispatch } from 'react-redux';
import { ReducersModel } from '../../../model';
import { editingNptCat } from './action';
import moment from 'moment';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            '& .MuiTextField-root': {
                marginBottom: '10px!important',
            },
        },
        textStyl: {
            fontSize: '20px',
            color: 'var(--dark-grey)',
            marginTop: '44px',
        },
        paper: {
            position: 'absolute',
            width: '25%',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        saveContainer: {
            backgroundColor: 'white',
            padding: '10px 0',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '41px',
        },
        cancelButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#515151',
            opacity: 1,
            height: '36px',
        },
        saveButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#FFFFFF',
            opacity: 1,
            height: '36px',
        },
        statusContainer: {
            display: 'flex',
            alignItems: 'center',
            '& .MuiFormGroup-root': { flexDirection: 'row' },
            '& .MuiTypography-root': {
                textAlign: 'left',
                font: 'normal normal normal 14px/21px Roboto',
                letterSpacing: '0.08px',
                color: '#515151',
                opacity: 1,
            },
        },
        status: {
            marginRight: '40px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#515151',
            opacity: 1,
        },
        subHeading: {
            textAlign: 'left',
            font: 'normal normal normal 12px/21px Roboto',
            letterSpacing: '0.07px',
            color: '#8C8C8C',
            opacity: 1,
            marginBottom: '3px',
        },
        author: {
            marginTop: '15px',
        },
    }),
);

export default function EditNPTCategory({ categoryId, handleClose }: any) {
    const classes = styles();
    const { t } = useTranslation();
    const { nptCat, desc1, updatedDt, updatedBy, createdBy, createdDt, isActive } = useSelector(
        (state: ReducersModel) => state.nptTypeListReducer,
    );

    const dispatch = useDispatch();

    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const [modalStyle] = React.useState(getModalStyle);

    return (
        <div style={modalStyle} className={classes.paper}>
            <div className={classes.mainContainer}>
                <Typography className={classes.textStyl}>
                    {t('Edit')} {t('NPTCategory')}
                </Typography>
                <div>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            nptCatId: categoryId,
                            displayName: nptCat,
                            description: desc1,
                            isActive: `${isActive}`,
                        }}
                        validationSchema={Schema.AddNPTCategory(t)}
                        onSubmit={(values, { setSubmitting }) => {
                            dispatch(editingNptCat(values, setSubmitting, handleClose, categoryId));
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                        }) => (
                            <Form>
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.displayName}
                                    onChange={handleChange}
                                    helperText={touched.displayName && errors.displayName}
                                    error={Boolean(touched.displayName && errors.displayName)}
                                    name={'displayName'}
                                    label={t('DisplayName')}
                                    isRequired={true}
                                />
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.description}
                                    onChange={handleChange}
                                    helperText={touched.description && errors.description}
                                    error={Boolean(touched.description && errors.description)}
                                    name={'description'}
                                    label={t('DESCRIPTION')}
                                />

                                <div className={classes.statusContainer}>
                                    <span className={classes.status}>{t('Status')}: </span>
                                    <RadioGroup
                                        id="isActive"
                                        value={values.isActive}
                                        onChange={(event: any) => {
                                            setFieldValue('isActive', `${event.target.value}`);
                                        }}
                                    >
                                        <FormControlLabel
                                            value={`true`}
                                            control={<Radio color="primary" />}
                                            label={t('Active')}
                                        />
                                        <FormControlLabel
                                            value={`false`}
                                            control={<Radio color="primary" />}
                                            label={t('Inactive')}
                                        />
                                    </RadioGroup>
                                </div>

                                <div className={classes.author}>
                                    <div className={classes.subHeading}>
                                        {t('MODIFIEDBY')}: {updatedBy ? updatedBy : 'N/A'}
                                    </div>
                                    <div className={classes.subHeading}>
                                        {t('DATEMODIFIED')}:
                                        {updatedDt !== null ? moment(updatedDt).format('YYYY-MM-DD') : '-'}
                                    </div>
                                    <div className={classes.subHeading}>
                                        {t('Createdby')}: {createdBy ? createdBy : 'N/A'}
                                    </div>
                                    <div className={classes.subHeading}>
                                        {t('DateCreated')}: {moment(createdDt).format('YYYY-MM-DD')}
                                    </div>
                                </div>

                                <div className={classes.saveContainer}>
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        className={classes.cancelButton}
                                        onClick={handleClose}
                                    >
                                        {t('CANCEL')}
                                    </Button>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className={classes.saveButton}
                                    >
                                        {isSubmitting ? `${t('Saving')}...` : t('Save')}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}
