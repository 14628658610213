import * as React from 'react';
import { Grid } from '@material-ui/core';
import { 
    HeadTitle, 
    ContainerTktMinMaxMinute, 
    TktMinMaxMinuteItem, 
    TktminuteLine, 
    AvgCyleTimeLine, 
    AvgTakTimeLine,
} from './../../styles';

const LineBalance = ({ 
    flag,
    avgTakTime,
    avgCycleTime,
}: any) => {
    
    return (
        <div style={{ position: 'absolute', top: 0, left: 5, width: '100%', height: '100%' }}>
            <Grid container style={{ justifyContent: 'flex-end', height: '100%' }}>
                <Grid item xs={2} style={{ height: '100%' }}>
                    <HeadTitle style={{ background: 'rgb(22, 137, 250)', color: '#fff', border: 'none', padding: '22px 20px' }}>
                        LINE BALANCE
                    </HeadTitle>
                    <div style={{ position: 'relative', height: '100%' }}>
                        <ContainerTktMinMaxMinute>
                            {Array(7).fill(null).map((_, key) => (
                                <TktMinMaxMinuteItem
                                    left={`${(flag * (key + 1))}`}
                                    minute={`${(flag * (key + 1))}s`} />
                            ))}
                        </ContainerTktMinMaxMinute>
                        <div style={{ position: 'absolute', width: '100%', height: '100%', top: 40, left: 0, display: 'flex' }}>
                            <AvgCyleTimeLine offset={(avgCycleTime)} />
                        </div>
                        <div style={{ position: 'absolute', width: '100%', height: '100%', top: 40, left: 0, display: 'flex' }}>
                            <AvgTakTimeLine offset={(avgTakTime)} />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default LineBalance;