import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import UserAccess from './userAccess';
import { useTranslation } from 'react-i18next';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        leftContainer: {
            width: '29%',
            margin: '16px auto',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            minHeight: '550px',
            '& .MuiTextField-root': {
                marginBottom: '10px',
            },
        },
        rightContainer: {
            width: '68.2%',
            margin: '16px auto',
            display: 'flex',
            flexDirection: 'column',
        },
        rightInnerContainer: {
            padding: '18px 1.2%',
            height: 'fit-content',
            marginBottom: '12px',
            backgroundColor: 'white',
            color: '#515151',
            textAlign: 'left',
            font: 'normal normal normal 20px/24px Roboto',
            letterSpacing: '0.07px',
            opacity: 1,
            '&>span': {
                color: '#DD0D0D',
            },
        },
        rightInnerMainContainer: {
            height: 'fit-content',
            display: 'flex',
            justifyContent: 'space-between',
            '&>div': {
                textAlign: 'left',
                font: 'normal normal normal 16px/19px Roboto',
                letterSpacing: '0.06px',
                color: '#515151',
                textTransform: 'uppercase',
                opacity: 1,
                marginBottom: '10px',
            },
        },
        leftTableDescription: {
            width: 'calc(13.74% - 32px)',
        },
        rightTableDescription: {
            width: 'calc(91.5% - 32px)',
        },
        leftTableDescriptionDrag: {
            width: 'calc(10.74%)',
            '&>div': {
                '& .MuiFormControl-root': {
                    width: '90%!important',
                    display: 'flex!important',
                    margin: 'auto',
                },
                backgroundColor: 'white',
                paddingBottom: '13px',
                marginBottom: '10px',
            },
        },
        rightTableDescriptionDrag: {
            width: 'calc(88.32%)',
        },
        columns: {
            backgroundColor: '#D2D2D2',
            minHeight: '35px',
        },
        heading: {
            backgroundColor: 'white',
            padding: '18px 16px',
        },
        saveContainer: {
            backgroundColor: 'white',
            padding: '10px 0',
            display: 'flex',
            justifyContent: 'center',
        },
        cancelButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#515151',
            opacity: 1,
            height: '36px',
        },
        saveButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#FFFFFF',
            opacity: 1,
            height: '36px',
        },
        browseButton: {
            margin: '0 15px',
            minWidth: '77.3%',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#FFFFFF',
            opacity: 1,
            height: '36px',
        },
        dropImagesContainer: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            border: '2px dashed rgb(112, 112, 112, 0.6)',
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '8%',
        },
        styleImageTitle: {
            marginBottom: '10px',
            marginTop: '25px',
            textAlign: 'left',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#000000',
            opacity: 1,
        },
        dropDescription: {
            textAlign: 'center',
            font: 'normal normal bold 16px/28px Roboto',
            letterSpacing: '0px',
            color: '#515151',
            opacity: 1,
        },
        orBox: {
            textAlign: 'center',
            font: 'normal normal normal 14px/28px Roboto',
            letterSpacing: '0px',
            color: '#515151',
            opacity: 1,
            marginBottom: '6px',
        },
        imageNote: {
            marginTop: '10px',
            textAlign: 'center',
            font: 'normal normal normal 14px/28px Roboto',
            letterSpacing: '0px',
            color: '#515151',
            opacity: 1,
        },
        dropIcon: {},
        noRecords: {
            marginTop: '25px',
            marginBottom: '14px',
            textAlign: 'center',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#707070',
            opacity: 1,
        },
        addOperation: {
            textAlign: 'center',
            font: 'normal normal medium 16px/21px Roboto',
            letterSpacing: '0.09px',
            color: '#1689FA',
            opacity: 1,
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            width: 'fit-content',
            margin: 'auto',
        },
        modalBodyContainer: {
            height: '50vh',
        },
        paper: {
            position: 'absolute',
            width: 400,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        categories: {
            display: 'flex',
            backgroundColor: '#D2D2D2',
            padding: '7px',
            '&>div': {
                font: 'normal normal normal 14px/21px Roboto',
                letterSpacing: '0.08px',
                color: '#000000DE',
                textTransform: 'uppercase',
                opacity: 1,
            },
            '& div:nth-child(1)': { width: '8%', marginLeft: '1%' },
            '& div:nth-child(2)': { width: '16%' },
            '& div:nth-child(3)': { width: '23%' },
            '& div:nth-child(4)': { width: '8%' },
            '& div:nth-child(5)': { width: '8%' },
            '& div:nth-child(6)': { width: '12%' },
            '& div:nth-child(7)': { width: '8%' },
            '& div:nth-child(8)': { width: '16%' },
        },
        totalCategories: {
            display: 'flex',
            width: 'calc(90.5% - 32px)',
            padding: '7px',
            '&>div': {
                font: 'normal normal bold 14px/21px Roboto',
                letterSpacing: '0.08px',
                color: '#000000DE',
                textTransform: 'uppercase',
                opacity: 1,
            },
            '& div:nth-child(1)': { width: '8%', paddingLeft: '2%' },
            '& div:nth-child(2)': { width: '16%' },
            '& div:nth-child(3)': { width: '24%' },
            '& div:nth-child(4)': { width: '8%' },
            '& div:nth-child(5)': { width: '8%' },
            '& div:nth-child(6)': { width: '12%' },
            '& div:nth-child(7)': { width: '6%' },
            '& div:nth-child(8)': { width: '18%' },
        },
        totalCategoriesContainer: {
            width: '100%',
            justifyContent: 'flex-end',
            display: 'flex',
        },
        stylesContainer: {
            display: 'flex',
        },
        styleImageContainer: {
            position: 'relative',
            marginRight: '20px',
        },
        styleImage: {
            width: '96px',
            height: '96px',
        },
        deleteStyle: {
            position: 'absolute',
            top: '2%',
            right: '2%',
            cursor: 'pointer',
            height: '24px',
            width: '24px',
        },
        ppaSelectContainer: {
            '& .MuiInputBase-root': {
                paddingTop: '16px',
            },
            '& .MuiInputBase-input': {
                textAlign: 'center',
            },
            '& svg': {
                paddingTop: '6%',
            },
        },
        headingMain: {
            marginBottom: '32px',
        },
        greyBackground: {
            background: '#6B6B6B 0% 0% no-repeat padding-box',
            opacity: 0.3,
            height: '160px',
            width: '100%',
        },
        roundContainer: {
            height: '104px',
            width: '104px',
            backgroundColor: 'white',
            justifyContent: 'center',
            display: 'flex',
            margin: '0 auto',
            marginTop: '-52px',
            borderRadius: '50%',
            border: '6px solid #e8e8e8',
            zIndex: 1,
            alignItems: 'center',
        },
        noSupply: {
            textAlign: 'center',
            font: 'normal normal normal 20px/20px Roboto',
            letterSpacing: '0.07px',
            color: '#515151',
            opacity: 1,
            marginTop: '26px',
        },
        subHeading: {
            textAlign: 'left',
            font: 'normal normal normal 12px/21px Roboto',
            letterSpacing: '0.07px',
            color: '#8C8C8C',
            opacity: 1,
        },
        informationContainer: {
            marginTop: '300px',
            paddingLeft: '20px',
            paddingBottom: '20px',
        },
        divider: {
            height: '2px',
            width: '94%',
            marginBottom: '16px',
            backgroundColor: '#f0f0f0',
        },
        tshirtImage: {
            width: '63px',
            height: '54px',
        },
    }),
);

export default function AddUser() {
    const classes = styles();
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Helmet>
                <title>{t('UserAccess')}</title>
            </Helmet>
            <div className={classes.mainContainer}>
                <div className={classes.leftContainer}>
                    <div className={classes.greyBackground}></div>
                    <div className={classes.roundContainer}>
                        {/* <img className={classes.tshirtImage} src={LocalImages.TSHIRT_DUMMY} alt={'tshirt'} /> */}
                    </div>
                </div>
                <div className={classes.rightContainer}>
                    <div className={classes.rightInnerContainer}>
                        <div className={classes.headingMain}>{`${t('UserAccessRight')} *`}</div>
                        <UserAccess />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
