import { createTheme } from '@material-ui/core/styles'

// When providing any color to items, check that specific color in light theme and the dark color will be automatically
// assigned to it when switch is turned ON.

export const lightTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#1E88E5',
            light: 'rgb(82,54,78)',
            contrastText: '#ffffff',
        },
    },
});

export const darkTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#1E88E5',
            light: 'rgb(82,54,78)',
            contrastText: '#ffffff',
        },
    },
    typography: {},
});
