import * as Yup from 'yup';
import Utils from '../utils';

//@ts-ignore
// import mapValues from 'lodash/mapValues';
/**
 * @description
 * @param t language
 */
const LoginSchema = (t: any) => {
    return Yup.object().shape({
        username: Yup.string().trim().required(t('UsernameEmailisrequired')).max(50),
        password: Yup.string()
            .min(6, t('ThisPasswordIsTooshort'))
            .max(20, t('ThisPasswordIsTooLong'))
            .required(t('PasswordisRequired'))
            .matches(/^\S*$/, t('PleaseStickTo')),
    });
};
const lineSetupSchema = (t: any) =>
    Yup.object().shape({
        stylcode: Yup.string()
            .required(t('Pleaseselectstylecode'))
            .max(25, `${t('MaximumCharactersLimitIs')} 25`)
            .matches(/^\S*$/, t('NoSpaceAllowed')),
        lineId: Yup.string().required(t('Lineisrequired')),
        order: Yup.string()
            .max(25, `${t('MaximumCharactersLimitIs')} 25`)
            .matches(/^\S*$/, t('NoSpaceAllowed')),
        customer: Yup.string().required(t('Pleaseselectcustomer')),
        shift: Yup.string().required(t('Pleaseselectshift')),
        endShift: Yup.string().required(t('Pleaseselectshift')),
        target: Yup.number()
            .typeError(t('Mustbeanumber'))
            .positive(t('Mustbeapositivenumber'))
            .required(t('Pleaseentertargetefficiency'))
            .max(100, `${t('MaximumLimitIs')} 100`),
        quantity: Yup.number()
            .typeError(t('Mustbeanumber'))
            .required(t('Pleaseenterquantity'))
            .max(999999999, `${t('MaximumCharactersLimitIs')} 9`),
        dateStart: Yup.date().required(t('Pleaseenterstartdate')),
        lineName: Yup.string()
                  .trim()
                  .required(t('NameIsRequired'))
                  .max(50, `${t('MaximumCharactersLimitIs')} 50`),
        dateEnd: Yup.date()
            .required(t('Pleaseenterenddate'))
            .min(Yup.ref('dateStart'), t('EndDateCantBeBeforeStartdate')),
    });
const EditShiftSchema = (t: any) =>
    Yup.object().shape({
        factoryStartTime: Yup.string(),
        factoryEndTime: Yup.string(),
        nextShiftStartTime: Yup.string(),
        previousShiftEndTime: Yup.string(),
        startTime: Yup.string()
            .required(t('PleaseEnterStartTime'))
            .test('is-valid', t('EnterValidTime'), function (val) {
                const { factoryEndTime, factoryStartTime } = this.parent;
                return Utils.CommonFunctions.isValidTime(val, factoryStartTime, factoryEndTime);
            }),
        endTime: Yup.string()
            .required(t('PleaseEnterEndTime'))
            .test('is-valid', t('EnterValidTime'), function (val: any) {
                const { startTime } = this.parent;
                const startTimeTS = Utils.CommonFunctions.toTimestamp(startTime);
                const endTimeTS = Utils.CommonFunctions.toTimestamp(val);
                let isEndTimeGreater = startTimeTS > endTimeTS ? false : true;

                return isEndTimeGreater;
            }),
        breakTime: Yup.array().of(
            Yup.object().shape({
                breakStartTime: Yup.string()
                    .required(t('PleaseEnterStartTime'))
                    .test('is-valid', t('EnterValidTime'), function (val) {
                        const { from } = this as any;
                        const { startTime, endTime } = from[1].value;
                        return Utils.CommonFunctions.isValidTime(val, startTime, endTime);
                    }),
                breakDuration: Yup.number()
                    .required(t('PleaseEnterDuration'))
                    .positive(t('PleaseEnterMins'))
                    .test('is-valid-break', 'Enter Valid Duration', function (val) {
                        const { from } = this as any;
                        const { endTime } = from[1].value;
                        const { breakStartTime } = from[0].value;
                        return Utils.CommonFunctions.isValidBreakDuration(val, endTime, breakStartTime);
                    }),
            }),
        ),
        overTime: Yup.array().of(
            Yup.object().shape({
                overStartTime: Yup.string()
                    .required(t('PleaseEnterStartTime'))
                    .test('is-valid', t('EnterValidTime'), function (val) {
                        const { from } = this as any;
                        return Utils.CommonFunctions.isValidOverTime(val, from[1].value);
                    }),
                overDuration: Yup.number()
                    .required(t('PleaseEnterDuration'))
                    .positive(t('PleaseEnterMins'))
                    .test('is-valid-duration', 'Enter Valid Duration', function (val) {
                        const { from } = this as any;
                        const { overStartTime } = from[0].value;
                        return Utils.CommonFunctions.isValidOverTimeDuration(val, from[1].value, overStartTime);
                    }),
            }),
        ),
    });
const AddStyleSchema = (t: any) =>
    Yup.object().shape({
        styleName: Yup.string()
            .trim()
            .required(t('Stylenameisrequired'))
            .max(50, `${t('MaximumCharactersLimitIs')} 50`),
        code: Yup.string()
            .trim()
            .required(t('StylecodeIsRequired'))
            .max(25, `${t('MaximumCharactersLimitIs')} 25`)
            .matches(/^\S*$/, t('NoSpaceAllowed')),
        description: Yup.string()
            .nullable()
            .max(250, `${t('MaximumCharactersLimitIs')} 250`),
        productTypeId: Yup.number().required(t('ProductTypeIsRequired')),
        // customer: Yup.string().required(t('CustomerIsRequired')),
        // season: Yup.string().required(t('SeasonIsRequired')),
    });
const AddOperationSchema = (t: any) =>
    Yup.object().shape({
        operationCodeId: Yup.string().required(t('OperationCodeisRequired')),
        description: Yup.string()
            .trim()
            .required(t('DescriptionIsRequired'))
            .max(300, `${t('MaximumCharactersLimitIs')} 300`),
        machine: Yup.string().required(t('MachineIsRequired')),
        needleType: Yup.string().required(t('NeedleTypeIsRequired')),
        smv: Yup.string().trim().required(t('SMVisRequired')),
    });
const AddOperatorSchema = (t: any) =>
    Yup.object().shape({
        lineId: Yup.string().required(t('Lineisrequired')),
        shiftId: Yup.string().required(t('ShiftIsRequired')),
        operatorName: Yup.string()
            .trim()
            .required(t('OperatorNameisRequired'))
            .max(50, `${t('MaximumCharactersLimitIs')} 50`),
        cardId: Yup.string()
            .trim()
            .required(t('CardIDisRequired'))
            .max(25, `${t('MaximumCharactersLimitIs')} 25`),
        employeeId: Yup.string()
            .required(t('EmployeeidIsRequired'))
            .max(25, `${t('MaximumCharactersLimitIs')} 25`)
            .matches(/^\S*$/, t('NoSpaceAllowed')),
        description: Yup.string().nullable(),
    });
const AddOperationCodeSchema = (t: any) =>
    Yup.object().shape({
        operationCode: Yup.string()
            .trim()
            .required(t('OperationCodeisRequired'))
            .max(25, `${t('MaximumCharactersLimitIs')} 25`)
            .matches(/^\S*$/, t('NoSpaceAllowed')),
        displayName: Yup.string()
            .trim()
            .required(t('DisplayNameisRequired'))
            .max(100, `${t('MaximumCharactersLimitIs')} 100`),
        firstLangData: Yup.string()
            .trim()
            .max(100, `${t('MaximumCharactersLimitIs')} 100`),
        secondLangData: Yup.string()
            .trim()
            .max(100, `${t('MaximumCharactersLimitIs')} 100`),
        smv: Yup.string()
            .typeError(t('Mustbeanumber'))
            .required(t('SMVisRequired'))
            .matches(/^(?!0\d|$)\d*(\.\d{1,4})?$/, 'Smv must be a number and cannot exceed four decimal place'),
        description: Yup.string()
            .required(t('DescriptionIsRequired'))
            .max(250, `${t('MaximumCharactersLimitIs')} 250`),
        skillTypeId: Yup.number().required(t('SkillTypeIsRequired')),
    });
const AddSkillSchema = (t: any) =>
    Yup.object().shape({
        operation: Yup.string().required(t('OperationIsRequired')),
        macSubTypeId: Yup.string().required(t('MachineIsRequired')),
        eff: Yup.string()
            .typeError(t('Mustbeanumber'))
            .trim()
            .required(t('EfficiencyIsRequired'))
            .matches(/^[0-9]\d{0,9}(\.\d{1,10})?%?$/, t('EntervalidEfficiency')),
        time: Yup.string(),
    });

const AddPpaSchema = (t: any) =>
    Yup.object().shape({
        ppaName: Yup.string()
            .trim()
            .required(t('FeatureNameIsRequired'))
            .max(35, `${t('MaximumCharactersLimitIs')} 35`),
        description: Yup.string()
            .trim()
            .max(250, `${t('MaximumCharactersLimitIs')} 250`),
        firstLang: Yup.string()
            .trim()
            .max(35, `${t('MaximumCharactersLimitIs')} 35`),
        secondLang: Yup.string()
            .trim()
            .max(35, `${t('MaximumCharactersLimitIs')} 35`),
    });
const MDMSchema = (t: any, selectedType: any) =>
    Yup.object().shape({
        nameType: Yup.string()
            .trim()
            .required(
                selectedType === 2
                    ? 'Product Type code is required'
                    : selectedType === 3
                    ? 'Machine Attachment code is required'
                    : selectedType === 4
                    ? 'Needles Attachment code is required'
                    : selectedType === 6
                    ? 'Machines is required'
                    : 'Skill Type  code is required',
            )
            .max(25, `${t('MaximumCharactersLimitIs')} 25`)
            .matches(/^\S*$/, t('NoSpaceAllowed')),
        description: Yup.string()
            .trim()
            .max(250, `${t('MaximumCharactersLimitIs')} 250`),
        displayName: Yup.string()
            .trim()
            .required('Display Name is required')
            .max(35, `${t('MaximumCharactersLimitIs')} 35`),
        firstLang: Yup.string()
            .trim()
            .max(35, `${t('MaximumCharactersLimitIs')} 35`),
        secondLang: Yup.string()
            .trim()
            .max(35, `${t('MaximumCharactersLimitIs')} 35`),
    });

const AddLineSetup = (t: any) =>
    Yup.object().shape({
        lineName: Yup.string()
            .trim()
            .required(t('NameIsRequired'))
            .max(50, `${t('MaximumCharactersLimitIs')} 50`),
        keepDefault: Yup.boolean(),
    });
const AddNPTCategory = (t: any) =>
    Yup.object().shape({
        displayName: Yup.string()
            .trim()
            .required(t('DisplayNameisRequired'))
            .max(35, `${t('MaximumCharactersLimitIs')} 35`),
        description: Yup.string()
            .trim()
            .nullable()
            .max(250, `${t('MaximumCharactersLimitIs')} 250`),
    });

const AddNPTType = (t: any) =>
    Yup.object().shape({
        nptType: Yup.string()
            .trim()
            .required(t('DisplayNameisRequired'))
            .max(35, `${t('MaximumCharactersLimitIs')} 35`),
        description: Yup.string()
            .trim()
            .nullable()
            .max(250, `${t('MaximumCharactersLimitIs')} 250`),
    });

const AddNPT = (t: any) =>
    Yup.object().shape({
        manpower: Yup.string()
            .trim()
            .required(t('ManpowerIsRequired'))
            .max(25, `${t('MaximumLimitIs')} 25`),
        date: Yup.string().trim().required(t('DateIsRequired')),
        startTime: Yup.string().trim().required(t('StarttimeIsRequired')).nullable(),
        endTime: Yup.string()
            .trim()
            .required(t('EndtimeIsRequired'))
            .test('match', 'End Time should be greater than Start Time', function (endTime) {
                //@ts-ignore
                return endTime > this.parent.startTime;
            }),
        // endTime: Yup.date()
        //     .required(t('EndtimeIsRequired'))
        //     .min(Yup.ref('startTime'), "End time can't be before start time"),
        lineId: Yup.string().trim().required(t('Lineisrequired')),
        categoryId: Yup.string().trim().required(t('CategoryIsRequired')),
        nptTypeId: Yup.string().trim().required(t('NPTtypeIsRequired')),
    });
const Schema = {
    LoginSchema,
    lineSetupSchema,
    AddStyleSchema,
    AddOperationSchema,
    AddOperatorSchema,
    AddSkillSchema,
    AddOperationCodeSchema,
    AddPpaSchema,
    AddLineSetup,
    AddNPTType,
    AddNPTCategory,
    AddNPT,
    EditShiftSchema,
    MDMSchema,
};

export default Schema;
