import axios from 'axios';
import moment from 'moment';
import Utils from '../../utils';
import { getUserIdToken } from '../../utils/session';

export const getStyleList = (limitManual?: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const {
            page,
            limit,
            search,
            sortBy,
            order,
            filterCustomerIds,
            filterFactoryIds,
            filterStatus,
            filterProductTypeIds,
            filterSeasonIds,
        } = getState().styleListReducer;

        let parameters = '';

        parameters = `?page=${page}&limit=${limit}${order.length > 0 ? `&order=${order}&sortBy=${sortBy}` : ''}${
            search.length > 0 ? `&search=${search}` : ''
        }${filterCustomerIds.length > 0 ? `&customerId=${filterCustomerIds.toString()}` : ''}${
            filterFactoryIds.length > 0 ? `&factoryId=${filterFactoryIds.toString()}` : ''
        }${filterStatus.length === 1 ? `&status=${filterStatus[0] === 'true' ? '1' : '0'}` : ''}${
            filterProductTypeIds.length > 0 ? `&productTypeId=${filterProductTypeIds.toString()}` : ''
        }${filterSeasonIds.length > 0 ? `&seasonId=${filterSeasonIds.toString()}` : ''}`;

        if (limitManual) {
            parameters = `?limit=${limitManual}&page=1`;
        }

        Utils.api.getApiCall(
            Utils.endPoints.styleList,
            parameters,
            (respData: any) => {
                let { data } = respData;
                // For users data
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;

                    dispatch({
                        type: Utils.ActionName.GET_STYLE_LIST,
                        payload: { data: innerData.data, page: innerData.page, count: innerData.count },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getProductType = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const { page } = getState().productTypeListReducer;

        let parameters = `?page=${page}`;

        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        Utils.api.getApiCall(
            Utils.endPoints.productMasterData,
            parameters,
            (respData: any) => {
                const { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.GET_PRODUCT_TYPE_LIST,
                        payload: { productTypes: data.data.data },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                const { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getCustomers = () => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = '';

        Utils.api.getApiCall(
            Utils.endPoints.customers,
            parameters,
            (respData: any) => {
                let { data } = respData;

                // For users data
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.GET_CUSTOMER_LIST,
                        payload: { customers: data.data },
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getSeasons = () => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = '';

        Utils.api.getApiCall(
            Utils.endPoints.season,
            parameters,
            (respData: any) => {
                let { data } = respData;
                // For users data
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.GET_SEASON_LIST,
                        payload: { seasons: data.data.data },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getStyleDetail = (id: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.GET_STYLE_DETAIL,
            payload: {
                id: '',
                brand: '',
                season: '',
                desc1: '',
                image1: null,
                image2: null,
                image3: null,
                createdBy: '',
                createdDt: '',
                updatedBy: '',
                updatedDt: '',
                code: '',
                style: '',
                customerId: '',
                seasonId: '',
                customer: '',
                createdById: '',
                sam: '',
                totalSmv: '',
                title: '',
                productTypeId: '',
                productType: '',
                isActive: false,
            },
        });
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = `?id=${id}`;

        Utils.api.getApiCall(
            Utils.endPoints.styleDetail,
            parameters,
            (respData: any) => {
                let { data } = respData;
                // For users data
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    let tempHostedImages: any = [];
                    if (innerData.image1 !== null) {
                        tempHostedImages.push(innerData.image1);
                    }
                    if (innerData.image2 !== null) {
                        tempHostedImages.push(innerData.image2);
                    }
                    if (innerData.image3 !== null) {
                        tempHostedImages.push(innerData.image3);
                    }
                    dispatch({
                        type: Utils.ActionName.GET_STYLE_DETAIL,
                        payload: {
                            id: innerData.id,
                            brand: innerData.brand,
                            season: innerData.season,
                            desc1: innerData.desc1,
                            image1: innerData.image1,
                            image2: innerData.image2,
                            image3: innerData.image3,
                            createdBy: innerData.createdBy,
                            createdDt: innerData.createdDt,
                            updatedBy: innerData.updatedBy,
                            updatedDt: innerData.updatedDt,
                            code: innerData.code,
                            style: innerData.style,
                            customerId: innerData.customerId,
                            seasonId: innerData.seasonId,
                            customer: innerData.brand,
                            createdById: innerData.createdById,
                            sam: innerData.sam,
                            totalSmv: innerData.totalSmv,
                            title: innerData.title,
                            editHostedImages: tempHostedImages,
                            productTypeId: innerData.productTypeId,
                            productType: innerData.productTypeData?.productTypeName,
                            isActive: innerData.isActive,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.GET_CUSTOMER_LIST,
                        payload: {
                            selectedCustomer: innerData.brand,
                            selectedCustomerId: innerData.customerId,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.GET_PRODUCT_TYPE_LIST,
                        payload: {
                            selectedProduct: innerData.productTypeData?.productTypeName,
                            selectedProductId: innerData.productTypeId,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getLineSetupHistory = (styleId?: any) => {
    return (dispatch: Function, getState: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();

        const {
            limit,
            page,
            search,
            filterCustomerIds,
            filterLineIds,
            filterShiftIds,
            order,
            sortBy,
            filterStatusHistory,
        } = getState().lineSetupHistoryTableReducer;

        let parameters = `?page=${page}&limit=${limit}${search.length > 0 ? `&search=${search}` : ''}${
            styleId ? `&styleId=${styleId}` : ''
        }${order.length > 0 ? `&order=${order}&sortBy=${sortBy}` : ''}${
            filterCustomerIds.length > 0 ? `&customerId=${filterCustomerIds.toString()}` : ''
        }${filterLineIds.length > 0 ? `&lineId=${filterLineIds.toString()}` : ''}${
            filterShiftIds.length > 0 ? `&shiftId=${filterShiftIds.toString()}` : ''
        }${filterStatusHistory.length === 1 ? `&statusId=${filterStatusHistory[0]}` : ''} `;
        Utils.api.getApiCall(
            Utils.endPoints.lineSetup,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.UPDATE_LINE_SETUP_HISTORY_TABLE,
                        payload: {
                            historyData: innerData.data,
                            page: innerData.page,
                            count: innerData.count,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};
// here in add Style 1st parameter "values" are of new operations when adding a style we are providing a 1 operation with it.
export const addStyle = (
    values: any,
    setSubmitting: any,
    history: any,
    styleName: any,
    styleCode: any,
    description: any,
    image1: any,
    image2: any,
    image3: any,
    selectedSeason: any,
    selectedSeasonId: any,
    selectedCustomer: any,
    selectedCustomerId: any,
    selectedProduct: any,
    selectedProductId: any,
    handleClose: any,
) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            setSubmitting(false);
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const { hostedImages } = getState().newStyleReducer;

        const dataToSend = {
            style: styleName,
            desc1: description === '' ? null : description,
            image1: hostedImages[0] ? hostedImages[0] : null,
            image2: hostedImages[1] ? hostedImages[1] : null,
            image3: hostedImages[2] ? hostedImages[2] : null,
            seasonId: selectedSeasonId === '' ? null : selectedSeasonId,
            title: styleName,
            code: styleCode,
            customer: selectedCustomer === '' ? null : selectedCustomer,
            season: selectedSeason === '' ? null : selectedSeason,
            customerId: selectedCustomerId === '' ? null : selectedCustomerId,
            productTypeId: selectedProductId,
            productTypeName: selectedProduct,
        };
        Utils.api.postApiCall(
            Utils.endPoints.styleList,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.NEW_STYLE,
                        payload: {
                            styleIdAfterAdding: data.data.id,
                        },
                    });
                    localStorage.setItem('styleAddedId', data.data.id);
                    Utils.showAlert(1, data.message);
                    dispatch(addFirstTimeOperationWithStyle(setSubmitting, history, values, data.data.id));
                    handleClose();
                    setSubmitting(false);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                setSubmitting(false);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const addFirstTimeOperationWithStyle = (setSubmitting: any, history: any, values: any, styleId: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            setSubmitting(false);
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const {
            operationcode,
            operationCodeId,
            description: operationDescription,
            machine,
            needleType,
            smv,
            tct,
            machineName,
            needleTypeName,
            skillTypeName,
            skillTypeCode,
            skillTypeId,
            images,
            imageIds,
            imageCodes,
        } = values;

        const dataToSend = {
            operationId: operationCodeId,
            operation: operationcode,
            styleId,
            machine: machineName,
            macSubTypeId: machine,
            needleType: needleTypeName,
            needleTypeId: needleType,
            smv,
            tct,
            desc1: operationDescription,
            step: 1,
            skillTypeId,
            skillTypeName,
            skillTypeCode,
            machineAttachmentId1: imageIds[0],
            machineAttachmentId2: imageIds[1],
            machineAttachmentId3: imageIds[2],
            machineAttachmentCode1: imageCodes[0],
            machineAttachmentCode2: imageCodes[1],
            machineAttachmentCode3: imageCodes[2],
            machineAttachmentImage1: images[0],
            machineAttachmentImage2: images[1],
            machineAttachmentImage3: images[2],
        };
        Utils.api.postApiCall(
            Utils.endPoints.operationList,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    setSubmitting(false);
                    dispatch(getSweingOperationForAddStyle(styleId));
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                setSubmitting(false);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

const groupBy = (array: any, property: any) => {
    let hash: any = {};
    for (var i = 0; i < array.length; i++) {
        if (!hash[array[i][property]]) hash[array[i][property]] = [];
        hash[array[i][property]].push(array[i]);
    }
    return hash;
};

export const getSweingOperation = (id: any) => {
    return (dispatch: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.UPDATE_SEWING_OPERATION,
            payload: {
                sewingData: [],
                page: 1,
            },
        });
        Utils.CommonFunctions.setAuthorizationToken();
        let parameters = `?styleId=${id}`;
        Utils.api.getApiCall(
            Utils.endPoints.operationList,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data.data;
                    const featureWiseOperations = groupBy(innerData, 'feature');

                    dispatch({
                        type: Utils.ActionName.UPDATE_SEWING_OPERATION,
                        payload: {
                            sewingData: innerData,
                            page: data.data.page,
                            featureWiseOperations,
                            totalSmv: data.data.totalSmv,
                            defaultDataOfStyle: innerData,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.UPDATE_SEWING_OPERATION_FOR_ADD_STYLE,
                        payload: {
                            sewingData: innerData,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getSweingOperationPostReorder = (id: any) => {
    return (dispatch: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.UPDATE_SEWING_OPERATION,
            payload: {
                sewingData: [],
                page: 1,
                postReorderRequest: true,
            },
        });
        Utils.CommonFunctions.setAuthorizationToken();
        let parameters = `?styleId=${id}`;
        Utils.api.getApiCall(
            Utils.endPoints.operationList,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data.data;
                    const featureWiseOperations = groupBy(innerData, 'feature');

                    dispatch({
                        type: Utils.ActionName.UPDATE_SEWING_OPERATION,
                        payload: {
                            sewingData: innerData,
                            page: data.data.page,
                            featureWiseOperations,
                            totalSmv: data.data.totalSmv,
                            editActive: false,
                            reorder: false,
                        },
                    });

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getSweingOperationForAddStyle = (id?: any) => {
    return (dispatch: Function, getState: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.UPDATE_SEWING_OPERATION_FOR_ADD_STYLE,
            payload: {
                sewingData: [],
                page: 1,
            },
        });
        Utils.CommonFunctions.setAuthorizationToken();
        let parameters = '';
        if (id) {
            parameters = `?styleId=${id}`;
        }

        Utils.api.getApiCall(
            Utils.endPoints.operationList,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.UPDATE_SEWING_OPERATION_FOR_ADD_STYLE,
                        payload: {
                            sewingData: innerData.data,
                            page: innerData.page,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getNeedleTypesList = () => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = '';

        Utils.api.getApiCall(
            Utils.endPoints.needleTypeList,
            parameters,
            (respData: any) => {
                let { data } = respData;
                // For users data coming
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.NEEDLE_LIST,
                        payload: { needlesTypes: data.data.data },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getMachineSubtypeList = () => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = '';

        Utils.api.getApiCall(
            Utils.endPoints.machineSubtypeList,
            parameters,
            (respData: any) => {
                let { data } = respData;
                // For users data
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.MACHINE_LIST,
                        payload: { machines: data.data.data },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const deleteOperation = (operationId: any, styleid: any, sewingData: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const dataToSend = '';
        Utils.api.deleteApiCall(
            `${Utils.endPoints.operationList}?styleId=${styleid}&operationId=${operationId}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);

                    const sewingDataDeepCopy = JSON.parse(JSON.stringify(sewingData));
                    const index = sewingDataDeepCopy.findIndex((data: any) => data.id === operationId);
                    if (index > -1) {
                        sewingDataDeepCopy.splice(index, 1);
                    }
                    dispatch({
                        type: Utils.ActionName.UPDATE_SEWING_OPERATION_FOR_ADD_STYLE,
                        payload: {
                            sewingData: sewingDataDeepCopy,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const addOperation = (setSubmitting: any, history: any, values: any, styleId: any, handleClose: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            setSubmitting(false);
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const {
            operationCodeId,
            operationcode,
            description: operationDescription,
            machine,
            needleType,
            smv,
            tct,
            machineName,
            needleTypeName,
            selectedOperationStep,
            skillTypeName,
            skillTypeCode,
            skillTypeId,
            images,
            imageIds,
            imageCodes,
        } = values;

        const dataToSend = {
            operationId: operationCodeId,
            operation: operationcode,
            styleId,
            machine: machineName,
            macSubTypeId: machine,
            needleType: needleTypeName,
            needleTypeId: needleType,
            smv,
            tct,
            desc1: operationDescription,
            step: selectedOperationStep,
            skillTypeId,
            skillTypeName,
            skillTypeCode,
            machineAttachmentId1: imageIds[0],
            machineAttachmentId2: imageIds[1],
            machineAttachmentId3: imageIds[2],
            machineAttachmentCode1: imageCodes[0],
            machineAttachmentCode2: imageCodes[1],
            machineAttachmentCode3: imageCodes[2],
            machineAttachmentImage1: images[0],
            machineAttachmentImage2: images[1],
            machineAttachmentImage3: images[2],
        };
        Utils.api.postApiCall(
            Utils.endPoints.operationList,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    setSubmitting(false);
                    dispatch(getSweingOperationForAddStyle(styleId));
                    handleClose();
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                setSubmitting(false);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const editStyle = (values: any, setSubmitting: any, history: any, handleClose: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            setSubmitting(false);
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const {
            description,
            image1,
            image2,
            image3,
            seasonId,
            title,
            code,
            customer,
            season,
            customerId,
            styleId,
            productTypeId,
            productTypeName,
        } = values;
        const dataToSend = {
            style: title.trim(),
            desc1: description,
            image1: image1 === '' ? null : image1,
            image2: image2 === '' ? null : image2,
            image3: image3 === '' ? null : image3,
            season: season,
            seasonId: seasonId,
            customer: customer,
            customerId: customerId,
            title: title,
            code: code,
            productTypeId,
            productTypeName,
        };

        Utils.api.putApiCall(
            `${Utils.endPoints.styleList}?id=${styleId}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    setSubmitting(false);

                    Utils.showAlert(1, data.message);
                    handleClose();
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });

                    history.push('/manage-style/style-list');
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                setSubmitting(false);
                let { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const editOperation = (values: any, setSubmitting: any, history: any, handleClose: any, styleId: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            setSubmitting(false);
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const {
            operationId,
            operationcode,
            operationCodeId,
            description,
            machine,
            needleType,
            smv,
            tct,
            machineName,
            needleTypeName,
        } = values;
        const {
            skillTypeId,
            skillTypeName,
            skillTypeCode,
            machineAttachmentId1,
            machineAttachmentId2,
            machineAttachmentId3,
            machineAttachmentCode1,
            machineAttachmentCode2,
            machineAttachmentCode3,
            machineAttachmentImage1,
            machineAttachmentImage2,
            machineAttachmentImage3,
        } = values.operation;
        const dataToSend = {
            operation: operationcode,
            operationId: operationCodeId,
            styleId,
            machine: machineName,
            macSubTypeId: machine,
            needleType: needleTypeName,
            needleTypeId: needleType,
            smv,
            tct,
            desc1: description,
            skillTypeId,
            skillTypeName,
            skillTypeCode,
            machineAttachmentId1,
            machineAttachmentId2,
            machineAttachmentId3,
            machineAttachmentCode1,
            machineAttachmentCode2,
            machineAttachmentCode3,
            machineAttachmentImage1,
            machineAttachmentImage2,
            machineAttachmentImage3,
        };

        Utils.api.putApiCall(
            `${Utils.endPoints.operationList}?id=${operationId}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch(getSweingOperationForAddStyle(styleId));
                    setSubmitting(false);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    handleClose();
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                setSubmitting(false);
                let { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const reorderOperations = (setSubmitting: any, operationIds: any, styleId: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            setSubmitting(false);
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        dispatch({
            type: Utils.ActionName.UPDATE_SEWING_OPERATION,
            payload: {
                reorder: true,
                editActive: false,
            },
        });

        const dataToSend = {
            operationIds,
        };

        Utils.api.putApiCall(
            Utils.endPoints.reorderOperations,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    setSubmitting(false);
                    dispatch(getSweingOperationPostReorder(styleId));
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }

                dispatch({
                    type: Utils.ActionName.NEW_STYLE,
                    payload: {
                        hostedImages: [],
                        description: '',
                        image1: '',
                        image2: '',
                        image3: '',
                        styleCode: '',
                        styleIdAfterAdding: '',
                        styleName: '',
                        productTypeId: '',
                    },
                });
            },
            (error: any) => {
                setSubmitting(false);
                let { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
                dispatch({
                    type: Utils.ActionName.NEW_STYLE,
                    payload: {
                        hostedImages: [],
                        description: '',
                        image1: '',
                        image2: '',
                        image3: '',
                        styleCode: '',
                        styleIdAfterAdding: '',
                        styleName: '',
                        productTypeId: '',
                    },
                });
            },
        );
    };
};

export const getPPA = () => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = '';

        Utils.api.getApiCall(
            Utils.endPoints.ppaMaster,
            parameters,
            (respData: any) => {
                let { data } = respData;
                // For users data
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.GET_MASTER_PPA,
                        payload: { PPAData: data.data.data },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const updatePPA = (operationId: any, ppa: any, ppaName: any, styleId: any, operations: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const dataToSend = {
            operationId,
            ppa: ppaName === 'null' ? null : ppaName,
            ppaId: ppa === 'null' ? null : ppa,
        };

        Utils.api.putApiCall(
            Utils.endPoints.editPPA,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });

                    const sewingDataDeepCopy = JSON.parse(JSON.stringify(operations));
                    const index = sewingDataDeepCopy.findIndex((data: any) => data.id === operationId);
                    if (index > -1) {
                        sewingDataDeepCopy[index].feature = ppa;
                    }
                    dispatch({
                        type: Utils.ActionName.UPDATE_SEWING_OPERATION_FOR_ADD_STYLE,
                        payload: {
                            sewingData: sewingDataDeepCopy,
                        },
                    });
                    dispatch(getSweingOperation(styleId));
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getFactoriesList = () => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = '';

        Utils.api.getApiCall(
            Utils.endPoints.factoriesList,
            parameters,
            (respData: any) => {
                let { data } = respData;
                // For users data
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.GET_FACTORIES_LIST,
                        payload: {
                            factoriesData: data.data.data,
                            limit: data.data.limit,
                            count: data.data.count,
                            page: data.data.page,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const saveAsStyle = (history: any, styleId: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const dataToSend = {};

        Utils.api.postApiCall(
            `${Utils.endPoints.saveAsStyle}?id=${styleId}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, 'New style is created, you may update the details');
                    history.push(`/manage-style/edit-style?id=${data.data.id}`);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const deleteLineSetup = (styleId: any, id: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const dataToSend = '';
        Utils.api.deleteApiCall(
            `${Utils.endPoints.deleteLineSetup}?id=${id}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch(getLineSetupHistory(styleId));
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

// here in add Style 1st parameter "values" are of new operations when adding a style we aare providing a 1 operation with it.
export const saveAsNewStyle = (
    setSubmitting: any,
    history: any,
    styleName: any,
    styleCode: any,
    description: any,
    image1: any,
    image2: any,
    image3: any,
    selectedSeason: any,
    selectedSeasonId: any,
    selectedCustomer: any,
    selectedCustomerId: any,
    productTypeId: number,
    selectedProduct: any,
    copyFromId?: any,
) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            setSubmitting(false);
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const { editHostedImages } = getState().styleDetailReducer;

        const dataToSend = {
            style: styleName,
            desc1: description,
            image1: editHostedImages[0] ? editHostedImages[0] : null,
            image2: editHostedImages[1] ? editHostedImages[1] : null,
            image3: editHostedImages[2] ? editHostedImages[2] : null,
            seasonId: selectedSeasonId,
            title: styleName,
            code: styleCode,
            customer: selectedCustomer,
            season: selectedSeason,
            customerId: selectedCustomerId,
            ...(copyFromId !== '' && copyFromId && { copyFromId }),
            productTypeId: productTypeId,
            productTypeName: selectedProduct,
        };
        Utils.api.postApiCall(
            Utils.endPoints.styleList,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    setSubmitting(false);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    history.push('/manage-style/style-list');
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                setSubmitting(false);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const validateStyleBulkUpload = (
    operations: any,
    setFirstStepLoader: any,
    handleNext: any,
    errorCallBack: Function,
) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        var dataUpload = new FormData();
        dataUpload.append('styles', operations);

        const dataToSend = dataUpload;
        Utils.api.postApiCall(
            Utils.endPoints.validateStyleBulkUpload,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.UPDATE_SKILL_LIST,
                        payload: {
                            totalRecord: data.data.totalRecords,
                            duplicateRecord: data.data.duplicateRecords,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    Utils.showAlert(1, data.message);
                    errorCallBack('');
                    setFirstStepLoader(false);
                    handleNext();
                } else {
                    errorCallBack(data.message);
                    setFirstStepLoader(false);
                }
            },
            (error: any) => {
                let { data } = error;
                errorCallBack(data.message);
                setFirstStepLoader(false);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const bulkUploadStyle = (setSecondStepLoader: any, handleNext: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const { wholeFile } = getState().skillReducer;

        var dataUpload = new FormData();
        dataUpload.append('styles', wholeFile);
        setSecondStepLoader(true);
        const dataToSend = dataUpload;
        Utils.api.postApiCall(
            Utils.endPoints.bulkUploadStyle,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.UPDATE_SKILL_LIST,
                        payload: {
                            totalRecord: data.data.totalRecords,
                            duplicateRecord: data.data.duplicateRecords,
                        },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });

                    dispatch(getStyleList());

                    Utils.showAlert(1, data.message);
                    setSecondStepLoader(false);
                    handleNext();
                } else {
                    Utils.showAlert(2, data.message);
                    setSecondStepLoader(false);
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                setSecondStepLoader(false);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const handleDownloadStyles = async () => {
    let date = moment().format('YYYY-MM-DD');
    const d = new Date();
    const offsetValue = -1 * d.getTimezoneOffset();

    const req = await axios({
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getUserIdToken()}`,
            offsetValue: offsetValue,
        },
        method: 'get',
        url: Utils.constants.apiUrl + `user/style/download-style`,
        responseType: 'blob',
    });
    var blob = new Blob([req.data], {
        type: req.headers['content-type'],
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `latestStyles_${date}.xlsx`;
    link.click();
};

export const handleDownloadStylesTemplate = async () => {
    const d = new Date();
    const offsetValue = -1 * d.getTimezoneOffset();

    const req = await axios({
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getUserIdToken()}`,
            offsetValue: offsetValue,
        },
        method: 'get',
        url: Utils.constants.apiUrl + `user/style/download-style-template-file`,
        responseType: 'blob',
    });
    var blob = new Blob([req.data], {
        type: req.headers['content-type'],
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `Styles_Template.xlsx`;
    link.click();
};

export const editCurrentStyle = (values: any, setSubmitting: any, history: any, handleClose: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            setSubmitting(false);
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const { editHostedImages } = getState().styleDetailReducer;
        const {
            description,
            seasonId,
            title,
            code,
            customer,
            season,
            customerId,
            styleId,
            productTypeId,
            productTypeName,
            isActive,
        } = values;
        const dataToSend = {
            style: title.trim(),
            desc1: description === '' ? null : description,
            image1: editHostedImages[0] ? editHostedImages[0] : null,
            image2: editHostedImages[1] ? editHostedImages[1] : null,
            image3: editHostedImages[2] ? editHostedImages[2] : null,
            season: season === '' ? null : season,
            seasonId: seasonId === '' ? null : seasonId,
            customer: customer === '' ? null : customer,
            customerId: customerId === '' ? null : customerId,
            title: title,
            code: code,
            productTypeId: productTypeId,
            productTypeName,
            isActive: isActive,
        };

        Utils.api.putApiCall(
            `${Utils.endPoints.styleList}?id=${styleId}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    setSubmitting(false);
                    Utils.showAlert(1, data.message);
                    handleClose();
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });

                    history.push(`/manage-style/style-information?id=${styleId}`);
                } else {
                    setSubmitting(false);
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                setSubmitting(false);
                let { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const cancelUpdatedStyle = (styleId: any, history: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            return;
        }
        const { defaultDataOfStyle } = getState().sewingOperationReducer;

        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const dataToSend = { data: defaultDataOfStyle };

        Utils.api.putApiCall(
            `${Utils.endPoints.cancelStyle}?styleId=${styleId}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });

                    history.push(`/manage-style/style-information?id=${styleId}`);
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

/**
 * funtion to upload image
 */

export const uploadImage = (file: any, type: any, screen: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const { editHostedImages } = getState().styleDetailReducer;
        const { hostedImages } = getState().newStyleReducer;

        let dataToSend = {};
        if (type === 1) {
            const imageArray: any = [];
            file.forEach((element: any) => {
                imageArray.push({
                    name: `images/${element.name}`,
                    type: element.type,
                });
            });
            dataToSend = {
                files: imageArray,
            };
        } else {
            const imageNames: any = [];
            file.forEach((elem: any) => {
                imageNames.push(`images/${elem.name}`);
            });

            dataToSend = {
                files: imageNames,
            };
        }
        Utils.api.postApiCall(
            type === 1 ? Utils.endPoints.imageUpload : Utils.endPoints.imageDownload,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    if (type === 1) {
                        dispatch(imageCompilation(file, data.data, screen));
                    } else {
                        if (screen === 1) {
                            dispatch({
                                type: Utils.ActionName.NEW_STYLE,
                                payload: {
                                    hostedImages: [...hostedImages, ...data.data],
                                },
                            });
                        } else if (screen === 2) {
                            dispatch({
                                type: Utils.ActionName.GET_STYLE_DETAIL,
                                payload: {
                                    editHostedImages: [...editHostedImages, ...data.data],
                                },
                            });
                        } else {
                            dispatch({
                                type: Utils.ActionName.UPDATE_PPA_CODE,
                                payload: {
                                    hostedImages: [data.data[0]],
                                },
                            });
                        }
                    }
                    Utils.showAlert(1, 'Image uploaded!');

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

/**
 * function to upload image to global admin
 */
export const uploadImageToGlobalAdmin = (files: any) => {
    return new Promise((resolve, reject) => {
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            reject('No internet connection');
            return;
        }

        Utils.CommonFunctions.setAuthorizationToken();
    
        const pathURL = Utils.endPoints.globalAdminImageUpload;
        const data = new FormData();
        data.append('image', files[0]);
    
        Utils.api.postApiGlobalCall(pathURL, data, ({ data }: any) => {
                const imageUrl = data.data;
                resolve(imageUrl);
            }, (err: any) => {
                console.error(err);
                reject(err);
            }
        );
    });
};

export const modifyStyleImage = (fileURL: any, isEdit = false) => {
    return (dispatch: Function, getState: Function) => {
        const { editHostedImages } = getState().styleDetailReducer;
        const { hostedImages } = getState().newStyleReducer;

        if (!isEdit) {
            dispatch({
                type: Utils.ActionName.NEW_STYLE,
                payload: {
                    hostedImages: [...hostedImages, fileURL],
                },
            });

            Utils.showAlert(1, 'Image uploaded!');

            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
        } else {
            dispatch({
                type: Utils.ActionName.GET_STYLE_DETAIL,
                payload: {
                    editHostedImages: [...editHostedImages, fileURL],
                },
            });
            
            Utils.showAlert(1, 'Image uploaded!');

            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
        }
    }
}

export const imageCompilation = (fileObjArr: [], responseUrl: [], screen: any) => (dispatch: any) => {
    let count = 0;
    try {
        fileObjArr.forEach(async (fileObj: File, index: number) => {
            count += 1;
            const xmlHttpRequest = new XMLHttpRequest();

            xmlHttpRequest.open('PUT', responseUrl[index], true);
            xmlHttpRequest.setRequestHeader('Content-Type', fileObj.type);
            xmlHttpRequest.send(fileObj);
        });
        if (count === fileObjArr.length) {
            dispatch(uploadImage(fileObjArr, 2, screen));
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
        }
    } catch (err) {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: false,
        });
    }
};
