import React from 'react';
import { 
  MenuList, 
  MenuItem, 
  Box, 
  Popover,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { 
  LBRCustomSelectButton, 
  LBRFlexContainer, 
} from './../styles';
import { OptionProp } from './../Interfaces';
import { LBRLegen } from '../Icons';

const LBRCustomSelect: React.FC<OptionProp> = ({
  label = 'name',
  key = 'id',
  defaultValue = null,
  data,
  onChange,
  isLoading = false,
}) => {

  const [selected, setSelected] = React.useState<any>(null);
  const [display, setDisplay] = React.useState<any>(null);
  const [displayLegend, setDisplayLegen] = React.useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleSelect = (value: string) => {
    const selected: any = data?.find((item: any) => item[key] === value);
    setDisplay(selected[label] || null);
    setDisplayLegen(selected['icon'] ? ({
      color: selected['color'],
      type: selected['type'],
    }) : null);
    setSelected(selected[key]);
    onChange(selected[key]);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'lbr-popover' : undefined;

  React.useEffect(() => {
    if (defaultValue) {
      const selected = data?.find((item: any) => item['id'] === defaultValue);
      setDisplay(selected[label] || null);
      setDisplayLegen(selected['icon'] ? ({
        color: selected['color'],
        type: selected['type'],
      }) : null);
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Box>
      <LBRCustomSelectButton
        disabled={isLoading}
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        aria-describedby={id}
        variant='outlined'
        onClick={(event: any) => handleClick(event)}
      >
        <LBRFlexContainer>
          {displayLegend && <LBRLegen color={displayLegend.color} type={displayLegend.type} />}
          {display || 'Choose Option'}
        </LBRFlexContainer>
      </LBRCustomSelectButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: 'right'
        }}
      >
        <MenuList
          style={{ minWidth: 150 }}
          id='menu-list-grow'
          onKeyDown={() => {}}
        >
          {data?.map((item) => (
            <MenuItem
              selected={selected === item[key]}
              key={item[key]}
              onClick={() => handleSelect(item[key])}
            >
              <LBRFlexContainer>
                {item.icon && <LBRLegen color={item.color} type={item.type} />}
                {item[label]}
              </LBRFlexContainer>
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </Box>
  );
};

export default LBRCustomSelect;
