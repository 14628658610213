import React from 'react';
import { 
  FormControlLabel, 
  Radio, 
  RadioGroup,
} from '@material-ui/core';
import { OptionProp } from './../Interfaces';

const LBRCustomRadio: React.FC<OptionProp> = ({
  data = [],
  defaultValue = null,
  key = 'id',
  label = 'name',
  onChange,
  isLoading = false,
}) => {

  const [selected, setSelected] = React.useState<any>(null);
  const handleSelect = (value: any) => {
    setSelected(value);
    onChange(value);
  };
  React.useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  return (
    <RadioGroup row defaultValue='top'>
      {data?.map((item) => (
        <FormControlLabel
          disabled={isLoading}
          onChange={() => handleSelect(item[key])}
          checked={item[key] === selected}
          value={item[key]}
          control={<Radio color='primary' />}
          label={item[label]}
        />
      ))}
    </RadioGroup>
  );
};

export default LBRCustomRadio;
