import React, { useEffect, useRef } from 'react';
import Utils from '../../../utils';
import LocalImages from '../../../utils/images';
import {
    makeStyles,
    createStyles,
    Theme,
    Typography,
    Button,
    TextField,
    InputAdornment,
    TableContainer,
    Table,
    TableRow,
    withStyles,
    TableHead,
    TableBody,
    Divider,
    Menu,
    Avatar,
    TablePagination,
} from '@material-ui/core';
//@ts-ignore
import Highlighter from 'react-highlight-words';
import MuiTableCell from '@material-ui/core/TableCell';
import AddIcon from '@material-ui/icons/Add';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import MultimediaButton from '../../../components/buttons/multimediaButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SimpleModal from '../../../components/modal';
import AddOperator from './addOperator';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModel } from '../../../model';
import moment from 'moment';
import BulkOperatorUpload from './bulkOperatorUpload';
import { getOperator, getSkill, getTimestudyOperator } from '../action';
import ManageOperatorFilter from '../../../components/filters/manageOperator';
import { useTranslation } from 'react-i18next';

const TableCell = withStyles({
    root: {
        borderBottom: 'none',
    },
})(MuiTableCell);

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-around',
        },
        headStyl: {
            marginTop: '25px',
            marginBottom: '14px',
            textAlign: 'center',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#707070',
            opacity: 1,
        },
        heading: {
            font: 'normal normal normal 20px/24px Roboto',
            letterSpacing: '0.07px',
            color: '#515151',
            opacity: 1,
            marginBottom: '18px',
        },
        styleOperations: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '18px',
        },
        leftStyleOperations: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            marginBottom: '10px',
        },
        searchInput: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            width: '180px',
            '& .MuiInputBase-root': {
                height: '36px',
                font: 'normal normal normal 14px/20px Roboto',
                letterSpacing: '0.05px',
                color: '#515151',
                opacity: 1,
            },
        },
        searchIcon: {
            cursor: 'pointer',
        },
        addButton: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            opacity: 1,
            margin: '0 15px',
            border: '1px solid #1E88E5',
            font: 'normal normal normal 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#1E88E5',
            textTransform: 'uppercase',
            height: '36px',
            width: '72px',
        },
        filterButton: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            opacity: 1,
            border: '1px solid #DFDFDF',
            font: 'normal normal normal 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: 'var(--title-light)',
            textTransform: 'uppercase',
            height: '36px',
        },
        categories: {
            padding: '7px',
            letterSpacing: '0.08px',
            textTransform: 'uppercase',
            opacity: 1,
            background: '#D2D2D2 0% 0% no-repeat ',
            wordBreak: 'keep-all',
            '& th': {
                font: 'normal normal normal 13px/21px Roboto',
                padding: '5px 5px',
                color: 'var(--input-fill)',
                verticalAlign: 'top',
                backgroundColor: '#D2D2D2',
                '& > span': {
                    cursor: 'pointer',
                },
            },
            '&>th:nth-child(1)': {
                width: '16%',
                maxWidth: '16%',
                [theme.breakpoints.between(700, 1380)]: {
                    whiteSpace: 'nowrap',
                },
            },
            '&>th:nth-child(2)': {
                width: '5%',
                minWidth: '5%',
            },
            '&>th:nth-child(3)': {
                width: '8%',
                minWidth: '8%',
            },
            '&>th:nth-child(4)': {
                width: '10%',
                minWidth: '10%',
            },
            '&>th:nth-child(5)': {
                width: '15%',
                maxWidth: '15%',
            },
            '&>th:nth-child(6)': {
                width: '10%',
                maxWidth: '10%',
            },
            '&>th:nth-child(7)': {
                width: '10%',
                maxWidth: '10%',
            },
            '&>th:nth-child(8)': {
                width: '24%',
                maxWidth: '24%',
            },
        },
        categoriesItems: {
            backgroundColor: 'var(--white)',
            margin: '5px 0',
            cursor: 'pointer',
            borderLeft: 'solid 5px rgb(30, 136, 229, 0)',
            '&:hover': {
                borderLeft: 'solid 5px rgb(30, 136, 229)',

                '&>td:nth-child(8)': {
                    '& svg': {
                        color: 'var(--blue)',
                        transition: '1s all',
                    },
                },
            },
            '&>td:nth-child(8)': {
                '& svg': {
                    cursor: 'pointer',
                    color: 'transparent',
                    width: '30px',
                },
            },
            '&>td:nth-child(2)': {
                textTransform: 'uppercase',
            },
            '&>td:nth-child(3)': {
                textTransform: 'uppercase',
            },
            '& td': {
                font: 'normal normal normal 14px/21px Roboto',
                padding: '10px 5px',
                color: 'var(--input-fill)',
                letterSpacing: '0.08px',
                opacity: 1,
                wordBreak: 'break-word',
                verticalAlign: 'top',
            },
            '&>td:nth-child(5)': {
                textTransform: 'uppercase',
            },
        },
        inActive: {
            font: 'italic normal normal 14px/21px Roboto',
            padding: '10px 5px',
            color: 'var(--dark-grey)',
            letterSpacing: '0.08px',
            opacity: 0.6,

            wordBreak: 'break-word',
            '&>td': {
                fontStyle: 'italic',
            },
        },
        icnStyl: {
            cursor: 'pointer',
            color: 'var(--blue)',
            width: '13px',
        },
        dwnIcnStyl: {
            cursor: 'pointer',
            width: '13px',
            verticalAlign: 'middle',
            transition: '1s all',
        },
        rotateIcon: {
            transform: 'rotate(180deg)',
            transition: '1s all',
        },
        shuftSelect: {
            width: '15%',
            margin: '0 30px 10px 0',
        },
        upperContainer: {
            backgroundColor: 'var(--white)',
            padding: '18px 16px',
            marginBottom: '10px',
        },
        divideStyle: {
            height: '10px',
            backgroundColor: 'transparent',
        },
        operatorTable: {
            '& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover': {
                backgroundColor: '#DDEDFB',
            },
        },
        filterMenu: {
            '& .MuiList-root': {
                width: '194px',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: '0px 0px 6px #00000029',
                opacity: 1,
            },
        },
        filterCount: {
            minWidth: '24px',
            minHeight: '24px',
            width: '1em',
            height: '1em',
            textAlign: 'center',
            font: 'normal normal normal 14px/20px Roboto!important',
            letterSpacing: '0.05px',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            opacity: 1,
            backgroundColor: '#1E88E5',
        },
        tableContainer: {
            overflow: 'scroll !important',
            maxHeight: '68vh',
            paddingBottom: '36px',
        },
        paginationContainer: {
            position: 'fixed',
            width: '46.1%',
            bottom: '0',
            background: 'white',
        },
    }),
);

export default function OperatorListTable() {
    const classes = styles();
    const { t } = useTranslation();

    // for add operator
    const [open, setOpen] = React.useState(false);
    const [isBulk, setIsBulk] = React.useState(false);
    const [isSort, setIsSort] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // for filter
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: Utils.ActionName.UPDATE_OPERATOR_LIST,
            payload: {
                filterShiftIds: [],
                filterLineIds: [],
                filterStatus: ['true'],
                selectedLine: '',
                selectedOperatorName: '',
                selectedCardId: '',
                selectedEmployeeId: '',
                operatorData: [],
                page: 1,
                count: 1,
                limit: 10,
                searchOperator: '',
            },
        });

        dispatch(getOperator());
    }, [dispatch]);

    const { operatorData, filterLineIds, filterShiftIds, filterStatus } = useSelector(
        (state: ReducersModel) => state.operatorReducer,
    );
    useEffect(() => { }, [operatorData]);
    const { operatorId } = useSelector((state: ReducersModel) => state.skillReducer);
    const { mfgLines } = useSelector((state: ReducersModel) => state.mfgLinesReducer);
    const { shifts } = useSelector((state: ReducersModel) => state.shiftsReducer);
    const { page, limit, count, searchOperator } = useSelector((state: ReducersModel) => state.operatorReducer);
    useEffect(() => {
        if (operatorData.length > 0) {
            dispatch({
                type: Utils.ActionName.UPDATE_OPERATOR_LIST,
                payload: {
                    selectedLine: operatorData[0].mfgLineData.mfgLine,
                    selectedOperatorName: operatorData[0].operatorName,
                    selectedCardId: operatorData[0].cardId,
                    selectedEmployeeId: operatorData[0].operatorId,
                    selectedisActive: operatorData[0].isActive,
                    operatorImage: operatorData[0].operatorImage,
                    description: operatorData[0].description,
                    selectedOperatorId: operatorData[0].id,
                    selectedLineId: operatorData[0].mfgLineId,
                    selectedShiftId: operatorData[0].shiftId,
                    selectedShift: operatorData[0].shiftData.shift,
                    createdBy: operatorData[0].createdBy,
                    updatedBy: operatorData[0].updatedBy,
                    updatedDt: operatorData[0].updatedDt,
                    createdDt: operatorData[0].createdDt,
                    selectedLineSetupId: parseInt(operatorData[0].mfgLineData.id),
                    activeLine:
                        operatorData[0].stationData.length > 0
                            ? operatorData[0]?.stationData[0]?.lineSetupData?.activeLine
                            : '-',
                },
            });
            dispatch(getTimestudyOperator(operatorData[0]?.id));
        }
    }, [operatorData]);
    const debounceCount = useRef(0);
    const api = () =>
        new Promise((res) => {
            setTimeout(() => {
                res(1);
            }, 500);
        });

    const handleSearch = (event: any) => {
        dispatch({
            type: Utils.ActionName.UPDATE_OPERATOR_LIST,
            payload: {
                searchOperator: event.target.value,
            },
        });
        debounceCount.current += 1;
        const localDebounceCount = debounceCount.current;
        api().then((d) => {
            if (localDebounceCount !== debounceCount.current) {
                return;
            }
            dispatch(getOperator());
        });
    };

    const getFilterCount = () => {
        return +filterLineIds.length + filterShiftIds.length + filterStatus.length;
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        dispatch({
            type: Utils.ActionName.UPDATE_OPERATOR_LIST,
            payload: {
                page: newPage + 1,
                data: [],
            },
        });
        dispatch(getOperator());
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({
            type: Utils.ActionName.UPDATE_OPERATOR_LIST,
            payload: {
                limit: event.target.value,
                page: 1,
                data: [],
            },
        });
        dispatch(getOperator());
    };

    const filter = () => {
        return (
            <>
                <div className={classes.styleOperations}>
                    <div className={classes.leftStyleOperations}>
                        <TextField
                            inputProps={{
                                autoComplete: 'off',
                            }}
                            className={classes.searchInput}
                            variant="outlined"
                            placeholder={t('Search')}
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img
                                            alt={t('Search')}
                                            src={LocalImages.SEARCH_ICON}
                                            className={classes.searchIcon}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<AddIcon />}
                            className={classes.addButton}
                            onClick={handleOpen}
                        >
                            {t('Add')}
                        </Button>
                        <MultimediaButton
                            icon={LocalImages.CLOUD_UPLOAD}
                            text={t('BulkUpload')}
                            onClick={() => {
                                setIsBulk(true);
                            }}
                        />
                    </div>
                    <div>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<FormatListBulletedIcon />}
                            className={classes.filterButton}
                            onClick={handleOpenFilter}
                            endIcon={<Avatar className={classes.filterCount}>{getFilterCount()}</Avatar>}
                        >
                            {t('Filter')}
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseFilter}
                            className={classes.filterMenu}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            getContentAnchorEl={null}
                        >
                            <ManageOperatorFilter mfgLines={mfgLines} shifts={shifts} />
                        </Menu>
                    </div>
                </div>
            </>
        );
    };
    const handleSkill = (
        id: any,
        selectedLine: any,
        selectedOperatorName: any,
        selectedCardId: any,
        selectedEmployeeId: any,
        selectedisActive: any,
        operatorImage: any,
        description: any,
        selectedShiftId: any,
        selectedShift: any,
        createdBy: any,
        updatedBy: any,
        updatedDt: any,
        createdDt: any,
        selectedLineSetupId: any,
        activeLine: any,
    ) => {
        dispatch({
            type: Utils.ActionName.UPDATE_SKILL_LIST,
            payload: {
                isOperatorChange: true,
                operatorId: id,
            },
        });
        dispatch({
            type: Utils.ActionName.UPDATE_OPERATOR_LIST,
            payload: {
                selectedLine,
                selectedOperatorName,
                selectedCardId,
                selectedEmployeeId,
                selectedisActive,
                operatorImage,
                description,
                selectedOperatorId: id,
                selectedLineId: selectedLineSetupId,
                selectedShiftId,
                selectedShift,
                createdBy,
                updatedBy,
                updatedDt,
                createdDt,
                selectedLineSetupId,
                activeLine: activeLine,
            },
        });
        dispatch(getSkill(id));
        dispatch(getTimestudyOperator(id));
    };

    const handleSort = () => {
        setIsSort(!isSort);

        if (isSort) {
            dispatch({
                type: Utils.ActionName.UPDATE_OPERATOR_LIST,
                payload: {
                    order: 'ASC',
                    sortBy: 'operatorName',
                },
            });
        } else {
            dispatch({
                type: Utils.ActionName.UPDATE_OPERATOR_LIST,
                payload: {
                    order: 'DESC',
                    sortBy: 'operatorName',
                },
            });
        }

        dispatch(getOperator());
    };

    const operatorTable = () => {
        return (
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead style={{ borderTop: '1px solid #e2dcdc' }}>
                        <TableRow className={classes.categories}>
                            <TableCell>
                                <span onClick={handleSort}>
                                    {t('OperatorName')}{' '}
                                    <ArrowDownwardIcon
                                        className={
                                            isSort ? classes.dwnIcnStyl : `${classes.rotateIcon} ${classes.dwnIcnStyl}`
                                        }
                                    />
                                </span>
                            </TableCell>
                            <TableCell>{t('DESCRIPTION')}</TableCell>
                            <TableCell>{t('EmployeeID')}</TableCell>
                            <TableCell>{t('Line')}</TableCell>
                            <TableCell>{t('Shift')}</TableCell>
                            <TableCell>{t('Status')}</TableCell>
                            <TableCell>{t('DATEMODIFIED')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {operatorData && operatorData.length > 0 ? (
                            operatorData.map((row: any) => (
                                <>
                                    <Divider className={classes.divideStyle} />
                                    <TableRow
                                        key={row.id}
                                        selected={row.id === operatorId}
                                        onClick={() =>
                                            handleSkill(
                                                row.id,
                                                row.mfgLineData.mfgLine,
                                                row.operatorName,
                                                row.cardId,
                                                row.operatorId,
                                                row.isActive,
                                                row.operatorImage,
                                                row.description,
                                                row.shiftId,
                                                row.shiftData.shift,
                                                row.createdBy,
                                                row.updatedBy,
                                                row.updatedDt,
                                                row.createdDt,
                                                row.mfgLineData.id,
                                                row.stationData.length > 0
                                                    ? row.stationData[0]?.lineSetupData?.activeLine
                                                    : '-',
                                            )
                                        }
                                        className={`${classes.categoriesItems} ${!row.isActive ? classes.inActive : ''
                                            }`}
                                    >
                                        <TableCell>
                                            <Highlighter
                                                highlightClassName="YourHighlightClass"
                                                searchWords={[searchOperator]}
                                                autoEscape={true}
                                                textToHighlight={row.operatorName}
                                            />
                                        </TableCell>
                                        <TableCell>{row.description !== null ? row.description : '-'}</TableCell>
                                        <TableCell>{row.operatorId}</TableCell>
                                        <TableCell>{row.mfgLineData.mfgLine}</TableCell>
                                        <TableCell>{row.shiftData.shift}</TableCell>
                                        <TableCell>{row.isActive ? t('Active') : t('Inactive')}</TableCell>
                                        <TableCell>
                                            {row.updatedDt !== null ? moment(row.updatedDt).format('YYYY-MM-DD') : '-'}
                                        </TableCell>
                                    </TableRow>
                                </>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={8} align="center">
                                    <div className={classes.headStyl}>{t('NoRecords')}</div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };
    return (
        <div>
            <div className={classes.upperContainer}>
                <Typography className={classes.heading}>{t('OperatorList')}</Typography>
                <div>{filter()}</div>
            </div>
            <div className={classes.operatorTable}>{operatorTable()}</div>
            <SimpleModal handleOpen={handleOpen} isOpen={open} body={<AddOperator handleClose={handleClose} />} />

            {operatorData.length > 0 ? (
                <div className={classes.paginationContainer}>
                    <TablePagination
                        component="div"
                        count={count}
                        page={page - 1}
                        onPageChange={handleChangePage}
                        rowsPerPage={limit}
                        labelDisplayedRows={({ from, to, count, page }) => `  ${from}-${to} of  ${count} `}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            ) : (
                ''
            )}
            <SimpleModal
                handleOpen={handleOpen}
                isOpen={isBulk}
                body={
                    <BulkOperatorUpload
                        handleClose={() => {
                            setIsBulk(false);
                        }}
                    />
                }
            />
        </div>
    );
}
