import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateRange } from 'react-date-range';
import { DialogContent } from '@material-ui/core';
import { LBRDialog } from './styles';
import { LBRHistoryProp} from './Interfaces';
import LBRChart from './Components/LBRChart/LBRChart';
import { LBRDialogHeader, LBRHistoryTable } from './Components';
import { 
  callUpdateReducer, 
  getFactoryShifts, 
  getLbrGraph, 
  getLbrRows,
} from './Redux/actions';
import { 
  UPDATE_DATE_RANGE, 
  UPDATE_GRAPH_SELECTED_LABELS, 
  UPDATE_SHIFT_ID,
} from './Redux/constant';

const LBRHistory: React.FC<LBRHistoryProp> = ({
   open, 
   onClose,
   lineSetupId,
   uid,
}) => {
  const dispatch = useDispatch();
  const { shiftInfoData } = useSelector((state: any) => state.lineSetupInfoReducer);
  const { 
    dateRange, 
    shiftId,
    errorShift,
    shifts,
    isLoadingShifts,
    isLoadingGraph,
    isLoadingRows
  } = useSelector((state: any) => state.lbrGlobal);
  const { query, selectedLabels } = useSelector((state: any) => state.lbrGraph);
  const dependencies = [shiftId, dateRange, open];
  const isLoading = isLoadingShifts || isLoadingGraph || isLoadingRows;

  const handleUpdateDateRange = (dateRange: DateRange) => {
    dispatch(callUpdateReducer(UPDATE_DATE_RANGE, dateRange));
  }

  const handleUpdateShiftId = (shiftId: number) => {
    dispatch(callUpdateReducer(UPDATE_SHIFT_ID, shiftId));
  }

  const lbrGraphApi = useCallback(async () => {
    if(open && dateRange && shiftId) {
      dispatch(getLbrGraph());
    }
  }, [shiftId, dateRange, open, query]);

  const lbrRowsApi = useCallback(async () => {
    if(open && dateRange && shiftId) {
      dispatch(getLbrRows());
    }
  }, dependencies);

  const fetchAllApi = useCallback(() => {
    lbrGraphApi();
    lbrRowsApi();
  }, dependencies);

  useEffect(() => {
    if(!errorShift && shiftInfoData.shiftId) {
      dispatch(getFactoryShifts(
        shiftInfoData.shiftId,
        lineSetupId,
        uid,
      ));
      fetchAllApi();
    }
  }, [fetchAllApi, shiftInfoData]);
  
  return (
    <LBRDialog fullScreen open={open}>
      <LBRDialogHeader
        date={new Date()}
        isLoading={isLoading}
        onClose={onClose}
        onSyncData={fetchAllApi} 
        onDateRangeChange={handleUpdateDateRange} 
        onShiftChange={handleUpdateShiftId}
        shiftOption={shifts} 
      />
      <DialogContent style={{ padding: 10 }}>
        <LBRChart />
        <LBRHistoryTable
          onDeselectedLabels={(selectedLabels: any) => dispatch(callUpdateReducer(
            UPDATE_GRAPH_SELECTED_LABELS,
            selectedLabels,
          ))}
          selectedLabels={selectedLabels} 
        />
      </DialogContent>
    </LBRDialog>
  );
};

export default LBRHistory;
