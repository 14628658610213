import moment from 'moment';
import Utils from '../../utils';

export const getNpt = () => {
    return (dispatch: Function, getState: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const { page, limit, searchNpt, sortBy, order, filterLineIds, filterNptTypeIds } = getState().nptListReducer;

        let parameters = `?page=${page}&limit=${limit}${
            order.length > 0
                ? `&order=${order}&sortBy=${sortBy}`
                : ''
        }${searchNpt.length > 0 ? `&search=${searchNpt}` : ''}${
            filterLineIds.length > 0 ? `&lineId=${filterLineIds.toString()}` : ''
        }${filterNptTypeIds.length > 0 ? `&nptTypeId=${filterNptTypeIds.toString()}` : ''} `;

        Utils.api.getApiCall(
            Utils.endPoints.npt,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.GET_NPT,
                        payload: {
                            nptData: innerData.data,
                            page: innerData.page,
                            count: innerData.count,
                        },
                    });

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const addingNpt = (values: any, setSubmitting: any, handleClose: any) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            setSubmitting(false);
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }

        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const { shifts } = getState().shiftsReducer;

        const { date, startTime, endTime, manpower, lineId, categoryId, nptTypeId } = values;
        let shiftId = 0;
        var alowApiCall = false;
        var beginningTime = moment(startTime);
        var endingTime = moment(endTime);
        var duration = moment.duration(endingTime.diff(beginningTime));
        var mins = duration.asMinutes();

        //Calculating the diffrenc between start and end time
        var start = moment.utc(startTime, 'HH:mm');
        var end = moment.utc(endTime, 'HH:mm');
        if (end.isBefore(start)) end.add(1, 'day');
        var durations = moment.duration(end.diff(start));
        var subtracted = moment.utc(+durations).format('H');
        var subtractedMin = moment.utc(+durations).format('mm');
        var totalHour = parseInt(subtracted) + parseInt(subtractedMin) / 60;
        if (totalHour > 8) {
            setSubmitting(false);
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
        } else {
            let realStartTime = moment(startTime, "HH:mm:ss").format("HH:mm:ss");
            shifts.forEach((shift:any) => {
                const {startTime: shiftStartTime, endTime: shiftEndTime, id: originalShiftId} = shift;
                if (shiftStartTime > shiftEndTime) {
                    if (
                      (realStartTime >= shiftStartTime && realStartTime <= "23:59:59") ||
                      (realStartTime >= "00:00:00" && realStartTime < shiftEndTime)
                    ) {
                        shiftId = originalShiftId;
                    }
                } else {
                    if (realStartTime >= shiftStartTime && realStartTime < shiftEndTime) {
                        shiftId = originalShiftId;
                    }
                }
            });

            alowApiCall = true;
        }

        let currentTime = moment(startTime).format('HH:mm:ss');
        let makedate = `${date} ${currentTime}`;

        const dataToSend = {
            mfgLineId: lineId,
            nptTypeId,
            tranDt: moment(makedate).utc().format(),
            tranTime: moment(startTime).utc().format('HH:mm:ss'),
            endTime: moment(endTime).utc().format('HH:mm:ss'),
            manpower,
            duration: mins,
            shiftId,
            nptCatId: categoryId,
        };

        if (alowApiCall) {
            Utils.api.postApiCall(
                Utils.endPoints.addNpt,
                dataToSend,
                (respData: any) => {
                    let { data } = respData;
                    if (data.statusCode === Utils.constants.api_success_code.success) {
                        Utils.showAlert(1, data.message);
                        setSubmitting(false);
                        dispatch({
                            type: Utils.ActionName.IS_LOADING,
                            payload: false,
                        });
                        dispatch(getNpt());
                        handleClose();
                    } else {
                        setSubmitting(false);
                        Utils.showAlert(2, data.message);
                        dispatch({
                            type: Utils.ActionName.IS_LOADING,
                            payload: false,
                        });
                    }
                },
                (error: any) => {
                    let { data } = error;
                    Utils.showAlert(2, data.message);
                    setSubmitting(false);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                },
            );
        }
    };
};

export const editingNptType = (
    values: any,
    setSubmitting: any,
    handleClose: any,
    nptId: any,
    startTimeSelect: any,
    endTimeSelect: any,
) => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            setSubmitting(false);
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const { shifts } = getState().shiftsReducer;

        const { date, startTime, endTime, manpower, lineId, categoryId, nptTypeId } = values;
        let shiftId = 0;
        var alowApiCall = false;

        var beginningTime = moment(startTimeSelect);
        var endingTime = moment(endTimeSelect);

        var duration = moment.duration(endingTime.diff(beginningTime));
        var mins = duration.asMinutes();
        var start = moment.utc(startTime, 'HH:mm');
        var end = moment.utc(endTime, 'HH:mm');
        if (end.isBefore(start)) end.add(1, 'day');
        var durations = moment.duration(end.diff(start));
        var subtracted = moment.utc(+durations).format('H');
        var subtractedMin = moment.utc(+durations).format('mm');
        var totalHour = parseInt(subtracted) + parseInt(subtractedMin) / 60;
        if (totalHour > 8) {
            setSubmitting(false);
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
        } else {
            let realStartTime = moment(startTime, "HH:mm:ss").format("HH:mm:ss");
            shifts.forEach((shift:any) => {
                const {startTime: shiftStartTime, endTime: shiftEndTime, id: originalShiftId} = shift;
                if (shiftStartTime > shiftEndTime) {
                    if (
                      (realStartTime >= shiftStartTime && realStartTime <= "23:59:59") ||
                      (realStartTime >= "00:00:00" && realStartTime < shiftEndTime)
                    ) {
                      shiftId = originalShiftId;
                    }
                } else {
                    if (realStartTime >= shiftStartTime && realStartTime < shiftEndTime) {
                        shiftId = originalShiftId;
                    }
                }
            });

            alowApiCall = true;
        }

        // Convert Date to DateTime Format
        let currentTime = moment(startTime, "HH:mm:ss").format('HH:mm:ss');
        let makedate = `${date} ${currentTime}`;


        const dataToSend = {
            mfgLineId: lineId,
            nptTypeId,
            tranDt: moment(makedate).utc().format(),
            tranTime: moment(startTime, "HH:mm:ss").utc().format('HH:mm:ss'),
            endTime: moment(endTime, "HH:mm:ss").utc().format('HH:mm:ss'),
            manpower,
            duration: mins,
            shiftId,
            nptCatId: categoryId,
        };

        if (alowApiCall) {
            Utils.api.putApiCall(
                `${Utils.endPoints.editNpt}?id=${nptId}`,
                dataToSend,
                (respData: any) => {
                    let { data } = respData;
                    if (data.statusCode === Utils.constants.api_success_code.success) {
                        Utils.showAlert(1, data.message);
                        setSubmitting(false);
                        dispatch({
                            type: Utils.ActionName.IS_LOADING,
                            payload: false,
                        });

                        dispatch(getNpt());
                        handleClose();
                    } else {
                        setSubmitting(false);
                        Utils.showAlert(2, data.message);
                        dispatch({
                            type: Utils.ActionName.IS_LOADING,
                            payload: false,
                        });
                    }
                },
                (error: any) => {
                    let { data } = error;
                    Utils.showAlert(2, data.message);
                    setSubmitting(false);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                },
            );
        }
    };
};

export const deleteNPT = (nptId: any, handleCloseEdit: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const dataToSend = '';
        Utils.api.deleteApiCall(
            `${Utils.endPoints.lineNpt}?id=${nptId}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch(getNpt());
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    handleCloseEdit();
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getAllNptTypes = () => {
    return (dispatch: Function, getState: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        if (!navigator.onLine) {
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }

        let parameters = ``;

        Utils.api.getApiCall(
            Utils.endPoints.allNptTypes,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.GET_ALL_NPT_TYPES,
                        payload: {
                            allNptTypeData: innerData,
                        },
                    });

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};
