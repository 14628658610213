import Utils from '.';

/**
 * set access token in axios instance header
 */
export const setAuthorizationToken = (token?: string) => {
    if (token) {
        Utils.constants.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        Utils.constants.axios.defaults.headers.common['Authorization'] = '';
        delete Utils.constants.axios.defaults.headers.common['Authorization'];
    }
};

/**
 * set user session in browser
 */
export const setSession = (payload: any) => {
    for (var k in payload) {
        var isCurrentValObject = typeof payload[k] === 'object';
        if (isCurrentValObject && payload[k] !== null) {
            //if property is object then save it as string
            localStorage.setItem(k, JSON.stringify((payload as any)[k]));
        } else {
            localStorage.setItem(k, (payload as any)[k]);
        }
    }
    setAuthorizationToken(payload.accessToken);
};

export const removeSession = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('i18nextLng');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('factoryId');
    localStorage.removeItem('selectedLanguage');
    setAuthorizationToken('');
    window.location.reload();
};
/**
 * get user access token form loclastorage
 */
export const getAccessToken = () => {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        setAuthorizationToken(accessToken);
    }
    return accessToken;
};

/**
 * get user access token form localStorage
 */
export const setAccessToken = (accessToken: any) => {
    if (accessToken) {
        setAuthorizationToken(accessToken);
    }
    return accessToken;
};
/**
 * get user id form localStorage
 */
export const getUserIdToken = () => {
    return localStorage.getItem('accessToken');
};

export const manageUserSession = (history: any) => {
    let { accessToken } = localStorage;
    if (accessToken) {
        //push user directly to dashboard in app
    } else {
        //do nothing reset user here
    }
};
