import Utils from '../../utils';
import ajax from '../../utils/ajax';

export const fetchNotification = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        const { page, limit } = getState().notificationReducer;
        let parameters = `?page=${page}&limit=${limit}`;
        Utils.api.getApiCall(
            Utils.endPoints.notification,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.GET_NOTIFICATION,
                        payload: {
                            notificationData: innerData,
                            isLoaded: true,
                            unreadCount: innerData.unreadCount,
                            limit: innerData.limit + 6,
                        },
                    });

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getMoreNotification = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const { limit } = getState().notificationReducer;
        let parameters = `?page=${1}&limit=${limit}`;
        Utils.api.getApiCall(
            Utils.endPoints.notification,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.GET_NOTIFICATION,
                        payload: {
                            notificationData: innerData,
                            isLoaded: true,
                            unreadCount: innerData.unreadCount,
                            limit: innerData.limit + 6,
                        },
                    });

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const markReadNotification = (id: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        const dataToSend = {
            id,
        };
        Utils.api.putApiCall(
            `${Utils.endPoints.notificationMarkRead}`,

            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch(fetchNotification());
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const markHide = (id: any, type: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let dataToSend = {};
        if (id === 0) {
            dataToSend = {
                type,
            };
        } else {
            dataToSend = {
                id,
                type,
            };
        }

        Utils.api.putApiCall(
            `${Utils.endPoints.hideNotification}`,

            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                    dispatch(fetchNotification());
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const deleteNotification = (id: any) => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }

        const idToDelete = `/?id=${id}`;
        Utils.api.deleteApiCall(
            `${Utils.endPoints.deleteNotification}`,
            idToDelete,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.GET_NOTIFICATION,
                        payload: { isDeleteLoading: false },
                    });
                    dispatch(fetchNotification());
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.GET_NOTIFICATION,
                        payload: { isDeleteLoading: false },
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                if (data.statusCode === 400) {
                    Utils.showAlert(2, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getListProductsRightMenu = () => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = '';
        Utils.api.getApiGlobalCall(
            Utils.endPoints.appDrawer,
            parameters,
            (respData: any) => {
                let { data } = respData;
                // For users data
                if (
                    respData.status === Utils.constants.api_success_code.success ||
                    data.statusCode === Utils.constants.api_success_code.success
                ) {
                    dispatch({
                        type: Utils.ActionName.GET_PRODUCT_LIST,
                        payload: { appDrawerData: data.data.products },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};
export const getUserName = () => {
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        Utils.CommonFunctions.setAuthorizationToken();
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = '';
        Utils.api.getApiGlobalCall(
            Utils.endPoints.userNameAdmin,
            parameters,
            (respData: any) => {
                let { data } = respData;
                // For users data
                if (
                    respData.status === Utils.constants.api_success_code.success ||
                    data.statusCode === Utils.constants.api_success_code.success
                ) {
                    dispatch({
                        type: Utils.ActionName.GET_PROFILE,
                        payload: { userNameData: data.name },
                    });
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};
export const companyCodeLogins = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = {
            companyCode:
                localStorage.getItem('companyCodeAdmin') || JSON.stringify(localStorage.getItem('companyCodeAdmin')),
        };
        Utils.api.postApiGlobalCall(
            Utils.endPoints.companyCodeLoginAdmin,
            parameters,
            (respData: any) => {
                let { data } = respData;
                localStorage.setItem('companyCodeURLAdmins', data.url);
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const getLoginUser = () => {
    return (dispatch: Function, getState: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(2, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        let parameters = '';
        Utils.api.getApiCall(
            Utils.endPoints.getUserName,
            parameters,
            (respData: any) => {
                let { data } = respData;
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    const innerData = data.data;
                    dispatch({
                        type: Utils.ActionName.GET_NOTIFICATION,
                        payload: {
                            userName: innerData.username,
                        },
                    });

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    Utils.showAlert(2, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const logout = async () => {
    try {
        const companyCode = localStorage.getItem('companyCodeAdmin');
        const { data } = await ajax(`${Utils.constants.apiUrlGlobal}companylogin`, {
            method: 'post',
            data: { companyCode },
        });
        const { url } = data;
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userId');
        localStorage.removeItem('factoryId');
        window.location.href = url;
    } catch (error) {
        console.error(error)
    }
};
export const logoutCompany = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('companyCode');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('userId');
    localStorage.removeItem('user');
    localStorage.removeItem('i18nextLng');
    localStorage.removeItem('user');
    localStorage.removeItem('ID');
    localStorage.removeItem('usernamedata');
    localStorage.removeItem('CompanyCode-Login');
    localStorage.removeItem('token');
    window.location.href = `https://stx-app.kjubsolutions.com`;
};
