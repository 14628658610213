import * as React from 'react';
import {
    Typography,
    Box,
    IconButton,
} from '@material-ui/core';
import { 
    LBRCloseButton, 
    LBRDateInformationContainer, 
    LBRDateInformationText, 
    LBRDialogHeaderContainer, 
    LBRFlexCenterContainer, 
    LBRLabel, 
    LBRLoadingButton,
} from '../styles';
import LBRDateRange from './LBRDateRange';
import LBRCustomSelect from './LBRCustomSelect';
import { formatDate } from '../helpers';
import LocalImages from '../../../../../utils/images';
import { useSelector } from 'react-redux';

interface LBRDialogHeaderProp {
    date: Date,
    shiftOption: [],
    onDateRangeChange?: (dateRange: any) => void,
    onShiftChange?: (shiftId: any) => void,
    onClose?: () => void,
    onSyncData?: () => void,
    isLoading?: boolean,
}

const LBRDialogHeader: React.FC<LBRDialogHeaderProp> = ({
    date,
    shiftOption,
    onDateRangeChange,
    onShiftChange,
    onClose,
    onSyncData,
    isLoading = false,
}) => {
    const { shiftId } = useSelector((state: any) => state.lbrGlobal);

    return (
        <LBRDialogHeaderContainer>
            <LBRFlexCenterContainer marginBottom={2}>
                <div 
                    style={{
                        display: 'flex'
                    }}
                >
                    <Typography variant='h5'>{'LBR History'}</Typography>
                    <img style={{ marginLeft: '15px' }} src={LocalImages.BETA} alt="beta" />
                </div>
                <LBRCloseButton onClick={onClose} />
            </LBRFlexCenterContainer>
            <Box display='flex' justifyContent='flex-start' alignItems='flex-end' gridGap={5}>
                <Box>
                    <LBRLabel>{'Select the date'}</LBRLabel>
                    <LBRDateRange isLoading={isLoading} onChange={onDateRangeChange} />
                </Box>
                <Box>
                    <LBRLabel>{'Select a shift'}</LBRLabel>
                    <LBRCustomSelect
                        defaultValue={shiftId}
                        onChange={onShiftChange}
                        data={shiftOption}
                        isLoading={isLoading}
                    />
                </Box>
                <Box style={{ marginLeft: 'auto' }}>
                    <LBRDateInformationContainer>
                        <Box>
                            <LBRDateInformationText isBlue={true}>{'Updated at'} {formatDate(date, 'HH:mm')}, </LBRDateInformationText>
                            <LBRDateInformationText isBlue={false}>{formatDate(date, 'dddd, DD, MMM Y')}</LBRDateInformationText>
                        </Box>
                        <IconButton onClick={onSyncData} size='small'>
                            <LBRLoadingButton isLoading={isLoading} />
                        </IconButton>
                    </LBRDateInformationContainer>
                </Box>
            </Box>
        </LBRDialogHeaderContainer>
    )
}

export default LBRDialogHeader;