import React from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    Typography,
    Backdrop,
    Breadcrumbs,
    Link,
    Fade,
    Button,
} from '@material-ui/core';
import Sidebar from '../sidebar/sidebar';
// import NewSidebar from '../sidebar/newSideBar';
// import { useDispatch } from 'react-redux';
// import Utils from '../../utils';

import Header from '../../components/header/header';
import Spinner from '../spinner';
import { ReducersModel } from '../../model';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SimpleModal from '../modal';
// import { getAccessTokenUsingRefreshToken } from '../../utils/api';
const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: { position: 'relative' },
        section: { display: 'flex', paddingTop: '48px' },
        rightContainer: {
            width: '100%',
            backgroundColor: 'var(--background-grey)',
            height: '93vh',
            marginLeft: '62px',
            position: 'relative',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                display: 'none !important',
            },
        },
        titleContainer: {
            width: '100%',
            backgroundColor: 'var(--white)',
            display: 'flex',
            alignItems: 'center',
            height: '46px',
            '& .MuiBreadcrumbs-li': {
                display: 'flex',
                alignItems: 'center',
                marginLeft: '15px',
            },
            '& .MuiLink-root': {
                cursor: 'pointer',
            },
        },
        titleText: {
            color: 'var(--title-color)',
            padding: '11px 9px',
        },
        slash: {
            color: 'var(--title-color)',
            padding: '0 4px',
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
        },
        rowDisplay: {
            display: 'flex',
            alignItems: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            textAlign: 'center',
            position: 'relative',
            left: '40%',
            top: '35%',
            borderRadius: '5px',
            width: '25%',
        },
        textStyl: {
            fontSize: '20px',
            color: 'var(--dark-grey)',
        },
        saveButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#FFFFFF',
            opacity: 1,
            height: '36px',
        },
        textSecond: {
            font: 'normal normal bold 14px/14px Roboto',
            letterSpacing: '0.08px',
            color: 'var(--dark-grey)',
            margin: ' 14px 0px',
        },
    }),
);

export default function Container({ children }: any) {
    const classes = styles();
    const pathname = useLocation().pathname;
    const history = useHistory();
    const { t } = useTranslation();

    const { isLoading } = useSelector((state: ReducersModel) => state.globalLoaderReducer);
    const { isTimeOut } = useSelector((state: ReducersModel) => state.signInReducer);

    return (
        <div className={classes.mainContainer}>
            <Backdrop className={classes.backdrop} open={isLoading ? isLoading : false}>
                <Spinner />
            </Backdrop>
            <Header />
            <div className={classes.section}>
                <Sidebar />
                <div className={classes.rightContainer}>
                    <div className={classes.titleContainer}>
                        <Breadcrumbs aria-label="breadcrumb">
                            {pathname === '/analytics' ? (
                                <div className={classes.rowDisplay}>
                                    <Link
                                        color="inherit"
                                        onClick={() => {
                                            history.push('/analytics');
                                        }}
                                    >
                                        {t('AnalyticsReporting')}
                                    </Link>
                                </div>
                            ) : pathname === '/dashboard' ? (
                                <div className={classes.rowDisplay}>
                                    <Link
                                        color="inherit"
                                        onClick={() => {
                                            history.push('/dashboard');
                                        }}
                                    >
                                        {t('DASHBOARD')}
                                    </Link>
                                    <Typography className={classes.slash}> / </Typography>
                                    <Typography className={classes.titleText}>{t('Overall')}</Typography>
                                </div>
                            ) : pathname === '/dashboard/line-output' ? (
                                <div className={classes.rowDisplay}>
                                    <Link
                                        color="inherit"
                                        onClick={() => {
                                            history.push('/dashboard');
                                        }}
                                    >
                                        {t('DASHBOARD')}
                                    </Link>
                                    <Typography className={classes.slash}> / </Typography>
                                    <Typography className={classes.titleText}>{t('LineOutput')}</Typography>
                                </div>
                            ) : pathname === '/lineSetup' ? (
                                'Line Setup'
                            ) : pathname === '/lineSetup-add' ? (
                                <div className={classes.rowDisplay}>
                                    <Link
                                        color="inherit"
                                        onClick={() => {
                                            history.push('/lineSetup');
                                        }}
                                    >
                                        {'Line Setup'}
                                    </Link>
                                    <Typography className={classes.slash}> / </Typography>
                                    <Typography className={classes.titleText}>{t('Add')}</Typography>
                                </div>
                            ) : pathname === '/manage-style/style-list' ? (
                                <div className={classes.rowDisplay}>
                                    <Link
                                        color="inherit"
                                        onClick={() => {
                                            history.push('/manage-style/style-list');
                                        }}
                                    >
                                        {t('ManageStyle')}
                                    </Link>
                                    <Typography className={classes.slash}> / </Typography>
                                    <Typography className={classes.titleText}>{t('StyleList')}</Typography>
                                </div>
                            ) : pathname === '/admin/manage-operator' ? (
                                t('Admin')
                            ) : pathname === '/admin/product-setting' ? (
                                t('Admin')
                            ) : pathname === '/admin/operationCode' ? (
                                <div className={classes.rowDisplay}>
                                    <Link
                                        color="inherit"
                                        onClick={() => {
                                            history.push('/admin/manage-operator');
                                        }}
                                    >
                                        {t('Admin')}
                                    </Link>
                                    <Typography className={classes.slash}> / </Typography>
                                    <Typography className={classes.titleText}>{t('ManageOperation')}</Typography>
                                </div>
                            ) : (
                                'Title'
                            )}

                            {pathname === '/manage-style/style-information' ? (
                                <div className={classes.rowDisplay}>
                                    <Link
                                        color="inherit"
                                        onClick={() => {
                                            history.push('/manage-style/style-list');
                                        }}
                                    >
                                        {t('ManageStyle')}
                                    </Link>
                                    <Typography className={classes.slash}> / </Typography>
                                    <Link
                                        color="inherit"
                                        onClick={() => {
                                            history.push('/manage-style/style-list');
                                        }}
                                    >
                                        {t('StyleList')}
                                    </Link>
                                    <Typography className={classes.slash}> / </Typography>
                                    <Typography className={classes.titleText}>{t('StyleInformation')}</Typography>
                                </div>
                            ) : pathname === '/manage-style/add-style' ? (
                                <div className={classes.rowDisplay}>
                                    <Link
                                        color="inherit"
                                        onClick={() => {
                                            history.push('/manage-style/style-list');
                                        }}
                                    >
                                        {t('ManageStyle')}
                                    </Link>
                                    <Typography className={classes.slash}> / </Typography>
                                    <Link
                                        color="inherit"
                                        onClick={() => {
                                            history.push('/manage-style/style-list');
                                        }}
                                    >
                                        {t('StyleList')}
                                    </Link>
                                    <Typography className={classes.slash}> / </Typography>
                                    <Typography className={classes.titleText}>{t('Add')}</Typography>
                                </div>
                            ) : pathname === '/manage-style/edit-style' ? (
                                <div className={classes.rowDisplay}>
                                    <Link
                                        color="inherit"
                                        onClick={() => {
                                            history.push('/manage-style/style-list');
                                        }}
                                    >
                                        {t('ManageStyle')}
                                    </Link>
                                    <Typography className={classes.slash}> / </Typography>
                                    <Link
                                        color="inherit"
                                        onClick={() => {
                                            history.push('/manage-style/style-list');
                                        }}
                                    >
                                        {t('StyleList')}
                                    </Link>
                                    <Typography className={classes.slash}> / </Typography>
                                    <Typography className={classes.titleText}>{t('Edit')}</Typography>
                                </div>
                            ) : (
                                ''
                            )}

                            {pathname === '/line-setup-view' ? (
                                <div className={classes.rowDisplay}>
                                    <Link
                                        color="inherit"
                                        onClick={() => {
                                            history.push('/lineSetup');
                                        }}
                                    >
                                        {t('LineSetup')}
                                    </Link>
                                    <Typography className={classes.slash}> / </Typography>
                                    <Typography className={classes.titleText}>{t('Add')}</Typography>
                                </div>
                            ) : (
                                ''
                            )}

                            {pathname === '/lineSetup' ? (
                                <Typography className={classes.titleText}>{t('LineSetup')}</Typography>
                            ) : (
                                ''
                            )}

                            {pathname === '/admin/manage-operator' || pathname === '/admin/product-setting' ? (
                                <div className={classes.rowDisplay}>
                                    {pathname === '/admin/manage-operator' ? (
                                        <div className={classes.rowDisplay}>
                                            <Link
                                                color="inherit"
                                                onClick={() => {
                                                    history.push('/admin/manage-operator');
                                                }}
                                            >
                                                {t('Admin')}
                                            </Link>

                                            <Typography className={classes.slash}> / </Typography>
                                            <Typography className={classes.titleText}>{t('ManageOperator')}</Typography>
                                        </div>
                                    ) : pathname === '/admin/product-setting' ? (
                                        <div className={classes.rowDisplay}>
                                            <Link
                                                color="inherit"
                                                onClick={() => {
                                                    history.push('/admin/manage-operator');
                                                }}
                                            >
                                                {t('Admin')}
                                            </Link>

                                            <Typography className={classes.slash}> / </Typography>
                                            <Typography className={classes.titleText}>
                                                {t('ProductSettings')}
                                            </Typography>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            ) : (
                                ''
                            )}

                            {pathname === '/line-balancing-assist' ? (
                                <Typography className={classes.titleText}>{t('LineBalancingAssist')}</Typography>
                            ) : (
                                ''
                            )}
                            {
                            pathname === '/line-balancing-assist/line-balancing-info' ||
                            pathname === '/lbrhistory-restore' ? (
                                <div className={classes.rowDisplay}>
                                    <Link
                                        color="inherit"
                                        onClick={() => {
                                            history.push('/line-balancing-assist');
                                        }}
                                    >
                                        {t('LineBalancingAssist')}
                                    </Link>

                                    <Typography className={classes.slash}> / </Typography>
                                    <Typography className={classes.titleText}>
                                        {t('LineBalancingInformation')}
                                    </Typography>
                                </div>
                            ) : (
                                ''
                            )}
                            {pathname === '/admin/manage-npt' ? (
                                <div className={classes.rowDisplay}>
                                    <Link
                                        color="inherit"
                                        onClick={() => {
                                            history.push('/admin/manage-operator');
                                        }}
                                    >
                                        {t('Admin')}
                                    </Link>

                                    <Typography className={classes.slash}> / </Typography>
                                    <Typography className={classes.titleText}>{t('ManageNPT')}</Typography>
                                </div>
                            ) : (
                                ''
                            )}
                            {pathname === '/admin/manage-npt-edit' ? (
                                <div className={classes.rowDisplay}>
                                    <Link
                                        color="inherit"
                                        onClick={() => {
                                            history.push('/admin/manage-operator');
                                        }}
                                    >
                                        {t('Admin')}
                                    </Link>

                                    <Typography className={classes.slash}> / </Typography>
                                    <Link
                                        color="inherit"
                                        onClick={() => {
                                            history.push('/admin/manage-npt');
                                        }}
                                    >
                                        {t('ManageNPT')}
                                    </Link>
                                    <Typography className={classes.slash}> / </Typography>
                                    <Typography className={classes.titleText}>{t('Edit')}</Typography>
                                </div>
                            ) : (
                                ''
                            )}

                            {pathname === '/npt' ? (
                                <Typography className={classes.titleText}>{t('NPT')}</Typography>
                            ) : (
                                ''
                            )}

                            {pathname === '/inbox' ? (
                                <Typography className={classes.titleText}>{t('Inbox')}</Typography>
                            ) : (
                                ''
                            )}
                        </Breadcrumbs>
                    </div>
                    {children}
                </div>
            </div>
            <SimpleModal
                // handleOpen={() => {
                // }}
                isOpen={isTimeOut}
                body={
                    <Fade in={isTimeOut}>
                        <div className={classes.paper}>
                            <Typography className={classes.textStyl} id="transition-modal-title">
                                {t('SessionExpired')}
                            </Typography>
                            <Typography className={classes.textSecond} id="transition-modal-description">
                                {t('Screenisidlefortoolong')}
                            </Typography>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.saveButton}
                                // onClick={() => {
                                //     getAccessTokenUsingRefreshToken();
                                // }}
                            >
                                {t('ok')}
                            </Button>
                        </div>
                    </Fade>
                }
            />
        </div>
    );
}
