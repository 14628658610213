import * as React from 'react';
import {
  LBRCustomTooltipContainer,
  LBRCustomTooltipDivider,
  LBRCustomTooltipSubTitle,
  LBRCustomTooltipText,
  LBRCustomTooltipTitle,
  LBRFlexCenterContainer,
  LBRFlexContainer,
  LBRLabel,
} from './../styles';
import { 
  formatNumberWithCommas, 
  isFloatingPoint, 
  legends, 
  viewByOptions,
} from '../helpers';
import { LBRLegen } from '../Icons';

interface LBRVictoryCustomTooltipProps {
  datum?: any;
  x?: number;
  y?: number;
  center?: any;
  data: any;
  endPoint: any,
}

const LBRVictoryCustomTooltip: React.FC<LBRVictoryCustomTooltipProps> = ({
  datum,
  x = 0,
  y = 0,
  data = [],
  endPoint = 0,
}) => {
  
  const isLastPoint = endPoint - datum.x < 5;
  const index = datum.x - 1;

  if(isFloatingPoint(index)) return null;
  
  return (
    <g x={10} style={{ padding: 20, pointerEvents: "none" }}>
      <foreignObject x={(isLastPoint ? x - 260 : x)} y={y - 100} width="300px" height="300px" style={{ padding: '0 15px' }}>
        <LBRCustomTooltipContainer isLastPoint={isLastPoint}>
          <LBRCustomTooltipTitle>LBR History - Best</LBRCustomTooltipTitle>
          <LBRCustomTooltipSubTitle>{data.axisBottom[index] || ''}</LBRCustomTooltipSubTitle>
          <LBRCustomTooltipDivider />
          {[...legends, ...viewByOptions].map(({ name, type, color, key }: any) => (
            <LBRFlexCenterContainer>
              <LBRFlexContainer>
                <LBRLegen color={color} type={type} />
                <LBRLabel color='#fff'>{name}</LBRLabel>
              </LBRFlexContainer>
              <LBRCustomTooltipText>
                {
                  type === 'line'
                    ? `${data[key][index]['y'].toFixed(2)}%`
                    : formatNumberWithCommas(data[key][index]['y'])
                }
              </LBRCustomTooltipText>
            </LBRFlexCenterContainer>
          ))}
        </LBRCustomTooltipContainer>
      </foreignObject>
    </g>
  )
}

export default LBRVictoryCustomTooltip;