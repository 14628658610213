import React, { useState, useEffect } from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    List,
    ListItem,
    ListItemIcon,
    Avatar,
    Popover,
    Typography,
    Divider,
    Drawer,
    ListItemText,
    Badge,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModel } from '../../model';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import LocalImages from '../../utils/images';
import NotificationsNoneSharpIcon from '@material-ui/icons/NotificationsNoneSharp';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import LanguageIcon from '@material-ui/icons/Language';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
    fetchNotification,
    markReadNotification,
    markHide,
    getMoreNotification,
    logout,
    getLoginUser,
    getListProductsRightMenu,
    logoutCompany,
} from './action';
import { getFactory, getLanguage, selectFactory } from '../../screens/login/action';
import Utils from '../../utils';
import { CancelRounded } from '@material-ui/icons';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            backgroundColor: 'var(--dark-green)',
            width: '100%',
            height: '48px',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'fixed',
            top: 0,
            zIndex: 999,
        },
        flexContainer: {
            display: 'flex',
            justifyContent: 'space-around',
        },
        flexContainerLeft: {
            display: 'flex',
        },
        flexInner: {
            display: 'flex',
            padding: '5px 20px',
        },
        leftContainer: {
            width: '158px',
            paddingTop: '9px',
        },
        imgContainer: {
            width: '30px',
            height: '30px',
            backgroundColor: 'var(--white)',
            borderRadius: '50%',
            textAlign: 'center',
            margin: '0 16px 0 18px',
        },

        logo: {
            marginRight: '30px',
        },
        imgStyl: {
            marginTop: '3px',
        },
        selectStyl: {
            borderBottom: 'solid 3px var(--dark-green)',
            zIndex: 3,
            fontSize: '14px',
            fontWeight: 'bold',
            color: 'white',
            position: 'relative',
            marginTop: '5px',
            cursor: 'pointer',
        },
        downIcon: {
            position: 'absolute',
            top: '-2px',
        },
        rightContainer: {
            width: '216px',
            paddingTop: '6px',
        },
        iconStyl: {
            color: 'var(--white)',
            marginTop: '3px',
            cursor: 'pointer',
            width: '30px',
            height: '30px',
        },
        avtarStyl: {
            width: '30px',
            height: '30px',
            fontSize: '13px',
            border: 'solid 1px var(--white)',
            color: 'var(--white)',
            backgroundColor: 'var(--light-brown)',
            cursor: 'pointer',
        },
        bottomStyle: {
            '&:hover': {
                borderBottom: 'solid 5px var(--light-blue)',
            },
            '& .MuiBadge-anchorOriginTopRightRectangle': {
                top: '8px',
                right: '2px',
            },
        },
        popoverContainer: {
            backgroundColor: 'var(--white)',
            width: '190px',
        },
        popoverHeading: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        popoverClearAll: {
            color: 'var(--blue)',
            fontSize: '14px',
            fontWeight: 500,
            cursor: 'pointer',
        },
        bigTitle: {
            color: 'var(--dark-grey)',
            fontSize: '16px',
            fontWeight: 'bold',
            marginTop: '8px',
        },
        blueTitle: {
            color: 'var(--blue)',
            fontSize: '12px',
            fontWeight: 'bold',
        },
        blackTitle: {
            color: 'var(--black)',
            fontSize: '12px',
            fontWeight: 'bold',
        },
        liteText: {
            color: 'var(--light-grey)',
            fontSize: '12px',
            fontWeight: 'bold',
        },
        marginStyle: {
            marginLeft: '12px',
        },
        avtarStylBig: {
            width: '50px',
            height: '50px',
            fontSize: '23px',
            border: '2px solid #E3E3E3',
            fontWeight: 'bold',
            color: 'var(--white)',
            backgroundColor: 'var(--light-brown)',
        },
        borderStyl: {
            borderBottom: 'solid 1px var(--lite-border)',
            borderTop: 'solid 1px var(--lite-border)',
            cursor: 'pointer',
        },
        paddingAvtar: {
            padding: '10px  20px',
        },
        paddingMenu: {
            padding: '5px 0 5px 0',
        },
        blueIcon: {
            color: 'var(--dark-green)',
        },
        darkStyle: {
            color: 'var(--dark-grey)',
            fontSize: '12px',
        },
        flexContainerSetting: {
            display: 'flex',
            padding: '15px',
        },
        innerSetting: {
            paddingLeft: '10px',
            '& p': {
                cursor: 'pointer',
            },
        },
        darkImg: {
            marginTop: '5px',
        },
        dividerStyl: {
            backgroundColor: 'var(--lite-border)',
        },
        headerFlex: {
            display: 'flex',
            height: '40px',
            backgroundColor: 'var(--dark-green)',
            paddingTop: '8px',
        },

        drwerContaier: {
            '& .MuiDrawer-paper': {
                backgroundColor: 'var(--white)',
                width: '200px',
            },
        },
        drwerRightContaier: {
            '& .MuiDrawer-paper': {
                backgroundColor: 'var(--white)',
                width: '100px',
            },
        },
        listText: {
            color: 'var( --title-color)',
            height: '48px',
            '& span': {
                fontSize: '12px',
            },
        },
        headerLogo: {
            flexBasis: '30%',
            marginLeft: '7px',
        },
        titleRihtMenu: {
            fontSize: '9px',
            textAlign: 'center',
            color: 'var(--title-light)',
            width: '80px',
            marginTop: '50px',
        },
        rightHeaderLOgo: {
            width: '30px',
            height: '30px',
            margin: '0 auto',
        },
        wrapStyle: {
            flexWrap: 'wrap',
            padding: '0px',
            marginBottom: '15px',
        },
        centerStyle: {
            margin: '0 auto',
        },
        popoverNotContainer: {
            backgroundColor: 'var(--white)',
            width: '256px',
            '& > div': {
                '&:first-child': {
                    font: 'normal normal medium 14px/21px Roboto',
                    letterSpacing: '0.08px',
                    color: '#515151',
                    padding: '16px',
                },
            },
        },
        moreNotfication: {
            font: 'normal normal normal 12px/21px Roboto',
            letterSpacing: '0.07px',
            color: '#1E88E5',
            textAlign: 'center',
            padding: '16px',
            cursor: 'pointer',
            '& a': {
                font: 'normal normal normal 12px/21px Roboto',
                letterSpacing: '0.07px',
                color: '#1E88E5',
                textAlign: 'center',
                padding: '16px',
            },
        },
        notification: {
            borderBottom: '1px solid #EAEAEA',
            font: 'normal normal normal 12px/21px Roboto',
            letterSpacing: '0.07px',
            position: 'relative',
            color: '#000000',
            cursor: 'pointer',
            display: 'flex',
            padding: '8px 10px 8px 16px',
            '& > div': {
                '&:nth-child(1)': {
                    '& svg': {
                        color: '#1E88E5',
                        fontSize: '10px',
                    },
                },
                '&:nth-child(2)': {
                    padding: '0 5px',
                    width: '85%',
                    '& > div': {
                        '&:first-child': {
                            color: '#515151',
                            font: 'normal normal bold 14px/21px Roboto',
                            letterSpacing: '0.08px',
                        },
                        '&:last-child': {
                            color: '#868686',
                        },
                    },
                },
                '&:nth-child(3)': {
                    '& svg': {
                        color: 'var(--bar-red)',
                        fontSize: '17px',
                        paddingTop: '2px',
                    },
                },
            },
        },
        cancelIcon: {
            position: 'absolute',
            right: '7px',
        },
        notificationTitle: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& svg': {
                color: 'var(--bar-red)',
                fontSize: '10px',
            },
        },
    }),
);

export default function AdminHeader() {
    const classes = styles();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [anchorElSet, setAnchorElSet] = useState<HTMLButtonElement | null>(null);
    const [anchorElNot, setAnchorElNot] = useState<HTMLButtonElement | null>(null);
    const [deleteId, setDeleteId] = useState('');
    const { t, i18n } = useTranslation();
    const [rightDarwer, setRightDarwer] = useState(false);
    const dispatch = useDispatch();
    const { notificationData, isLoaded, unreadCount, isDeleteLoading, userName } = useSelector(
        (state: ReducersModel) => state.notificationReducer,
    );
    const { factoryData, darwerOpen, factoryLanguages } = useSelector((state: ReducersModel) => state.factoryReducer);
    const { appDrawerData } = useSelector((state: ReducersModel) => state.appDrawerReducer);
    const listLanguages = factoryLanguages.length > 0 ? factoryLanguages[0].listLanguages : [];
    const handleAppDrawer = () => {
        setRightDarwer(true);
        dispatch(getListProductsRightMenu());
    };
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        setAnchorElSet(null);
    };
    useEffect(() => {
        dispatch(fetchNotification());
        dispatch(getFactory());
        dispatch(getLanguage());
        dispatch(getLoginUser());
        let lang = i18n.language;
        let langHeader = '';
        switch (lang) {
            case 'zh':
                langHeader = 'chi';
                break;
            case 'vi':
                langHeader = 'khm';
                break;
            case 'id':
                langHeader = 'ind';
                break;
            case 'es':
                langHeader = 'spa';
                break;
            case 'bhs':
                langHeader = 'bhs';
                break;
            case 'ind':
                langHeader = 'ind';
                break;
            default:
                langHeader = 'en';
                break;
        }
        Utils.constants.axios.defaults.headers.common['lang'] = `${langHeader}`;
    }, [dispatch]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickSetting = (event: any) => {
        setAnchorElSet(event.currentTarget);
        setAnchorEl(null);
    };

    const handleCloseSetting = () => {
        setAnchorElSet(null);
    };

    const handleClickNotification = (event: any) => {
        setAnchorElNot(event.currentTarget);
        setAnchorEl(null);
    };

    const handleCloseNotification = () => {
        setAnchorElNot(null);
    };

    const setLanguage = (lang: string) => {
        switch (lang) {
            case 'my': //Burmese
                i18n.changeLanguage('en');
                break;
            case 'zh-CN': //Chinese (Simplified)
                i18n.changeLanguage('zh');
                break;
            case 'zh-TW': //Chinese (Traditional)
                i18n.changeLanguage('zh');
                break;
            case 'in': //Indonesian
                i18n.changeLanguage('id');
                break;
            case 'km': //Khmer
                i18n.changeLanguage('kh');
                break;
            case 'es': //Spanish
                i18n.changeLanguage('es');
                break;
            case 'vi': //Vietnamese
                i18n.changeLanguage('vi');
                break;
            case 'bhs': //Bahasa Indonesia
                i18n.changeLanguage('bhs');
            case 'ind': //Bahasa Indonesia
                i18n.changeLanguage('ind');
                break;
            case 'en': //English
                i18n.changeLanguage('en');
                break;
        }
        setAnchorElSet(null);
        window.location.reload();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const isSetting = Boolean(anchorElSet);
    const idSetting = isSetting ? 'simple-popover' : undefined;
    const isNotification = Boolean(anchorElNot);
    const idNotification = isNotification ? 'simple-popover' : undefined;

    const readMore = () => {
        dispatch(getMoreNotification());
    };

    const accountDetail = () => {
        return (
            <div className={classes.popoverContainer}>
                <div className={`${classes.flexInner} ${classes.paddingAvtar}`}>
                    <div>
                        <Avatar onClick={handleClick} className={classes.avtarStylBig} {...stringAvatar(userName)} />
                    </div>
                    <div>
                        <Typography className={`${classes.bigTitle} ${classes.marginStyle}`}>{userName}</Typography>
                        <Typography className={`${classes.blueTitle} ${classes.marginStyle}`}>
                            {t('EditProfile')}
                        </Typography>
                    </div>
                </div>
                <div
                    className={`${classes.flexInner} ${classes.borderStyl} `}
                    onClick={() => {
                        logout();
                    }}
                >
                    <div>
                        <img alt={'logout_logo'} src={LocalImages.LOGOUT} />
                    </div>
                    <Typography className={`${classes.liteText} ${classes.marginStyle}`}>{t('Logout')}</Typography>
                </div>
                <div onClick={() => logoutCompany()} className={`${classes.flexInner} ${classes.borderStyl} `}>
                    <div>
                        <img alt={'logout_logo'} src={LocalImages.LOGOUT} />
                    </div>
                    <Typography className={`${classes.liteText} ${classes.marginStyle}`}>
                        {t('SignOutCompany')}
                    </Typography>
                </div>
            </div>
        );
    };

    const settingDetail = () => {
        return (
            <div className={classes.popoverContainer}>
                <div className={`${classes.flexContainerSetting} ${classes.paddingMenu}`}>
                    <div>
                        <LanguageIcon className={classes.blueIcon} />
                    </div>
                    <div className={classes.innerSetting}>
                        <Typography className={classes.darkStyle}>{t('Language')}</Typography>
                        <Typography
                            className={
                                localStorage.getItem('selectedLanguage') === 'en'
                                    ? classes.blackTitle
                                    : classes.blueTitle
                            }
                            onClick={() => {
                                localStorage.setItem('selectedLanguage', 'en');
                                setLanguage('en');
                            }}
                        >
                            {'English'}
                        </Typography>
                        {listLanguages.map((lang: any) => {
                            return (
                                <Typography
                                    className={
                                        localStorage.getItem('selectedLanguage') === lang.language
                                            ? classes.blackTitle
                                            : classes.blueTitle
                                    }
                                    onClick={() => {
                                        localStorage.setItem('selectedLanguage', lang.language);
                                        setLanguage(lang.language);
                                    }}
                                >
                                    {lang?.desc1}
                                </Typography>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };
    const markAsRead = (id: any) => {
        dispatch(markReadNotification(id));
    };

    const notificationDetail = () => {
        return (
            <div className={classes.popoverNotContainer}>
                <div className={classes.popoverHeading}>
                    <div>{t('Inbox')}</div>
                    <div onClick={() => dispatch(markHide(0, 1))} className={classes.popoverClearAll}>
                        {t('Clear All')}
                    </div>
                </div>
                <Divider className={classes.dividerStyl} />
                {notificationData.data.map((row: any, index: any) => (
                    <div key={row.id} onClick={() => markAsRead(row.id)} className={classes.notification}>
                        <div>
                            {row.isRead === 0 ? (
                                <FiberManualRecordIcon />
                            ) : (
                                <FiberManualRecordIcon style={{ visibility: 'hidden' }} />
                            )}
                        </div>
                        <div>
                            <div className={classes.notificationTitle}>
                                <div>{row.title}</div>
                            </div>
                            <div>
                                #{row.factoryId} {row.updatedBy}
                            </div>
                            <div>{row.message}</div>
                            <div>{row.updatedDt !== null ? moment(row.updatedDt).format('YYYY-MM-DD HH:MM') : '-'}</div>
                        </div>
                        {isDeleteLoading && row.id === deleteId ? (
                            '...'
                        ) : (
                            <div className={classes.cancelIcon}>
                                <CancelRounded onClick={() => dispatch(markHide(row.id, 0))} />
                            </div>
                        )}
                    </div>
                ))}
                <div className={classes.moreNotfication} onClick={() => readMore()}>
                    {t('ReadMore')}
                </div>
            </div>
        );
    };

    const headerLogo = () => {
        return (
            <div className={classes.imgContainer}>
                <img className={classes.imgStyl} alt="header-logo" src={LocalImages.HEADER_LOGO} />
            </div>
        );
    };

    const insideRightDrawer = () => {
        return (
            <div>
                <div className={classes.headerFlex}>
                    <img className={classes.rightHeaderLOgo} alt="right_header" src={LocalImages.RIGHT_HEADER} />
                </div>

                <List>
                    {appDrawerData.map((item: any) => {
                        return (
                            <ListItem
                                button
                                className={`${classes.listText} ${classes.wrapStyle}`}
                                onClick={() => window.open(item.product_url, '_blank')}
                            >
                                <ListItemIcon className={classes.centerStyle}>
                                    <img className={classes.rightHeaderLOgo} alt="right_menu" src={item.iconpath} />
                                </ListItemIcon>
                                <Typography className={`${classes.titleRihtMenu} ${classes.centerStyle}`}>
                                    {item.productDesc}
                                </Typography>
                            </ListItem>
                        );
                    })}
                </List>
            </div>
        );
    };
    function stringAvatar(name: string) {
        return {
            children: `${name.split(' ')[0][0]}${name.split(' ')[0][1]}`,
        };
    }
    const activeFactoryId = localStorage.getItem('factoryId');

    const setFactory = (factoryInfo: any) => {
        dispatch(selectFactory(factoryInfo.id));
    };

    const renderActiveFactory = (drawer: boolean) => {
        const factoryInfo = factoryData.find((factory: any) => {
            /* eslint-disable-next-line */
            return factory.id == activeFactoryId;
        });
        if (drawer) {
            if (factoryInfo) {
                return (
                    <div className={classes.selectStyl}>
                        {factoryInfo.factory} <ArrowDropDownIcon className={classes.downIcon} />
                    </div>
                );
            }
            return (
                <div className={classes.selectStyl}>
                    {t('None')} <ArrowDropDownIcon className={classes.downIcon} />
                </div>
            );
        }
        return (
            <div
                onClick={() => {
                    dispatch({
                        type: Utils.ActionName.UPDATE_FACTORY,
                        payload: {
                            darwerOpen: true,
                        },
                    });
                }}
                className={classes.selectStyl}
            >
                {factoryInfo ? factoryInfo.factory : t('None')} <ArrowDropDownIcon className={classes.downIcon} />
            </div>
        );
    };

    const insideDrawer = () => {
        return (
            <div>
                <div className={classes.headerFlex}>
                    <div className={classes.headerLogo}>{headerLogo()}</div>
                    <div>{renderActiveFactory(true)}</div>
                </div>

                <List>
                    {factoryData &&
                        factoryData.length > 0 &&
                        factoryData.map((factoryInfo: any, index: number) => {
                            return (
                                <ListItem
                                    key={index + 1}
                                    button
                                    className={classes.listText}
                                    onClick={() => setFactory(factoryInfo)}
                                >
                                    <ListItemIcon>
                                        <Avatar className={classes.avtarStyl}>{index + 1}</Avatar>
                                    </ListItemIcon>
                                    <ListItemText primary={factoryInfo.factory} />
                                </ListItem>
                            );
                        })}
                </List>
            </div>
        );
    };

    return (
        <div className={classes.mainContainer}>
            <div className={`${classes.flexContainerLeft} ${classes.leftContainer}`}>
                {headerLogo()}
                <div>
                    {renderActiveFactory(false)}
                    <Drawer
                        className={classes.drwerContaier}
                        anchor={'left'}
                        open={darwerOpen}
                        onClose={() => {
                            dispatch({
                                type: Utils.ActionName.UPDATE_FACTORY,
                                payload: {
                                    darwerOpen: false,
                                },
                            });
                        }}
                    >
                        {insideDrawer()}
                    </Drawer>
                </div>
            </div>
            <div className={`${classes.flexContainer} ${classes.rightContainer}`}>
                {isLoaded ? (
                    <div className={classes.bottomStyle}>
                        <Badge badgeContent={unreadCount} color="primary">
                            <NotificationsNoneSharpIcon
                                className={classes.iconStyl}
                                onClick={handleClickNotification}
                            />
                        </Badge>

                        <Popover
                            id={idNotification}
                            open={isNotification}
                            anchorEl={anchorElNot}
                            onClose={handleCloseNotification}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            {notificationDetail()}
                        </Popover>
                    </div>
                ) : (
                    ''
                )}
                <div className={classes.bottomStyle}>
                    {/* <SettingsSharpIcon onClick={handleClickSetting} className={classes.iconStyl} /> */}
                    <img
                        alt="setting_icon"
                        onClick={handleClickSetting}
                        className={classes.iconStyl}
                        src={LocalImages.SETTING_ICON}
                    />

                    <Popover
                        id={idSetting}
                        open={isSetting}
                        anchorEl={anchorElSet}
                        onClose={handleCloseSetting}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        {settingDetail()}
                    </Popover>
                </div>
                <div className={classes.bottomStyle}>
                    <Avatar onClick={handleClick} className={classes.avtarStyl} {...stringAvatar(userName)} />

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        {accountDetail()}
                    </Popover>
                </div>
                <div className={classes.bottomStyle}>
                    {/* <AppsIcon onClick={()=>{setRightDarwer(true)}} className={classes.iconStyl} /> */}
                    <img
                        alt="menu_icon"
                        onClick={() => {
                            handleAppDrawer();
                        }}
                        className={classes.iconStyl}
                        src={LocalImages.RIGHT_HEADER}
                    />
                    <Drawer
                        className={classes.drwerRightContaier}
                        anchor={'right'}
                        open={rightDarwer}
                        onClose={() => {
                            setRightDarwer(false);
                        }}
                    >
                        {insideRightDrawer()}
                    </Drawer>
                </div>
            </div>
        </div>
    );
}
