export default {
    SIGN_IN: 'SIGN_IN',
    LOADING: 'LOADING',
    IS_LOADING: 'IS_LOADING',
    IS_LOADING_FILTER: 'IS_LOADING_FILTER',
    UPDATE_THEME_VALUE: 'UPDATE_THEME_VALUE',
    LINE_SETUP: 'line_setup',
    UPDATE_LINE_BALANCE: 'update_line_balance',
    UPDATE_LINE_SETUP_HISTORY_TABLE: 'update_line_setup_history_table',
    GET_STYLE_LIST: 'GET_STYLE_LIST',
    GET_SEASON_LIST: 'GET_SEASON_LIST',
    GET_CUSTOMER_LIST: 'GET_CUSTOMER_LIST',
    GET_STYLE_DETAIL: 'GET_STYLE_DETAIL',
    NEW_STYLE: 'NEW_STYLE',
    NEW_OPERATION: 'NEW_OPERATION',
    OPERATION_CODE_LIST: 'OPERATION_CODE_LIST',
    NEEDLE_LIST: 'NEEDLE_LIST',
    MACHINE_LIST: 'MACHINE_LIST',
    UPDATE_SEWING_OPERATION: 'update_sewing_opertaion',
    UPDATE_OPERATOR_LIST: 'update_operator_list',
    UPDATE_SKILL_LIST: 'update_skill_list',
    UPDATE_SEWING_OPERATION_FOR_ADD_STYLE: 'UPDATE_SEWING_OPERATION_FOR_ADD_STYLE',
    GET_LINES_SETUP: 'GET_LINES_SETUP',
    GET_SHIFTS_LIST: 'GET_SHIFTS_LIST',
    UPDATE_OPERRATION_CODE: 'update_operation_code',
    UPDATE_PPA_CODE: 'update_ppa_code',
    GET_MASTER_PPA: 'GET_MASTER_PPA',
    GET_MFG_LINES: 'GET_MFG_LINES',
    GET_FACTORIES_LIST: 'GET_FACTORIES_LIST',
    GET_LINE_SETUP_INFO: 'get_line_setup_info',
    GET_OPERATOR_POOL: 'get_operator_pool',
    GET_STATION: 'get_station',
    GET_LINES_SETUP_REPLICA: 'GET_LINES_SETUP_REPLICA',
    GET_OPERATOR_IN_POOL_SEARCH: 'GET_OPERATOR_IN_POOL_SEARCH',
    LINE_SETUP_DATA: 'line_setup_data',
    LINE_SETUP_DATA_STATION_REPLICA: 'LINE_SETUP_DATA_STATION_REPLICA',
    GET_NPT_CATEGORIES_LIST: 'GET_NPT_CATEGORIES_LIST',
    GET_NPT_TYPES_LIST: 'GET_NPT_TYPES_LIST',
    GET_LINE_ASSIST_INFO: 'get_line_assist_info',
    ASSIST_LINE_TABLE: 'assist_line_table',
    GET_NPT: 'GET_NPT',
    GET_ALL_NPT_TYPES: 'GET_ALL_NPT_TYPES',
    GET_DASHBOARD: 'get_dashboard',
    GET_ANALYTICS_FILTERS: 'GET_ANALYTICS_FILTERS',
    GET_ANALYTICS_EFFICIENCY: 'GET_ANALYTICS_EFFICIENCY',
    GET_ANALYTICS_OP_NPT: 'GET_ANALYTICS_OP_NPT',
    GET_ANALYTICS_STATS: 'GET_ANALYTICS_STATS',
    UPDATE_ANALYTICS_TEMPLATE_NAME: 'UPDATE_ANALYTICS_TEMPLATE_NAME',
    GET_ANALYTICS_TEMPLATE_LIST: 'GET_ANALYTICS_TEMPLATE_LIST',
    GET_DASHBOARD_FILTER: 'get_dashboard_filter',
    GET_ANALYTICS_CHART: 'get_analytica_chart',
    GET_NOTIFICATION: 'get_notificatio',
    GET_PRODUCT_SETTINGS: 'GET_PRODUCT_SETTINGS',
    UPDATE_FACTORY: 'update_factory',
    PRODUCT_SETTING: 'UPDATE_PRODUCT_SETTING',
    MASTER_DATA_TRANSLATION: 'MASTER_DATA_TRANSLATION',
    GET_PRODUCT_LIST: 'GET_PRODUCT_LIST',
    GET_PROFILE: 'GET_PROFILE',
    ASSIST_LINE_RREPORT: 'assist_line_report',
    GET_PRODUCT_TYPE_LIST: 'GET_PRODUCT_TYPE_LIST',
    GET_TIMESTUDY_OPERATOR_LIST: 'GET_TIMESTUDY_OPERATOR_LIST',
    LINE_SETUP_LIST_DATA: 'LINE_SETUP_LIST_DATA',
    GET_LINE_SHIFTS: 'get_line_shifts',
    GLOBAL_LANG_DATA: 'GLOBAL_LANG_DATA',
    UPDATE_PAGINATION_DASHBOARD: 'UPDATE_PAGINATION_DASHBOARD',
};
