import React from 'react';
import { makeStyles, createStyles, Theme, Typography, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';
import Schema from '../../../schema';
import { useTranslation } from 'react-i18next';
import NormalInput from '../../../components/inputs/normalInput';
import { useDispatch } from 'react-redux';

import { addingNptType } from './action';
const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            '& .MuiTextField-root': {
                marginBottom: '10px!important',
            },
        },
        textStyl: {
            fontSize: '20px',
            color: 'var(--dark-grey)',
            marginTop: '44px',
        },
        paper: {
            position: 'absolute',
            width: '25%',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        saveContainer: {
            backgroundColor: 'white',
            padding: '10px 0',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '41px',
        },
        cancelButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#515151',
            opacity: 1,
            height: '36px',
        },
        saveButton: {
            margin: '0 15px',
            minWidth: '100px',
            textAlign: 'center',
            font: 'normal normal medium 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#FFFFFF',
            opacity: 1,
            height: '36px',
        },
    }),
);

export default function AddNPTType({ categoryId, handleClose }: any) {
    const classes = styles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const [modalStyle] = React.useState(getModalStyle);

    return (
        <div style={modalStyle} className={classes.paper}>
            <div className={classes.mainContainer}>
                <Typography className={classes.textStyl}>{t("AddNPTType")}</Typography>
                <div>
                    <Formik
                        initialValues={{
                            nptCatId: categoryId,
                            nptType: '',
                            description: '',
                        }}
                        validationSchema={Schema.AddNPTType(t)}
                        onSubmit={(values, { setSubmitting }) => {
                            dispatch(addingNptType(values, setSubmitting, handleClose));
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                        }) => (
                            <Form>
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.nptType}
                                    onChange={handleChange}
                                    helperText={touched.nptType && errors.nptType}
                                    error={Boolean(touched.nptType && errors.nptType)}
                                    name={'nptType'}
                                    label={t("DisplayName")}
                                    isRequired={true}
                                />
                                <NormalInput
                                    onBlur={handleBlur}
                                    values={values.description}
                                    onChange={handleChange}
                                    helperText={touched.description && errors.description}
                                    error={Boolean(touched.description && errors.description)}
                                    name={'description'}
                                    label={t("DESCRIPTION")}
                                />

                                <div className={classes.saveContainer}>
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        className={classes.cancelButton}
                                        onClick={handleClose}
                                    >
                                        {t("CANCEL")}
                                    </Button>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className={classes.saveButton}
                                    >
                                        {isSubmitting ? `${t("Saving")}...` : t("Save")}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}
