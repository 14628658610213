import React, { useEffect, useRef } from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    TextField,
    InputAdornment,
    Button,
    Avatar,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Collapse,
    IconButton,
    TablePagination,
    Menu,
} from '@material-ui/core';
//@ts-ignore
import Highlighter from 'react-highlight-words';
import MultimediaButton from '../../../components/buttons/multimediaButton';
import EditIcon from '@material-ui/icons/Edit';
import LocalImages from '../../../utils/images';
import { ReducersModel } from '../../../model';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SimpleModal from '../../../components/modal';
import AddNPTCategory from './addNptcategory';
import Utils from '../../../utils';
import { getNptCategories } from './action';
import moment from 'moment';
import BulkNptUploading from './bulkNptUploading';
import { useHistory } from 'react-router-dom';
import ManageNPTFilter from '../../../components/filters/manageNpt';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            width: '95.5%',
            margin: '16px auto',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '18px 1.2%',
        },
        heading: {
            font: 'normal normal normal 20px/24px Roboto',
            letterSpacing: '0.07px',
            color: '#515151',
            opacity: 1,
            marginBottom: '18px',
        },
        searchInput: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            width: '300px',
            '& .MuiInputBase-root': {
                height: '36px',
                font: 'normal normal normal 14px/20px Roboto',
                letterSpacing: '0.05px',
                color: '#515151',
                opacity: 1,
            },
        },
        styleOperations: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '18px',
        },
        leftStyleOperations: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
        },
        addButton: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            opacity: 1,
            margin: '0 15px',
            border: '1px solid #1E88E5',
            font: 'normal normal normal 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#1E88E5',
            textTransform: 'uppercase',
            height: '36px',
        },
        paginationContainer: {
            marginTop: '40px',
            backgroundColor: '#F7F8FA',
            '& .MuiTypography-root': {
                textAlign: 'left',
                font: 'normal normal normal 14px/14px Roboto',
                letterSpacing: '0px',
                color: '#515151',
                opacity: 1,
            },
        },
        filterButton: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            opacity: 1,
            border: '1px solid #1E88E5',
            font: 'normal normal normal 16px/20px Roboto',
            letterSpacing: '0.06px',
            color: '#1E88E5',
            textTransform: 'uppercase',
            height: '36px',
        },
        categories: {
            padding: '11px',
            textTransform: 'uppercase',
            backgroundColor: '#F7F8FA',
            '& th': {
                font: 'normal normal bold 14px/14px Roboto',
                letterSpacing: '0.08px',
                color: '#515151',
                textTransform: 'uppercase',
                opacity: 1,
                marginLeft: '0.5%',
                padding: '16px 16px 16px 0',
                wordBreak: 'break-word',
                verticalAlign: 'top',
                [theme.breakpoints.between(700, 1380)]: {
                    whiteSpace: 'nowrap',
                },
            },
        },
        searchIcon: {
            cursor: 'pointer',
        },
        filterCount: {
            minWidth: '24px',
            minHeight: '24px',
            width: '1em',
            height: '1em',
            textAlign: 'center',
            font: 'normal normal normal 14px/20px Roboto!important',
            letterSpacing: '0.05px',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            opacity: 1,
            backgroundColor: '#1E88E5',
        },
        categoriesItems: {
            backgroundColor: 'var(--white)',
            alignItems: 'center',
            minHeight: '32px',
            padding: '6px 11px',
            borderBottom: '1px solid #E0E0E0',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#f2f2f2',
            },
            '& td': {
                font: 'normal normal normal 14px/14px Roboto',
                letterSpacing: '0px',
                fontWeight: 500,
                padding: '16px 16px 16px 0',
                color: '#515151',
                opacity: 1,
                marginLeft: '0.5%',
                wordBreak: 'break-word',
                verticalAlign: 'top',
            },
            '&>td:nth-child(1)': {
                padding: '8px 0',
            },
        },
        dwnIcnStyl: {
            cursor: 'pointer',
            width: '13px',
            verticalAlign: 'middle',
            transition: '1s all',
        },
        root: {
            '& > *': {
                borderBottom: 'unset',
            },
        },
        headStyl: {
            marginTop: '25px',
            marginBottom: '14px',
            textAlign: 'center',
            font: 'normal normal normal 14px/21px Roboto',
            letterSpacing: '0.08px',
            color: '#707070',
            opacity: 1,
        },
        collapsedRow: {
            '& td': {
                font: 'normal normal normal 14px/14px Roboto',
                letterSpacing: '0px',
                color: '#515151',
                opacity: 1,
                padding: '16px 16px 16px 0',
                wordBreak: 'break-word',
                verticalAlign: 'top',
            },
            '& td:nth-child(2)': {
                paddingLeft: '24px',
            },
        },
        inActive: {
            font: 'italic normal normal 14px/14px Roboto',
            color: 'var(--dark-grey)',
            letterSpacing: '0px',
            padding: '16px 16px 16px 0',
            opacity: 0.6,
            wordBreak: 'break-word',
            '&>td': {
                fontStyle: 'italic',
            },
        },
        filterMenu: {
            '& .MuiList-root': {
                width: '194px',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: '0px 0px 6px #00000029',
                opacity: 1,
            },
        },
    }),
);

export default function ManageNPT() {
    const classes = styles();
    const [openAddNptCategory, setOpenAddNptCategory] = React.useState(false);
    const { nptCategoriesData, count, page, limit, filterStatus, searchNpt } = useSelector(
        (state: ReducersModel) => state.nptCategoryListReducer,
    );
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const [openBulkUpload, setOpenBulkUpload] = React.useState(false);

    const handleOpenBulkUpload = () => {
        setOpenBulkUpload(true);
    };

    const handleCloseBulkUpload = () => {
        setOpenBulkUpload(false);
    };

    useEffect(() => {
        dispatch({
            type: Utils.ActionName.GET_NPT_CATEGORIES_LIST,
            payload: {
                filterStatus: ['true'],
                nptCategoriesData: [],
                page: 1,
                count: 1,
                limit: 10,
            },
        });
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: false,
        });
        dispatch(getNptCategories());
    }, [dispatch]);

    const handleOpenAddNptCategory = () => {
        setOpenAddNptCategory(true);
    };
    const handleCloseAddNptCategory = () => {
        setOpenAddNptCategory(false);
    };
    function Row({ category }: any) {
        const [open, setOpen] = React.useState(false);

        return (
            <React.Fragment>
                <TableRow className={`${classes.categoriesItems} ${!category.isActive ? classes.inActive : ''}`}>
                    <TableCell
                        style={category.npttypes && category.npttypes.length > 0 ? { opacity: 1 } : { opacity: 0 }}
                        width={'5%'}
                    >
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                                if (category.npttypes && category.npttypes.length > 0) setOpen(!open);
                            }}
                        >
                            {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell width={'15%'}>
                        <Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={[searchNpt]}
                            autoEscape={true}
                            textToHighlight={category.nptCat !== '' ? category.nptCat : '-'}
                        />
                    </TableCell>
                    <TableCell width={'15%'}>{t('NPTCategory')}</TableCell>
                    <TableCell width={'12%'}>
                        {category.desc1 !== '' && category.desc1 ? category.desc1 : '-'}
                    </TableCell>
                    <TableCell width={'8%'}>{category && category.isActive ? 'Active' : 'Inactive'}</TableCell>
                    <TableCell width={'10%'}>
                        {category.updatedBy !== '' && category.updatedBy ? category.updatedBy : '-'}
                    </TableCell>
                    <TableCell width={'10%'}>
                        {category.updatedDt !== null ? moment(category.updatedDt).format('YYYY-MM-DD') : '-'}
                    </TableCell>
                    <TableCell width={'10%'}>{category.createdBy !== '' ? category.createdBy : '-'}</TableCell>
                    <TableCell width={'10%'}>{moment(category.createdDt).format('YYYY-MM-DD')}</TableCell>
                    <TableCell width={'5%'}>
                        <EditIcon
                            onClick={() => {
                                history.push(`/admin/manage-npt-edit?id=${category.id}`);
                            }}
                            color={'primary'}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}
                        colSpan={10}
                    >
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Table aria-label="simple table">
                                <TableBody>
                                    {category &&
                                        category.npttypes &&
                                        category.npttypes.length > 0 &&
                                        category.npttypes.map((nptType: any, index: any) => (
                                            <TableRow
                                                className={`${classes.collapsedRow} ${!nptType.isActive ? classes.inActive : ''
                                                    }`}
                                                key={index}
                                            >
                                                <TableCell width={'5%'}></TableCell>
                                                <TableCell width={'15%'}>{nptType.nptType}</TableCell>
                                                <TableCell width={'15%'}>{t('NPTType')}</TableCell>
                                                <TableCell width={'12%'}>
                                                    {nptType.desc1 !== '' && nptType.desc1 ? nptType.desc1 : '-'}
                                                </TableCell>
                                                <TableCell width={'8%'}>
                                                    {nptType && nptType.isActive ? 'Active' : 'Inactive'}
                                                </TableCell>
                                                <TableCell width={'10%'}>
                                                    {nptType.updatedBy !== '' && nptType.updatedBy
                                                        ? nptType.updatedBy
                                                        : '-'}
                                                </TableCell>
                                                <TableCell width={'10%'}>
                                                    {nptType.updatedDt !== null
                                                        ? moment(nptType.updatedDt).format('YYYY-MM-DD')
                                                        : '-'}
                                                </TableCell>
                                                <TableCell width={'10%'}>
                                                    {nptType.createdBy !== '' ? nptType.createdBy : '-'}
                                                </TableCell>
                                                <TableCell width={'10%'}>
                                                    {moment(nptType.createdDt).format('YYYY-MM-DD')}
                                                </TableCell>
                                                <TableCell width={'5%'}></TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                            {/* </Box> */}
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        dispatch({
            type: Utils.ActionName.GET_NPT_CATEGORIES_LIST,
            payload: {
                nptCategoriesData: [],
                page: newPage + 1,
            },
        });
        dispatch(getNptCategories());
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({
            type: Utils.ActionName.GET_NPT_CATEGORIES_LIST,
            payload: {
                nptCategoriesData: [],
                page: 1,
                limit: event.target.value,
            },
        });

        dispatch(getNptCategories());
    };

    const debounceCount = useRef(0);
    const api = () =>
        new Promise((res) => {
            setTimeout(() => {
                res(1);
            }, 500);
        });

    const handleSearch = (event: any) => {
        dispatch({
            type: Utils.ActionName.GET_NPT_CATEGORIES_LIST,
            payload: {
                searchNpt: event.target.value,
                page: 1,
            },
        });
        debounceCount.current += 1;
        const localDebounceCount = debounceCount.current;
        api().then((d) => {
            if (localDebounceCount !== debounceCount.current) {
                return;
            }
            dispatch(getNptCategories());
        });
    };

    // for filter

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    const getFilterCount = () => {
        return +filterStatus.length;
    };

    return (
        <>
            <Helmet>
                <title>{t('NPTType')}</title>
            </Helmet>
            <div className={classes.mainContainer}>
                <div className={classes.heading}>{t('NPTType')}</div>
                <div className={classes.styleOperations}>
                    <div className={classes.leftStyleOperations}>
                        <TextField
                            className={classes.searchInput}
                            variant="outlined"
                            placeholder={t('Search')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img
                                            alt={t('Search')}
                                            src={LocalImages.SEARCH_ICON}
                                            className={classes.searchIcon}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={handleSearch}
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<AddIcon />}
                            className={classes.addButton}
                            onClick={handleOpenAddNptCategory}
                        >
                            {t('Add')}
                        </Button>
                        <MultimediaButton
                            icon={LocalImages.CLOUD_UPLOAD}
                            text={t('BulkUpload')}
                            onClick={() => {
                                setOpenBulkUpload(true);
                            }}
                        />
                        <SimpleModal
                            handleOpen={handleOpenBulkUpload}
                            isOpen={openBulkUpload}
                            body={<BulkNptUploading handleClose={handleCloseBulkUpload} />}
                        />
                    </div>
                    <div>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<FormatListBulletedIcon />}
                            className={classes.filterButton}
                            endIcon={<Avatar className={classes.filterCount}>{getFilterCount()}</Avatar>}
                            onClick={handleOpenFilter}
                        >
                            {t('Filter')}
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseFilter}
                            className={classes.filterMenu}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            getContentAnchorEl={null}
                        >
                            <ManageNPTFilter />
                        </Menu>
                    </div>
                </div>
                <Table stickyHeader={true} aria-label="sticky table">
                    <TableHead style={{ borderTop: '1px solid #e2dcdc' }}>
                        <TableRow className={classes.categories}>
                            <TableCell width={'5%'}></TableCell>
                            <TableCell width={'15%'}>{t('DisplayName')}</TableCell>
                            <TableCell width={'15%'}>{t('Type')}</TableCell>
                            <TableCell width={'12%'}>{t('DESCRIPTION')}</TableCell>
                            <TableCell width={'8%'}>{t('Status')}</TableCell>
                            <TableCell width={'10%'}>{t('MODIFIEDBY')}</TableCell>
                            <TableCell width={'10%'}>{t('DATEMODIFIED')}</TableCell>
                            <TableCell width={'10%'}>{t('Createdby')}</TableCell>
                            <TableCell width={'10%'}>{t('DateCreated')}</TableCell>
                            <TableCell width={'5%'}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {nptCategoriesData && nptCategoriesData.length > 0
                            ? nptCategoriesData.map((category: any, index: any) => {
                                return <Row category={category} />;
                            })
                            : ''}
                    </TableBody>
                </Table>
                {nptCategoriesData && nptCategoriesData.length > 0 ? (
                    <div className={classes.paginationContainer}>
                        <TablePagination
                            component="div"
                            count={count}
                            page={page - 1}
                            onPageChange={handleChangePage}
                            rowsPerPage={limit}
                            labelDisplayedRows={({ from, to, count, page }) => `  ${from}-${to} of  ${count} `}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                ) : (
                    <div className={classes.headStyl}>{t('NoRecords')}</div>
                )}

                <SimpleModal
                    handleOpen={handleOpenAddNptCategory}
                    isOpen={openAddNptCategory}
                    body={<AddNPTCategory handleClose={handleCloseAddNptCategory} />}
                />
            </div>
        </>
    );
}
