import React, { useEffect } from 'react';
import { makeStyles, createStyles, Theme, Tabs, Tab } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import StyleDetails from './styleDetails';
import LineSetupHistory from './lineSetupHistory';
import { getCustomers, getFactoriesList, getStyleDetail, getSweingOperation } from './action';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModel } from '../../model';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            width: '95.5%',
            margin: '8px auto',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '18px 25px',
        },
        heading: {
            font: 'normal normal normal 20px/24px Roboto',
            letterSpacing: '0.07px',
            color: '#515151',
            opacity: 1,
            marginBottom: '18px',
        },
        tabsContainer: {
            display: 'flex',
            borderBottom: '3px solid #f0f0f0',
            '& .MuiButtonBase-root': {
                borderBottom: '4px solid rgb(22, 137, 250, 0.2)',
            },
            '& .MuiTabs-indicator': {
                height: '3px',
            },
            '& .MuiTab-wrapper': {
                font: 'normal normal normal 16px/19px Roboto',
                letterSpacing: '0.06px',
                opacity: 1,
            },
            '& .MuiTab-root': { textTransform: 'unset' },
        },
    }),
);

export default function StyleInformation({ history }: any) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = styles();
    const [value, setValue] = React.useState(0);
    const styleId = new URL(window.location.href).searchParams.get('id');
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };
    const { reorder } = useSelector((state: ReducersModel) => state.sewingOperationReducer);
    const { style } = useSelector((state: ReducersModel) => state.styleDetailReducer);

    useEffect(() => {
        dispatch(getStyleDetail(styleId));
        if (!reorder) {
            dispatch(getSweingOperation(styleId));
        }
        dispatch(getCustomers());
        dispatch(getFactoriesList());
    }, [dispatch, styleId]); // eslint-disable-line

    return (
        <React.Fragment>
            <Helmet>
                <title>{t('StyleInformation')}</title>
            </Helmet>
            <div className={classes.mainContainer}>
                <div className={classes.heading}>{value === 0 ? t('StyleInformation') : style}</div>
                <div className={classes.tabsContainer}>
                    <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
                        <Tab label={t('StyleDetails')} />
                        <span style={{ marginRight: '16px' }} />
                        <Tab label={t('LineSetupHistory')} />
                    </Tabs>
                </div>
            </div>

            <div>{value === 0 ? <StyleDetails history={history} /> : <LineSetupHistory history={history} />}</div>
        </React.Fragment>
    );
}
